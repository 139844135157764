import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { APERuleCollection, BaseComponent, FennecSnackbarService, Logger } from "xf-common";


@Component({
  selector: 'app-ape-collection-dialog',
  templateUrl: './ape-collection-dialog.component.html',
  styleUrls: ['./ape-collection-dialog.component.scss']
})
export class ApeCollectionDialogComponent extends BaseComponent implements OnInit {

  mode?: "Create" | "Edit" | "View";
  apeRuleCollection?: APERuleCollection;
  formGroup: FormGroup;

  protected log = new Logger("ApeCollectionDialogComponent");

  constructor(
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<ApeCollectionDialogComponent>,
    override snack: FennecSnackbarService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    super();
    this.mode = this.data?.mode;
    this.apeRuleCollection = this.data?.apeRuleCollection;
    this.formGroup = new FormGroup({
      name: new FormControl(this.apeRuleCollection?.name ?? "", Validators.required),
      description: new FormControl(this.apeRuleCollection?.description ?? ""),
      uniqueIdentificationCode: new FormControl(this.apeRuleCollection?.uniqueIdentificationCode ?? "")
    })
   }

  ngOnInit(): void {
  }

  cancel() {
    this.dialogRef.close();
  }

  submit() {
    const apeRuleCollection: APERuleCollection = this.apeRuleCollection ?? new APERuleCollection();
    const formValue = this.formGroup.value;
    if (!formValue.name) {
      super.showErrorSnack("Name is required");
      return;
    }
    if (!formValue.description) {
      super.showErrorSnack("Description required");
      return;
    }
    if (!formValue.uniqueIdentificationCode) {
      super.showErrorSnack("Unique Identification Code required");
      return;
    }

    apeRuleCollection.name = formValue["name"];
    apeRuleCollection.description = formValue["description"];
    apeRuleCollection.uniqueIdentificationCode = formValue["uniqueIdentificationCode"];

    this.dialogRef.close(apeRuleCollection);
  }

}
