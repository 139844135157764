import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent, CompanyService, FennecSnackbarService, Logger } from "xf-common";

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent extends BaseComponent implements OnInit {
  log: Logger = new Logger("CompanyComponent");

  company:any = null;
  companyId = -1;
  constructor(
    protected snack: FennecSnackbarService,
    protected activeRoute: ActivatedRoute,
    private companyService: CompanyService
  ) {
    super();
    this.companyId = this.activeRoute.snapshot.params["id"];
    this.getCompany();
   }

  ngOnInit(): void {
  }

  getCompany() {
    this.isLoading = true;
    this.performXFRequest({
      requestDescription: "GET Company by ID",
      requestFn: this.companyService.getCompany,
      fnParams: [this.companyId],
      onSuccess: data => {
        this.company = data;
      },
      onError: errString => {
        super.showErrorSnack(errString);
      }
    });
  }

}
