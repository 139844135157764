import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { APERuleAPERuleFilter, BaseCrudService, Crud, DataResponse, PagedResponse } from "xf-common";

@Injectable()
export class ApeRuleApeRuleFilterService extends BaseCrudService<APERuleAPERuleFilter> {
  public baseEndpoint = super.apiEndpoint("ape-rule-ape-rule-filter");

  constructor(
    public override httpClient: HttpClient
  ) {
    super(httpClient, "ApeRuleApeRuleFilterService", "APERuleAPERuleFilter");
  }

  _list: Crud.ListFunction<APERuleAPERuleFilter> = (first, max) => {
    const endpoint = `${this.baseEndpoint}/list`;
    return this.httpClient.get<PagedResponse<APERuleAPERuleFilter[]>>(endpoint, { params: { first, max} })
  };

  _getById: Crud.GetByIdFunction<APERuleAPERuleFilter> = () => {
    return EMPTY;
  };

  _create: Crud.CreateFunction<APERuleAPERuleFilter> = (payload) => {
    const endpoint = `${this.baseEndpoint}/create-update`;
    return this.httpClient.post<DataResponse<APERuleAPERuleFilter>>(endpoint, payload);
  };
  _update: Crud.UpdateFunction<APERuleAPERuleFilter> = (payload) => {
    const endpoint = `${this.baseEndpoint}/create-update`;
    return this.httpClient.post<DataResponse<APERuleAPERuleFilter>>(endpoint, payload);
  };

  _deactivate: Crud.DeleteFunction<APERuleAPERuleFilter> = (id) => {
    const endpoint = `${this.baseEndpoint}/delete`;
    return this.httpClient.put<DataResponse<boolean>>(endpoint, id[0]);
  };
  _delete: Crud.DeleteFunction<APERuleAPERuleFilter> = (id) => {
    const endpoint = `${this.baseEndpoint}/${id}`;
    return this.httpClient.delete<DataResponse<boolean>>(endpoint);
    // return EMPTY;
  };

  protected _crudFunctions: Crud.CrudServiceDef<APERuleAPERuleFilter> = {
    _list: this._list,
    _getById: this._getById,
    _create: this._create,
    _update: this._update,
    _deactivate: this._deactivate,
    _delete: this._delete
  };

  list = (id: number, first: number, max: number) => {
    const endpoint = `${this.baseEndpoint}/list/${id}?first=${first}&max=${max}`;
    return this.httpClient.get<PagedResponse<APERuleAPERuleFilter[]>>(endpoint);
  }

  assertionList = () => {
    const endpoint = `${this.baseEndpoint}/assertions`;
    return this.httpClient.get<DataResponse<String[]>>(endpoint);
  }

  reorder = (payload:any): Observable<PagedResponse<any>> => {
    return this.httpClient.post<PagedResponse<any>>(this.baseEndpoint + "/reorder", payload);
  }
}
