import { Deserializable, deserializeAssign } from "../deserializable";
import { APERule } from "./APERule";
import { APERuleFilter } from "./APERuleFilter";

export class APERuleAPERuleFilter implements Deserializable<APERuleAPERuleFilter> {

  id?: number;
  seqNo?: number;
  apeRule?: APERule;
  apeRuleFilter?: APERuleFilter;
  apeRuleFilterAssertion?: string;

  deserialize(input: any): APERuleAPERuleFilter {
    return deserializeAssign(this, input);
  }

  toJSON(): any {
    const obj: { [key: string]: any } = {};
    Object.assign(obj, this);
    delete obj["selected"];
    return obj;
  }
}