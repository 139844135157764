<lib-page #page [showTitle]="false">

  <ng-container pageTitle>
    Finance
  </ng-container>

  <ng-container pageContent class="container-override">
    <form [formGroup]="formGroup">
      <div>
        <mat-checkbox placeholder="Claim Paid" formControlName="claimPaid">Claim Paid</mat-checkbox>
      </div>
    </form>
    <ng-container *ngIf="miCaseFinanceInfo.miCaseDbrdSavingsStatus != 'READY'">
      Savings Dashboard: 
      <button mat-flat-button color="accent" (click)="setDbrdSavingsReady()">Not Ready</button>
    </ng-container>
    <ng-container *ngIf="miCaseFinanceInfo.miCaseDbrdSavingsStatus == 'READY'">
      Savings Dashboard: 
      <button mat-flat-button color="accent" (click)="setDbrdSavingsNotReady()" class="mi-case-finance-dbrd-savings-ready">Ready</button>
    </ng-container>    
  </ng-container>
  <ng-container pageActions class="container-override">
    <button style="align-self: flex-end;" mat-flat-button color="accent" (click)="save()"> Save </button>
  </ng-container>
</lib-page>
