import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { BaseComponent, CompanyService, FennecSnackbarService, Logger, SingleChoiceDialogComponent } from 'xf-common';
import { MatLegacyPaginator as MatPaginator } from "@angular/material/legacy-paginator";
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { CompanyAddComponent } from "@app/company/company-add/company-add.component";


@Component({
  selector: 'app-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.scss', '../../../lib/styles/system-admin-styles.component.scss']
})
export class CompanyListComponent extends BaseComponent implements OnInit, AfterViewInit {

  displayedColumns = ['id', 'name', 'address', 'actions'];
  companyList: any [] = [];
  log: Logger = new Logger("CompanyListComponent");

  @ViewChild(MatPaginator)
  paginator?: MatPaginator;

  totalRowCount?: number;

  dialogRef?: MatDialogRef<any>

  queryParams = {
    companyName: "",
  };

  defaultPageSize = 20;
  pageSizeOptions = [5, 10, 20, 25, 50];


  constructor(
    private companyService: CompanyService,
    protected snack: FennecSnackbarService,
    protected dialog: MatDialog,
  ) {
    super();
    this.getCompanies();
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    if (this.paginator) {
      this.paginator.page.subscribe(() => {
        this.getCompanies();
      })
    }
  }

  clearSearch() {
    this.queryParams.companyName = "";
    this.getCompanies()
  }

  searchCompanies() {
    this.paginator?.firstPage();
    this.getCompanies();
  }

  getCompanies() {
    this.queryParams.companyName = this.queryParams.companyName.trim();
    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;
    this.performXFRequest({
      requestDescription: "GET all companies",
      requestFn: this.companyService.getAllCompanies,
      fnParams: [this.queryParams.companyName, first, pageSize],
      onResponse: response => {
        this.companyList = response.data;
        this.totalRowCount = response['totalRowCount'];
      },
      onError: errString => {
        super.showErrorSnack(errString)
      }
    })
  }

  onCreate() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "40%";
    this.dialogRef = this.dialog.open(CompanyAddComponent, dialogConfig);
    this.dialogRef.afterClosed().subscribe( response => {
      if (response.confirm) {
        this.getCompanies();
      }
    })
  }

  onDeleteCompany(company:any) {
    if (company) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        question:`Are you sure you want to deactivate ${company.name}?`
      };
      this.dialogRef = this.dialog.open(SingleChoiceDialogComponent, dialogConfig);
      this.dialogRef.afterClosed().subscribe((result) => {
        if(result?.confirm) {
          this.performXFRequest({
            requestDescription: "Delete company",
            requestFn: this.companyService.deleteCompany,
            fnParams: [company.id],
            onSuccess: response => {
              this.getCompanies();
            },
            onError: errString => {
              super.showErrorSnack(errString)
            }
          })
        }
      })
    }
  }
}
