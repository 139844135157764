import { of } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseCrudService, Crud, DataResponse, HolidayQueryPacket, HolidayUpdatePacket, PagedResponse } from "xf-common";


@Injectable()
export class HolidayService extends BaseCrudService<HolidayQueryPacket> {

  baseEndpoint: string = super.apiEndpoint("holiday");

  constructor(public override httpClient: HttpClient) {
    super(httpClient, "HolidayService", "Holiday");
  }

  _create: Crud.CreateFunction<HolidayQueryPacket> = (el: HolidayUpdatePacket) => {
    const endpoint = `${this.baseEndpoint}/create`;
    return this.httpClient.post<DataResponse<HolidayQueryPacket>>(endpoint, el);
  };

  _deactivate: Crud.DeactivateFunction<HolidayQueryPacket> = (ids: number[]) => of(new DataResponse(false));

  _delete: Crud.DeleteFunction<HolidayQueryPacket> = (ids: number[]) => {
    const endpoint = `${this.baseEndpoint}/delete/${ids[0]}`;
    return this.httpClient.delete<DataResponse<boolean>>(endpoint);
  };

  _getById: Crud.GetByIdFunction<HolidayQueryPacket> = (id: number) => {
    const endpoint = `${this.baseEndpoint}/${id}`;
    return this.httpClient.get<DataResponse<HolidayQueryPacket>>(endpoint);
  };

  _list: Crud.ListFunction<HolidayQueryPacket> = (first: number, max: number) => {
    const endpoint = `${this.baseEndpoint}/list`;
    return this.httpClient.get<PagedResponse<HolidayQueryPacket[]>>(endpoint, { params: {first, max}});
  };

  _update: Crud.UpdateFunction<HolidayQueryPacket> = (el: HolidayUpdatePacket) => {
    const endpoint = `${this.baseEndpoint}/update`;
    return this.httpClient.put<DataResponse<HolidayQueryPacket>>(endpoint, el);
  };


  protected _crudFunctions: Crud.CrudServiceDef<HolidayQueryPacket> = {
    _list: this._list,
    _getById: this._getById,
    _create: this._create,
    _update: this._update,
    _deactivate: this._deactivate,
    _delete: this._delete
  }
}
