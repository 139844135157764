<lib-page>
  <ng-container pageTitle
    *ngIf="patientInfo"
  >
    Patient {{ patientInfo.altPatientId }} {{ patientInfo.lastName }}, {{ patientInfo.firstName }}
  </ng-container>

  <ng-container pageContent>
    <mat-tab-group #tabGroup>
      <!-- <mat-tab label="Overview">
        <lib-patient-overview></lib-patient-overview>
      </mat-tab> -->

      <mat-tab label="Cases">
        <lib-patient-case-list></lib-patient-case-list>
      </mat-tab>
    </mat-tab-group>
  </ng-container>
</lib-page>
