import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { BaseComponent } from "../../../view/base.component";
import { AdmissionSourcePacket } from "../../../model/net/admission-source";
import { Logger } from "../../../util/logger";
import { AdmissionSourceService } from "../admission-source.service";
import { FennecSnackbarService } from "../../../dialog/fennec-snackbar/fennec-snackbar.service";

@Component({
  selector: 'app-admission-source-dialog',
  templateUrl: './admission-source-dialog.component.html',
  styleUrls: ['./admission-source-dialog.component.scss']
})
export class AdmissionSourceDialogComponent extends BaseComponent implements OnInit {

  mode?: "Create" | "Edit" | "View";
  admissionSource?: AdmissionSourcePacket;
  formGroup: FormGroup;

  protected log = new Logger("AdmissionSourceDialogComponent");

  admissionTypes: string[] = [];

  constructor(
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<AdmissionSourceDialogComponent>,
    override snack: FennecSnackbarService,
    protected admissionSourceService: AdmissionSourceService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    super();
    this.mode = this.data?.mode;
    this.admissionSource = this.data?.AdmissionSource;
    this.formGroup = new FormGroup({
      code: new FormControl(this.admissionSource?.code ?? "", [Validators.required, Validators.maxLength(4)]),
      description: new FormControl(this.admissionSource?.description ?? "", [Validators.required, Validators.maxLength(600)]),
      admissionType: new FormControl(this.admissionSource?.admissionType ?? "", Validators.required)
    });
  }

  ngOnInit(): void {
    this.performXFRequest({
      requestDescription: "Get Admission Source Types",
      requestFn: this.admissionSourceService.getAdmissionTypes,
      fnParams: [],
      onSuccess: (data: string[]) => {
        this.admissionTypes = data;
      },
      onComplete: () => {
        this.isLoading = false;
      },
      onError: (error) => {
        super.showErrorSnack(error);
      }
    });
  }

  submit() {
    const admissionSource: AdmissionSourcePacket = this.admissionSource ?? new AdmissionSourcePacket();
    const formValue = this.formGroup.value;
    if (!formValue.code) {
      super.showErrorSnack("Code is required");
      return;
    }
    if (!formValue.description) {
      super.showErrorSnack("Description required");
      return;
    }
    admissionSource.code = formValue["code"];
    admissionSource.description = formValue["description"];
    admissionSource.admissionType = formValue["admissionType"];
    this.dialogRef.close(admissionSource);
  }

  cancel() {
    this.dialogRef.close();
  }


}
