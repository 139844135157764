import {AfterViewInit, Component, ElementRef, Input, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import { Subject } from 'rxjs';
// @ts-ignore
import {CellComponent, ColumnComponent, RowComponent, Tabulator, TabulatorFull} from 'tabulator-tables';
import { DateTime} from "luxon";

// Utilizing Tabulator found here: https://tabulator.info/
// https://github.com/David-Mawer/tabulator-angular-sample/blob/master/src/app/tabulator-grid/tabulator-grid.component.ts

@Component({
  selector: 'app-itemized-line-item-grid',
  templateUrl: './itemized-line-item-grid.component.html',
  styleUrls: ['./itemized-line-item-grid.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class ItemizedLineItemGridComponent implements OnInit, AfterViewInit {

  // Specifies whether or not to let the grid automatically create columns. If
  // false, you need to supply a columnConfig in order for the grid to render
  // anything.
  @Input()
  autoColumns = false;

  @Output()
  initialized = new Subject<void>();

  @Input()
  activityLogAccess = false;

  // Subscribe to this subject to be informed that the user wishes to take an
  // action on an array of supplied line item ids. This component does not implement
  // those actions. The caller/parent/subscriber must implement the desired action.
  // Returned: {
  //     lineItemIds: [],
  //     action: '(string that represents the action requested)'
  // }
  lineItemActionRequested: Subject<any> = new Subject();

  // Subscribe to this subject if you wish to know when line item rows have
  // been selected. This subject will return a list of the line item ids of
  // the selected line items.
  lineItemsSelected: Subject<any> = new Subject();

  @ViewChild('tabularGridWrapper', { static: true }) wrapperDiv!: ElementRef<HTMLDivElement>;

  // Tabulator fields
  private tableDiv = document.createElement('div'); // this is the div that will contain that tabulator-grid HTML.
  private tableRef: Tabulator; // this will become a reference to the tabulator-grid object
  private tableData: any[] = [];
  private columnConfig: any[] = [];

  // Line Item Id to scroll to after update operations.
  private scrollToLineItemId: number = -1;

  // This determines what actions are available on each line item, based upon this value.
  private iliEditorMode: string = "CLAIM_REVIEW";

  // TODO: make this configurable
  private showLineItemActions = true;

  // An array of row data that is currently selected by the grid.
  private selectedRowData: any [] = [];

  /* used for testing
  private tableData: any [] = [
    {id:1, name:"Oli Bob", progress:12, gender:"male", rating:1, col:"red", dob:"19/02/1984", car:1},
    {id:2, name:"Mary May", progress:1, gender:"female", rating:2, col:"blue", dob:"14/05/1982", car:true},
    {id:3, name:"Christine Lobowski", progress:42, gender:"female", rating:0, col:"green", dob:"22/05/1982", car:"true"},
    {id:4, name:"Brendon Philips", progress:100, gender:"male", rating:1, col:"orange", dob:"01/08/1980"},
    {id:5, name:"Margret Marmajuke", progress:16, gender:"female", rating:5, col:"yellow", dob:"31/01/1999"},
    {id:6, name:"Frank Harbours", progress:38, gender:"male", rating:4, col:"red", dob:"12/05/1966", car:1},
  ]; */

  constructor() {
    window["DateTime"] = DateTime;
  }
  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
  }

  // Line item data is set by the external consumer.
  public setLineItemData(liData: any[]) {
    if (this.tableData) {
      this.tableData.splice(0, this.tableData.length)
    } else {
      this.tableData = [];
    }
    this.tableData.push(...liData);

    // Mark the row we need to scroll to, if any.
    if (this.scrollToLineItemId >= 0) {
      liData.forEach((li) => {
        if (li.id === this.scrollToLineItemId) {
          li.scrollToRow = true;
        }
      });
    }

    // Delay the table refresh a little bit so line item data is properly established and ready for rendering in
    // the grid.
    setTimeout(() => {
      this.createTable();
    }, 200);
  }

  setScrollToLineItemId(id: number) {
    this.scrollToLineItemId = id;
  }

  isColumnConfigEmpty(): boolean {
    return this.columnConfig.length === 0;
  }

  setColumnConfig(columnConfig: any[], iliEditorMode: string = "CLAIM_REVIEW") {
    this.iliEditorMode = iliEditorMode;
    this.columnConfig = [];
    columnConfig.forEach((cc) => {
      this.columnConfig.push(cc);
    });

    if (this.showLineItemActions) {
      if (this.iliEditorMode === "APPEAL") {
        this.columnConfig.push({
          width: 60,
          formatterParams: {_this: this, action: "apl"},
          formatter: function(cell:any, formatterParams:any, onRendered: any) {
            let r = cell.getRow();
            let isAdjusted =
              r._row.data.adjustmentReasonCode !== null ||
              r._row.data.adjustmentExplanationCode !== null ||
              r._row.data.appealReasonCode !== null ||
              r._row.data.appealExplanationCode !== null;
            if (isAdjusted) {
              return '<div style="width: 100%; text-align: center"><button style="background-color: #B8DDD6; height: 30px; font-size: 12px;">APL</button></div>';
            } else {
              return '<div style="width: 100%; text-align: center">---</div>';
            }
          }},
        );
      }
      this.columnConfig.push({
        width: 60,
        formatterParams: {_this: this, action: "adj"},
        formatter: function(cell:any, formatterParams:any, onRendered: any) {
          return '<div style="width: 100%; text-align: center"><button style="background-color: yellow; height: 30px; font-size: 12px;">ADJ</button></div>';
        }},
      );
      this.columnConfig.push(
        {width: 60,
        formatterParams: {_this: this, action: "pristine"},
        formatter: function(cell:any, formatterParams:any, onRendered: any) {
          return '<div style="width: 100%; text-align: center"><button style="background-color: wheat; height: 30px; font-size: 12px;">CLR</button></div>';
        }},
      );
      this.columnConfig.push({
        width:60,
        formatterParams: {_this:this, action:"comments"},
        formatter: function(cell: any, formatterParams:any, onRendered: any) {
          let r = cell.getRow();
          let commentCount = r._row.data.commentsCount !== null ?
            r._row.data.commentsCount : 0;
          if (commentCount <= 0) {
            return '<div style="width: 100%; text-align: center"><button class="no-comments-button">CMTS</button></div>';
          }

          //let commentInfoDiv = "";
          //commentInfoDiv += commentCount + " Comments";

          // return '<div class="comment-button-container">' 
          //   + '<button class="comment-button">CMTS</button>' 
          //   + '<div class="comment-info-div">'
          //   + commentInfoDiv
          //   + '</div>'
          //   + '</div>';

          return '<button class="comment-button">CMTS</button>';

        },
        tooltip:function(e, cell, onRendered) {
          let r = cell.getRow();
          let commentCount = r._row.data.commentsCount !== null ?
            r._row.data.commentsCount : 0;
          if (commentCount <= 0) {
            return null;
          }

          return "<div class='comment-tooltip'>"
          + `<h3>${commentCount} Comment${commentCount != 1 ? "s" : ""}</h3>`
          + "</div>" 
        }
      })
      this.columnConfig.push(
        {width: 60,
        formatterParams: {_this: this, action: "rev_code"},
        formatter: function(cell:any, formatterParams:any, onRendered: any) {
          return '<div style="width: 100%; text-align: center"><button style="background-color: wheat; height: 30px; font-size: 12px;">EDIT</button></div>';
        }},
      );

      if (this.activityLogAccess) {
        this.columnConfig.push(
          {width: 60,
          formatterParams: {_this: this, action: "activity_log"},
          formatter: function(cell:any, formatterParams:any, onRendered: any) {
            return '<div style="width: 100%; text-align: center"><button style="background-color: wheat; height: 30px; font-size: 12px;">LOG</button></div>';
          }},
        );      
      }
    }

  }

  createLineItemActions(id: number):any {
  }

  refreshTableDisplay() {
    this.createTable();
  }

  clearTableSort() {
    this.tableRef?.clearSort();
  }

  private createTable() {
    if (!this.tableRef) {
      // create the table with the given Angular parameters
      // for details about the Tabulator parameters - check http://tabulator.info/docs/4.2/options
      this.tableRef = new TabulatorFull(this.tableDiv, {
        // Note: do not load data on the initial table create. Need to do this in order for the grid to render
        // fully and not compete with angular rendering.
        data: this.tableData,
        // reactiveData: true, // DO NOT ENABLE REACTIVE DATA - IT WILL CAUSE THE GRID TO FREEZE
        columns: this.columnConfig,
        autoColumns: this.autoColumns,
        layout: "fitDataFill",
        selectableRows: true,
        selectableRowsRangeMode: "click",
        rowFormatter: function(row: any) {
          //console.log(row.getData());
          if (row.getData().adjustmentExplanationCode !== null && row.getData().adjustmentExplanationCode !== undefined) {
            const children = row.getElement().childNodes;
            children.forEach((child: any) => {
              let tf: string = child.getAttribute('tabulator-field');
              if (!!tf) {
                if (tf.includes("adjustmentReasonCode") || tf.includes("adjustmentExplanationCode")) {
                  child.style.backgroundColor = '#F5DEB3';
                }
              }
            });
          }
          if (row.getData().appealExplanationCode !== null && row.getData().appealExplanationCode !== undefined) {
            const children = row.getElement().childNodes;
            children.forEach((child: any) => {
              let tf: string = child.getAttribute('tabulator-field');
              if (!!tf) {
                if (tf.includes("appealReasonCode") || tf.includes("appealExplanationCode") || tf.includes("appealDecision")) {
                  child.style.backgroundColor = '#B8DDD6';
                }
              }
            });
          }
          if (row.getData().apeAdjustedFormatted !== null) {
            const children = row.getElement().childNodes;
            children.forEach((child: any) => {
              let tf: string = child.getAttribute('tabulator-field');
              if (!!tf) {
                if (tf.includes('apeAdjusted')) {
                  if (row.getData().apeAdjusted) {
                    child.style.backgroundColor = 'yellow';
                  }
                }
              }
            });
          }
          if (row.getData().revenueCode !== row.getData().mappedRevenueCode) {
            const children = row.getElement().childNodes;
            children.forEach((child: any) => {
              let tf: string = child.getAttribute('tabulator-field');
              if (!!tf) {
                if (tf.includes('mappedRevenueCode')) {
                  if (row.getData().mappedRevenueCode) {
                    child.style.backgroundColor = 'yellow';
                  }
                }
              }
            });
          }
          if (row.getData().netZeroId !== null) {
            row.getElement().style.backgroundColor = "#bdcebe";
          }
          // Scroll to position row background color - this should change every update.
          if (row.getData().scrollToRow) {
            row.getElement().style.backgroundColor = "#FFFF8F";
          }

        }
        //height: this.height,
      });
      // Capture some of the events and pass them back to the declaring code.
      // http://tabulator.info/docs/5.0/events
      /*
      this.tableRef.on('tableBuilding', this.tableBuilding.bind(this));
      this.tableRef.on('tableBuilt', this.tableBuilt.bind(this));
      this.tableRef.on('cellEdited', this.cellEdited.bind(this));
      this.tableRef.on('dataLoading', this.dataLoading.bind(this));
      this.tableRef.on('dataLoaded', this.dataLoaded.bind(this));
      */
      this.tableRef?.on('cellClick', this.cellClicked.bind(this));
      this.tableRef?.on('rowSelectionChanged', this.rowSelectionChanged.bind(this));
      this.tableRef?.on("tableBuilt", () => {
        this.initialized.next();
      });
      this.wrapperDiv.nativeElement.appendChild(this.tableDiv);
      // this.tableRef?.redraw(true);

      // Note: This is being done because the initial setting of the column config above (i.e. setting the 'columns'
      // property on the table itself) is not affecting the grid columns at all. In order to have the column definitions
      // take effect on table creating time, I set a little delay so the grid builds, then I re-apply the column
      // configuration data.
      setTimeout(() => {
        if (!!this.columnConfig) {
          this.tableRef?.setColumns(this.columnConfig);
        }
      });
    } else {
      if (!!this.columnConfig) {
        this.tableRef?.setColumns(this.columnConfig);
      }
      if (!!this.tableData) {
        this.tableRef?.replaceData(this.tableData);
      }
    }

    setTimeout(() => {
      this.repositionToLineItem();
    }, 1000);
  }

  rowSelectionChanged(data: any, rows: any) {
    //rows - array of row components for the selected rows in order of selection
    //data - array of data objects for the selected rows in order of selection
    this.selectedRowData = data;
    let selectedLineItemIds: any [] = [];
    data.forEach((r:any) => {
      selectedLineItemIds.push(r.id);
    });
    this.lineItemsSelected.next(selectedLineItemIds);
  }

  // Note: For this to work, actions on cells must be specified as a
  // formatterParams property called 'action'.
  cellClicked(e: any, cell: any) {
    if (cell.getColumn().getDefinition()['formatterParams'] === null ||
        cell.getColumn().getDefinition()['formatterParams'] === undefined) {
      return;
    }
    let actionTaken: string = cell.getColumn().getDefinition()['formatterParams']['action'];

    // TODO: this will have to be extended to return multiple line item ids if
    // needed.
    let cellData = cell.getRow().getData();
    let lineItemId = cell.getRow().getData()['id'];

    //console.log(cellData);

    if (actionTaken != null) {
      if (actionTaken.toUpperCase() === "APL") {
        const payload = {
          lineItemIds: [lineItemId],
          action: 'APL'
        }
        this.lineItemActionRequested.next(payload);
      }
      if (actionTaken.toUpperCase() === "ADJ") {
        const payload = {
          lineItemIds: [lineItemId],
          action: 'ADJ'
        }
        this.lineItemActionRequested.next(payload);
      }
      if (actionTaken.toUpperCase() === "PRISTINE") {
        const payload = {
          lineItemIds: [lineItemId],
          action: 'PRISTINE'
        }
        this.lineItemActionRequested.next(payload);
      }

      if (actionTaken.toUpperCase() === "APE" && cellData['apeAdjusted']) {
        const payload = {
          lineItemIds: [lineItemId],
          action: 'APE'
        }
        this.lineItemActionRequested.next(payload);
      }

      if (actionTaken.toUpperCase() === "COMMENTS") {
        const payload = {
          lineItemIds: [lineItemId],
          action: 'COMMENTS'
        }
        this.lineItemActionRequested.next(payload);
      }

      if (actionTaken.toUpperCase() === "ACTIVITY_LOG") {
        const payload = {
          lineItemIds: [lineItemId],
          action: 'ACTIVITY_LOG'
        }
        this.lineItemActionRequested.next(payload);
      }      

      /* Line Item Edit */
      if (actionTaken.toUpperCase() === "REV_CODE") {
        const payload = {
          lineItemIds: [lineItemId],
          action: 'REV_CODE',
          mappedRevenueCode: cellData['mappedRevenueCode'],
          mappedRevenueCodeDescription: cellData['mappedRevenueCodeDescription'],
          description: cellData['description'],
          preExAdjustedAmount: cellData['preExAdjustedAmount'],
          preExExplanation: cellData['preExExplanation'],
          billPageNo: cellData['billPageNo'],
          reportExclude: cellData['reportExclude']
        }
        this.lineItemActionRequested.next(payload);
      }
    }

  }

  repositionToLineItem() {
    if (this.scrollToLineItemId < 0) {
      return;
    }

    this.tableRef.scrollToRow(this.scrollToLineItemId, "center", true)
    .then(() => {
      this.scrollToLineItemId = -1;
    })
    .catch(function(error: any) {
      console.log(error);
    });
  }

  generateCommentInfoDivHTML = () => {
    return "Comment Info Div Works!"
  }
}
