import {Component, Input, OnInit} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor, FormControl, FormGroup, FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from "@angular/forms";
import {DateUtil} from "../../util/date-util";
import {FennecSnackbarService} from "../../dialog/fennec-snackbar/fennec-snackbar.service";
import {Logger} from "../../util/logger";

const log = new Logger("DateInputComponent");

@Component({
  selector: 'lib-date-input',
  templateUrl: './date-input.component.html',
  styleUrl: './date-input.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DateInputComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: DateInputComponent
    }
  ]
})
export class DateInputComponent implements ControlValueAccessor, Validator, OnInit {

  touched = false;
  disabled = false;
  focused = false;
  firstInput = true;

  @Input()
  reactive = false;

  @Input()
  formGroup: FormGroup;

  customControl: FormControl;

  @Input()
  name: string;

  @Input()
  label: string = "";

  @Input()
  min = new Date(1900, 0, 1);

  @Input()
  max = new Date(2040, 0, 1);

  constructor(private snack: FennecSnackbarService) {
  }

  ngOnInit() {
    if (this.reactive && this.formGroup != null) {
      this.customControl = this.formGroup.get(this.name) as FormControl;
    }
  }

  _dateValue: Date | null = null;
  set dateValue(value: Date) {
    this._dateValue = value;
    this._stringValue = DateUtil.dateObjectToFennecDBString(value);
    this.onChange(this._stringValue);
  }
  get dateValue(): Date | null {
    return this._dateValue;
  }

  _stringValue: string | null = null;
  set stringValue(value: string) {
    this._stringValue = value;
    this._dateValue = DateUtil.parseDateStringToDateObject(value);
    this.onChange(this._stringValue);
  }
  get stringValue(): string | null {
    return this._stringValue;
  }

  set value(value: Date | string | null) {
    if (value == null) this.stringValue = null;
    if (value instanceof Date) {
      this.dateValue = value;
    } else if (typeof value === "string") {
      this.stringValue = value;
    } else {
      log.error("Attempted to set invalid value for DateInputComponent: " + JSON.stringify(value));
    }
  }
  get value(): string {
    return this._stringValue;
  }

  writeValue(obj: any): void {
    if (obj == null) {
      this.dateValue = null;
      return;
    }
    if (typeof obj === "string") {
      this.stringValue = obj;
    } else if (typeof obj === "object") {
      if (obj instanceof Date) {
        this.dateValue = obj;
      }
    } else {
      log.error("Attempted to write invalid value to DateInputComponent: " + JSON.stringify(obj));
    }
  }

  onChange = (value) => {};
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  onTouched = () => {};

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onFocus() {
    this.focused = true;
    if (!this.touched) {
      this.touched = true;
      this.onTouched();
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onValidatorChange = () => {};
  registerOnValidatorChange(fn: () => void): void {
    this.onValidatorChange = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.firstInput) return null;
    if (control instanceof FormGroup) return null;
    const value = control.value;
    if (value == null) return null;
    const dateValue = DateUtil.dbDateStringToDateObject(value);
    if (dateValue == null) return null;
    if (dateValue < this.min || dateValue > this.max) {
      return {invalid: true};
    }
    return null;
  }

  get errorState(): boolean {
    if (this.firstInput || !this.touched) return null;
    if (this.reactive && this.formGroup != null) {
      return this.formGroup.controls[this.name].errors != null;
    }
    return false;
  }

  onBlur = () => {
    this.firstInput = false;
    this.focused = false;
    this.dateValue = this.dateValue;
  }
}
