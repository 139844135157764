import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { BaseComponent, FennecSnackbarService, Logger, ValueCodePacket } from "xf-common";


@Component({
  selector: 'app-value-code-dialog',
  templateUrl: './value-code-dialog.component.html',
  styleUrls: ['./value-code-dialog.component.scss']
})
export class ValueCodeDialogComponent extends BaseComponent implements OnInit {

  mode?: "create" | "edit" | "view";
  valueCode?: ValueCodePacket;
  formGroup: FormGroup;

  protected log = new Logger("valueCodeDialogComponent");

  constructor(
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<ValueCodeDialogComponent>,
    override snack: FennecSnackbarService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    super();
    this.mode = this.data?.mode;
    this.valueCode = this.data?.valueCode;
    this.formGroup = new FormGroup({
      code: new FormControl(this.valueCode?.code ?? "", Validators.required),
      description: new FormControl(this.valueCode?.description ?? "", [Validators.required, Validators.maxLength(100)]),
    });
  }

  ngOnInit(): void {
  }

  submit() {
    const valueCode: ValueCodePacket = this.valueCode ?? new ValueCodePacket();
    const formValue = this.formGroup.value;
    if (!formValue.code) {
      super.showErrorSnack("Code is required");
      return;
    }
    if (!formValue.description) {
      super.showErrorSnack("Description required");
      return;
    }
    valueCode.code = formValue["code"];
    valueCode.description = formValue["description"];
    this.dialogRef.close(valueCode);
  }

  cancel() {
    this.dialogRef.close();
  }

}
