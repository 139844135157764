import { Deserializable, deserializeAssign } from "../deserializable";

export class ItemizedRevisionExportPacket implements Deserializable<ItemizedRevisionExportPacket> {
    
    itemizedRevisionId?:number;
    requestingUser?:string;
    miCaseId?:number;

    deserialize(input: any): ItemizedRevisionExportPacket {
        return deserializeAssign(this, input)
    }

    toJSON(): any {
        const obj: {[key: string]:any} = {}
        Object.assign(obj, this)
        delete obj["selected"]
        return obj
    }
}