import { Component, Inject, OnInit, Optional} from '@angular/core';
import {
  BaseComponent,
  FennecSnackbarService,
  Logger,
  UserGroupPacket
} from "xf-common";
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
  MatLegacyDialog as MatDialog
} from "@angular/material/legacy-dialog";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";


@Component({
  selector: 'app-user-group-dialog',
  templateUrl: './user-group-dialog.component.html',
  styleUrls: ['../../../../lib/styles/system-admin-styles.component.scss']
})
export class UserGroupDialogComponent extends BaseComponent implements OnInit {

  protected log = new Logger("UserGroupDialogComponent");
  mode?: "Create" | "Edit" | "View";
  userGroup?: UserGroupPacket;

  formGroup: FormGroup;

  userGroupTypes: string[] = [];

  constructor(
    protected dialog: MatDialog,
    @Optional() public dialogRef: MatDialogRef<UserGroupDialogComponent>,
    override snack: FennecSnackbarService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    super();
    this.mode = this.data?.mode;
    this.userGroup = this.data?.group;
    this.formGroup = new FormGroup({
      name: new FormControl(this.userGroup?.name ?? "", [Validators.required, Validators.maxLength(255)]),
      description: new FormControl(this.userGroup?.description ?? "", [Validators.maxLength(255)])
    });
  }

  ngOnInit(): void {
  }

  submit() {
    const userGroup: UserGroupPacket = this.userGroup ?? new UserGroupPacket();
    const formValue = this.formGroup.value;
    if (!formValue.name) {
      super.showErrorSnack("Name is required");
      return;
    }
    userGroup.name = formValue["name"];
    userGroup.description = formValue["description"];
    this.dialogRef.close(userGroup);
  }

  cancel() {
    this.dialogRef.close();
  }

}
