import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import {
  AddressComponent,
  BaseComponent,
  CompanyPacket,
  CompanyService,
  FennecSnackbarService,
  Logger
} from 'xf-common';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { Subject } from "rxjs";


@Component({
  selector: 'app-company-add',
  templateUrl: './company-add.component.html',
  styleUrls: ['./company-add.component.scss']
})
export class CompanyAddComponent extends BaseComponent implements OnInit, AfterViewInit {
  log: Logger = new Logger("ProviderAddComponent");
  formGroup!: FormGroup;

  @ViewChild('addressComponent')
  addressComponent?: AddressComponent;

  companySaveComplete: Subject<any> = new Subject();

  constructor(
    private companyService: CompanyService,
    protected dialogRef: MatDialogRef<CompanyAddComponent>,
    protected snack: FennecSnackbarService
  ) {
    super();
    this.formGroup = new FormGroup({
      name: new FormControl("", Validators.required),
      addressValidator: new FormControl()
    });
  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.findAddressComponent();
  }

  findAddressComponent() {
    if (this.addressComponent !== null && this.addressComponent !== undefined) {
      this.addressComponent?.addressSaveComplete?.subscribe((id: number) => {
        this.saveCompanyToServer(id);
      });
      this.addressComponent?.formGroup?.valueChanges?.subscribe(() => {
        this.validateAddress();
      })
    }
  }

  saveCompanyToServer(addressId: number){
    this.formGroup.markAllAsTouched();
    let packet = new CompanyPacket();
    packet.id = -1;
    packet.name = this.formGroup.controls["name"].value;
    packet.addressId = addressId;

    this.companyService.createCompany(packet).subscribe(response => {
      if (response.hasErrors) {
        super.showErrorSnack(response.consolidatedErrorMessage)
      } else {
        this.companySaveComplete.next(response.data);
        super.showSuccessSnack(`Successfully Created!`);
        let returnObj = {
          confirm: true
        }
        this.dialogRef.close(returnObj);
      }
    })
  }

  validateAddress(){
    let addressFormControl: FormGroup | undefined = this?.addressComponent?.formGroup;

    if(addressFormControl){
      if(!addressFormControl.valid){
        this.formGroup.controls['addressValidator'].setErrors({'incorrect':true});
      }else{
        this.formGroup.controls['addressValidator'].setErrors(null);
      }
    }
  }

  cancel() {
    let returnObj = {
      confirm: false
    };
    this.dialogRef.close(returnObj);
  }

  onSubmit() {
    this.addressComponent?.saveAddressToServer();
  }

}
