<div matDialogTitle>
  {{ this.mode }} user
</div>

<div matDialogContent>
  <form [formGroup]="formGroup">
    <mat-form-field>
      <mat-label>First Name</mat-label>
      <input matInput class="name" id="first-name" formControlName="firstName" required/>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Last Name</mat-label>
      <input matInput class="name" id="last-name" formControlName="lastName" required/>
    </mat-form-field>
  </form>
</div>

<div matDialogActions>
  <button mat-button color="accent" (click)="cancel()">
    Cancel
  </button>
  <button mat-button color="primary" (click)="submit()">
    Submit
  </button>
</div>
