import { Component, Inject, OnInit } from '@angular/core';
import { BaseComponent, Logger } from 'xf-common';
import { ItemizedRevisionService } from '../itemized-revision.service';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { FennecSnackbarService } from 'xf-common';
import { FormControl, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-itemized-revision-edit-dialog',
  templateUrl: './itemized-revision-edit-dialog.component.html',
  styleUrls: ['./itemized-revision-edit-dialog.component.scss']
})
export class ItemizedRevisionEditDialogComponent extends BaseComponent implements OnInit {

  protected override log: Logger = new Logger("ItemizedRevisionEditDialogComponent");

  iRevId: number = -1;
  revisionTypeList: any[] = [];
  loading:boolean = false;

  // Form Group to manage edit bindings.
  formGroup: FormGroup = new FormGroup({
    revisionType: new FormControl("", Validators.required),
    name: new FormControl("", Validators.required)
  });

  constructor(
    private itemizedRevisionService: ItemizedRevisionService,
    public dialogRef: MatDialogRef<ItemizedRevisionEditDialogComponent>,
    protected snack: FennecSnackbarService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    super();

    if (data?.revisionType) {
      this.formGroup.controls['revisionType'].setValue(data.revisionType);
    }
    if (data?.name) {
      this.formGroup.controls['name'].setValue(data.name);
    }
    if (data?.id) {
      this.iRevId = data.id;
    }
   }

  ngOnInit(): void {
    this.populateRevisionTypes();
  }

  populateRevisionTypes = () => {
    this.performXFRequest({
      requestDescription: "GET Revision Types",
      requestFn: this.itemizedRevisionService.getItemizedRevisionReviewTypeList,
      fnParams: [],
      onSuccess: data => {
        this.revisionTypeList = data;
      },
      onError: errString => {
        super.showErrorSnack(errString);
      }
    })
  }

  onSave() {
    const packet = {
      id: this.iRevId,
      itemizedRevisionReviewType: this.formGroup.controls['revisionType'].value,
      name: this.formGroup.controls['name'].value
    }

    this.performXFRequest({
      requestDescription: "UPDATE Itemized Revision",
      requestFn: this.itemizedRevisionService.updateItemizedRevision,
      fnParams: [packet],
      onSuccess: data => {
        super.showSuccessSnack("Itemized Revision updated successfully!");
        const returnObj = {
          confirm: true
        }
        this.dialogRef.close(returnObj);
      },
      onError: errString => {
        super.showErrorSnack(errString);
      }
    })
  }

  cancel() {
    const returnObj = {
      confirm: false
    }
    this.dialogRef.close(returnObj);
  }
}


