import { Deserializable, deserializeAssign } from "../deserializable";

export class CommentQueryParamsPacket implements Deserializable<CommentQueryParamsPacket> {

  relatedId?:number;
  commentRelationalType?:string;
  commentType?:string;
  lineItemUniqueRefId?:string;
  lineItemIds?:number[];

  deserialize(input: any): CommentQueryParamsPacket {
    return deserializeAssign(this, input);
  }

  toJSON(): any {
    const obj: { [key: string]: any } = {};
    Object.assign(obj, this);
    delete obj["selected"];
    return obj;
  }
}