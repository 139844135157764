import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { BaseComponent, DateUtil, FennecSnackbarService, Logger } from 'xf-common';
import { MiCaseReportElementsService } from '../mi-case-report-elements.service';


@Component({
  selector: 'app-mi-case-report-element-publish-date-edit-dialog',
  templateUrl: './mi-case-report-element-publish-date-edit-dialog.component.html',
  styleUrls: ['./mi-case-report-element-publish-date-edit-dialog.component.scss']
})
export class MiCaseReportElementPublishDateEditDialogComponent extends BaseComponent implements OnInit {

  protected override log: Logger = new Logger("MICaseReportElementPublishDateEditDialogComponent");
  
  formGroup: FormGroup;
  mode: "Provider" | "Client" | "Appeal" | "appealReceivedDateString"
  miCaseId: number = -1;

  reportPublishTypes = ["Provider", "Client", "Appeal"];

  constructor(
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<MiCaseReportElementPublishDateEditDialogComponent>,
    protected miCaseReportElementsService: MiCaseReportElementsService,
    override snack: FennecSnackbarService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    super();
    this.mode = this.data.mode;
    this.miCaseId = this.data.miCaseId;
    this.formGroup = new FormGroup({
      date: new FormControl(this.data.date ?? "", [Validators.required, Validators.pattern('^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\\d\\d$')]),
    });
   }

  ngOnInit(): void {
  }

  close() {
    const returnObj = {
      confirm: false
    }
    this.dialogRef.close(returnObj);
  }

  submit = () => {

    const date = DateUtil.inputDateStringToFennecDBDateString(this.formGroup.controls["date"].value);
    const packet = {
      miCaseId: this.miCaseId
    }
    if (this.reportPublishTypes.includes(this.mode) ) {
      packet[`earliest${this.mode}ReportPublishDateString`] = date;
    }else {
      packet[this.mode] = date;
    }
    

    this.performXFRequest({
      requestDescription: "GET Case Report Elements Info",
      requestFn: this.miCaseReportElementsService.updateMICaseReportElements,
      fnParams: [packet],
      onSuccess: data => {
        super.showSuccessSnack("Save Successful");
        this.dialogRef.close({confirm:true});
      },
      onError: errString => {
        super.showErrorSnack(errString);
      }
    });
  }
}
