import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseCrudService, Crud, DataResponse, PagedResponse, ValueCodePacket } from "xf-common";


@Injectable()
export class ValueCodeService extends BaseCrudService<ValueCodePacket> {
    baseEndpoint = super.apiEndpoint("value-code");

    constructor(public override httpClient: HttpClient) {
      super(httpClient, "ValueCodeService", "ValueCode");
    }

    getValueCode: () => Observable<DataResponse<ValueCodePacket>> = () => {
      const endpoint = `${this.baseEndpoint}`;
      return this.httpClient.get<DataResponse<ValueCodePacket>>(endpoint);
    }

    changeStatus = (diagnosisCodePacket: ValueCodePacket): Observable<DataResponse<ValueCodePacket>> => {
      const endpoint = `${this.baseEndpoint}/status`;
      return this.httpClient.put<DataResponse<ValueCodePacket>>(endpoint, diagnosisCodePacket);
    }

    _list: Crud.ListFunction<ValueCodePacket> = (first, max) => {
      const endpoint = `${this.baseEndpoint}/list`;
      return this.httpClient.get<PagedResponse<ValueCodePacket[]>>(endpoint, { params: { first, max } });
    };

    _getById: Crud.GetByIdFunction<ValueCodePacket> = (id) => {
      const endpoint = `${this.baseEndpoint}/${id}`;
      return this.httpClient.get<PagedResponse<ValueCodePacket>>(endpoint);
    }

    _create: Crud.CreateFunction<ValueCodePacket> = (user) => {
      const endpoint = `${this.baseEndpoint}/create`;
      return this.httpClient.post<PagedResponse<ValueCodePacket>>(endpoint, user);
    }

    _update: Crud.UpdateFunction<ValueCodePacket> = (user): Observable<DataResponse<ValueCodePacket>> => {
      const endpoint = `${this.baseEndpoint}/update`;
      return this.httpClient.put<PagedResponse<ValueCodePacket>>(endpoint, user);
    }

    _deactivate: Crud.DeactivateFunction<ValueCodePacket> = (ids) => {
      const endpoint = `${this.baseEndpoint}/deactivate`;
      return this.httpClient.put<DataResponse<boolean>>(endpoint, ids[0]);
    }

    _delete: Crud.DeleteFunction<ValueCodePacket> = (ids) => {
      const endpoint = `${this.baseEndpoint}/delete`;
      return this.httpClient.put<DataResponse<boolean>>(endpoint, ids);
    }

    protected _crudFunctions: Crud.CrudServiceDef<ValueCodePacket> = {
      _list: this._list,
      _getById: this._getById,
      _create: this._create,
      _update: this._update,
      _deactivate: this._deactivate,
      _delete: this._delete
    };

    getValueCodesByParams = (packet:any, first:number, max:number) => {
      const endpoint = `${this.baseEndpoint}/list?first=${first}&max=${max}`;
      return this.httpClient.post<PagedResponse<ValueCodePacket>>(endpoint, packet);
    }
  }
