import { AfterViewInit, Component } from '@angular/core';
import { BaseComponent } from '../../view/base.component';
import { FennecSnackbarService } from '../../dialog/fennec-snackbar/fennec-snackbar.service';
import { Logger } from '../../util/logger';
import { ActivatedRoute } from '@angular/router';
import { PatientService } from '../patient.service';

@Component({
  selector: 'lib-patient',
  templateUrl: './patient.component.html',
  styleUrl: './patient.component.scss'
})
export class PatientComponent extends BaseComponent implements AfterViewInit {
  protected override log: Logger = new Logger("PatientComponent");
  
  patientId = "-1";
  patientInfo:any = null;

  constructor(
    protected override snack: FennecSnackbarService,
    protected patientService: PatientService,
    protected route: ActivatedRoute,
  ) {
    super();
    this.patientId = this.route.snapshot.paramMap.get("id") ?? "-1";
  }

  ngAfterViewInit(): void {
    this.getData();
  }

  getData = () => {
    if (this.patientId != "-1") {
      const id = parseInt(this.patientId);
      if (id) {
        this.performXFRequest({
          requestDescription: "Get Patient Info",
          requestFn: this.patientService.getById,
          fnParams: [id],
          onSuccess: data => {
            this.patientInfo = data;
          },
          onError: errString => {
            super.showErrorSnack(errString);
          }
        })
      }
    }
  }

}
