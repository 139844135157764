<!-- <form [formGroup]="formGroup" class="add-comment-form">
  <div class="alt-theme">

    <div class="username-box">
      <h5>{{formattedUsername}}</h5>
    </div>
    <mat-form-field appearance="outline">
      <textarea matInput cdkTextareaAutosize rows="5" formControlName="commentText"></textarea>
    </mat-form-field>
  </div>

  <div class="add-comment-actions">
    <button
      [disabled]="!formGroup.valid"
      mat-raised-button
      color="accent"
      (click)="saveCommentToServer()"
      class="save-btn"
    >
      Save
    </button>

    <button
      mat-stroked-button
      (click)="cancelAdd()"
    >
      Cancel
    </button>
  </div>
</form> -->

<mat-card class="add-comment-card">
    <mat-card-header>
      <mat-card-title>
        {{mode === "CREATE" || mode === "MULTI" ? "Add" : "Edit"}}
         Comment
      </mat-card-title>
      
      <mat-card-subtitle class="alt-theme">
        {{formattedUsername}}
        <mat-form-field appearance="outline">
          <mat-label>Comment Type</mat-label>
          <mat-select [(ngModel)]="selectedCommentType">
            <mat-option *ngFor="let type of commentTypes" [value]="type">{{type}}</mat-option>
          </mat-select>
        </mat-form-field>
      </mat-card-subtitle>
    </mat-card-header>

  <mat-card-content>
    <form [formGroup]="formGroup" class="add-comment-form">
      <div class="alt-theme">
        <mat-form-field appearance="outline">
          <mat-label>Comment Text</mat-label>
          <textarea matInput cdkTextareaAutosize="false" rows="6" formControlName="commentText"></textarea>
        </mat-form-field>
      </div>
    
      <div class="add-comment-actions">
        <button
          [disabled]="manualValidation()"
          mat-raised-button
          color="accent"
          (click)="saveCommentToServer()"
          class="save-btn"
        >
          Save
        </button>
    
        <button
          mat-stroked-button
          (click)="cancelAdd()"
          *ngIf="!embeddedInDialog"
        >
          Cancel
        </button>
      </div>
    </form>
  </mat-card-content>
</mat-card>