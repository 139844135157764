import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { BaseComponent, FennecSnackbarService, Logger, SingleChoiceDialogComponent } from 'xf-common';
import { HealthCarePlanService } from '../health-care-plan.service';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import {
  HealthCarePlanRelationalDialogComponent
} from '../health-care-plan-relational-dialog/health-care-plan-relational-dialog.component';


@Component({
  selector: 'app-health-care-plan-relational-list',
  templateUrl: './health-care-plan-relational-list.component.html',
  styleUrls: ['./health-care-plan-relational-list.component.scss']
})
export class HealthCarePlanRelationalListComponent extends BaseComponent implements OnInit, AfterViewInit {

  log: Logger = new Logger("HealthCarePlanRelationalListComponent");

  @ViewChild(MatPaginator)
  paginator?: MatPaginator;

  dataSource = new MatTableDataSource<any>();
  totalRowCount?: number;
  defaultPageSize = 10;
  pageSizeOptions = [10, 20, 25, 50];

  displayColumns = ['name', 'state', 'active', 'actions'];
  userProfileDisplayColumns = ['companyName', 'name', 'state', 'actions']

  // mode needs to be Health Care Plan Relational Type
  @Input()
  mode: "COMPANY" | "USER_PROFILE" = "COMPANY";

  @Input()
  relatedId: string = "-1"

  constructor(
    public matDialog: MatDialog,
    protected snack: FennecSnackbarService,
    protected healthCarePlanService: HealthCarePlanService
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.mode === "USER_PROFILE") {
      this.displayColumns = this.userProfileDisplayColumns;
    }
    this.getData();
  }

  ngAfterViewInit(): void {
    if (this.paginator) {
      this.paginator.page.subscribe(() => {
        this.getData();
      })
    }
  }

  getData = () => {
    const packet = {
      relatedId: parseInt(this.relatedId),
      healthCarePlanRelationalType: this.mode
    }

    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;
    this.performXFRequest({
      requestDescription: "GET Tenant Companies",
      requestFn: this.mode == "USER_PROFILE" ? this.healthCarePlanService.getAllAssignedHealthCarePlansForUser  : this.healthCarePlanService.getHealthCarePlansByRelation,
      fnParams: [packet, first, pageSize],
      onResponse: (response) => {
        this.dataSource.data = response['data'];
        this.totalRowCount = response['totalRowCount'];

      },
      onComplete: () => {
        this.isLoading = false;
      },
      onError: (error) => {
        super.showErrorSnack(error);
      }
    });
  }

  getFormattedMode = () => {
    const formattedMode = this.mode;
    return formattedMode.slice(0, 1) + formattedMode.slice(1).toLowerCase();
  }

  openDialog = () => {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "900px";
    matDialogConfig.data = {
      mode: this.mode,
      relatedId: parseInt(this.relatedId)
    };
    const dialogRef = this.matDialog.open(HealthCarePlanRelationalDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe((response) => {
      if(response.confirm) {
        this.getData();
      }
    })
  }

  openDeactivateDialog = (plan:any) => {
    let dialogType = plan.relationalMapActive ? "deactivate" : "activate";

    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      question: `Are you sure you want to ${dialogType} '${plan.name}'?`
    };
    const dialogRef = this.matDialog.open(SingleChoiceDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe((response) => {
      if(response.confirm) {

        this.performXFRequest({
          requestDescription: "Toggle Status of Health Care Plan Relational Map",
          requestFn: this.healthCarePlanService.toggleActiveStatusOfHealthCarePlanRelationalMap,
          fnParams: [plan.relationalMapId],
          onSuccess: (data:any[]) => {
            super.showSuccessSnack("Request Successful");
            this.getData();
          },
          onComplete: () => {
            this.isLoading = false;
          },
          onError: (error) => {
            super.showErrorSnack(error);
          }
        });
      }
    })
  }

  deleteAllHCPlanRelationalMap() {
    // Option not available for COMPANY mode at this time.
    if (this.mode === "COMPANY") {
      return;
    }
    let relationTypeString = "User ID: " + this.relatedId;

    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      question : `Permamently remove all HC Plans from ${relationTypeString}?`,
      infoLine1 : ""
    };
    const dialogRef = this.matDialog.open(SingleChoiceDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {
        this.performXFRequest({
          requestDescription: "Delete All HC Plan Relationship",
          requestFn: this.healthCarePlanService.deleteALLHealthCarePlanRelationalMapForUser,
          fnParams: [parseInt(this.relatedId)],
          onSuccess: (data) => {
            this.getData();
          },
          onComplete: () => {
            this.isLoading = false;
          },
          onError: (error) => {
            super.showErrorSnack(error);
          }
        });
      }
    });
  }

}
