import { Component, Inject, OnInit } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { FennecSnackbarService, Logger } from "xf-common";


const log = new Logger("JSLInstanceParamViewDialogComponent");

@Component({
  selector: "app-jsl-instance-param-view-dialog-component",
  templateUrl: "./jsl-instance-param-view-dialog.component.html",
  styleUrls: ["./jsl-instance-param-view-dialog.component.scss"]
})
export class JSLInstanceParamViewDialogComponent implements OnInit {

  instanceParams: any [] = [];

  paramDisplayColumns = [
    'seqNo', 'paramType', 'paramValue'
  ];

  constructor(
    private dialogRef: MatDialogRef<JSLInstanceParamViewDialogComponent>,
    protected snack: FennecSnackbarService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.instanceParams = data.instanceParams;
  }

  ngOnInit() {

  }

  close() {
    const response = {
      confirm: false
    };
    this.dialogRef.close(response);
  }

}
