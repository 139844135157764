import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '../environment.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContactServiceService {

  private contactUrl = this.environment.baseApiUrl + "contact/";

  constructor(
    public httpClient: HttpClient,
    private environment: EnvironmentService
  ) { }

  getContactsByRelationalMapPacket = (packet:any, first:number, max:number): Observable<any> => {
    return this.httpClient.put(`${this.contactUrl}list?first=${first}&max=${max}`, packet);
  }

  createUpdateContactWithMap = (packet): Observable<any> => {
    return this.httpClient.post(`${this.contactUrl}crm`, packet);
  }

  deleteContactAndMap = (id): Observable<any> => {
    return this.httpClient.delete(`${this.contactUrl}crm/${id}`);
  }

  getContactTypes = (): Observable<any> => {
    return this.httpClient.get(`${this.contactUrl}contact-types`);
  }
}
