import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import {BaseComponent, CompanyService, Logger, PagedResponse} from 'xf-common';
import { FennecSnackbarService } from 'xf-common';
import {SelectionModel} from "@angular/cdk/collections";


@Component({
  selector: 'app-company-relational-type-add',
  templateUrl: './company-relational-type-add.component.html',
  styleUrls: ['./company-relational-type-add.component.scss']
})
export class CompanyRelationalTypeAddComponent extends BaseComponent implements OnInit, AfterViewInit {
  protected log = new Logger("CompanyRelationalMapAddComponent");

  companyRelationalType: string = "TENANT";
  companyType: string = "PRIMARY_CLIENT";
  formGroup!: FormGroup;

  @ViewChild(MatPaginator)
  paginator?: MatPaginator;
  totalRowCount?: number;
  defaultPageSize = 20;
  pageSizeOptions = [5, 10, 20, 25, 50];

  displayedColumns = ['select', 'id', 'name', 'address'];
  companyList: any [] = [];
  selection = new SelectionModel<any>(true, []);

  // Modes: NEW_TENANT, USER_TENANT
  mode: string = "NEW_TENANT";
  constructor(
    private companyService: CompanyService,
    public dialogRef: MatDialogRef<CompanyRelationalTypeAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    override snack: FennecSnackbarService,
  ) {
    super();
    this.formGroup = new FormGroup({
      searchCompanyName: new FormControl("")
    });

    if(this.data?.mode) {
      this.mode = this.data.mode;
    }

    if(this.data?.companyRelationalType) {
      this.companyRelationalType = this.data.companyRelationalType;
    }

    if(this.data?.companyType) {
      this.companyType = this.data.companyType;
    }
  }

  ngOnInit(): void {
    if(this.mode === "USER_TENANT") {
      this.getData();
    }
  }

  ngAfterViewInit(): void {
    if (this.paginator) {
      this.paginator.page.subscribe(() => {
        this.getData();
      })
    }
  }

  onSubmit() {
    let returnObj = {
      confirm : true,
      companyIds: this.selection.selected?.map(row => this.mode === 'NEW_TENANT' ? row.id : row.companyId),
      companyType: this.companyType,
      companyRelationalType: this.companyRelationalType
    };
    this.dialogRef.close(returnObj);
  }

  cancel() {
    let returnObj = {
      confirm : false
    };
    this.dialogRef.close(returnObj);
  }

  getData() {
    if(this.mode === "NEW_TENANT") {
      this.onCompanySearch();
    }

    if(this.mode === "USER_TENANT") {
      this.getTenantCompanies();
    }
  }

  onCompanySearch() {
    let searchCompanyName = this.formGroup.controls["searchCompanyName"].value;
    this.getCompanies(searchCompanyName);
  }

  getTenantCompanies() {
    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;

    this.performXFRequest({
      requestDescription: "GET all companies",
      requestFn: this.companyService.getAllTenantCompanies,
      fnParams: [first, pageSize],
      onResponse: (response: PagedResponse<any>) => {
        this.companyList = response.data;
        this.totalRowCount = response['totalRowCount'];
      },
      onError: errString => {
        super.showErrorSnack(errString)
      }
    });
  }

  /**
   * This gets all companies using a contains name search. Relevant to CRM mappings where you're
   * allowing the entire Company List to be valid candidates for relationship. (ex: TENANT / PRIMARY_CLIENT)
   */
  getCompanies(companyName: string) {
    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;
    this.performXFRequest({
      requestDescription: "GET all companies",
      requestFn: this.companyService.getAllCompanies,
      fnParams: [companyName, first, pageSize],
      onResponse: (response: PagedResponse<any>) => {
        this.companyList = response.data;
        this.totalRowCount = response['totalRowCount'];
      },
      onError: errString => {
        super.showErrorSnack(errString)
      }
    });
  }

  onSelect(row: any) {
    if (row == null) {
      return;
    }
    row.selected = !row.selected;

    this.selection?.toggle(row);
  }

}
