import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ConditionCodeService } from '../condition-code.service';
import { ConditionCode, FennecSnackbarService } from "xf-common";

@Component({
  selector: 'app-condition-code-delete-form',
  templateUrl: './condition-code-delete-form.component.html',
  styleUrls: ['./condition-code-delete-form.component.scss']
})
export class ConditionCodeDeleteFormComponent implements OnInit {
  mode: string = "NONE";
  code: ConditionCode;
  buttonValue?: string;

  constructor(
    private snack: FennecSnackbarService,
    public dialogRef: MatDialogRef<ConditionCodeDeleteFormComponent>,
    private codeService: ConditionCodeService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.mode = data.mode;
    this.code = data.code;
    if (this.mode == 'status') {
      this.buttonValue = this.code?.active ? "Deactivate" : "Activate";
    }
  }

  ngOnInit(): void {
  }

  onCancel() {
    this.dialogRef.close()
  }

  onDelete() {
    if (this.mode === 'delete') {
      this.codeService.destroy(this.code.id).subscribe(
        response => {
          if (response.hasErrors) {
            this.snack.showErrorSnack(response.consolidatedErrorMessage);
          } else {
            this.dialogRef.close()
          }
        }
      )
    } else if (this.mode ==='status') {
      this.code.active = !this.code.active
      this.codeService.deactivateConditionCode(this.code).subscribe(
        response => {
          if (response.hasErrors) {
            this.snack.showErrorSnack(response.consolidatedErrorMessage);
          } else {
            this.snack.showSuccessSnack("Success!");
            this.dialogRef.close()
          }
        }
      )
    }
  }
}
