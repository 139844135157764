import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { BaseComponent, FennecSnackbarService, Logger } from "xf-common";


@Component({
  selector: 'app-jsl-job-instance-error-dialog',
  templateUrl: './jsl-job-instance-error-dialog.component.html',
  styleUrls: ['./jsl-job-instance-error-dialog.component.scss']
})
export class JSLJobInstanceErrorDialogComponent extends BaseComponent implements OnInit {

  log: Logger = new Logger("ActivityDiaryDialogComponent");

  constructor(
  protected snack: FennecSnackbarService,
  @Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<JSLJobInstanceErrorDialogComponent>
  ) {
    super();
  }

  ngOnInit(): void {
  }

  onClose() {
    this.dialogRef.close();
  }

}
