import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import {
  BaseComponent,
  CompanyService,
  FennecSnackbarService,
  HealthCarePlanPacket,
  Logger,
  StatePacket,
  StateService
} from 'xf-common';
import { HealthCarePlanService } from '../health-care-plan.service';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-health-plan-dialog',
  templateUrl: './health-care-plan-dialog.component.html',
  styleUrls: ['./health-care-plan-dialog.component.scss']
})
export class HealthCarePlanDialogComponent extends BaseComponent implements OnInit, AfterViewInit {

  log: Logger = new Logger("HealthCarePlanDialogComponent");
  formGroup!: FormGroup;

  mode: "Create" | "Edit" = "Create";

  stateAutoCompleteOptions: StatePacket[] = [];

  companyRelationalMapList: any[] = [];

  constructor(
    private healthCarePlanService: HealthCarePlanService,
    private stateService: StateService,
    protected dialogRef: MatDialogRef<HealthCarePlanDialogComponent>,
    protected snack: FennecSnackbarService,
    protected companyService: CompanyService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    super();
    this.mode = this.data?.mode;
    this.formGroup = this.createFormGroup();

  }

  ngAfterViewInit(): void {
    if (this.mode == "Create") {
      this.getCompanyList();
    }
  }

  ngOnInit(): void {
  }

  cancel() {
    let returnObj = {
      confirm: false
    };
    this.dialogRef.close(returnObj);
  }

  submit() {
    const dto = new HealthCarePlanPacket();
    dto.id = this.data?.healthCarePlanPacket?.id ?? -1,
    dto.name = this.formGroup.controls['name'].value,
    dto.stateCode = this.formGroup.controls['stateCode'].value

    if (this.mode == "Create" && this.formGroup.controls['company'].value !== -1) {
      dto.companyId = this.formGroup.controls['company'].value
    }

    this.performXFRequest({
      requestDescription: `${this.mode} Health Care Plan`,
      requestFn: this.mode == "Create" ? this.healthCarePlanService._create : this.healthCarePlanService._update,
      fnParams: [dto],
      onResponse: response => {
        let returnObj = {
          confirm: true
        };
        this.dialogRef.close(returnObj);
      },
      onError: errString => {
        super.showErrorSnack(errString)
      }
    })
  }

  createFormGroup(): FormGroup {
    const newGroup = new FormGroup({
      name: new FormControl(this.data?.healthCarePlanPacket?.name ?? "", Validators.required),
      stateCode: new FormControl(this.data?.healthCarePlanPacket?.stateCode ?? "", Validators.required),
      company: new FormControl(-1)
    })

    newGroup.controls["stateCode"].valueChanges.subscribe(() => {
      this.searchStates();
    })

    return newGroup;
  }

  searchStates = () => {
    const formControl = this.formGroup.controls["stateCode"];

    if(!formControl.pristine) {
      if(formControl.value.length > 0) {
        const params = {
          stateCode: formControl.value
        }

        this.performXFRequest({
          requestDescription: "search states",
          requestFn: this.stateService.searchByParams,
          fnParams: [params, 0, 5],
          onResponse: response => {
            this.stateAutoCompleteOptions = response.data;
            // this.totalRowCount = response['totalRowCount'];
          },
          onError: errString => {
            super.showErrorSnack(errString)
          }
        })
      }
    }
  }

  getCompanyList() {
    this.performXFRequest({
      requestDescription: "GET Company List",
      requestFn: this.companyService.getActiveUserProfilePrimaryClientCompanies,
      fnParams: [0, 9999],
      onSuccess: data => {
        this.companyRelationalMapList = data;
      },
      onError: errString => {
        super.showErrorSnack(errString);
      }
    });
  }
}
