<lib-page>
  <ng-container pageTitle>
    <span> Tenant Companies </span>
    <button *ngIf="mode === 'USER_PROFILE'" class="delete-all-button" mat-flat-button (click)="deleteAllTenantCompanies()">
      <mat-icon>delete</mat-icon>
      Delete All
    </button>
    <button class="add" mat-flat-button (click)="addTenantCompany()">
      <mat-icon>add_circle</mat-icon>
      New
    </button>
  </ng-container>
  <ng-container pageContent>

    <mat-table [dataSource]="dataSource">

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
        <mat-cell *matCellDef="let company"> {{company.name}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="companytype">
        <mat-header-cell *matHeaderCellDef> Type </mat-header-cell>
        <mat-cell *matCellDef="let company"> {{company.companyType}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="active">
        <mat-header-cell *matHeaderCellDef> active </mat-header-cell>
        <mat-cell *matCellDef="let company">
          <span *ngIf="company.active" class="company-active">
            {{company.active}}
          </span>
          <span *ngIf="!company.active" class="company-inactive">
            {{company.active}}
          </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="activateactions">
        <mat-header-cell *matHeaderCellDef> activate actions </mat-header-cell>
        <mat-cell *matCellDef="let company">
          <span *ngIf="company.active" class="company-active">
            <button mat-flat-button color="primary"
              (click)="setInactive(company.companyRelationalMapId, company.name)">Set Inactive</button>
          </span>
          <span *ngIf="!company.active" class="company-inactive">
            <button mat-flat-button color="primary"
              (click)="setActive(company.companyRelationalMapId, company.name)">Set Active</button>
          </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="delete">
        <mat-header-cell *matHeaderCellDef> Delete </mat-header-cell>
        <mat-cell *matCellDef="let company">
          <button 
            mat-flat-button
            (click)="onDelete(company.companyRelationalMapId, company.name)"
            >
            <mat-icon>delete</mat-icon>
            Delete
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayColumns;"></mat-row>
    </mat-table>
    <mat-paginator [length]="totalRowCount" [pageSize]="defaultPageSize" [pageSizeOptions]="pageSizeOptions"></mat-paginator>
  </ng-container>
</lib-page>
