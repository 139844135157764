<mat-dialog-content>
    <h1 mat-dialog-title>Are You Sure You Want To {{ buttonValue ? buttonValue : 'Delete'}} {{ !shortenLabel ? "This" : "" }} {{ label }}?</h1>
</mat-dialog-content>

<mat-dialog-actions class="fennec-controls">
    <div>
      <button (click)="onSubmit()" mat-flat-button color="accent">
        {{mode === 'delete' ? 'Delete' : buttonValue }}
      </button>
        <button (click)="onCancel()" mat-flat-button color="primary">
          Cancel
        </button>
    </div>
</mat-dialog-actions>
