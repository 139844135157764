<div mat-dialog-title class="edi-run-mat-dialog-title">
    Cases associated with this EDI Run
  </div>

  <lib-page>
    <ng-container pageTitle>
        <!-- 40px == 2.463vw -->
  <div style="width: 99%; margin-top: 40px;">

    <mat-table [dataSource]="ediMICaseList" class="mat-elevation-z8"
      style="
      width: 90%;
      bottom: 90%;
      margin-left: 5%;
      border-collapse: collapse;
      box-shadow: 0 0 20px rgba(0, 0, 0, 100%);
      background-color: #f8f8f8;
      ">

      <ng-container matColumnDef="miCaseId">
        <mat-header-cell *matHeaderCellDef> Case </mat-header-cell>
        <mat-cell *matCellDef="let ad">
            <button class="edi-run-mat-cell-button-text" style="right: -21px;" mat-button mat-raised-button color="accent"  (click)="onClose()"
                    [routerLink]="['/mi-case/', ad.miCaseId]" routerLinkActive="active">
                <mat-icon [inline]="true" matListIcon>folder</mat-icon>
                Case {{ad.miCaseId}}
            </button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="ediRunId">
        <mat-header-cell *matHeaderCellDef> EDI Run ID </mat-header-cell>
        <mat-cell *matCellDef="let ad" class="edi-run-id">   {{ad.ediRunId}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="caseType">
        <mat-header-cell *matHeaderCellDef> Case Type </mat-header-cell>
        <mat-cell *matCellDef="let ad" class="edi-run-mi-case-type"> {{ad.caseType}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="createdBy">
        <mat-header-cell *matHeaderCellDef> Created By </mat-header-cell>
        <mat-cell *matCellDef="let ad" class="edi-run-created-by"> {{ad.createdBy}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="createdDate">
        <mat-header-cell *matHeaderCellDef> Created Date </mat-header-cell>
        <mat-cell *matCellDef="let ad" class="edi-run-created-date"> {{ad.createdDate | date: 'MM/dd/yyyy'}} </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayColumns;"></mat-row>
    </mat-table>
    <mat-paginator [length]="totalRowCount" [pageSize]="defaultPageSize" [pageSizeOptions]="pageSizeOptions"></mat-paginator>
  </div>
  <div >
  </div>
  <div matDialogActions class="fennec-controls"
  style="
  margin-top: -30px;
  display: flex;
  justify-content: center;
  ">
    <button (click)="onClose()" mat-flat-button color="primary">
      <mat-icon  [inline]="true">cancel</mat-icon> Close
    </button>
  </div>
  </ng-container>
  </lib-page>

