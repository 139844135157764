<ng-container matDialogContent>
  <h2>Add Health Care Plan</h2>

  <mat-table [dataSource]="healthCarePlanList">

    <ng-container matColumnDef="planName">
      <mat-header-cell *matHeaderCellDef> Plan Name </mat-header-cell>
      <mat-cell  *matCellDef="let plan"> 
        {{plan.name}}
       </mat-cell>
    </ng-container>  

    <ng-container matColumnDef="compnayName">
      <mat-header-cell *matHeaderCellDef> Company Name </mat-header-cell>
      <mat-cell  *matCellDef="let plan"> 
        {{plan.companyName}}
       </mat-cell>
    </ng-container> 

    <ng-container matColumnDef="select">
      <mat-header-cell *matHeaderCellDef>  </mat-header-cell>
      <mat-cell  *matCellDef="let plan"> 
        <button
          [disabled]="plan.id == selectedHealthCarePlanId"
          mat-stroked-button
          (click)="selectHealthCarePlan(plan.id)"
        >
          {{ plan.id == selectedHealthCarePlanId ? "Selected" : "Select" }}
        </button>
       </mat-cell>
    </ng-container>  

    <ng-container matColumnDef="address">
      <mat-header-cell *matHeaderCellDef> Address </mat-header-cell>
      <mat-cell  *matCellDef="let plan"> 
        {{plan.addressString}}
       </mat-cell>
    </ng-container> 

    <mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayColumns;"
    [ngClass]="{ hovered: row.hovered, highlighted: selectedHealthCarePlanId == row.id }"
    ></mat-row>
  </mat-table>

</ng-container>

<div matDialogActions class="fennec-dialog-action-button-container">
  <button mat-flat-button color="primary" (click)="closeSearchEvent.emit()">
    <mat-icon>cancel</mat-icon>
    Cancel
  </button>
  <button [disabled]="selectedHealthCarePlanId == -1" mat-flat-button color="accent" (click)="submit()">
    <mat-icon>save</mat-icon>
    Save
  </button>

  <mat-paginator [length]="totalRowCount" [pageSize]="defaultPageSize" [pageSizeOptions]="pageSizeOptions"> </mat-paginator>
</div>


