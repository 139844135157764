import { AfterViewInit, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { KeycloakService } from 'keycloak-angular';
import { ProcedureCodeDialogComponent } from '../procedure-code-dialog/procedure-code-dialog.component';
import { ProcedureCodeService } from '../procedure-code.service';
import { FormControl, FormGroup } from '@angular/forms';
import {
  BaseCrudComponent,
  CRUDDeleteStatusChangeDialogComponent,
  FennecSnackbarService,
  Logger,
  ProcedureCodePacket
} from "xf-common";


@Component({
  selector: 'app-procedure-code-list',
  templateUrl: './procedure-code-list.component.html',
  styleUrls: ['../../../../lib/styles/system-admin-styles.component.scss'],
})
export class ProcedureCodeListComponent extends BaseCrudComponent<ProcedureCodePacket, ProcedureCodeService> implements AfterViewInit {
  @ViewChild(MatPaginator)
  protected log = new Logger("ProcedureCodeListComponent");
  displayedColumns = ["code", "description", "active", "actions"];
  dialogRef?: MatDialogRef<any>
  @ViewChild("page", { read: ElementRef})
  element?: ElementRef
  pageSizeOptions = [10, 20, 25, 50]

  formGroup: FormGroup;

  constructor(
    protected override snack: FennecSnackbarService,
    protected procedureCodeService: ProcedureCodeService,
    protected dialog: MatDialog,
    private renderer: Renderer2,
    protected keycloakService: KeycloakService
  ) {
    super(procedureCodeService);
    this.canCreate = keycloakService.isUserInRole("ADMIN")
    this.canUpdate = keycloakService.isUserInRole("ADMIN")
    this.canDelete = keycloakService.isUserInRole("ADMIN")
    this.canDeactivate = keycloakService.isUserInRole("ADMIN")
    this.canView = keycloakService.isUserInRole("ADMIN")

    this.formGroup = this.createFormGroup();
   }

   ngAfterViewInit() {
       this.selection.changed.subscribe(change => this.selectedRow = change.added[0] ?? change.removed[0] ?? undefined)
       if (this.element?.nativeElement) {
        this.renderer.listen(this.element.nativeElement, "keyup", (event) => this.keyEvent(event))
       }
       if (this.paginator) {
          this.paginator.page.subscribe(() => {
          this.getList();
        })
       }

   }

  ngOnInit(): void {
    this.getList();
  }
   getList() {
    // const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    // const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;
    // this.paginator.pageIndex = first;
    // this.paginator.pageSize = pageSize;
    // this.list();
    this.executeListQuery();
   }

   onSelect = (row?: ProcedureCodePacket) => {
    if (row == null) {
      return;
    }
    row.selected = !row.selected;

    this.selection?.toggle(row);
  }

   keyEvent(event: KeyboardEvent) {
    const selected = this.selection?.selected
    switch (event.key) {
      case "c": this.onCreate();
      break
      case "e":
        if (this.selectedRow) {
          this.onEdit(this.selectedRow.id)
        }
        break
      case "d":
        this.onDelete(selected?.map(v => v.id))
        break
    }
   }

   onCreate() {
    const dialogConfig = new MatDialogConfig()
    dialogConfig.data = {
      mode: "create"
    }
    dialogConfig.autoFocus = "first-tabbable"
    this.dialogRef = this.dialog.open(ProcedureCodeDialogComponent, dialogConfig)
    this.dialogRef.afterClosed().subscribe((procedureCode?: ProcedureCodePacket) => {
      if (procedureCode) {
        this.create(procedureCode)
      }
    })
   }

   onDelete = (ids?: any[]) => {
    if (ids?.length === 1) {
      this.snack.showInfoSnack(`You deleted ${ids} from the list`);
      this.delete([ids[0]])
    } else if (ids?.length) {
      this.snack.showInfoSnack(`(Coming soon) Bulk delete the following: ${this.selection.selected.map(v => v.id).join(" ")}`);
    }
   }

   onEdit = (id?: number) => {
    if (!id) {
      return
    }
    this.getById(id).then(res => {
      if (res && res.data) {
        this.openEditDialog(res.data)
      }
    }).catch(err => super.showErrorSnack(err))
   }

   openEditDialog(procedureCode: ProcedureCodePacket) {
    if (procedureCode) {
      const dialogConfig = new MatDialogConfig()
      dialogConfig.data = {
        mode: "edit",
        procedureCode: procedureCode
      }
      dialogConfig.autoFocus = "first-tabbable"
      this.dialogRef = this.dialog.open(ProcedureCodeDialogComponent, dialogConfig)
      this.dialogRef?.afterClosed().subscribe({
        next: (value?: ProcedureCodePacket) => {
          if (value) {
            this.update(value)
          }
        }
      })
    }
   }

   openProcedureCodeStatusChangeDialog(procedureCodePacket: ProcedureCodePacket) {
    if (procedureCodePacket) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        mode: "status",
        source: procedureCodePacket,
        label: "Procedure Code"
      };
      this.dialogRef = this.dialog.open(CRUDDeleteStatusChangeDialogComponent, dialogConfig);
      this.dialogRef.afterClosed().subscribe({
        next: (value?: ProcedureCodePacket) => {
          if (value) {
            this.performXFRequest({
              requestDescription: "UPDATE procedure code status",
              requestFn: this.procedureCodeService.changeStatus,
              fnParams: [value],
              onSuccess: data => {
                super.showSuccessSnack("Successful!");
                this.list();
              },
              onError: errString => {
                super.showErrorSnack(errString);
              }
            })
          }
        }
      });
    }
  }

  createFormGroup = () => {
    return new FormGroup({
      code: new FormControl(),
      description: new FormControl(),
      active: new FormControl(true)
    })
  }

  clearSearch = () => {
    this.formGroup = this.createFormGroup();
  }

  executeListQuery = () => {
    const controls = this.formGroup?.controls;

    const searchParams = {
      code: controls["code"].value?.trim() ?? null,
      description: controls["description"].value?.trim() ?? null,
      active: controls["active"].value,
    }

    if(searchParams.active === "all") {
      searchParams.active = null;
    }

    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;
    this.performXFRequest({
      requestDescription: "GET Procedure Code By Search Params",
      requestFn: this.procedureCodeService.getProcedureCodesByParams,
      fnParams: [searchParams, first, pageSize],
      onResponse: response => {
        this.dataSource = response.data;
        this.totalRowCount = response['totalRowCount'];
      },
      onError: errString => {
        super.showErrorSnack(errString)
      }
    })
  }

  onSearch = () => {
    this.paginator?.firstPage();
    this.executeListQuery();
  }
}
