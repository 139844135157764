import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import {
  APERuleEventDefinitionPacket,
  BaseCrudService,
  BaseResponse,
  Crud,
  DataResponse,
  PagedResponse
} from "xf-common";

@Injectable()
export class ApeRuleEventDefinitionService extends BaseCrudService<APERuleEventDefinitionPacket> {
  public baseEndpoint = super.apiEndpoint("ape-rule-event-definition");

  constructor(
    public override httpClient: HttpClient
  ) {
    super(httpClient, "ApeRuleEventDefinitionService", "ApeRuleEventDefinitionService");
   }

  _list: Crud.ListFunction<APERuleEventDefinitionPacket> = (first, max) => {
    const endpoint = `${this.baseEndpoint}/list?first=${first}&max=${max}`;
    return this.httpClient.post<PagedResponse<APERuleEventDefinitionPacket[]>>(endpoint, { params: { first, max} })
  };

  _getById: Crud.GetByIdFunction<APERuleEventDefinitionPacket> = () => {
    return EMPTY;
  };

  _create: Crud.CreateFunction<APERuleEventDefinitionPacket> = (payload) => {
    const endpoint = `${this.baseEndpoint}/create-update`;
    return this.httpClient.post<DataResponse<APERuleEventDefinitionPacket>>(endpoint, payload);
  };
  _update: Crud.UpdateFunction<APERuleEventDefinitionPacket> = (payload) => {
    const endpoint = `${this.baseEndpoint}/create-update`;
    return this.httpClient.post<DataResponse<APERuleEventDefinitionPacket>>(endpoint, payload);
  };

  _deactivate: Crud.DeleteFunction<APERuleEventDefinitionPacket> = (id) => {
    const endpoint = `${this.baseEndpoint}/delete`;
    return this.httpClient.put<DataResponse<boolean>>(endpoint, id);
  };
  _delete: Crud.DeleteFunction<APERuleEventDefinitionPacket> = (id) => {
    const endpoint = `${this.baseEndpoint}/${id}`;
    return this.httpClient.delete<DataResponse<boolean>>(endpoint);
    // return EMPTY;
  };

  protected _crudFunctions: Crud.CrudServiceDef<APERuleEventDefinitionPacket> = {
    _list: this._list,
    _getById: this._getById,
    _create: this._create,
    _update: this._update,
    _deactivate: this._deactivate,
    _delete: this._delete
  };

  getAPERuleEventDefinitions = (queryParams: {}, first?: number, max?: number): Observable<PagedResponse<any>> => {
    return this.httpClient.put<PagedResponse<any>>(this.baseEndpoint + "/list?first=" + first + "&max=" + max, queryParams)
  }

  validateAPERuleEventDefinitionCode = (queryParams: {}): Observable<BaseResponse>  => {
    return this.httpClient.put<any>(this.baseEndpoint + "/validate-code", queryParams);
  }
}
