import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from "@angular/cdk/layout";
import { KeycloakService } from "keycloak-angular";
import { KeycloakUserInfo } from "./keycloak/keycloak-user-info";
import { UserProfileService } from './user/user-profile.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  title = 'ui';
  deviceIsMobile = false;
  username: string = "";
  userIsAdmin = false;
  userIsApeAdmin = false;
  userIsCrudAdmin = false;
  userIsSystemAdmin = false;
  userIsPatientRead = false;

  constructor(
    public breakpointObserver: BreakpointObserver,
    private keycloakService: KeycloakService,
    private userProfileService: UserProfileService
  ) { }

  ngOnInit(): void {
    const userRoles = this.keycloakService.getUserRoles();
    this.userIsAdmin = userRoles.includes("ADMIN");
    this.userIsApeAdmin = userRoles.includes("APE_ADMIN");
    this.userIsCrudAdmin = userRoles.includes("CRUD_ADMIN");
    this.userIsSystemAdmin = userRoles.includes("GLOBAL_SYSTEM_ADMIN");
    this.userIsPatientRead = userRoles.includes("PATIENT_READ") || this.userIsAdmin

    this.breakpointObserver
      .observe(Breakpoints.XSmall)
      .subscribe((state: BreakpointState) => {
        this.deviceIsMobile = state.matches;
      });

    this.keycloakService.getKeycloakInstance().loadUserInfo().then((result) => {
      let type: KeycloakUserInfo = <KeycloakUserInfo>result;
      this.username = type.given_name;
    });

    // Ping up to the Fennec server to get the UserProfile. We don't do anything with the data
    // returned here. We need to do this for new users added into Keycloak and not yet
    // added into the Fennec db. If the UserProfile on this call is not found, the back
    // end will automatically add the UserProfile record for the user. This is simple
    // housekeeping that is required for new users.
    // TODO: maybe make an EP that just does the new user add and does not return user
    // profile information.
    this.getUserProfile();

  }

  logout(){
    this.keycloakService.logout();
  }

  getUserProfile() {
    this.userProfileService.getUserProfile().subscribe((response: any) => {
    });
  }

}
