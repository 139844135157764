<lib-page>
  <ng-container pageTitle>
    <span> JSL Workbench </span>
    <button *ngIf="selectedTabIndex === 0" class="jsl-job-definition-add" mat-flat-button (click)="createNewJobDefinitionInDialog()">
      <mat-icon>add_circle</mat-icon>
      New Job Definition
    </button>
    <button *ngIf="selectedTabIndex === 1 && selectedJobDefinition !== null" class="jsl-job-definition-add" mat-flat-button 
      (click)="createNewJobDefinitionParamInDialog()">
      <mat-icon>add_circle</mat-icon>
      New Parameter
    </button>    
  </ng-container>
  <ng-container pageContent>
    <mat-tab-group #tabGroup [selectedIndex]="selectedTabIndex" (selectedTabChange)="onTabChanged($event)" color="accent">
      <mat-tab label="Job Definitions">
        <ng-template matTabContent>
          
          <!-- JSL Job Definitions-->
          <mat-table [dataSource]="jobDefinitions" class="job-definition-list-mat-table-wrapper">

            <!-- Id Column -->
            <ng-container matColumnDef="id">
              <mat-header-cell *matHeaderCellDef style="cursor: pointer;">
                Id
              </mat-header-cell>
              <mat-cell *matCellDef="let jobDef">
                {{jobDef.id}}
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="jslJobCode">
              <mat-header-cell *matHeaderCellDef> JSL Job Code </mat-header-cell>
              <mat-cell *matCellDef="let jobDef"> {{jobDef.jslJobCode}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef> JSL Job Name </mat-header-cell>
              <mat-cell *matCellDef="let jobDef"> {{jobDef.name}} </mat-cell>
            </ng-container>      

            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef>
                Actions
              </mat-header-cell>
              <mat-cell *matCellDef="let jobDef">
                <button mat-flat-button (click)="selectJSLJobDefinition(jobDef)">
                  Select
                </button>
                <button
                  mat-icon-button
                  [matMenuTriggerFor]="actionMenu"
                >
                  <mat-icon>more_vert</mat-icon>
                </button>

                <mat-menu #actionMenu="matMenu">

                  <button
                    mat-menu-item
                    (click)="openEditDialog(jobDef)"
                  >
                    <mat-icon>edit</mat-icon> Edit
                  </button>
                  <button
                    mat-menu-item
                    (click)="jslExecuteJobInDialog(jobDef)"
                  >
                    <mat-icon>workspaces_outline</mat-icon> Execute Job
                  </button>

                </mat-menu>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></mat-header-row>
            <mat-row
              *matRowDef="let row; columns: displayColumns;"
              [class.jsl-job-selected]="isSelectedJobDefinition(row.id)"
            >
            </mat-row>
          </mat-table>
          <mat-paginator #jobDefPaginator [length]="totalRowCount" [pageSize]="defaultPageSize" [pageSizeOptions]="pageSizeOptions"></mat-paginator>
        </ng-template>
      </mat-tab>
      <mat-tab label="Params">
        <ng-template matTabContent>
          <div *ngIf="selectedJobDefinition !== null" class="jsl-job-definition-header">
            Job Definition Name: {{selectedJobDefinition?.name}}
          </div>
          <div *ngIf="selectedJobDefinition === null" class="jsl-job-definition-header">
            Please select a Job Definition!
          </div>          
          <!-- Params -->
          <mat-table *ngIf="selectedJobDefinition !== null" [dataSource]="jobDefinitionParams" class="job-definition-list-mat-table-wrapper">

            <ng-container matColumnDef="seqNo">
              <mat-header-cell *matHeaderCellDef> Seq No </mat-header-cell>
              <mat-cell *matCellDef="let jobDefParam"> {{jobDefParam.seqNo}} </mat-cell>
            </ng-container> 
            
            <ng-container matColumnDef="paramType">
              <mat-header-cell *matHeaderCellDef> Type </mat-header-cell>
              <mat-cell *matCellDef="let jobDefParam"> {{jobDefParam.paramType}} </mat-cell>
            </ng-container> 
            
            <ng-container matColumnDef="paramPrompt">
              <mat-header-cell *matHeaderCellDef> User Prompt </mat-header-cell>
              <mat-cell *matCellDef="let jobDefParam"> {{jobDefParam.paramPrompt}} </mat-cell>
            </ng-container>            

            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef>
                Actions
              </mat-header-cell>
              <mat-cell *matCellDef="let jobDefParam">
                <button
                  mat-icon-button
                  [matMenuTriggerFor]="actionMenu"
                >
                  <mat-icon>more_vert</mat-icon>
                </button>

                <mat-menu #actionMenu="matMenu">
                  <button
                    mat-menu-item
                    (click)="openParamEditDialog(jobDefParam)"
                  >
                    <mat-icon>edit</mat-icon> Edit
                  </button>
                </mat-menu>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="paramDisplayColumns; sticky: true"></mat-header-row>
            <mat-row
              *matRowDef="let row; columns: paramDisplayColumns;"
            >
            </mat-row>
          </mat-table>

        </ng-template>
      </mat-tab>
      <mat-tab label="Instances">
        <ng-template matTabContent>
          <div *ngIf="selectedJobDefinition !== null" class="jsl-job-definition-header">
            Job Definition Name: {{selectedJobDefinition?.name}}
          </div>
          <div *ngIf="selectedJobDefinition === null" class="jsl-job-definition-header">
            Please select a Job Definition!
          </div>
          <mat-table [dataSource]="jobDefinitionInstances" class="job-definition-list-mat-table-wrapper">
            <ng-container matColumnDef="createdBy">
              <mat-header-cell *matHeaderCellDef> Created By </mat-header-cell>
              <mat-cell *matCellDef="let instance"> {{instance.createdBy}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="createdDate">
              <mat-header-cell *matHeaderCellDef> Created </mat-header-cell>
              <mat-cell *matCellDef="let instance"> {{instance.createdDate}} </mat-cell>
            </ng-container> 
            
            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
              <mat-cell *matCellDef="let instance"> {{instance.name}} </mat-cell>
            </ng-container>  
            
            <ng-container matColumnDef="jobExecutionStatus">
              <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
              <mat-cell *matCellDef="let instance">
                <ng-container *ngIf="instance.jobExecutionStatus !== 'ERROR'"> 
                  {{instance.jobExecutionStatus}} 
                </ng-container>
                <ng-container *ngIf="instance.jobExecutionStatus === 'ERROR'"> 
                  <span class="jsl-job-execution-status-error-link" (click)="openInstanceErrorDialog(instance.errorMessage)">
                    {{instance.jobExecutionStatus}}
                  </span> 
                </ng-container>                  
              </mat-cell>
            </ng-container>  
            
            <ng-container matColumnDef="elapsedSeconds">
              <mat-header-cell *matHeaderCellDef> Elapsed Secs </mat-header-cell>
              <mat-cell *matCellDef="let instance"> {{instance.elapsedSeconds}} </mat-cell>
            </ng-container>
            
            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef>
                Actions
              </mat-header-cell>
              <mat-cell *matCellDef="let instance">
                <button mat-flat-button (click)="selectInstance(instance)">
                  Select
                </button>
                <button
                  mat-icon-button
                  [matMenuTriggerFor]="actionMenu"
                >
                  <mat-icon>more_vert</mat-icon>
                </button>

                <mat-menu #actionMenu="matMenu">

                  <button
                    mat-menu-item
                    (click)="showInstanceParams(instance)"
                  >
                    Show Params
                  </button>

                </mat-menu>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="instanceDisplayColumns; sticky: true"></mat-header-row>
            <mat-row
              *matRowDef="let row; columns: instanceDisplayColumns;"
              [class.jsl-instance-selected]="isSelectedInstance(row.id)"
            >
            </mat-row>
            
          </mat-table>
          <mat-paginator #jobInstancePaginator [length]="totalJobInstanceRowCount" [pageSize]="defaultPageSize" [pageSizeOptions]="pageSizeOptions"></mat-paginator>

        </ng-template>
      </mat-tab>
      <mat-tab label="Instance Log (All)">
        <ng-template matTabContent>
          <mat-table [dataSource]="jobInstances" class="job-definition-list-mat-table-wrapper">
            <ng-container matColumnDef="createdBy">
              <mat-header-cell *matHeaderCellDef> Created By </mat-header-cell>
              <mat-cell *matCellDef="let instance"> {{instance.createdBy}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="createdDate">
              <mat-header-cell *matHeaderCellDef> Created </mat-header-cell>
              <mat-cell *matCellDef="let instance"> {{instance.createdDate}} </mat-cell>
            </ng-container> 
            
            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
              <mat-cell *matCellDef="let instance"> {{instance.name}} </mat-cell>
            </ng-container>  
            
            <ng-container matColumnDef="jobExecutionStatus">
              <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
              <mat-cell *matCellDef="let instance">
                <ng-container *ngIf="instance.jobExecutionStatus !== 'ERROR'"> 
                  {{instance.jobExecutionStatus}} 
                </ng-container>
                <ng-container *ngIf="instance.jobExecutionStatus === 'ERROR'"> 
                  <span class="jsl-job-execution-status-error-link" (click)="openInstanceErrorDialog(instance.errorMessage)">
                    {{instance.jobExecutionStatus}}
                  </span> 
                </ng-container>                  
              </mat-cell>
            </ng-container>  
            
            <ng-container matColumnDef="elapsedSeconds">
              <mat-header-cell *matHeaderCellDef> Elapsed Secs </mat-header-cell>
              <mat-cell *matCellDef="let instance"> {{instance.elapsedSeconds}} </mat-cell>
            </ng-container>             

            <ng-container matColumnDef="actions">
              <mat-header-cell *matHeaderCellDef>
                Actions
              </mat-header-cell>
              <mat-cell *matCellDef="let instance">
                <button mat-flat-button (click)="selectInstance(instance)">
                  Select
                </button>
                <button
                  mat-icon-button
                  [matMenuTriggerFor]="actionMenu"
                >
                  <mat-icon>more_vert</mat-icon>
                </button>

                <mat-menu #actionMenu="matMenu">

                  <button
                    mat-menu-item
                    (click)="showInstanceParams(instance)"
                  >
                    Show Params
                  </button>

                </mat-menu>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="instanceDisplayColumns; sticky: true"></mat-header-row>
            <mat-row
              *matRowDef="let row; columns: instanceDisplayColumns;"
              [class.jsl-instance-selected]="isSelectedInstance(row.id)"
            >
            </mat-row>
            
          </mat-table>
          <mat-paginator #instanceLogPaginator [length]="totalInstanceLogRowCount" [pageSize]="defaultPageSize" [pageSizeOptions]="pageSizeOptions"></mat-paginator>

        </ng-template>
      </mat-tab>
    </mat-tab-group>  
  </ng-container>
  
</lib-page>
