import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ItemizedLineItemService } from '../../itemized-line-item.service';
import { BaseComponent, FennecSnackbarService, Logger } from "xf-common";


@Component({
  selector: 'app-itemized-ape-rule-event-diary',
  templateUrl: './itemized-ape-rule-event-diary.component.html',
  styleUrls: ['./itemized-ape-rule-event-diary.component.scss']
})
export class ItemizedApeRuleEventDiaryComponent extends BaseComponent implements OnInit, AfterViewInit {

  protected log = new Logger("ItemizedApeRuleEventDiaryComponent");

  diaryEntries:any[] = [];
  itemizedLineItemId:number = -1;

  displayedColumns:string[] = ["apeRuleName", "apeRuleCode", "eventName", "eventCode", "adjustmentExplanationCode", "adjustmentReasonCode"];

  constructor(
    private itemizedLineItemService: ItemizedLineItemService,
    protected dialog: MatDialog,
    override snack: FennecSnackbarService,
    protected dialogRef: MatDialogRef<ItemizedApeRuleEventDiaryComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    super();
    this.itemizedLineItemId = data.itemizedLineItemId;
   }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    if(this.itemizedLineItemId < 1) {
      super.showErrorSnack("No Itemized Line Item ID Provided");
      return;
    }
    this.itemizedLineItemService.getApeRuleEventDiaryForItemizedLineItemUniqueRefId(this.itemizedLineItemId).subscribe((response) => {
      if(response.hasErrors) {
        super.showErrorSnack(response.consolidatedErrorMessage);
      }else{
        this.diaryEntries = response.data;
      }
    })


    // this.performXFRequest({
    //   requestDescription: "GET APE Rule Diary for Itemized Line Item",
    //   requestFn: this.itemizedLineItemService.getApeRuleEventDiaryForItemizedLineItemUniqueRefId,
    //   fnParams: [this.itemizedLineItemId],
    //   onResponse: response => {
    //     this.diaryEntries = response.data;
    //   },
    //   onError: errString => {
        // super.showErrorSnack(errString);
    //   }
    // })
  }

  closeDialog():void {
    this.dialogRef.close();
  }
}
