import { AfterViewInit, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { KeycloakService } from 'keycloak-angular';
import {
  AdjustmentReasonCodeDialogComponent
} from '../adjustment-reason-code-dialog/adjustment-reason-code-dialog.component';
import { AdjustmentReasonCodeService } from '../adjustment-reason-code.service';
import { FormControl, FormGroup } from '@angular/forms';
import {
  AdjustmentReasonCodePacket,
  BaseCrudComponent,
  CRUDDeleteStatusChangeDialogComponent,
  FennecSnackbarService,
  Logger
} from "xf-common";

@Component({
  selector: 'app-adjustment-reason-code-list',
  templateUrl: './adjustment-reason-code-list.component.html',
  styleUrls: ['../../../../lib/styles/system-admin-styles.component.scss'],
})
export class AdjustmentReasonCodeListComponent extends BaseCrudComponent<AdjustmentReasonCodePacket, AdjustmentReasonCodeService> implements AfterViewInit {

  protected log = new Logger("AdjustmentReasonCodeListComponent");
  displayedColumns = ["code", "description", "shortDescription", "type", "visibleOnDashboard", "internal", "active", "actions"];
  dialogRef?: MatDialogRef<any>
  @ViewChild("page", { read: ElementRef})
  element?: ElementRef
  pageSizeOptions = [10, 20, 25, 50]

  formGroup: FormGroup;
  filtersOpen: boolean = false;
  adjustmentReasonTypes: string[] = [];

  constructor(
    protected override snack: FennecSnackbarService,
    protected adjustmentReasonCodeService: AdjustmentReasonCodeService,
    protected dialog: MatDialog,
    private renderer: Renderer2,
    protected keycloakService: KeycloakService
  ) {
    super(adjustmentReasonCodeService);
    this.canCreate = keycloakService.isUserInRole("ADMIN");
    this.canUpdate = keycloakService.isUserInRole("ADMIN");
    this.canDelete = keycloakService.isUserInRole("ADMIN");
    this.canDeactivate = keycloakService.isUserInRole("ADMIN");
    this.canView = keycloakService.isUserInRole("ADMIN");

    this.formGroup = this.createFormGroup();
   }

   ngAfterViewInit() {
    this.selection.changed.subscribe(change => this.selectedRow = change.added[0] ?? change.removed[0] ?? undefined)
    if (this.element?.nativeElement) {
      this.renderer.listen(this.element.nativeElement, "keyup", (event) => this.keyEvent(event))
      }
      if (this.paginator) {
        this.paginator.page.subscribe(() => {
        this.executeListQuery()
      })
    }

   }

  ngOnInit(): void {
    this.executeListQuery();
    this.getAdjustmentReasonTypes();
  }

  // Old- Keep just in case
  //  getList() {
  //   const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
  //   const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;
  //   this.paginator.pageIndex = first;
  //   this.paginator.pageSize = pageSize;
  //   this.list();
  //  }

   onSelect = (row?: AdjustmentReasonCodePacket) => {
    if (row == null) {
      return;
    }
    row.selected = !row.selected;

    this.selection?.toggle(row);
  }

   keyEvent(event: KeyboardEvent) {
    if (this.filtersOpen) {
      return;
    }

    const selected = this.selection?.selected
    switch (event.key) {
      case "c": this.onCreate();
      break
      case "e":
        if (this.selectedRow) {
          this.onEdit(this.selectedRow.id)
        }
        break
      case "d":
        this.onDelete(selected?.map(v => v.id))
        break
    }
   }

   onCreate() {
    const dialogConfig = new MatDialogConfig()
    dialogConfig.data = {
      mode: "create",
      adjustmentReasonTypes: this.adjustmentReasonTypes
    }
    dialogConfig.autoFocus = "first-tabbable"
    this.dialogRef = this.dialog.open(AdjustmentReasonCodeDialogComponent, dialogConfig)
    this.dialogRef.afterClosed().subscribe((adjustmentReasonCode?: AdjustmentReasonCodePacket) => {
      if (adjustmentReasonCode) {
        this.create(adjustmentReasonCode).then(() => {
          this.executeListQuery();
        })
      }
    })
   }

   onDelete = (ids?: any[]) => {
    if (ids?.length === 1) {
      this.snack.showInfoSnack(`You deleted ${ids} from the list`);
      this.delete([ids[0]])
    } else if (ids?.length) {
      this.snack.showInfoSnack(`(Coming soon) Bulk delete the following: ${this.selection.selected.map(v => v.id).join(" ")}`);
    }
   }

   onEdit = (id?: number) => {
    if (!id) {
      return;
    }
    this.getById(id).then(res => {
      if (res && res.data) {
        this.openEditDialog(res.data);
      }
    }).catch(err => super.showErrorSnack(err));
   }

   openEditDialog(adjustmentReasonCode: AdjustmentReasonCodePacket) {
    if (adjustmentReasonCode) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        mode: "edit",
        adjustmentReasonCode: adjustmentReasonCode,
        adjustmentReasonTypes: this.adjustmentReasonTypes
      }
      dialogConfig.autoFocus = "first-tabbable"
      this.dialogRef = this.dialog.open(AdjustmentReasonCodeDialogComponent, dialogConfig);
      this.dialogRef?.afterClosed().subscribe({
        next: (value?: AdjustmentReasonCodePacket) => {
          if (value) {
            this.update(value, {onSuccess: () => this.executeListQuery(), override: true});
          }
        }
      });
    }
   }

   openAdjustmentReasonCodeStatusChangeDialog(adjustmentReasonCodePacket: AdjustmentReasonCodePacket) {
    if (adjustmentReasonCodePacket) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        mode: "status",
        source: adjustmentReasonCodePacket,
        label: "Adjustment Reason Code"
      };
      this.dialogRef = this.dialog.open(CRUDDeleteStatusChangeDialogComponent, dialogConfig);
      this.dialogRef.afterClosed().subscribe({
        next: (value?: AdjustmentReasonCodePacket) => {
          if (value) {
            this.performXFRequest({
              requestDescription: "UPDATE adjustment reason code status",
              requestFn: this.adjustmentReasonCodeService.changeStatus,
              fnParams: [value],
              onSuccess: data => {
                super.showSuccessSnack("Successful!");
                this.executeListQuery();
              },
              onError: errString => {
                super.showErrorSnack(errString);
              }
            })
          }
        }
      });
    }
  }

  createFormGroup = () => {
    return new FormGroup({
      code: new FormControl(),
      description: new FormControl(),
      adjustmentReasonType: new FormControl("all"),
      active: new FormControl(true)
    })
  }

  clearSearch = () => {
    this.formGroup = this.createFormGroup();
  }

  executeListQuery = () => {
    const controls = this.formGroup?.controls;

    const searchParams = {
      code: controls["code"].value?.trim() ?? null,
      description: controls["description"].value?.trim() ?? null,
      adjustmentReasonType: controls["adjustmentReasonType"].value?.trim() ?? null,
      active: controls["active"].value,
    }

    if(searchParams.active === "all") {
      searchParams.active = null;
    }

    if (searchParams.adjustmentReasonType == "all") {
      searchParams.adjustmentReasonType = null;
    }

    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;
    this.performXFRequest({
      requestDescription: "GET Adjustment Reason Code By Search Params",
      requestFn: this.adjustmentReasonCodeService.getAdjustmentReasonCodesByParams,
      fnParams: [searchParams, first, pageSize],
      onResponse: response => {
        this.dataSource = response.data;
        this.totalRowCount = response['totalRowCount'];
      },
      onError: errString => {
        super.showErrorSnack(errString)
      }
    })
  }

  onSearch = () => {
    this.paginator?.firstPage();
    this.executeListQuery();
  }

  getAdjustmentReasonTypes = () => {
    this.performXFRequest({
      requestDescription: "GET adjustment reason type",
      requestFn: this.adjustmentReasonCodeService.getAdjustmentReasonTypes,
      fnParams: [],
      onSuccess: data => {
        // super.showSuccessSnack("Successful!");
        // this.executeListQuery();
        this.adjustmentReasonTypes = data;
      },
      onError: errString => {
        super.showErrorSnack(errString);
      }
    })
  }
}
