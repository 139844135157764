<!-- <div #resizeBox class="container" style="position: relative;" [style.width.px]="width"
  [style.height.px]="height">
  <span #dragHandleCorner [cdkDragLockAxis]="lockAxis" class="dragHandle corner" cdkDrag (cdkDragMoved)="dragMove(dragHandleCorner, $event)"></span>
  <span #dragHandleRight cdkDragLockAxis="x" class="dragHandle right" cdkDrag (cdkDragMoved)="dragMove(dragHandleRight, $event)"></span>
  <span #dragHandleBottom cdkDragLockAxis="y" class="dragHandle bottom" cdkDrag (cdkDragMoved)="dragMove(dragHandleBottom, $event)"></span> -->


<div matDialogTitle cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="custom-mat-dialog-panel">
    Attachment List
</div>
<div matDialogContent class="custom-mat-dialog-panel" >
  <app-attachment #attachmentComponent 
  miCaseId="{{miCaseId}}" 
  showAttachmentList="true" 
  showUpload="false"
  headerString="Read only: Attachment" 
  context="MICASE"
  >
  <!-- context="{{data.context}}" -->
  </app-attachment>
</div>

<div matDialogActions class="fennec-controls">  
    <button (click)="onClose()" mat-flat-button color="primary">
        <mat-icon>cancel</mat-icon> Close
    </button>
  </div>

  
<!-- </div> -->
