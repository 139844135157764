import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { JslService } from "../jsl.service";
import { MatLegacyPaginator as MatPaginator } from "@angular/material/legacy-paginator";
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from "@angular/material/legacy-dialog";
import { JSLJobDefinitionDialogComponent } from "./jsl-job-definition-dialog/jsl-job-definition-dialog.component";
import {
  JSLJobDefinitionParamDialogComponent
} from "./jsl-job-definition-param-dialog/jsl-job-definition-param-dialog.component";
import { JSLExecuteJobDialogComponent } from "./jsl-execute-job-dialog/jsl-execute-job-dialog.component";
import {
  JSLJobInstanceErrorDialogComponent
} from "./jsl-job-instance-error-dialog/jsl-job-instance-error-dialog.component";
import {
  JSLInstanceParamViewDialogComponent
} from "./jsl-instance-param-view-dialog/jsl-instance-param-view-dialog.component";
import { BaseComponent, BaseResponse, FennecSnackbarService, JSLExecuteJobCPQUpdate, Logger } from "xf-common";


@Component({
  selector: "app-jsl-workbench",
  templateUrl: "./jsl-workbench.component.html",
  styleUrls: ["./jsl-workbench.component.scss"]
})
export class JslWorkbenchComponent extends BaseComponent implements AfterViewInit, OnInit {

  log: Logger = new Logger("JSLWorkbenchComponent");

  displayColumns = [
    'id', 'jslJobCode', 'name', 'actions'
  ];
  paramDisplayColumns = [
    'seqNo', 'paramType', 'paramPrompt', 'actions'
  ];
  instanceDisplayColumns = [
    'createdDate', 'createdBy', 'name', 'elapsedSeconds', 'jobExecutionStatus', 'actions'
  ];

  selectedTabIndex: number = 0;

  @ViewChild("jobDefPaginator")
  paginator?: MatPaginator;

  @ViewChild("jobInstancePaginator")
  jobInstancePaginator?: MatPaginator;

  @ViewChild("instanceLogPaginator")
  instanceLogPaginator?: MatPaginator;

  totalRowCount?: number;
  totalJobInstanceRowCount?: number;
  totalInstanceLogRowCount?: number;

  defaultPageSize = 20;
  pageSizeOptions = [10, 20, 25, 50];

  constructor(
    private jslService: JslService,
    public matDialog: MatDialog,
    protected snack: FennecSnackbarService
  ) {
    super();

    this.jslJobDefinitionFormGroup = new FormGroup({
      jslJobCode: new FormControl(),
      name: new FormControl()
    });

    this.jslJobDefinitionInstanceFormGroup = new FormGroup({
      jslJobDefinitionId: new FormControl(),
      jslJobCode: new FormControl(),
      name: new FormControl(),
      createdDate: new FormControl(),
      createdBy: new FormControl(),
      jobExecutionStatus: new FormControl(),
      elapsedSeconds: new FormControl(),
      errorMessage: new FormControl()
    });

  }

  ngAfterViewInit(): void {
    if (this.paginator) {
      this.paginator.page.subscribe(() => {
        this.getAllJobDefinitions();
      });
    }
  }

  jslJobDefinitionFormGroup: FormGroup;
  jslJobDefinitionInstanceFormGroup: FormGroup;

  jobDefinitions: any [] = [];
  jobDefinitionParams: any [] = [];
  jobDefinitionInstances: any [] = [];
  jobInstances: any [] = [];

  selectedJobDefinition: any = null;
  selectedParam: any = null;
  selectedInstance: any = null;

  ngOnInit() {
    this.getAllJobDefinitions();
    //this.getAllJobInstances();

    this.jslJobDefinitionFormGroup = new FormGroup({
      jslJobCode: new FormControl(),
      name: new FormControl()
    });

    this.jslJobDefinitionInstanceFormGroup = new FormGroup({
      jslJobDefinitionId: new FormControl(),
      jslJobCode: new FormControl(),
      name: new FormControl(),
      createdDate: new FormControl(),
      createdBy: new FormControl(),
      jobExecutionStatus: new FormControl(),
      elapsedSeconds: new FormControl(),
      errorMessage: new FormControl()
    });
  }

  isSelectedInstance(instanceId: number): boolean {
    if (this.selectedInstance === null || this.selectedInstance === undefined) {
      return false;
    } else {
      return this.selectedInstance.id === instanceId;
    }
  }

  isSelectedJobDefinition(jslJobDefinitionId: number): boolean {
    if (this.selectedJobDefinition === null || this.selectedJobDefinition === undefined) {
      return false;
    } else {
      return this.selectedJobDefinition.id === jslJobDefinitionId;
    }
  }

  getAllJobDefinitions() {
    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;
    this.jslService.getAllJSLJobDefinitions(first, pageSize).subscribe(
      (response: any) => {
        if (response.hasErrors) {
          this.snack.showErrorSnack(response.consolidatedErrorMessage);
        } else {
          this.jobDefinitions = response.data;
          this.totalRowCount = response['totalRowCount'];
        }
      });
  }

  createNewJobDefinitionInDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "40%";
    dialogConfig.height = "auto";
    dialogConfig.data = {};
    dialogConfig.data.mode = "Add";

    const dialogRef = this.matDialog.open(JSLJobDefinitionDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(response => {
      if (response.confirm) {
        this.addNewJobDefinition(response.jslJobCode, response.name);
      }
    });
  }

  addNewJobDefinition(jslJobCode: string, name: string) {
    this.jslService.addNewJSLJobDefinition(jslJobCode, name).subscribe(
      (response: BaseResponse) => {
        if (response.hasErrors) {
          this.snack.showErrorSnack(response.consolidatedErrorMessage);
        } else {
          this.getAllJobDefinitions();
        }
      });
  }

  selectJSLJobDefinition(jslJobDef: any) {
    this.selectedJobDefinition = jslJobDef;
    this.getJobDefinitionParamsForJobDefinition(this.selectedJobDefinition.id);
  }

  openEditDialog(jslJobDef: any) {
    this.selectJSLJobDefinition(jslJobDef);
    this.updateJobDefinitionDialog();
  }

  updateJobDefinitionDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "40%";
    dialogConfig.height = "auto";
    dialogConfig.data = {};
    dialogConfig.data.mode = "Modify";
    dialogConfig.data.jslJobCode = this.selectedJobDefinition.jslJobCode;
    dialogConfig.data.name = this.selectedJobDefinition.name;

    const dialogRef = this.matDialog.open(JSLJobDefinitionDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(response => {
      if (response.confirm) {
        this.updateJobDefinition(response.jslJobCode, response.name);
      }
    });
  }

  updateJobDefinition(jslJobCode: string, name: string) {
    this.jslService.updateJSLJobDefinition(this.selectedJobDefinition.id, jslJobCode, name).subscribe(
      (response: BaseResponse) => {
        if (response.hasErrors) {
          this.snack.showErrorSnack(response.consolidatedErrorMessage);
        } else {
          this.snack.showSuccessSnack("Successful!");
          this.getAllJobDefinitions();
        }
      });
  }

  getJobDefinitionParamsForJobDefinition(jobDefinitionId: number) {
    this.jslService.getJSLJobDefinitionParamsForJobDefinition(jobDefinitionId).subscribe(
      (response: BaseResponse) => {
        if (response.hasErrors) {
          this.snack.showErrorSnack(response.consolidatedErrorMessage);
        } else {
          this.jobDefinitionParams = response.data;
        }
      });
  }

  onTabChanged(event: any) {
    this.selectedTabIndex = event.index;

    // Wire up paginators on tab changes.
    if (this.instanceLogPaginator && !this.instanceLogPaginator.page.observed) {
      this.instanceLogPaginator.page.subscribe(() => {
        this.getAllJobInstances();
      });
    }
    if (this.jobInstancePaginator && !this.jobInstancePaginator.page.observed) {
      this.jobInstancePaginator.page.subscribe(() => {
        this.getJobInstancesForJobDefinition(this.selectedJobDefinition.id);
      });
    }

    if (event.tab !== null && event.tab !== undefined) {
      if (event.tab.textLabel.toString().includes("Instance Log")) {
        this.getAllJobInstances();
      } else if (event.tab.textLabel.toString().includes("Instances")) {
        this.getJobInstancesForJobDefinition(this.selectedJobDefinition.id);
      }
    }
  }

  createNewJobDefinitionParamInDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "40%";
    dialogConfig.height = "auto";
    dialogConfig.data = {};
    dialogConfig.data.mode = "Add";

    const dialogRef = this.matDialog.open(JSLJobDefinitionParamDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(response => {
      if (response.confirm) {
        this.addNewJobDefinitionParam(response.seqNo, response.paramType, response.paramPrompt);
      }
    });
  }

  addNewJobDefinitionParam(seqNo: number, paramType: any, paramPrompt: string) {
    if (this.selectedJobDefinition === null || this.selectedJobDefinition === undefined) {
      return;
    }

    this.jslService.addNewJSLJobDefinitionParam(seqNo, this.selectedJobDefinition.id, paramType, paramPrompt).subscribe(
      (response: BaseResponse) => {
        if (response.hasErrors) {
          this.snack.showErrorSnack(response.consolidatedErrorMessage);
        } else {
          this.getJobDefinitionParamsForJobDefinition(this.selectedJobDefinition.id);
        }
      });
  }

  openParamEditDialog(jobDefParam: any) {
    this.selectedParam = jobDefParam;
    this.updateJobDefinitionParamDialog();
  }

  updateJobDefinitionParamDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "40%";
    dialogConfig.height = "auto";
    dialogConfig.data = {};
    dialogConfig.data.mode = "Modify";
    dialogConfig.data.seqNo = this.selectedParam.seqNo;
    dialogConfig.data.paramType = this.selectedParam.paramType;
    dialogConfig.data.paramPrompt = this.selectedParam.paramPrompt;

    const dialogRef = this.matDialog.open(JSLJobDefinitionParamDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(response => {
      if (response.confirm) {
        this.updateJobDefinitionParam(response.seqNo, response.paramType, response.paramPrompt);
      }
    });
  }

  updateJobDefinitionParam(seqNo: number, paramType: any, paramPrompt: string) {
    if (
      this.selectedJobDefinition === null ||
      this.selectedJobDefinition === undefined ||
      this.selectedParam === null ||
      this.selectedParam === undefined
    ) {
      return;
    }

    this.jslService
      .updateJSLJobDefinitionParam(this.selectedParam.id, seqNo, this.selectedJobDefinition.id, paramType, paramPrompt)
      .subscribe(
        (response: BaseResponse) => {
          if (response.hasErrors) {
            this.snack.showErrorSnack(response.consolidatedErrorMessage);
          } else {
            this.getJobDefinitionParamsForJobDefinition(this.selectedJobDefinition.id);
          }
    });
  }

  jslExecuteJobInDialog(jobDef: any) {
    this.selectedJobDefinition = jobDef;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "40%";
    dialogConfig.height = "auto";
    dialogConfig.data = {};
    dialogConfig.data.jslJobDefinitionId = this.selectedJobDefinition.id;
    dialogConfig.data.name = this.selectedJobDefinition.name;
    dialogConfig.data.mode = "Execute";

    const dialogRef = this.matDialog.open(JSLExecuteJobDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(response => {
      if (response.confirm) {
        const payload = new JSLExecuteJobCPQUpdate();
        payload.jslJobDefinitionId = this.selectedJobDefinition.id;
        payload.paramValues = response.paramValues;
        this.executeJSLJob(payload);
      }
    });
  }

  executeJSLJob(payload: JSLExecuteJobCPQUpdate) {
    this.jslService.executeJSLJob(payload).subscribe(
      (response: BaseResponse) => {
        if (response.hasErrors) {
          this.snack.showErrorSnack(response.consolidatedErrorMessage);
        } else {
          this.snack.showSuccessSnack("Job submitted successfully!!");
          this.getAllJobInstances();
          this.getJobInstancesForJobDefinition(this.selectedJobDefinition.id);
        }
      });
  }

  getAllJobInstances() {
    const pageSize = !this.instanceLogPaginator?.pageSize ? this.defaultPageSize : this.instanceLogPaginator.pageSize;
    const first = this.instanceLogPaginator?.pageIndex ? this.instanceLogPaginator.pageIndex * pageSize : 0;
    this.jslService.getAllJSLJobInstances(first, pageSize).subscribe(
      (response: any) => {
        if (response.hasErrors) {
          this.snack.showErrorSnack(response.consolidatedErrorMessage);
        } else {
          this.jobInstances = response.data;
          this.totalInstanceLogRowCount = response['totalRowCount'];
        }
      });
  }

  getJobInstancesForJobDefinition(jobDefinitionId: number) {
    const pageSize = !this.jobInstancePaginator?.pageSize ? this.defaultPageSize : this.jobInstancePaginator.pageSize;
    const first = this.jobInstancePaginator?.pageIndex ? this.jobInstancePaginator.pageIndex * pageSize : 0;
    this.jslService.getJSLJobDefinitionInstancesForJobDefinition(jobDefinitionId, first, pageSize).subscribe(
      (response: any) => {
        if (response.hasErrors) {
          this.snack.showErrorSnack(response.consolidatedErrorMessage);
        } else {
          this.jobDefinitionInstances = response.data;
          this.totalJobInstanceRowCount = response['totalRowCount'];
        }
    });
  }

  openInstanceErrorDialog(errorMessage: string) {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      messageText: errorMessage,
    };
    const dialogRef = this.matDialog.open(JSLJobInstanceErrorDialogComponent, matDialogConfig);
  }

  selectInstance(instance: any) {
    this.selectedInstance = instance;
  }

  showInstanceParams(instance: any) {
    this.selectInstance(instance);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {};
    dialogConfig.data.instanceParams = this.selectedInstance.params;

    const dialogRef = this.matDialog.open(JSLInstanceParamViewDialogComponent, dialogConfig);
  }

  /*

  instanceSelectionChanged(event: any) {
    if (this.jobDefinitionInstances.items.length > 0) {
      this.selectedInstance = event.panel.rows[event.row].dataItem;
      this.jslJobDefinitionInstanceFormGroup.controls.jslJobDefinitionId.setValue(
        this.selectedInstance.jslJobDefinitionId
      );
      this.jslJobDefinitionInstanceFormGroup.controls.name.setValue(this.selectedInstance.name);
      this.jslJobDefinitionInstanceFormGroup.controls.jslJobCode.setValue(this.selectedInstance.jslJobCode);
    }
  }

  jslQueuedJobToCancelInDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "40%";
    dialogConfig.height = "auto";
    dialogConfig.data = {};
    dialogConfig.data.mode = "Cancel";
    dialogConfig.data.jslJobDefinitionId = this.selectedInstance.jslJobDefinitionId;
    dialogConfig.data.jslJobExecutionStatus = this.selectedInstance.jobExecutionStatus;
    dialogConfig.data.name = this.selectedInstance.name;

    const dialogRef = this.dialog.open(JSLUpdateQueuedJobToCancelledDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(response => {
      if (response.confirm) {
        this.updateQueuedJobtoCancelledInDialog(
          response.jslJobDefinitionId,
          response.name,
          response.jobExecutionStatus
        );
      }
    });
  }

  updateQueuedJobtoCancelledInDialog(jslJobDefinitionId: number, name: string, jobExecutionStatus: EnumerationService) {
    this.jslService
      .updateJSLQueuedJobToCancelled(this.selectedInstance.id, jslJobDefinitionId, jobExecutionStatus, name)
      .subscribe(
        (response: ServerClientBaseResponse) => {
          if (response.hasErrors) {
            super.showErrorSnack(response.consolidatedErrorMessage);
          } else {
            super.showSuccessSnack("Successful!", "OK");
            this.getAllJobInstances();
          }
        },
        err => {
          super.showErrorSnackSanitized(err, "OK");
        }
      );
  }

  // Fires when a tab is selected - in this case the Job Instance History tab
  tabSelected(tabPanel: WjTabPanel, event: any) {
    // if (tabPanel.selectedIndex === 0) {
    // }
    this.getAllJobInstances();
  }
 */
}
