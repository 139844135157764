<mat-dialog-content>
  <h3>Instance Parameters</h3>

  <mat-table [dataSource]="instanceParams" class="instance-param-table-wrapper">

    <ng-container matColumnDef="seqNo">
      <mat-header-cell *matHeaderCellDef> Seq No </mat-header-cell>
      <mat-cell *matCellDef="let instance"> {{instance.paramSeqNo}} </mat-cell>
    </ng-container> 
    
    <ng-container matColumnDef="paramType">
      <mat-header-cell *matHeaderCellDef> Type </mat-header-cell>
      <mat-cell *matCellDef="let instance"> {{instance.paramType}} </mat-cell>
    </ng-container> 
    
    <ng-container matColumnDef="paramValue">
      <mat-header-cell *matHeaderCellDef> Value </mat-header-cell>
      <mat-cell *matCellDef="let instance"> {{instance.paramValue}} </mat-cell>
    </ng-container>            

    <mat-header-row *matHeaderRowDef="paramDisplayColumns; sticky: true"></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: paramDisplayColumns;"
    >
    </mat-row>
  </mat-table>

</mat-dialog-content>
  
<mat-dialog-actions class="fennec-controls">
  <button type="button" mat-stroked-button (click)="close()">
      <mat-icon>cancel</mat-icon> Close
  </button>
</mat-dialog-actions>
