<div matDialogTitle>
    {{ this.mode }} occurrence code
  </div>
  
  <div matDialogContent>
    <form [formGroup]="formGroup" class="alt-theme" style="display:flex; flex-direction:column;">
      <mat-form-field appearance="outline">
        <mat-label>Code</mat-label>
        <input matInput class="name" id="code" formControlName="code" required maxlength="4"/>
        <mat-hint>{{formGroup.controls['code'].value.length}} of 4</mat-hint>
      </mat-form-field>
      <mat-form-field appearance="outline" [style.width.px]=450>
        <mat-label>Description</mat-label>
        <textarea matInput cdkTextareaAutosize rows="5" class="name" id="description" formControlName="description" required maxlength="100"></textarea>
        <mat-hint>{{formGroup.controls['description'].value.length}} of 100</mat-hint>
      </mat-form-field>
    </form>
  </div>
  
  <div matDialogActions class="fennec-dialog-action-button-container">
    <button mat-stroked-button (click)="cancel()">
      Cancel
    </button>
    <button [disabled]="formGroup.invalid" mat-flat-button color="accent" (click)="submit()">
      Submit
    </button>
  </div>
  