<span class="container">
  @if (buttonType == "icon") {
    <button
      class="button"
      mat-icon-button
      [color]="totalRowCount > 0 ? 'accent' : ''"
      (click)="openCommentDialog()">
      <mat-icon>comments</mat-icon>
    </button>
  }@else if (buttonType == "menuItem") {
    <button
      style="color:darkorange;"
      class="button"
      mat-menu-item
      color="accent"
      (click)="openCommentDialog()">

      <mat-icon *ngIf="totalRowCount > 0" color="accent">comments</mat-icon>
      <mat-icon *ngIf="totalRowCount <= 0">comments</mat-icon>  
      View Comments <span *ngIf="totalRowCount > 0">[{{totalRowCount}}]</span>
    </button>
  }@else {
    <button
      class="button"
      mat-raised-button
      color="accent"
      (click)="openCommentDialog()">
      <mat-icon>comments</mat-icon> View Comments
    </button>
  }
  <div class="comment-info-div">
    @if (loading) {
      <div class="loading-spinner-div">
        <mat-spinner color="accent"></mat-spinner>
      </div>
    }@else if (totalRowCount <= 0) { 
      <div style="margin-top: 30%">
      No comments available!    
      </div>
    }@else {
      <div>
        <p>
          {{ totalRowCount }} Comment{{ totalRowCount != 1 ? "s" : ""}}
        </p>
    
        @for (comment of commentList; track comment) {
          <p>
            <span style="color: blue">
              {{(comment.createdDate | date: 'MM/dd/yyyy h:mm a') + (comment.createdDate !== comment.lastModifiedDate ? ' (edited: ' + (comment.lastModifiedDate | date: 'M/dd/yyyy h:mm a') + ')' : '')}}
            </span>
    
            <span>
              <b>{{ comment.authorFirstName }} {{ comment.authorLastName }}</b>
            </span>

            <br><br>
            {{comment.commentText | slice:0:250}}
            @if (comment.commentText.length > 250) {
              <b><span style="font-size: small">...more</span></b>
            }
          </p>
        }
    
        @if (totalRowCount > 3) {
          <span>...{{ totalRowCount - 3 }} more</span>
        }
      </div>
    }
  </div>
</span>