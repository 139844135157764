import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { KeycloakService } from "keycloak-angular";
import { PatientRelationshipCodeService } from '../patient-relationship-code.service';
import {
  PatientRelationshipCodeDialogComponent
} from '../patient-relationship-code-dialog/patient-relationship-code-dialog.component';
import { FormControl, FormGroup } from '@angular/forms';
import {
  BaseCrudComponent,
  CRUDDeleteStatusChangeDialogComponent,
  DiagnosisCodePacket,
  FennecSnackbarService,
  Logger,
  PatientRelationshipCodePacket
} from "xf-common";


@Component({
  selector: 'app-patient-relationship-code-list',
  templateUrl: './patient-relationship-code-list.component.html',
  styleUrls: ['../../../../lib/styles/system-admin-styles.component.scss']
})
export class PatientRelationshipCodeListComponent extends BaseCrudComponent<PatientRelationshipCodePacket, PatientRelationshipCodeService> implements AfterViewInit, OnInit {
  displayedColumns = ["code", "description", "active", "actions"];

  dialogRef?: MatDialogRef<any>;

  @ViewChild("page", { read: ElementRef })
  element?: ElementRef;

  protected log = new Logger("PatientRelationshipCodeListComponent");

  formGroup: FormGroup;
  filtersOpen: boolean = false;

  constructor(
    protected override snack: FennecSnackbarService,
    protected patientRelationshipCodeService: PatientRelationshipCodeService,
    protected dialog: MatDialog,
    private renderer: Renderer2,
    protected keycloakService: KeycloakService
  ) {
    // super(new Logger("UserListComponent"), snack, patientRelationshipCodeService);
    super(patientRelationshipCodeService);
    this.canCreate = keycloakService.isUserInRole("ADMIN");
    this.canUpdate = keycloakService.isUserInRole("ADMIN");
    this.canDelete = keycloakService.isUserInRole("ADMIN");
    this.canDeactivate = keycloakService.isUserInRole("ADMIN");
    this.canView = keycloakService.isUserInRole("ADMIN");

    this.formGroup = this.createFormGroup();
  }

  ngOnInit() {
    this.executeListQuery();
  }

  ngAfterViewInit(): void {
    this.selection.changed.subscribe(change => this.selectedRow = change.added[0] ?? change.removed[0] ?? undefined);
    if (this.element?.nativeElement) {
      this.renderer.listen(this.element.nativeElement, "keyup", (event) => this.keyEvent(event));
    }
    if (this.paginator) {
      this.paginator.page.subscribe(() => {
        this.executeListQuery();
      })
    }
  }

  // executeListQuery() {
  //   const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
  //   const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;
  //   this.paginator.pageIndex = first;
  //   this.paginator.pageSize = pageSize;
  //   this.list();
  // }

  onCreate(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      mode: "Create"
    };
    dialogConfig.autoFocus = "first-tabbable";
    dialogConfig.width = "35%";
    this.dialogRef = this.dialog.open(PatientRelationshipCodeDialogComponent, dialogConfig);
    this.dialogRef.afterClosed().subscribe((value?: PatientRelationshipCodePacket) => {
      if (value) {
        this.create(value).then(() => {this.executeListQuery()});
      }
    });
  }

  onEdit = (id?: number) => {
    if (!id) {
      return;
    }
    this.getById(id)
      .then(response => {
        if (response && response.data) {
          this.openEditDialog(response.data)
        }
      })
      .catch(error => super.showErrorSnack(error));
  }

  onDelete = (ids: any[]) => {
    if (ids.length == 1) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        mode: "delete",
        source: ids,
        label: "Patient Relationship Code"
      };
      this.dialogRef = this.dialog.open(CRUDDeleteStatusChangeDialogComponent, dialogConfig);
      this.dialogRef.afterClosed().subscribe({
        next: (value?: number[]) => {
          if (value) {
            this.performXFRequest({
              requestDescription: "Delete patient relationship code",
              requestFn: this.patientRelationshipCodeService._delete,
              fnParams: [value],
              onSuccess: data => {
                super.showSuccessSnack("Successful!");
                this.executeListQuery();
              },
              onError: errString => {
                super.showErrorSnack(errString);
              }
            })
          }
        }
      });
    }
  }

  openRelationshipChangeDialog(patientRelationshipCodePacket: PatientRelationshipCodePacket) {
    if (patientRelationshipCodePacket) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        mode: "status",
        source: patientRelationshipCodePacket,
        label: "Patient Relationship Code"
      };
      this.dialogRef = this.dialog.open(CRUDDeleteStatusChangeDialogComponent, dialogConfig);
      this.dialogRef.afterClosed().subscribe({
        next: (value?: DiagnosisCodePacket) => {
          if (value) {
            this.performXFRequest({
              requestDescription: "UPDATE patient relationship code status",
              requestFn: this.patientRelationshipCodeService.changeStatus,
              fnParams: [value],
              onSuccess: data => {
                super.showSuccessSnack("Successful!");
                this.executeListQuery();
              },
              onError: errString => {
                super.showErrorSnack(errString);
              }
            })
          }
        }
      });
    }
  }

  onSelect(row: PatientRelationshipCodePacket | undefined) {
    if (row == null) {
      return;
    }
    row.selected = !row.selected;

    this.selection?.toggle(row);
  }

  keyEvent(event: KeyboardEvent) {
    if (this.filtersOpen) {
      return;
    }

    const selected = this.selection?.selected;

    switch (event.key) {
      case "c":
        this.onCreate();
        break;
      case "e":
        if (this.selectedRow) {
          this.onEdit(this.selectedRow.id);
        }
        break;
      case "d":
        this.onDelete(selected?.map(v => v.id));
        break;
    }
  }

  openEditDialog(patientRelationshipCode: PatientRelationshipCodePacket) {
    if (patientRelationshipCode) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        mode: "Edit",
        patientRelationshipCode: patientRelationshipCode
      };
      dialogConfig.autoFocus = "first-tabbable";
      dialogConfig.width = "35%";
      this.dialogRef = this.dialog.open(PatientRelationshipCodeDialogComponent, dialogConfig);
      this.dialogRef.afterClosed().subscribe({
        next: (value?: PatientRelationshipCodePacket) => {
          if (value) {
            this.update(value).then(() => {this.executeListQuery()});
          }
        }
      });
    }
  }

  createFormGroup = () => {
    return new FormGroup({
      code: new FormControl(),
      description: new FormControl(),
      active: new FormControl(true)
    })
  }

  clearSearch = () => {
    this.formGroup = this.createFormGroup();
  }

  executeListQuery = () => {
    const controls = this.formGroup?.controls;

    const searchParams = {
      code: controls["code"].value?.trim() ?? null,
      description: controls["description"].value?.trim() ?? null,
      active: controls["active"].value,
    }

    if(searchParams.active === "all") {
      searchParams.active = null;
    }

    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;
    this.performXFRequest({
      requestDescription: "GET Patient Relationship Code By Search Params",
      requestFn: this.patientRelationshipCodeService.getPatientRelationshipCodesByParams,
      fnParams: [searchParams, first, pageSize],
      onResponse: response => {
        this.dataSource = response.data;
        this.totalRowCount = response['totalRowCount'];
      },
      onError: errString => {
        super.showErrorSnack(errString)
      }
    })
  }

  onSearch = () => {
    this.paginator?.firstPage();
    this.executeListQuery();
  }
}
