<h3 matDialogTitle>
  Health Care Plans for APE Rule Filter '{{apeRuleFilter?.name}}'

  <button
    mat-icon-button
    style="float:right; color:black;"
    (click)="closeDialog()"
    >
    <mat-icon>close</mat-icon>
  </button>
</h3>

<ng-container *ngIf="mode === 'VIEW'">

  <mat-card *ngIf="this.companyList.length == 0 && !isLoading">
    <mat-card-title>
      No Plans Found For APE Rule Filter '{{apeRuleFilter?.name}}'
    </mat-card-title>
    <mat-card-content>
      <button
        mat-stroked-button
        (click)="setModeToSearch()"
      >
        <mat-icon>add-circle</mat-icon>
        Add New Health Care Plan
      </button>
    </mat-card-content>
  </mat-card>


  <mat-table [dataSource]="this.companyList" *ngIf="this.companyList.length > 0">

    <ng-container matColumnDef="companyName">
      <mat-header-cell *matHeaderCellDef>Company Name</mat-header-cell>
      <mat-cell *matCellDef="let filterHealthCarePlan">{{filterHealthCarePlan.healthCarePlan?.companyName}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="planName">
      <mat-header-cell *matHeaderCellDef>Plan Name</mat-header-cell>
      <mat-cell *matCellDef="let filterHealthCarePlan">{{filterHealthCarePlan.healthCarePlan?.name}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="active">
      <mat-header-cell *matHeaderCellDef>Active</mat-header-cell>
      <mat-cell *matCellDef="let filterHealthCarePlan">
        <mat-icon>
          {{filterHealthCarePlan?.active ? "check_circle" : "error_outline"}}
        </mat-icon>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef>
        <button 
          class="add" 
          mat-button
          (click)="setModeToSearch()"
          color="accent"
          >
          <mat-icon>add_circle</mat-icon>
          New
        </button>
      </mat-header-cell>

      <mat-cell *matCellDef="let filter">
        <button
          mat-stroked-button
          (click)="deletePrompt(filter)"
        >
          <mat-icon>delete</mat-icon> Delete
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="select">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let filterHealthCarePlan">
        <button
          mat-stroked-button
          color="accent"
        >
          Select
        </button>
      </mat-cell>
    </ng-container>
    
    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: displayedColumns; let i=index"
      [tabindex]="i + 1"
      [attr.data-index]="i"
      [ngClass]="{ hovered: row.hovered }"
      (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"
    ></mat-row>
  </mat-table>

  <div matDialogActions class="fennec-actions" style="float:right;">
    <mat-paginator class="alt-theme" [length]="totalRowCount" [pageSize]="defaultPageSize" [pageSizeOptions]="defaultPageSizeOptions"></mat-paginator>
  </div>
</ng-container>

<ng-container *ngIf="mode === 'SEARCH'">
  <app-add-ape-rule-filter-health-care-plan-dialog #addComponent (closeSearchEvent)="setModeToView()" [apeRuleFilterId]="apeRuleFilter?.id ?? -1"></app-add-ape-rule-filter-health-care-plan-dialog>
</ng-container>