import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { BaseComponent, FennecSnackbarService, Logger } from "xf-common";

@Component({
  selector: 'app-edi-run-attachment-dialog',
  templateUrl: './edi-run-attachment-dialog.component.html',
  styleUrls: ['./edi-run-attachment-dialog.component.scss']
})
export class EdiRunAttachmentDialogComponent extends BaseComponent implements OnInit {


  protected log = new Logger("EDIRunAttachmentDialog");
  constructor(
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<EdiRunAttachmentDialogComponent>,
    override snack: FennecSnackbarService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super();
  }

  ngOnInit(): void {
  }

}
