import { Deserializable, deserializeAssign } from "../deserializable";

export class APERule implements Deserializable<APERule> {

  id?: number;
  apeRuleType?: string;
  name?: string;
  description?: string;
  serviceIdentificationCode?: string;

  deserialize(input: any): APERule {
    return deserializeAssign(this, input);
  }

  toJSON(): any {
    const obj: { [key: string]: any } = {};
    Object.assign(obj, this);
    delete obj["selected"];
    return obj;
  }
}