<h2 mat-dialog-title>
  Edit Itemized Revision
</h2>

<mat-dialog-content>
    <div>
      <form [formGroup]="formGroup" class="create-form alt-theme">
        <mat-form-field appearance="outline">
          <mat-label>Name</mat-label>
          <input matInput formControlName="name" required/>
        </mat-form-field>
				<mat-form-field appearance="outline">
          <mat-label>Revision Type</mat-label>
          <mat-select formControlName="revisionType">
						<mat-option *ngFor="let type of revisionTypeList" [value]="type">
							{{ type }}
						</mat-option>
					</mat-select>
        </mat-form-field> 
      </form>
    </div>
</mat-dialog-content>

<mat-dialog-actions class="fennec-controls">
	<button 
		mat-flat-button
    [disabled]="!formGroup.valid || loading" 
		(click)="onSave()" 
		color="accent">
			<mat-icon>check_circle</mat-icon> Save
	</button>

	<button 
		mat-button 
		mat-stroked-button 
		(click)="cancel()">
			<mat-icon>cancel</mat-icon> Cancel
	</button>
</mat-dialog-actions>