import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { BaseCrudService, BaseResponse, Crud, DataResponse, PagedResponse, UserProfilePacket } from "xf-common";

@Injectable()
export class UserProfileService extends BaseCrudService<UserProfilePacket> {
  baseEndpoint = super.apiEndpoint("user-profile");

  constructor(public override httpClient: HttpClient) {
    super(httpClient, "UserProfileService", "User");
  }

  getUserProfile: () => Observable<DataResponse<UserProfilePacket>> = () => {
    const endpoint = `${this.baseEndpoint}`;
    return this.httpClient.get<DataResponse<UserProfilePacket>>(endpoint);
  }

  getUserProfileMICaseVisibilityInfo(): Observable<BaseResponse> {
    return this.httpClient.get<BaseResponse>(this.baseEndpoint + "/usermicasevisibilityinfo");
  }

  getActiveUserList = (first: number, max: number) => {
    const endpoint = `${this.baseEndpoint}/list?first=${first}&max=${max}`;
    return this.httpClient.get<any>(endpoint);
  }

  _list: Crud.ListFunction<UserProfilePacket> = (first, max) => {
    const endpoint = `${this.baseEndpoint}/list`;
    return this.httpClient.get<PagedResponse<UserProfilePacket[]>>(endpoint, { params: { first, max } });
  };

  _getById: Crud.GetByIdFunction<UserProfilePacket> = (id) => {
    const endpoint = `${this.baseEndpoint}/${id}`;
    return this.httpClient.get<PagedResponse<UserProfilePacket>>(endpoint);
  }

  _create: Crud.CreateFunction<UserProfilePacket> = (user) => {
    const endpoint = `${this.baseEndpoint}/create`;
    return this.httpClient.post<PagedResponse<UserProfilePacket>>(endpoint, user);
  }

  _update: Crud.UpdateFunction<UserProfilePacket> = (user): Observable<DataResponse<UserProfilePacket>> => {
    const endpoint = `${this.baseEndpoint}/update`;
    return this.httpClient.put<PagedResponse<UserProfilePacket>>(endpoint, user);
  }

  _deactivate: Crud.DeactivateFunction<UserProfilePacket> = (ids) => {
    const endpoint = `${this.baseEndpoint}/deactivate`;
    return this.httpClient.put<DataResponse<boolean>>(endpoint, ids);
  }

  _delete: Crud.DeleteFunction<UserProfilePacket> = (ids) => {
    const endpoint = `${this.baseEndpoint}/delete`;
    return this.httpClient.put<DataResponse<boolean>>(endpoint, ids[0]);
  }

  protected _crudFunctions: Crud.CrudServiceDef<UserProfilePacket> = {
    _list: this._list,
    _getById: this._getById,
    _create: this._create,
    _update: this._update,
    _deactivate: this._deactivate,
    _delete: this._delete
  };

  getUserListByParams = (packet:any, first:number, max:number) => {
    const endpoint = `${this.baseEndpoint}/list?first=${first}&max=${max}`;
    return this.httpClient.post<PagedResponse<UserProfilePacket>>(endpoint, packet);
  }

  getUserMICaseVisibilityLevels = () => {
    const endpoint = `${this.baseEndpoint}/usermicasevisibilitylevels`;
    return this.httpClient.get<any>(endpoint);
  }

  copyCloneMICaseVisibility = (fromId: number, toId: number) => {
    const endpoint = `${this.baseEndpoint}/copy-usermicasevisibility/${fromId}/${toId}`;
    return this.httpClient.put<any>(endpoint, null);
  }

}
