import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivityDiaryQueryRequestPacket, EnvironmentService, PagedResponse } from "xf-common";

@Injectable({
  providedIn: 'root',
})
export class ActivityDiaryService {

  constructor(
    private httpClient: HttpClient,
    private environment: EnvironmentService
  )
  { }

  getAllActivityDiaryForRequestParams(params: ActivityDiaryQueryRequestPacket, first?: number, max?: number): Observable<PagedResponse<any>> {
    return this.httpClient.put<any>(this.environment.baseApiUrl + "activity-diary/list/"
      + "?first=" + first?.toString() + "&max=" + max?.toString(), params);
  }

  getAllActivityDiaryForMICase(id: any, first?: number, max?: number): Observable<PagedResponse<any>> {
    return this.httpClient.get<any>(this.environment.baseApiUrl + "activity-diary/list/mi-case/"
      + id + "?first=" + first?.toString() + "&max=" + max?.toString());
  }

  getAllActivityDiaryForId(id: any, first?: number, max?: number): Observable<PagedResponse<any>> {
    return this.httpClient.get<any>(this.environment.baseApiUrl + "activity-diary/list/"
      + id + "?first=" + first?.toString() + "&max=" + max?.toString());
  }

  getAllActivityList(first?: number, max?: number): Observable<PagedResponse<any>> {
    return this.httpClient.get<any>(`${this.environment.baseApiUrl}activity-diary/list/?first=${first}&max=${max}`);
  }

  getActivityDiaryForUserId = (first?: number, max?: number): Observable<PagedResponse<any>> =>  {
    return this.httpClient.get<any>(`${this.environment.baseApiUrl}activity-diary/list/user-id?first=${first}&max=${max}`);
  }

  getActivityDiaryDomains(): Observable<any> {
    return this.httpClient.get<any>(this.environment.baseApiUrl + "activity-diary/domains");
  }

  getActivityDiaryMessageTypes(): Observable<any> {
    return this.httpClient.get<any>(this.environment.baseApiUrl + "activity-diary/messagetypes");
  }

}
