import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AdjustmentExplanationCodePacket, BaseCrudService, Crud, DataResponse, PagedResponse } from "xf-common";

@Injectable()
export class AdjustmentExplanationCodeService extends BaseCrudService<AdjustmentExplanationCodePacket> {
  baseEndpoint = super.apiEndpoint("adjustment-explanation-code")

  constructor(public override httpClient: HttpClient) {
    super(httpClient, "AdjustmentExplanationCodeService", "AdjustmentExplanationCode");
  }

  _list: Crud.ListFunction<AdjustmentExplanationCodePacket> = (first, max) => {
    const endpoint = `${this.baseEndpoint}/list`
    return this.httpClient.get<PagedResponse<AdjustmentExplanationCodePacket[]>>(endpoint, {params: {first, max}})
  }

  _getById: Crud.GetByIdFunction<AdjustmentExplanationCodePacket> = (id) => {
    const endpoint = `${this.baseEndpoint}/${id}`
    return this.httpClient.get<PagedResponse<AdjustmentExplanationCodePacket>>(endpoint)
  }

  _create: Crud.CreateFunction<AdjustmentExplanationCodePacket> = (user) => {
    const endpoint = `${this.baseEndpoint}/create`
    return this.httpClient.post<PagedResponse<AdjustmentExplanationCodePacket>>(endpoint, user)
  }

  _update: Crud.UpdateFunction<AdjustmentExplanationCodePacket> = (user): Observable<DataResponse<AdjustmentExplanationCodePacket>> => {
    const endpoint = `${this.baseEndpoint}/update`
    return this.httpClient.put<PagedResponse<AdjustmentExplanationCodePacket>>(endpoint, user)
  }

  _deactivate: Crud.DeleteFunction<AdjustmentExplanationCodePacket> = (ids) => {
    const endpoint = `${this.baseEndpoint}/deactivate`
    return this.httpClient.put<DataResponse<boolean>>(endpoint, ids[0])
  }

  _delete: Crud.DeleteFunction<AdjustmentExplanationCodePacket> = (ids) => {
    const endpoint = `${this.baseEndpoint}/delete/` + ids
    return this.httpClient.delete<DataResponse<boolean>>(endpoint)

  }
  changeStatus = (adjustmentExplanationCodePacket: AdjustmentExplanationCodePacket): Observable<DataResponse<AdjustmentExplanationCodePacket>> => {
    const endpoint = `${this.baseEndpoint}/status`;
    return this.httpClient.put<DataResponse<AdjustmentExplanationCodePacket>>(endpoint, adjustmentExplanationCodePacket);
  }

  protected _crudFunctions: Crud.CrudServiceDef<AdjustmentExplanationCodePacket> = {
    _list: this._list,
    _getById: this._getById,
    _create: this._create,
    _update: this._update,
    _deactivate: this._deactivate,
    _delete: this._delete
  }

  getAdjustmentExplanationCodesByParams = (packet:any, first:number, max:number) => {
    const endpoint = `${this.baseEndpoint}/list?first=${first}&max=${max}`;
    return this.httpClient.post<PagedResponse<AdjustmentExplanationCodePacket>>(endpoint, packet);
  }

  getAdjustmentExplanationTypes = () => {
    const endpoint = `${this.baseEndpoint}/adjustment-explanation-types`;
    return this.httpClient.get<PagedResponse<any>>(endpoint);
  }
}
