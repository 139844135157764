import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { APERuleFilterHealthCarePlanPacket, BaseCrudService, Crud, DataResponse, PagedResponse } from "xf-common";

@Injectable()
export class ApeRuleFilterHealthCarePlanService extends BaseCrudService<APERuleFilterHealthCarePlanPacket> {

  public baseEndpoint = super.apiEndpoint("ape-rule-filter-health-care-plan");

  constructor(
    public override httpClient: HttpClient
  ) {
    super(httpClient, "ApeRuleFilterHealthCarePlanService", "ApeRuleFilterHealthCarePlan");
  }

  _list: Crud.ListFunction<APERuleFilterHealthCarePlanPacket> = (first, max) => {
    const endpoint = `${this.baseEndpoint}/list`;
    return this.httpClient.put<PagedResponse<APERuleFilterHealthCarePlanPacket[]>>(endpoint, { params: { first, max} })
  };

  _getById: Crud.GetByIdFunction<APERuleFilterHealthCarePlanPacket> = () => {
    return EMPTY;
  };

  _create: Crud.CreateFunction<APERuleFilterHealthCarePlanPacket> = (payload) => {
    const endpoint = `${this.baseEndpoint}/create`;
    return this.httpClient.post<DataResponse<APERuleFilterHealthCarePlanPacket>>(endpoint, payload);
  };

  _update: Crud.UpdateFunction<APERuleFilterHealthCarePlanPacket> = (payload) => {
    const endpoint = `${this.baseEndpoint}/create`;
    return this.httpClient.post<DataResponse<APERuleFilterHealthCarePlanPacket>>(endpoint, payload);
  };

  _deactivate: Crud.DeleteFunction<APERuleFilterHealthCarePlanPacket> = (id) => {
    const endpoint = `${this.baseEndpoint}/delete`;
    return this.httpClient.put<DataResponse<boolean>>(endpoint, id[0]);
  };

  _delete: Crud.DeleteFunction<APERuleFilterHealthCarePlanPacket> = (id) => {
    const endpoint = `${this.baseEndpoint}/${id}`;
    return this.httpClient.delete<DataResponse<boolean>>(endpoint);
  };

  protected _crudFunctions: Crud.CrudServiceDef<APERuleFilterHealthCarePlanPacket> = {
    _list: this._list,
    _getById: this._getById,
    _create: this._create,
    _update: this._update,
    _deactivate: this._deactivate,
    _delete: this._delete
  };

  getAPERuleFilterHealthCarePlanListForAPERuleFilterId = (id:number, first:number, max:number): Observable<PagedResponse<any>> => {
    const endpoint = `${this.baseEndpoint}/list/${id}`;
    return this.httpClient.get<PagedResponse<APERuleFilterHealthCarePlanPacket[]>>(endpoint, { params: { first, max} })
  };

  deleteAPERuleFilterHealthCarePlan = (id:number) => {
    const endpoint = `${this.baseEndpoint}/${id}`;
    return this.httpClient.delete<DataResponse<boolean>>(endpoint);
  }
}
