<div class="base-component">
  <form *ngIf="provider" class="container" [formGroup]="formGroup">
    <mat-card class="child-container">
      <mat-card-header>
        <mat-card-title>
          General Provider Information
        </mat-card-title>
      </mat-card-header>
      <div class="mat-card-divider"></div>
      <mat-card-content class="form-div">
        <mat-form-field appearance="outline" color="accent">
          <mat-label>Provider Type</mat-label>
          <mat-select matInput formControlName="providerType">
            <mat-option *ngFor="let type of providerTypes" [value]="type.value">{{type.label}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" color="accent">
          <mat-label>Name</mat-label>
          <input matInput formControlName="name">
        </mat-form-field>
        <mat-form-field appearance="outline" color="accent">
          <mat-label>First Name</mat-label>
          <input matInput formControlName="firstName">
        </mat-form-field>
        <mat-form-field appearance="outline" color="accent">
          <mat-label>Last Name</mat-label>
          <input matInput formControlName="lastName">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>NPI Number</mat-label>
          <input matInput formControlName="npiNumber">
        </mat-form-field>
        <mat-form-field appearance="outline" color="accent">
          <mat-label>Taxonomy Code</mat-label>
          <input matInput class="name" id="code" formControlName="taxonomyCode" />
        </mat-form-field>
        <mat-form-field appearance="outline" color="accent">
          <mat-label>Description</mat-label>
          <textarea matInput cdkTextareaAutosize class="name" id="taxonomyCodeDescription" formControlName="taxonomyCodeDescription"></textarea>
        </mat-form-field>
      </mat-card-content>
    </mat-card>
    <app-address class="child-container" #addressComponent [mode]="'EDIT'" [existingAddressId]="provider.addressId"></app-address>
  </form>
  <div class="fennec-controls">
    <button mat-stroked-button color="secondary"  routerLink="/providers" [disabled]="isLoading">
      Cancel
    </button>
    <button mat-flat-button color="accent" (click)="onSubmit()" [disabled]="!formGroup.valid">
      Save
    </button>
  </div>
</div>

