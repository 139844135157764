<div matDialogTitle>
</div>
<div matDialogContent>
  <app-attachment #attachmentComponent miCaseId="{{miCaseId}}" showAttachmentList="false"
    headerString="Upload/Create EDI Run" context="EDIRUN">
  </app-attachment>
</div>

<div matDialogActions class="fennec-controls">

</div>

