import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent, FennecSnackbarService, Logger, MICaseService } from 'xf-common';
import { ActivityLogService } from '../activity-log.service';
import {
  ActivityLogListSubDialogComponent
} from './activity-log-list-sub-dialog/activity-log-list-sub-dialog.component';

@Component({
  selector: 'app-activity-log-list',
  templateUrl: './activity-log-list.component.html',
  styleUrls: ['./activity-log-list.component.scss', '../../admin/system-admin/system-admin.component.scss']
})
export class ActivityLogListComponent extends BaseComponent implements OnInit {
  log: Logger = new Logger("ActivityLogListComponent");

  @Input()
  selectedRelatedId: any;

  @Input()
  entity: string = "XFWF_TASK";

  dataSource = new MatTableDataSource<any>()
  displayColumns = ['relatedId',
  // 'activityLogEntity',
  'activityLogType',
  'changed', 'beforeData', 'afterData',
  'changes',
  'miCaseId', 'createdBy', 'createdDate'];

  @ViewChild(MatPaginator)
  paginator?: MatPaginator;
  totalRowCount?: number;
  defaultPageSize = 20;
  pageSizeOptions = [5, 10, 20, 25, 50];

  miCaseId: number = -1;
  miCase: any | null = null;

  formGroup: FormGroup;

  constructor(
    protected snack: FennecSnackbarService,
    private activityLogService: ActivityLogService,
    protected activeRoute: ActivatedRoute,
    protected router: Router,
    protected dialog: MatDialog,
    private miCaseService: MICaseService,
  ) {
    super();
    this.selectedRelatedId = this.activeRoute.snapshot.params["id"];

    this.formGroup = this.createFormGroup();
    // this.selectedRelatedId = this.router.getCurrentNavigation()?.id;
  }

  ngOnInit() {
    this.getRecentTaskActivityLogList();
    this.formGroup.valueChanges.subscribe(() => {
      this.getRecentTaskActivityLogList();
    })
  }

  ngAfterViewInit() {
    if (this.paginator) {
      this.paginator.page.subscribe(() => {
        this.getRecentTaskActivityLogList();
      })
    }
  }

  createFormGroup() {
    return new FormGroup({
      userId: new FormControl("ALL")
    })
  }

  getMiCaseInfo() {
    if(this.miCaseId !== -1) {
      const packet = {
        miCaseId: this.miCaseId,
        miCaseName: "",
        altExtId: ""
      }

      this.performXFRequest({
        requestDescription: "Get Task Statuses",
        requestFn: this.miCaseService.getAllCases,
        fnParams: [packet, 0, 1],
        onSuccess: data => {
          if(data.length > 0) {
            this.miCase = data[0];
          }
        },
        onError: errString => {
          super.showErrorSnack(errString);
        }
      })
    }
  }

  selectTaskId(taskId: number): void {
    this.selectedRelatedId = taskId;
  }

  getRecentTaskActivityLogList() {
    const user = this.formGroup.controls['userId'].value;
    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;
    const entity =
    this.activityLogService.getAllActivityLogForXFWFTask(ActivityLogService.ELEMENT_XFWF_TASK, this.selectedRelatedId, user, first, pageSize).subscribe((response: any) => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.dataSource.data = response.data;
        this.totalRowCount = response.totalRowCount;
        if(response.data.length > 0) {
          this.miCaseId = response.data[0].miCaseId;
          this.getMiCaseInfo();
        }
        this.formatDataForDisplay();
      }
    })
  }

  formatDataForDisplay() {
    this.dataSource.data.forEach((e) => {
      if(e && e.data) {
        const dataObj = JSON.parse(e.data);
        e.beforeData = dataObj.beforeData;
        e.afterData = dataObj.afterData;
        e.changes = this.findChanges(dataObj);
        // console.log(e);
      }
    });
  }

  backButton = () => {
    this.router.navigateByUrl(``);
  }

  findChanges = (dataObj:any) => {
    let beforeData = null;
    let afterData = null;
    try{
      beforeData = JSON.parse(dataObj.beforeData);
      afterData = JSON.parse(dataObj.afterData);
    }catch(e) {
      console.log(e);
    }

    let changes = [];

    for(let key in beforeData) {
      if(afterData[key] != beforeData[key]) {
        const text = key;
        const result = text.replace(/([A-Z])/g, " $1");
        const formattedKey = result.charAt(0).toUpperCase() + result.slice(1);

        let changedData = {
          key: formattedKey,
          beforeData: beforeData[key],
          afterData: afterData[key]
        }
        changes.push(changedData);
      }
    }

    // console.log(changes);
    return changes;
  }

  moreInfoDialog = (data:any) => {
    const matDialogConfig = new MatDialogConfig();
    // matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      beforeData: data.beforeData,
      afterData: data.afterData
    };

    const dialogRef = this.dialog.open(ActivityLogListSubDialogComponent, matDialogConfig);
  }
}
