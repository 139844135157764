import { Component, OnInit, ViewChild } from '@angular/core';
import {
  AddressComponent,
  BaseComponent,
  BaseResponse,
  CompanyPacket,
  CompanyService,
  FennecSnackbarService,
  Logger
} from 'xf-common';
import { Subject } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { FormControl, FormGroup } from "@angular/forms";


@Component({
  selector: 'app-company-edit',
  templateUrl: './company-edit.component.html',
  styleUrls: ['./company-edit.component.scss']
})
export class CompanyEditComponent extends BaseComponent implements OnInit {
  log: Logger = new Logger("CompanyEditComponent");

  @ViewChild('addressComponent')
  addressComponent?: AddressComponent;

  companyId = -1;

  company: CompanyPacket | undefined;

  companySaveComplete: Subject<any> = new Subject();
  formGroup!: FormGroup;

  constructor(
    protected snack: FennecSnackbarService,
    protected activeRoute: ActivatedRoute,
    private companyService: CompanyService
  ) {
    super();
    this.companyId = this.activeRoute.snapshot.params["id"];
    this.getCompany();
  }

  ngOnInit(): void {
  }

  findAddressComponent(){
    if (this.addressComponent !== null && this.addressComponent !== undefined) {
      this.addressComponent?.addressSaveComplete?.subscribe((id: number) => {
        // this.ub04Patient.patient.addressId = id;
        this.saveCompanyToServer(id);
      });
      this.addressComponent?.formGroup?.valueChanges?.subscribe(() => {
        this.validateAddress();
      })
    }
  }

  getCompany() {
    this.isLoading = true;
    this.performXFRequest({
      requestDescription: "GET Company by ID",
      requestFn: this.companyService.getCompany,
      fnParams: [this.companyId],
      onSuccess: data => {
        this.company = data;
        this.createForm();
        this.isLoading = false;
      },
      onError: errString => {
        super.showErrorSnack(errString);
        this.isLoading = false;
      }
    });
  }

  createForm() {
    this.formGroup = new FormGroup({
      name: new FormControl(this.company?.name),
      addressValidator: new FormControl()
    });

    this.formGroup.valueChanges.subscribe(() => {
      this.validateAddress();
    })
  }

  validateAddress(){
    let addressFormControl: FormGroup | undefined = this?.addressComponent?.formGroup;

    if(addressFormControl){
      if(!addressFormControl.valid){
        this.formGroup.controls['addressValidator'].setErrors({'incorrect':true});
      }else{
        this.formGroup.controls['addressValidator'].setErrors(null);
      }
    }
  }

  cancel() {
    this.getCompany();
  }
  onSubmit() {
    this.findAddressComponent();
    this.addressComponent?.saveAddressToServer();
  }

  saveCompanyToServer(addressId?: number) {
    this.formGroup.markAllAsTouched();
    let updateDto: CompanyPacket = new CompanyPacket();
    updateDto.name = this.formGroup.controls['name'].value;
    updateDto.id = this.companyId;
    updateDto.addressId = addressId;

    this.companyService.updateCompany(updateDto).subscribe( (response: BaseResponse) => {
      if(response.hasErrors) {
        super.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.companySaveComplete.next(response.data);
        this.getCompany();
        super.showSuccessSnack("Successfully Updated!");
      }
    });
  }

}
