<lib-page>
    <ng-container pageTitle>
      UB04 Review Data
    </ng-container>
    <ng-container pageContent>
      <!-- No Linked UB04 found -->
      <div *ngIf="ub04ReviewData == null">
        No linked UB04 data found!
        <!-- UB04 Select Mode -->
        <div class="itemized-ub04-select-wrapper">
          <div class="itemized-line-item-top-nav">
            <span class="itemized-line-item-rev-header-line"><mat-icon>assignment</mat-icon> Select UB04 for Itemized</span>
            <div class="itemized-ub04-select-component-wrapper">
              <app-ub04-select #ub04SelectComponent></app-ub04-select>
            </div>
          </div>
        </div>
      </div>
      <!-- Linked UB04 found -->
      <div *ngIf="ub04ReviewData != null">
        <div class="ub04-review-ub04-info-header">
          UB04 Id: {{ub04ReviewData.ub04Id}}
        </div>
        <div class="ub04-review-section-header">
          Diagnosis Codes
        </div>
        <div *ngIf="ub04ReviewData.diagnosisCodeQueryPackets.length == 0">
          No Data Available!
        </div>
        <div *ngIf="ub04ReviewData.diagnosisCodeQueryPackets.length > 0" class="ub04-review-diagnosis-code-container">
          <ng-container *ngFor="let diag of ub04ReviewData.diagnosisCodeQueryPackets">
            <table class="ub04-review-diagnosis-code-table">
              <tr>
                <td class="ub04-review-diagnosis-code-code">{{diag.diagnosisCode}}</td>
                <td>{{diag.ub04DiagnosisCodeType}}</td>
              </tr>
              <tr>
                <td colspan="2">{{diag.diagnosisCodeDescription}}</td>
              </tr>
            </table>
          </ng-container>
        </div>
        <div class="ub04-review-section-header">
          Procedure Codes
        </div>
        <div *ngIf="ub04ReviewData.procedureCodes.length == 0">
          No Data Available!
        </div>
        <div *ngIf="ub04ReviewData.procedureCodes.length > 0" class="ub04-review-procedure-code-container">
          <ng-container *ngFor="let proc of ub04ReviewData.procedureCodes">
            <table class="ub04-review-diagnosis-code-table">
              <tr>
                <td class="ub04-review-diagnosis-code-code">{{proc.procedureCode}}</td>
              </tr>
              <tr>
                <td>{{proc.procedureCodeDescription}}</td>
              </tr>
            </table>
          </ng-container>
        </div>
      </div>
    </ng-container>
</lib-page>
