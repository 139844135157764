
<mat-table [dataSource]="providerAddresses">

  <ng-container matColumnDef="addressLines">
    <mat-header-cell *matHeaderCellDef>
      Address
    </mat-header-cell>
    <mat-cell *matCellDef="let pa">
      <table>
        <tr>
          <td style="font-size: small;"><app-truncate-text>{{pa?.address?.addressLine1}}</app-truncate-text></td>
        </tr>
        <tr>
          <td style="font-size: small;"><app-truncate-text>{{pa?.address?.addressLine2}}</app-truncate-text></td>
        </tr>
        <tr>
          <td style="font-size: small;"><app-truncate-text>{{pa?.address?.addressLine3}}</app-truncate-text></td>
        </tr>
      </table>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="city">
    <mat-header-cell *matHeaderCellDef>
      City
    </mat-header-cell>
    <mat-cell *matCellDef="let pa">
      <app-truncate-text>{{pa?.address?.city}}</app-truncate-text>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="state">
    <mat-header-cell *matHeaderCellDef>
      State
    </mat-header-cell>
    <mat-cell *matCellDef="let pa">
      <app-truncate-text>{{pa?.address?.stateCode}}</app-truncate-text>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="zip">
    <mat-header-cell *matHeaderCellDef>
      Zip Code
    </mat-header-cell>
    <mat-cell *matCellDef="let pa">
      <app-truncate-text>{{pa?.address?.zipCode}}</app-truncate-text>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="type">
    <mat-header-cell *matHeaderCellDef>
      Type
    </mat-header-cell>
    <mat-cell *matCellDef="let pa">
      <app-truncate-text>{{pa?.providerAddressType}}</app-truncate-text>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="actions">
    <mat-header-cell *matHeaderCellDef>
      <button
        mat-raised-button
        color="accent"
        (click)="openAddressDialog(null)"
      >
        <mat-icon>add_circle</mat-icon>
        Add
      </button>

    </mat-header-cell>

    <mat-cell *matCellDef="let pa">
      <mat-menu #actionMenu="matMenu">
        <button
          mat-menu-item
          (click)="openAddressDialog(pa)"
        >
          <mat-icon>edit</mat-icon>
          Edit Address
        </button>

        <button
            mat-menu-item 
            (click)="deleteProviderAddresses(pa)"
            color="primary">
            <mat-icon>delete</mat-icon>
             Delete
          </button>
      </mat-menu>

      <button mat-icon-button [matMenuTriggerFor]="actionMenu" [matMenuTriggerData]="">
        <mat-icon>more_vert</mat-icon>
      </button>

    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayColumns;"></mat-row>
</mat-table>