import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { APERuleCollection, BaseCrudComponent, FennecSnackbarService, SingleChoiceDialogComponent } from 'xf-common';
import { Logger } from 'xf-common';
import { KeycloakService } from 'keycloak-angular';
import { ApeCollectionDialogComponent } from '../ape-collection-dialog/ape-collection-dialog.component';
import { ApeRuleCollectionService } from '../ape-rule-collection.service';
import { ApeRuleCollectionRuleListComponent } from '../ape-rule/ape-rule-collection-rule/ape-rule-collection-rule-list/ape-rule-collection-rule-list.component';

@Component({
  selector: 'app-ape-collection-list',
  templateUrl: './ape-collection-list.component.html',
  styleUrls: ['./ape-collection-list.component.scss', '../../../lib/styles/system-admin-styles.component.scss']
})
export class ApeCollectionListComponent extends BaseCrudComponent<APERuleCollection, ApeRuleCollectionService> implements OnInit, AfterViewInit {

  displayedColumns: string[] = ["uniqueIdentificationCode", "name", "description", "active", "actions"];

  dialogRef?: MatDialogRef<any>;

  @ViewChild("page", { read: ElementRef })
  element?: ElementRef;

  protected log: Logger = new Logger("APECollectionList");

  formGroup: FormGroup;

  constructor(
    protected apeRuleCollectionService: ApeRuleCollectionService,
    public override snack: FennecSnackbarService,
    protected dialog: MatDialog,
    private renderer: Renderer2,
    protected keycloakService: KeycloakService
    ) {
      super(apeRuleCollectionService);
      this.formGroup = this.createFormGroup();
      this.canCreate = keycloakService.isUserInRole("ADMIN");
      this.canUpdate = keycloakService.isUserInRole("ADMIN");
      this.canDelete = keycloakService.isUserInRole("ADMIN");
      this.canDeactivate = keycloakService.isUserInRole("ADMIN");
      this.canView = keycloakService.isUserInRole("ADMIN");
    }

    ngOnInit(): void {
      this.executeListQuery();
    }

    ngAfterViewInit(): void {
      this.selection.changed.subscribe(change => this.selectedRow = change.added[0] ?? change.removed[0] ?? undefined);
      if (this.element?.nativeElement) {
        this.renderer.listen(this.element.nativeElement, "keyup", (event) => this.keyEvent(event));
      }
      if (this.paginator) {
        this.paginator.page.subscribe(() => {
          this.executeListQuery();
        })
      }
    }

    clearSearch = () => {
      this.formGroup = this.createFormGroup();
    }

    executeListQuery() {
      const controls = this.formGroup?.controls;
      if(controls == undefined) {
        return;
      }

    const searchParams = {
      name: controls["name"].value?.trim() ?? null,
      description: controls["description"].value?.trim() ?? null,
      uniqueIdentificationCode: controls["uniqueIdentificationCode"].value?.trim() ?? null,
      active: controls["active"].value,
    }

    if(searchParams.active === "all") {
      searchParams.active = null;
    }

      const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
      const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;
      // this.paginator.pageIndex = first;
      // this.paginator.pageSize = pageSize;
      this.performXFRequest({
        requestDescription: "GET APE Rules By Search Params",
        requestFn: this.apeRuleCollectionService.getAPERuleCollections,
        fnParams: [searchParams, first, pageSize],
        onResponse: response => {
          this.dataSource = response.data;
          this.totalRowCount = response['totalRowCount'];
        },
        onError: errString => {
          super.showErrorSnack(errString)
        }
      })
    }

    createFormGroup = () => {
      return new FormGroup({
        name: new FormControl(),
        description: new FormControl(),
        uniqueIdentificationCode: new FormControl(),
        active: new FormControl("all")
      })
    }

    keyEvent(event: KeyboardEvent) {
      const selected = this.selection?.selected;

      switch (event.key) {
        case "c":
          this.onCreate();
          break;
        case "e":
          if (this.selectedRow) {
            // this.onEdit(this.selectedRow.id);
          }
          break;
        // case "d":
        //   this.onDelete(selected);
        //   break;
      }
    }

    onCreate = () => {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        mode: "Create"
      }
      dialogConfig.autoFocus = "first-tabbable";
      this.dialogRef = this.dialog.open(ApeCollectionDialogComponent, dialogConfig);
      this.dialogRef.afterClosed().subscribe((value?: APERuleCollection) => {
        if(value){
          this.create(value);
        }
        this.executeListQuery();
      })
    }
    onEdit= () => {

    }

    openEditDialog = (apeRuleCollection: APERuleCollection) => {
      if (apeRuleCollection) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
          mode: "Edit",
          apeRuleCollection: apeRuleCollection
        };
        dialogConfig.autoFocus = "first-tabbable";
        this.dialogRef = this.dialog.open(ApeCollectionDialogComponent, dialogConfig);
        this.dialogRef.afterClosed().subscribe({
          next: (value?: APERuleCollection) => {
            if (value) {
              this.update(value);
            }
            this.executeListQuery();
          }
        });
      }
    };

    onDelete = (ids: any[]) => {

    }

    openDeactivateDialog(apeRuleCollection: APERuleCollection) {
      if (apeRuleCollection) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
          question:`Are you sure you want to deactivate ${apeRuleCollection.name}?`
        };
        this.dialogRef = this.dialog.open(SingleChoiceDialogComponent, dialogConfig);
        this.dialogRef.afterClosed().subscribe((result) => {
          if (result?.confirm && apeRuleCollection.id) {
            this.apeRuleCollectionService._deactivate([apeRuleCollection.id]).subscribe(response => {
              if(response.hasErrors) {
                super.showErrorSnack("Error processing delete request");
              } else {
                super.showSuccessSnack("Delete successful!");
                this.executeListQuery();
              }
            })
          }
        });
      }
    }

    openRuleDialog(collection:any) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        mode: "Edit",
        apeRuleCollection: collection
      };

      this.dialogRef = this.dialog.open(ApeRuleCollectionRuleListComponent, dialogConfig);
    }

    getDeleteIconString = (rule:any): string => {
      if(rule.active) {
        return "delete";
      }
      return "restore_from_trash";
    }

    getActiveIconStyle = (rule:any) => {
      if(rule?.active) {
        return {"color":"green"}
      }

      return {"color":"red"}
    }

    onSearch = () => {
      this.paginator?.firstPage();
      this.executeListQuery();
    }
}
