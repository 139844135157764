<mat-dialog-content>
    <h1 mat-dialog-title>Create Condition Code</h1>
    <div class="alt-theme">
        <form [formGroup]="formGroup" class="container">
            <mat-form-field appearance="outline">
                <mat-label>
                    Code
                </mat-label>
                <input matInput formControlName="code" maxLength="4">
                <mat-hint>{{formGroup.controls['code'].value.length}} of 4</mat-hint>
            </mat-form-field>
    
            <mat-form-field appearance="outline" [style.width.px]=450>
                <mat-label>
                    Description
                </mat-label>
                <textarea matInput rows="5" cdkTextareaAutosize formControlName="description" maxLength="1000"></textarea>
                <mat-hint>{{formGroup.controls['description'].value.length}} of 1000</mat-hint>
            </mat-form-field>
        </form>
    </div>
</mat-dialog-content>

<mat-dialog-actions class="fennec-dialog-action-button-container">
    <button mat-flat-button (click)="onCancel()">
        <mat-icon>cancel</mat-icon> Cancel
    </button>
    <button mat-flat-button (click)="onAddCode()" [disabled]="formGroup.invalid" color="accent">
        <mat-icon>save</mat-icon> Save
    </button>

</mat-dialog-actions>
