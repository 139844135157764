import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ApeRuleFilterPrimaryClientService } from '../ape-rule-filter-primary-client.service';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import {
  APERuleFilter,
  APERuleFilterPrimaryClientPacket,
  BaseComponent,
  CompanyPacket,
  CompanyService,
  FennecSnackbarService,
  Logger
} from "xf-common";

@Component({
  selector: 'app-ape-rule-filter-add-primary-client-dialog',
  templateUrl: './ape-rule-filter-add-primary-client-dialog.component.html',
  styleUrls: ['./ape-rule-filter-add-primary-client-dialog.component.scss']
})
export class ApeRuleFilterAddPrimaryClientDialogComponent extends BaseComponent implements OnInit, AfterViewInit {

  protected log = new Logger("ApeRuleFilterAddPrimaryClientDialogComponent");

  @Output()
  closeSearchEvent: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  apeRuleFilterId: number = -1;

  companyList: any[] = [];

  selectedCompanyId: number = -1;

  displayColumns = ["select", "name", "address"];

  @ViewChild(MatPaginator)
  paginator?: MatPaginator;
  totalRowCount?: number;
  defaultPageSize = 20;
  pageSizeOptions = [5, 10, 20, 25];

  constructor(
    override snack: FennecSnackbarService,
    protected companyService: CompanyService,
    protected apeRuleFilterPrimaryClientService: ApeRuleFilterPrimaryClientService,
  ) {
    super();
  }
  ngAfterViewInit(): void {
    this.getCompanyList();
  }

  ngOnInit(): void {
  }

  getCompanyList = () => {

    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;
    this.performXFRequest({
      requestDescription: "GET Company List",
      requestFn: this.companyService.getActiveUserProfilePrimaryClientCompanies,
      fnParams: [first, pageSize],
      onSuccess: data => {
        this.companyList = data;
      },
      onError: errString => {
        super.showErrorSnack(errString);
      },
      onResponse: response => {
        this.totalRowCount = response['totalRowCount'];
      }
    });
  }

  selectCompany = (companyId: number) => {
    this.selectedCompanyId = companyId;
  }

  submit() {

    const payload = new APERuleFilterPrimaryClientPacket();
    payload.apeRuleFilter = new APERuleFilter();
    payload.apeRuleFilter.id = this.apeRuleFilterId;
    payload.primaryClient = new CompanyPacket();
    payload.primaryClient.id = this.selectedCompanyId;

    this.performXFRequest({
      requestDescription: "CREATE APE Rule Filter Primary Client",
      requestFn: this.apeRuleFilterPrimaryClientService._create,
      fnParams: [payload],
      onSuccess: data => {
        super.showSuccessSnack("Primary Client Successfuly Added to Ape Rule Filter");
        this.closeSearchEvent.emit();
      },
      onError: errString => {
        super.showErrorSnack(errString);
      }
    });
  }

}
