import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { JslService } from "../../jsl.service";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { FennecSnackbarService } from 'xf-common';
import { Logger } from 'xf-common';

const log = new Logger("JSLJobDefinitionDialogComponent");

@Component({
  selector: "app-jsl-job-definition-dialog-component",
  templateUrl: "./jsl-job-definition-dialog.component.html",
  styleUrls: ["./jsl-job-definition-dialog.component.scss"]
})
export class JSLJobDefinitionDialogComponent implements OnInit {
  jobDefinitionDialogFormGroup: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<JSLJobDefinitionDialogComponent>,
    protected snackBar: FennecSnackbarService,
    protected jslService: JslService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    this.jobDefinitionDialogFormGroup = new FormGroup({
      name: new FormControl(),
      jslJobCode: new FormControl()
    });
    if (this.data !== null && this.data !== undefined && this.data.mode === "Modify") {
      this.jobDefinitionDialogFormGroup.controls['name'].setValue(this.data.name);
      this.jobDefinitionDialogFormGroup.controls['jslJobCode'].setValue(this.data.jslJobCode);
    }

  }

  ngOnInit() {

  }

  close() {
    const response = {
      confirm: false
    };
    this.dialogRef.close(response);
  }

  submit() {
    if (
      this.jobDefinitionDialogFormGroup.controls['name'].value === null ||
      this.jobDefinitionDialogFormGroup.controls['name'].value === undefined ||
      this.jobDefinitionDialogFormGroup.controls['jslJobCode'].value === null ||
      this.jobDefinitionDialogFormGroup.controls['jslJobCode'].value === undefined
    ) {
      return;
    }

    const response = {
      confirm: true,
      name: this.jobDefinitionDialogFormGroup.controls['name'].value,
      jslJobCode: this.jobDefinitionDialogFormGroup.controls['jslJobCode'].value
    };
    this.dialogRef.close(response);
  }
}
