<mat-card>
  <mat-card-title>
    Report Elements
  </mat-card-title>
  
  <mat-card-content>
    <br>
    <p>
      <span class="label-text">UB04 Revenue Code Total</span> - {{reportElements?.ub04RevenueCodeSummaryTotal ?? 0 | currency}}
    </p>

    <p>
      <span class="label-text">Earliest Provider Report Publish Date</span> - {{earliestProviderReportPublishDate}}
      <mat-icon 
        *ngIf="userCanEdit" 
        class="edit-icon" 
        (click)="openEditDialog('Provider', earliestProviderReportPublishDate)"
        matTooltip="Edit Provider Report Publish Date">
        edit
      </mat-icon>
    </p>
    <p>
      <span class="label-text">Earliest Client Report Publish Date</span> - {{earliestClientReportPublishDate}}
      <mat-icon 
        *ngIf="userCanEdit" 
        class="edit-icon" 
        (click)="openEditDialog('Client', earliestClientReportPublishDate)"
        matTooltip="Edit Client Report Publish Date">
        edit
      </mat-icon>
    </p>

    <p>
      <span class="label-text">Earliest Appeal Report Publish Date</span> - {{earliestAppealReportPublishDate}}
      <mat-icon 
        *ngIf="userCanEdit" 
        class="edit-icon" 
        (click)="openEditDialog('Appeal', earliestAppealReportPublishDate)"
        matTooltip="Edit Appeal Report Publish Date">
        edit
      </mat-icon>
    </p>

    <p>
      <span class="label-text">Appeal Received Date</span> - {{appealReceivedDate}}
      <mat-icon 
        *ngIf="userCanEdit" 
        class="edit-icon" 
        (click)="openEditDialog('appealReceivedDateString', appealReceivedDate)"
        matTooltip="Edit Appeal Report Publish Date">
        edit
      </mat-icon>
    </p>
  </mat-card-content>
</mat-card>