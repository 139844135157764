<lib-page>
  <ng-container pageTitle>
    Condition Codes
  </ng-container>
<ng-container pageContent>

  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h4>Filters</h4>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form [formGroup]="formGroup" class="alt-theme" (submit)="onSearch()">
        <div class="input-wrapper">
          <mat-form-field appearance="outline" class="code-search-input">
            <mat-label>Code</mat-label>
            <input matInput placeholder="Search by Code" formControlName="code">
          </mat-form-field>

          <mat-form-field appearance="outline" class="description-search-input">
            <mat-label>Description</mat-label>
            <input matInput placeholder="Search by Description" formControlName="description">
          </mat-form-field>

          <mat-form-field appearance="outline" class="active-search-input">
            <mat-label>Status</mat-label>
            <mat-select formControlName="active">
              <mat-option [value]="'all'">All</mat-option>
              <mat-option [value]="true">Active</mat-option>
              <mat-option [value]="false">Not Active</mat-option>
            </mat-select>
          </mat-form-field>

          <div class="search-button-div">
            <button
              mat-stroked-button
              color="accent"
              type="submit"
              class="search-button"
            >
              <mat-icon>search</mat-icon> Search
            </button>

            <button
              mat-stroked-button
              color="primary"
              (click)="clearSearch()"
            >
              Clear
            </button>
          </div>
        </div>
      </form>
    </mat-expansion-panel>
  </mat-accordion>

    <mat-table [dataSource]="dataSource">

      <ng-container matColumnDef="code">
        <mat-header-cell *matHeaderCellDef> Code </mat-header-cell>
        <mat-cell *matCellDef="let conditionCode"> {{conditionCode.code}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef> Description </mat-header-cell>
        <mat-cell *matCellDef="let conditionCode"> {{conditionCode.description}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="active">
        <mat-header-cell *matHeaderCellDef> Active </mat-header-cell>
        <mat-cell *matCellDef="let conditionCode">
          <mat-icon class="active" *ngIf="conditionCode.active">
              check_small
          </mat-icon>
          <mat-icon class="non-active" *ngIf="!conditionCode.active">
            close
          </mat-icon>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef>
            <button class="add" mat-flat-button (click)="createClick()">
              <mat-icon>add_circle</mat-icon>
              New
            </button>
        </mat-header-cell>
      <mat-cell *matCellDef="let code">
        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Row Action Items">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="editClick(code)">
          <mat-icon>edit</mat-icon>
          <span>Edit</span>
        </button>
        <button mat-menu-item (click)="delete(code)">
          <mat-icon>delete</mat-icon>
          <span>Delete</span>
        </button>
        <button *ngIf="!code?.active" mat-menu-item (click)="changeStatus(code)">
          <mat-icon>check</mat-icon>
          <span>Activate</span>
        </button>
        <button *ngIf="code?.active" mat-menu-item (click)="changeStatus(code)">
          <mat-icon>close</mat-icon>
          <span>Deactivate</span>
        </button>
        </mat-menu>
    </mat-cell>
    </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedColumns; let i=index"
          [tabindex]="i + 1"
          [attr.data-index]="i"
          [ngClass]="{ hovered: row.hovered}"
          (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"
        ></mat-row>
    </mat-table>
    <mat-paginator class="mat-paginator" [length]="totalRowCount" [pageSize]="defaultPageSize" [pageSizeOptions]="pageSizeOptions"></mat-paginator>
</ng-container>
</lib-page>
