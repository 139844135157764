import {Deserializable, deserializeAssign} from "../deserializable";

export class PatientRelationshipCodePacket implements Deserializable<PatientRelationshipCodePacket> {

  id?: number;
  code?: string;
  description?: string;
  selected?: boolean;
  active?: boolean;

  deserialize(input: any): PatientRelationshipCodePacket {
    return deserializeAssign(this, input);
  }

  toJSON(): any {
    const obj: { [key: string]: any } = {};
    Object.assign(obj, this);
    delete obj["selected"];
    return obj;
  }
}
