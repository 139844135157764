import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { BehaviorSubject } from "rxjs";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-truncate-text',
  templateUrl: './truncate-text.component.html',
  styleUrls: ['./truncate-text.component.scss']
})
export class TruncateTextComponent implements AfterViewInit, OnChanges {

  @ViewChild('tpl') tpl?: TemplateRef<any>;

  initialized$ = new BehaviorSubject<boolean>(false);

  @Input()
  fullText?: any;

  fullText$ = new BehaviorSubject<any>("");

  constructor(
    protected cdr: ChangeDetectorRef,
    private vc: ViewContainerRef
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes['fullText']) {
      setTimeout(() => {
        this.fullText$.next(changes['fullText'].currentValue);
      });
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.tpl != null) {
        this.vc.createEmbeddedView(this.tpl);
        this.initialized$.next(true);
      }
    });
  }
}
