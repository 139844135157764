import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { APERule, BaseComponent, FennecSnackbarService, Logger } from "xf-common";

@Component({
  selector: 'app-ape-rule-event-definition-list-dialog',
  templateUrl: './ape-rule-event-definition-list-dialog.component.html',
  styleUrls: ['./ape-rule-event-definition-list-dialog.component.scss']
})
export class ApeRuleEventDefinitionListDialogComponent extends BaseComponent implements OnInit {

  protected log = new Logger("ApeRuleEventDefinitionListDialogComponent");
  apeRule?: APERule;
  dataSource?: any[];
  mode:string = "LIST";

  constructor(
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<ApeRuleEventDefinitionListDialogComponent>,
    override snack: FennecSnackbarService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    super();
    this.apeRule = this.data?.rule;

    if(this.data?.mode) {
      this.mode = "LIST";
    }
   }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
