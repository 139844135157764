<div mat-dialog-title>
  <h3>Add EIN to Provider ID: {{providerId}}</h3>
</div>

<mat-dialog-content class="alt-theme">
  <form [formGroup]="formGroup">
    <mat-form-field appearance="outline" style="width: 100%;">
      <mat-label>Federal Tax Number (EIN)</mat-label>
      <input matInput formControlName="federalTaxNumber">
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions
  style="float:right;"
>
  <button
    mat-flat-button
    color="accent"
    (click)="submit()"
  >
    <mat-icon>save</mat-icon>
    Save
  </button>

  <button
    mat-stroked-button
    (click)="cancel()"
  >
    <mat-icon>cancel</mat-icon>
    Cancel
  </button>
</mat-dialog-actions>