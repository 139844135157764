import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { JslService } from "../../jsl.service";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { BaseResponse, FennecSnackbarService, Logger } from "xf-common";


const log = new Logger("JSLJobDefinitionDialogComponent");

@Component({
  selector: "app-jsl-job-definition-dialog-component",
  templateUrl: "./jsl-job-definition-param-dialog.component.html",
  styleUrls: ["./jsl-job-definition-param-dialog.component.scss"]
})
export class JSLJobDefinitionParamDialogComponent implements OnInit {
  jobDefinitionParamDialogFormGroup: FormGroup;

  paramTypes: string[] = [];
  paramType: any;

  constructor(
    private dialogRef: MatDialogRef<JSLJobDefinitionParamDialogComponent>,
    protected snack: FennecSnackbarService,
    protected jslService: JslService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    this.jobDefinitionParamDialogFormGroup = new FormGroup({
      seqNo: new FormControl(),
      paramType: new FormControl(),
      paramPrompt: new FormControl()
    });

  }

  ngOnInit() {
    this.getAllParamTypes();

    if (this.data !== null && this.data !== undefined && this.data.mode === "Modify") {
      this.jobDefinitionParamDialogFormGroup.controls['seqNo'].setValue(this.data.seqNo);
      this.jobDefinitionParamDialogFormGroup.controls['paramType'].setValue(this.data.paramType);
      this.jobDefinitionParamDialogFormGroup.controls['paramPrompt'].setValue(this.data.paramPrompt);
    }
  }

  getAllParamTypes() {
    this.jslService.getAllParamTypes().subscribe(
      (response: BaseResponse) => {
        if (response.hasErrors) {
          this.snack.showErrorSnack(response.consolidatedErrorMessage);
        } else {
          this.paramTypes = response.data;
        }
      });
  }

  close() {
    const response = {
      confirm: false
    };
    this.dialogRef.close(response);
  }

  submit() {
    if (
      this.jobDefinitionParamDialogFormGroup.controls['seqNo'].value === null ||
      this.jobDefinitionParamDialogFormGroup.controls['seqNo'].value === undefined ||
      this.jobDefinitionParamDialogFormGroup.controls['paramType'].value === undefined ||
      this.jobDefinitionParamDialogFormGroup.controls['paramType'].value === null ||
      this.jobDefinitionParamDialogFormGroup.controls['paramPrompt'].value === undefined ||
      this.jobDefinitionParamDialogFormGroup.controls['paramPrompt'].value === null
    ) {
      return;
    }

    const response = {
      confirm: true,
      seqNo: this.jobDefinitionParamDialogFormGroup.controls['seqNo'].value,
      paramType: this.jobDefinitionParamDialogFormGroup.controls['paramType'].value,
      paramPrompt: this.jobDefinitionParamDialogFormGroup.controls['paramPrompt'].value
    };
    this.dialogRef.close(response);
  }
}
