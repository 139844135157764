<lib-page #page [isLoading]="isLoading">
  <ng-container pageTitle>Holidays</ng-container>
  <ng-container pageContent>
    <mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>Description</mat-header-cell>
        <mat-cell *matCellDef="let holiday"> {{holiday?.name}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef>Date</mat-header-cell>
        <mat-cell *matCellDef="let holiday"> {{holiday?.date}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef>
          <button class="add" mat-flat-button (click)="onCreate()">
            <mat-icon>add_circle</mat-icon>
            New
          </button>
        </mat-header-cell>
        <mat-cell *matCellDef="let holiday">
          <mat-menu #appMenu="matMenu">
            <button
              matTooltip="Edit (e)"
              mat-menu-item color="accent"
              (click)="onEdit(holiday)">
              <mat-icon>edit</mat-icon>
              <span>Edit</span>
            </button>
            <button
              matTooltip="Delete (d)"
              mat-menu-item color="primary"
              (click)="onDelete([holiday.id])">
              <mat-icon>delete</mat-icon>
              <span>Delete</span>
            </button>
          </mat-menu>
          <button mat-icon-button [matMenuTriggerFor]="appMenu">
            <mat-icon>more_vert</mat-icon>
          </button>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedColumns; let i=index"
        [tabindex]="i + 1"
        [attr.data-index]="i"
        [ngClass]="{ hovered: row.hovered }"
        (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"
      ></mat-row>
    </mat-table>
    <mat-paginator [length]="totalRowCount" [pageSize]="defaultPageSize" [pageSizeOptions]="defaultPageSizeOptions"></mat-paginator>

  </ng-container>
</lib-page>
