<lib-page #page [noDataFound]="dataSource.data?.length === 0" [isLoading]="isLoading">
  <ng-container pageTitle>
    Users
  </ng-container>

  <ng-container pageContent *ngIf="selectedUserProfile === null">

    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h4>Filters</h4>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <form [formGroup]="formGroup" class="alt-theme" (submit)="onSearch()">
          <div class="input-wrapper">
            <mat-form-field appearance="outline">
              <mat-label>First Name</mat-label>
              <input matInput placeholder="Search by First Name" formControlName="firstName">
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Last Name</mat-label>
              <input matInput placeholder="Search by Last Name" formControlName="lastName">
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>User ID</mat-label>
              <input matInput placeholder="Search by User Id" formControlName="userId">
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Status</mat-label>
              <mat-select formControlName="active">
                <mat-option [value]="'all'">All</mat-option>
                <mat-option [value]="true">Active</mat-option>
                <mat-option [value]="false">Not Active</mat-option>
              </mat-select>
            </mat-form-field>

            <div class="button-div">
              <button
                mat-stroked-button
                color="accent"
                type="submit"
                class="search-button"
              >
                <mat-icon>search</mat-icon> Search
              </button>

              <button
                mat-stroked-button
                color="primary"
                (click)="clearSearch()"
              >
                Clear
              </button>
            </div>
          </div>
        </form>
      </mat-expansion-panel>
    </mat-accordion>


    <mat-table [dataSource]="dataSource">

      <ng-container matColumnDef="select">
        <mat-header-cell *matHeaderCellDef> {{selection.selected.length}} </mat-header-cell>
        <mat-cell *matCellDef="let user">
          <mat-checkbox [checked]="user?.selected" [disabled]="true"></mat-checkbox>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef> ID</mat-header-cell>
        <mat-cell *matCellDef="let user"> {{user?.id}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="userId">
        <mat-header-cell *matHeaderCellDef>User ID</mat-header-cell>
        <mat-cell *matCellDef="let user"> {{user?.userId}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef> Name</mat-header-cell>
        <mat-cell *matCellDef="let user"> {{user?.firstName}} {{user?.lastName}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="userGroup">
        <mat-header-cell *matHeaderCellDef> Group </mat-header-cell>
        <mat-cell *matCellDef="let user"> {{user?.userGroupId ? userGroups[user.userGroupId]?.name : ''}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef>
          <mat-menu #appMenu="matMenu">
            <ng-template matMenuContent let-name="name">
              <button
                *ngIf="canCreate"
                matTooltip="Create (c)"
                mat-icon-button color="accent"
                (click)="onCreate()">
                <mat-icon>add</mat-icon>
              </button>
              <button
                *ngIf="canDelete && selection.selected.length"
                matTooltip="Bulk Delete (d)"
                mat-icon-button color="warn"
                (click)="onDelete()">
                <mat-icon>delete</mat-icon>
              </button>
            </ng-template>
          </mat-menu>

          <button mat-icon-button [matMenuTriggerFor]="appMenu" [matMenuTriggerData]="{name: 'Sally'}">
            <mat-icon>more_vert</mat-icon>
          </button>
        </mat-header-cell>
        <mat-cell *matCellDef="let user">
          <mat-menu #appMenu="matMenu">
            <button
              [attr.data-userbutton]="'edit-user-' + user.userId"
              *ngIf="canUpdate"
              matTooltip="Edit (e)"
              mat-menu-item color="accent"
              (click)="openUserManagement(user)">
              <mat-icon>edit</mat-icon> Edit
            </button>
            <button
              [attr.data-userbutton]="'copy-user-permissions-' + user.userId"
              *ngIf="canUpdate"
              matTooltip="Copy/Clone Permissions"
              mat-menu-item color="accent"
              (click)="openCopyClonePermissions(user)">
              <mat-icon>content_copy</mat-icon> Copy/Clone Permissions
            </button>            
            <button
              [attr.data-userbutton]="'delete-user-' + user.userId"
              *ngIf="canDelete"
              matTooltip="Delete (d)"
              mat-menu-item color="accent"
              (click)="onDelete([user.id])">
              <mat-icon>delete</mat-icon> Delete
            </button>
          </mat-menu>

          <button mat-icon-button [matMenuTriggerFor]="appMenu" [matMenuTriggerData]="{name: 'Sally'}">
            <mat-icon>more_vert</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedColumns; let i=index"
        [tabindex]="i + 1"
        [attr.data-index]="i"
        [ngClass]="{ hovered: row.hovered, highlighted: selection.isSelected(row) }"
        (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"
      ></mat-row>
    </mat-table>
    <mat-paginator class="alt-theme" [length]="totalRowCount" [pageSize]="defaultPageSize" [pageSizeOptions]="defaultPageSizeOptions"></mat-paginator>

  </ng-container>

  <ng-container pageContent *ngIf="selectedUserProfile !== null">

    <div style="width: 100%; display:flex;">
      <button
        (click)="closeUserManagement()"
        mat-button
        color="accent"
      >
        <mat-icon>arrow_back</mat-icon> Back
      </button>
      <h3>User Profile Management for User ID: {{selectedUserProfile.userId}}</h3>
    </div>

    <app-user-management [userProfile]="selectedUserProfile"></app-user-management>
  </ng-container>
</lib-page>
