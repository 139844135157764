<div mat-dialog-title>
    Error Message: 
  </div>
  
  
  <div matDialogContent>
    <span class="error-text">{{data.messageText}}</span>
    <ng-container 
      *ngIf="data.activityDiaryMessageType == 'ITEMIZED_ANALYSIS_PRE_VALIDATION' && data.data.length > 0">
      <table class="ape-pre-validation-error-table">
        <tr>
          <th>Itemized Analysis Pre Validation</th>
        </tr>
        <ng-container *ngFor="let vError of data.data">
          <tr [ngClass]="{'ape-pre-validation-valid' : vError.valid, 'ape-pre-validation-invalid' : !vError.valid}">
            <td class="ape-pre-validation-error-td">
              {{vError.validationDescription}} 
            </td>
          </tr>
        </ng-container>
      </table>
    </ng-container>
  </div>
  
  <div matDialogActions class="fennec-controls">  
    <button (click)="onClose()" mat-flat-button color="primary">
        <mat-icon>cancel</mat-icon> Close
    </button>
  </div>