<div matDialogTitle>
    {{ this.mode }} state
  </div>
  
  <div matDialogContent>
    <form [formGroup]="formGroup" class="container">
      <mat-form-field appearance="outline" color="accent">
        <mat-label>Code</mat-label>
        <input matInput class="name" id="stateCode" formControlName="stateCode" required/>
      </mat-form-field>
      <mat-form-field appearance="outline" color="accent" [style.width.px]=400>
        <mat-label>State Name</mat-label>
        <textarea matInput cdkTextareaAutosize class="name" id="stateName" formControlName="stateName" required></textarea>
      </mat-form-field>
    </form>
  </div>
  
  <div matDialogActions class="fennec-dialog-action-button-container">
    <button mat-flat-button color="primary" (click)="cancel()">
      Cancel
    </button>
    <button [disabled]="formGroup.invalid" mat-flat-button color="accent" (click)="submit()">
      Save
    </button>
  </div>
  