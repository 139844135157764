import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseResponse, DataResponse, EnvironmentService, PagedResponse } from "xf-common";

@Injectable({
  providedIn: 'root'
})
export class XfwfService {

  constructor(
    public httpClient: HttpClient,
    private environment: EnvironmentService
  ) { }

  getTaskTypes = (): Observable<any> => {
    return this.httpClient.get<any>(this.environment.baseApiUrl + `xfwf/tasktypes`);
  }

  getTaskStatuses = (): Observable<any> => {
    return this.httpClient.get<any>(this.environment.baseApiUrl + `xfwf/taskstatuses`);
  }

  getTasks = (type?: string, status?: string, miCaseId?: number, first?: number, max?: number): Observable<PagedResponse<any>> => {
    return this.httpClient.get<PagedResponse<any>>(this.environment.baseApiUrl + `xfwf/${status}/list?type=${type}&id=${miCaseId}&first=${first}&max=${max}`);
  }

  getLatestNonNullTaskForMICase = (miCaseId?: number): Observable<PagedResponse<any>> => {
    return this.httpClient.get<PagedResponse<any>>(this.environment.baseApiUrl + `xfwf/latest-non-hold-task/${miCaseId}`);
  }  

  processXFWFTaskAction(id: number, actionName: string, payload: any = {}): Observable<BaseResponse> {
    return this.httpClient.put<any>(this.environment.baseApiUrl + "xfwf/" + id.toString() + "/" + actionName.toUpperCase(), payload);
  }

  createManualTask = (payload: any): Observable<DataResponse<any>> => {
    return this.httpClient.post<any>(`${this.environment.baseApiUrl}xfwf/create`, payload);
  }

  getTaskListByParams = (params:any, first:number, max:number): Observable<PagedResponse<any>> => {
    return this.httpClient.put<PagedResponse<any>>(`${this.environment.baseApiUrl}xfwf/list?first=${first}&max=${max}`, params);
  }
}
