import { Deserializable, deserializeAssign } from "../deserializable";


export class APERuleCollection implements Deserializable<APERuleCollection> {
  id?:number;
  name?:string;
  description?:string;
  uniqueIdentificationCode?:string;
  active?:boolean;

  deserialize(input: any): APERuleCollection {
    return deserializeAssign(this, input);
  }

  toJSON(): any {
    const obj: { [key: string]: any } = {};
    Object.assign(obj, this);
    delete obj["selected"];
    return obj;
  }
}
