<mat-form-field [class.mat-form-field-invalid]="reactive && customControl?.errors != null">
  <mat-label>{{label}}</mat-label>
  <input type="date" matInput [(ngModel)]="value" (blur)="onBlur()" min="1800-01-01" max="2040-01-01" (focus)="onFocus()" [name]="name" ngDefaultControl>
  <mat-hint *ngIf="reactive && customControl?.errors != null">
    <mat-error>
      Error
    </mat-error>
  </mat-hint>
<!--  <mat-hint>MM/DD/YYYY</mat-hint>-->
<!--  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>-->
<!--  <mat-datepicker #picker ></mat-datepicker>-->
</mat-form-field>
