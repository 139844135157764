
<div matDialogTitle>
    <div>
    Status Tracking
    </div>
    <div class="activity-log-dialog-sub-title">
    Domain: <b>{{configData?.statusTrackDomain}}</b>
    Related Id: <b>{{configData?.relatedId}}</b>
    </div>
  </div>
  
  <mat-dialog-content>

    <mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="statusTrackElement">
          <mat-header-cell *matHeaderCellDef> Element </mat-header-cell>
          <mat-cell *matCellDef="let c"> {{c.statusTrackElement}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="statusTrackStatusLabel">
        <mat-header-cell *matHeaderCellDef> Current Status </mat-header-cell>
        <mat-cell *matCellDef="let c"> {{c.statusTrackStatusLabel}} </mat-cell>
      </ng-container>        

      <ng-container matColumnDef="createdBy">
          <mat-header-cell *matHeaderCellDef> Created By </mat-header-cell>
          <mat-cell *matCellDef="let c"> {{c.createdBy}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="createdDate">
        <mat-header-cell *matHeaderCellDef> Created Date </mat-header-cell>
        <mat-cell *matCellDef="let c">
          {{c.createdDate | date: 'MM/dd/yyyy'}} <br> {{c.createdDate | date: 'shortTime'}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="lastModifiedDate">
        <mat-header-cell *matHeaderCellDef> Last Updated </mat-header-cell>
        <mat-cell *matCellDef="let c">
          {{c.lastModifiedDate | date: 'MM/dd/yyyy'}} <br> {{c.lastModifiedDate | date: 'shortTime'}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="lastModifiedBy">
          <mat-header-cell *matHeaderCellDef> Last Modified By </mat-header-cell>
          <mat-cell *matCellDef="let c"> {{c.lastModifiedBy}} </mat-cell>
      </ng-container>

      <!-- Status Track Log Column -->
      <ng-container matColumnDef="history">
        <mat-header-cell *matHeaderCellDef> Status Track Log </mat-header-cell>
        <mat-cell *matCellDef="let c">
          <button matTooltip="Dialog" mat-icon-button (click)="openStatusTrackLogDialog(c.statusTrackElement, c.id)">
            <mat-icon>view_kanban</mat-icon>
          </button>          
        </mat-cell>
      </ng-container>      

      <mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayColumns;"></mat-row>
    </mat-table>
    <mat-paginator #adPaginator [length]="totalRowCount" [pageSize]="defaultPageSize" [pageSizeOptions]="pageSizeOptions"></mat-paginator>

  </mat-dialog-content>
  
  <mat-dialog-actions>
    <button
      mat-stroked-button
      (click)="close()"
    >
      <mat-icon>close</mat-icon>
      Close
    </button>
  </mat-dialog-actions>