import { AfterViewInit, Component, Input, OnInit, Renderer2 } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {
  APERule,
  APERuleFilter,
  BaseCrudComponent,
  FennecSnackbarService,
  Logger,
  SingleChoiceDialogComponent
} from 'xf-common';
import { KeycloakService } from 'keycloak-angular';
import { Subject } from 'rxjs';
import { ApeRuleFilterFormDialogComponent } from '../ape-rule-filter-form-dialog/ape-rule-filter-form-dialog.component';
import {
  ApeRuleFilterStateDialogComponent
} from '../ape-rule-filter-state/ape-rule-filter-state-dialog/ape-rule-filter-state-dialog.component';
import { ApeRuleFilterService } from '../ape-rule-filter.service';
import {
  ApeRuleFilterPrimaryClientDialogComponent
} from '../ape-rule-filter-primary-client-dialog/ape-rule-filter-primary-client-dialog.component';
import {
  ApeRuleFilterHealthCarePlanDialogComponent
} from '../ape-rule-filter-health-care-plan/ape-rule-filter-health-care-plan-list/ape-rule-filter-health-care-plan-list.component';

@Component({
  selector: 'app-ape-rule-filter-list',
  templateUrl: './ape-rule-filter-list.component.html',
  styleUrls: ['./ape-rule-filter-list.component.scss', '../../ape-rule-admin-styles.scss', '../../../../../lib/styles/system-admin-styles.component.scss']
})
export class ApeRuleFilterListComponent extends BaseCrudComponent<APERuleFilter, ApeRuleFilterService> implements OnInit, AfterViewInit {

  displayedColumns: string[] = ["id", "domain", "name", "description", "active", "actions"];

  formGroup: FormGroup;
  dialogRef?: MatDialogRef<any>;
  domains: string[] = [""];

  adminColumns = ["id", "domain", "name", "devCode", "description", "active", "actions"];
  selectColumns = ["id", "domain", "name", "devCode", "description", "select"];
  /*
    Working mode values
    "ADMIN" - provides a fully searchable list with access to crud components.
    "SELECT" - provides an active record only searchable list without access to crud features
  */
  @Input()
  mode: string = "ADMIN";

  @Input()
  apeRule?: APERule;

  @Input()
  selectFilter?: Function;

  onEdit = (id: number | undefined) => {};
  onDelete = (ids: any[]) => {};

  protected log: Logger = new Logger("APERuleList");

  selectedApeRuleFilter: Subject<APERuleFilter> = new Subject();

  constructor(
    protected apeRuleFilterService: ApeRuleFilterService,
    public override snack: FennecSnackbarService,
    protected dialog: MatDialog,
    private renderer: Renderer2,
    protected keycloakService: KeycloakService
  ) {
    super(apeRuleFilterService);
    this.formGroup = this.createFormGroup();
  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
    this.executeListQuery();

    if(this.mode === "ADMIN") {
      this.displayedColumns = this.adminColumns;
    }

    if(this.mode === "SELECT") {
      this.displayedColumns = this.selectColumns;
    }

    this.performXFRequest({
      requestDescription: "GET Ape Rule Filter Domains",
      requestFn: this.apeRuleFilterService.getAPEFilterDomainList,
      fnParams: [],
      onResponse: response => {
        this.domains = response.data;
      },
      onError: errString => {
        super.showErrorSnack(errString);
      }
    })
  }

  executeListQuery = () => {
    const controls = this.formGroup?.controls;

    const searchParams = {
      name: controls["name"].value?.trim() ?? null,
      description: controls["description"].value?.trim() ?? null,
      active: controls["active"].value == "all" ? null : controls["active"].value?.trim() ?? null,
      apeRuleFilterDomain: controls["apeRuleFilterDomain"].value == 'all' ? null : controls["apeRuleFilterDomain"].value,
      apeRuleFilterDevCode: controls["devCode"].value?.trim() ?? null,
    }

    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;
    // this.paginator.pageIndex = first;
    // this.paginator.pageSize = pageSize;
    this.performXFRequest({
      requestDescription: "GET APE Rule Filters By Search Params",
      requestFn: this.apeRuleFilterService.getAPERuleFilters,
      fnParams: [searchParams, first, pageSize],
      onResponse: response => {
        this.dataSource = response.data;
        this.totalRowCount = response['totalRowCount'];
      },
      onError: errString => {
        super.showErrorSnack(errString)
      }
    })
  }

  createFormGroup = () => {
    return new FormGroup({
      name: new FormControl(),
      description: new FormControl(),
      devCode: new FormControl(),
      apeRuleFilterDomain: new FormControl('all'),
      active: new FormControl("all")
    })
  }

  getDeleteIconString = (rule:any): string => {
    if(rule.active) {
      return "delete";
    }
    return "restore_from_trash";
  }

  getIconStyle = (rule:any) => {
    if(rule?.active) {
      return {"color":"green"}
    }

    return {"color":"red"}
  }

  getDeleteButtonText = (rule:any): string => {
    if(rule.active) {
      return "Deactivate";
    }
    return "Activate";
  }

  clearSearch = () => {
    this.formGroup = this.createFormGroup();
    this.executeListQuery();
  }

  openFormDialog = (apeRuleFilter: APERuleFilter | null) => {
    const dialogConfig = new MatDialogConfig();
    if (apeRuleFilter) {
      dialogConfig.data = {
        mode: "Edit",
        apeRuleFilter: apeRuleFilter
      };
    }
    dialogConfig.autoFocus = "first-tabbable";
    this.dialogRef = this.dialog.open(ApeRuleFilterFormDialogComponent, dialogConfig);
    this.dialogRef.afterClosed().subscribe((response) => {
      this.executeListQuery();
    });
  }

  openDeactivateDialog = (apeRuleFilter: APERuleFilter) => {
    let mode = "Deactivate";
    if(apeRuleFilter.active == false) {
      mode = "Reactivate"
    }

    const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        question:`Are you sure you want to ${mode} ${apeRuleFilter.name}?`
      };
      this.dialogRef = this.dialog.open(SingleChoiceDialogComponent, dialogConfig);
      this.dialogRef.afterClosed().subscribe((result) => {
        if (result?.confirm && apeRuleFilter.id) {
          this.apeRuleFilterService._delete([apeRuleFilter.id]).subscribe(response => {
            if(response.hasErrors) {
              super.showErrorSnack(`Error processing ${mode} request`);
            }else{
              super.showSuccessSnack(`${mode} successful!`);
              this.executeListQuery();
            }
          })
        }
      });
  }

  selectAPERuleFilter(filter: APERuleFilter) {
    if(this.selectFilter) {
      this.selectFilter(filter);
    }
  }

  openStateDialog = (filter: APERuleFilter) => {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      apeRuleFilter:filter
    };
    const dialogRef = this.dialog.open(ApeRuleFilterStateDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(() => {
      this.executeListQuery();
    })
  }

  onSearch = () => {
    this.paginator?.firstPage();
    this.executeListQuery();
  }

  openPrimaryClientDialog = (filter: APERuleFilter) => {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "75vw";
    matDialogConfig.data = {
      apeRuleFilter:filter
    };
    const dialogRef = this.dialog.open(ApeRuleFilterPrimaryClientDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(() => {
      this.executeListQuery();
    })
  }

  openHealthCarePlanDialog = (filter: APERuleFilter) => {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "75vw";
    matDialogConfig.data = {
      apeRuleFilter:filter
    };
    const dialogRef = this.dialog.open(ApeRuleFilterHealthCarePlanDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(() => {
      this.executeListQuery();
    })
  }
}
