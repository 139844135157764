import { Deserializable, deserializeAssign } from "../deserializable";


export class AdmissionSourcePacket implements Deserializable<AdmissionSourcePacket> {
  id: number = -1;
  code: String="";
  admissionType: string="";
  description: String="";
  selected?: boolean;
  active?: boolean;

  deserialize(input: any): AdmissionSourcePacket {
    return deserializeAssign(this, input);
  }

  toJSON(): any {
    const obj: { [key: string]: any } = {};
    Object.assign(obj, this);
    delete obj["selected"];
    return obj;
  }
}
