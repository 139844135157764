<lib-page>
  <ng-container pageTitle>
    <span>EDI Run</span>
  </ng-container>
  <ng-container pageContent>
      <mat-table [dataSource]="ediRunList">

      <ng-container matColumnDef="ediRunId">
        <mat-header-cell *matHeaderCellDef>
          <!-- <mat-icon color="accent">work_outline</mat-icon> <pre> </pre>  -->
          EDI Run ID </mat-header-cell>
        <mat-cell ngClass="ediRunId" *matCellDef="let c">
          <button mat-button mat-raised-button color="accent" class="edi-run-btn" (click)="openCaseDialog(c.ediRunId)">
            <mat-icon matListIcon>cached</mat-icon>
           EDI Run ID: {{c.ediRunId}}
          </button>
       </mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef>
          <!-- <mat-icon color="accent">assessment</mat-icon><pre></pre>  -->
          Status </mat-header-cell>
        <mat-cell *matCellDef="let c">{{getFormattedStatus(c)}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="createdDate">
        <mat-header-cell *matHeaderCellDef>
          <!-- <mat-icon color="accent">date_range</mat-icon> <pre> </pre>  -->
          Created Date </mat-header-cell>
        <mat-cell *matCellDef="let c"> {{c.createdDate | date: 'MM/dd/yyyy'}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="createdBy">
        <mat-header-cell *matHeaderCellDef>
          <!-- <mat-icon color="accent" >supervised_user_circle</mat-icon> <pre> </pre>  -->
          Created By</mat-header-cell>
        <mat-cell ngClass="createdBy" *matCellDef="let c"> {{c.createdBy}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef>
          <!-- <mat-icon color="accent">pending_actions</mat-icon><pre></pre> -->
          <button mat-flat-button (click)="onCreateEDIRun()">
            <mat-icon>add_circle</mat-icon>
            New
        </button>
        </mat-header-cell>
        <mat-cell *matCellDef="let c">

          <mat-menu #appMenu="matMenu">

            <button
              mat-menu-item
              (click)="processEDIRun(c.ediRunId)"
              >
                <mat-icon color="accent">
                  play_circle_outline
                </mat-icon>
                Process EDI Run
              </button>

              <button
              mat-menu-item
              (click)="viewAttachmentDialog(c.ediRunId)"
              >
              <mat-icon color="accent">
                attach_file
              </mat-icon>
              View Attachments
            </button>

              <button
              mat-menu-item
              (click)="openDiaryDialog(c.ediRunId)"
              >
              <mat-icon color="accent">
                view_kanban
              </mat-icon>
              View Activity Diaries
            </button>
          </mat-menu>


          <button
            mat-icon-button
            [matMenuTriggerFor]="appMenu"
            [matMenuTriggerData]=""
            color="accent">
            <mat-icon>more_vert</mat-icon>
          </button>
        </mat-cell>
      </ng-container>


      <mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayColumns;"></mat-row>
    </mat-table>
    <mat-paginator [length]="totalRowCount" [pageSize]="defaultPageSize" [pageSizeOptions]="pageSizeOptions"></mat-paginator>
  </ng-container>
</lib-page>

