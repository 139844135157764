import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '../environment.service';

@Injectable({
  providedIn: 'root'
})
export class PatientService {

  private patientUrl = this.environment.baseApiUrl + "patient/";

  constructor(
    public httpClient: HttpClient,
    private environment: EnvironmentService
  ) {
  }

  getById = (id:number) => {
    return this.httpClient.get<any>(`${this.patientUrl}${id}`);
  }

  getCasesByPatientId = (id:number) => {
    return this.httpClient.get<any>(`${this.patientUrl}case-list/${id}`);
  }
}
