import { Deserializable } from "../deserializable";


export class ItemizedLineItemAdjustmentPacket extends Deserializable<ItemizedLineItemAdjustmentPacket> {
  ids?: number [] = [];
  adjustedUnits?: number = 0;
  adjustedAmount?: number = 0;
  adjustmentReasonCode?: string | null;
  adjustmentExplanationCode?: string | null;
}
