import { AfterViewInit, Component, OnInit } from '@angular/core';
import {
  AdjustmentExplanationCodePacket,
  BaseCrudComponent,
  CRUDDeleteStatusChangeDialogComponent, DataResponse,
  FennecSnackbarService,
  Logger,
  UserGroupQueryPacket, UserGroupPacket
} from "xf-common";
import { UserGroupService } from "../../user-group.service";
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
  MatLegacyDialogRef as MatDialogRef
} from "@angular/material/legacy-dialog";
import { KeycloakService } from "keycloak-angular";
import { UserGroupDialogComponent } from "../user-group-dialog/user-group-dialog.component";
import {FormControl, FormGroup} from "@angular/forms";
@Component({
  selector: 'app-user-group-list',
  templateUrl: './user-group-list.component.html',
  styleUrls: ['./user-group-list.component.scss', '../../../../lib/styles/system-admin-styles.component.scss']
})
export class UserGroupListComponent extends BaseCrudComponent<UserGroupQueryPacket, UserGroupService> implements OnInit, AfterViewInit  {

  protected log = new Logger("UserGroupListComponent");
  displayedColumns = ["name", "description", "actions"];
  dialogRef?: MatDialogRef<any>;
  statusDialogRef?: MatDialogRef<any>;
  filtersOpen: boolean;
  formGroup: FormGroup;

  constructor(
    override snack: FennecSnackbarService,
    protected userGroupService: UserGroupService,
    protected dialog: MatDialog,
    protected keycloakService: KeycloakService
  ) {
    super(userGroupService);
    this.canCreate = keycloakService.isUserInRole("ADMIN")
    this.canUpdate = keycloakService.isUserInRole("ADMIN")
    this.canDelete = keycloakService.isUserInRole("ADMIN")
    this.canDeactivate = keycloakService.isUserInRole("ADMIN")
    this.canView = keycloakService.isUserInRole("ADMIN")

    this.formGroup = this.createFormGroup();
  }

  ngOnInit() {
    this.executeListQuery();
  }

  ngAfterViewInit() {
    if (this.paginator) {
      this.paginator.page.subscribe(() => {
        // this.getList()
        this.executeListQuery();
      })
    }

  }

  onCreate = () => {
    const config = new MatDialogConfig();
    config.data = {
      mode: 'Create'
    }

    this.dialogRef = this.dialog.open(UserGroupDialogComponent, config);
    this.dialogRef.afterClosed().subscribe((val) => {
      if (val) {
        this.create(val).then(() => this.executeListQuery());
      }
    });
  };

  onDelete = (ids: any[]): void => {
    if (ids?.length === 1) {
      this.snack.showInfoSnack(`You deleted ${ids} from the list`);
      this.delete([ids[0]]);
    } else if (ids?.length) {
      this.snack.showInfoSnack(`(Coming soon) Bulk delete the following: ${this.selection.selected.map(v => v.id).join(" ")}`);
    }
  }

  onEdit = (id?: number): void => {
    if (!id) {
      return;
    }
    this.getById(id).then(res => {
      if (res && res.data) {
        this.openEditDialog(res.data);
      }
    }).catch(err => super.showErrorSnack(err));
  }

  onSearch() {
    this.paginator?.firstPage();
    this.executeListQuery();
  }

  clearSearch() {
    this.formGroup = this.createFormGroup();
  }

  openStatusChangeDialog(group) {
    if (!group) return;

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      mode: "status",
      source: group,
      label: "User Group"
    };
    this.statusDialogRef = this.dialog.open(CRUDDeleteStatusChangeDialogComponent, dialogConfig);
    this.statusDialogRef.afterClosed().subscribe({
      next: (value?: AdjustmentExplanationCodePacket) => {
        if (value) {
          this.performXFRequest({
            requestDescription: "Update user group status",
            requestFn: this.userGroupService.changeStatus,
            fnParams: [value],
            onSuccess: data => {
              super.showSuccessSnack("Successful!");
              // this.list();
              this.executeListQuery();
            },
            onError: errString => {
              super.showErrorSnack(errString);
            }
          })
        }
      }
    });
  }

  private createFormGroup() {
    return new FormGroup({
      name: new FormControl(),
      description: new FormControl(),
      active: new FormControl(true)
    });
  }

  private executeListQuery() {
    const controls = this.formGroup?.controls;

    const searchParams = {
      name: controls["name"].value?.trim() ?? null,
      description: controls["description"].value?.trim() ?? null,
      active: controls["active"].value,
    }

    if (searchParams.active === "all") {
      searchParams.active = null;
    }

    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;
    this.performXFRequest({
      requestDescription: "Get user groups with search params",
      requestFn: this.userGroupService.getUserGroupsByParams,
      fnParams: [searchParams, first, pageSize],
      onResponse: (response: DataResponse<UserGroupQueryPacket[]>) => {
        this.dataSource.data = response.data;
        this.totalRowCount = response['totalRowCount'];
      },
      onError: errString => {
        super.showErrorSnack(errString)
      }
    })
  }

  private openEditDialog(group: UserGroupQueryPacket) {
    if (!group) return;

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      mode: "Edit",
      group: group
    }
    dialogConfig.autoFocus = "first-tabbable";
    this.dialogRef = this.dialog.open(UserGroupDialogComponent, dialogConfig);
    this.dialogRef?.afterClosed().subscribe({
      next: (value?: UserGroupPacket) => {
        if (value) {
          this.update(value, {onSuccess: () => this.executeListQuery(), override: true});
        }
      }
    });
  }
}
