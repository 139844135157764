import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { APERule, BaseComponent, FennecSnackbarService, Logger } from 'xf-common';
import { ApeRuleService } from '../ape-rule-list/ape-rule.service';

@Component({
  selector: 'app-ape-rule-dialog',
  templateUrl: './ape-rule-dialog.component.html',
  styleUrls: ['./ape-rule-dialog.component.scss']
})
export class ApeRuleDialogComponent extends BaseComponent implements OnInit {

  id: number = -1;

  protected log = new Logger("ApeRuleDialogComponent");
  formGroup: FormGroup;

  types: string[] = [];

  constructor(
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<ApeRuleDialogComponent>,
    override snack: FennecSnackbarService,
    protected apeRuleService: ApeRuleService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    super();

    if(this.data?.rule?.id) {
      this.id = this.data.rule.id;
    }
    this.formGroup = this.createFormGroup(this.data?.rule);
   }

  ngOnInit(): void {
    this.performXFRequest({
      requestDescription: "GET APERule Types",
      requestFn: this.apeRuleService.getAPERuleTypes,
      fnParams: [],
      onResponse: response => {
        this.types = response.data;
      },
      onError: errString => {
        super.showErrorSnack(errString)
      }
    })
  }

  createFormGroup(rule:any) {
    return new FormGroup({
      name: new FormControl(rule != null ? rule.name : "", Validators.required),
      description: new FormControl(rule != null ? rule.description : "", Validators.required),
      serviceIdentificationCode: new FormControl(rule != null ? rule.serviceIdentificationCode : "", Validators.required),
      apeRuleType: new FormControl(rule != null ? rule.apeRuleType : "", Validators.required)
    })
  }

  close = () => {
    this.dialogRef.close();
  }

  getHeaderText = () => {
    let result = "";

    if(this.id == -1) {
      result = "Add New "
    }else{
      result = "Edit Existing "
    }

    result += "APE Rule";
    return result;
  }

  submit = () => {
    const controls = this.formGroup.controls;
    const dto = new APERule();
    dto.id = this.id;
    dto.name = controls["name"].value;
    dto.description = controls["description"].value;
    dto.serviceIdentificationCode = controls["serviceIdentificationCode"].value;
    dto.apeRuleType = controls["apeRuleType"].value;

    this.performXFRequest({
      requestDescription: "CREATE/UPDATE APERule Types",
      requestFn: this.apeRuleService._create,
      fnParams: [dto],
      onResponse: response => {
        super.showSuccessSnack("Save Complete");
        this.dialogRef.close();
      },
      onError: errString => {
        super.showErrorSnack(errString)
      }
    })
  }
}
