import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { BaseComponent, FennecSnackbarService, Logger } from 'xf-common';
import { ItemizedRevisionService } from '../itemized-revision.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-itemized-revision-revenue-code-summary',
  templateUrl: './itemized-revision-revenue-code-summary.component.html',
  styleUrls: ['./itemized-revision-revenue-code-summary.component.scss']
})
export class ItemizedRevisionRevenueCodeSummaryComponent extends BaseComponent implements AfterViewInit, OnInit {

  log: Logger = new Logger("ItemizedRevisionRevenueCodeSummaryComponent");

  @Input()
  itemizedRevisionId: string = "-1";

  revenueCodeSummaryData: any = {};

  revenueCodeSelected: Subject<any> = new Subject();
  mappedRevenueCodeSelected: Subject<any> = new Subject();

  constructor(
    private itemizedRevisionService: ItemizedRevisionService,
    protected override snack: FennecSnackbarService,
  ) {
    super();
   }

  ngAfterViewInit(): void {
    this.getRevenueCodeSummary();
  }

  ngOnInit(): void {

  }

  getRevenueCodeSummary() {
    this.performXFRequest({
      requestDescription: "GET Revenue Code Summary",
      requestFn: this.itemizedRevisionService.getItemizedRevisionRevenueCodeSummary,
      fnParams: [parseInt(this.itemizedRevisionId)],
      onSuccess: data => {
        this.revenueCodeSummaryData = data;
      },
      onError: errString => {
        super.showErrorSnack(errString);
      }
    });
  }

  onRevenueCodeSelected(rc: string) {
    this.revenueCodeSelected.next(rc);
  }

  onMappedRevenueCodeSelected(mrc: string) {
    this.mappedRevenueCodeSelected.next(mrc);
  }

}
