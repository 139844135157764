import { APERuleFilter } from "./APERuleFilter";

import { Deserializable, deserializeAssign } from "../deserializable";
import { HealthCarePlanPacket } from "./healthCarePlanPacket";

export class APERuleFilterHealthCarePlanPacket implements Deserializable<APERuleFilterHealthCarePlanPacket>  {
  id?:number;
  apeRuleFilter?: APERuleFilter;
  healthCarePlan?: HealthCarePlanPacket;

  deserialize(input: any): APERuleFilterHealthCarePlanPacket {
    return deserializeAssign(this, input);
  }

  toJSON(): any {
    const obj: { [key: string]: any } = {};
    Object.assign(obj, this);
    delete obj["selected"];
    return obj;
  }
}