<div matDialogTitle>
  Rules for APE Rule Collection ID: {{ this.apeRuleCollection?.id }} - {{ this.apeRuleCollection?.name }}

  <button
    mat-icon-button
    class="close-btn"
    (click)="closeDialog()"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>

<div class="dialog-wrapper-container" matDialogContent>
  <app-ape-rule-list mode="COLLECTION_RULES" apeRuleCollectionID={{this.apeRuleCollection?.id}}></app-ape-rule-list>
</div>