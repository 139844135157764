import { AfterViewInit, Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ActivityLogJsonDialogComponent, FennecSnackbarService, Logger } from 'xf-common';
import { ActivityLogService } from '../activity-log.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { registerCustomQueryHandler } from 'puppeteer';
import { MatDialogConfig } from '@angular/material/dialog';

@Component({
  selector: 'app-line-item-activity-log-dialog',
  templateUrl: './line-item-activity-log-dialog.component.html',
  styleUrls: ['./line-item-activity-log-dialog.component.scss']
})
export class LineItemActivityLogDialogComponent implements AfterViewInit, OnInit {

  configData: any;

  dataSource = new MatTableDataSource<any>()
  displayColumns = ['activityLogEntity', 'itemizedRevisionId', 'itemizedRevisionName', 
    'itemizedRevisionReviewType', 'beforeData', 'afterData', 'createdBy', 'createdDate'];  

  @ViewChild('adPaginator')
  paginator?: MatPaginator;

  totalRowCount?: number;
  defaultPageSize = 1000;
  pageSizeOptions = [1000];  

  constructor(
    private activityLogService: ActivityLogService,
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<LineItemActivityLogDialogComponent>,
    private snack: FennecSnackbarService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.configData = data;
  }

  ngOnInit(): void {
    this.getActivityLog();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.paginator) {
        this.paginator.page.subscribe(() => {
          this.getActivityLog();
        });
      }
    }, 200);
  }

  getActivityLog() {
    const lineItemId = this.configData.lineItemId;
    this.activityLogService.getAllActivityLogForLineItem(lineItemId).subscribe((response: any) => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.dataSource.data = response.data;
        this.totalRowCount = response.totalRowCount;
        this.formatDataForDisplay();
      }
    })
  }

  close() {
    const retObj = {
      confirm: false
    }
    this.dialogRef.close(retObj);
  }

  moreInfoDialog(x: any) {

  }

  formatDataForDisplay() {
    if (this.dataSource.data === null || this.dataSource.data === undefined) {
      return;
    }
    this.dataSource.data.forEach((rec) => {
      var obj = JSON.parse(rec.data);
      rec['beforeData'] = obj['beforeData'];
      rec['afterData'] = obj['afterData'];
      console.log(rec);
    }) 
  }

  viewJson(title: string, json: any) {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = { 
      title: title,
      jsonContent: JSON.parse(json),
    }
    const dialogRef = this.dialog.open(ActivityLogJsonDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {
      }
    });
  }

}
