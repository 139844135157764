import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ProviderEinDialogComponent } from '../provider-ein-dialog/provider-ein-dialog.component';
import { ProviderEINService } from '../provider-ein.service';
import { BaseComponent } from "../../../view/base.component";
import { SingleChoiceDialogComponent } from "../../../dialog/single-choice-dialog/single-choice-dialog.component";
import { FennecSnackbarService } from "../../../dialog/fennec-snackbar/fennec-snackbar.service";
import { Logger } from "../../../util/logger";

@Component({
  selector: 'app-provider-ein-list',
  templateUrl: './provider-ein-list.component.html',
  styleUrls: ['./provider-ein-list.component.scss']
})
export class ProviderEinListComponent extends BaseComponent implements OnInit, AfterViewInit {

  displayedColumns = ['id', 'federalTaxNumber', 'actions'];
  providerEINList: any [] = [];
  log: Logger = new Logger("ProviderEINListComponent");
  dialogRef?: MatDialogRef<any>

  @Input()
  providerId: number = -1;

  constructor(
    protected snack: FennecSnackbarService,
    protected dialog: MatDialog,
    public providerEINService: ProviderEINService,
  ) {
    super();
   }

  ngAfterViewInit(): void {
    this.fetchData();
  }

  ngOnInit(): void {
  }

  fetchData = () => {

    this.providerEINService.getProviderEINListByProviderID(this.providerId).subscribe(response => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.providerEINList = response.data;
      }
    })
  }

  openProviderEINDialog(providerEin:any = null) {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "65vw";
    matDialogConfig.data = {
      mode: providerEin == null ? "create" : "edit",
      existingId: providerEin == null ? null : providerEin.id,
      federalTaxNumber: providerEin == null ? null : providerEin.federalTaxNumber,
      providerId: this.providerId,
    };

    const dialogRef = this.dialog.open(ProviderEinDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.fetchData();
    });
  }

  deleteDialog = (ein:any) => {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    // matDialogConfig.height = "auto";
    // matDialogConfig.width = "auto";
    matDialogConfig.data = {
      question: `Are you sure you want to delete EIN ${ein.federalTaxNumber} from Provider ID:${this.providerId}?`
    };

    const dialogRef = this.dialog.open(SingleChoiceDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if(result.confirm) {
        this.providerEINService.deleteProviderEIN(ein.id).subscribe(response => {
          if (response.hasErrors) {
            this.snack.showErrorSnack(response.consolidatedErrorMessage);
          } else {
            this.snack.showSuccessSnack(`Successfully deleted EIN ${ein.federalTaxNumber}`);
            this.fetchData();
          }
        })
      }
    });
  }

}
