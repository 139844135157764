<div *ngIf="loading" class="loading-div">
  <div>
    <mat-spinner color="accent"></mat-spinner>
    <h2>Loading...</h2>
  </div>
</div>

<div *ngIf="!loading && attachment === null" class="loading-div">
  <h2>Cannot view attachment</h2>
</div>

<ngx-extended-pdf-viewer
  *ngIf="!loading && attachment !== null && attachment !== undefined"
  [src]="attachment"
  [delayFirstView]="250"
  [showDownloadButton]="false"
  [showOpenFileButton]="false"
  [showPrintButton]="false"
  >
</ngx-extended-pdf-viewer>
