<lib-page>

  <ng-container pageTitle>
    <span>Patients</span>
    <!-- <button class="add" mat-flat-button (click)="onCreate()">
      <mat-icon color="accent">add_circle</mat-icon>
      New
    </button> -->
  </ng-container>

  <ng-container pageContent>
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          Filters
        </mat-expansion-panel-header>

        <form [formGroup]="formGroup">
<!--          <mat-form-field class="alt-patient-id" appearance="outline">-->
<!--            <mat-label>Alternate Patient Id</mat-label>-->
<!--            <input matInput placeholder="Alternate Patient Id" formControlName="altPatientId">-->
<!--          </mat-form-field>-->

          <mat-form-field class="client-member-number" appearance="outline">
            <mat-label>Client Member Number</mat-label>
            <input matInput placeholder="Client Member Number" formControlName="clientMemberNumber">
          </mat-form-field>

          <div class="input-group">
            <mat-form-field class="input form-field-spacer" appearance="outline">
              <mat-label>
                First Name
              </mat-label>
              <input matInput placeholder="First Name" formControlName="firstName">
            </mat-form-field>
            <mat-form-field class="input form-field-spacer" appearance="outline">
              <mat-label>Last Name</mat-label>
              <input matInput placeholder="Last Name" formControlName="lastName">
            </mat-form-field>
          </div>
        </form>

        <button
          class="search-btn"
          mat-stroked-button
          (click)="getData()"
        >
          <mat-icon>search</mat-icon> Search
        </button>
      </mat-expansion-panel>
    </mat-accordion>

    <mat-table [dataSource]="patientList">

      <ng-container matColumnDef="altPatientId">
        <mat-header-cell *matHeaderCellDef> Alt Patient ID</mat-header-cell>
        <mat-cell *matCellDef="let patient"> {{patient.altPatientId}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef
          (click)="toggleNameSort()"
          style="cursor: pointer;"
        >
          <mat-icon>{{nameSort === "ASC" ? "arrow_drop_up" : "arrow_drop_down"}}</mat-icon>
          Name
        </mat-header-cell>
        <mat-cell *matCellDef="let patient"> {{patient.lastName}}, {{patient.firstName}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="dob">
        <mat-header-cell *matHeaderCellDef> Date of Birth</mat-header-cell>
        <mat-cell *matCellDef="let patient"> {{formatDate(patient.birthDateString)}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="address">
        <mat-header-cell *matHeaderCellDef> Address </mat-header-cell>
        <mat-cell *matCellDef="let patient">
          {{patient?.address?.addressLine1}} {{patient?.address?.addressLine2}} <br>
          {{ patient?.address?.city }}, {{ patient?.address?.stateCode }} {{ patient?.address?.zipCode}}
        </mat-cell>
      </ng-container>

<!--      <ng-container matColumnDef="clientMemberNumber">-->
<!--        <mat-header-cell *matHeaderCellDef> Client Member No </mat-header-cell>-->
<!--        <mat-cell *matCellDef="let patient">-->
<!--          {{patient?.clientMemberNumber}}-->
<!--        </mat-cell>-->
<!--      </ng-container>-->

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let patient">
          <button
            mat-raised-button
            color="accent"
            (click)="selectPatient(patient)"
          >
            Select
          </button>
        </mat-cell>
      </ng-container>


      <mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayColumns;"></mat-row>
      </mat-table>
      <mat-paginator
        #paginator
        [length]="totalRowCount"
        [pageSize]="defaultPageSize"
        [pageSizeOptions]="pageSizeOptions"
        (page)="getData()"></mat-paginator>

  </ng-container>
</lib-page>
