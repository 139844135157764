import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from "@angular/material/legacy-paginator";
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { ProviderAddComponent } from "@app/provider/provider-add/provider-add.component";
import {
  BaseComponent,
  FennecSnackbarService,
  Logger,
  ProviderEINService,
  ProviderService,
  SingleChoiceDialogComponent
} from "xf-common";

@Component({
  selector: 'app-provider-list',
  templateUrl: './provider-list.component.html',
  styleUrls: ['./provider-list.component.scss', '../../../lib/styles/system-admin-styles.component.scss']
})
export class ProviderListComponent extends BaseComponent implements OnInit, AfterViewInit {

  displayedColumns = ['id', 'name', 'npiNumber', 'firstName', 'lastName', 'address', 'type', 'actions'];
  providerList: any [] = [];
  log: Logger = new Logger("ProviderListComponent");
  dialogRef?: MatDialogRef<any>

  providerTypes: any;

  @ViewChild(MatPaginator)
  paginator?: MatPaginator;

  totalRowCount?: number;

  queryParams = {
    providerName: "",
    firstName: "",
    lastName: "",
    npiNumber: "",
    type: ""
  };

  federalTaxNumber:String = "";

  defaultPageSize = 20;
  pageSizeOptions = [5, 10, 20, 25, 50];

  constructor(
    private providerService: ProviderService,
    private providerEINService: ProviderEINService,
    protected snack: FennecSnackbarService,
    protected dialog: MatDialog,
  ) {
    super();
    this.getProviderTypes();
  }

  ngAfterViewInit(): void {
    if (this.paginator) {
      this.paginator.page.subscribe(() => {
        this.getProviders();
      })
    }
  }

  onCreate() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "40%";
    this.dialogRef = this.dialog.open(ProviderAddComponent, dialogConfig);
    this.dialogRef.afterClosed().subscribe( response => {
      if (response.confirm) {
        this.getProviders();
      }
    });
  }

  getProviderTypes() {
    this.performXFRequest({
      requestDescription: "GET Provider Types",
      requestFn: this.providerService.getProviderTypes,
      fnParams: [],
      onSuccess: data => {
        this.providerTypes = data;
      },
      onError: errString => {
        super.showErrorSnack(errString);
      }
    })
  }

  clearSearch() {
    this.queryParams.providerName = "";
    this.queryParams.firstName = "";
    this.queryParams.lastName = "";
    this.queryParams.npiNumber = "";
    this.queryParams.type = "";
    this.getProviders()
  }

  searchProviders() {
    this.paginator?.firstPage();
    this.getProviders();
  }

  ngOnInit(): void {
    this.getProviders();
  }

  getProviders() {
    this.queryParams.providerName = this.queryParams.providerName.trim();
    this.queryParams.firstName = this.queryParams.firstName.trim();
    this.queryParams.lastName = this.queryParams.lastName.trim();
    this.queryParams.npiNumber = this.queryParams.npiNumber.trim();
    this.queryParams.type = this.queryParams.type.trim();
    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;
    this.performXFRequest({
      requestDescription: "GET all providers",
      requestFn: this.providerService.getAllProviders,
      fnParams: [this.queryParams, first, pageSize],
      onResponse: response => {
        this.providerList = response.data;
        this.totalRowCount = response['totalRowCount'];
      },
      onError: errString => {
        super.showErrorSnack(errString)
      }
    })
  }

  editProvider(providerId:number) {
    alert(providerId);
  }

  onDeleteProvider(provider:any) {
    if (provider) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        question:`Are you sure you want to deactivate ${provider.name}?`
      };
      this.dialogRef = this.dialog.open(SingleChoiceDialogComponent, dialogConfig);
      this.dialogRef.afterClosed().subscribe((result) => {
        if(result?.confirm) {
          this.performXFRequest({
            requestDescription: "Delete provider",
            requestFn: this.providerService.deleteProvider,
            fnParams: [provider.id],
            onSuccess: response => {
              this.getProviders();
            },
            onError: errString => {
              super.showErrorSnack(errString)
            }
          })
        }
      })
    }
  }

  providerEINSearch() {
    this.paginator?.firstPage();

    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;

    const payload = {
      federalTaxNumber: this.federalTaxNumber
    }

    this.providerEINService.searchProviderByFederalTaxNumber(payload, first, pageSize).subscribe(response => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage ?? "Undefined Error Message");
      } else {
        this.providerList = response.data;
        this.totalRowCount = response['totalRowCount'];
      }
    })
  }
}
