import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EMPTY } from 'rxjs/internal/observable/empty';
import { APERuleCollection, BaseCrudService, Crud, DataResponse, PagedResponse } from "xf-common";


@Injectable()
export class ApeRuleCollectionService extends BaseCrudService<APERuleCollection> {
  public baseEndpoint = super.apiEndpoint("ape/collection");
  public collectionRuleEndpoint = super.apiEndpoint("ape-rule-collection-rule");

  constructor(
    public override httpClient: HttpClient
  ) {
    super(httpClient, "APERuleCollectionService", "APERuleCollection");
   }

  _list: Crud.ListFunction<APERuleCollection> = (first, max) => {
    const endpoint = `${this.baseEndpoint}/list`;
    return this.httpClient.get<PagedResponse<APERuleCollection[]>>(endpoint, { params: { first, max} })
  };

  _getById: Crud.GetByIdFunction<APERuleCollection> = () => {
    return EMPTY;
  };

  _create: Crud.CreateFunction<APERuleCollection> = (payload) => {
    const endpoint = `${this.baseEndpoint}/create-update`;
    return this.httpClient.post<DataResponse<APERuleCollection>>(endpoint, payload);
  };
  _update: Crud.UpdateFunction<APERuleCollection> = (payload) => {
    const endpoint = `${this.baseEndpoint}/create-update`;
    return this.httpClient.post<DataResponse<APERuleCollection>>(endpoint, payload);
  };

  _deactivate: Crud.DeleteFunction<APERuleCollection> = (id) => {
    const endpoint = `${this.baseEndpoint}/delete`;
    return this.httpClient.put<DataResponse<boolean>>(endpoint, id[0]);
  };
  _delete: Crud.DeleteFunction<APERuleCollection> = (id) => {
    const endpoint = `${this.baseEndpoint}/${id}`;
    return this.httpClient.delete<DataResponse<boolean>>(endpoint);
    // return EMPTY;
  };

  protected _crudFunctions: Crud.CrudServiceDef<APERuleCollection> = {
    _list: this._list,
    _getById: this._getById,
    _create: this._create,
    _update: this._update,
    _deactivate: this._deactivate,
    _delete: this._delete
  };

  deactivate = (id: number) => {
    const endpoint = `${this.baseEndpoint}/delete`;
    return this.httpClient.put<DataResponse<boolean>>(endpoint, id);
  }

  getAPERuleCollections = (queryParams: {}, first?: number, max?: number): Observable<PagedResponse<any>> => {
    return this.httpClient.put<PagedResponse<any>>(this.baseEndpoint + "/list?first=" + first + "&max=" + max, queryParams)
  }

  getAPERuleCollectionRulesByAPERuleCollectionId = (id: number, first?: number, max?: number): Observable<PagedResponse<any>> => {
    return this.httpClient.get<PagedResponse<any>>(this.collectionRuleEndpoint + "/list/" + id + "?first=" + first + "&max=" + max);
  }

  createUpdateAPERuleCollection = (payload:any): Observable<PagedResponse<any>> => {
    return this.httpClient.post<PagedResponse<any>>(this.collectionRuleEndpoint + "/create-update", payload);
  }

  reorder = (payload:any): Observable<PagedResponse<any>> => {
    return this.httpClient.post<PagedResponse<any>>(this.collectionRuleEndpoint + "/reorder", payload);
  }

  deleteCollectionRule = (id:number): Observable<PagedResponse<any>> => {
    const endpoint = `${this.collectionRuleEndpoint}/${id}`;
    return this.httpClient.delete<DataResponse<boolean>>(endpoint);
  }
}
