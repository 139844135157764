import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UserProfileService } from "@app/user/user-profile.service";
import { BaseComponent, FennecSnackbarService, Logger, UserProfilePacket } from 'xf-common';


@Component({
  selector: 'app-user-update-form',
  templateUrl: './user-update-form.component.html',
  styleUrls: ['./user-update-form.component.css']
})
export class UserUpdateFormComponent extends BaseComponent {
  log = new Logger("UserUpdateFormComponent");

  constructor(
    protected snack: FennecSnackbarService,
    private userProfileService: UserProfileService,
    public dialogRef: MatDialogRef<UserUpdateFormComponent>,
    @Inject(MAT_DIALOG_DATA) public userProfile: UserProfilePacket | any
  ) {
    super();
  }

  onCancel() {
    this.dialogRef.close();
  }

  onSave() {
    this.performXFRequest(this.userProfileService.updateRequest
      .def({
        fnParams: [this.userProfile]
      })
      .handle({
        onSuccess: data => {
          super.showSuccessSnack("Successfully saved");
          this.dialogRef.close(this.userProfile);
        },
        onError: message => {
          this.showErrorSnack(message);
        }
      })
    );
  }

}
