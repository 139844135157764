import { Deserializable, deserializeAssign } from "../deserializable";

export class ActivityDiaryQueryRequestPacket implements Deserializable<ActivityDiaryQueryRequestPacket> {
    
    activityDiaryDomain?: string;
    activityDiaryMessageType?: string;

    deserialize(input: any): ActivityDiaryQueryRequestPacket {
        return deserializeAssign(this, input)
    }

    toJSON(): any {
        const obj: {[key: string]:any} = {}
        Object.assign(obj, this)
        delete obj["selected"]
        return obj
    }
}
