<div matDialogTitle class="duplicate-entity-dialog-title">
  {{entityType}} Duplicate Info
</div>


<div matDialogContent class="duplicate-entity-dialog-content">
  <div class="duplicate-summary-info">
    <span class="parent-info">
      {{entityType}} is currently
      <ng-template #notMarkedDuplicate>
        <span class="not-marked-duplicate">
          not marked as duplicate
        </span>
      </ng-template>
      <ng-container *ngIf="entityHasParent; else notMarkedDuplicate" class="marked-duplicate">
        <span class="marked-duplicate">
          marked as duplicate
        </span>
      </ng-container>
    </span>
    <span
      class="children-info"
      *ngIf="entityHasChildren"
    >
      {{entityType}} has children flagged as duplicates:
      <div class="child-entity-id-string">
        {{ childEntitiesDisplayString }}
      </div>
    </span>
  </div>

  <form
    id="duplicate-entity-dialog-form"
    [formGroup]="formGroup"
    class="alt-theme"
    (keydown.enter)="submit()"
  >
    <mat-form-field>
      <mat-label>Parent ID</mat-label>
      <input class="input" matInput formControlName="duplicateParentEntityId" placeholder="Parent ID">
    </mat-form-field>
  </form>
</div>

<div matDialogActions class="duplicate-entity-dialog-actions">
  <button
    id="submit-duplicate-entity-info"
    type="button"
    mat-flat-button
    color="accent"
    (click)="submit()"
  >
    <mat-icon>save</mat-icon>
    Save
  </button>

  <button
    mat-stroked-button
    (click)="onCancel()">
    <mat-icon>cancel</mat-icon>
    Cancel
  </button>
</div>
