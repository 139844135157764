import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AdjustmentReasonCodePacket, BaseCrudService, Crud, DataResponse, PagedResponse } from "xf-common";


@Injectable()
export class AdjustmentReasonCodeService extends BaseCrudService<AdjustmentReasonCodePacket> {
    baseEndpoint = super.apiEndpoint("adjustment-reason-code")

    constructor(public override httpClient: HttpClient) {
      super(httpClient, "AdjustmentReasonCodeService", "AdjustmentReasonCode");
    }

    _list: Crud.ListFunction<AdjustmentReasonCodePacket> = (first, max) => {
      const endpoint = `${this.baseEndpoint}/list`
      return this.httpClient.get<PagedResponse<AdjustmentReasonCodePacket[]>>(endpoint, {params: {first, max}})
    }

    _getById: Crud.GetByIdFunction<AdjustmentReasonCodePacket> = (id) => {
      const endpoint = `${this.baseEndpoint}/${id}`
      return this.httpClient.get<PagedResponse<AdjustmentReasonCodePacket>>(endpoint)
    }

    _create: Crud.CreateFunction<AdjustmentReasonCodePacket> = (user) => {
      const endpoint = `${this.baseEndpoint}/create`
      return this.httpClient.post<PagedResponse<AdjustmentReasonCodePacket>>(endpoint, user)
    }

    _update: Crud.UpdateFunction<AdjustmentReasonCodePacket> = (user): Observable<DataResponse<AdjustmentReasonCodePacket>> => {
      const endpoint = `${this.baseEndpoint}/update`
      return this.httpClient.put<PagedResponse<AdjustmentReasonCodePacket>>(endpoint, user)
    }

    _deactivate: Crud.DeleteFunction<AdjustmentReasonCodePacket> = (ids) => {
        const endpoint = `${this.baseEndpoint}/deactivate`
        return this.httpClient.put<DataResponse<boolean>>(endpoint, ids[0])
    }

    _delete: Crud.DeleteFunction<AdjustmentReasonCodePacket> = (ids) => {
      const endpoint = `${this.baseEndpoint}/delete/` + ids
      return this.httpClient.delete<DataResponse<boolean>>(endpoint)
    }

    changeStatus = (adjustmentReasonCodePacket: AdjustmentReasonCodePacket): Observable<DataResponse<AdjustmentReasonCodePacket>> => {
      const endpoint = `${this.baseEndpoint}/status`;
      return this.httpClient.put<DataResponse<AdjustmentReasonCodePacket>>(endpoint, adjustmentReasonCodePacket);
    }


    protected _crudFunctions: Crud.CrudServiceDef<AdjustmentReasonCodePacket> = {
      _list: this._list,
      _getById: this._getById,
      _create: this._create,
      _update: this._update,
      _deactivate: this._deactivate,
      _delete: this._delete
    }

    getAdjustmentReasonCodesByParams = (packet:any, first:number, max:number) => {
      const endpoint = `${this.baseEndpoint}/list?first=${first}&max=${max}`;
      return this.httpClient.post<PagedResponse<AdjustmentReasonCodePacket>>(endpoint, packet);
    }

    getAdjustmentReasonTypes = () => {
      const endpoint = `${this.baseEndpoint}/adjustment-reason-types`;
      return this.httpClient.get<PagedResponse<any>>(endpoint);
    }
}
