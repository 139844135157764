<div mat-dialog-title>
  {{data?.title}}
  <div class="user-search-dialog-subtitle" *ngIf="data?.subtitle">
    {{data.subtitle}}
  </div>
</div>

<div matDialogContent>

  <mat-accordion>
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h4>Search</h4>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form [formGroup]="formGroup" class="alt-theme" (submit)="executeSearchQuery()">
        <div class="input-wrapper">
          <mat-form-field appearance="outline" class="search-field">
            <mat-label>First Name</mat-label>
            <input matInput placeholder="Search by First Name" formControlName="firstName">
          </mat-form-field>

          <mat-form-field appearance="outline" class="search-field">
            <mat-label>Last Name</mat-label>
            <input matInput placeholder="Search by Last Name" formControlName="lastName">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>User ID</mat-label>
            <input matInput placeholder="Search by User Id" formControlName="userId">
          </mat-form-field>
        </div>

          <div class="button-div">
            <button
              mat-stroked-button
              color="accent"
              type="submit"
              class="search-button"
            >
              <mat-icon>search</mat-icon> Search
            </button>

            <button
              mat-stroked-button
              color="primary"
              (click)="clearSearch()"
            >
              Clear
            </button>
          </div>
      </form>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef> User ID</mat-header-cell>
      <mat-cell *matCellDef="let user"> {{user?.userId}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef> Name</mat-header-cell>
      <mat-cell *matCellDef="let user"> {{user?.firstName}} {{user?.lastName}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let user">
          <button
            matTooltip="Select"
            mat-icon-button color="accent"
            (click)="onUserProfileSelect(user)">
            Select
          </button>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: displayedColumns; let i=index"
      [tabindex]="i + 1"
      [attr.data-index]="i"
      [ngClass]="selectedUser != null && selectedUser.id == row.id ? 'user-profile-selected' : 'user-profile-not-selected'"
    ></mat-row>
  </mat-table>
  <mat-paginator [length]="totalRowCount" [pageSize]="defaultPageSize" [pageSizeOptions]="pageSizeOptions"></mat-paginator>

</div>

<div matDialogActions class="fennec-controls">
  <button (click)="onSubmit()" mat-flat-button color="accent" [disabled]="isSaveDisabled()">
      <mat-icon>save_as</mat-icon> {{data.actionLabel ?? "Select"}}
  </button>
  <button (click)="onClose()" mat-flat-button color="primary">
      <mat-icon>cancel</mat-icon> {{this.data?.selectedUser ? "Clear" : "Cancel"}}
  </button>
</div>


