import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { BaseComponent, FennecSnackbarService, Logger, OccurrenceCodePacket } from "xf-common";

@Component({
  selector: 'app-occurrence-code-dialog',
  templateUrl: './occurrence-code-dialog.component.html',
  styleUrls: ['./occurrence-code-dialog.component.scss']
})
export class OccurrenceCodeDialogComponent extends BaseComponent implements OnInit {
  protected log = new Logger("OccurrenceCodeDialogComponent")
  mode?: "create" | "edit" | "view"
  occurrenceCode?: OccurrenceCodePacket
  formGroup: FormGroup

  constructor(
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<OccurrenceCodeDialogComponent>,
    override snack: FennecSnackbarService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    super();
    this.mode = this.data?.mode
    this.occurrenceCode = this.data?.occurrenceCode
    this.formGroup = new FormGroup({
      code: new FormControl(this.occurrenceCode?.code ?? "", Validators.required),
      description: new FormControl(this.occurrenceCode?.description ?? "", [Validators.required, Validators.maxLength(100)])
    })
  }

  ngOnInit(): void {
  }

  submit() {
    const occurrenceCode: OccurrenceCodePacket = this.occurrenceCode ?? new OccurrenceCodePacket()
    const formValue = this.formGroup.value
    if (!formValue.code) {
      super.showErrorSnack("Code is required")
      return
    }
    if (!formValue.description) {
      super.showErrorSnack("Description required")
      return
    }
    occurrenceCode.code = formValue["code"]
    occurrenceCode.description = formValue["description"]
    this.dialogRef.close(occurrenceCode)
  }
  cancel() {
    this.dialogRef.close()
  }

}
