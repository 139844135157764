<lib-page>
  <ng-container pageTitle>
    <button
      mat-raised-button
      color="accent"
      class="back-btn"
      (click)="backButton()"
    >
      <mat-icon>arrow_back</mat-icon>
      Back
    </button>
    <span>{{entity}} Activity Log for ID: {{selectedRelatedId}} </span>
    <span class="header-data-element-span">Stay: <span class="header-data-value-span">{{miCase?.id}}</span></span>
    <span class="header-data-element-span" *ngIf="miCase?.altExternalId != null">Alt Ext Id: <span class="header-data-value-span">{{miCase?.altExternalId}}</span></span>
    <span class="header-data-element-span" *ngIf="miCase?.name != null">Name: <span class="header-data-value-span">{{miCase?.name}}</span></span>
  </ng-container>

  <ng-container pageContent>
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>Filters</mat-expansion-panel-header>

        <form [formGroup]="formGroup">
          <mat-radio-group formControlName="userId">
            <mat-radio-button
              class="radio-btn"
              value="MINE"
            >My Activities
            </mat-radio-button>

            <mat-radio-button
              value="ALL"
            >Activities for All Users
            </mat-radio-button>
          </mat-radio-group>
        </form>
      </mat-expansion-panel>
    </mat-accordion>

    <mat-table [dataSource]="dataSource">

        <ng-container matColumnDef="relatedId">
          <mat-header-cell *matHeaderCellDef> Task ID </mat-header-cell>
          <mat-cell *matCellDef="let c"> {{c.id}} </mat-cell>
        </ng-container>


        <ng-container matColumnDef="activityLogEntity">
          <mat-header-cell *matHeaderCellDef> Entity </mat-header-cell>
          <mat-cell *matCellDef="let c"> {{c.activityLogEntity}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="activityLogType">
            <mat-header-cell *matHeaderCellDef> Log Type</mat-header-cell>
            <mat-cell *matCellDef="let c"> {{c.activityLogType}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="changed">
            <mat-header-cell *matHeaderCellDef>Value Changed</mat-header-cell>
            <mat-cell *matCellDef="let c">
              {{
                c?.changes?.length > 0 && c.changes[0]?.key ? c.changes[0].key : "None"
              }}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="beforeData">
          <mat-header-cell *matHeaderCellDef> Before Data </mat-header-cell>
          <mat-cell *matCellDef="let c">
            <!-- <div style="width: 442px; word-wrap: break-word; padding: 10px">
            {{c.beforeData}}
            </div> -->

            <div *ngIf="c?.changes?.length > 0 && c?.changes[0]?.beforeData">
              {{c.changes[0].beforeData}}
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="afterData">
          <mat-header-cell *matHeaderCellDef> After Data </mat-header-cell>
          <mat-cell *matCellDef="let c">
            <!-- <div style="width: 442px; word-wrap: break-word; padding: 10px">
            {{c.afterData}}
            </div> -->
            <div *ngIf="c?.changes?.length > 0 && c?.changes[0]?.afterData">
              {{c.changes[0].afterData}}
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="miCaseId">
            <mat-header-cell *matHeaderCellDef> Stay ID </mat-header-cell>
            <mat-cell *matCellDef="let c"> {{c.miCaseId}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="createdBy">
            <mat-header-cell *matHeaderCellDef> Created By </mat-header-cell>
            <mat-cell *matCellDef="let c"> {{c.createdBy}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="createdDate">
          <mat-header-cell *matHeaderCellDef> Created Date </mat-header-cell>
          <mat-cell *matCellDef="let c">
            {{c.createdDate | date: 'MM/dd/yyyy'}} <br> {{c.createdDate | date: 'shortTime'}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="lastModifiedDate">
          <mat-header-cell *matHeaderCellDef> Last Updated </mat-header-cell>
          <mat-cell *matCellDef="let c">
            {{c.lastModifiedDate | date: 'MM/dd/yyyy'}} <br> {{c.lastModifiedDate | date: 'shortTime'}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="lastModifiedBy">
            <mat-header-cell *matHeaderCellDef> Last Modified By </mat-header-cell>
            <mat-cell *matCellDef="let c"> {{c.lastModifiedBy}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="changes">
          <mat-header-cell *matHeaderCellDef>Changes Made</mat-header-cell>
          <mat-cell *matCellDef="let c" class="changes-column">
            <!-- <div *ngFor="let change of c.changes">
              <h4>{{change.key}}</h4>

              <p>
                <span>Before: </span> {{change.beforeData}}
              </p>

              <p>
                <span>After: </span> {{change.afterData}}
              </p>
            </div>

            <span
              *ngIf="c?.changes?.length > 0"
              (click)="moreInfoDialog(c)"
              class="more-info-span">
              More Info
            </span> -->

            <button
              *ngIf="c?.changes?.length > 0"
              (click)="moreInfoDialog(c)"
              mat-stroked-button
            >
              <mat-icon>info</mat-icon>
              More Info
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayColumns;"></mat-row>
      </mat-table>
      <mat-paginator [length]="totalRowCount" [pageSize]="defaultPageSize" [pageSizeOptions]="pageSizeOptions"></mat-paginator>
  </ng-container>
</lib-page>

