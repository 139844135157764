
<div matDialogTitle>
  Contact
</div>

<div matDialogContent class="alt-theme">
  @if (loading) {
    <div>
      <mat-spinner></mat-spinner>
      Loading...
    </div>
  }@else {
    <form [formGroup]="formGroup">
      <div class="input-group">
        <mat-form-field class="input form-field-spacer" appearance="outline">
          <mat-label>
            Name
          </mat-label>
          <input matInput placeholder="Name" formControlName="name">
        </mat-form-field>
    
        <mat-form-field class="input form-field-spacer" appearance="outline">
          <mat-label>Phone Number</mat-label>
          <input matInput placeholder="phoneNumber" formControlName="phoneNumber">
        </mat-form-field>
    
        <mat-form-field class="input form-field-spacer" appearance="outline">
          <mat-label>Fax Number</mat-label>
          <input matInput placeholder="Fax Number" formControlName="faxNumber">
        </mat-form-field>
    
        <mat-form-field class="input form-field-spacer" appearance="outline">
          <mat-label>Email Address</mat-label>
          <input matInput placeholder="Email Address" formControlName="emailAddress">
        </mat-form-field>

        <mat-form-field class="input form-field-spacer" appearance="outline">
          <mat-label>Contact Type</mat-label>
          <!-- <input matInput placeholder="Email Address" formControlName="contactType"> -->
          <mat-select
            formControlName="contactType"
          >
            @for(type of typeOptions; track type) {
              <mat-option
                [value]="type.value"
              >
                {{ type.label }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </form>
  }
</div>

<div matDialogActions class="actions">
  <button
    mat-raised-button
    color="accent"
    [disabled]="!this.formGroup.valid"
    (click)="save()"
  >
    <mat-icon>save</mat-icon>
    Save
  </button>

  <button
    mat-stroked-button
    (click)="cancel()"
  >
    <mat-icon>cancel</mat-icon>
    Cancel
  </button>
</div>