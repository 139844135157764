import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { AddressComponent } from '../address.component';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { FormGroup } from '@angular/forms';
import { ProviderService } from '../../../provider/provider.service';
import { BaseComponent } from '../../../view/base.component';
import { FennecSnackbarService } from '../../../dialog/fennec-snackbar/fennec-snackbar.service';
import { Logger } from '../../../util/logger';

@Component({
  selector: 'lib-address-dialog',
  templateUrl: './address-dialog.component.html',
  styleUrl: './address-dialog.component.scss'
})
export class AddressDialogComponent extends BaseComponent implements AfterViewInit {
  protected override log: Logger = new Logger("AddressDialogComponent");
  
  @ViewChild('address')
  addressComponent?: AddressComponent;
  
  mode: "address" | "provider_address" = "address";
  saveMode: "CREATE" | "EDIT" = "CREATE";
  
  providerAddressType = null;
  providerAddressTypeOptions = [];

  addressId:number = null;
  providerAddressId:number = null;

  constructor(
    protected override snack: FennecSnackbarService,
    private providerService: ProviderService,
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<AddressDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    super();

    if (data.mode) {
      this.mode = data.mode;
    }

    if (data.saveMode) {
      this.saveMode = data.saveMode;
    }

    if (data.addressId) {
      this.addressId = data.addressId;
    }

    if (data.providerAddress) {
      this.providerAddressId = data.providerAddress.id;
      this.providerAddressType = data.providerAddress.providerAddressType;
    }
  }

  ngAfterViewInit(): void {
    this.getProviderAddressType();
    this.addressComponent.addressId = this.addressId
    this.addressComponent.mode = this.saveMode;
  }

  findAddressComponent() {
    if (this.addressComponent !== null && this.addressComponent !== undefined) {
      this.addressComponent?.addressSaveComplete?.subscribe((id: number) => {
        this.close(id);
      });
    }
  }

  saveAddress = () => {
    this.findAddressComponent();
    this.addressComponent?.saveAddressToServer();
  }

  close = (id) => {
    const data = {
      providerAddressId: this.providerAddressId,
      addressId: id, 
      providerAddressType: this.providerAddressType
    }
    this.dialogRef.close(data);
  }

  cancel() {
    this.close(null);
  }

  getProviderAddressType = () => {
    this.performXFRequest({
      requestDescription: "GET Provider Address Types",
      requestFn: this.providerService.getProviderAddressType,
      fnParams: [],
      onSuccess: data => {
        this.providerAddressTypeOptions = data;
      },
      onError: errString => {
        super.showErrorSnack(errString);
      }
    })
  }

  isValid = () => {
    if (this.mode == "provider_address") {
      return this.addressComponent?.formGroup?.valid && this.providerAddressType != null
    }else {
      return this.addressComponent?.formGroup?.valid
    }
  }
}
