import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ItemizedLineItemService } from '../itemized-line-item.service';
import { AddCommentComponent } from '@app/comments/add-comment/add-comment.component';
import {
  BaseResponse,
  CodeValidationService,
  FennecSnackbarService,
  ItemizedLineItemAppealAdjustmentPacket,
  SingleChoiceDialogComponent
} from "xf-common";


@Component({
  selector: 'app-itemized-line-item-appeal-adj-dialog',
  templateUrl: './itemized-line-item-appeal-adj-dialog.component.html',
  styleUrls: ['./itemized-line-item-appeal-adj-dialog.component.scss']
})
export class ItemizedLineItemAppealAdjDialogComponent implements OnInit, AfterViewInit {

  formGroup: FormGroup = new FormGroup({});

  liData: any = {};

  // Line Item Information Display
  liServiceDateString = "01/01/1900";
  liDescription = "Unknown";

  internalReasonCode:boolean = false;
  internalExplanationCode:boolean = false;

  // List of all Appeal Decision Codes
  appealDecisionCodes: any [] = [];

  ids = [];
  addCommentVisible:boolean = false;
  showOverturnAmount:boolean = false;

  singleChoiceDialogRef?: MatDialogRef<any>;

  constructor(
    public dialogRef: MatDialogRef<ItemizedLineItemAppealAdjDialogComponent>,
    private snack: FennecSnackbarService,
    private itemizedLineItemService: ItemizedLineItemService,
    private codeValidationService: CodeValidationService,
    public matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.liData = data;
    this.liServiceDateString = data.liServiceDateString;
    this.liDescription = data.liDescription;
    this.populateFormGroup(data);
    if(data.ids) {
      this.ids = data.ids;
    }
    this.showOverturnAmount = 
      data.appealDecision !== null && 
      data.appealDecision !== undefined && 
      data.appealDecision.toUpperCase() === "OVERTURNED";
  }

  populateFormGroup(data: any) {
    const newFormGroup = new FormGroup({
      appealReasonCode: new FormControl(data.appealReasonCode, Validators.required),
      appealReasonCodeDescription: new FormControl(data.appealReasonCodeDescription),
      appealExplanationCode: new FormControl(data.appealExplanationCode, Validators.required),
      appealExplanationCodeDescription: new FormControl(data.appealExplanationCodeDescription),
      appealDecision: new FormControl(data.appealDecision),
      overturnAmount: new FormControl(data.overturnAmount),
      adjustedUnits: new FormControl(data.adjustedUnits),
      adjustedAmount: new FormControl(data.adjustedAmount)
    });

    newFormGroup.controls['appealReasonCodeDescription'].disable();

    newFormGroup.controls['appealReasonCode'].valueChanges.subscribe(() => {
      this.validateAppealReasonCode();
    })

    newFormGroup.controls['appealExplanationCodeDescription'].disable();

    newFormGroup.controls['appealExplanationCode'].valueChanges.subscribe(() => {
      this.validateAppealExplanationCode();
    })

    this.formGroup = newFormGroup;

    if (data.appealReasonCode) {
      this.validateReasonCodeOnLoad(data.appealReasonCode);
    }
    if (data.appealExplanationCode) {
      this.validateAppealExplanationCodeOnLoad(data.appealExplanationCode);
    }
  }

  ngOnInit(): void {
    this.getAllAppealDecisionCodes();
  }

  ngAfterViewInit(): void {
  }

  isMulti() {
    return this.ids?.length > 1;
  }

  onSubmit() {
    this.formGroup.markAllAsTouched();
    let dto: ItemizedLineItemAppealAdjustmentPacket = new ItemizedLineItemAppealAdjustmentPacket();
    dto.ids = this.data.ids;
    dto.appealExplanationCode = this.formGroup.controls['appealExplanationCode'].value;
    dto.appealReasonCode = this.formGroup.controls['appealReasonCode'].value;
    dto.appealDecision = this.formGroup.controls['appealDecision'].value;
    dto.overturnAmount = this.formGroup.controls['overturnAmount'].value;
    dto.adjustedAmount = this.formGroup.controls['adjustedAmount'].value;
    dto.adjustedUnits = this.formGroup.controls['adjustedUnits'].value;

    this.itemizedLineItemService.putItemizedLineItemAppealAdjustment(dto).subscribe(response => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        const returnObj = {
          confirm: true,
          ids: this.data.ids
        }
        this.dialogRef.close(returnObj);
        this.snack.showSuccessSnack(`Successfully Updated!`);
      }
    });
  }

  getAllAppealDecisionCodes() {
    this.itemizedLineItemService.getAllAppealDecisionCodes().subscribe(response => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.appealDecisionCodes = response.data;
      }
    });
  }

  onCancel() {
    const returnObj = {
      confirm: false
    }
    this.dialogRef.close(returnObj);
  }

  onClearAppealAdjustment() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      question:`Are you sure you want to clear the Appeal Reason and Explanation?`
    };
    this.singleChoiceDialogRef = this.matDialog.open(SingleChoiceDialogComponent, dialogConfig);
    this.singleChoiceDialogRef.afterClosed().subscribe((result) => {
      if (result?.confirm) {
        let dto: ItemizedLineItemAppealAdjustmentPacket = new ItemizedLineItemAppealAdjustmentPacket();
        dto.ids = this.data.ids;
        dto.clearAppealAdjustment = true;
    
        this.itemizedLineItemService.putItemizedLineItemAppealAdjustment(dto).subscribe(response => {
          if (response.hasErrors) {
            this.snack.showErrorSnack(response.consolidatedErrorMessage);
          } else {
            const returnObj = {
              confirm: true,
              ids: this.data.ids
            }
            this.dialogRef.close(returnObj);
            this.snack.showSuccessSnack(`Successfully Updated!`);
          }
        });
      }
    });
  }

  validateAppealReasonCode() {
    let arcFormControl: FormControl = this.formGroup.controls['appealReasonCode'] as FormControl;
    let arCodeDescriptionFormControl: FormControl = this.formGroup.controls['appealReasonCodeDescription'] as FormControl;

    if (!arcFormControl.pristine) {
      if (arcFormControl.value.length < 1) {
        arcFormControl.markAsPristine();
        arCodeDescriptionFormControl.setValue("");
        arcFormControl.setErrors(null);
        this.internalReasonCode = false;
      } else if (arcFormControl.value.length > 4) {
        arcFormControl.markAsPristine();
        arCodeDescriptionFormControl.setValue("Invalid");
        arcFormControl.setErrors({'incorrect': true});
        this.internalReasonCode = false;
      } else {
        this.codeValidationService.getAdjustmentReasonCode(arcFormControl.value).subscribe((response: BaseResponse) => {
          if (response.hasErrors) {
            arcFormControl.markAsPristine();
            arcFormControl.setErrors({'incorrect': true});
            this.internalReasonCode = false;
          } else {
            if (response.data !== null && response.data.adjustmentReasonType === "APPEAL") {
              arcFormControl.markAsPristine();
              arCodeDescriptionFormControl.setValue(response.data.description);
              arcFormControl.setErrors(null);
              this.internalReasonCode = response.data.internal;
            } else {
              arCodeDescriptionFormControl.setValue("Invalid");
              arcFormControl.markAsPristine();
              arcFormControl.setErrors({'incorrect': true});
              this.internalReasonCode = false;
            }
          }
        });
      }
    }
  }

  validateReasonCodeOnLoad(code:string) {
    let arcFormControl: FormControl = this.formGroup.controls['appealReasonCode'] as FormControl;
    let arCodeDescriptionFormControl: FormControl = this.formGroup.controls['appealReasonCodeDescription'] as FormControl;

    this.codeValidationService.getAdjustmentReasonCode(code).subscribe((response: BaseResponse) => {
      if (response.hasErrors) {
        arcFormControl.markAsPristine();
        arcFormControl.setErrors({'incorrect': true});
        this.internalReasonCode = false;
      } else {
        if (response.data !== null && response.data.adjustmentReasonType === "APPEAL") {
          arcFormControl.markAsPristine();
          arCodeDescriptionFormControl.setValue(response.data.description);
          arcFormControl.setErrors(null);
          this.internalReasonCode = response.data.internal;
        } else {
          arCodeDescriptionFormControl.setValue("Invalid");
          arcFormControl.markAsPristine();
          arcFormControl.setErrors({'incorrect': true});
          this.internalReasonCode = false;
        }
      }
    });
  }

  validateAppealExplanationCode() {
    let arcFormControl: FormControl = this.formGroup.controls['appealExplanationCode'] as FormControl;
    let arCodeDescriptionFormControl: FormControl = this.formGroup.controls['appealExplanationCodeDescription'] as FormControl;

    if (!arcFormControl.pristine) {
      if (arcFormControl.value.length < 1) {
        arcFormControl.markAsPristine();
        arCodeDescriptionFormControl.setValue("");
        arcFormControl.setErrors(null);
        this.internalExplanationCode = false;
      } else if (arcFormControl.value.length > 4) {
        arcFormControl.markAsPristine();
        arCodeDescriptionFormControl.setValue("Invalid");
        arcFormControl.setErrors({'incorrect': true});
        this.internalExplanationCode = false;
      } else {
        this.codeValidationService.getAdjustmentExplanationCode(arcFormControl.value).subscribe((response: BaseResponse) => {
          if (response.hasErrors) {
            arcFormControl.markAsPristine();
            arcFormControl.setErrors({'incorrect': true});
            this.internalExplanationCode = false;
          } else {
            if (response.data !== null && response.data.adjustmentExplanationType === "APPEAL") {
              arcFormControl.markAsPristine();
              arCodeDescriptionFormControl.setValue(response.data.description);
              arcFormControl.setErrors(null);
              this.internalExplanationCode = response.data.internal;
            } else {
              arCodeDescriptionFormControl.setValue("Invalid");
              arcFormControl.markAsPristine();
              arcFormControl.setErrors({'incorrect': true});
              this.internalExplanationCode = false;
            }
          }
        });
      }
    }
  }

  validateAppealExplanationCodeOnLoad(code:string) {
    let arcFormControl: FormControl = this.formGroup.controls['appealExplanationCode'] as FormControl;
    let arCodeDescriptionFormControl: FormControl = this.formGroup.controls['appealExplanationCodeDescription'] as FormControl;

    this.codeValidationService.getAdjustmentExplanationCode(code).subscribe((response: BaseResponse) => {
      if (response.hasErrors) {
        arcFormControl.markAsPristine();
        arcFormControl.setErrors({'incorrect': true});
        this.internalExplanationCode = false;
      } else {
        if (response.data !== null && response.data.adjustmentExplanationType === "APPEAL") {
          arcFormControl.markAsPristine();
          arCodeDescriptionFormControl.setValue(response.data.description);
          arcFormControl.setErrors(null);
          this.internalExplanationCode = response.data.internal;
        } else {
          arCodeDescriptionFormControl.setValue("Invalid");
          arcFormControl.markAsPristine();
          arcFormControl.setErrors({'incorrect': true});
          this.internalExplanationCode = false;
        }
      }
    });
  }

  addCommentDialog = () => {
    let mode = "MULTI";

    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "65vw";
    matDialogConfig.data = {
      mode,
      comment: null,
      relationalType: "APPEAL",
      relatedId: null,
      relatedIdList: this.data.ids
    };

    const dialogRef = this.matDialog.open(AddCommentComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(() => {
      // if (this.selectedLineItems.length > 0) {
      //   this.itemizedLineItemGridComponent?.setScrollToLineItemId(this.selectedLineItems[0]);
      // }
      // this.getLineItemQuery();
    })
  }

  toggleEmbeddedComments = () => {
    this.addCommentVisible = !this.addCommentVisible;
  }

  onClearAppealDecision() {
    this.formGroup?.controls['appealDecision']?.setValue(null);
    this.formGroup?.controls['overturnAmount']?.setValue(0);
    this.showOverturnAmount = false;
  }

  onAppealDecisionChange(event: any) {
    if (event?.value?.toUpperCase() === "OVERTURNED") {
      this.showOverturnAmount = true;
    } else {
      this.showOverturnAmount = false;
    }
  }

}
