<div style="margin-top: 15px">

  <div class="no-attachments-found" *ngIf="attachmentList.length <= 0 && showAttachmentList === 'true'">
    No Attachments Found!
  </div>

  <!-- Existing Attachment List -->
  <div class="attachment-list-div" *ngIf="attachmentList.length > 0 && showAttachmentList === 'true'">
    <mat-grid-list cols="{{attachmentList.length}}" rowHeight="fit" gutterSize="15px">
      <ng-container *ngFor="let attachment of attachmentList">
        <mat-grid-tile>
          <mat-card class="mat-elevation-z8 attachment-card" [ngStyle]="getSelectedStyle(attachment.id)">
            <mat-card-header>
              <mat-card-title [attr.data-attachment-name]="attachment.name">{{attachment.name}}</mat-card-title>
              <mat-card-subtitle [attr.data-attachment-type]="attachment.type">Attachment Type: {{attachment.attachmentType}}</mat-card-subtitle>
              <mat-card-subtitle [attr.data-attachment-id]="attachment.id">Attachment ID: {{attachment.id}}</mat-card-subtitle>

              <mat-menu #appMenu="matMenu">
                <button
                  *ngIf="isFilePdf(attachment)"
                  mat-menu-item
                  color="accent"
                  (click)="viewAttachment(attachment)"
                >
                  <mat-icon color="accent">picture_as_pdf</mat-icon>
                  View PDF
                </button>
                <button
                  mat-menu-item
                  color="accent"
                  (click)="downloadAttachment(attachment.id)"
                  >
                  <mat-icon color="accent">download</mat-icon>
                  <span>Download</span>
                </button>
                <button
                  mat-menu-item
                  color="accent"
                  (click)="emailAttachment(attachment.id)">
                  <mat-icon color="accent">email</mat-icon>
                  <span>Email</span>
                </button>
                <button
                  mat-menu-item
                  color="accent"
                  (click)="modifyAttachmentDialog(attachment)">
                  <mat-icon color="accent">edit</mat-icon>
                  <span>Edit</span>
                </button>

                <button
                  mat-menu-item
                  color="accent"
                  (click)="deleteAttachmentConfirmation(attachment)">
                  <mat-icon color="accent">delete</mat-icon>
                  <span>Delete</span>
                </button>
              </mat-menu>
              <button 
                style="margin-left:auto;" 
                class="menu-icon" mat-icon-button [matMenuTriggerFor]="appMenu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <!-- <button style="margin-left:auto;" mat-button title="Delete Attachment" (click)="deleteAttachmentConfirmation(attachment)"><mat-icon>delete</mat-icon></button> -->
            </mat-card-header>
            <mat-card-content>
              <p>
                <span class="global-data-element-label-span">Created:</span><span class="global-data-element-value-span">{{attachment.createdDate | date: 'MM/dd/yyyy'}}</span>
                <span class="global-data-element-label-span"> Publ:</span> <mat-icon class="eye-icon">{{attachment.publishToWeb ? "visibility" : "visibility_off"}}</mat-icon>
                <span class="global-data-element-value-span"
                  [class.attachment-report-received-color-indicator]="attachment.reportReceived"
                >
                  {{attachment.publishToWeb ? "True" : "False"}}
                </span>
              </p>
            </mat-card-content>

            <mat-card-actions>
              <button mat-raised-button color="accent" (click)="selectAttachment(attachment.id)">Select</button>
              <!-- Note: this functionality has been temporarily RETIRED by EDI Run(s)
              <button *ngIf="attachment.attachmentType == 'EDI_837'"
                mat-raised-button color="accent"
                (click)="convertToUB04(attachment.id)">Convert to UB04</button> -->


              <!-- Old buttons for Cards -->
              <!-- <button *ngIf="attachment.attachmentType == 'Itemized'"
                mat-raised-button color="accent"
                (click)="convertToItemized(attachment.id)">Process/Create Itemized </button>

              <button *ngIf="attachment.attachmentType == 'UB04Report' || attachment.attachmentType == 'ItemizedProviderReport' || attachment.attachmentType == 'ItemizedClientReport'"
                mat-raised-button color="accent"
                (click)="downloadAttachment(attachment.id)"
              >Download</button>

              <button mat-raised-button color="accent" (click)="emailAttachment(attachment.id)">Email</button> -->

            </mat-card-actions>
          </mat-card>
        </mat-grid-tile>
      </ng-container>
    </mat-grid-list>

    <!-- <button
      mat-icon-button
      class="arrow-button"
      id="left" (click)="getAttachmentViewList(currentIndex - attachmentViewAmount)"
      [disabled]="currentIndex == 0"><mat-icon>arrow_circle_left</mat-icon>
    </button>

    <button
      mat-icon-button
      class="arrow-button"
      id="right" (click)="getAttachmentViewList(currentIndex + attachmentViewAmount)"
      [disabled]="currentIndex + 5 >= attachmentList.length"><mat-icon>arrow_circle_right</mat-icon>
    </button> -->

    <mat-paginator
      #paginator
      [pageSize]="defaultPageSize"
      [length]="totalRowCount"
      (page)="getAttachmentList()"
    ></mat-paginator>
  </div>

  <!-- Selected Attachment -->
  <div class="selected-attachment-container">
    <mat-card *ngIf="selectedAttachment != null" [@fadeInOut] class="selected-attachment-card">
      <mat-card-title style="display:flex;">
        <mat-card-header>
          Selected Attachment
        </mat-card-header>
        <button mat-icon-button class="close-button" (click)="deselectAttachment()"><mat-icon>close</mat-icon></button>
      </mat-card-title>

      <mat-card-content>
        <div style="display:flex; justify-content: space-around; margin-left: 15px; margin-right:15px;">
          <div>
            <p><span class="global-data-element-label-span">Name:</span><span class="global-data-element-value-span"> {{selectedAttachment.name}}</span></p>
            <p><span class="global-data-element-label-span">Created:</span><span class="global-data-element-value-span"> {{selectedAttachment.createdDate | date: 'MM/dd/yyyy'}}</span></p>
            <p><span class="global-data-element-label-span">Published:</span> <mat-icon class="eye-icon">{{selectedAttachment.publishToWeb ? "visibility" : "visibility_off"}}</mat-icon><span class="global-data-element-value-span"> {{selectedAttachment.publishToWeb ? "True" : "False"}}</span></p>
          </div>

          <div>
            <p><span class="global-data-element-label-span">ID:</span><span class="global-data-element-value-span"> {{selectedAttachment.id}}</span></p>
            <p>
              <span *ngIf="!selectedAttachment.reportReceived">...</span>
              <span *ngIf="selectedAttachment.reportReceived" class="global-data-element-value-span" style="color: green">Received</span>
            </p>
            <p *ngIf="selectedAttachment.publishToWeb"><span class="global-data-element-label-span">Publish Date:</span><span class="global-data-element-value-span"> {{selectedAttachment.latestPublishDateString | date: 'MM/dd/yyyy'}}</span></p>
          </div>

          <div>
            <p><span class="global-data-element-label-span">Type:</span><span class="global-data-element-value-span"> {{selectedAttachment.attachmentType}}</span></p>
            <p> ... </p>
            <p *ngIf="selectedAttachment.publishToWeb"><span class="global-data-element-label-span">Earliest Publish Date:</span><span class="global-data-element-value-span"> {{selectedAttachment.earliestPublishDateString | date: 'MM/dd/yyyy'}}</span></p>
          </div>

        </div>
      </mat-card-content>

      <mat-card-actions style="float:right;">

        <button
          style="margin-left:5px;"
          mat-raised-button color="accent"
          (click)="confirmPublishDialog(selectedAttachment)">
            <mat-icon>{{selectedAttachment.publishToWeb ? "visibility_off" : "visibility"}}</mat-icon>
            {{selectedAttachment.publishToWeb ? "Hide from" : "Publish to"}} Client Portal
        </button>

        <button
          style="margin-left:5px;"
          *ngIf="selectedAttachment.attachmentType == 'Itemized_SS'"
          mat-raised-button color="accent"
          (click)="convertToItemized(selectedAttachment.id)">
            <mat-icon>insights</mat-icon>
            Process/Create Itemized
        </button>

        <button
          style="margin-left:5px;"
          mat-raised-button color="accent"
          (click)="downloadAttachment(selectedAttachment.id)"
        >
        <mat-icon>download</mat-icon>
        Download</button>

        <button
          style="margin-left:5px;"
          mat-raised-button
          color="accent"
          (click)="emailAttachment(selectedAttachment.id)">
          <mat-icon>email</mat-icon>
          Email
        </button>

        <button
          style="margin-left:5px;"
          mat-raised-button
          title="Delete Attachment"
          color="accent"
          (click)="deleteAttachmentConfirmation(selectedAttachment)">
          <mat-icon>delete</mat-icon>Delete</button>
      </mat-card-actions>
    </mat-card>
  </div>


  <!-- Upload File Attachment Div-->
  <div *ngIf="showUpload === 'true'" style="width: 100%; text-align:center">
    <h1>Create and Upload Attachment</h1>
  </div>
  <div *ngIf="showUpload === 'true'" class="container">
  <div class="inside">
    <div class="input-group alt-theme">
      <mat-form-field appearance="outline" class="name-input">
        <mat-label>Attachment Name</mat-label>
        <input matInput [(ngModel)]="name">
      </mat-form-field>

      <mat-form-field appearance="outline" class="type-input">
        <mat-label>Attachment Type</mat-label>
        <mat-select
          [(ngModel)]="attachmentType"
          [ngModelOptions]="{standalone: true}"
          >
          <mat-option *ngFor="let at of attachmentTypes" [value]="at">{{ at }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <mat-label>Publish To Web</mat-label>
    <mat-checkbox [(ngModel)]="attachmentPublishToWeb">
    </mat-checkbox>

    <h5>{{ file ? "Current file: " + file.name : "No File Chosen" }}</h5>
    <input
      #fileUploadInput
      type="file"
      name="fileUpload"
      (change)="addFile(fileUploadInput)"
      [hidden]="true">

      <div class="button-group">
        <button
          [disabled]="uploading"
          class="btn"
          mat-raised-button
          color="primary"
          #upload
          (click)="fileUploadInput.click()">
            <mat-icon>upload</mat-icon> Choose file
        </button>

        <button
          class="btn"
          mat-raised-button
          color="accent"
          (click)="onSave()"
          [disabled]="disableSave()"
          [attr.data-action]="'upload-file'"
          >
          <mat-icon>save</mat-icon>
          {{uploading ? "Uploading..." : "Save"}}
        </button>

      </div>

      <div *ngIf="uploading" class="spinner">
        <mat-spinner color="accent"></mat-spinner>
      </div>
  </div>
</div>

</div>
