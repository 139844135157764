import { AfterViewInit, Component, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute, Router } from '@angular/router';
import { AttachmentComponent } from '@app/attachment/attachment.component';
import { ActivityDiaryQueryRequestPacket, BaseComponent, DateUtil, FennecSnackbarService, Logger } from 'xf-common';
import {
  ActivityDiaryAttachmentDialogComponent
} from '../activity-diary-attachment-dialog/activity-diary-attachment-dialog.component';
import { ActivityDiaryDialogComponent } from '../activity-diary-dialog/activity-diary-dialog.component';
import { ActivityDiaryService } from '../activity-diary.service';

@Component({
  selector: 'app-activity-diary-list',
  templateUrl: './activity-diary-list.component.html',
  styleUrls: ['./activity-diary-list.component.scss', '../../../styles.scss', '../../../lib/styles/system-admin-styles.component.scss']
})
export class ActivityDiaryListComponent extends BaseComponent implements AfterViewInit, OnInit {

  log: Logger = new Logger("ActivityDiaryComponent");

  @Input()
  miCaseId: string = "-1";
  @Input()
  activityDiaryId: any | null;

  userId = -1;
  activityDiaryList = new MatTableDataSource<any>();
  displayColumns = ['createdDate', 'activityDiaryDomain', 'miCaseId','relatedId', 'activityDiaryMessageType', 'messageText', 'createdBy', 'errorFlag'];
  @ViewChild(MatPaginator)
  // matDialogRef?: MatDialogRef<any>
  paginator?: MatPaginator;
  totalRowCount?: number;
  defaultPageSize = 20;
  pageSizeOptions = [5, 10, 20, 25, 50];

  showActivityDiaryFilterOptions:boolean = false;
  activityDiaryDomains: string [] = [];
  activityDiaryMessageTypes: string [] = [];

  // Activity Diary Refresh Interval Object
  refreshIntervalObj: any = null;

  formGroup:FormGroup;

  constructor(
    protected snack: FennecSnackbarService,
    private activityDiaryService: ActivityDiaryService,
    protected route: ActivatedRoute,
    private router: Router,
    private readonly matDialog: MatDialog,
    @Optional() public errorDialogRef: MatDialogRef<ActivityDiaryListComponent>,
    @Optional() public dialogRef: MatDialogRef<AttachmentComponent>
  ) {
    super();
    this.miCaseId = this.route.parent?.snapshot.paramMap.get("miCaseId") ?? "-1";
    this.formGroup = new FormGroup({
      userId: new FormControl(this.miCaseId === "-1" ? "MINE" : "ALL"),
      activityDiaryDomain: new FormControl(null),
      activityDiaryMessageType: new FormControl(null),
    });

    this.formGroup.valueChanges.subscribe(() => {
      if (this.miCaseId === "-1" && this.formGroup.value["userId"] === "ALL") {
        this.showActivityDiaryFilterOptions = true;
      } else {
        this.showActivityDiaryFilterOptions = false;
      }
      this.getAllActivityList();
    });
  }

  ngOnInit() {
    this.getActivityDiaryList();
  }

  ngAfterViewInit() {
    if (this.paginator) {
      this.paginator.page.subscribe(() => {
        this.getActivityDiaryList();
      });
    }
    this.getActivityDiaryDomains();
    this.getActivityDiaryMessageTypes();
  }

  getActivityDiaryList() {
    if (this.miCaseId != "-1") {
      this.getActivityListForMICase();
    } else {
      this.getAllActivityList();
    }
  }

  getActivityDiaryDomains() {
    this.activityDiaryService.getActivityDiaryDomains().subscribe((response: any) => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.activityDiaryDomains = response.data;
      }
    });
  }

  getActivityDiaryMessageTypes() {
    this.activityDiaryService.getActivityDiaryMessageTypes().subscribe((response: any) => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.activityDiaryMessageTypes = response.data;
      }
    });
  }

  getAllActivityList() {
    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;

    if (this.formGroup.controls['userId'].value === "MINE") {
      this.activityDiaryService.getActivityDiaryForUserId(first, pageSize).subscribe((response: any) => {
        if (response.hasErrors) {
          this.snack.showErrorSnack(response.consolidatedErrorMessage);
        } else {
          this.activityDiaryList.data = response.data;
          this.totalRowCount = response.totalRowCount;
        }
      });
    } else {
      // No filters - essentially - ALL for everyone
      if (this.formGroup.controls['activityDiaryDomain'].value === null && this.formGroup.controls['activityDiaryMessageType'].value === null) {
        this.activityDiaryService.getAllActivityList(first, pageSize).subscribe((response: any) => {
          if (response.hasErrors) {
            this.snack.showErrorSnack(response.consolidatedErrorMessage);
          } else {
            this.activityDiaryList.data = response.data;
            this.totalRowCount = response.totalRowCount;
          }
        });
      } else {
        let params: ActivityDiaryQueryRequestPacket = new ActivityDiaryQueryRequestPacket();
        params.activityDiaryDomain = this.formGroup.controls['activityDiaryDomain'].value;
        params.activityDiaryMessageType = this.formGroup.controls['activityDiaryMessageType'].value;
        this.activityDiaryService.getAllActivityDiaryForRequestParams(params, first, pageSize).subscribe((response: any) => {
          if (response.hasErrors) {
            this.snack.showErrorSnack(response.consolidatedErrorMessage);
          } else {
            this.activityDiaryList.data = response.data;
            this.totalRowCount = response.totalRowCount;
          }
        });
      }
    }
  }

  getActivityListForMICase() {
    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;
    // const paramId = this.activeRoute?.snapshot?.paramMap?.get('id');
    this.activityDiaryService.getAllActivityDiaryForMICase(this.miCaseId, first, pageSize).subscribe((response: any) => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.activityDiaryList.data = response.data;
        this.totalRowCount = response.totalRowCount;
      }
    })
  }

  getActivityDiaryListForId(diaryId: any) {
    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;
    this.activityDiaryService.getAllActivityDiaryForId(diaryId, first, pageSize).subscribe((response: any) => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.activityDiaryList.data = response.data;
        this.totalRowCount = response.totalRowCount;
      }
    })
  }

  getActivityDiaryGivenId(activityDiaryId: number): any {
    if (this.activityDiaryList === null || this.activityDiaryList === undefined) {
      return null;
    } else {
      let ad = null;
      this.activityDiaryList.data.forEach((element: any) => {
        if (element.id === activityDiaryId) {
          ad = element;
        }
      });
      return ad;
    }
  }

  navigateToCase(id: number) {
    if (id) {
      this.router.navigateByUrl(`/mi-case/${id}`);
    }
  }

  getRowStyle(row: any) {
    // use toString because of boolean value returned
    if (row.errorFlag.toString() === 'false') {
      return {
        "background-color": "#ccffcc"
      }
    } if (row.errorFlag.toString() === 'true') {
      return {
        "background-color": "#ffb3b3",
        // "background-color": "firebrick",
        "width": "100% 100% !important"
      }
    } else {
      return { "background-color": "beige" }
    }
  }

  openErrorDialog(id: number) {
    let ad: any = this.getActivityDiaryGivenId(id);
    if (ad === null) {
      return;
    }

    // Translate the different kinds of 'data' we may get on an activity diary so we can display it
    // properly if necessary.
    let data: any = null;
    if (ad.data !== null && ad.data !== undefined) {
      data = JSON.parse(ad.data);
    }

    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      id: ad.id,
      messageText: ad.messageText,
      data: data,
      activityDiaryMessageType: ad.activityDiaryMessageType
    };
    const dialogRef = this.matDialog.open(ActivityDiaryDialogComponent, matDialogConfig);
  }

  openAttachmentDialog(miCaseId: number | string, activityDiaryDomain: string) {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.height = "auto";
    matDialogConfig.width = "100%";
    matDialogConfig.panelClass='custom-mat-dialog-panel';
    matDialogConfig.data = {
      miCaseId: miCaseId,
      context: activityDiaryDomain,
    };
    // const dialogRef = this.matDialog.open(AttachmentComponent, matDialogConfig);
    const dialogRef = this.matDialog.open(ActivityDiaryAttachmentDialogComponent, matDialogConfig);
  }

  formatDate(dateString:string) {
    return DateUtil.getDisplayDate(dateString);
  }

  // Note: This method clears the navigation router by routing to / first, and then re-routing to the requested
  // destination. This fixes the problem of routing only working the first time and then not doing anything
  // after that.
  // Note: expected format of URL is: /(routeurl)/
  //       Example: /mi-case-itemized/
  routeToUrl(url: string, id: number) {
    let toUrl: string = url;
    if (id >= 0) {
      toUrl += id.toString();
    }
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigateByUrl(toUrl));
  }

  routeToItemizedUrl(url: string, id: number, itemizedId: number) {
    let toUrl: string = url;
    if (id >= 0) {
      toUrl += id.toString();
    }
    if (itemizedId >= 0) {
      toUrl += "/";
      toUrl += itemizedId.toString();
    }
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigateByUrl(toUrl));
  }

  routeToItemizedRevisionUrl(url: string, id: number, itemizedRevisionId: number) {
    let toUrl: string = url;
    if (id >= 0) {
      toUrl += id.toString();
    }
    if (itemizedRevisionId >= 0) {
      toUrl += "/";
      toUrl += itemizedRevisionId.toString();
    }
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
    this.router.navigateByUrl(toUrl));
  }

  onRefreshList() {
    this.getActivityDiaryList();
  }

  onClearFilters() {
    this.formGroup.controls['activityDiaryDomain'].setValue(null);
    this.formGroup.controls['activityDiaryMessageType'].setValue(null);
  }

}
