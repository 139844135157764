import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { BaseComponent } from '../../view/base.component';
import { FennecSnackbarService } from '../../dialog/fennec-snackbar/fennec-snackbar.service';
import { Logger } from '../../util/logger';
import { Ub04Service } from '../../ub04/ub04.service';
import { FormControl, FormGroup } from '@angular/forms';
import { DateUtil } from '../../util/date-util';
import { MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'lib-patient-list',
  templateUrl: './patient-list.component.html',
  styleUrl: './patient-list.component.scss'
})
export class PatientListComponent extends BaseComponent implements AfterViewInit {

  protected override log: Logger = new Logger("PatientCaseListComponent");
  formGroup:FormGroup;

  patientList = [];
  displayColumns = ["altPatientId", "name", "dob", "address", "actions"];

  totalRowCount = 0;
  defaultPageSize = 15;
  pageSizeOptions = [10, 15, 20];

  @ViewChild("paginator")
  paginator;

  nameSort: "ASC" | "DESC" = "ASC";

  constructor(
    protected override snack: FennecSnackbarService,
    protected ub04Service: Ub04Service,
    private router: Router,
  ) {
    super();
    this.formGroup = new FormGroup({
      firstName: new FormControl(),
      lastName: new FormControl(),
      altPatientId: new FormControl(),
      clientMemberNumber: new FormControl()
    })
  }

  ngAfterViewInit(): void {
    this.getData();
  }

  getData = () => {
    const packet = {
      altPatientId: this.formGroup.controls['altPatientId']?.value,
      clientMemberNumber: this.formGroup.controls['clientMemberNumber']?.value,
      firstName: this.formGroup.controls['firstName']?.value,
      lastName: this.formGroup.controls['lastName']?.value,
      nameSort: this.nameSort
    };

    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;

    this.performXFRequest({
      requestDescription: "Get Existing Patients",
      requestFn: this.ub04Service.searchExistingPatients,
      fnParams: [packet, first, pageSize],
      onSuccess: data => {
        this.patientList = data;
      },
      onResponse: response => {
        if (response?.['totalRowCount'] != null) {
          this.totalRowCount = response['totalRowCount'];
        }
      },
      onError: errString => {
        super.showErrorSnack(errString);
      }
    })
  }

  formatDate = (inputDate:string) => {
    return DateUtil.getDisplayDate(inputDate);
  }

  selectPatient(patient:any) {
    this.router.navigateByUrl(`/patient/${patient.id}`);
  }

  onCreate = () => {

  }

  toggleNameSort = () => {
    if (this.nameSort == "ASC") {
      this.nameSort = "DESC";
    }else if (this.nameSort == "DESC") {
      this.nameSort = "ASC";
    }
    this.getData();
  }
}
