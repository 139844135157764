<lib-page>
  <ng-container pageTitle>
    <div>
    Analysis Processing Engine (APE) <img class="ape-component-img" src="../assets/ape.png">
    </div>
  </ng-container>

  <ng-container pageContent>
    <mat-tab-group color="accent">
      <mat-tab label="APE Rules">
        <ng-template matTabContent>
          <app-ape-rule-list mode="ADMIN"></app-ape-rule-list>
        </ng-template>
      </mat-tab>

      <mat-tab label="Collection List">
        <ng-template matTabContent>
          <app-ape-collection-list></app-ape-collection-list>
        </ng-template>
      </mat-tab>

      <mat-tab label="Filters">
        <ng-template matTabContent>
          <app-ape-rule-filter-list></app-ape-rule-filter-list>
        </ng-template>
      </mat-tab>

    </mat-tab-group>
  </ng-container>
</lib-page>
