<div #topLevelWrapper class="itemized-line-item-parent-wrapper alt-theme" [class.split-view]="navigationOpen">

  <div class="itemized-line-item-left-wrapper" [hidden]="!navigationOpen">
    <div class="itemized-line-item-rev-header-line">
      <button mat-raised-button color="accent" (click)="closeItemizedLineItemComponent()">
        <mat-icon>arrow_back</mat-icon>
        Back
      </button>
      <span class="itemized-revision-header-revision-no">Itemized Id: {{ itemizedId }}
        Rev Id: {{ itemizedRevisionId }}</span>
    </div>
    <div class="left-nav-views-selection-div">
      <button class="left-nav-views-selection-button" (click)="leftNavView(0)"
              [ngStyle]="{'background-color': leftNavViewIdx == 0 ? 'yellow': 'white', 'font-weight': leftNavViewIdx == 0 ? 'bold': 'normal' }">
        General
      </button>
      <button class="left-nav-views-selection-button" (click)="leftNavView(1)"
              [ngStyle]="{'background-color': leftNavViewIdx == 1 ? 'yellow': 'white', 'font-weight': leftNavViewIdx == 1 ? 'bold': 'normal'}">
        Views
      </button>
      <button class="left-nav-views-selection-button" (click)="leftNavView(2)"
              [ngStyle]="{'background-color': leftNavViewIdx == 2 ? 'yellow': 'white', 'font-weight': leftNavViewIdx == 1 ? 'bold': 'normal'}">
        Columns
      </button>
    </div>
    <div [hidden]="leftNavViewIdx != 0">
      <div>
        <table class="itemized-revision-summary-table">
          <tr>
            <td>Line Items:</td>
            <td class="itemized-revision-summary-number">{{ lineItemCount }}</td>
          </tr>
          <tr>
            <td>Total Bill Amt:</td>
            <td class="itemized-revision-summary-number">{{ totalBilledAmount | currency }}</td>
          </tr>
          <tr>
            <td>Total Alwd Amt:</td>
            <td class="itemized-revision-summary-number">{{ totalAdjustedAmount | currency }}</td>
          </tr>
          <tr>
            <td>Savings Amt:</td>
            <td
              [ngClass]="{'itemized-revision-summary-savings': (totalBilledAmount - totalAdjustedAmount) > 0, 'itemized-revision-summary-no-savings' : (totalBilledAmount - totalAdjustedAmount) == 0}">
              {{ totalBilledAmount - totalAdjustedAmount | currency }}
            </td>
          </tr>
        </table>
      </div>

      <div class="sort-filter-options-div">
        <div class="line-item-query-filter-header">
          Query Sort Order
        </div>
        <form [formGroup]="sortOrderFormGroup">
          <!--
          <mat-radio-group aria-label="Sort" formControlName="sortOrder" (change)="sortOrderChanged($event)">
            <mat-radio-button value="0">Service Date, Rev Code, Desc</mat-radio-button>
            <mat-radio-button value="1">Bill Line No</mat-radio-button>
          </mat-radio-group> -->
          <mat-form-field appearance="outline" class="query-sort-order-select">
            <mat-label>Query Sort Order</mat-label>
            <mat-select formControlName="sortOrder" (selectionChange)="sortOrderChanged($event.value)">
              <mat-option [value]="0">Service Date, Rev Code, Desc</mat-option>
              <mat-option [value]="1">Bill Line No</mat-option>
              <mat-option [value]="6">Billed Amt (Asc)</mat-option>
              <mat-option [value]="5">Billed Amt (Desc)</mat-option>
              <mat-option [value]="2">Description</mat-option>
              <mat-option [value]="4">Mapped Revenue Code</mat-option>
              <mat-option [value]="3">Revenue Code</mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </div>

      <div class="multi-select-actions" *ngIf="selectedLineItemIds.length > 1">
        Multi Select Actions
        <div class="multi-select-action-button-div">
          <button class="multi-select-adj-button" (click)="onMultiSelectAdj()">ADJ</button>
          <button class="multi-select-appeal-adj-button" (click)="onMultiSelectAppealAdj()">APL</button>
          <button class="multi-select-pristine-button" (click)="onMultiSelectPristine()">CLR</button>
          <button class="multi-select-pristine-button" (click)="onMultiSelectEdit()">EDIT</button>
          <button class="multi-select-pristine-button" (click)="onMultiComment()">CMNT</button>
        </div>
      </div>

      <div *ngIf="apeProcessed" class="ape-processed-indicator">
        <img class="ape-processed-img" src="../assets/ape.png">
      </div>
      <div *ngIf="iliEditorMode === 'APPEAL'" class="ape-processed-indicator">
        <img class="ape-processed-img" src="../assets/appeal-icon.png">
      </div>
      <div class="line-item-query-filter-div">
        <div class="line-item-query-filter-header">
          Line Item Filters
          <button mat-icon-button (click)="clearAllFilters()" matTooltip="Clear All filters">
            <mat-icon>clear</mat-icon>
          </button>
        </div>
        <form [formGroup]="lineItemQueryFormGroup" class="center-form">
          <mat-form-field appearance="outline">
            <mat-label>Service Date</mat-label>
            <mat-select formControlName="serviceDateString"
                        (selectionChange)="serviceDateSelectionChanged($event.value)">
              <mat-option *ngFor="let sds of serviceDateStrings" [value]="sds">{{ sds }}</mat-option>
            </mat-select>
          </mat-form-field>
          <div class="service-date-picker-filter-div">
            <mat-calendar [(selected)]="selected" [dateClass]="dateClass" #serviceDatePicker></mat-calendar>
          </div>
          <table class="adj-filter-table">
            <tr class="adj-filter-table-tr">
              <td class="adjusted-filter-header-div">
                Adj
              </td>
              <td>
                <mat-radio-group aria-label="Adjusted" formControlName="adjusted"
                                 (change)="adjustedFilterChanged($event)">
                  <mat-radio-button value="ALL">All</mat-radio-button>
                  <mat-radio-button value="TRUE">Adjusted</mat-radio-button>
                  <mat-radio-button value="APPEAL">Appeal</mat-radio-button>
                  <mat-radio-button value="FALSE">Non-Adjusted</mat-radio-button>
                </mat-radio-group>
              </td>
            </tr>
            <tr class="adj-filter-table-tr">
              <td class="ape-adjusted-filter-header">
                <img class="ape-adjusted-filter-img" src="../assets/ape.png">
              </td>
              <td>
                <mat-radio-group aria-label="APE Adjusted" formControlName="apeAdjusted"
                                 (change)="apeAdjustedFilterChanged($event)">
                  <mat-radio-button value="ALL">All</mat-radio-button>
                  <mat-radio-button value="TRUE">Adjusted</mat-radio-button>
                  <mat-radio-button value="FALSE">Non-Adjusted</mat-radio-button>
                </mat-radio-group>
              </td>
            </tr>
            <tr class="adj-filter-table-tr">
              <td class="adjusted-filter-header-div">
                Comments
              </td>
              <td>
                <mat-radio-group aria-label="Comments" formControlName="comments"
                                 (change)="commentsFilterChanged($event)">
                  <mat-radio-button value="NA">N/A</mat-radio-button>
                  <mat-radio-button value="ALL">All</mat-radio-button>
                  <mat-radio-button value="APPEAL">Appeals</mat-radio-button>
                  <mat-radio-button value="QA">QA</mat-radio-button>
                </mat-radio-group>
              </td>
            </tr>
          </table>
          <table class="center-table">
            <tr>
              <td>
                <mat-form-field appearance="outline">
                  <mat-label>Revenue Code</mat-label>
                  <mat-select panelClass="alt-theme" [multiple]="true" formControlName="revenueCodeString"
                              (selectionChange)="revenueCodeSelectionChanged($event.value)">
                    <mat-option *ngFor="let rcs of revenueCodeStrings" [value]="rcs">{{ rcs }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
              <td>
                <button mat-icon-button (click)="clearRevenueCodeFilter()" matTooltip="Clear Revenue Code filter">
                  <mat-icon>clear</mat-icon>
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <mat-form-field appearance="outline">
                  <mat-label>(M) Revenue Code</mat-label>
                  <mat-select panelClass="alt-theme" [multiple]="true" formControlName="mappedRevenueCodeString"
                              (selectionChange)="mappedRevenueCodeSelectionChanged($event.value)">
                    <mat-option *ngFor="let mrcs of mappedRevenueCodeStrings" [value]="mrcs">{{ mrcs }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
              <td>
                <button mat-icon-button (click)="clearMappedRevenueCodeFilter()"
                        matTooltip="Clear Mapped Revenue Code filter">
                  <mat-icon>clear</mat-icon>
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <mat-form-field appearance="outline">
                  <mat-label>Reason Code</mat-label>
                  <mat-select panelClass="alt-theme" [multiple]="true" formControlName="reasonCodeFilter"
                              (selectionChange)="reasonCodeFilterChanged($event.value)">
                    <mat-option *ngFor="let code of reasonCodeFilterOptions" [value]="code.code">{{ code.code }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
              <td>
                <button mat-icon-button (click)="clearReasonCodeFilter()"
                        matTooltip="Clear Reason Code filter">
                  <mat-icon>clear</mat-icon>
                </button>
              </td>
            </tr>
            <tr>
              <td>
                <mat-form-field appearance="outline">
                  <mat-label>Explanation Code</mat-label>
                  <mat-select panelClass="alt-theme" [multiple]="true" formControlName="explanationCodeFilter"
                              (selectionChange)="explanationCodeFilterChanged($event.value)">
                    <mat-option *ngFor="let code of explanationCodeFilterOptions" [value]="code.code">{{ code.code }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
              <td>
                <button mat-icon-button (click)="clearExplanationCodeFilter()"
                        matTooltip="Clear Explanation Code filter">
                  <mat-icon>clear</mat-icon>
                </button>
              </td>
            </tr>
          </table>
          <table class="center-table">
            <tr class="adj-filter-table-tr">
              <td class="adjusted-filter-header-div">
                Report Exclude:
              </td>
              <td>
                <mat-radio-group aria-label="Report Exclude" formControlName="reportExcludeString"
                                 (change)="reportExcludeFilterChanged($event)">
                  <mat-radio-button value="ALL">All</mat-radio-button>
                  <mat-radio-button value="TRUE">True</mat-radio-button>
                  <mat-radio-button value="FALSE">False</mat-radio-button>
                </mat-radio-group>
              </td>
            </tr>
          </table>
        </form>
      </div>

      <!-- Moved up on screen per users request -->
      <!-- <div class="multi-select-actions" *ngIf="selectedLineItems.length > 1">
        Multi Select Actions
        <div class="multi-select-action-button-div">
          <button class="multi-select-adj-button" (click)="onMultiSelectAdj()">ADJ</button>
          <button class="multi-select-pristine-button" (click)="onMultiSelectPristine()">CLR</button>
          <button class="multi-select-pristine-button" (click)="onMultiSelectEdit()">EDIT</button>
          <button class="multi-select-pristine-button" (click)="onMultiComment()">CMNT</button>
        </div>
      </div> -->
    </div>
    <div [hidden]="leftNavViewIdx != 1">
      <!-- Developer Note: I had to nest this extra div down a level to get the [hidden] to work properly.
        Please be advised. -->
      <div [hidden]="leftNavViewIdx != 1" class="left-nav-views-wrapper-div">
        <!-- <button class="left-nav-views-button" (click)="leftNavSelectedView(0)"
        [ngStyle]="{'background-color': leftNavSelectedViewIdx == 0 ? 'yellow': 'white', 'font-weight': leftNavViewIdx == 1 ? 'bold': 'normal'}">Line Item Grid</button> -->
        <button class="left-nav-views-button" (click)="leftNavSelectedView(0)" mat-stroked-button
                [color]="leftNavSelectedViewIdx == 0 ? 'accent' : undefined">
          Line Item Grid
        </button>
        <br>
        <!-- <button class="left-nav-views-button" (click)="leftNavSelectedView(1)"
        [ngStyle]="{'background-color': leftNavSelectedViewIdx == 1 ? 'yellow': 'white', 'font-weight': leftNavViewIdx == 1 ? 'bold': 'normal'}">Revenue Code Summary</button> -->
        <button class="left-nav-views-button" (click)="leftNavSelectedView(1)" mat-stroked-button
                [color]="leftNavSelectedViewIdx == 1 ? 'accent' : undefined">
          Revenue Code Summary
        </button>
        <br>
        <!-- <button class="left-nav-views-button" (click)="leftNavSelectedView(1)"
        [ngStyle]="{'background-color': leftNavSelectedViewIdx == 1 ? 'yellow': 'white', 'font-weight': leftNavViewIdx == 1 ? 'bold': 'normal'}">Revenue Code Summary</button> -->
        <button class="left-nav-views-button" (click)="leftNavSelectedView(2)" mat-stroked-button
                [color]="leftNavSelectedViewIdx == 2 ? 'accent' : undefined">
          UB04 Review
        </button>
      </div>
    </div>

    <div [hidden]="leftNavViewIdx != 2">
      <div *ngFor="let key of getVisibleColumnKeys()">
        <!-- <mat-form-field *ngFor="let column in defaultColumnConfig"> -->
        <mat-checkbox
          [checked]="visibleColumns[key]"
          (change)="toggleColumn(key)"
        >
          {{ key }}
        </mat-checkbox>
        <!-- </mat-form-field> -->
      </div>
    </div>
  </div>
  <div
    [ngClass]="{'itemized-line-item-right-wrapper' : navigationOpen, 'itemized-line-item-grid-full': !navigationOpen}">
    @switch (leftNavSelectedViewIdx) {
      @case (0) {
        <table>
          <tr>
            <td class="line-item-query-filter-td">
              <form [formGroup]="descriptionFilterFormGroup">
                <!-- Side Nav Toggle Button -->
                <button
                  mat-icon-button
                  (click)="toggleSideNav()"
                  matTooltip="Views"
                >
                  <mat-icon>{{ navigationOpen ? 'more' : 'read_more' }}</mat-icon>
                </button>
                <mat-form-field class="description-filter-input">
                  <mat-label>Search Description</mat-label>
                  <input matInput id="description" formControlName="description" (keydown.enter)="onDescriptionSearch(); $event.preventDefault();"/>
                </mat-form-field>
                <button mat-icon-button (click)="onDescriptionSearch()" matTooltip="Search by Description">
                  <mat-icon>search</mat-icon>
                </button>
                <button mat-icon-button (click)="clearDescriptionFilter()" matTooltip="Clear Description filter">
                  <mat-icon>clear</mat-icon>
                </button>
              </form>
            </td>
            <td>
              <table class="center-table">
                <tr>
                  <td>#: <span class="num-highlight">{{ itemizedLineItems.lineItemCount }}</span></td>
                  <td>Total Billed: <span
                    class="num-highlight">{{ itemizedLineItems.totalBilledAmount | currency }}</span></td>
                  <td>Total Alwd: <span
                    class="num-highlight">{{ itemizedLineItems.totalAdjustedAmount | currency }}</span></td>
                </tr>
                <tr>
                  <td></td>
                  <td>Total Units: <span
                    class="num-highlight">{{ itemizedLineItems.totalUnits | number: '1.0-0' }}</span></td>
                  <td>Total Alwd: <span
                    class="num-highlight">{{ itemizedLineItems.totalAdjustedUnits | number: '1.0-0' }}</span></td>
                </tr>
              </table>
            </td>
            <td>
              <mat-paginator
                [length]="totalRowCount" [pageSize]="defaultPageSize"
                [pageSizeOptions]="defaultPageSizeOptions">
              </mat-paginator>
            </td>
          </tr>
        </table>
        <div class="grid-viewport">
          <div [hidden]="userDefaultPageSize >= 0" class="itemized-line-item-editor-user-select-page-size">
            <button *ngFor="let ps of defaultPageSizeOptions" class="user-select-page-size-button"
                    (click)="userSelectItemsPerPage(ps)" mat-stroked-button>
              Items per Page: {{ ps }}
            </button>
          </div>
          @if (!noDataFound) {
            <app-itemized-line-item-grid #itemizedLineItemGrid [autoColumns]=true (initialized)="refreshTableConfig()" [activityLogAccess]="lineItemActivityLogAccess">
            </app-itemized-line-item-grid>
          } @else {
            <div class="itemized-line-item-no-data-found">
              No line items found for current query filter values!
            </div>
          }
        </div>
      }
      @case (1) {
        <app-itemized-revision-revenue-code-summary
          #itemizedRevisionRevenueCodeSummary
          [itemizedRevisionId]="itemizedRevisionId">

        </app-itemized-revision-revenue-code-summary>

      }
      @case (2) {
        <app-ub04-review #ub04ReviewComponent
                         [ub04Id]="linkedItemizedUb04Id"
                         [itemizedId]="itemizedId"
                         [miCaseId]="miCaseId">
        </app-ub04-review>
      }
    }
  </div>

</div>
