import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseResponse, ConditionCode, EnvironmentService, PagedResponse } from "xf-common";


@Injectable()
export class ConditionCodeService {

    constructor(
        private httpClient: HttpClient,
        private environment: EnvironmentService
    ) { }

    getCodes(first?: number, max?: number): Observable<PagedResponse<ConditionCode[]>> {
        return this.httpClient.get<PagedResponse<ConditionCode[]>>(this.environment.baseApiUrl + "condition_code/list?first=" + first + "&max=" + max);
      }

    addCode(data: ConditionCode): Observable<BaseResponse> {
    return this.httpClient.post<BaseResponse>(this.environment.baseApiUrl + "condition_code/create", data);
    }

    updateCode(data: ConditionCode): Observable<BaseResponse> {
    return this.httpClient.put<BaseResponse>(this.environment.baseApiUrl + "condition_code/update", data);
    }

    deactivateConditionCode(data: ConditionCode): Observable<BaseResponse> {
    return this.httpClient.put<BaseResponse>(this.environment.baseApiUrl + "condition_code/deactivate", data);
    }

    destroy(id: number): Observable<BaseResponse> {
    return this.httpClient.delete<BaseResponse>(this.environment.baseApiUrl + "condition_code/destroy/" + id);
    }

    getConditionCodesByParams = (packet:any, first:number, max:number) => {
        const endpoint = `${this.environment.baseApiUrl}condition_code/list?first=${first}&max=${max}`;
        return this.httpClient.post<PagedResponse<any>>(endpoint, packet);
      }

}
