import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../view/base.component';
import { FennecSnackbarService } from '../../dialog/fennec-snackbar/fennec-snackbar.service';
import { Logger } from '../../util/logger';
import { ProviderService } from '../provider.service';
import { ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AddressComponent } from '../../admin/address-admin/address.component';
import { AddressDialogComponent } from '../../admin/address-admin/address-dialog/address-dialog.component';
import { SingleChoiceDialogComponent } from 'projects/xf-common/src/public-api';

@Component({
  selector: 'lib-provider-address',
  templateUrl: './provider-address.component.html',
  styleUrl: './provider-address.component.scss'
})
export class ProviderAddressComponent extends BaseComponent implements OnInit {

  protected override log: Logger = new Logger("ProviderAddressComponent");
  
  providerId = -1;
  providerAddresses = [];
  displayColumns = ["addressLines", "city", "state", "zip", "type", "actions"];

  constructor(
    protected override snack: FennecSnackbarService,
    private providerService: ProviderService,
    protected activeRoute: ActivatedRoute,
    protected dialog: MatDialog,
  ) {
    super();
    this.providerId = this.activeRoute?.snapshot?.params["id"];
  }

  ngOnInit(): void {
    this.getData();
  }

  getData = () => {
    this.performXFRequest({
      requestDescription: "GET Provider Addresses",
      requestFn: this.providerService.getAddressesForProvider,
      fnParams: [this.providerId],
      onSuccess: data => {
        this.providerAddresses = data;
      },
      onError: errString => {
        super.showErrorSnack(errString);
      }
    })
  }


  openAddressDialog(providerAddress) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      mode: "provider_address",
      saveMode: providerAddress == null ? "CREATE" : "EDIT",
      addressId: providerAddress == null ? null : providerAddress?.address.id,
      providerAddress: providerAddress
    }
    const dialogRef = this.dialog.open(AddressDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result.addressId != null) {
        const dto = {
          id: result.providerAddressId,
          providerId: this.providerId,
          addressId: result.addressId,
          providerAddressType: result.providerAddressType
        }

        this.performXFRequest({
          requestDescription: "CREATE Provider Address",
          requestFn: this.providerService.addAddressForProvider,
          fnParams: [dto],
          onSuccess: data => {
            this.getData();
          },
          onError: errString => {
            super.showErrorSnack(errString);
          }
        })
      }
    })
  }

  deleteProviderAddresses = (providerAddress) => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      question: "Are you sure you want to delete this address?"
    }
    const dialogRef = this.dialog.open(SingleChoiceDialogComponent, dialogConfig); 

    dialogRef.afterClosed().subscribe((result) => {
      if (result.confirm) {

        this.performXFRequest({
          requestDescription: "CREATE Provider Address",
          requestFn: this.providerService.deleteProviderAddress,
          fnParams: [providerAddress.id],
          onSuccess: data => {
            this.getData();
          },
          onError: errString => {
            super.showErrorSnack(errString);
          }
        })
      }
    })
  }
}
