import { Component, Inject, OnInit } from '@angular/core';
import { BaseComponent, 
  DateUtil, 
  FennecSnackbarService, 
  Logger, 
  MICaseService, 
  SimpleObject,
  SingleChoiceDialogComponent } from 'xf-common';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";
import { XfwfService } from '../../xfwf/xfwf.service';

@Component({
  selector: 'case-hold-info',
  templateUrl: './case-hold-dialog.component.html',
  styleUrls: ['./case-hold-dialog.component.scss']
})
export class CaseHoldDialogComponent extends BaseComponent implements OnInit {
  log = new Logger("CaseHoldDialogComponent");
  id?: number;

  formGroup: FormGroup;
  formGroup2: FormGroup;
  minHoldReviewDate = DateUtil.getDenverJSDateNow();
  holdReviewDateControl?: AbstractControl;
  holdReasons: any;
  holdReasonMap: SimpleObject = {};
  // XFWF On Hold Task due date if the case is currently on hold.
  dueDate: string;
  // Case Review Due Date - used to prompt for the On Hold Review Date (i.e. dueDate) if this date is not null.
  caseReviewDueDate: string | null = null;

  // Used for UI display if case is ON Hold.
  latestNonHoldXFWFTask: any = null;

  constructor(
    protected snack: FennecSnackbarService,
    protected dialog: MatDialog,
    public dialogRef: MatDialogRef<CaseHoldDialogComponent>,
    private miCaseService: MICaseService,
    private xfwfService: XfwfService,
    @Inject(MAT_DIALOG_DATA) public miCaseInfo: any
  ) {
    super();

    this.id = miCaseInfo?.id;
    this.dueDate = miCaseInfo?.dueDate;
    this.caseReviewDueDate = DateUtil.fennecDBStringToInputDateString(miCaseInfo?.caseReviewDueDate);

    this.formGroup = new FormGroup({
      holdReviewDate: new FormControl(this.caseReviewDueDate ?? "", this.validateHoldReviewDate()),
      holdReason: new FormControl(miCaseInfo.holdReason ?? "")
    });
    this.formGroup2 = new FormGroup({
      createXFWFTask: new FormControl(true)
    });

    this.getHoldReasons();
    
    // If the case is currently on hold, grab information on the latest NON HOLD xfwf on the case (if there is one).
    if (miCaseInfo?.onHold) {
      this.getLatestNonHoldTaskForMICase();
    }
  }

  ngOnInit(): void {
    this.holdReviewDateControl = this.formGroup.controls["holdReviewDate"];
  }

  getHoldReasons() {
    this.performXFRequest({
      requestDescription: "Get hold reasons",
      requestFn: this.miCaseService.getHoldReasons,
      onSuccess: holdReasons => {
        this.holdReasons = holdReasons;
      },
      onError: err => this.snack.showErrorSnack(err)
    });
  }

  getLatestNonHoldTaskForMICase() {
    this.performXFRequest({
      requestDescription: "Get latest non hold task",
      requestFn: this.xfwfService.getLatestNonNullTaskForMICase,
      fnParams: [this.miCaseInfo?.id],
      onSuccess: latestTaskInfo => {
        this.latestNonHoldXFWFTask = latestTaskInfo;
        console.log(latestTaskInfo);
      },
      onError: err => this.snack.showErrorSnack(err)
    });
  }  

  validateHoldReviewDate(): ValidatorFn {
    return (ctrl: AbstractControl): ValidationErrors | null => {
      const val = ctrl.value;
      if (val == null || val == "") {
        // not a required field; no error
        return null;
      }

      if (val.length != 10) {
        // value entered does not match format length
        return {incomplete: true};
      }

      const dateEntered = DateUtil.inputDateStringToJSDate(val);
      if (dateEntered == null) {
        // value failed to parse as MM/dd/yyyy
        return {incomplete: true};
      }

      if (dateEntered < this.minHoldReviewDate) {
        // value entered is too early
        return {dateTooEarly: true};
      }

      // value entered is valid
      return null;
    }
  }

  submitCaseHoldConfirm () {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.invalid && this.miCaseInfo?.onHold === false) {
      return;
    }

    let question = "Put case on hold?";
    if (this.miCaseInfo?.onHold) {
      if (this.formGroup2.controls["createXFWFTask"].getRawValue()) {
        question = "Take case off hold and create workflow task?";
      } else {
        question = "Take case off hold?";
      }
    }

    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      question : question,
    };
    const dialogRef = this.dialog.open(SingleChoiceDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {
        this.submitCaseHold();
      }
    });
  }

  submitCaseHold () {
    const holdReason = this.formGroup.value?.holdReason;
    const data: SimpleObject = {
      id: this.id,
      holdReason: holdReason === "" ? null : holdReason,
      holdReviewDate: DateUtil.inputDateStringToFennecDBDateString(this.formGroup.value?.holdReviewDate),
      desiredHoldState: !this.miCaseInfo?.onHold,
      createXFWFTask: this.formGroup2.value?.createXFWFTask
    };

    this.performXFRequest({
      requestDescription: "Toggle Case Hold",
      requestFn: this.miCaseService.toggleMICaseHold,
      fnParams: [data],
      onSuccess: (data) => {
        this.dialogRef.close(data);
        super.showSuccessSnack("Success");
        this.miCaseService.emitRefreshCaseInfo();
      },
      onError: (error) => {
        super.showWarningSnack("Failed to edit case hold info.\n" + error);
        this.log.debug("Failed to edit case hold info", error);
      }
    });
  }

  close() {
    this.dialogRef.close(null);
  }

}
