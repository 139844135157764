import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ItemizedEditDialogComponent } from '../itemized-edit-dialog/itemized-edit-dialog.component';
import { ItemizedLineItemComponent } from '../itemized-line-item/itemized-line-item/itemized-line-item.component';
import {
  ItemizedRevisionListComponent
} from '../itemized-revision/itemized-revision-list/itemized-revision-list.component';
import { ItemizedRevisionService } from '../itemized-revision/itemized-revision.service';
import {
  BaseComponent,
  BaseResponse,
  FennecSnackbarService,
  ItemizedCreateComponent,
  ItemizedService,
  ItemizedUpdatePacket, Logger,
  Ub04SelectComponent
} from 'xf-common';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-itemized-list',
  templateUrl: './itemized-list.component.html',
  styleUrls: ['./itemized-list.component.scss']
})
export class ItemizedListComponent extends BaseComponent implements OnInit, AfterViewInit {

  log: Logger = new Logger("CompanyAddComponent");

  @Input()
  miCaseId: string = "-1";

  @ViewChild('ub04SelectComponent')
  ub04SelectComponent!: Ub04SelectComponent;

  screenMode: string = "OVERVIEW";
  itemizedList: any [] = [];
  selectedItemizedId: string = "-1";
  selectedItemizedRevisionId: string = "-1";

  @ViewChild('itemizedRevisionListComponent')
  itemizedRevisionListComponent!: ItemizedRevisionListComponent;

  @ViewChild('itemizedLineItemComponent')
  itemizedLineItemComponent!: ItemizedLineItemComponent;

  @ViewChild('itemizedEditDialogComponent')
  itemizedEditDialogComponent!: ItemizedEditDialogComponent;

  defaultPageSize:number = 3;
  totalRowCount:number = 0;

  @ViewChild('paginator')
  paginator?: MatPaginator;

  constructor(
    private itemizedRevisionService: ItemizedRevisionService,
    private itemizedService: ItemizedService,
    protected override snack: FennecSnackbarService,
    public matDialog: MatDialog,
    // public matDialogRef: MatDialogRef<ItemizedEditDialogComponent, any>,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    super();
    if (this.route.parent) {
      this.miCaseId = this.route.parent?.snapshot.paramMap.get("miCaseId") ?? "-1";
    }
  }

  ngAfterViewInit(): void {
    this.getItemizedList();
    this.itemizedRevisionListComponent?.itemizedLineItemComponentRequested?.subscribe((id) => {
      this.selectedItemizedRevisionId = id;
      this.screenMode = "ITEMIZED-LINE-ITEM"
      setTimeout(() => {
        this.itemizedLineItemComponent?.fetchLineItems();
        this.itemizedLineItemComponent?.getItemizedRevisionInfoQuery();
        this.itemizedLineItemComponent?.itemizedLineItemComponentClose.subscribe((obj) => {
          this.screenMode = "OVERVIEW";
        });
      }, 100);
    });
  }

  ngOnInit(): void {
  }

  getSelectedStyle(id: number) {
    if (id.toString() === this.selectedItemizedId) {
      return {
        "background-color": "powderblue"
      }
    } else {
      return {
        "background-color": "#E5E6EB"
      }
    }
  }

  getItemizedList() {
    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;

    this.itemizedService.getAllItemizedForMICasePaginated(parseInt(this.miCaseId, 10), first, pageSize).subscribe((response: any) => {
      if (response.hasErrors) {
        this.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.itemizedList = response.data;
        this.totalRowCount = response['totalRowCount'];
        if (this.selectedItemizedId === "-1") {
          if (this.itemizedList.length > 0) {
            this.selectItemized(this.itemizedList[0].id);
          } else {
            this.selectItemized(parseInt(this.selectedItemizedId, 10));
          }
        }
      }
    });
  }

  createItemized(miCaseId: any) {
    // Create a new ItemizedUpdatePacket object
    const newItemized = new ItemizedUpdatePacket();
    // Set the miCaseId property to the miCaseId input property
    newItemized.miCaseId = parseInt(this.miCaseId, 10);
    // Open a dialog for the user to enter the external alt id for the new itemized
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      miCaseId: miCaseId,
      origin: 'MANUAL'
    };
    const dialogRef = this.matDialog.open(ItemizedCreateComponent, dialogConfig);

    // After the dialog is closed, subscribe to the returned result
    dialogRef.afterClosed().subscribe(result => {
      // If the user entered an external alt id, set it on the newItemized object
      console.log(result);
      if (result.confirm) {
        this.selectedItemizedId = "-1";
        this.getItemizedList();
      }
    });
  }

  selectItemized(id: number) {
    this.selectedItemizedId = id.toString();
    this.itemizedRevisionListComponent?.getItemizedRevisions(id);
  }

  createItemizedViaUpload(miCaseId: any) {
    this.router.navigated = false
    // reset navigation stack
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';

    const extras: NavigationExtras = {
      state: { tabIndex: 3 }
    };

    this.router.navigate(['mi-case', miCaseId, 'attachments'], extras).then(() => {
    });
  }

  returnToOverview() {
    this.screenMode = "OVERVIEW";
    this.getItemizedList();
  }

  ub04SelectMode(itemizedId: number) {
    this.selectItemized(itemizedId);
    this.ub04SelectComponent.getUb04List(parseInt(this.miCaseId), itemizedId);
    this.screenMode = "UB04-SELECT";
  }

  getFormattedItemizedName() {
    const itemized = this.getSelectedItemized();

    if(itemized != null && itemized['externalAltId'] != null) {
      return itemized['externalAltId'];
    }
    return `Itemized ID:${this.selectedItemizedId}`
  }

  getSelectedItemized() {
    let result = null;
    this.itemizedList.forEach(item => {
      if(item && item.id && item.id === parseInt(this.selectedItemizedId)) {
        result = item;
        return;
      }
    })

    return result;
  }

  updateItemized(itemizedId: number, externalAltId: string) {
    const newItemized = new ItemizedUpdatePacket();
    newItemized.id = itemizedId;
    newItemized.externalAltId = externalAltId;
    newItemized.miCaseId = parseInt(this.miCaseId, 10);

    this.itemizedService.update(newItemized).subscribe((response: any) => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.snack.showSuccessSnack("Itemized updated successfully");
        this.getItemizedList();
      }
    });
  }


    // Method to open the Edit Itemized dialog
  openEditItemizedDialog(itemizedId: number) {

    // Find the itemized to edit.
    let itemized = null;
    this.itemizedList.forEach(item => {
      if (item && item.id && item.id === itemizedId) {
        itemized = item;
        return;
      }
    });
    if (itemized == null) {
      return;
    }

    // Create a new MatDialogConfig object
    const dialogConfig = new MatDialogConfig();

    // Set the width and height of the dialog
    dialogConfig.width = '500px';
    dialogConfig.height = '350px';

    // Pass the data to the dialog
    dialogConfig.data = {
      itemized : itemized
    };

    // Open the dialog and store the reference in a variable
    const dialogRef = this.matDialog.open(ItemizedEditDialogComponent, dialogConfig);

    // Subscribe to the afterClosed observable to handle the dialog result
    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {

        // If the result is truthy, call the updateItemized() method
        // and pass in the itemizedId and externalAltId
        const payload = new ItemizedUpdatePacket();
        payload.id = itemizedId;
        payload.externalAltId = result.itemized.externalAltId;

        this.itemizedService.update(payload).subscribe((response: BaseResponse) => {
          if (response.hasErrors) {
            this.snack.showErrorSnack(response.consolidatedErrorMessage);
          } else {
            // If the response is successful, show a success message
            this.snack.showSuccessSnack("Itemized updated successfully!");
            // and refresh the itemized list
            this.getItemizedList();
          }
        });
      }
    });
  }

  navigateToItemizedRevision(iRevId: number) {
    // Get the itemized that the requested itemized revision id is associated with. We will then scan the
    // existing, loaded itemized list and reposition the user there.
    let itemizedId: number = -1;
    this.itemizedRevisionService.getItemizedRevisionInfo(iRevId).subscribe((response: any) => {
      if (response.hasErrors) {
        this.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        itemizedId = response.data.itemizedId;

        if (itemizedId >= 0) {
          this.selectItemized(itemizedId);
          this.itemizedRevisionListComponent?.selectItemizedRevision(iRevId);
        }

      }
    });

  }

}
