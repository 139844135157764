import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { BaseComponent, FennecSnackbarService, Logger } from 'xf-common';
import { CommentListComponent } from '../comment-list/comment-list.component';

@Component({
  selector: 'app-comment-list-dialog',
  templateUrl: './comment-list-dialog.component.html',
  styleUrls: ['./comment-list-dialog.component.scss']
})
export class CommentListDialogComponent extends BaseComponent implements OnInit, AfterViewInit {

  protected log: Logger = new Logger("CommentListDialogComponent");

  mode: string = "MI_CASE";
  relatedId: string | number = "-1";
  //
  lineItemIds:[] = [];

  @ViewChild(CommentListComponent)
  commentListComponent?: CommentListComponent;

  public readonly defaultPage = 0;
  public defaultPageSize = 10;
  public readonly defaultPageSizeOptions = [10, 25, 50];
  public totalRowCount = 0;

  @ViewChild(MatPaginator)
  paginator?: MatPaginator;

  constructor(
    override snack: FennecSnackbarService,
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<CommentListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    super();
    if(data) {
      this.mode = data.mode;
      this.relatedId = data.relatedId;
      this.lineItemIds = data.lineItemIds ?? [];
    }

   }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    if (this.paginator && this.commentListComponent) {
      this.commentListComponent.dialogPaginator = this.paginator;

      this.paginator.page.subscribe(() => {
        this.commentListComponent?.getComments();
      })
    }
  }

  closeDialog = () => {
    this.dialogRef.close();
  }

  openAddDialog = () => {
    if(this.commentListComponent) {
      this.commentListComponent.openCommentDialog();
    }
  }
}
