import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DateUtil } from 'xf-common';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";

@Component({
  selector: 'app-holiday-edit-dialog',
  templateUrl: './holiday-edit-dialog.component.html',
  styleUrls: ['./holiday-edit-dialog.component.scss']
})
export class HolidayEditDialogComponent implements OnInit {

  formGroup!: FormGroup;
  holidayData: any;
  mode?: "Edit" | "Create";

  constructor(
    public dialogRef: MatDialogRef<HolidayEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private fb: FormBuilder
  ) {
    this.mode = data?.mode ?? "Create";
    this.holidayData = data?.holiday ?? {};
  }

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.formGroup = this.fb.group({
      name: [this.holidayData?.name ?? "", Validators.required],
      date: [this.holidayData?.date ?? "", DateUtil.dateStringValidator()]
    });
  }

  close(save?: boolean) {
    this.formGroup.markAllAsTouched();
    Object.assign(this.holidayData, this.formGroup.value);
    this.dialogRef.close(save ? this.holidayData : null);
  }
}
