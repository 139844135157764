<mat-dialog-content>
  <h3>{{data.mode}} JSL Job Definition Param</h3>

  <form [formGroup]="jobDefinitionParamDialogFormGroup">

    <mat-form-field appearance="outline" color="accent" class="mat-form-field-seq-no">
      <mat-label>Seq No</mat-label>
      <input type="number" matInput placeholder="Seq No" formControlName="seqNo"/>
    </mat-form-field>

    <mat-form-field appearance="outline" color="accent">
      <mat-label>Param Type</mat-label>
      <mat-select formControlName="paramType" required>
      <mat-option *ngFor="let paramType of paramTypes" [value]="paramType">
        {{paramType}}
      </mat-option>
    </mat-select> 
    </mat-form-field>

    <mat-form-field appearance="outline" color="accent" class="mat-form-field-param-prompt">
      <mat-label>User Parameter Prompt</mat-label>
      <input matInput placeholder="Enter User Param Prompt" formControlName="paramPrompt"/>
    </mat-form-field>
  </form>
</mat-dialog-content>
  
<mat-dialog-actions class="fennec-controls">
    <button type="submit" mat-flat-button color="accent" (click)="submit()" style="margin-right: 5px;">
      <mat-icon>save</mat-icon> Save
    </button>
    <button type="button" mat-stroked-button (click)="close()">
        <mat-icon>cancel</mat-icon> Cancel
    </button>
</mat-dialog-actions>
