<div class="wrapper">
  <!-- <div *ngFor="let comment of commentList">
    <p>{{comment.commentText}}</p>
  </div> -->

  <div
    class="add-comment-btn"
    *ngIf="commentList.length > 0 && displayContext === 'FLAT'"
  >
    <button
      mat-raised-button
      (click)="openCommentDialog()"
      >
      <mat-icon color="accent">add_comment</mat-icon>
      Add Comment
    </button>
  </div>

  <!-- old approach  -->
<!-- <app-add-comment #add [hidden]="!addNewActive" mode="{{mode}}" relatedId="{{relatedId}}" formattedUsername="{{getFormattedUsername()}}"></app-add-comment> -->

  <mat-card *ngIf="commentList.length === 0" class="no-comment-card">
    <mat-card-header>
      <mat-card-title class="ncf-header">
        No Comments found
      </mat-card-title>
    </mat-card-header>

    <mat-card-actions>
      <button
        *ngIf="displayContext === 'FLAT'"
        mat-stroked-button
        (click)="openCommentDialog()"
        >
          <mat-icon color="accent">add_comment</mat-icon>
          Add Comment
        </button>
    </mat-card-actions>

  </mat-card>

  <mat-card *ngFor="let comment of commentList" class="comment-card">
    <mat-card-header>
      <mat-card-title>
        {{comment.authorFirstName}} {{comment.authorLastName}}
      </mat-card-title>
      <mat-card-subtitle>
        Type: {{comment.commentType}}
      </mat-card-subtitle>

      <mat-card-subtitle>
        {{(comment.createdDate | date: 'MM/dd/yyyy h:mm a') + (comment.createdDate !== comment.lastModifiedDate ? ' (edited: ' + (comment.lastModifiedDate | date: 'M/dd/yyyy h:mm a') + ')' : '')}}
      </mat-card-subtitle>

    </mat-card-header>
    <mat-card-content>
      <pre class="comment-content">
        {{comment.commentText}}
      </pre>
    </mat-card-content>

    <mat-card-actions>
      <button
        *ngIf="comment.authorUserId === userProfile?.userId"
        mat-icon-button
        matTooltip="Edit Comment"
        (click)="openCommentDialog(comment)"
      >
        <mat-icon>edit</mat-icon>
      </button>

      <button
        *ngIf="comment.authorUserId === userProfile?.userId || adminDelete"
        mat-icon-button
        matTooltip="Delete Comment"
        (click)="deleteComment(comment)"
      >
        <mat-icon>delete</mat-icon>
      </button>
    </mat-card-actions>


  </mat-card>

  <mat-paginator
    *ngIf="displayContext === 'FLAT'"
    class="alt-theme"
    [length]="totalRowCount"
    [pageSize]="defaultPageSize"
    [pageSizeOptions]="defaultPageSizeOptions">
  </mat-paginator>
</div>
