<mat-card *ngIf="miCase">
  <mat-card-header>
    <mat-card-title>
      <span class="label-text">Main Status:</span> {{miCase.statusTrackStatus}}
      <span class="mi-case-overview-on-hold-reason-title" *ngIf="miCase.holdReasonUserLabel !== null && miCase.holdReasonUserLabel !== undefined">
        Reason: {{miCase.holdReasonUserLabel}}
      </span>
    </mat-card-title>

    <mat-card-subtitle>
      {{miCase.typeLabel}}
    </mat-card-subtitle>    

  </mat-card-header>
  <mat-card-content>

    <div style="width: 100%; display: table;">
      <div style="display: table-row">
          <div style="display: table-cell;">                                  
            <ng-container *ngIf="!miCase.terminalStatus">
              <!-- Only allow the user to cancel or mark a case complete if it's not on hold. Using the hold reason label to do this. There
                might be a better way to check for on hold here. -->
                <ng-container *ngIf="(miCase.holdReasonUserLabel === null || miCase.holdReasonUserLabel === undefined)">
                  <button 
                  mat-stroked-button
                  color="accent"
                  class="mi-case-close-button"
                  (click)="confirmCloseDialog('CANCEL')"
                >
                  <mat-icon>check</mat-icon>
                  Cancel Case
                </button>                
                <button 
                  mat-stroked-button
                  color="accent"
                  class="mi-case-close-button"
                  (click)="confirmCloseDialog('COMPLETE')"
                >
                  <mat-icon>check</mat-icon>
                  Mark as Complete
                </button>
              </ng-container>
            </ng-container>
            <button
            class="mi-case-edit-button"
            mat-flat-button color="accent"
            (click)="handleOpenEditDialog(miCase)">
            <mat-icon>edit</mat-icon>
            Edit
            </button>            
          </div>
          <div *ngIf="activityLogAccess" style="display: table-cell">
            <button mat-icon-button matTooltip="Activity Log" class="mi-case-activity-log-button" (click)="openActivityLog()">
              <mat-icon>view_kanban</mat-icon>
            </button>
            <button mat-icon-button matTooltip="Status Track Log" class="mi-case-status-track-log-button" (click)="openStatusTrackLog()">
              <mat-icon>view_kanban</mat-icon>
            </button>            
          </div>
      </div>
    </div>      

    <div class="info-content">
        <p>
          <span class="label-text">Client Name: </span>
          <span [attr.data-field]="'name'">{{miCase.name}}</span>
        </p>
        <p>
          <span class="label-text">Primary Client:</span>
          <span [attr.data-field]="'primaryClientName'">{{miCase.primaryClientName}}</span>
          <button
            class="primary-client-edit-btn"
            *ngIf="isUserAdmin || isUserCaseClientUpdate"
            mat-icon-button
            (click)="openPrimaryClientEditDialog()"
          >
            <mat-icon class="primary-client-edit-btn">edit</mat-icon>
          </button>
        </p>  
        <p>
          <span class="label-text">Current Stage:</span>
          <span [attr.data-field]="'currentXFWFStageName'">{{miCase.currentXFWFStageName}}</span>
        </p>              
        <p>
          <span class="label-text">Alt External ID:</span>
          <span [attr.data-field]="'altExternalId'">{{ miCase.altExternalId != null ? miCase.altExternalId : 'N/A' }}</span>
        </p>
        <p>
          <span class="label-text">Plan:</span>
          <span [attr.data-field]="'healthCarePlanName'">{{miCase?.healthCarePlanName ? miCase.healthCarePlanName + " - " + miCase.healthCarePlanStateCode : "N/A"}}</span>
          <button
            class="primary-client-edit-btn"
            *ngIf="isUserAdmin || isUserCaseHealthCarePlanUpdate"
            mat-icon-button
            (click)="openHealthCarePlanEditDialog()"
          >
            <mat-icon class="primary-client-edit-btn">edit</mat-icon>
          </button>
        </p> 
        <p>
          <span class="label-text">Current/Latest Task:</span>
          <span [attr.data-field]="'currentXFWFTaskType'">{{miCase.currentXFWFTaskType}}</span>
        </p>               
        <p>
          <span class="label-text">Salesforce Case ID:</span>
          <span [attr.data-field]="'salesforceCaseId'">{{ miCase.salesforceCaseId != null ? miCase.salesforceCaseId : 'N/A' }}</span>
        </p>
        <p>
          <span class="label-text">Client Claim ID:</span>
          <span [attr.data-field]="'clientClaimId'">{{miCase.clientClaimId ?? "N/A"}}</span>
        </p> 
        <p>
          <span class="label-text">Main Status:</span>
          <span [attr.data-field]="'statusTrackStatus'">{{miCase.statusTrackStatus ?? "N/A"}}</span>
          <span class="hold-reason-user-label" *ngIf="miCase.holdReasonUserLabel !== null && miCase.holdReasonUserLabel !== undefined">
            {{miCase.holdReasonUserLabel}}
          </span>
        </p>               
        <p>
          <span class="label-text">Trial Case:</span>
          <span [attr.data-field]="'trialCase'">{{ miCase.trialCase ? "Yes" : "No" }}</span>
        </p>
        <p>
          <span class="label-text">Client Member Number:</span>
          <span [attr.data-field]="'clientMemberNumber'">{{miCase.clientMemberNumber ?? "N/A"}}</span>
        </p> 
        <p>
          <span class="label-text">Customer Status:</span>
          <span [attr.data-field]="'customerFacingStatus'">{{miCase.customerFacingStatus ?? "N/A"}}</span>
          <span class="hold-reason-user-label" *ngIf="miCase.holdReasonUserLabel !== null && miCase.holdReasonUserLabel !== undefined"
                [attr.data-field]="'holdReasonUserLabel'">
            {{miCase.holdReasonUserLabel}}
          </span>
          <button
          class="primary-client-edit-btn"
          mat-icon-button
          (click)="handleEditCustomerStatus(miCase, '')">
          <mat-icon>edit</mat-icon>
        </button>
        </p>               
        <p>
          <span class="label-text">Case Review Due Date:</span>
          <span [attr.data-field]="'caseReviewDueDate'">{{ miCase.caseReviewDueDate != null ? miCase.caseReviewDueDate : 'N/A' }}</span>
        </p>
        <p>
          <span class="label-text">Created Date:</span>
          <span [attr.data-field]="'createdDate'">{{miCase.createdDate | date:'MM/dd/yyyy h:mm a'}}</span>
        </p> 
        <p>
          <span class="label-text">Line of Business:</span>
          <span [attr.data-field]="'lineOfBusiness'">{{miCase.lineOfBusiness ?? "N/A"}}</span>
        </p>               
        <p>
          <span class="label-text">IB Requested Date:</span>
          <span [attr.data-field]="'ibRequestedDate'">{{ miCase.ibRequestedDate != null ? miCase.ibRequestedDate : 'N/A' }}</span>
        </p>
        <p>
          <span class="label-text">Created By:</span>
          <span [attr.data-field]="'createdUser'">{{miCase.createdUser}}</span>
        </p> 
        <p>
          <span class="label-text">Network Discount:</span>
          <span [attr.data-field]="'networkDiscount'">{{ miCase.networkDiscount != null ? miCase.networkDiscount + "%" : 'N/A' }}</span>
        </p>               
        <p>
          <span class="label-text">Pre 6DH Review Payable:</span>
          <span [attr.data-field]="'preReviewPayable'">{{ miCase.preReviewPayable != null ? (miCase.preReviewPayable | currency) : 'N/A' }}</span>
        </p>
        <p>
          <span class="label-text">Type:</span>
          <span [attr.data-field]="'type'">{{miCase.typeLabel ?? "N/A"}}</span>
        </p> 
        <p>
          <span class="label-text">Post Payment Review:</span>
          <span [attr.data-field]="'postPaymentReview'">{{ miCase.postPaymentReview === true ? "Yes" : "No" }}</span>
        </p>               
        <p>
          <span class="label-text">Post 6DH Review Payable:</span>
          <span [attr.data-field]="'postReviewPayable'">{{ miCase.postReviewPayable != null ? (miCase.postReviewPayable | currency) : 'N/A' }}</span>
        </p>
        <p>
          <span class="label-text">DRG Paid Date:</span>
          <span [attr.data-field]="'drgPaidDate'">{{ miCase.drgPaidDate != null ? (miCase.drgPaidDate) : 'N/A' }}</span>
        </p> 
        <p>
          <span class="label-text">Estimated Amount Due:</span>
          <span [attr.data-field]="'estimatedAmountDue'">{{ estimatedAmountDue }}</span>
        </p>               
        <p>
          <span class="label-text">Post Appeal Payable:</span>
          <span [attr.data-field]="'postAppealPayable'">{{ miCase.postAppealPayable != null ? (miCase.postAppealPayable | currency) : 'N/A' }}</span>
        </p>
        <p>
          <span class="label-text">Par/Non-Par:</span>
          <span [attr.data-field]="'providerPar'">{{ miCase?.providerPar ?? "N/A" }}</span>
        </p>
        <p>
          <span class="label-text">DRG Payment Amount:</span>
          <span [attr.data-field]="'drgPaymentAmount'">{{ miCase.drgPaymentAmount != null ? (miCase.drgPaymentAmount | currency) : 'N/A' }}</span>
        </p> 
        <p>
          <span class="label-text">Client Internal Adjustments:</span>
          <span [attr.data-field]="'clientInternalAdjustments'">{{ miCase.clientInternalAdjustments != null ? (miCase.clientInternalAdjustments | currency) : 'N/A' }}</span>
        </p> 
        <p>
          <span class="label-text">Insured Group No:</span>
          <span [attr.data-field]="'insuredGroupNo'">{{ miCase.insuredGroupNo ?? "N/A" }}</span>
        </p>               
        <p>
          <span class="label-text">Cover Period From:</span>
          <span [attr.data-field]="'coverPeriodFromDate'">{{ miCase.coverPeriodFromDate != null ? miCase.coverPeriodFromDate : 'N/A' }}</span>
        </p>
        <p></p>
        <p>
          <span class="label-text">Insured Group Name:</span>
          <span [attr.data-field]="'insuredGroupName'">{{ miCase.insuredGroupName ?? "N/A" }}</span>
        </p>
        <p>
          <span class="label-text">Cover Period Thru:</span>
          <span [attr.data-field]="'coverPeriodThruDate'">{{ miCase.coverPeriodThruDate != null ? miCase.coverPeriodThruDate : 'N/A' }}</span>
        </p>
        <p></p>
        <p></p>        
    </div>
    <div style="margin-top: 5px">
      <span class="label-text">Notes:</span>
      <p style="margin-left: 5px; margin-right: -15px;" [attr.data-field]="'notes'">
        {{miCase.notes ?? "N/A"}}
      </p>
    </div>
  </mat-card-content>
</mat-card>
