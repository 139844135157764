import { Deserializable, deserializeAssign } from "../deserializable";


export class State implements Deserializable<State> {
    id: number = -1;
    stateName: String="";
    stateCode: String="";
    active?: boolean;
    selected?: boolean;

  deserialize(input: any): State {
    return deserializeAssign(this, input);
  }

  toJSON(): any {
    const obj: { [key: string]: any } = {};
    Object.assign(obj, this);
    delete obj["selected"];
    return obj;
  }
}
