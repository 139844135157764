<lib-page #page [noDataFound]="dataSource.data?.length === 0" [isLoading]="isLoading">
    <ng-container pageTitle>
      Adjustment Explanation Codes
    </ng-container>

    <ng-container pageContent class="container-override">

      <mat-accordion>
        <mat-expansion-panel
          (opened)="filtersOpen = true"
          (closed)="filtersOpen = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h4>Filters</h4>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <form [formGroup]="formGroup" class="alt-theme" (submit)="onSearch()">
            <div class="input-wrapper">
              <mat-form-field appearance="outline" class="code-search-input">
                <mat-label>Code</mat-label>
                <input matInput placeholder="Search by Code" formControlName="code">
              </mat-form-field>

              <mat-form-field appearance="outline" class="description-search-input">
                <mat-label>Description</mat-label>
                <input matInput placeholder="Search by Description" formControlName="description">
              </mat-form-field>

              <mat-form-field appearance="outline" class="active-search-input">
                <mat-label>Status</mat-label>
                <mat-select formControlName="active">
                  <mat-option [value]="'all'">All</mat-option>
                  <mat-option [value]="true">Active</mat-option>
                  <mat-option [value]="false">Not Active</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field appearance="outline" class="type-input">
                <mat-label>Type</mat-label>
                <mat-select formControlName="adjustmentExplanationType">
                  <mat-option [value]="'all'">All</mat-option>
                  <mat-option *ngFor="let type of adjustmentExplanationTypes" [value]="type">{{ type }}</mat-option>
                </mat-select>
              </mat-form-field>

              <div class="search-button-div">
                <button
                  mat-stroked-button
                  color="accent"
                  type="submit"
                  class="search-button"
                >
                  <mat-icon>search</mat-icon> Search
                </button>

                <button
                  mat-stroked-button
                  color="primary"
                  (click)="clearSearch()"
                >
                  Clear
                </button>
              </div>
            </div>
          </form>
        </mat-expansion-panel>
      </mat-accordion>

      <mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="code">
          <mat-header-cell *matHeaderCellDef> Code</mat-header-cell>
          <mat-cell *matCellDef="let code"> {{code?.code}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="description">
          <mat-header-cell *matHeaderCellDef> Description</mat-header-cell>
          <!-- Note: had to remove app-truncate-text so large descriptions would render w/o the parent
              form moving off to the right of the screen. If you wish to revive, please test
              Adjustment Explanation Codes with large descriptions to make sure whole form stays on
              the screen. -->
          <mat-cell *matCellDef="let code">{{code?.description}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="shortDescription">
          <mat-header-cell *matHeaderCellDef> Short Desc </mat-header-cell>
          <mat-cell *matCellDef="let code"><app-truncate-text> {{code?.shortDescription}} </app-truncate-text></mat-cell>
        </ng-container>

        <ng-container matColumnDef="type">
          <mat-header-cell *matHeaderCellDef> Type </mat-header-cell>
          <mat-cell *matCellDef="let code"><app-truncate-text> {{code?.adjustmentExplanationType}} </app-truncate-text></mat-cell>
        </ng-container>

        <ng-container matColumnDef="internal">
          <mat-header-cell
            *matHeaderCellDef>
            Public
          </mat-header-cell>
          <mat-cell *matCellDef="let code">
            @if (code?.internal) {
              <mat-icon class="active">check_small</mat-icon>
            }
            @else {
              <mat-icon class="non-active">close</mat-icon>
            }
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="visibleOnDashboard">
          <mat-header-cell *matHeaderCellDef>Visible on Dashboard</mat-header-cell>
          <mat-cell *matCellDef="let code">
            @if (code?.visibleOnDashboard) {
              <mat-icon class="active">check_small</mat-icon>
            }
            @else {
              <mat-icon class="non-active">close</mat-icon>
            }
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="active">
          <mat-header-cell *matHeaderCellDef> Active </mat-header-cell>
          <mat-cell *matCellDef="let code">
            @if (code?.active) {
              <mat-icon class="active">check_small</mat-icon>
            }
            @else {
              <mat-icon class="non-active">close</mat-icon>
            }
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef>
            <button class="add" mat-flat-button (click)="onCreate()">
              <mat-icon>add_circle</mat-icon>
              New
            </button>
          </mat-header-cell>
          <mat-cell *matCellDef="let code">
            <mat-menu #appMenu="matMenu">
              <button
                *ngIf="canUpdate"
                matTooltip="Edit (e)"
                mat-menu-item color="accent"
                (click)="onEdit(code.id)">
                <mat-icon>edit</mat-icon>
                <span>Edit</span>
              </button>
              <button
                *ngIf="canDelete"
                matTooltip="Delete (d)"
                mat-menu-item color="primary"
                (click)="onDelete([code.id])">
                <mat-icon>delete</mat-icon>
                <span>Delete</span>
              </button>
              <button
                *ngIf="!code?.active && canDeactivate"
                matTooltip="Activate"
                mat-menu-item
                (click)="openAdjustmentExplanationCodeStatusChangeDialog(code)">
                <mat-icon>check</mat-icon>
                <span>Activate</span>
              </button>
              <button
                *ngIf="code?.active && canDeactivate"
                matTooltip="Deactivate"
                mat-menu-item
                (click)="openAdjustmentExplanationCodeStatusChangeDialog(code)">
                <mat-icon>close</mat-icon>
                <span>Deactivate</span>
              </button>
            </mat-menu>

            <button mat-icon-button [matMenuTriggerFor]="appMenu">
              <mat-icon>more_vert</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedColumns; let i=index"
          [tabindex]="i + 1"
          [attr.data-index]="i"
          [ngClass]="{ hovered: row.hovered }"
          (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"
        ></mat-row>
      </mat-table>
      <mat-paginator [length]="totalRowCount" [pageSize]="defaultPageSize" [pageSizeOptions]="defaultPageSizeOptions"></mat-paginator>

    </ng-container>

  </lib-page>
