import { AfterViewInit, Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogConfig } from '@angular/material/dialog';
import { StatusTrackLogDialogConfig } from 'projects/xf-common/src/lib/model/status-track-log-dialog-config';
import { StatusTrackService } from 'projects/xf-common/src/public-api';
import { FennecSnackbarService } from 'xf-common';

@Component({
  selector: 'app-status-track-log-dialog',
  templateUrl: './status-track-log-dialog.component.html',
  styleUrls: ['./status-track-log-dialog.component.scss']
})
export class StatusTrackLogDialogComponent implements AfterViewInit, OnInit {

  configData: StatusTrackLogDialogConfig;

  dataSource = new MatTableDataSource<any>()
  displayColumns = ['statusTrackStatus', 'createdBy', 'createdDate', 'lastModifiedBy', 'lastModifiedDate'];  

  @ViewChild('adPaginator')
  paginator?: MatPaginator;

  totalRowCount?: number;
  defaultPageSize = 20;
  pageSizeOptions = [5, 10, 20, 25, 50];  

  constructor(
    private statusTrackService: StatusTrackService,
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<StatusTrackLogDialogComponent>,
    private snack: FennecSnackbarService,
    @Inject(MAT_DIALOG_DATA) private data: StatusTrackLogDialogConfig
  ) {
    this.configData = data;
  }

  ngOnInit(): void {
    this.getStatusTrackLog();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.paginator) {
        this.paginator.page.subscribe(() => {
          this.getStatusTrackLog();
        });
      }
    }, 200);
  }

  getStatusTrackLog() {
    const user = "ALL";
    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;
    const id = this.configData.statusTrackId;
    this.statusTrackService.getStatusTrackLogListForStatusTrackId(id, first, pageSize).subscribe((response: any) => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.dataSource.data = response.data;
        this.totalRowCount = response.totalRowCount;
      }
    })
  }

  close() {
    const retObj = {
      confirm: false
    }
    this.dialogRef.close(retObj);
  }

}
