import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UserProfileService } from '@app/user/user-profile.service';
import {
  BaseComponent,
  FennecSnackbarService,
  Logger,
  UserGroupPacket,
  UserGroupQueryPacket,
  UserProfilePacket
} from "xf-common";
import {UserGroupService} from "@app/admin/user-group.service";

@Component({
  selector: 'app-admin-user-profile-edit',
  templateUrl: './admin-user-profile-edit.component.html',
  styleUrls: ['./admin-user-profile-edit.component.scss']
})
export class AdminUserProfileEditComponent extends BaseComponent implements AfterViewInit, OnInit {

  log: Logger = new Logger("AdminUserProfileEditComponent");

  @Input()
  userProfile: UserProfilePacket | null = null;

  formGroup: FormGroup = new FormGroup({});

  userMICaseVisibilityLevels: string [] = [];
  userGroupOptions: UserGroupQueryPacket[] = [];

  constructor(
    protected snack: FennecSnackbarService,
    private userProfileService: UserProfileService,
    private userGroupService: UserGroupService
  ) {
    super();
  }
  ngAfterViewInit(): void {
    this.performXFRequest({
      requestDescription: "Get user groups",
      requestFn: this.userGroupService._list,
      fnParams: [0, 100],
      onSuccess: data => this.userGroupOptions = data,
      onError: err => super.showErrorSnack(err)
    });

    this.userProfileService.getUserMICaseVisibilityLevels().subscribe((response: any) => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.userMICaseVisibilityLevels = response.data;
      }
    });
  }

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      firstName: new FormControl(this.userProfile?.firstName ?? ""),
      lastName: new FormControl(this.userProfile?.lastName ?? ""),
      userMICaseVisibilityLevel: new FormControl(this.userProfile?.userMICaseVisibilityLevel),
      userGroup: new FormControl(this.userProfile?.userGroupId ?? -1)
    });
  }

  saveUserProfile() {
    const formValue = this.formGroup.value;
    const userProfile = new UserProfilePacket().deserialize({
      id: this.userProfile?.id,
      userId: this.userProfile?.userId,
      firstName: formValue.firstName,
      lastName: formValue.lastName,
      userMICaseVisibilityLevel: formValue.userMICaseVisibilityLevel,
      addressId: this.userProfile?.addressId,
      userGroupId: formValue?.userGroup
    });

    this.performXFRequest({
      requestDescription: "Update user profile",
      requestFn: this.userProfileService._update,
      fnParams: [userProfile],
      onResponse: response => {
        super.showSuccessSnack("Save Successful");
      },
      onError: message => super.showErrorSnack(message)
    });
  }
}
