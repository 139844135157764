import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {
  BaseCrudComponent,
  CRUDDeleteStatusChangeDialogComponent,
  FennecSnackbarService,
  Logger,
  State,
  StateService
} from 'xf-common';
import { KeycloakService } from "keycloak-angular";
import { StateDialogComponent } from '../state-dialog/state-dialog.component';


@Component({
  selector: 'app-state-admin-list',
  templateUrl: './state-admin-list.component.html',
  styleUrls: ['../../../../lib/styles/system-admin-styles.component.scss']
})
export class StateAdminListComponent extends BaseCrudComponent<State, StateService> implements AfterViewInit, OnInit {

  log: Logger = new Logger("StateAdminListComponent");
  displayedColumns = ['name', 'code', 'active', 'actions'];
  dialogRef?: MatDialogRef<any>;
  @ViewChild("page", { read: ElementRef })
  element?: ElementRef;

  constructor(
    private stateService: StateService,
    protected override snack: FennecSnackbarService,
    private renderer: Renderer2,
    public dialog: MatDialog,
    protected keycloakService: KeycloakService
  ) {
    super(stateService);
    this.canCreate = keycloakService.isUserInRole("STATE_CREATE");
    this.canUpdate = keycloakService.isUserInRole("STATE_UPDATE");
    this.canDelete = keycloakService.isUserInRole("STATE_DELETE");
    this.canDeactivate = keycloakService.isUserInRole("STATE_DELETE");
    this.canView = keycloakService.isUserInRole("STATE_VIEW");
  }

  ngOnInit() {
    this.executeListQuery();
  }

  ngAfterViewInit(): void {
    this.selection.changed.subscribe(change => this.selectedRow = change.added[0] ?? change.removed[0] ?? undefined);
    if (this.element?.nativeElement) {
      this.renderer.listen(this.element.nativeElement, "keyup", (event) => this.keyEvent(event));
    }
    if (this.paginator) {
      this.paginator.page.subscribe(() => {
        this.executeListQuery();
      })
    }
  }

  keyEvent(event: KeyboardEvent) {
    const selected = this.selection?.selected;

    switch (event.key) {
      case "c":
        this.onCreate();
        break;
      case "e":
        if (this.selectedRow) {
          this.onEdit(this.selectedRow.id);
        }
        break;
      case "d":
        this.onDelete(selected?.map(v => v.id));
        break;
    }
  }
  executeListQuery() {
    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;
    this.paginator.pageIndex = first;
    this.paginator.pageSize = pageSize;
    this.list();
  }

  onSelect(row: State | undefined) {
    if (row == null) {
      return;
    }
    row.selected = !row.selected;

    this.selection?.toggle(row);
  }

  onCreate(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      mode: "Create"
    };
    dialogConfig.autoFocus = "first-tabbable";
    dialogConfig.width = "35%";
    this.dialogRef = this.dialog.open(StateDialogComponent, dialogConfig);
    this.dialogRef.afterClosed().subscribe((value?: State) => {
      if (value) {
        this.create(value);
      }
    });
  }

  onEdit = (id?: number) => {
    if (!id) {
      return;
    }
    this.getById(id)
      .then(response => {
        if (response && response.data) {
          this.openEditDialog(response.data)
        }
      })
      .catch(error => super.showErrorSnack(error));
  }

  onDelete = (ids: number[]) => {
    if (ids.length == 1) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        mode: "delete",
        source: ids,
        label: "state"
      };
      const dialogRef = this.dialogRef = this.dialog.open(CRUDDeleteStatusChangeDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe({
        next: (value?: number[]) => {
          if (value) {
            this.performXFRequest({
              requestDescription: "Delete state",
              requestFn: this.stateService._delete,
              fnParams: [value],
              onSuccess: data => {
                super.showSuccessSnack("Successful!");
                this.list();
              },
              onError: errString => {
                super.showErrorSnack(errString);
              }
            })
          }
        }
      });
    }
  }

  openStateDeleteDialog(state: State) {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {};
    matDialogConfig.data.source = state;
    matDialogConfig.data.mode = 'delete';
    matDialogConfig.data.label = 'State';
    const dialogRef = this.dialog.open(CRUDDeleteStatusChangeDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe({
      next: (value?: number[]) => {
        if (value) {
          this.performXFRequest({
            requestDescription: "DELETE state",
            requestFn: this.stateService._delete,
            fnParams: [value],
            onSuccess: data => {
              super.showSuccessSnack("Successful!");
              this.list();
            },
            onError: errString => {
              super.showErrorSnack(errString);
            }
          })
        }
      }
    });
  }

  openRelationshipChangeDialog(state: State) {
    if (state) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        mode: "status",
        source: state,
        label: "Patient Relationship Code"
      };
      this.dialogRef = this.dialog.open(CRUDDeleteStatusChangeDialogComponent, dialogConfig);
      this.dialogRef.afterClosed().subscribe({
        next: (value?: State) => {
          if (value) {
            this.performXFRequest({
              requestDescription: "UPDATE state status",
              requestFn: this.stateService.changeStatus,
              fnParams: [value],
              onSuccess: data => {
                super.showSuccessSnack("Successful!");
                this.list();
              },
              onError: errString => {
                super.showErrorSnack(errString);
              }
            })
          }
        }
      });
    }
  }

  openEditDialog(state: State) {
    if (state) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        mode: "Edit",
        state: state
      };
      dialogConfig.autoFocus = "first-tabbable";
      dialogConfig.width = "35%";
      this.dialogRef = this.dialog.open(StateDialogComponent, dialogConfig);
      this.dialogRef.afterClosed().subscribe({
        next: (value?: State) => {
          if (value) {
            this.update(value);
          }
        }
      });
    }
  }
}
