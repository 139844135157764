<div matDialogTitle>
  Edit Primary Client
</div>

<div matDialogContent>
  <mat-table [dataSource]="companyRelationalMapList">

    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
      <mat-cell  *matCellDef="let crm"> 
        {{crm.name}}
       </mat-cell>
    </ng-container>  

    <ng-container matColumnDef="select">
      <mat-header-cell *matHeaderCellDef>  </mat-header-cell>
      <mat-cell  *matCellDef="let crm"> 
        <button
          [disabled]="crm.companyId == selectedCompanyId"
          mat-stroked-button
          (click)="selectCompany(crm.companyId)"
        >
          {{ crm.companyId == initialCompanyId ? "Current Client" : crm.companyId == selectedCompanyId ? "Selected" : "Select" }}
        </button>
       </mat-cell>
    </ng-container>  

    <ng-container matColumnDef="address">
      <mat-header-cell *matHeaderCellDef> Address </mat-header-cell>
      <mat-cell  *matCellDef="let crm"> 
        {{crm.addressString}}
       </mat-cell>
    </ng-container> 

    <mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayColumns;"
    [ngClass]="{ hovered: row.hovered, highlighted: row.companyId ? selectedCompanyId == row.companyId : selectedCompanyId == row.id }"
    ></mat-row>
  </mat-table>
</div>

<div matDialogActions class="fennec-dialog-action-button-container">
  <button mat-flat-button color="primary" (click)="cancel()">
    <mat-icon>cancel</mat-icon>
    Cancel
  </button>
  <button [disabled]="!changed" mat-flat-button color="accent" (click)="submit()">
    <mat-icon>save</mat-icon>
    Save
  </button>

  <mat-paginator [length]="totalRowCount" [pageSize]="defaultPageSize" [pageSizeOptions]="pageSizeOptions"> </mat-paginator>
</div>