import { Component, Inject, OnInit } from '@angular/core';
import { BaseComponent, DischargeStatusPacket, FennecSnackbarService, Logger } from 'xf-common';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";

@Component({
  selector: 'app-diagnosis-code-dialog',
  templateUrl: './diagnosis-code-dialog.component.html',
  styleUrls: ['./diagnosis-code-dialog.component.scss']
})
export class DiagnosisCodeDialogComponent extends BaseComponent implements OnInit {

  log: Logger = new Logger("DischargeStatusCodeDialogComponent");
  mode?: "Create" | "Edit" | "View";
  dischargeStatusCode?: DischargeStatusPacket;
  formGroup: FormGroup;

  constructor(
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<DiagnosisCodeDialogComponent>,
    override snack: FennecSnackbarService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    super();
    this.mode = this.data?.mode;
    this.dischargeStatusCode = this.data?.dischargeStatus;
    this.formGroup = new FormGroup({
      code: new FormControl(this.dischargeStatusCode?.code ?? "", Validators.required),
      description: new FormControl(this.dischargeStatusCode?.description ?? "", [Validators.required, Validators.maxLength(255)]),
    });
  }

  ngOnInit(): void {
  }

  submit() {
    const dischargeStatusPacket: DischargeStatusPacket = this.dischargeStatusCode ?? new DischargeStatusPacket();
    const formValue = this.formGroup.value;
    if (!formValue.code) {
      super.showErrorSnack("Code is required");
      return;
    }
    if (!formValue.description) {
      super.showErrorSnack("Description required");
      return;
    }
    dischargeStatusPacket.code = formValue["code"];
    dischargeStatusPacket.description = formValue["description"];
    this.dialogRef.close(dischargeStatusPacket);
  }

  cancel() {
    this.dialogRef.close();
  }

}
