import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { KeycloakService } from 'keycloak-angular';
import { SystemConfigurationService } from './system-configuration.service';
import { BaseComponent, FennecSnackbarService, Logger, SystemConfigurationPacket } from "xf-common";


@Component({
  selector: 'app-system-configuration',
  templateUrl: './system-configuration.component.html',
  styleUrls: ['./system-configuration.component.scss']
})
export class SystemConfigurationComponent extends BaseComponent implements OnInit, AfterViewInit {

  protected log: Logger = new Logger("SystemConfigurationComponent");

  // MODES: VIEW, EDIT
  @Input()
  mode: string = "VIEW";

  formGroup: FormGroup;

  constructor(
    override snack: FennecSnackbarService,
    protected dialog: MatDialog,
    protected keycloakService: KeycloakService,
    protected systemConfigurationService: SystemConfigurationService,
  ) {
    super();

    const roles = keycloakService.getUserRoles();
    if(roles.includes("ADMIN") || roles.includes("GLOBAL_SYSTEM_ADMIN")) {
      this.mode = "EDIT";
    }

    this.formGroup = this.createFormGroup();
  }
  ngAfterViewInit(): void {
    this.getSystemConfiguration();
  }

  ngOnInit(): void {
  }

  getSystemConfiguration = () => {
    this.performXFRequest({
      requestDescription: "GET System Configuration",
      requestFn: this.systemConfigurationService.getSystemConfiguration,
      fnParams: [],
      onSuccess: (data) => {
        this.formGroup = this.createFormGroup(data);
      },
      onComplete: () => {
        this.isLoading = false;
      },
      onError: (error) => {
        super.showErrorSnack(error);
      }
    })
  }

  createFormGroup = (data: SystemConfigurationPacket | null = null) => {
    const formGroup = new FormGroup({
      tennantName: new FormControl(data?.tennantName ?? "")
    });

    if(this.mode === "VIEW") {
      formGroup.controls['tennantName'].disable();
    }

    return formGroup;
  }

  saveSystemConfiguration = () => {
    const packet = new SystemConfigurationPacket();
    packet.tennantName = this.formGroup.controls['tennantName'].value;
    packet.traceDebugOutputDirectory = "./"

    this.performXFRequest({
      requestDescription: "UPDATE System Configuration",
      requestFn: this.systemConfigurationService.updateSystemConfiguration,
      fnParams: [packet],
      onSuccess: (data) => {
        super.showSuccessSnack("Save Complete");
        this.getSystemConfiguration();
      },
      onComplete: () => {
        this.isLoading = false;
      },
      onError: (error) => {
        super.showErrorSnack(error);
      }
    });
  }

}
