import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ConditionCodeService } from '../condition-code.service';
import { ConditionCode, FennecSnackbarService } from "xf-common";

@Component({
  selector: 'app-condition-code-create-form',
  templateUrl: './condition-code-create-form.component.html',
  styleUrls: ['./condition-code-create-form.component.scss']
})
export class ConditionCodeCreateFormComponent implements OnInit {
  formGroup: FormGroup;
  code: ConditionCode = new ConditionCode();
  constructor(
    public dialogRef: MatDialogRef<ConditionCodeCreateFormComponent>,
    private snack: FennecSnackbarService,
    private codeService: ConditionCodeService
  ) {
    this.formGroup = new FormGroup({
      code: new FormControl("", [Validators.required, Validators.maxLength(4)]),
      description: new FormControl("", [Validators.required, Validators.maxLength(1000)])
    })
   }

  ngOnInit(): void {
  }

  onCancel() {
    this.dialogRef.close();
  }

  onAddCode() {
    this.formGroup.markAllAsTouched();
      this.code.code = this.formGroup.controls['code'].value;
      this.code.description =this.formGroup.controls['description'].value;
      this.codeService.addCode(this.code).subscribe(response => {
        if (response.hasErrors) {
          this.snack.showErrorSnack(response.consolidatedErrorMessage);
        } else {
          this.dialogRef.close(true);
          this.snack.showSuccessSnack(`${response.data.code}(${response.data.description}) Created`);
        }
      })
  }

}
