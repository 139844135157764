export class CaseCreate {
  type: string = "";
  name?: string;
  altExternalId?: string;
  primaryClientCompanyId?: number = -1;
  lineOfBusiness?: any;
  customerPriority?: any;
  preReviewPayable?: number;
  postReviewPayable?: number;
  drgPaidDate?: string;
  drgPaymentAmount?: number;
  networkDiscount?: number;
  clientClaimId?: string;
  notes?: string;
  providerPar?: string;
  postPaymentReview?: any;
  clientMemberNumber?: string;
  healthCarePlanId?: number;
  insuredGroupName?: string;
  insuredGroupNo?: string;
  clientInternalAdjustments?:number;
}
