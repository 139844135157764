import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ApeRuleFilterHealthCarePlanService } from '../ape-rule-filter-health-care-plan-service.service';
import {
  AddApeRuleFilterHealthCarePlanDialogComponent
} from '../add-ape-rule-filter-health-care-plan-dialog/add-ape-rule-filter-health-care-plan-dialog.component';
import {
  APERuleFilter,
  APERuleFilterHealthCarePlanPacket,
  BaseCrudComponent,
  CompanyService,
  FennecSnackbarService,
  Logger,
  SingleChoiceDialogComponent
} from "xf-common";

@Component({
  selector: 'app-ape-rule-filter-health-care-plan-list',
  templateUrl: './ape-rule-filter-health-care-plan-list.component.html',
  styleUrls: ['./ape-rule-filter-health-care-plan-list.component.scss']
})
export class ApeRuleFilterHealthCarePlanDialogComponent extends BaseCrudComponent<APERuleFilterHealthCarePlanPacket, ApeRuleFilterHealthCarePlanService> implements OnInit, AfterViewInit {
  override onEdit = (id: number | undefined) => {};
  override onDelete = (ids: any[]) => {};

  protected log = new Logger("ApeRuleFilterHealthCarePlanDialogComponent");

  apeRuleFilter: APERuleFilter;
  companyList: APERuleFilterHealthCarePlanPacket[] = [];

  mode: "VIEW" | "SEARCH" = "VIEW";
  displayedColumns: string[] = ["companyName", "planName", "actions"];

  @ViewChild('addComponent')
  addComponent?: AddApeRuleFilterHealthCarePlanDialogComponent;

  constructor(
    protected dialog: MatDialog,
    protected companyService: CompanyService,
    protected apeRuleFilterHealthCarePlanService: ApeRuleFilterHealthCarePlanService,
    protected dialogRef: MatDialogRef<ApeRuleFilterHealthCarePlanDialogComponent>,
    override snack: FennecSnackbarService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    super(apeRuleFilterHealthCarePlanService);
    this.apeRuleFilter = data.apeRuleFilter;

  }
  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
    this.getHealthCarePlanList();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  getHealthCarePlanList = () => {
    this.isLoading = true;

    const id = this.apeRuleFilter?.id;
    if (!id) {
      this.snack.showErrorSnack("APE Rule Filter ID Not Provided");
      return;
    }

    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;

    this.performXFRequest({
      requestDescription: "GET Health Care Plan List",
      requestFn: this.apeRuleFilterHealthCarePlanService.getAPERuleFilterHealthCarePlanListForAPERuleFilterId,
      fnParams: [id, first, pageSize],
      onSuccess: data => {
        this.companyList = data;
      },
      onError: errString => {
        super.showErrorSnack(errString);
      },
      onResponse: response => {
        this.totalRowCount = response['totalRowCount'];
        this.isLoading = false;
      }
    });
  }

  setModeToSearch = () => {
    this.mode = "SEARCH";
  }

  setModeToView = () => {
    this.mode = "VIEW";
    this.getHealthCarePlanList();
  }

  deletePrompt = (packet: APERuleFilterHealthCarePlanPacket): void  => {

    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      question : "Remove Primary Client",
      infoLine1 : `Remove ${packet.healthCarePlan?.name ?? "Primary Client"} from ${packet.apeRuleFilter?.name ?? "Ape Rule Filter"}?`
    };
    const dialogRef = this.dialog.open(SingleChoiceDialogComponent, matDialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {
        if (!packet.id) {
          super.showErrorSnack("Cannot delete APE Rule Filter Primary Client.  No ID Provided");
          return;
        }

        this.performXFRequest({
          requestDescription: "DELETE Ape Rule Filter Primary Client",
          requestFn: this.apeRuleFilterHealthCarePlanService.deleteAPERuleFilterHealthCarePlan,
          fnParams: [packet.id],
          onSuccess: data => {
            super.showSuccessSnack("Delete Successful");
            this.getHealthCarePlanList();
          },
          onError: errString => {
            super.showErrorSnack(errString);
          }
        });
      }
    });
  }
}
