import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseCrudService, Crud, DataResponse, PagedResponse, ProcedureCodePacket } from "xf-common";


@Injectable()
export class ProcedureCodeService extends BaseCrudService<ProcedureCodePacket> {
  baseEndpoint = super.apiEndpoint("procedure-code")

  constructor(public override httpClient: HttpClient) {
    super(httpClient, "ProcedureCodeService", "ProcedureCode");
  }

  _list: Crud.ListFunction<ProcedureCodePacket> = (first, max) => {
    const endpoint = `${this.baseEndpoint}/list`
    return this.httpClient.get<PagedResponse<ProcedureCodePacket[]>>(endpoint, { params: { first, max } })
  }

  _getById: Crud.GetByIdFunction<ProcedureCodePacket> = (id) => {
    const endpoint = `${this.baseEndpoint}/${id}`
    return this.httpClient.get<PagedResponse<ProcedureCodePacket>>(endpoint)
  }

  _create: Crud.CreateFunction<ProcedureCodePacket> = (user) => {
    const endpoint = `${this.baseEndpoint}/create`
    return this.httpClient.post<PagedResponse<ProcedureCodePacket>>(endpoint, user)
  }

  _update: Crud.UpdateFunction<ProcedureCodePacket> = (user): Observable<DataResponse<ProcedureCodePacket>> => {
    const endpoint = `${this.baseEndpoint}/update`
    return this.httpClient.put<PagedResponse<ProcedureCodePacket>>(endpoint, user)
  }

  _deactivate: Crud.DeleteFunction<ProcedureCodePacket> = (ids) => {
    const endpoint = `${this.baseEndpoint}/deactivate`
    return this.httpClient.put<DataResponse<boolean>>(endpoint, ids[0])
  }

  _delete: Crud.DeleteFunction<ProcedureCodePacket> = (ids) => {
    const endpoint = `${this.baseEndpoint}/delete/` + ids
    return this.httpClient.delete<DataResponse<boolean>>(endpoint)

  }

  protected _crudFunctions: Crud.CrudServiceDef<ProcedureCodePacket> = {
    _list: this._list,
    _getById: this._getById,
    _create: this._create,
    _update: this._update,
    _deactivate: this._deactivate,
    _delete: this._delete
  }

  changeStatus = (procedureCodePacket: ProcedureCodePacket): Observable<DataResponse<ProcedureCodePacket>> => {
    const endpoint = `${this.baseEndpoint}/status`;
    return this.httpClient.put<DataResponse<ProcedureCodePacket>>(endpoint, procedureCodePacket);
  }

  getProcedureCodesByParams = (packet: any, first: number, max: number) => {
    const endpoint = `${this.baseEndpoint}/list?first=${first}&max=${max}`;
    return this.httpClient.post<PagedResponse<ProcedureCodePacket>>(endpoint, packet);
  }
}
