
export interface JSLExecuteJobCPQUpdate1 {
  seqNo: number;
  paramValue: string;
}

export class JSLExecuteJobCPQUpdate {
  jslJobDefinitionId?: number;
  paramValues?: JSLExecuteJobCPQUpdate1[];

}
