<div class="base-component">
  <form *ngIf="company" class="container" [formGroup]="formGroup">
    <mat-card class="child-container">
      <mat-card-header>
        <mat-card-title>
          General Company Information
        </mat-card-title>
      </mat-card-header>
      <div class="mat-card-divider"></div>
      <mat-card-content class="form-div">
        <mat-form-field appearance="outline" color="accent">
          <mat-label>Name</mat-label>
          <input matInput formControlName="name">
        </mat-form-field>
      </mat-card-content>
    </mat-card>
    <app-address class="child-container" #addressComponent [mode]="'EDIT'" [existingAddressId]="company?.addressId"></app-address>
  </form>
  <div class="fennec-controls">
    <button mat-stroked-button color="accent" routerLink="/companies" [disabled]="isLoading">
      Cancel
    </button>
    <button mat-flat-button color="accent" (click)="onSubmit()" [disabled]="isLoading">
      Save
    </button>
  </div>
</div>
