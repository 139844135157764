import { animate, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { KeycloakService } from 'keycloak-angular';
import {
  APERule,
  APERuleAPERuleFilter,
  APERuleFilter,
  BaseCrudComponent, FennecSnackbarService, Logger,
  SingleChoiceDialogComponent
} from 'xf-common';
import { ApeRuleApeRuleFilterService } from '../ape-rule-ape-rule-filter.service';


@Component({
  selector: 'app-ape-rule-ape-rule-filter-list',
  templateUrl: './ape-rule-ape-rule-filter-list.component.html',
  styleUrls: ['./ape-rule-ape-rule-filter-list.component.scss', '../../../ape-rule-admin-styles.scss', '../../../../../../lib/styles/system-admin-styles.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({opacity:0}),
        animate(500, style({opacity:1}))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(500, style({opacity:0}))
      ])
    ])
  ]
})
export class ApeRuleApeRuleFilterListComponent extends BaseCrudComponent<APERuleAPERuleFilter, ApeRuleApeRuleFilterService> implements OnInit, AfterViewInit  {

  displayedColumns: string[] = ["simple_reorder", "seqNo", "filterName", "filterDescription", "filterAssertion", "actions"];
  protected log: Logger = new Logger("ApeRuleApeRuleFilterListComponent");

  apeRule?: APERule;
  selectedApeRuleApeRuleFilter: APERuleAPERuleFilter | null = null;

  selectedApeRuleFilter: APERuleFilter | null = null;

  displayMode: string = "ADMIN";
  previousDisplayMode: string = "";

  displayModes: string[] = ["ADMIN", "NEW", "EDIT", "SELECT"]
  formGroup: FormGroup | null = null;
  filterAssertions: string[] = [];

  constructor(
    protected apeRuleApeRuleFilterService: ApeRuleApeRuleFilterService,
    public override snack: FennecSnackbarService,
    protected dialog: MatDialog,
    private renderer: Renderer2,
    protected keycloakService: KeycloakService,
    protected dialogRef: MatDialogRef<ApeRuleApeRuleFilterListComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
    ) {
      super(apeRuleApeRuleFilterService);

      if(this.data.apeRule) {
        this.apeRule = this.data.apeRule;
      }
    }

  ngAfterViewInit(): void {
    if (this.paginator) {
      this.paginator.page.subscribe(() => {
        this.executeListQuery();
      })
    }
  }

  ngOnInit(): void {
    this.executeListQuery();
    this.populateFilterAssertions();
  }

  onEdit = (id: number | undefined) => {};
  onDelete = (ids: any[]) => {};

  setDisplayMode = (mode: string) => {
    if(mode === "") {
      this.previousDisplayMode = this.displayMode;
      this.displayMode = "ADMIN";
    }

    if(this.displayModes.includes(mode)) {
      if(mode === "EDIT" || mode === "NEW") {
        this.createFormGroup();
      }

      this.previousDisplayMode = this.displayMode;
      this.displayMode = mode;
    }
  }

  backButton = () => {
    if(this.displayMode === "SELECT") {
      this.setDisplayMode(this.previousDisplayMode);
    }else{
      this.setDisplayMode('ADMIN');
    }
  }

  startEdit = (apeRuleApeRuleFilter: APERuleAPERuleFilter) => {
    this.selectedApeRuleApeRuleFilter = apeRuleApeRuleFilter;

    if(apeRuleApeRuleFilter.apeRuleFilter) {
      this.selectedApeRuleFilter = apeRuleApeRuleFilter.apeRuleFilter;
    }

    this.setDisplayMode("EDIT");
  }

  startAddNew = () => {
    this.selectedApeRuleApeRuleFilter = null;
    this.selectedApeRuleFilter = null;
    this.setDisplayMode("NEW");
  }

  selectFilter = (apeRuleFilter: APERuleFilter) => {
    this.selectedApeRuleFilter = apeRuleFilter;
    this.setDisplayMode(this.previousDisplayMode);
  }

  closeDialog = () => {
    this.dialogRef.close();
  }

  executeListQuery = () => {
    if(!this.apeRule || !this.apeRule.id) {
      return;
    }

    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;
    this.paginator.pageIndex = first;
    this.paginator.pageSize = pageSize;
    this.performXFRequest({
      requestDescription: "GET APE Rule APE Rule Filters for APE Rule ID",
      requestFn: this.apeRuleApeRuleFilterService.list,
      fnParams: [this.apeRule?.id, first, pageSize],
      onResponse: response => {
        this.dataSource = response.data;
        this.totalRowCount = response['totalRowCount'];
      },
      onError: errString => {
        super.showErrorSnack(errString)
      }
    })
  }

  createFormGroup = () => {
    this.formGroup = new FormGroup({
      apeRuleFilterAssertion: new FormControl(this.selectedApeRuleApeRuleFilter?.apeRuleFilterAssertion ? this.selectedApeRuleApeRuleFilter.apeRuleFilterAssertion : this.filterAssertions[0]),
      seqNo: new FormControl(this.selectedApeRuleApeRuleFilter?.seqNo ? this.selectedApeRuleApeRuleFilter.seqNo : this.totalRowCount)
    })
  }

  populateFilterAssertions = () => {
    this.performXFRequest({
      requestDescription: "GET Filter Assertions",
      requestFn: this.apeRuleApeRuleFilterService.assertionList,
      fnParams: [],
      onResponse: response => {
        this.filterAssertions = response.data;
      },
      onError: errString => {
        super.showErrorSnack(errString)
      }
    })
  }

  saveApeRuleApeRuleFilter = () => {
    const packet = new APERuleAPERuleFilter();

    if(!this.apeRule || !this.selectedApeRuleFilter) {
      console.log("Selected APE Rule Filter is Null");
      return;
    }

    if(this.formGroup == null) {
      console.log('Form Group is Null');
      return;
    }

    if(this.displayMode === "EDIT" && this.selectedApeRuleApeRuleFilter?.id) {
      packet.id = this.selectedApeRuleApeRuleFilter.id;
    }else{
      packet.id = -1;
    }

    packet.apeRule = this.apeRule;
    packet.apeRuleFilter = this.selectedApeRuleFilter;
    packet.apeRuleFilterAssertion = this.formGroup.controls['apeRuleFilterAssertion'].value
    packet.seqNo = this.formGroup.controls['seqNo'].value;

    this.performXFRequest({
      requestDescription: "SAVE APE Rule APE Rule Filter to Server",
      requestFn: this.apeRuleApeRuleFilterService._create,
      fnParams: [packet],
      onResponse: response => {
        super.showInfoSnack("Save Successful");
        this.executeListQuery();
        this.setDisplayMode('ADMIN');
      },
      onError: errString => {
        super.showErrorSnack(errString)
      }
    })
  }

  deletePrompt = (apeRuleApeRuleFilter: APERuleAPERuleFilter) => {

    const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        question:`Are you sure you want to delete APE Rule APE Rule Filter ID:${apeRuleApeRuleFilter.id}?`
      };
      const dialogRef = this.dialog.open(SingleChoiceDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe((response) => {
        if(response?.confirm) {
          if(apeRuleApeRuleFilter.id === undefined){
            return;
          }

          this.apeRuleApeRuleFilterService._delete([apeRuleApeRuleFilter.id]).subscribe(result => {
            if(result.hasErrors) {
              super.showErrorSnack("Error processing delete request");
            }else{
              super.showSuccessSnack("Delete successful!");
              this.executeListQuery();
            }
          })
        }
      })

  }

  moveFilterOnePlace(filter:APERuleAPERuleFilter, down:boolean=false) {
    let seqNo = filter.seqNo ? filter.seqNo : 1;
    if(down){
      seqNo++;
    }else{
      seqNo--;
    }

    const dto = {
      movingEntityId: filter.id,
      targetSeqNo: seqNo,
      mode: "SWAP"
    }

    this.performXFRequest({
      requestDescription: "POST Reorder APE Rule APE Rule Filter",
      requestFn: this.apeRuleApeRuleFilterService.reorder,
      fnParams: [dto],
      onResponse: response => {
        // this.dataSource = response.data;
        // this.totalRowCount = response['totalRowCount'];
        super.showSuccessSnack(`${filter?.apeRuleFilter?.name} moved to Sequence #${seqNo}`);
        this.executeListQuery();
      },
      onError: errString => {
        super.showErrorSnack(errString)
      }
    })
  }
}
