<div class="user-profile-div-wrapper">
  <form [formGroup]="formGroup">
    <h1 class="form-title">Edit User Profile</h1>

    <div style="display: flex; flex-direction:column; margin: 2%">

      <mat-form-field 
        appearance="outline"
        color="accent"
        >
        <mat-label>
          First Name
        </mat-label>
        <input 
          matInput 
          id="firstName"
          formControlName="firstName">
      </mat-form-field>

      <mat-form-field 
        appearance="outline"
        color="accent">
        <mat-label>
          Last Name
        </mat-label>
        <input 
          matInput 
          id="lastName"
          formControlName="lastName">
      </mat-form-field>

    </div>

    <app-address #addressComponent></app-address>

    <div class="action-buttons">
      <button 
        mat-raised-button 
        color="accent" 
        (click)="onSave()">
        <mat-icon>save</mat-icon> Save
      </button>
    </div>
  </form>
</div>