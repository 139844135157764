import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { JslWorkbenchComponent } from "./jsl-workbench/jsl-workbench.component";
import { JslService } from "./jsl.service";
import { CommonViewModule, MaterialModule } from 'xf-common';
import { JSLJobDefinitionDialogComponent } from "./jsl-workbench/jsl-job-definition-dialog/jsl-job-definition-dialog.component";
import { JSLJobDefinitionParamDialogComponent } from "./jsl-workbench/jsl-job-definition-param-dialog/jsl-job-definition-param-dialog.component";
import { JSLExecuteJobDialogComponent } from "./jsl-workbench/jsl-execute-job-dialog/jsl-execute-job-dialog.component";
import { JSLJobInstanceErrorDialogComponent } from "./jsl-workbench/jsl-job-instance-error-dialog/jsl-job-instance-error-dialog.component";
import { JSLInstanceParamViewDialogComponent } from "./jsl-workbench/jsl-instance-param-view-dialog/jsl-instance-param-view-dialog.component";

@NgModule({
    declarations: [
        JslWorkbenchComponent,
        JSLJobDefinitionDialogComponent,
        JSLJobDefinitionParamDialogComponent,
        JSLJobInstanceErrorDialogComponent,
        JSLExecuteJobDialogComponent,
        JSLInstanceParamViewDialogComponent
    ],
    providers: [JslService],
    exports: [JslWorkbenchComponent],
    imports: [CommonModule, MaterialModule, FormsModule, ReactiveFormsModule, CommonViewModule]
})
export class JslModule {}
