
<div matDialogTitle class="hold-dialog-title">
  Case Hold Info
</div>

<div matDialogContent class="hold-dialog-content" [style.height.px]="miCaseInfo?.onHold ? 140 : 260">
  <span class="global-data-element-label-span">Status:</span>
  <span class="global-data-element-value-span">{{ miCaseInfo?.onHold ? "On Hold" : "Off Hold" }} </span>
  <ng-container *ngIf="!miCaseInfo?.onHold">
    <span class="global-data-element-label-span">Case Review Date:</span>
    <span *ngIf="caseReviewDueDate !== null && caseReviewDueDate !== undefined && caseReviewDueDate !== ''" 
      class="global-data-element-value-span">{{caseReviewDueDate | date: 'MM/dd/yyyy' }}</span>
    <span *ngIf="(caseReviewDueDate === null || caseReviewDueDate === undefined || caseReviewDueDate === '')" 
      class="global-data-element-value-span">Not Specified</span>
  </ng-container>
  <ng-container *ngIf="miCaseInfo?.onHold">
    <br>
    <span class="global-data-element-label-span">Reason:</span>
    <span class="global-data-element-value-span">{{miCaseInfo?.holdReasonUserLabel || "Unspecified"}} </span>
    <span class="global-data-element-label-span">Due Date:</span>
    <span class="global-data-element-value-span">{{dueDate | date: 'MM/dd/yyyy' }}</span>
  </ng-container>     

  <ng-container *ngIf="miCaseInfo?.onHold">
    <form [formGroup]="formGroup2">
      <div style="width: 100%; margin-top: 20px; text-align: center;">
        When taking the case OFF HOLD, create
        <span class="global-data-element-value-span">{{latestNonHoldXFWFTask?.taskTypeLabel}}</span>
        workflow task 
        <span *ngIf="latestNonHoldXFWFTask?.taskAssignedTo === null" class="global-data-element-value-span">UNASSIGNED?</span>
        <ng-container *ngIf="latestNonHoldXFWFTask?.taskAssignedTo !== null">
          and assign it to:
          <span class="global-data-element-value-span">{{latestNonHoldXFWFTask?.firstName}} {{latestNonHoldXFWFTask?.lastName}}?</span>
        </ng-container> 
        <mat-checkbox style="margin-left: 8px;" [formControlName]="'createXFWFTask'"></mat-checkbox>
      </div>
    </form>
  </ng-container>

  <form
    id="hold-dialog-form"
    *ngIf="miCaseInfo?.onHold === false"
    [formGroup]="formGroup"
    class="alt-theme"
    (keydown.enter)="submitCaseHold()"
  >

    <mat-form-field appearance="outline">
      <mat-label>Hold Reason</mat-label>
      <mat-select matInput formControlName="holdReason">
        <mat-option [value]="''">Unspecified</mat-option>
        <mat-option *ngFor="let reason of holdReasons" [value]="reason?.value">{{reason?.label}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" matTooltipPosition="above" matTooltipClass="hold-tooltip" matTooltipShowDelay="500">
      <mat-label>**Hold Review Date (MM/DD/YYYY)</mat-label>
      <input class="input" matInput formControlName="holdReviewDate" placeholder="MM/DD/YYYY" [min]="minHoldReviewDate">
      <mat-error *ngIf="holdReviewDateControl?.hasError('dateTooEarly')">
        The review date must be in the present or future
      </mat-error>
      <mat-error *ngIf="holdReviewDateControl?.hasError('incomplete')">
        Please enter a date in MM/dd/yyyy format
      </mat-error>
    </mat-form-field>
  </form>
  <div style="width: 100%; text-align: center; font-size: small;" *ngIf="miCaseInfo?.onHold === false">
    ** Note: Hold Review Date defaults to two weeks from today if left blank.
  </div>
</div>

<div matDialogActions class="hold-dialog-actions">
  <button
    id="submit-case-hold"
    type="button"
    mat-button
    color="accent"
    (click)="submitCaseHoldConfirm()"
    [class.case-on-hold]="miCaseInfo?.onHold"
  >
    {{ miCaseInfo?.onHold ? "Take off hold" : "Put on hold" }}
  </button>

  <button
  mat-stroked-button
  (click)="close()"
>
  <mat-icon>cancel</mat-icon>
  Cancel
</button>
</div>
