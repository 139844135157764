import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';

@Component({
  selector: 'app-system-admin',
  templateUrl: './system-admin.component.html',
  styleUrls: ['./system-admin.component.scss']
})

export class SystemAdminComponent implements OnInit, AfterViewInit {

  @ViewChild("page", { read: ElementRef })
  element?: ElementRef;

  options: any[] = [
    {text:"Patient Relationship Codes", icon: "people"},
    {text:"State Codes", icon:"list"},
    {text:"Condition Codes", icon:"reorder"},
    {text:"Discharge Status Codes", icon:"list_alt"},
    {text:"Revenue Codes", icon:"paid"},
    {text:"Diagnosis Codes", icon:"segment"},
    {text:"Value Codes", icon:"filter_list"},
    {text:"Admission Sources", icon:"clear_all"},
    {text:"HCPCS Codes", icon:"list"},
    {text:"Taxonomy Codes", icon:"list_alt"},
    {text:"Procedure Codes", icon:"list"},
    {text:"Adjustment Reason Codes", icon:"list"},
    {text:"Adjustment Explanation Codes", icon:"list"},
    {text:"Occurrence Codes", icon:"list_alt"},
    {text:'DRG Codes', icon: "format_list_bulleted_icon"},
    {text:"Holidays", icon: "flight"},
    {text:"User Groups", icon: "group"}
  ];
  selected: number = 0;

  // Put the side menu bar in the 'closed' position initially. See ngAfterViewInit().
  navigationOpen: boolean = false;

  constructor(
    private renderer: Renderer2,
  ) {

  }
  ngAfterViewInit(): void {
    if (this.element?.nativeElement) {
      this.renderer.listen(this.element.nativeElement, "keyup", (event) => this.keyEvent(event));
    }

    // I'm putting a little delay here to allow the first admin CRUD screen (right panel) to fully render
    // before opening the side menu bar. If this is not done, the left side menu tends to bleed into the
    // right panel CRUD screen because it probably does not have enough information yet to render properly.
    setTimeout(() =>
    {this.toggle()}, 200);
  }

  ngOnInit() {
    // Sorting the Mat-Nav-Content in the side bar
    this.options = this.options.sort((current,next)=>{
      return current.text.localeCompare(next.text)
    });
  }

  toggle() {
    this.navigationOpen = !this.navigationOpen;
  }

  changeSelected(index:any){
    this.selected = index;
  }

  keyEvent(event: KeyboardEvent) {
    console.log("Key Event!");
    switch (event.key) {
      case "n":
        this.toggle();
        break;
    }
  }

}
