import { AfterViewInit, Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { BaseComponent } from '../../view/base.component';
import { FennecSnackbarService } from 'projects/xf-common/src/public-api';
import { Logger } from '../../util/logger';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ContactServiceService } from '../contact-service.service';

@Component({
  selector: 'lib-contact-dialog',
  templateUrl: './contact-dialog.component.html',
  styleUrl: './contact-dialog.component.scss'
})
export class ContactDialogComponent extends BaseComponent implements AfterViewInit {
  protected override log: Logger= new Logger("ContactDialogComponent");
  
  // Context = contactRelationalType
  context;
  relatedId;

  formGroup:FormGroup;

  existingContactRelationalMapId = -1;
  existingContactId = -1;

  loading = false;
  typeOptions = [];

  constructor(
    protected override snack: FennecSnackbarService,
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<ContactDialogComponent>,
    protected contactService: ContactServiceService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    super();
    this.context = this.data.context;
    this.relatedId = this.data.relatedId;

    let contact = null;
    if (this.data.contactRelationalMap) {
      this.existingContactRelationalMapId = this.data.contactRelationalMap?.id ?? -1;
      this.existingContactId = this.data.contactRelationalMap?.contact?.id ?? -1;

      contact = this.data.contactRelationalMap?.contact;
    }

    this.formGroup = this.createFormGroup(contact);
  }

  ngAfterViewInit(): void {
    this.getContactTypes();
  }

  createFormGroup = (contact) => {
    return new FormGroup({
      name: new FormControl(contact?.name ?? "", Validators.required),
      phoneNumber: new FormControl(contact?.phoneNumber ?? ""),
      faxNumber: new FormControl(contact?.faxNumber ?? ""),
      emailAddress: new FormControl(contact?.emailAddress ?? ""),
      contactType: new FormControl(contact?.contactType ?? "", Validators.required)
    })
  }

  save = () => {
    const controls = this.formGroup.controls;
    const packet = {
      id: this.existingContactRelationalMapId,
      contact: {
        id: this.existingContactId,
        name: controls["name"].value ?? "",
        phoneNumber: controls["phoneNumber"].value ?? "", 
        faxNumber: controls["faxNumber"].value ?? "",
        emailAddress: controls["emailAddress"].value ?? "",
        contactType: controls["contactType"].value ?? ""
      },
      relatedId: this.relatedId,
      contactRelationalType: this.context
    }

    this.performXFRequest({
      requestDescription: "Save Contact and Relational Map",
      requestFn: this.contactService.createUpdateContactWithMap,
      fnParams: [packet],
      onSuccess: data => {
        this.cancel(true);
      },
      onError: errString => {
        super.showErrorSnack(errString);
      }
    })
  }

  cancel = (confirm = false) => {
    this.dialogRef.close({confirm});
  }

  getContactTypes = () => {
    this.loading = true;

    this.performXFRequest({
      requestDescription: "Get Contact Types",
      requestFn: this.contactService.getContactTypes,
      fnParams: [],
      onSuccess: data => {
        console.log(data);
        this.typeOptions = data;
        // this.cancel(true);
      },
      onResponse: response => {
        this.loading = false;
      },
      onError: errString => {
        super.showErrorSnack(errString);
        this.loading = false;
      }
    })
  }
}
