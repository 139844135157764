<mat-dialog-content>
    <h1 mat-dialog-title>Are You Sure You Want To {{ buttonValue ? buttonValue : 'Delete'}} This Code?</h1>
</mat-dialog-content>

<mat-dialog-actions class="fennec-controls">
    <div>   
        <button (click)="onDelete()" mat-button>
            <mat-icon>delete</mat-icon> 
            {{mode === 'delete' ? 'Delete' : buttonValue }}
        </button>
        

        <button (click)="onCancel()" mat-button>
            <mat-icon>cancel</mat-icon> Cancel
        </button>
    </div>
</mat-dialog-actions>
