<div matDialogTitle>
  {{ this.mode }} Revenue Code
</div>

<div matDialogContent class="alt-theme">
  <form [formGroup]="formGroup" class="container">
    <mat-form-field appearance="outline">
      <mat-label>Code</mat-label>
      <input matInput class="id" id="code" formControlName="code" required/>
      <mat-hint>{{formGroup.controls['code'].value.length}} of 4</mat-hint>
    </mat-form-field>
    <mat-form-field appearance="outline" [style.width.px]=450>
      <mat-label>Description</mat-label>
      <textarea matInput cdkTextareaAutosize rows="5" class="description" id="description" formControlName="description" required maxlength="500"></textarea>
      <mat-hint>{{formGroup.controls['description'].value.length}} of 500</mat-hint>
    </mat-form-field>
  </form>
</div>

<div matDialogActions class="fennec-dialog-action-button-container">
  <button mat-flat-button color="primary" (click)="cancel()">
    Cancel
  </button>
  <button [disabled]="formGroup.invalid" mat-flat-button color="accent" (click)="submit()">
    Save
  </button>
</div>

