import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { BaseComponent, FennecSnackbarService, Logger, State } from 'xf-common';


@Component({
  selector: 'app-state-dialog',
  templateUrl: './state-dialog.component.html',
  styleUrls: ['./state-dialog.component.scss']
})
export class StateDialogComponent extends BaseComponent implements OnInit {
  mode?: "Create" | "Edit" | "View"
  state: State
  formGroup: FormGroup
  protected log = new Logger("StateDialogComponent")
  constructor(
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<StateDialogComponent>,
    override snack: FennecSnackbarService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    super();
    this.mode = this.data?.mode;
    this.state = this.data?.state;
    this.formGroup = new FormGroup({
      stateCode: new FormControl(this.state?.stateCode ?? "", Validators.required),
      stateName: new FormControl(this.state?.stateName ?? "", Validators.required),
    });
   }

  ngOnInit(): void {
  }
  submit() {
    const state: State = this.state ?? new State();
    const formValue = this.formGroup.value;
    if (!formValue.stateCode) {
      super.showErrorSnack("Code is required");
      return;
    }
    if (!formValue.stateName) {
      super.showErrorSnack("Name required");
      return;
    }
    state.stateCode = formValue["stateCode"];
    state.stateName = formValue["stateName"];
    this.dialogRef.close(state);
  }

  cancel() {
    this.dialogRef.close();
  }
}
