<div class="wrapper">
  <h2 mat-dialog-title>
    APE Rule Filters for APE Rule '{{apeRule?.name}}'
  
    <button
      mat-icon-button
      style="float:right; color:black;"
      (click)="closeDialog()"
      >
      <mat-icon>close</mat-icon>
    </button>
  </h2>
  
  <mat-table *ngIf="displayMode === 'ADMIN'" [dataSource]="dataSource">

    <ng-container matColumnDef="simple_reorder">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let filter">
        <button
          class="reorder-button"
          mat-icon-button
          (click)="moveFilterOnePlace(filter)"
        >
          <mat-icon>keyboard_arrow_up</mat-icon>
        </button>

        <button
          class="reorder-button"
          mat-icon-button
          (click)="moveFilterOnePlace(filter, true)"
        >
          <mat-icon>keyboard_arrow_down</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="seqNo">
      <mat-header-cell *matHeaderCellDef>Sequence Number</mat-header-cell>
      <mat-cell *matCellDef="let apeRuleApeRuleFilter">{{apeRuleApeRuleFilter.seqNo}}</mat-cell>
    </ng-container>
  
    <ng-container matColumnDef="filterName">
      <mat-header-cell *matHeaderCellDef>Filter Name</mat-header-cell>
      <mat-cell *matCellDef="let apeRuleApeRuleFilter">{{apeRuleApeRuleFilter.apeRuleFilter.name}}</mat-cell>
    </ng-container>
  
    <ng-container matColumnDef="filterDescription">
      <mat-header-cell *matHeaderCellDef>Filter Description</mat-header-cell>
      <mat-cell *matCellDef="let apeRuleApeRuleFilter">{{apeRuleApeRuleFilter.apeRuleFilter.description}}</mat-cell>
    </ng-container>
  
    <ng-container matColumnDef="filterAssertion">
      <mat-header-cell *matHeaderCellDef>Filter Assertion</mat-header-cell>
      <mat-cell *matCellDef="let apeRuleApeRuleFilter">{{apeRuleApeRuleFilter.apeRuleFilterAssertion}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef>
        <button
          mat-icon-button
          (click)="startAddNew()"
        >
          <mat-icon>add_circle</mat-icon> Add Filter
        </button>
      </mat-header-cell>
      <mat-cell *matCellDef="let apeRuleApeRuleFilter">
        <mat-menu #actionMenu="matMenu">
          <button
            mat-menu-item
            (click)="startEdit(apeRuleApeRuleFilter)"
          >
            <mat-icon>edit</mat-icon>
            Edit
          </button>

          <button
            mat-menu-item
            (click)="deletePrompt(apeRuleApeRuleFilter)"
          >
            <mat-icon>delete</mat-icon>
            Delete
          </button>
        </mat-menu>

        <button 
          mat-icon-button
          [matMenuTriggerFor]="actionMenu"
          [matMenuTriggerData]="">
          <mat-icon color="accent">more_vert</mat-icon>
        </button>
      </mat-cell>
    </ng-container>
  
    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedColumns; let i=index"
        [tabindex]="i + 1"
        [attr.data-index]="i"
        [ngClass]="{ hovered: row.hovered }"
        (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"
      ></mat-row>
  </mat-table>
  <mat-paginator *ngIf="displayMode === 'ADMIN'" class="alt-theme" [length]="totalRowCount" [pageSize]="defaultPageSize" [pageSizeOptions]="defaultPageSizeOptions"></mat-paginator>
  
  <div *ngIf="displayMode !== 'ADMIN'" class="sub-header">
    <button
      mat-flat-button
      (click)="backButton()"
    >
      <mat-icon>arrow_back</mat-icon>
      Back
    </button>
    <h3 *ngIf="displayMode !== 'SELECT'">{{ displayMode === 'EDIT' ? 'Edit Filter Sequence #' + selectedApeRuleApeRuleFilter?.seqNo : "Add New Filter" }}</h3>
  </div>

  <form 
    *ngIf="(displayMode === 'EDIT' || displayMode === 'NEW') && formGroup !== null" 
    [formGroup]="formGroup"
    class="alt-theme"
    style="text-align:center;">
    
    <mat-card class="filter-card">
      <mat-card-title>
        {{ selectedApeRuleFilter === null ? "No Filter Selected" : "Selected APE Rule Filter" }}
      </mat-card-title>

      <mat-divider></mat-divider>

      <mat-card-content class="spacer" *ngIf="selectedApeRuleFilter !== null">
        <h3>ID:{{selectedApeRuleFilter.id}} '{{selectedApeRuleFilter.name}}'</h3>
        <p>{{selectedApeRuleFilter.description}}</p>
      </mat-card-content>

      <mat-card-actions>
        <button
          mat-stroked-button
          color="accent"
          (click)="setDisplayMode('SELECT')"
        >
          {{ selectedApeRuleFilter === null ? "Select Filter" : "Change Selected Filter"}}
        </button>
      </mat-card-actions>
    </mat-card>

    <mat-form-field appearance="outline" class="input">
      <mat-label>Filter Assertion</mat-label>
      <mat-select formControlName="apeRuleFilterAssertion">
        <mat-option *ngFor="let assertion of filterAssertions" [value]="assertion">{{assertion}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="seq-no-input">
      <mat-label>Sequence Number</mat-label>
      <input matInput formControlName="seqNo" type="number" min="0">
    </mat-form-field>

    <div mat-dialog-actions [align]="'end'" class="form-button-group">
      <button
        mat-stroked-button
        color="accent"
        (click)="saveApeRuleApeRuleFilter()"
        [disabled]="selectedApeRuleFilter === null"
      >
      <mat-icon>save</mat-icon>
      Save
    </button>
  
      <button
        mat-stroked-button
        (click)="backButton()"
      >
      <mat-icon>cancel</mat-icon>
        Cancel
      </button>
    </div>
  </form>

  <app-ape-rule-filter-list 
    *ngIf="displayMode === 'SELECT'"
    [mode]="'SELECT'" 
    [apeRule]="apeRule"
    [selectFilter]="selectFilter"
    >
  </app-ape-rule-filter-list>
</div>

