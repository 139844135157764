<div matDialogTitle>
  {{ this.mode }} Discharge Status Code
</div>

<div matDialogContent class="alt-theme">
  <form [formGroup]="formGroup" class="container">
    <mat-form-field appearance="outline" color="accent">
      <mat-label>Code</mat-label>
      <input matInput class="name" id="code" formControlName="code" required maxlength="4"/>
      <mat-hint>{{formGroup.controls['code'].value.length}} of 4</mat-hint>
    </mat-form-field>
    <mat-form-field appearance="outline" color="accent">
      <mat-label>Description</mat-label>
      <textarea matInput cdkTextareaAutosize rows="5" class="name" id="description" formControlName="description" required maxlength="250"></textarea>
      <mat-hint>{{formGroup.controls['description'].value.length}} of 250</mat-hint>
    </mat-form-field>
  </form>
</div>

<div matDialogActions class="fennec-dialog-action-button-container">
  <button mat-flat-button color="primary" (click)="cancel()">
    <mat-icon>cancel</mat-icon>
    Cancel
  </button>
  <button [disabled]="formGroup.invalid" mat-flat-button color="accent" (click)="submit()">
    <mat-icon>save</mat-icon>
    Save
  </button>
</div>
