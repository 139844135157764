<div mat-dialog-title class="global-mat-dialog-title">
    Activity Diaries associated with this EDI Run:
  </div>

  <lib-page>
    <ng-container pageTitle>
        <!-- 40px == 2.463vw -->
  <div style="width: 99%; margin-top: 40px;">

    <mat-table [dataSource]="diariesList" class="mat-elevation-z8"
      style="
      width: 90%;
      bottom: 90%;
      margin-left: 5%;
      border-collapse: collapse;
      box-shadow: 0 0 20px rgba(0, 0, 0, 100%);
      background-color: #f8f8f8;
      ">
        <ng-container matColumnDef="createdDate">
          <mat-header-cell *matHeaderCellDef class="global-mat-header-cell"> Created Date </mat-header-cell>
          <mat-cell *matCellDef="let ad" class="global-created-date"> {{ad.createdDate | date: 'MM/dd/yyyy'}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="activityDiaryDomain">
          <mat-header-cell *matHeaderCellDef class="global-mat-header-cell"> Domain </mat-header-cell>
          <mat-cell *matCellDef="let ad"> {{ad.activityDiaryDomain}} </mat-cell>
        </ng-container>

      <ng-container matColumnDef="activityDiaryId">
        <mat-header-cell *matHeaderCellDef class="global-mat-header-cell"> Activity Diary ID </mat-header-cell>
        <mat-cell *matCellDef="let ad">
            <button class="global-mat-cell-button-text" style="right: -21px;"  mat-button mat-raised-button color="accent"  (click)="openDiaryDialog(ad.activityDiaryId)">
                <mat-icon [inline]="true" matListIcon>view_kanban</mat-icon>
                   View Diary {{ad.activityDiaryId}}
            </button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="ediRunId">
        <mat-header-cell *matHeaderCellDef class="global-mat-header-cell"> EDI Run ID </mat-header-cell>
        <mat-cell *matCellDef="let ad" class="global-id">   {{ad.ediRunId}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="createdBy">
        <mat-header-cell *matHeaderCellDef class="global-mat-header-cell"> Created By </mat-header-cell>
        <mat-cell *matCellDef="let ad" class="global-created-by"> {{ad.createdBy}} </mat-cell>
      </ng-container>


      <mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayColumns;"></mat-row>
    </mat-table>
    <mat-paginator [length]="totalRowCount" [pageSize]="defaultPageSize" [pageSizeOptions]="pageSizeOptions"></mat-paginator>
  </div>
  <div >
  </div>
  <div matDialogActions class="fennec-controls"
  style="
  margin-top: -30px;
  display: flex;
  justify-content: center;
  ">
    <button style="font-size: 1.5vw;" (click)="onClose()" mat-flat-button color="primary">
      <mat-icon  [inline]="true">cancel</mat-icon> Close
    </button>
  </div>
  </ng-container>
  </lib-page>

