import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SystemAdminComponent } from "./admin/system-admin/system-admin.component";
import { UserListComponent } from "./user/user-list/user-list.component";
import { UserProfileComponent } from "./user-profile/user-profile.component";
import { ProviderListComponent } from "./provider/provider-list/provider-list.component";
import { XFWFTaskListComponent } from './xfwf/xfwf-task-list/xfwf-task-list.component';
import { CaseListComponent } from "@app/mi-case/case-list/case-list.component";
import { MICaseComponent } from "@app/mi-case/mi-case.component";
import { AttachmentComponent } from './attachment/attachment.component';
import { ProviderComponent } from "@app/provider/provider/provider.component";
import { CompanyListComponent } from "@app/company/company-list/company-list.component";
import { CompanyComponent } from "@app/company/company.component";
import { ActivityLogListComponent } from './activity-log/activity-log-list/activity-log-list.component';
import { EdiRunComponent } from './edi-run/edi-run.component';
import { ActivityDiaryListComponent } from './activity-diary/activity-diary-list/activity-diary-list.component';
import { ApeComponent } from './ape/ape-component/ape.component';
import { PdfViewerComponent } from './attachment/pdf-viewer/pdf-viewer.component';
import { AuthGuard } from './guard/auth.guard';
import { SystemConfigurationComponent } from './system-configuration/system-configuration.component';
import { JslWorkbenchComponent } from './jsl/jsl-workbench/jsl-workbench.component';
import { HealthCarePlanListComponent } from './health-care-plan/health-care-plan-list/health-care-plan-list.component';
import { MiCaseGeneralComponent } from "@app/mi-case/mi-case-general/mi-case-general.component";
import { ItemizedListComponent } from "@app/itemized/itemized-list/itemized-list.component";
import { CommentListComponent } from "@app/comments/comment-list/comment-list.component";
import { MiCaseFinanceComponent } from "@app/mi-case/mi-case-finance/mi-case-finance.component";
import { MiCaseUsersComponent } from "@app/mi-case/mi-case-users/mi-case-users.component";
import { MiCaseReportElementsComponent } from './mi-case/mi-case-report-elements/mi-case-report-elements.component';
import { MiCaseUb04Component, PatientListComponent, PatientComponent } from "xf-common";
import { HealthCarePlanComponent } from './health-care-plan/health-care-plan.component';

const routes: Routes = [
  { path: '', component: XFWFTaskListComponent },
  { path: 'admin', component: SystemAdminComponent },
  { path: 'jsl', component: JslWorkbenchComponent},
  { path: 'edi-run', component: EdiRunComponent },
  { path: 'users', component: UserListComponent },
  { path: 'profile', component: UserProfileComponent },
  { path: 'providers', component: ProviderListComponent },
  { path: 'companies', component: CompanyListComponent },
  { path: 'company/:id', component: CompanyComponent },
  { path: 'health-care-plan/:id', component: HealthCarePlanComponent},
  { path: 'provider/:id', component: ProviderComponent },
  { path: 'mi-case', component: CaseListComponent},
  {
    path: 'mi-case/:miCaseId', component: MICaseComponent, canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'overview', pathMatch: 'full' },
      { path: 'overview', component: MiCaseGeneralComponent },
      { path: 'ub04', redirectTo: 'ub04/', pathMatch: 'full' },
      { path: 'ub04/:ub04Id', component: MiCaseUb04Component },
      { path: 'itemized', redirectTo: 'itemized/', pathMatch: 'full' },
      { path: 'itemized/:itemizedId', component: ItemizedListComponent },
      { path: 'itemized-revision/:itemizedRevisionId', redirectTo: 'itemized/', pathMatch: 'full', data: { source: 'itemized-revision' } },
      { path: 'attachments', component: AttachmentComponent },
      { path: 'task-list', component: XFWFTaskListComponent },
      { path: 'comments', component: CommentListComponent },
      { path: 'activity-diary', component: ActivityDiaryListComponent},
      { path: 'finance', component: MiCaseFinanceComponent},
      { path: 'users', component: MiCaseUsersComponent },
      { path: 'report-elements', component: MiCaseReportElementsComponent }
    ]
  },
  { path: 'attachment', component: AttachmentComponent },
  { path: 'xfwf-activity-log/:id', component: ActivityLogListComponent },
  { path: 'activity-diary', component: ActivityDiaryListComponent},
  { path: 'ape', component: ApeComponent },
  { path: 'view-attachment/:id', component: PdfViewerComponent, canActivate: [AuthGuard]},
  { path: 'system-configuration', component: SystemConfigurationComponent },
  { path: 'health-care-plan', component: HealthCarePlanListComponent },
  { path: 'patients', component: PatientListComponent },
  { path: 'patient/:id', component: PatientComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
