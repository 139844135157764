import { AfterViewInit, Component } from '@angular/core';
import { BaseComponent } from '../../view/base.component';
import { FennecSnackbarService } from '../../dialog/fennec-snackbar/fennec-snackbar.service';
import { Logger } from '../../util/logger';
import { PatientService } from '../patient.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MICaseService } from '../../mi-case/mi-case.service';

@Component({
  selector: 'lib-patient-case-list',
  templateUrl: './patient-case-list.component.html',
  styleUrl: './patient-case-list.component.scss'
})
export class PatientCaseListComponent extends BaseComponent implements AfterViewInit {
  protected override log: Logger = new Logger("PatientCaseListComponent");
  
  patientId = "-1";
  caseList = [];
  displayColumns = [
    'id', 'type', 'stage', 'statusTrackStatus', 'altexternalid', 'name',
    'clientName', 'totalBilledCharges', 'startDate', 'endDate'
  ];

  sortOptions = {
    coverPeriodFromDate: "",
    coverPeriodThruDate: "",
    ub04RevenueCodeSummaryTotal: ""
  }

  constructor(
    protected override snack: FennecSnackbarService,
    protected patientService: PatientService,
    protected route: ActivatedRoute,
    private router: Router,
    protected miCaseService: MICaseService
  ) {
    super();
    this.patientId = this.route.snapshot.paramMap.get("id") ?? "-1";
  }

  ngAfterViewInit(): void {
    this.getData();
  }

  getData = () => {
    if (this.patientId != "-1") {
      const id = parseInt(this.patientId);
      if (id) {
        this.performXFRequest({
          requestDescription: "Get Cases for Patient",
          requestFn: this.patientService.getCasesByPatientId,
          fnParams: [id],
          onSuccess: data => {
            this.caseList = data;
          },
          onError: errString => {
            super.showErrorSnack(errString);
          }
        })

        this.performXFRequest({
          requestDescription: "Get Cases for Patient",
          requestFn: this.miCaseService.getAllCases,
          fnParams: [{
            patientId: id,
            totalBilledChargesSort: this.sortOptions.ub04RevenueCodeSummaryTotal,
            coverPeriodFromSort: this.sortOptions.coverPeriodFromDate,
            coverPeriodThruSort: this.sortOptions.coverPeriodThruDate
          }, 0, 9999],
          onSuccess: data => {
            this.caseList = data;
          },
          onError: errString => {
            super.showErrorSnack(errString);
          }
        })
      }
    }
  }

  navigateToMICase = (miCase) => {
    this.router.navigateByUrl(`/mi-case/${miCase.id}`);
  }

  frontEndSort = (key) => {
    if (this.sortOptions[key] == "DESC") {
      this.sortOptions[key] = "ASC"
    }else {
      this.sortOptions[key] = "DESC"
    }

    Object.keys(this.sortOptions).forEach((k) => {
      if (k != key) {
        this.sortOptions[k] = ""
      }
    })
    this.getData();

    // if (this.sortOptions[key] == "DESC") {
    //   console.log("Sorting!");
    //   const dataCopy = this.caseList;
    //   dataCopy.sort((a, b) => a[key] - b[key]);

    //   this.caseList = dataCopy;
    //   console.log(this.caseList.sort((a, b) => a[key] - b[key]));
    // }

    // if (this.sortOptions[key] == "ASC") {
    //   console.log("Sorting!");
    //   const dataCopy = this.caseList;
    //   dataCopy.sort((a, b) => b[key] - a[key]);

    //   this.caseList = dataCopy;

    //   console.log(this.caseList.sort((a, b) => b[key] - a[key]));
    // }
  }
}
