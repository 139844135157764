import { Deserializable, deserializeAssign } from "../deserializable";

export class AdjustmentReasonCodePacket implements Deserializable<AdjustmentReasonCodePacket> {
  id?: number;
  code?: string;
  description?: string;
  shortDescription?: string;
  selected?: boolean;
  active?: boolean;
  internal?: boolean;
  visibleOnDashboard?: boolean;
  adjustmentReasonType?: string;

  deserialize(input: any): AdjustmentReasonCodePacket {
    return deserializeAssign(this, input)
  }

  toJSON(): any {
    const obj: { [key: string]: any } = {}
    Object.assign(obj, this)
    delete obj["selected"]
    return obj
  }
}
