<div matDialogTitle>
  Modify Attachment ID: {{attachment?.id}}

  <button
    mat-icon-button
    (click)="close()"
    class="close-btn"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>

<div matDialogContent>
  <form [formGroup]="formGroup" class="alt-theme">
    <mat-form-field appearance="outline" class="name-input">
      <mat-label>Attachment Name</mat-label>
      <input matInput formControlName="name">
    </mat-form-field>

    <mat-form-field appearance="outline" class="type-input">
      <mat-label>Attachment Type</mat-label>
      <mat-select
        formControlName="attachmentType"
        >
        <mat-option *ngFor="let at of attachmentTypes" [value]="at">{{ at }}</mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</div>

<mat-dialog-actions class="fennec-dialog-action-button-container">

  <button
    mat-stroked-button
    (click)="close()"
  >
    <mat-icon>cancel</mat-icon>
    Cancel
  </button>

  <button
    mat-raised-button
    color="accent"
    (click)="saveAttachmentToServer()"
  >
    <mat-icon>save</mat-icon>
    Save
  </button>

</mat-dialog-actions>