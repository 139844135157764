import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ItemizedLineItemService } from '../itemized-line-item.service';
import {
  BaseComponent,
  BaseResponse,
  CodeValidationService,
  FennecSnackbarService,
  ItemizedLineItemUpdatePacket002,
  Logger
} from 'xf-common';


@Component({
  selector: 'app-itemized-line-item-edit-dialog',
  templateUrl: './itemized-line-item-edit-dialog.component.html',
  styleUrls: ['./itemized-line-item-edit-dialog.component.scss']
})
export class ItemizedLineItemEditDialogComponent extends BaseComponent implements AfterViewInit, OnInit {

  log: Logger = new Logger("ItemizedLineItemEditDialogComponent");

  ids: number[] = [];
  formGroup: FormGroup;

  constructor(
    private codeValidationService: CodeValidationService,
    private itemizedLineItemService: ItemizedLineItemService,
    public dialogRef: MatDialogRef<ItemizedLineItemEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    override snack: FennecSnackbarService,
  ) {
    super();
    this.formGroup = new FormGroup({
      mappedRevenueCode: new FormControl(data.mappedRevenueCode),
      mappedRevenueCodeDescription: new FormControl(data.mappedRevenueCodeDescription),
      description: new FormControl(data.description),
      preExExplanation: new FormControl(data.preExExplanation),
      preExAdjustedAmount: new FormControl(data.preExAdjustedAmount),
      billPageNo: new FormControl(data.billPageNo),
      reportExclude: new FormControl(data.reportExclude)
    });
    this.formGroup.controls['mappedRevenueCodeDescription'].disable();
    this.ids = data.lineItemIds;
   }

  ngAfterViewInit(): void {
    this.formGroup.controls['mappedRevenueCode'].valueChanges.subscribe(() => {
      this.validateRevenueCode()
    })
  }

  ngOnInit(): void {
  }

  onCancel() {
    const returnObj = {
      confirm: false
    }
    this.dialogRef.close(returnObj);
  }


  getDisplayIds = () => {
    let resultString;
    if(this.ids) {
      resultString = `${this.ids[0] ?? ""}`;
      for(let i = 1; i < this.ids.length; i++) {
        if(this.ids[1]) {
          resultString += `, ${this.ids[i]}`;
        }
      }
    }
    return resultString;
  }

  validateRevenueCode() {
    let rcFormControl: FormControl = this.formGroup.controls['mappedRevenueCode'] as FormControl;
    let descriptionFormControl: FormControl = this.formGroup.controls['mappedRevenueCodeDescription'] as FormControl;

    if (!rcFormControl.pristine) {
      if (rcFormControl.value.length < 2 && rcFormControl.value.length < 4) {
        rcFormControl.markAsPristine();
        descriptionFormControl.setValue("Invalid");
        rcFormControl.setErrors({'incorrect': true});
      } else {
        let value = rcFormControl.value;
        if(value.length === 3) {
          value = "0" + value;
        }

        this.codeValidationService.getRevenueCode(value).subscribe((response: BaseResponse) => {
          if (response.hasErrors) {
            rcFormControl.markAsPristine();
            rcFormControl.setErrors({'incorrect': true});
          } else {
            if (response.data !== null) {
              rcFormControl.markAsDirty();
              descriptionFormControl.setValue(response.data.description);
              rcFormControl.setErrors(null);
            } else {
              descriptionFormControl.setValue("Invalid");
              rcFormControl.markAsPristine();
              rcFormControl.setErrors({'incorrect': true});

            }
          }
        });

      }
    }
  }

  save = () => {
    let value = this.formGroup.controls['mappedRevenueCode'].value;
    if(value.length === 3) {
      value = "0" + value;
    }

    const packet:ItemizedLineItemUpdatePacket002 = new ItemizedLineItemUpdatePacket002();
    packet.ids = this.ids;
    packet.mappedRevenueCode = value;
    packet.mappedRevenueCodeDirty = this.formGroup.controls['mappedRevenueCode'].dirty;
    packet.description = this.formGroup.controls['description'].value;
    packet.descriptionDirty = this.formGroup.controls['description'].dirty;
    packet.preExExplanation = this.formGroup.controls['preExExplanation'].value;
    packet.preExExplanationDirty = this.formGroup.controls['preExExplanation'].dirty;
    packet.preExAdjustedAmount = this.formGroup.controls['preExAdjustedAmount'].value;
    packet.preExAdjustedAmountDirty = this.formGroup.controls['preExAdjustedAmount'].dirty;
    packet.billPageNo = this.formGroup.controls['billPageNo'].value;
    packet.billPageNoDirty = this.formGroup.controls['billPageNo'].dirty;
    packet.reportExclude = this.formGroup.controls['reportExclude'].value;
    packet.reportExcludeDirty = this.formGroup.controls['reportExclude'].dirty;

    this.performXFRequest({
      requestDescription: "PUT Mapped Revenue Code",
      requestFn: this.itemizedLineItemService.putItemizedLineItemMappedRevenueCode,
      fnParams: [packet],
      onSuccess: (data) => {
        super.showSuccessSnack("Save Successful");
      },
      onComplete: () => {
        // this.isLoading = false;
        const returnObj = {
          confirm: true,
          ids : this.ids
        }
        this.dialogRef.close(returnObj);
      },
      onError: (error) => {
        super.showErrorSnack(error);
      }
    });
  }
}
