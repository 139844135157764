<div matDialogTitle>
  {{ this.mode == 'create' ? 'Create' : 'Edit'}} Adjustment Reason Code
</div>

<div matDialogContent class="alt-theme">
  <form [formGroup]="formGroup" style="display:flex; flex-direction:column;">
    <mat-form-field appearance="outline" class="type-input">
      <mat-label>Adjustment Type</mat-label>
      <mat-select formControlName="adjustmentReasonType">
        <mat-option *ngFor="let type of adjustmentReasonTypes" [value]="type">{{ type }}</mat-option>
      </mat-select>
    </mat-form-field>
    
    <mat-form-field appearance="outline">
      <mat-label>Code</mat-label>
      <input matInput class="id" id="code" formControlName="code" required/>
      <mat-hint>{{formGroup.controls['code'].value.length}} of 4</mat-hint>
    </mat-form-field>
    <mat-form-field appearance="outline" [style.width.px]=450>
      <mat-label>Description</mat-label>
      <textarea matInput cdkTextareaAutosize rows="5" class="description" id="description" formControlName="description"
                required maxlength="1000"></textarea>
      <mat-hint>{{formGroup.controls['description'].value.length}} of 1000</mat-hint>
    </mat-form-field>
    <mat-form-field appearance="outline" [style.width.px]=450>
      <mat-label>Short Description</mat-label>
      <textarea matInput cdkTextareaAutosize rows="5" class="shortDescription" id="shortDescription" formControlName="shortDescription"
                required maxlength="100"></textarea>
      <mat-hint>{{formGroup.controls['shortDescription'].value.length}} of 100</mat-hint>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Visible on Dashboard</mat-label>
      <!--      Option 1: Suffix checkbox (right-aligned) -->
      <input matInput disabled>
      <mat-checkbox formControlName="visibleOnDashboard" matSuffix></mat-checkbox>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Internal</mat-label>
      <!--      Option 1: Suffix checkbox (right-aligned) -->
      <input matInput disabled>
      <mat-checkbox formControlName="internal" matSuffix></mat-checkbox>
    </mat-form-field>
  </form>
</div>

<div matDialogActions class="fennec-dialog-action-button-container">
  <button mat-flat-button color="primary" (click)="cancel()">
    Cancel
  </button>
  <button mat-flat-button color="accent" [disabled]="formGroup.invalid" (click)="submit()">
    Save
  </button>
</div>
