import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AttachmentPacket, BaseService, DataResponse, EnvironmentService, Logger, PagedResponse } from "xf-common";

@Injectable({
  providedIn: 'root'
})
export class EdiRunService extends BaseService {
  baseEndpoint = this.apiEndpoint("edirun");

  constructor(
    protected override httpClient: HttpClient,
    private environment: EnvironmentService
  ) {
    super(new Logger("EdiRunService"));
  }

  createUpdateEDIRun = (attachment: AttachmentPacket): Observable<DataResponse<boolean>> => {
    return this.httpClient.put<DataResponse<boolean>>(this.baseEndpoint, attachment);
  };

  getAllEDIRunList(first?: number, max?: number): Observable<PagedResponse<any>> {
    return this.httpClient.get<any>(`${this.environment.baseApiUrl}edirun/list/?first=${first}&max=${max}`);
  }

  getMICaseForEDIRun(id?: number, first?: number, max?: number): Observable<PagedResponse<any>> {
    return this.httpClient.get<any>(`${this.environment.baseApiUrl}edirun/list/edi-cases/${id}?first=${first}&max=${max}`);
  }

  getActivityDiariesForEDIRun(id?: number, first?: number, max?: number): Observable<PagedResponse<any>> {
    return this.httpClient.get<any>(`${this.environment.baseApiUrl}edirun/list/edi-diaries/${id}?first=${first}&max=${max}`);
  }

  processEDIRun(id: number): Observable<DataResponse<boolean>> {
    return this.httpClient.get<any>(`${this.environment.baseApiUrl}edirun/process/${id}`);
  }
}
