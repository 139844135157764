<ng-container pageContent class="container-override">

  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h4>Filters</h4>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form [formGroup]="formGroup" class="alt-theme" (submit)="onSearch()">
        <div class="input-wrapper">
          <mat-form-field appearance="outline">
            <mat-label>Name</mat-label>
            <input matInput placeholder="Search by Name" formControlName="name">
          </mat-form-field>
    
          <mat-form-field appearance="outline">
            <mat-label>Description</mat-label>
            <input matInput placeholder="Search by Description" formControlName="description">
          </mat-form-field>
          
          <mat-form-field appearance="outline">
            <mat-label>Unique Identification Code</mat-label>
            <input matInput placeholder="Search by Unique Identification Code" formControlName="uniqueIdentificationCode">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Status</mat-label>
            <mat-select formControlName="active">
              <mat-option [value]="'all'">All</mat-option>
              <mat-option [value]="true">Active</mat-option>
              <mat-option [value]="false">Not Active</mat-option>
            </mat-select>
          </mat-form-field>
          
          <div class="button-div">
            <button
              mat-stroked-button
              color="accent"
              type="submit"
              class="search-button"
            >
              <mat-icon>search</mat-icon> Search
            </button>
      
            <button
              mat-stroked-button
              color="primary"
              (click)="clearSearch()"
            >
              Clear
            </button>
          </div>
        </div>
      </form>
    </mat-expansion-panel>
  </mat-accordion>

    <mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="uniqueIdentificationCode">
        <mat-header-cell *matHeaderCellDef>Identification Code</mat-header-cell>
        <mat-cell *matCellDef="let collection"> {{collection?.uniqueIdentificationCode}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="description">
        <mat-header-cell *matHeaderCellDef> Description</mat-header-cell>
        <mat-cell *matCellDef="let collection"> {{collection?.description}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
        <mat-cell *matCellDef="let collection">
          {{collection?.name}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef>
          <button class="add" mat-flat-button (click)="onCreate()">
            <mat-icon>add_circle</mat-icon>
            New
          </button>
        </mat-header-cell>
        <mat-cell *matCellDef="let collection">
          <mat-menu #appMenu="matMenu">
            <button
              matTooltip="View Rules"
              mat-menu-item color="accent"
              (click)="openRuleDialog(collection)"
            >
              <mat-icon>preview</mat-icon>
              View Rules
            </button>

            <button
              *ngIf="canUpdate"
              matTooltip="Edit (e)"
              mat-menu-item color="accent"
              (click)="openEditDialog(collection)">
              <mat-icon>edit</mat-icon>
              <span>Edit</span>
            </button>

            <button
              *ngIf="canDelete"
              matTooltip="Delete (d)"
              mat-menu-item color="primary"
              (click)="openDeactivateDialog(collection)">
              <mat-icon>{{getDeleteIconString(collection)}}</mat-icon>
               {{collection?.active ? "Deactivate" : "Restore"}}
            </button>
            <!-- <button
              *ngIf="!collection?.active && canDeactivate"
              matTooltip="Activate"
              mat-menu-item
              (click)="openDeactivateDialog(collection)"
              >
              <mat-icon>check</mat-icon>
              <span>Activate</span>
            </button>
            <button
              *ngIf="collection?.active && canDeactivate"
              matTooltip="Deactivate"
              mat-menu-item
              >
              <mat-icon>close</mat-icon>
              <span>Deactivate</span>
            </button> -->
          </mat-menu>

          <button mat-icon-button [matMenuTriggerFor]="appMenu" [matMenuTriggerData]="{name: 'Sally'}">
            <mat-icon>more_vert</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="active">
        <mat-header-cell *matHeaderCellDef>Active</mat-header-cell>
        <mat-cell *matCellDef="let eventDefinition">
          <mat-icon [ngStyle]="getActiveIconStyle(eventDefinition)">
            {{eventDefinition?.active ? "check_circle" : "error_outline"}}
          </mat-icon>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedColumns; let i=index"
        [tabindex]="i + 1"
        [attr.data-index]="i"
        [ngClass]="{ hovered: row.hovered }"
        (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"
      ></mat-row>
    </mat-table>
    <mat-paginator class="alt-theme" [length]="totalRowCount" [pageSize]="defaultPageSize" [pageSizeOptions]="defaultPageSizeOptions"></mat-paginator>

</ng-container>
