import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import {
  AddressComponent,
  BaseComponent,
  BaseResponse,
  CodeValidationService,
  FennecSnackbarService,
  Logger,
  ProviderPacket,
  ProviderService
} from 'xf-common';
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";


@Component({
  selector: 'app-ub04-add-provider',
  templateUrl: './provider-add.component.html',
  styleUrls: ['./provider-add.component.scss']
})
export class ProviderAddComponent extends BaseComponent implements OnInit, AfterViewInit {
  log: Logger = new Logger("ProviderAddComponent");

  formGroup!: FormGroup;
  providerTypes?: any;

  @ViewChild('addressComponent')
  addressComponent?: AddressComponent;

  saveProvider: Subject<any> = new Subject();
  providerSaveComplete: Subject<any> = new Subject();

  constructor(
    private providerService: ProviderService,
    private codeValidationService: CodeValidationService,
    protected dialogRef: MatDialogRef<ProviderAddComponent>,
    protected snack: FennecSnackbarService
  ) {
    super();
    this.formGroup = new FormGroup({
      name: new FormControl(""),
      firstName: new FormControl(""),
      lastName: new FormControl(""),
      npiNumber: new FormControl(""),
      providerType: new FormControl("", Validators.required),
      taxonomyCode: new FormControl(""),
      taxonomyCodeDescription: new FormControl(""),
      addressValidator: new FormControl()
    }, {
      // updateOn: 'blur'
    })

    this.formGroup.controls['taxonomyCodeDescription'].disable();

    this.formGroup.valueChanges.subscribe(() => {
      this.validateTaxonomyCode();
      this.validateAddress();
    })

  }

  ngOnInit(): void {
    this.fetchData();
  }

  ngAfterViewInit(): void {
    this.findAddressComponent();
  }

  findAddressComponent(){
    if (this.addressComponent != null) {
      this.addressComponent?.addressSaveComplete?.subscribe((id: number) => {
        // this.ub04Patient.patient.addressId = id;
        this.saveProviderToServer(id);
      });
      this.addressComponent?.formGroup?.valueChanges?.subscribe(() => {
        this.validateAddress();
      })
    }
  }

  fetchData() {
    this.performXFRequest({
      requestDescription: "GET provider types",
      requestFn: this.providerService.getProviderTypes,
      fnParams: [],
      onSuccess: data => {
        this.providerTypes = data;
      },
      onError: errString => {
        super.showErrorSnack(errString);
      }
    })
  }

  validateTaxonomyCode() {
    let taxonomyCodeControl: FormControl = this.formGroup.controls['taxonomyCode'] as FormControl;
    let taxonomyDescriptionControl: FormControl = this.formGroup.controls['taxonomyCodeDescription'] as FormControl;

    if (!taxonomyCodeControl.pristine) {
      if(taxonomyCodeControl.value.length == 0) {
        taxonomyCodeControl.markAsPristine();
        taxonomyDescriptionControl.setValue("", {onlySelf: true, emitEvent: false});
        taxonomyCodeControl.setErrors(null);
      }else if (taxonomyCodeControl.value.length < 2) {
        taxonomyCodeControl.markAsPristine();
        taxonomyDescriptionControl.setValue("Invalid", {onlySelf: true, emitEvent: false});
        taxonomyCodeControl.setErrors({'incorrect': true});
      }else {
        this.providerService.getTaxonomyCodeByCode(taxonomyCodeControl.value).subscribe((response: BaseResponse) => {
          taxonomyCodeControl.markAsPristine();
          if (response.hasErrors) {
            taxonomyCodeControl.setErrors({'incorrect': true});
          }else {
            if (response.data !== null) {
              taxonomyDescriptionControl.setValue(response.data.description, {onlySelf: true, emitEvent: false});
              taxonomyCodeControl.setErrors(null);
            }else {
              taxonomyDescriptionControl.setValue("Invalid", {onlySelf: true, emitEvent: false});
              taxonomyCodeControl.setErrors({'incorrect': true});
            }
          }
        })
      }
    }
  }

  validateAddress(): boolean {
    let addressForm: FormGroup | undefined = this?.addressComponent?.formGroup;
    let addressValueEntered = false;

    if(addressForm){
      if (addressForm?.touched && addressForm?.dirty) {
        // check whether address has values to persist
        const enteredAddressInfo = addressForm?.value;
        const addressFields = Object.keys(enteredAddressInfo);
        let field: string;
        for (let key in addressFields) {
          field = addressFields[key];
          if (field != null && field != "") {
            addressValueEntered = true;
            break;
          }
        }
      }
      if(!addressForm.valid){
        this.formGroup.controls['addressValidator'].setErrors({'incorrect':true});
      }else{
        this.formGroup.controls['addressValidator'].setErrors(null);
      }
    }
    return addressValueEntered;
  }

  displayFriendlyProviderType(type: string){
    return type.toLowerCase().replace("_", " ");
  }

  onSubmit() {
    this.formGroup.markAllAsTouched();
    const addressEntered = this.validateAddress();
    if (this.formGroup.invalid) {
      return;
    }
    if (addressEntered) {
      this.addressComponent?.saveAddressToServer();
    } else {
      this.saveProviderToServer(null);
    }
  }

  saveProviderToServer(addressId: number | null){
    this.formGroup.markAllAsTouched();
    const value = this.formGroup.value;

    if (this.formGroup.errors || this.formGroup.invalid
      || (value["providerType"] == null || value["providerType"] == "")) {
      super.showErrorSnack("Please fill out all required fields.");
      return;
    }

    let packet = new ProviderPacket();
    packet.id = -1;
    packet.name = value["name"];
    packet.firstName = value["firstName"];
    packet.lastName = value["lastName"];
    packet.npiNumber = value["npiNumber"];
    packet.providerType = value["providerType"];
    packet.taxonomyCode = value["taxonomyCode"];
    packet.addressId = addressId;

    this.providerService.createProvider(packet).subscribe(response => {
      if (response.hasErrors) {
        super.showErrorSnack(response.consolidatedErrorMessage)
      } else {
        this.providerSaveComplete.next(response.data);
        super.showSuccessSnack(`Successfully Created!`);
        let returnObj = {
          confirm: true
        }
        this.dialogRef.close(returnObj);
      }
    })
  }

  cancel() {
    let returnObj = {
      confirm: false
    };
    this.dialogRef.close(returnObj);
  }
}
