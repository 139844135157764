<lib-page>
  <ng-container pageTitle>
    <div class="header">
      <button mat-button matTooltip="Open Sidebar"
      color="accent"
      class="header-content"
      (click)="toggle()">
        <mat-icon>menu</mat-icon>
      </button>
      Admin
    </div>
  </ng-container>

  <ng-container pageContent>

  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav #sidenav mode="side" [(opened)]="navigationOpen">
      <div class="sidebar-header">
        <h4>Admin</h4>
        <button
          mat-button
          matTooltip="Close Sidebar"
          (click)="toggle()">
          <mat-icon>close</mat-icon>
        </button>
      </div>

      <div
        class="admin-side-nav"
      >
        <button
          mat-menu-item
          *ngFor="let option of options; let i = index"
          (click)="changeSelected(i)"
          [ngClass]="{ 'selected': i === selected}"
        >
          <mat-icon color="accent">{{ option.icon }}</mat-icon> <span style="color:black;">{{ option.text }}</span>
        </button>
      </div>

    </mat-sidenav>
      <mat-sidenav-content>

        @switch (options[selected].text) {
          @case ('Patient Relationship Codes') {
            <app-patient-relationship-code-list></app-patient-relationship-code-list>
          }
          @case ('State Codes') {
            <app-state-admin-list></app-state-admin-list>
          }
          @case ('Condition Codes') {
            <app-condition-code></app-condition-code>
          }
          @case ('Discharge Status Codes') {
            <app-discharge-status-code></app-discharge-status-code>
          }
          @case ('Revenue Codes') {
            <app-revenue-code-list></app-revenue-code-list>
          }
          @case ('Diagnosis Codes') {
            <app-diagnosis-code-list></app-diagnosis-code-list>
          }
          @case ('Value Codes') {
            <app-value-code-list></app-value-code-list>
          }
          @case ('Admission Sources') {
            <app-admission-source-list></app-admission-source-list>
          }
          @case ('HCPCS Codes') {
            <app-hcpcs-code-list></app-hcpcs-code-list>
          }
          @case ('Taxonomy Codes') {
            <app-taxonomy-code-list></app-taxonomy-code-list>
          }
          @case ('Procedure Codes') {
            <app-procedure-code-list></app-procedure-code-list>
          }
          @case ('Adjustment Reason Codes') {
            <app-adjustment-reason-code-list></app-adjustment-reason-code-list>
          }
          @case ('Adjustment Explanation Codes') {
            <app-adjustment-explanation-code-list></app-adjustment-explanation-code-list>
          }
          @case ('Occurrence Codes') {
            <app-occurrence-code-list></app-occurrence-code-list>
          }
          @case ('DRG Codes') {
            <app-drg-code-list></app-drg-code-list>
          }
          @case ('Holidays') {
            <app-holiday-list></app-holiday-list>
          }
          @case ('User Groups') {
            <app-user-group-list></app-user-group-list>
          }
        }

      </mat-sidenav-content>
    </mat-sidenav-container>
  </ng-container>
</lib-page>
