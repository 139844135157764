<ng-container pageContent>

  <mat-table [dataSource]="providerEINList">

    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef> ID </mat-header-cell>
      <mat-cell *matCellDef="let p">
        {{p.id}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="federalTaxNumber">
      <mat-header-cell *matHeaderCellDef> Federal Tax Number </mat-header-cell>
      <mat-cell *matCellDef="let p">
        {{p.federalTaxNumber}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef> 
        <button
          mat-button
          color="accent"
          (click)="openProviderEINDialog()"
        >
          <mat-icon>add_circle</mat-icon>
          Add EIN
        </button>  
      </mat-header-cell>
      <mat-cell *matCellDef="let p">
        <mat-menu #actionMenu="matMenu">
          <button
            mat-menu-item
            (click)="openProviderEINDialog(p)"
          >
            <mat-icon>edit</mat-icon>
            Edit
          </button>
          
          <button
            mat-menu-item
            (click)="deleteDialog(p)"
          >
            <mat-icon>delete</mat-icon>
            Delete
          </button>
        </mat-menu>

        <button mat-icon-button [matMenuTriggerFor]="actionMenu" aria-label="Row Action Items">
          <mat-icon>more_vert</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>

  <ng-container *ngIf="providerEINList.length == 0">
    <div class="no-data-message">
      <div style="text-align:center;">
        <h3 style="color:red;">No EINs found for Provider ID:{{providerId}}</h3>
        <button
            mat-button
            color="accent"
            (click)="openProviderEINDialog()"
          >
            <mat-icon>add_circle</mat-icon>
            Add EIN
          </button>  
      </div>
    </div>
  </ng-container>
</ng-container>