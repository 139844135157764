<mat-dialog-content>
  <h2>Clone Itemized Revision (Id: {{iRevId}})</h2>
  <div class="create-form" *ngIf="!loading">
    <form [formGroup]="formGroup" class="container create-form alt-theme">
      <mat-form-field class="name-field" appearance="outline">
        <mat-label>Enter Name</mat-label>
        <input matInput placeholder="Enter Name..." formControlName="name">
      </mat-form-field> 

      <mat-form-field class="name-field" appearance="outline">
        <mat-label>Revision Type</mat-label>
        <mat-select formControlName="revisionType">
          <mat-option *ngFor="let type of revisionTypeList" [value]="type">
            {{ type }}
          </mat-option>
        </mat-select>
      </mat-form-field> 
    </form>
  </div> 
  <div *ngIf="loading" class="irev-cloning">
    <div class="irev-cloning-item">Cloning - Please Wait!</div>
    <div class="irev-cloning-item"><mat-spinner color="accent"></mat-spinner></div>
  </div>
</mat-dialog-content>
  
<mat-dialog-actions class="fennec-controls" *ngIf="!loading">
	
	<button
		mat-flat-button
    	[disabled]="!formGroup.valid" 
		(click)="onYes()" 
		color="accent">
			<mat-icon>check_circle</mat-icon> Yes
	</button>

	<button
		mat-button 
		mat-stroked-button 
		(click)="onNo()">
			<mat-icon>cancel</mat-icon> No
	</button>
</mat-dialog-actions>
