import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseResponse, EnvironmentService, JSLExecuteJobCPQUpdate, Logger } from 'xf-common';
import { Observable } from "rxjs";


const log = new Logger("JSLService");

@Injectable({
  providedIn: "root"
})
export class JslService {
  private jslUrl = `${this.environment.baseApiUrl}jsl/`;

  constructor(
    protected http: HttpClient,
    private environment: EnvironmentService
  ) {
  }

  getAllParamTypes(): Observable<any> {
    const ep = this.jslUrl + "paramtypes";
    log.debug(`Sending request to endpoint of ${ep}`);
    return this.http.get<any>(ep);
  }

  getAllJSLJobDefinitions(first: number = 0, max: number = 100): Observable<BaseResponse> {
    const ep = this.jslUrl + "jobdefinitions" + "?first=" + first.toString() + "&max=" + max.toString();
    log.debug(`Sending request to endpoint of ${ep}`);
    return this.http.get<any>(ep);
  }

  getAllJSLJobInstances(first: number = 0, max: number = 100): Observable<any> {
    const ep = this.jslUrl + "jobinstances" + "?first=" + first.toString() + "&max=" + max.toString();
    log.debug(`Sending request to endpoint of ${ep}`);
    return this.http.get<any>(ep);
  }

  getJSLJobDefinitionParamsForJobDefinition(jobDefinitionId: number): Observable<any> {
    const ep = this.jslUrl + jobDefinitionId.toString() + "/jobdefinitionparams";
    log.debug(`Sending request to endpoint of ${ep}`);
    return this.http.get<any>(ep);
  }

  getJSLJobDefinitionInstancesForJobDefinition(jobDefinitionId: number, first: number = 0, max: number = 100): Observable<any> {
    const ep = this.jslUrl + jobDefinitionId.toString() + "/jobdefinitioninstances" + "?first=" + first.toString() + "&max=" + max.toString();
    log.debug(`Sending request to endpoint of ${ep}`);
    return this.http.get<any>(ep);
  }

  addNewJSLJobDefinition(jslJobCode: string, name: string): Observable<any> {
    const payload = {
      id: -1,
      name,
      jslJobCode
    };
    const ep = this.jslUrl + "jobdefinition/new";
    log.debug(`Sending request to endpoint of ${ep}`);
    return this.http.post<any>(ep, payload);
  }

  updateJSLJobDefinition(id: number, jslJobCode: string, name: string): Observable<any> {
    const payload = {
      id,
      name,
      jslJobCode
    };
    const ep = this.jslUrl + "jobdefinition";
    log.debug(`Sending request to endpoint of ${ep}`);
    return this.http.put<any>(ep, payload);
  }

  updateJSLQueuedJobToCancelled(
    id: number,
    jslJobDefinitionId: number,
    jslJobExecutionStatus: any,
    name: string
  ): Observable<any> {
    const payload = {
      id,
      jslJobDefinitionId,
      name,
      jslJobExecutionStatus
    };
    const ep = this.jslUrl + "cancel";
    log.debug(`Sending request to endpoint of ${ep}`);
    return this.http.put<any>(ep, payload);
  }

  addNewJSLJobDefinitionParam(
    seqNo: number,
    jslJobDefinitionId: number,
    paramType: any,
    paramPrompt: string
  ): Observable<any> {
    const payload = {
      id: -1,
      seqNo,
      jslJobDefinitionId,
      paramType,
      paramPrompt
    };
    const ep = this.jslUrl + "jobdefinitionparam/new";
    log.debug(`Sending request to endpoint of ${ep}`);
    return this.http.post<any>(ep, payload);
  }

  updateJSLJobDefinitionParam(
    id: number,
    seqNo: number,
    jslJobDefinitionId: number,
    paramType: any,
    paramPrompt: string
  ): Observable<any> {
    const payload = {
      id,
      seqNo,
      jslJobDefinitionId,
      paramType,
      paramPrompt
    };
    const ep = this.jslUrl + "jobdefinitionparam";
    log.debug(`Sending request to endpoint of ${ep}`);
    return this.http.put<any>(ep, payload);
  }

  executeJSLJob(payload: JSLExecuteJobCPQUpdate): Observable<any> {
    const ep = this.jslUrl + "execute";
    log.debug(`Sending request to endpoint of ${ep}`);
    return this.http.post<any>(ep, payload);
  }
}
