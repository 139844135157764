<div matDialogTitle>
  {{mode}} Health Care Plan
</div>

<div matDialogContent class="alt-theme">
  <form [formGroup]="formGroup" style="display:flex; flex-direction:column;">
    <mat-form-field appearance="outline">
      <mat-label>Name</mat-label>
      <input matInput class="id" id="name" formControlName="name" required/>
      <!-- <mat-hint>{{formGroup.controls['code'].value.length}} of 4</mat-hint> -->
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>State Code</mat-label>
      <!-- <textarea matInput cdkTextareaAutosize rows="5" class="description" id="description" formControlName="description" required maxlength="100"></textarea> -->
      <!-- <mat-hint>{{formGroup.controls['description'].value.length}} of 100</mat-hint> -->
      <input type="text"
           placeholder="State Code"
           aria-label="Number"
           matInput
           formControlName="stateCode"
           [matAutocomplete]="auto"
           maxlength="2">
      <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption>
        <mat-option *ngFor="let option of stateAutoCompleteOptions" [value]="option.stateCode">
          {{option.stateCode}} - {{option.stateName}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field *ngIf="mode === 'Create'">
      <mat-label>Company</mat-label>
      <mat-select formControlName="company">
        <mat-option [value]="-1">
          Unassigned
        </mat-option>

        <mat-option *ngFor="let company of companyRelationalMapList" [value]="company.companyId">
          {{company?.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</div>

<div matDialogActions class="fennec-dialog-action-button-container">
  <button mat-flat-button color="primary" (click)="cancel()">
    Cancel
  </button>
  <button mat-flat-button color="accent" [disabled]="formGroup.invalid" (click)="submit()">
    Save
  </button>
</div>
