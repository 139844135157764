import { Deserializable, deserializeAssign } from "../deserializable";

export class HealthCarePlanLOBUpdatePacket implements Deserializable<HealthCarePlanLOBUpdatePacket> {
  id: number = -1;
  planCode?: string;
  stateCode?: string;
 
  deserialize(input: any): HealthCarePlanLOBUpdatePacket {
    return deserializeAssign(this, input);
  }

  toJSON(): any {
    const obj: { [key: string]: any } = {};
    Object.assign(obj, this);
    delete obj["selected"];
    return obj;
  }
}
