<div class="wrapper">
  <div matDialogTitle>
    {{ this.mode }} Taxonomy Code
  </div>
  
  <div matDialogContent class="alt-theme">
    <form [formGroup]="formGroup" class="container">
      <mat-form-field appearance="outline">
        <mat-label>Code</mat-label>
        <input matInput class="id" id="code" formControlName="code" required maxlength="40" />
        <mat-hint>{{formGroup.controls['code'].value.length}} of 40</mat-hint>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Description</mat-label>
        <textarea matInput cdkTextareaAutosize rows="3" class="description" id="description" formControlName="description" required maxlength="100"></textarea>
        <mat-hint>{{formGroup.controls['description'].value.length}} of 100</mat-hint>
      </mat-form-field>
      
      <mat-form-field appearance="outline" color="accent">
        <mat-label>Definition</mat-label>
        <textarea 
          matInput 
          cdkTextareaAutosize 
          class="name" 
          id="definition" 
          formControlName="definition" 
          required
          maxlength="2000"
          rows="5"></textarea>
          <mat-hint>{{formGroup.controls['definition'].value.length}} of 2000</mat-hint>
      </mat-form-field>
    </form>
  </div>
  
  <div matDialogActions class="fennec-dialog-action-button-container">
    <button mat-flat-button color="primary" (click)="cancel()">
      Cancel
    </button>
    <button [disabled]="formGroup.invalid" mat-flat-button color="accent" (click)="submit()">
      Save
    </button>
  </div>
</div>

