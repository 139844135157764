import { Deserializable, deserializeAssign } from "../deserializable";

export class ItemizedLineItemUpdatePacket002 implements Deserializable<ItemizedLineItemUpdatePacket002> {

  ids?: number[];
  mappedRevenueCode?: string;
  description?: string;
  preExExplanation?: string;
  preExAdjustedAmount?: number;
  billPageNo?: number;
  reportExclude: boolean = false;

  // Developers - please maintain the 'targetted' (or dirty) update pattern.
  mappedRevenueCodeDirty: boolean = false;
  descriptionDirty: boolean = false;
  preExExplanationDirty: boolean = false;
  preExAdjustedAmountDirty: boolean = false;
  billPageNoDirty: boolean = false;
  reportExcludeDirty: boolean = false;

  deserialize(input: any): ItemizedLineItemUpdatePacket002 {
    return deserializeAssign(this, input);
  }

  toJSON(): any {
    const obj: { [key: string]: any } = {};
    Object.assign(obj, this);
    delete obj["selected"];
    return obj;
  }
}