import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { BaseComponent, FennecSnackbarService, Logger, PatientRelationshipCodePacket } from "xf-common";


@Component({
  selector: 'app-patient-relationship-code-dialog',
  templateUrl: './patient-relationship-code-dialog.component.html',
  styleUrls: ['./patient-relationship-code-dialog.component.scss']
})
export class PatientRelationshipCodeDialogComponent extends BaseComponent implements OnInit {

  mode?: "Create" | "Edit" | "View";
  patientRelationshipCode?: PatientRelationshipCodePacket;
  formGroup: FormGroup;

  protected log = new Logger("PatientRelationshipCodeDialogComponent");

  constructor(
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<PatientRelationshipCodeDialogComponent>,
    override snack: FennecSnackbarService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    // super(new Logger("PatientRelationshipCodeDialogComponent"), snack);
    super();
    this.mode = this.data?.mode;
    this.patientRelationshipCode = this.data?.patientRelationshipCode;
    this.formGroup = new FormGroup({
      code: new FormControl(this.patientRelationshipCode?.code ?? "", [Validators.required, Validators.maxLength(4)]),
      description: new FormControl(this.patientRelationshipCode?.description ?? "", [Validators.required, Validators.maxLength(100)]),
    });
  }

  ngOnInit(): void {
  }

  submit() {
    const patientRelationshipCode: PatientRelationshipCodePacket = this.patientRelationshipCode ?? new PatientRelationshipCodePacket();
    const formValue = this.formGroup.value;
    if (!formValue.code) {
      super.showErrorSnack("Code is required");
      return;
    }
    if (!formValue.description) {
      super.showErrorSnack("Description required");
      return;
    }
    patientRelationshipCode.code = formValue["code"];
    patientRelationshipCode.description = formValue["description"];
    this.dialogRef.close(patientRelationshipCode);
  }

  cancel() {
    this.dialogRef.close();
  }
}
