<lib-page>
  <ng-container pageTitle>
    System Configuration
  </ng-container>

  <ng-container pageContent>

    <mat-tab-group color="accent">
      <mat-tab label="General">
        <mat-card>
          <mat-card-content>
            <form [formGroup]="formGroup" class="alt-theme">

              <mat-form-field appearance="outline" class="input">
                <mat-label>Tenant Name</mat-label>
                <input matInput formControlName="tennantName">
              </mat-form-field>
            </form>
          </mat-card-content>

          <mat-card-actions>
            <button *ngIf="mode === 'EDIT'"
              mat-raised-button
              color="accent"
              (click)="saveSystemConfiguration()"
            >
              Save
            </button>
          </mat-card-actions>
        </mat-card>
      </mat-tab>
      <mat-tab label="Companies">
        <app-tenant-company-list></app-tenant-company-list>
      </mat-tab>
    </mat-tab-group>


  </ng-container>
</lib-page>
