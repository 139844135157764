import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ConditionCodeService } from '../condition-code.service';
import { ConditionCode, FennecSnackbarService } from "xf-common";


@Component({
  selector: 'app-condition-code-edit-form',
  templateUrl: './condition-code-edit-form.component.html',
  styleUrls: ['./condition-code-edit-form.component.scss']
})
export class ConditionCodeEditFormComponent implements OnInit {
  selectedCode: ConditionCode;
  formGroup: FormGroup;

  constructor(
    private snackBar: FennecSnackbarService,
    private codeService: ConditionCodeService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ConditionCodeEditFormComponent>
  ) {
    this.selectedCode = data;
    this.formGroup = new FormGroup({
      code: new FormControl("", [Validators.required, Validators.maxLength(4)]),
      description: new FormControl("", [Validators.required, Validators.maxLength(1000)])
    })
    if (data.code !== null && data.code !== undefined) {
      this.formGroup.patchValue({
        code: data.code.code,
        description: data.code.description
      })
    }
   }

  ngOnInit() {
  }

  onSave() {
    this.data.code.code = this.formGroup.get('code')?.value;
    this.data.code.description = this.formGroup.get('description')?.value;
    this.codeService.updateCode(this.data.code).subscribe((response) => {
      this.dialogRef.close();
    })
  }
  onClose() {
    this.dialogRef.close()
  }
}
