import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { BaseCrudService, Crud, DataResponse, PagedResponse, RevenueCodePacket } from "xf-common";


@Injectable()
export class RevenueCodeService extends BaseCrudService<RevenueCodePacket> {
  baseEndpoint = super.apiEndpoint("revenue-code");

  constructor(public override httpClient: HttpClient) {
    super(httpClient, "RevenueCodeService", "RevenueCode");
  }

  getRevenueCode: () => Observable<DataResponse<RevenueCodePacket>> = () => {
    const endpoint = `${this.baseEndpoint}`;
    return this.httpClient.get<DataResponse<RevenueCodePacket>>(endpoint);
  }

  _list: Crud.ListFunction<RevenueCodePacket> = (first, max) => {
    const endpoint = `${this.baseEndpoint}/list`;
    return this.httpClient.get<PagedResponse<RevenueCodePacket[]>>(endpoint, { params: { first, max } });
  };

  _getById: Crud.GetByIdFunction<RevenueCodePacket> = (id) => {
    const endpoint = `${this.baseEndpoint}/${id}`;
    return this.httpClient.get<PagedResponse<RevenueCodePacket>>(endpoint);
  }

  _create: Crud.CreateFunction<RevenueCodePacket> = (user) => {
    const endpoint = `${this.baseEndpoint}/create`;
    return this.httpClient.post<PagedResponse<RevenueCodePacket>>(endpoint, user);
  }

  _update: Crud.UpdateFunction<RevenueCodePacket> = (user): Observable<DataResponse<RevenueCodePacket>> => {
    const endpoint = `${this.baseEndpoint}/update`;
    return this.httpClient.put<PagedResponse<RevenueCodePacket>>(endpoint, user);
  }

  _deactivate: Crud.DeactivateFunction<RevenueCodePacket> = (ids) => {
    const endpoint = `${this.baseEndpoint}/deactivate`;
    return this.httpClient.put<DataResponse<boolean>>(endpoint, ids[0]);
  }

  _delete = (ids: number[]):  Observable<DataResponse<boolean>> => {
    const endpoint = `${this.baseEndpoint}/delete/` + ids[0];
    return this.httpClient.delete<DataResponse<boolean>>(endpoint, {});
  }

  changeStatus = (diagnosisCodePacket: RevenueCodePacket): Observable<DataResponse<RevenueCodePacket>> => {
    const endpoint = `${this.baseEndpoint}/status`;
    return this.httpClient.put<DataResponse<RevenueCodePacket>>(endpoint, diagnosisCodePacket);
  }

  protected _crudFunctions: Crud.CrudServiceDef<RevenueCodePacket> = {
    _list: this._list,
    _getById: this._getById,
    _create: this._create,
    _update: this._update,
    _deactivate: this._deactivate,
    _delete: this._delete
  };

  getRevenueCodesByParams = (packet:any, first:number, max:number) => {
    const endpoint = `${this.baseEndpoint}/list?first=${first}&max=${max}`;
    return this.httpClient.post<PagedResponse<RevenueCodePacket>>(endpoint, packet);
  }
}
