<lib-page>
  <ng-container pageTitle style="display:flex;">
    <button type="button" mat-stroked-button color="accent" class="button-with-icon" routerLink="/health-care-plan">
      <mat-icon>navigate_before</mat-icon>
      Health Care Plans
    </button>

    <span class="health-care-plan-name">
      {{ healthCarePlan?.name }}
    </span>
  </ng-container>
  <ng-container pageContent>
    <mat-tab-group color="accent">
      <mat-tab label="Line of Business">
        <app-health-care-plan-lob [healthCarePlanId]="healthCarePlanId"></app-health-care-plan-lob>
      </mat-tab>

    </mat-tab-group>
  </ng-container>

</lib-page>
