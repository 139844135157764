<mat-dialog-content>
  <h3>Error Message</h3>
  <div>
    <span class="error-text">{{data.messageText}}</span>
  </div>
</mat-dialog-content>
  
  <div matDialogActions class="fennec-controls">  
    <button (click)="onClose()" mat-flat-button color="primary">
        <mat-icon>cancel</mat-icon> Close
    </button>
  </div>