import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { BaseComponent, FennecSnackbarService, Logger, UserProfilePacket } from 'xf-common';


@Component({
  selector: 'app-user-dialog',
  templateUrl: './user-dialog.component.html',
  styleUrls: ['./user-dialog.component.scss']
})
export class UserDialogComponent extends BaseComponent implements OnInit {
  log = new Logger("UserDialogComponent");

  mode?: "create" | "edit" | "view";
  user?: UserProfilePacket;
  formGroup: FormGroup;

  constructor(
    protected snack: FennecSnackbarService,
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<UserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    super();
    this.mode = this.data?.mode;
    this.user = this.data?.user;
    this.formGroup = new FormGroup({
      firstName: new FormControl(this.user?.firstName ?? "", Validators.required),
      lastName: new FormControl(this.user?.lastName ?? "", Validators.required),
    });
  }

  ngOnInit(): void {
  }

  submit() {
    const user: UserProfilePacket = this.user ?? new UserProfilePacket();
    const formValue = this.formGroup.value;
    if (!formValue.firstName) {
      super.showErrorSnack("First name is required");
      return;
    }
    if (!formValue.lastName) {
      super.showErrorSnack("Last name is required");
      return;
    }
    user.firstName = formValue["firstName"];
    user.lastName = formValue["lastName"];
    user.userId = `${user.firstName} ${user.lastName}`;
    this.dialogRef.close(user);
  }

  cancel() {
    this.dialogRef.close();
  }
}
