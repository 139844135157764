import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { APERuleFilterPrimaryClientPacket, BaseCrudService, Crud, DataResponse, PagedResponse } from "xf-common";

@Injectable()
export class ApeRuleFilterPrimaryClientService extends BaseCrudService<APERuleFilterPrimaryClientPacket> {

  public baseEndpoint = super.apiEndpoint("ape-rule-filter-primary-client");

  constructor(
    public override httpClient: HttpClient
  ) {
    super(httpClient, "ApeRuleFilterPrimaryClientService", "ApeRuleFilterPrimaryClient");
  }

  _list: Crud.ListFunction<APERuleFilterPrimaryClientPacket> = (first, max) => {
    const endpoint = `${this.baseEndpoint}/list`;
    return this.httpClient.put<PagedResponse<APERuleFilterPrimaryClientPacket[]>>(endpoint, { params: { first, max} })
  };

  _getById: Crud.GetByIdFunction<APERuleFilterPrimaryClientPacket> = () => {
    return EMPTY;
  };

  _create: Crud.CreateFunction<APERuleFilterPrimaryClientPacket> = (payload) => {
    const endpoint = `${this.baseEndpoint}/create`;
    return this.httpClient.post<DataResponse<APERuleFilterPrimaryClientPacket>>(endpoint, payload);
  };

  _update: Crud.UpdateFunction<APERuleFilterPrimaryClientPacket> = (payload) => {
    const endpoint = `${this.baseEndpoint}/create`;
    return this.httpClient.post<DataResponse<APERuleFilterPrimaryClientPacket>>(endpoint, payload);
  };

  _deactivate: Crud.DeleteFunction<APERuleFilterPrimaryClientPacket> = (id) => {
    const endpoint = `${this.baseEndpoint}/delete`;
    return this.httpClient.put<DataResponse<boolean>>(endpoint, id[0]);
  };

  _delete: Crud.DeleteFunction<APERuleFilterPrimaryClientPacket> = (id) => {
    const endpoint = `${this.baseEndpoint}/${id}`;
    return this.httpClient.delete<DataResponse<boolean>>(endpoint);
  };

  protected _crudFunctions: Crud.CrudServiceDef<APERuleFilterPrimaryClientPacket> = {
    _list: this._list,
    _getById: this._getById,
    _create: this._create,
    _update: this._update,
    _deactivate: this._deactivate,
    _delete: this._delete
  };

  getAPERuleFilterPrimaryClientListForAPERuleFilterId = (id:number, first:number, max:number): Observable<PagedResponse<any>> => {
    const endpoint = `${this.baseEndpoint}/list/${id}`;
    return this.httpClient.get<PagedResponse<APERuleFilterPrimaryClientPacket[]>>(endpoint, { params: { first, max} })
  };

  deleteAPERuleFilterPrimaryClient = (id:number) => {
    const endpoint = `${this.baseEndpoint}/${id}`;
    return this.httpClient.delete<DataResponse<boolean>>(endpoint);
  }
}
