import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APERuleFilter, APERuleFilterState, BaseService, DataResponse, Logger } from "xf-common";

@Injectable()
export class ApeRuleFilterStateService extends BaseService {
  public baseEndpoint: string = super.apiEndpoint("ape-rule-filter-state");

  constructor(
    protected httpClient: HttpClient
  ) {
    super(new Logger("ApeRuleFilterStateService"));
  }

  getApeRuleFilterStatesForApeRuleFilterId = (id: number): Observable<DataResponse<APERuleFilter[]>> => {
    const endpoint = `${this.baseEndpoint}/list/${id}`
    return this.httpClient.get<DataResponse<APERuleFilter[]>>(endpoint);
  }

  deleteApeRuleFilterState = (id: number): Observable<DataResponse<boolean>> => {
    const endpoint = `${this.baseEndpoint}/${id}`
    return this.httpClient.delete<DataResponse<boolean>>(endpoint);
  }

  createAPERule = (packet: APERuleFilterState): Observable<DataResponse<boolean>> => {
    const endpoint = `${this.baseEndpoint}/create`
    return this.httpClient.post<DataResponse<boolean>>(endpoint, packet);
  }

  deleteAllStatesForAPERuleFilterId = (id:number): Observable<DataResponse<boolean>> => {
    const endpoint = `${this.baseEndpoint}/delete-all/${id}`
    return this.httpClient.delete<DataResponse<boolean>>(endpoint);
  }

  addAllStatesForAPERuleFilterId = (id:number): Observable<DataResponse<boolean>> => {
    const endpoint = `${this.baseEndpoint}/add-all`
    return this.httpClient.post<DataResponse<boolean>>(endpoint, id);
  }
}
