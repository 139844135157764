import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges
} from '@angular/core';
import { SimpleObject } from "../../model/simple-object";

// Directive provided by https://medium.com/@anup.bangale/showing-tooltip-only-when-text-overflow-ellipsis-active-angular8-bd5e9d7667a9
@Directive({
  selector: '[appTruncateText]'
})
export class TruncateTextDirective implements AfterViewInit, OnChanges {
  domElement: any;

  @Input() appTruncateText?: any;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {
    this.domElement = this.elementRef.nativeElement;
    const ellipseCSS: SimpleObject = {
      'text-overflow': 'ellipsis',
      'overflow': 'hidden',
      'white-space': 'nowrap',
    };

    Object.keys(ellipseCSS).forEach(element=> {
      this.renderer.setStyle(
        this.domElement, `${element}`, ellipseCSS[element]
      );
    });
  }

  ngAfterViewInit(): void {
    this.renderer.setProperty(this.domElement, 'scrollTop', 1);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes["appTruncateText"]) {
      setTimeout(() => {
        this.setToolTip();
      });
    }
  }

  @HostListener("window:resize", ["$event.target"])
  setToolTip() {
    const tooltip = this.appTruncateText != null && this.appTruncateText != ''
      ? this.appTruncateText
      : this.domElement.textContent;
    this.renderer.setAttribute(
      this.domElement,
      'data-tooltip',
      ((this.appTruncateText != null && this.appTruncateText != '') || (this.domElement.offsetWidth < this.domElement.scrollWidth)
        ? tooltip
        : '')
    );
  }

}
