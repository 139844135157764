import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import {
  BaseService,
  CommentPacket,
  CommentRelationalMapMultiCreatePacket,
  CommentRelationalMapPacket,
  Logger
} from "xf-common";


@Injectable({
  providedIn: 'root'
})
export class CommentService extends BaseService {
  baseEndpoint = this.apiEndpoint("comment");

  constructor(
    protected override httpClient: HttpClient
  ) {
    super(new Logger("CommentService"));
  }

  getComments = (packet:{}, first:number, max:number): Observable<any> => {
    return this.httpClient.post(`${this.baseEndpoint}/list?first=${first}&max=${max}`, packet);
  }

  createComment = (packet:CommentRelationalMapPacket): Observable<any> => {
    return this.httpClient.post(`${this.baseEndpoint}/create`, packet);
  }

  editComment = (packet: CommentPacket): Observable<any> => {
    return this.httpClient.post(`${this.baseEndpoint}/update`, packet)
  }

  getCommentTypes = (commentRelationalType: string): Observable<any> => {
    return this.httpClient.get(`${this.baseEndpoint}/types?crt=${commentRelationalType}`);
  }

  deleteComment = (id:number): Observable<any> => {
    return this.httpClient.delete(`${this.baseEndpoint}/delete/${id}`);
  }

  createMultiComment = (packet:CommentRelationalMapMultiCreatePacket): Observable<any> => {
    return this.httpClient.post(`${this.baseEndpoint}/create-multiple`, packet);
  }
}
