import { AfterViewInit, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { DischargeStatusCodeService } from "@app/admin/discharge-status-code-admin/discharge-status-code.service";
import { KeycloakService } from "keycloak-angular";
import {
  DischargeStatusCodeDialogComponent
} from "@app/admin/discharge-status-code-admin/discharge-status-code-dialog/discharge-status-code-dialog.component";
import { FormControl, FormGroup } from '@angular/forms';
import {
  BaseCrudComponent,
  CRUDDeleteStatusChangeDialogComponent,
  DischargeStatusPacket,
  FennecSnackbarService,
  Logger,
  UserProfilePacket
} from "xf-common";


@Component({
  selector: 'app-discharge-status-code',
  templateUrl: './discharge-status-code-list.component.html',
  styleUrls: ['../../../../lib/styles/system-admin-styles.component.scss']
})
export class DischargeStatusCodeListComponent extends BaseCrudComponent<DischargeStatusPacket, DischargeStatusCodeService> implements AfterViewInit {

  log: Logger = new Logger("DischargeStatusCodeListComponent");
  displayedColumns = ["code", "description", "active", "actions"];

  dialogRef?: MatDialogRef<any>;

  @ViewChild("page", { read: ElementRef })
  element?: ElementRef;

  formGroup: FormGroup;
  filtersOpen: boolean = false;

  constructor(
    protected override snack: FennecSnackbarService,
    protected dischargeStatusService: DischargeStatusCodeService,
    protected dialog: MatDialog,
    private renderer: Renderer2,
    protected keycloakService: KeycloakService
  ) {
    super(dischargeStatusService);
    this.canCreate = keycloakService.isUserInRole("DISCHARGE_STATUS_CREATE");
    this.canUpdate = keycloakService.isUserInRole("DISCHARGE_STATUS_UPDATE");
    this.canDelete = keycloakService.isUserInRole("DISCHARGE_STATUS_DELETE");
    this.canDeactivate = keycloakService.isUserInRole("DISCHARGE_STATUS_DELETE");
    this.canView = keycloakService.isUserInRole("DISCHARGE_STATUS_VIEW");

    this.formGroup = this.createFormGroup();
  }

  ngOnInit() {
    this.executeListQuery();
  }

  ngAfterViewInit(): void {
    this.selection.changed.subscribe(change => this.selectedRow = change.added[0] ?? change.removed[0] ?? undefined);
    if (this.element?.nativeElement) {
      this.renderer.listen(this.element.nativeElement, "keyup", (event) => this.keyEvent(event));
    }
    if (this.paginator) {
      this.paginator.page.subscribe(() => {
        this.executeListQuery();
      })
    }
  }

  // executeListQuery() {
  //   const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
  //   const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;
  //   this.paginator.pageIndex = first;
  //   this.paginator.pageSize = pageSize;
  //   this.list();
  // }

  onCreate(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      mode: "Create"
    };
    dialogConfig.autoFocus = "first-tabbable";
    dialogConfig.width = "35%";
    this.dialogRef = this.dialog.open(DischargeStatusCodeDialogComponent, dialogConfig);
    this.dialogRef.afterClosed().subscribe((value?: DischargeStatusPacket) => {
      if (value) {
        this.create(value);
      }
    });
  }

  onDelete = (ids: number[]) => {
    if (ids.length == 1) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        mode: "delete",
        source: ids,
        label: "Discharge Status Code"
      };
      this.dialogRef = this.dialog.open(CRUDDeleteStatusChangeDialogComponent, dialogConfig);
      this.dialogRef.afterClosed().subscribe({
        next: (value?: number[]) => {
          if (value) {
            this.performXFRequest({
              requestDescription: "Delete discharge code status",
              requestFn: this.dischargeStatusService._delete,
              fnParams: [value],
              onSuccess: data => {
                super.showSuccessSnack("Successful!");
                this.list();
              },
              onError: errString => {
                super.showErrorSnack(errString);
              }
            })
          }
        }
      });
    }
  }

  openDischargeStatusCodeStatusChangeDialog(dischargeStatusPacket: DischargeStatusPacket) {
    if (dischargeStatusPacket) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        mode: "status",
        source: dischargeStatusPacket,
        label: "Discharge Status Code"
      };
      this.dialogRef = this.dialog.open(CRUDDeleteStatusChangeDialogComponent, dialogConfig);
      this.dialogRef.afterClosed().subscribe({
        next: (value?: DischargeStatusPacket) => {
          if (value) {
            this.performXFRequest({
              requestDescription: "UPDATE discharge code status",
              requestFn: this.dischargeStatusService.changeStatus,
              fnParams: [value],
              onSuccess: data => {
                super.showSuccessSnack("Successful!");
                this.list();
              },
              onError: errString => {
                super.showErrorSnack(errString);
              }
            })
          }
        }
      });
    }
  }

  onEdit = (id?: number) => {
    if (!id) {
      return;
    }
    this.getById(id)
      .then(response => {
        if (response && response.data) {
          this.openEditDialog(response.data)
        }
      })
      .catch(error => super.showErrorSnack(error));
  }

  onStatusChange = (id?: number) => {
    if (!id) {
      return;
    }
    this.getById(id)
      .then(response => {
        if (response && response.data) {
          this.openDischargeStatusCodeStatusChangeDialog(response.data)
        }
      })
      .catch(error => super.showErrorSnack(error));
  }

  keyEvent(event: KeyboardEvent) {
    if (this.filtersOpen) {
      return;
    }

    const selected = this.selection?.selected;

    switch (event.key) {
      case "c":
        this.onCreate();
        break;
      case "e":
        if (this.selectedRow) {
          this.onEdit(this.selectedRow.id);
        }
        break;
    }
  }

  onSelect(row: UserProfilePacket | undefined) {
    if (row == null) {
      return;
    }
    row.selected = !row.selected;

    this.selection?.toggle(row);
  }

  openEditDialog(dischargeStatusPacket: DischargeStatusPacket) {
    if (dischargeStatusPacket) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        mode: "Edit",
        dischargeStatus: dischargeStatusPacket
      };
      dialogConfig.autoFocus = "first-tabbable";
      dialogConfig.width = "35%";
      this.dialogRef = this.dialog.open(DischargeStatusCodeDialogComponent, dialogConfig);
      this.dialogRef.afterClosed().subscribe({
        next: (value?: UserProfilePacket) => {
          if (value) {
            this.update(value);
          }
        }
      });
    }
  }

  createFormGroup = () => {
    return new FormGroup({
      code: new FormControl(),
      description: new FormControl(),
      active: new FormControl(true)
    })
  }

  clearSearch = () => {
    this.formGroup = this.createFormGroup();
  }

  executeListQuery = () => {
    const controls = this.formGroup?.controls;

    const searchParams = {
      code: controls["code"].value?.trim() ?? null,
      description: controls["description"].value?.trim() ?? null,
      active: controls["active"].value,
    }

    if(searchParams.active === "all") {
      searchParams.active = null;
    }

    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;
    this.performXFRequest({
      requestDescription: "GET Discharge Status By Search Params",
      requestFn: this.dischargeStatusService.getDischargeStatusByParams,
      fnParams: [searchParams, first, pageSize],
      onResponse: response => {
        this.dataSource = response.data;
        this.totalRowCount = response['totalRowCount'];
      },
      onError: errString => {
        super.showErrorSnack(errString)
      }
    })
  }

  onSearch = () => {
    this.paginator?.firstPage();
    this.executeListQuery();
  }
}
