<div matDialogContent class="alt-theme">
  @if (mode == 'provider_address') {
    <mat-form-field style="width:100%;">
      <mat-label>Address Type</mat-label>
      <mat-select [(ngModel)]="providerAddressType">
        <mat-option 
          *ngFor="let type of providerAddressTypeOptions"
        [value]="type">{{ type }}</mat-option>
      </mat-select>
    </mat-form-field>
  }

  <app-address 
    #address
    [mode]="saveMode"
    [existingAddressId]="addressId"
    ></app-address>
</div>

<div matDialogActions>
  <button
    mat-raised-button
    color="accent"
    [disabled]="!isValid()"
    (click)="saveAddress()"
  >
    <mat-icon>save</mat-icon>
    Save
  </button>

  <button
    mat-stroked-button
    (click)="cancel()"
  >
    <mat-icon>cancel</mat-icon>
    Cancel
  </button>
</div>