import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {
  UB04StatusTrackBaseDialogComponent
} from '../ub04-status-track-base-dialog/ub04-status-track-base-dialog.component';
import { Ub04Service } from '../ub04.service';
import { BaseResponse } from "../../model/base-response";
import { UB04OccurrenceCodePacket } from "../../model/net/ub04-occurrence-code-packet";
import { DateUtil } from "../../util/date-util";
import { UB04OccurrenceCodeBulkPacket } from "../../model/net/ub04-occurrence-code-bulk-packet";
import { CodeValidationService } from "../../code/code-validation.service";
import { StatusTrackService } from "../../status-track/status-track.service";
import { FennecSnackbarService } from "../../dialog/fennec-snackbar/fennec-snackbar.service";
import { Logger } from '../../util/logger';



@Component({
  selector: 'app-ub04-occurrence-code-dialog',
  templateUrl: './ub04-occurrence-code-dialog.component.html',
  styleUrls: [
    './ub04-occurrence-code-dialog.component.scss',
    '../ub04-dialog-base-styles.scss'
]
})
export class Ub04OccurrenceCodeDialogComponent extends UB04StatusTrackBaseDialogComponent implements OnInit, AfterViewInit {
  override log = new Logger("Ub04OccurrenceCodeDialogComponent");
  formGroup!: FormGroup;
  ub04OccurrenceCodes: any [] = [];
  // ub04OccurrenceCodesWithThruDate: any[] = [];

  @ViewChild("page", { read: ElementRef })
  element?: ElementRef;

  constructor(
    private codeValidationService: CodeValidationService,
    public dialogRef: MatDialogRef<Ub04OccurrenceCodeDialogComponent>,
    private statusTrackService: StatusTrackService,
    override snack: FennecSnackbarService,
    private ub04Service: Ub04Service,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    super(snack, data.ub04Id, StatusTrackService.ELEMENT_UB04_EDIT_OCCURRENCE_CODE, statusTrackService);
    this.constructFormGroup();
  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
    this.fetchData();
  }

  getRowDisplayStyle(idx: number): any {
    let ocFormGroup: FormGroup = this.getOccurrenceCodeFormGroup(idx);
    if (ocFormGroup.controls["deleteRow"].value) {
      return {
        "background-color": "rgba(255, 0, 0, 0.32)"
      };
    } else {
      return {};
    }
  }

  getRowDisplayStyleDateRange(idx: number): any {
    let ocFormGroup: FormGroup = this.getOccurrenceCodeWithDateRangeFormGroup(idx);
    if (ocFormGroup.controls["deleteRow"].value) {
      return {
        "background-color": "rgba(255, 0, 0, 0.32)"
      };
    } else {
      return {};
    }
  }

  getIconStyle(idx: number){
    let rcFormGroup: FormGroup = this.getOccurrenceCodeFormGroup(idx);
    let color = 'green';

    if(!rcFormGroup.valid){
      color = 'orange'
    }

    if(rcFormGroup.controls["deleteRow"].value){
      color = 'red'
    }

    return {
      color
    }
  }

  getIconStyleDateRange(idx: number){
    let rcFormGroup: FormGroup = this.getOccurrenceCodeWithDateRangeFormGroup(idx);
    let color = 'green';

    if(!rcFormGroup.valid){
      color = 'orange'
    }

    if(rcFormGroup.controls["deleteRow"].value){
      color = 'red'
    }

    return {
      color
    }
  }

  getDeleteRowByIdx(idx: number): any {
    let rcFormGroup: FormGroup = this.getOccurrenceCodeFormGroup(idx);
    if (rcFormGroup.controls["deleteRow"].value) {
      return true;
    } else {
      return false;
    }
  }

  getDateRangeDeleteRowByIdx(idx: number): any {
    let rcFormGroup: FormGroup = this.getOccurrenceCodeWithDateRangeFormGroup(idx);
    if (rcFormGroup.controls["deleteRow"].value) {
      return true;
    } else {
      return false;
    }
  }

  getDeleteRowOrBlankValueByIdx(idx: number): any {
    let rcFormGroup: FormGroup = this.getOccurrenceCodeFormGroup(idx);
    if (rcFormGroup.controls["deleteRow"].value) {
      return true;
    }

    if(!rcFormGroup.controls["occurrenceCode"].value){
      return true;
    }

    return false;
  }

  getDateWidth(idx: number): number {
    let result = 240;
    if(idx > 3){
      result = 120;
    }
    return result;
  }

  constructFormGroup() {
    this.formGroup = new FormGroup({
      occurrenceCodes: new FormArray([]),
      occurrenceCodesWithDateRange: new FormArray([]),
    });
  }

  get occurrenceCodes(): FormArray {
    return this.formGroup.controls["occurrenceCodes"] as FormArray;
  }

  get occurrenceCodesWithDateRange(): FormArray {
    return this.formGroup.controls["occurrenceCodesWithDateRange"] as FormArray;
  }

  getOccurrenceCodeFormGroup(idx: number): FormGroup {
    return this.occurrenceCodes.at(idx) as FormGroup;
  }

  getOccurrenceCodeWithDateRangeFormGroup(idx: number): FormGroup {
    return this.occurrenceCodesWithDateRange.at(idx) as FormGroup;
  }

  populateOccurrenceCodesOnFormGroup() {
    // if (this.ub04OccurrenceCodes.length <= 0) {
    //   let idx = 0;
    //   for (idx = 0; idx < 6; idx++) {
    //     this.onAddOccurrenceCodeLine();
    //   }
    //   return;
    // }

    let occurrenceCodes = this.occurrenceCodes;
    occurrenceCodes.clear();

    let thruDatesNeeded = [];

    let idx = 0;
    for (idx = 0; idx < this.ub04OccurrenceCodes.length; idx++) {
      let oc = this.ub04OccurrenceCodes[idx];
      let ocFormGroup: FormGroup = new FormGroup({
        id: new FormControl(oc.id),
        occurrenceCode: new FormControl(oc.occurrenceCode, [Validators.required, Validators.maxLength(4)]),
        description: new FormControl(oc.occurrenceCodeDescription),
        fromDate: new FormControl(DateUtil.fennecDBStringToInputDateString(oc.fromDate), [Validators.pattern('^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\\d\\d$')]),
        tempKey: new FormControl(this.codeValidationService.createUUID()),
        deleteRow: new FormControl(false),
      });

      if(oc.thruDate){
        ocFormGroup.addControl("thruDate", new FormControl(DateUtil.fennecDBStringToInputDateString(oc.thruDate), [Validators.pattern('^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\\d\\d$')]));
      }

      ocFormGroup.controls['description'].disable();
      // Chatty Occurrence code validation during data entry
      ocFormGroup.controls['occurrenceCode'].valueChanges.subscribe((val) => {
        this.validateOccurrenceCode(ocFormGroup, val);
      });

      if(oc.thruDate){
        this.occurrenceCodesWithDateRange.push(ocFormGroup);
      }else{
        this.occurrenceCodes.push(ocFormGroup);
      }
    }

    // This loop ensures there is always 6 fields, either with or without data, and displays existing data with thruDate at the bottom
    // for(let i = occurrenceCodes.length; i < 6; i++){
    //   if(i < 4 && occurrenceCodes.at(i) == null){
    //     this.onAddOccurrenceCodeLine();
    //   }

    //   if(i >= 4){
    //     if(thruDatesNeeded[i - 4]){
    //       occurrenceCodes.push(thruDatesNeeded[i - 4]);
    //     }else{
    //       this.onAddOccurrenceCodeLine();
    //     }
    //   }
    // }

  }

  onAddOccurrenceCodeLine(){
    let newFormGroup: FormGroup = new FormGroup({
      id: new FormControl(-1),
      occurrenceCode: new FormControl("", [Validators.maxLength(4)]),
      description: new FormControl(""),
      fromDate: new FormControl("", [Validators.pattern('^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\\d\\d$')]),
      tempKey: new FormControl(this.codeValidationService.createUUID()),
      deleteRow: new FormControl(false),
    }, {
      // updateOn: 'blur'
    });

    if(this.occurrenceCodes.length + 1 >= 4) {
      newFormGroup.addControl("thruDate", new FormControl("", [Validators.pattern('^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\\d\\d$')]));
    }

    newFormGroup.controls['description'].disable();
    newFormGroup.controls['occurrenceCode'].valueChanges.subscribe((val) => {
      this.validateOccurrenceCode(newFormGroup, val);
    });
    this.occurrenceCodes.push(newFormGroup);
  }

  onAddOccurrenceCodeLineWithDateRange() {
    let newFormGroup: FormGroup = new FormGroup({
      id: new FormControl(-1),
      occurrenceCode: new FormControl("", [Validators.maxLength(4)]),
      description: new FormControl(""),
      fromDate: new FormControl("", [Validators.pattern('^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\\d\\d$')]),
      thruDate: new FormControl("", [Validators.pattern('^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\\d\\d$')]),
      tempKey: new FormControl(this.codeValidationService.createUUID()),
      deleteRow: new FormControl(false),
    }, {
      // updateOn: 'blur'
    });


    // newFormGroup.addControl("thruDate", new FormControl("", [Validators.pattern('^(19|20)\\d\\d[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])$')]));

    newFormGroup.controls['description'].disable();
    newFormGroup.controls['occurrenceCode'].valueChanges.subscribe((val) => {
      this.validateOccurrenceCode(newFormGroup, val);
    });
    this.occurrenceCodesWithDateRange.push(newFormGroup);
  }

  validateOccurrenceCode(row: FormGroup, value: any) {
    let ccFormControl: FormControl = row.controls['occurrenceCode'] as FormControl;
    let descriptionFormControl: FormControl = row.controls['description'] as FormControl;
    let fromDateControl: FormControl = row.controls['fromDate'] as FormControl;
    let thruDateControl: FormControl | null = row?.controls['thruDate'] as FormControl;

    if (!ccFormControl.pristine) {
      if (fromDateControl.value.length > 0) {
        fromDateControl.addValidators(Validators.required);
        fromDateControl.updateValueAndValidity();
      } else {
        fromDateControl.removeValidators(Validators.required);
      }
      if (thruDateControl) {
        if (thruDateControl.value.length > 0) {
          thruDateControl.addValidators(Validators.required);
          thruDateControl.updateValueAndValidity();
        } else {
          thruDateControl.removeValidators(Validators.required);
        }
      }

      if (ccFormControl.value.length < 2 ||  ccFormControl.value.length > 4) {
        ccFormControl.markAsPristine();
        descriptionFormControl.setValue("Invalid", {onlySelf: true, emitEvent: false});
        ccFormControl.setErrors({'incorrect': true});
      } else {
        this.codeValidationService.getOccurrenceCode(ccFormControl.value).subscribe((response: BaseResponse) => {
          if (response.hasErrors) {
            ccFormControl.markAsPristine();
            ccFormControl.setErrors({'incorrect': true});
          } else {
            if (response.data !== null) {
              ccFormControl.markAsPristine();
              ccFormControl.setValue(response.data.code, {onlySelf: true, emitEvent: false});
              descriptionFormControl.setValue(response.data.description, {onlySelf: true, emitEvent: false});
              ccFormControl.setErrors(null);
            } else {
              descriptionFormControl.setValue("Invalid");
              ccFormControl.markAsPristine();
              ccFormControl.setErrors({'incorrect': true});

            }
          }
        });

      }
    }
  }

  findControlUsingTempKey(tempKey: string): FormGroup {
    let ctrl: FormGroup = new FormGroup({});
    let idx = 0;
    for (idx = 0; idx < this.occurrenceCodes.length; idx++) {
      let rc: FormGroup = this.getOccurrenceCodeFormGroup(idx);
      if (rc.controls['tempKey'].value === tempKey) {
        ctrl = rc;
        break;
      }
    }
    for (idx = 0; idx < this.occurrenceCodesWithDateRange.length; idx++) {
      let rc: FormGroup = this.getOccurrenceCodeWithDateRangeFormGroup(idx);
      if (rc.controls['tempKey'].value === tempKey) {
        ctrl = rc;
        break;
      }
    }
    return ctrl;
  }

  fetchData() {
    this.ub04Service.getUb04OccurrenceCodes(this.data.ub04Id).subscribe(response => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.ub04OccurrenceCodes = response.data;
        this.populateOccurrenceCodesOnFormGroup();
      }
    });
  }

  onDelete(idx: number) {
    let rfg: FormGroup = this.getOccurrenceCodeFormGroup(idx);
    if (rfg.controls["id"].value < 0) {
      this.occurrenceCodes.removeAt(idx);
    } else {
      rfg.controls["deleteRow"].setValue(!rfg.controls["deleteRow"].value);
    }
  }

  onDeleteDateRange(idx: number) {
    let rfg: FormGroup = this.getOccurrenceCodeWithDateRangeFormGroup(idx);
    if (rfg.controls["id"].value < 0) {
      this.occurrenceCodesWithDateRange.removeAt(idx);
    } else {
      rfg.controls["deleteRow"].setValue(!rfg.controls["deleteRow"].value);
    }
  }

  onCancel() {
    const returnObj = {
      confirm: false
    }
    this.dialogRef.close(returnObj);
  }

  onSubmit() {
    this.formGroup.markAllAsTouched();
    let updDto: UB04OccurrenceCodeBulkPacket = new UB04OccurrenceCodeBulkPacket();
    updDto.ub04Id = parseInt(this.data.ub04Id, 10);

    // Populates DTO with Single Date Occurrence Codes
    let ocRows: FormArray = this.occurrenceCodes;
    ocRows.controls.forEach((ocRow: any) => {
      if(ocRow.controls["occurrenceCode"].value != ""){
        let ocDto: UB04OccurrenceCodePacket = new UB04OccurrenceCodePacket();
        ocDto.id = ocRow.controls["id"].value;
        ocDto.ub04Id = parseInt(this.data.ub04Id, 10);
        ocDto.occurrenceCode = ocRow.controls["occurrenceCode"].value;
        ocDto.fromDate = DateUtil.inputDateStringToFennecDBDateString(ocRow.controls["fromDate"].value) ?? undefined;
        ocDto.deleteRow = ocRow.controls["deleteRow"].value;
        updDto.ub04OccurrenceCodes.push(ocDto);
      }
    });

    // Populates DTO with Date Range Occurrence Codes
    ocRows = this.occurrenceCodesWithDateRange
    ocRows.controls.forEach((ocRow: any) => {
      if(ocRow.controls["occurrenceCode"].value != ""){
        let ocDto: UB04OccurrenceCodePacket = new UB04OccurrenceCodePacket();
        ocDto.id = ocRow.controls["id"].value;
        ocDto.ub04Id = parseInt(this.data.ub04Id, 10);
        ocDto.occurrenceCode = ocRow.controls["occurrenceCode"].value;
        ocDto.fromDate = DateUtil.inputDateStringToFennecDBDateString(ocRow.controls["fromDate"].value) ?? undefined;
        ocDto.thruDate = DateUtil.inputDateStringToFennecDBDateString(ocRow.controls["thruDate"].value) ?? undefined;
        ocDto.deleteRow = ocRow.controls["deleteRow"].value;
        updDto.ub04OccurrenceCodes.push(ocDto);
      }
    });

    this.ub04Service.putUb04OccurrenceCodesBulkUpdate(updDto).subscribe(response => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        const returnObj = {
          confirm: true,
        }
        this.dialogRef.close(returnObj);
        this.snack.showSuccessSnack(`Successfully Updated!`);
      }
    });
  }

  keyEvent(event: KeyboardEvent) {
    switch (event.key) {
      case "Escape":
        this.onCancel();
        break;
      case "n":
        this.onAddOccurrenceCodeLine();
        break;
    }
  }
}
