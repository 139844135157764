import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseCrudService, Crud, DataResponse, OccurrenceCodePacket, PagedResponse } from "xf-common";


@Injectable()
export class OccurrenceCodeService extends BaseCrudService<OccurrenceCodePacket> {
    baseEndpoint = super.apiEndpoint("occurrence-code")

    constructor(public override httpClient: HttpClient) {
        super(httpClient, "OccurrenceCodeService", "OccurrenceCode");
    }

    _list: Crud.ListFunction<OccurrenceCodePacket> = (first, max) => {
        const endpoint = `${this.baseEndpoint}/list`
        return this.httpClient.get<PagedResponse<OccurrenceCodePacket[]>>(endpoint, {params: {first, max}})
    }

    _getById: Crud.GetByIdFunction<OccurrenceCodePacket> = (id) => {
        const endpoint = `${this.baseEndpoint}/${id}`
        return this.httpClient.get<PagedResponse<OccurrenceCodePacket>>(endpoint)
    }

    _create: Crud.CreateFunction<OccurrenceCodePacket> = (user) => {
        const endpoint = `${this.baseEndpoint}/create`
        return this.httpClient.post<PagedResponse<OccurrenceCodePacket>>(endpoint, user)
    }

    _update: Crud.UpdateFunction<OccurrenceCodePacket> = (user): Observable<DataResponse<OccurrenceCodePacket>> => {
        const endpoint = `${this.baseEndpoint}/update`
        return this.httpClient.put<PagedResponse<OccurrenceCodePacket>>(endpoint, user)
    }

    _deactivate: Crud.DeleteFunction<OccurrenceCodePacket> = (ids) => {
        const endpoint = `${this.baseEndpoint}/deactivate`
        return this.httpClient.put<DataResponse<boolean>>(endpoint, ids[0])
    }

    _delete: Crud.DeleteFunction<OccurrenceCodePacket> = (ids) => {
        const endpoint = `${this.baseEndpoint}/delete/` + ids
        return this.httpClient.delete<DataResponse<boolean>>(endpoint)

    }
    changeStatus = (occurrenceCodePacket: OccurrenceCodePacket): Observable<DataResponse<OccurrenceCodePacket>> => {
        const endpoint = `${this.baseEndpoint}/status`;
        return this.httpClient.put<DataResponse<OccurrenceCodePacket>>(endpoint, occurrenceCodePacket);
    }


    protected _crudFunctions: Crud.CrudServiceDef<OccurrenceCodePacket> = {
        _list: this._list,
        _getById: this._getById,
        _create: this._create,
        _update: this._update,
        _deactivate: this._deactivate,
        _delete: this._delete
    }

    getOccurrenceCodesByParams = (packet:any, first:number, max:number) => {
        const endpoint = `${this.baseEndpoint}/list?first=${first}&max=${max}`;
        return this.httpClient.post<PagedResponse<OccurrenceCodePacket>>(endpoint, packet);
      }
}
