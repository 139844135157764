import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AdjustmentReasonCodePacket, BaseComponent, FennecSnackbarService, Logger } from "xf-common";
import { AdjustmentReasonCodeService } from '../adjustment-reason-code.service';

@Component({
  selector: 'app-adjustment-reason-code-dialog',
  templateUrl: './adjustment-reason-code-dialog.component.html',
  styleUrls: ['../../../../lib/styles/system-admin-styles.component.scss']
})
export class AdjustmentReasonCodeDialogComponent extends BaseComponent implements OnInit {
  protected log = new Logger("AdjustmentReasonCodeDialogComponent");
  mode?: "create" | "edit" | "view";
  adjustmentReasonCode?: AdjustmentReasonCodePacket;
  formGroup: FormGroup;

  adjustmentReasonTypes: string[] = null;

  constructor(
    protected dialog: MatDialog,
    protected adjustmentReasonCodeService: AdjustmentReasonCodeService,
    protected dialogRef: MatDialogRef<AdjustmentReasonCodeDialogComponent>,
    override snack: FennecSnackbarService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    super();
    this.mode = this.data?.mode;
    this.adjustmentReasonCode = this.data?.adjustmentReasonCode;
    this.formGroup = new FormGroup({
      code: new FormControl(this.adjustmentReasonCode?.code ?? "", Validators.required),
      description: new FormControl(this.adjustmentReasonCode?.description ?? "", [Validators.required, Validators.maxLength(1000)]),
      shortDescription: new FormControl(this.adjustmentReasonCode?.shortDescription ?? "", [Validators.required, Validators.maxLength(100)]),
      visibleOnDashboard: new FormControl(this.adjustmentReasonCode?.visibleOnDashboard ?? false, [Validators.required]),
      internal: new FormControl(this.adjustmentReasonCode?.internal ?? false, Validators.required),
      adjustmentReasonType: new FormControl(this.adjustmentReasonCode?.adjustmentReasonType ?? "", Validators.required)
    });

    this.adjustmentReasonTypes = this.data?.adjustmentReasonTypes ?? null;
  }

  ngOnInit(): void {
    if (this.adjustmentReasonTypes == null) {
      this.getAdjustmentReasonTypes();
    }
  }

  submit() {
    const adjustmentReasonCode: AdjustmentReasonCodePacket = this.adjustmentReasonCode ?? new AdjustmentReasonCodePacket();
    const formValue = this.formGroup.value;
    if (!formValue.code) {
      super.showErrorSnack("Code is required");
      return;
    }
    if (!formValue.description) {
      super.showErrorSnack("Description required");
      return;
    }
    adjustmentReasonCode.code = formValue["code"];
    adjustmentReasonCode.description = formValue["description"];
    adjustmentReasonCode.shortDescription = formValue["shortDescription"];
    adjustmentReasonCode.visibleOnDashboard = formValue["visibleOnDashboard"];
    adjustmentReasonCode.internal = formValue["internal"];
    adjustmentReasonCode.adjustmentReasonType = formValue["adjustmentReasonType"];
    this.dialogRef.close(adjustmentReasonCode);
  }
  cancel() {
    this.dialogRef.close();
  }

  getAdjustmentReasonTypes = () => {
    this.performXFRequest({
      requestDescription: "GET adjustment reason type",
      requestFn: this.adjustmentReasonCodeService.getAdjustmentReasonTypes,
      fnParams: [],
      onSuccess: data => {
        // super.showSuccessSnack("Successful!");
        // this.executeListQuery();
        this.adjustmentReasonTypes = data;

        if (this.formGroup.controls["adjustmentReasonType"].value == "") {
          this.formGroup.controls["adjustmentReasonType"].setValue(this.adjustmentReasonTypes[0]);
        }
      },
      onError: errString => {
        super.showErrorSnack(errString);
      }
    })
  }

}
