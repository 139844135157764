import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { BaseComponent, FennecSnackbarService, Logger } from 'xf-common';

@Component({
  selector: 'app-activity-log-list-sub-dialog',
  templateUrl: './activity-log-list-sub-dialog.component.html',
  styleUrls: ['./activity-log-list-sub-dialog.component.scss']
})
export class ActivityLogListSubDialogComponent extends BaseComponent implements OnInit {

  protected log = new Logger("ActivityLogListSubDialogComponent");

  // beforeData:{} = {};
  // afterData:{} = {};

  beforeData:any[] = [];
  afterData:any[] = [];


  constructor(
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<ActivityLogListSubDialogComponent>,
    override snack: FennecSnackbarService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    super();
    this.formatData(data);
  }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }

  formatData = (data:any) => {
    let beforeData;
    let afterData;
    if(data.beforeData) {
      beforeData = JSON.parse(data.beforeData);
    }

    if(data.afterData) {
      afterData = JSON.parse(data.afterData);
    }

    let beforeDataList = [];
    let afterDataList = [];
    for(let key in beforeData) {
      const text = key;
      const result = text.replace(/([A-Z])/g, " $1");
      const formattedKey = result.charAt(0).toUpperCase() + result.slice(1);

      let changed = false;
      if(beforeData) {

        if(afterData && afterData[key] && afterData[key] !== beforeData[key]) {
          changed = true;
        }

        beforeDataList.push({
          key:formattedKey,
          value:beforeData[key] ?? "Null",
          changed
        })
      }

      if(afterData) {
        afterDataList.push({
          key:formattedKey,
          value:afterData[key] ?? "Null",
          changed
        });
      }
    }

    this.beforeData = beforeDataList;
    this.afterData = afterDataList;
  }


  getStyle = (data:any) => {
    if(data.changed) {
      return {"color":"red"}
    }
    return {"color":"black"}
  }
}
