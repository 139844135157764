import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { BaseComponent, FennecSnackbarService, Logger, TaxonomyCodePacket } from 'xf-common';


@Component({
  selector: 'app-taxonomy-code-dialog',
  templateUrl: './taxonomy-code-dialog.component.html',
  styleUrls: ['./taxonomy-code-dialog.component.scss']
})
export class TaxonomyCodeDialogComponent extends BaseComponent implements OnInit {

  mode?: "Create" | "Edit" | "View";
  taxonomyCode?: TaxonomyCodePacket;
  formGroup: FormGroup;

  protected log = new Logger("TaxonomyCodeDialogComponent");

  constructor(
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<TaxonomyCodeDialogComponent>,
    override snack: FennecSnackbarService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    super();
    this.mode = this.data?.mode;
    this.taxonomyCode = this.data?.taxonomyCode;
    this.formGroup = new FormGroup({
      code: new FormControl(this.taxonomyCode?.code ?? "", [Validators.required, Validators.maxLength(40)]),
      description: new FormControl(this.taxonomyCode?.description ?? "", [Validators.required, Validators.maxLength(100)]),
      definition: new FormControl(this.taxonomyCode?.description ?? "", [Validators.required, Validators.maxLength(2000)]),
    });
  }

  ngOnInit(): void {
  }

  submit() {
    const taxonomyCode: TaxonomyCodePacket = this.taxonomyCode ?? new TaxonomyCodePacket();
    const formValue = this.formGroup.value;
    if (!formValue.code) {
      super.showErrorSnack("Code is required");
      return;
    }
    if (!formValue.description) {
      super.showErrorSnack("Description required");
      return;
    }
    taxonomyCode.code = formValue["code"];
    taxonomyCode.description = formValue["description"];
    taxonomyCode.definition = formValue["definition"];
    this.dialogRef.close(taxonomyCode);
  }

  cancel() {
    this.dialogRef.close();
  }

}
