import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ItemizedService } from "xf-common";


@Component({
  selector: 'app-itemized-edit-dialog',
  templateUrl: './itemized-edit-dialog.component.html',
  styleUrls: ['./itemized-edit-dialog.component.scss']
})
export class ItemizedEditDialogComponent implements OnInit {
  form!: FormGroup;
  itemized: any;
  // itemized = {itemizedId: undefined, externalAltId: undefined};
  isLoading = false;
  errorMessage!: string;

  constructor(
    public dialogRef: MatDialogRef<ItemizedEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private itemizedService: ItemizedService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.itemized = this.data.itemized;
    if (this.itemized === null || this.itemized === undefined) {
      this.data.itemized = {
        externalAltId: 'Unknown'
      };
    }

    this.form = this.fb.group({
      // Initialize form controls here
      externalAltId: [this.itemized?.externalAltId, Validators.required]
    });
  }

  onSave() {
    // Make sure all of the form controls update the class level itemized, so we can pass it
    // back to the caller.
    this.itemized['externalAltId'] = this.form.value?.externalAltId;

    let dialogResult = {
      confirm: true,
      itemized: this.itemized
    };
    this.dialogRef.close(dialogResult);

  }

  onCancel() {
    let dialogResult = {
      confirm: false
    };
    this.dialogRef.close(dialogResult);
  }

}
