import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute } from '@angular/router';
import { MICaseUsersService } from './mi-case-users.service';
import { BaseComponent, FennecSnackbarService, Logger, SingleChoiceDialogComponent } from "xf-common";

@Component({
  selector: 'app-mi-case-users',
  templateUrl: './mi-case-users.component.html',
  styleUrls: ['./mi-case-users.component.scss']
})

export class MiCaseUsersComponent extends BaseComponent implements OnInit {
  log: Logger = new Logger("MiCaseUsersComponent");

  @Input()
  miCaseId = "-1";

  dataSource = new MatTableDataSource<any>();
  displayColumns = ['name', 'type', 'actions'];
  userList: any [] = [];
  caseUserTypeList: any [] = [];

  dialogRef?: MatDialogRef<any>;

  constructor(
    protected snack: FennecSnackbarService,
    private miCaseUsersService: MICaseUsersService,
    protected route: ActivatedRoute,
    protected dialog: MatDialog
  ) {
    super();
    this.miCaseId = this.route.parent?.snapshot.paramMap.get("miCaseId") ?? "-1";
  }

  ngOnInit() {
    this.getAllCaseUserTypes();
    this.getUsersForCase()
  }

  getAllCaseUserTypes() {
    this.miCaseUsersService.getAllCaseUserTypes().subscribe((response: any) => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.caseUserTypeList = response.data
      }
    });
  }

  getUsersForCase() {
    this.miCaseUsersService.getCaseUsers(parseInt(this.miCaseId)).subscribe((response: any) => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.dataSource.data = response.data
      }
    });
  }

  isRemovalAllowedFromMICase(miCaseUserType: string): boolean {
    var isRemovalAllowed = false;
    this.caseUserTypeList.forEach((ut) => {
      if (ut.miCaseUserType === miCaseUserType) {
        isRemovalAllowed = ut.removalAllowedFromMICase;
      }
    });
    return isRemovalAllowed;
  }

  removeUser(c: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      question:`Are you sure you want to remove user ${c.name} with type ${c.type} from the case?`
    };
    this.dialogRef = this.dialog.open(SingleChoiceDialogComponent, dialogConfig);
    this.dialogRef.afterClosed().subscribe((result) => {
      if (result?.confirm) {
        this.miCaseUsersService.deleteCaseUser(c.id).subscribe((response: any) => {
          if (response.hasErrors) {
            super.showErrorSnack("Error processing delete request");
          } else {
            super.showSuccessSnack("Delete successful!");
            this.getUsersForCase();
          }
        });
      }
    });
  }
}
