import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { BaseComponent, FennecSnackbarService, Logger } from 'xf-common';
import { ActivityDiaryService } from '../activity-diary.service';

@Component({
  selector: 'app-activity-diary-dialog',
  templateUrl: './activity-diary-dialog.component.html',
  styleUrls: ['./activity-diary-dialog.component.scss']
})
export class ActivityDiaryDialogComponent extends BaseComponent implements OnInit {

  log: Logger = new Logger("ActivityDiaryDialogComponent");

  constructor(
  protected snack: FennecSnackbarService,
  private acivityDiaryService: ActivityDiaryService,
  @Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<ActivityDiaryDialogComponent>
  ) {
    super();
  }

  ngOnInit(): void {
  }

  onClose() {
    this.dialogRef.close();
  }

}
