import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UserUpdateFormComponent } from './user-update-form/user-update-form.component';
import { UserProfileService } from "../user/user-profile.service";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  AddressComponent,
  BaseComponent,
  DataResponse,
  FennecSnackbarService,
  Logger,
  UserProfilePacket
} from "xf-common";


@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent extends BaseComponent implements OnInit, AfterViewInit {
  log = new Logger("UserProfileComponent");

  firstName?: string;
  lastName?: string;
  id?: number;
  addressId?: number;
  userId?: string;
  formGroup: FormGroup;

  userMICaseVisibilityLevels: string [] = [];

  @ViewChild('addressComponent')
  addressComponent?: AddressComponent;

  constructor(
    protected snack: FennecSnackbarService,
    private userProfileService: UserProfileService,
    protected matDialog: MatDialog
  ) {
    super();
    this.formGroup = this.getFormGroupFromPacket({});
  }

  ngAfterViewInit(): void {
    if (this.addressComponent !== null && this.addressComponent !== undefined) {
      this.addressComponent.addressSaveComplete.subscribe((addressId) => {
        this.addressId = addressId;
        this.addressComponent!.addressId = addressId;
        this.saveUserProfile();
      });
    }
    this.userProfileService.getUserMICaseVisibilityLevels().subscribe((response: any) => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.userMICaseVisibilityLevels = response.data;
      }
    });
  };

  ngOnInit(): void {
    this.userProfileService.getUserProfile().subscribe((response: DataResponse<UserProfilePacket>) => {
      if (response.hasErrors) {
        alert(response.consolidatedErrorMessage);
      } else {
        this.id = response.data?.id;
        this.userId = response.data?.userId;
        this.addressId = response.data?.addressId;
        this.formGroup = this.getFormGroupFromPacket(response.data);
        this.addressComponent?.getAddressById(this.addressId);
      }
    });

  }

  getFormGroupFromPacket(packet: any) {
    return new FormGroup({
      firstName: new FormControl(packet?.firstName ?? "", [Validators.required, Validators.maxLength(50)]),
      lastName: new FormControl(packet?.lastName ?? "", [Validators.required, Validators.maxLength(50)])
    });
  }

  openUserProfileUpdateDialog() {
    this.addressComponent?.saveAddress.next(null);

    const data = {
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName
    }
    const dialogRef = this.matDialog.open(UserUpdateFormComponent, { data: data });
    dialogRef.afterClosed().subscribe(
      response => {
        if (response !== undefined) {
          this.firstName = response.firstName;
          this.lastName = response.lastName;
        }
      }
    )
  }

  onSave() {
    this.addressComponent?.saveAddress.next(null);
  }

  saveUserProfile() {
    const formValue = this.formGroup.value;
    const userProfile = new UserProfilePacket().deserialize({
      id: this.id,
      userId: this.userId,
      firstName: formValue.firstName,
      lastName: formValue.lastName,
      addressId: this.addressId
    });

    this.performXFRequest({
      requestDescription: "Update user profile",
      requestFn: this.userProfileService._update,
      fnParams: [userProfile],
      onResponse: response => {
        super.showSuccessSnack("Save Successful");
      },
      onError: message => super.showErrorSnack(message)
    });
  }

  isValid() {
    return this.addressComponent?.isValid() && this.formGroup?.valid
  }
}
