import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { EdiMicaseDialogComponent } from './edi-micase-dialog/edi-micase-dialog.component';
import { SingleChoiceDialogComponent } from 'xf-common';
import { DateUtil } from 'xf-common';
import { EdiRunAttachmentDialogComponent } from './edi-run-attachment-dialog/edi-run-attachment-dialog.component';
import { EdiRunCreateComponent } from './edi-run-create/edi-run-create.component';
import { EdiRunService } from './edi-run.service';
import { EdiActivityDiaryDialogComponent } from './edi-activity-diary-dialog/edi-activity-diary-dialog.component';
import { FennecSnackbarService } from 'xf-common';

@Component({
  selector: 'app-edi-run',
  templateUrl: './edi-run.component.html',
  styleUrls: ['./edi-run.component.scss', '../../lib/styles/system-admin-styles.component.scss']
})
export class EdiRunComponent implements AfterViewInit, OnInit {

  @Input()
  dialogRef?: MatDialogRef<any>;
  ediRunList = new MatTableDataSource<any>()
  displayColumns = ['ediRunId', 'status', 'createdDate', 'createdBy', 'actions'];

  @ViewChild(MatPaginator)
  paginator?: MatPaginator;

  totalRowCount?: number;
  defaultPageSize = 20;
  pageSizeOptions = [5, 10, 20, 25, 50];

  constructor(
    protected matDialog: MatDialog,
    protected snack: FennecSnackbarService,
    private ediRunService: EdiRunService,
    ) { }

  ngOnInit() {
    this.getAllEDIRunList();
  }

  ngAfterViewInit() {
    if (this.paginator) {
      this.paginator.page.subscribe(() => {
        this.getAllEDIRunList();
      })
    }
  }

  getAllEDIRunList() {
    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;
    this.ediRunService.getAllEDIRunList(first, pageSize).subscribe((response: any) => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.ediRunList.data = response.data;
        this.totalRowCount = response.totalRowCount;
      }
    })
  }

  onCreateEDIRun() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "40%";
    this.dialogRef = this.matDialog.open(EdiRunCreateComponent, dialogConfig);
    this.dialogRef.afterClosed().subscribe( response => {
      if (response.confirm) {
        this.getAllEDIRunList();
      }
    });
  }


  openCaseDialog(caseId: any) {
    const matDialogConfig = new MatDialogConfig();

    matDialogConfig.panelClass = "dialog-responsive";
    matDialogConfig.height = "auto";
    matDialogConfig.width = "1000px";
    matDialogConfig.data = caseId
    const dialogRef = this.matDialog.open(EdiMicaseDialogComponent, matDialogConfig);
  }

  openDiaryDialog(diaryId: any) {
    const matDialogConfig = new MatDialogConfig();

    matDialogConfig.panelClass = "dialog-responsive";
    matDialogConfig.height = "auto";
    matDialogConfig.width = "1000px";
    matDialogConfig.data = diaryId
    const dialogRef = this.matDialog.open(EdiActivityDiaryDialogComponent, matDialogConfig);
  }

  viewAttachmentDialog(ediRunId: number) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "60%";
    dialogConfig.data = {
      ediRunId:ediRunId,
    }
    this.dialogRef = this.matDialog.open(EdiRunAttachmentDialogComponent, dialogConfig);
    this.dialogRef.afterClosed().subscribe(response => {
    })
  }

  formatDate(dateString:string) {
    return DateUtil.getDisplayDate(dateString);
  }

  processEDIRun(id: number): void {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      question : `Process EDI Run ID: ${id}?`,
    };
    const dialogRef = this.matDialog.open(SingleChoiceDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {
        this.ediRunService.processEDIRun(id).subscribe(result => {
          if (result.hasErrors) {
            this.snack.showErrorSnack("Error processing EDI Run");
          } else {
            this.snack.showWorkerSubmitSnack("EDI Run Initiated");
          }
          this.getAllEDIRunList();
        });
      }
    });
  }

  getFormattedStatus(ediRun: any):string {
    const status = ediRun.status;
    let result = "Created";

    if(status) {
      result = status[0] + status.slice(1).toLowerCase();
    }

    return result;
  }
}
