import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute } from '@angular/router';
import {
  CompanyRelationalTypeAddComponent
} from '@app/company/company-relational-type-add/company-relational-type-add.component';
import {
  BaseComponent,
  CompanyRelationalTypeCreatePacket,
  CompanyService,
  FennecSnackbarService,
  Logger,
  SingleChoiceDialogComponent
} from 'xf-common';

@Component({
  selector: 'app-tenant-company-list',
  templateUrl: './tenant-company-list.component.html',
  styleUrls: ['./tenant-company-list.component.scss', '../../../lib/styles/system-admin-styles.component.scss']
})
export class TenantCompanyListComponent extends BaseComponent implements OnInit, AfterViewInit {

  log: Logger = new Logger("TenantCompanyListComponent");

  dataSource = new MatTableDataSource<any>();
  displayColumns = ['name', 'companytype', 'active', 'activateactions', 'delete'];
  tenantColumns = ['name', 'companytype', 'active', 'activateactions', 'delete'];
  userProfileColumns = ['name', 'companytype'];

  @ViewChild(MatPaginator)
  paginator?: MatPaginator;

  totalRowCount?: number;
  defaultPageSize = 1000;
  pageSizeOptions = [10, 20, 25, 50, 1000];

  // Modes: TENANT, USER_PROFILE
  @Input()
  mode: string = "TENANT"

  @Input()
  relatedId: string = "-1"

  constructor(
    protected activeRoute: ActivatedRoute,
    protected snack: FennecSnackbarService,
    protected companyService: CompanyService,
    public matDialog: MatDialog,
  ) {
    super();
  }

  ngOnInit(): void {
    this.getData();
  }

  ngAfterViewInit(): void {
    if (this.paginator) {
      this.paginator.page.subscribe(() => {
        this.getData();
      })
    }
  }

  getData = () => {
    if(this.mode === "TENANT") {
      this.getTenantCompanies();
    }
    if(this.mode === "USER_PROFILE") {
      this.getUserCompanies();
    }
  }

  getTenantCompanies = () => {
    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;
    this.performXFRequest({
      requestDescription: "GET Tenant Companies",
      requestFn: this.companyService.getAllTenantCompanies,
      fnParams: [first, pageSize],
      onSuccess: (data:any[]) => {
        this.dataSource.data = data;
        this.totalRowCount = data.length;
      },
      onComplete: () => {
        this.isLoading = false;
      },
      onError: (error) => {
        super.showErrorSnack(error);
      }
    });
  }

  getUserCompanies = () => {
    const id = parseInt(this.relatedId);

    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;
    this.performXFRequest({
      requestDescription: "GET User Companies",
      requestFn: this.companyService.getAllUserCompanies,
      fnParams: [id, first, pageSize],
      onSuccess: (data:any[]) => {
        this.dataSource.data = data;
        this.totalRowCount = data.length;
      },
      onComplete: () => {
        this.isLoading = false;
      },
      onError: (error) => {
        super.showErrorSnack(error);
      }
    });
  }

  addTenantCompany() {
    let addMode = "NEW_TENANT";
    if(this.mode === "USER_PROFILE") {
      addMode = "USER_TENANT";
    }

    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "900px";
    matDialogConfig.data = {
      mode:addMode,
      companyRelationalType:this.mode
    }
    const dialogRef = this.matDialog.open(CompanyRelationalTypeAddComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {
        if (result.companyIds?.length > 0) {
          if(this.mode === "TENANT") {
            this.createTenantPrimaryClientCompany(result.companyIds);
          }else{
            this.createCompanyRelationalMap(result);
          }
        }
      }
    });
  }

  createTenantPrimaryClientCompany = (companyIds: number[]) => {
    let reqPacket = new CompanyRelationalTypeCreatePacket();
    reqPacket.companyIds = companyIds;
    reqPacket.relatedId = -1;
    reqPacket.companyRelationalType = "TENANT";
    reqPacket.companyType = "PRIMARY_CLIENT";
    this.performXFRequest({
      requestDescription: "Create Tenant / Primary Client Company",
      requestFn: this.companyService.createCompanyRelationalType,
      fnParams: [reqPacket],
      onSuccess: (data) => {
        const showWarning = data === "DUPE";
        super.showSuccessSnack(
          "Tenant Companies added"
          + (showWarning
            ? ". Note: At least one selected company already had an existing Tenant relationship."
            : "")
        );
        this.getData();
      },
      onComplete: () => {
        this.isLoading = false;
      },
      onError: (error) => {
        super.showErrorSnack(error);
      }
    });
  }

  createCompanyRelationalMap = (data:any) => {
    let reqPacket = new CompanyRelationalTypeCreatePacket();
    reqPacket.companyIds = data.companyIds;
    reqPacket.relatedId = parseInt(this.relatedId);
    reqPacket.companyRelationalType = data.companyRelationalType;
    reqPacket.companyType = data.companyType;
    this.performXFRequest({
      requestDescription: "Create Company Relational Map",
      requestFn: this.companyService.createCompanyRelationalType,
      fnParams: [reqPacket],
      onSuccess: (data) => {
        const showWarning = data === "DUPE";
        super.showSuccessSnack(
          "Company Relationships added"
          + (showWarning
            ? ". Note: at least one selected company already had an existing relationship."
            : "")
        );
        this.getData();
      },
      onComplete: () => {
        this.isLoading = false;
      },
      onError: (error) => {
        super.showErrorSnack(error);
      }
    });
  }

  setActive(crmId: number, companyName: string = "Unknown") {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      question : "Set Active?",
      infoLine1 : "Set " + companyName + " to Active?"
    };
    const dialogRef = this.matDialog.open(SingleChoiceDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {
        this.performXFRequest({
          requestDescription: "Activate Tenant / Primary Client Company",
          requestFn: this.companyService.reinstateCompanyRelationalType,
          fnParams: [crmId],
          onSuccess: (data) => {
            this.getData();
          },
          onComplete: () => {
            this.isLoading = false;
          },
          onError: (error) => {
            super.showErrorSnack(error);
          }
        });
      }
    });
  }

  setInactive(crmId: number, companyName: string = "Unknown") {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      question : "Set InActive?",
      infoLine1 : "Set " + companyName + " to InActive?"
    };
    const dialogRef = this.matDialog.open(SingleChoiceDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {
        this.performXFRequest({
          requestDescription: "InActivate Tenant / Primary Client Company",
          requestFn: this.companyService.deleteCompanyRelationalType,
          fnParams: [crmId],
          onSuccess: (data) => {
            this.getData();
          },
          onComplete: () => {
            this.isLoading = false;
          },
          onError: (error) => {
            super.showErrorSnack(error);
          }
        });
      }
    });
  }

  onDelete(crmId: number, companyName: string = "Unknown") {
    let relationTypeString = this.mode == "TENANT" ? "Tenant Company List" : "User ID: " + this.relatedId;

    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      question : `Permamently remove ${companyName} from ${relationTypeString}?`,
      infoLine1 : ""
    };
    const dialogRef = this.matDialog.open(SingleChoiceDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {
        this.performXFRequest({
          requestDescription: "Delete Company Relationship",
          requestFn: this.companyService.hardDeleteCompanyRelationalType,
          fnParams: [crmId],
          onSuccess: (data) => {
            this.getData();
          },
          onComplete: () => {
            this.isLoading = false;
          },
          onError: (error) => {
            super.showErrorSnack(error);
          }
        });
      }
    });
  }

  deleteAllTenantCompanies() {
    // Option not available for TENANT mode at this time.
    if (this.mode === "TENANT") {
      return;
    }
    let relationTypeString = this.mode == "TENANT" ? "Tenant Company List" : "User ID: " + this.relatedId;

    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      question : `Permamently remove all companies from ${relationTypeString}?`,
      infoLine1 : ""
    };
    const dialogRef = this.matDialog.open(SingleChoiceDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {
        this.performXFRequest({
          requestDescription: "Delete All Company Relationship",
          requestFn: this.companyService.hardDeleteAllCompaniesFromUser,
          fnParams: [parseInt(this.relatedId)],
          onSuccess: (data) => {
            this.getData();
          },
          onComplete: () => {
            this.isLoading = false;
          },
          onError: (error) => {
            super.showErrorSnack(error);
          }
        });
      }
    });
  }

}
