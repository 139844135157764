import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatLegacyTabGroup as MatTabGroup } from '@angular/material/legacy-tabs';
import { BaseComponent, FennecSnackbarService, Logger, UserProfilePacket } from 'xf-common';


@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent extends BaseComponent implements OnInit {

  log: Logger = new Logger("UserManagementComponent");

  @Input()
  userProfile: UserProfilePacket | null = null;

  selectedTabIndex: number | undefined;
  currentTabIndex: number | undefined;

  @ViewChild('tabGroup')
  tabGroup?: MatTabGroup;

  constructor(
    protected snack: FennecSnackbarService
  ) {
    super();
  }

  ngOnInit(): void {
  }



}
