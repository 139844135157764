<div mat-dialog-title>
  Update Assigned Company For {{healthCarePlanName ?? "Health Plan"}}
</div>

<div mat-dialog-content>
  <form [formGroup]="formGroup" class="alt-theme">
    <mat-form-field appearance="outline" style="width:100%">
      <mat-label>Company</mat-label>
      <mat-select formControlName="company">
        <mat-option *ngFor="let company of userPrimaryClientList" [value]="company.companyId">
          {{company.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</div>

<div matDialogActions class="fennec-dialog-action-button-container">
  <button mat-flat-button color="primary" (click)="cancel()">
    Cancel
  </button>
  <button mat-flat-button color="accent" [disabled]="formGroup.invalid" (click)="submit()">
    Save
  </button>
</div>