import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ItemizedRevisionService } from '../itemized-revision.service';
import { BaseComponent, FennecSnackbarService, Logger } from "xf-common";


@Component({
  selector: 'app-itemized-revision-clone-dialog',
  templateUrl: './itemized-revision-clone-dialog.component.html',
  styleUrls: ['./itemized-revision-clone-dialog.component.scss']
})
export class ItemizedRevisionCloneDialogComponent extends BaseComponent implements OnInit {
  protected override log: Logger = new Logger("ItemizedRevisionCloneDialogComponent");

  iRevId: number = -1;
  revisionTypeList: any[] = [];
  loading:boolean = false;

  // Form Group to manage edit bindings.
  formGroup: FormGroup = new FormGroup({
    name: new FormControl(null, Validators.required),
    revisionType: new FormControl("CLAIM_REVIEW", Validators.required)
  });

  constructor(
    private itemizedRevisionService: ItemizedRevisionService,
    public dialogRef: MatDialogRef<ItemizedRevisionCloneDialogComponent>,
    protected snack: FennecSnackbarService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    super();
   }

  ngOnInit(): void {
    this.iRevId = this.data.iRevId;
    this.populateRevisionTypes();
  }

  onYes() {
    this.loading = true;
    this.itemizedRevisionService.cloneItemizedRevision(this.iRevId, this.formGroup.controls['name'].value, this.formGroup.controls['revisionType'].value).subscribe(response => {
      this.loading = false;
      if(response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        const returnObj = {
          confirm: true
        }
        this.dialogRef.close(returnObj);
      }
    });
  }

  onNo() {
    const returnObj = {
      confirm: false
    }
    this.dialogRef.close(returnObj);
  }

  populateRevisionTypes = () => {
    this.performXFRequest({
      requestDescription: "GET Revision Types",
      requestFn: this.itemizedRevisionService.getItemizedRevisionReviewTypeList,
      fnParams: [],
      onSuccess: data => {
        this.revisionTypeList = data;
      },
      onError: errString => {
        super.showErrorSnack(errString);
      }
    })
  }
}
