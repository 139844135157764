import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { BaseComponent, CompanyService, FennecSnackbarService, Logger } from 'xf-common';


@Component({
  selector: 'app-primary-client-edit-dialog',
  templateUrl: './primary-client-edit-dialog.component.html',
  styleUrls: ['./primary-client-edit-dialog.component.scss']
})
export class PrimaryClientEditDialogComponent extends BaseComponent implements OnInit, AfterViewInit {

  log: Logger = new Logger("PrimaryClientEditDialogComponent");

  // formGroup: FormGroup;
  displayColumns = ["select", "name", "address"];

  @ViewChild(MatPaginator)
  paginator?: MatPaginator;
  totalRowCount?: number;
  defaultPageSize = 20;
  pageSizeOptions = [5, 10, 20, 25, 50];
  companyRelationalMapList = [];

  changed:Boolean = false;
  selectedCompanyId: number = -1;
  initialCompanyId: number = -1;

  constructor(
    public matDialog: MatDialog,
    protected dialogRef: MatDialogRef<PrimaryClientEditDialogComponent>,
    protected snack: FennecSnackbarService,
    protected companyService: CompanyService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    super();
    if(this.data.currentCompanyId) {
      this.selectedCompanyId = this.data.currentCompanyId;
      this.initialCompanyId = this.selectedCompanyId;
    }
    // this.formGroup = new FormGroup({});
   }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    if (this.paginator) {
      this.paginator.page.subscribe(() => {
        this.getCompanyList();
      });
    }
    this.getCompanyList();
  }

  cancel() {
    this.dialogRef.close({
      confirm: false
    });
  }

  getCompanyList() {
    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;

    this.performXFRequest({
      requestDescription: "GET Company List",
      requestFn: this.companyService.getActiveUserProfilePrimaryClientCompanies,
      fnParams: [first, pageSize],
      onResponse: response => {
        this.companyRelationalMapList = response.data;
        this.totalRowCount = response['totalRowCount'];
      },
      onError: errString => {
        super.showErrorSnack(errString);
      }
    });
  }

  submit() {
    this.dialogRef.close({
      confirm:true,
      companyId: this.selectedCompanyId,
    })
  }

  selectCompany(companyId:number) {
    this.selectedCompanyId = companyId;
    if(companyId == this.initialCompanyId) {
      this.changed = false;
    }else {
      this.changed = true;
    }
  }

}
