import { Component, Inject, OnInit } from '@angular/core';
import { BaseComponent, FennecSnackbarService, Logger } from 'xf-common';
import { FormControl, FormGroup } from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { DuplicateEntityService } from "@app/duplicate-entity/duplicate-entity.service";


@Component({
  selector: 'app-duplicate-entity-dialog',
  templateUrl: './duplicate-entity-dialog.component.html',
  styleUrls: ['./duplicate-entity-dialog.component.scss']
})
export class DuplicateEntityDialogComponent extends BaseComponent implements OnInit {
  log = new Logger("DuplicateEntityDialogComponent");
  entityId: any;
  entityType: any;
  duplicateInfo?: any;
  entityHasParent?: boolean;
  entityHasChildren?: boolean;
  childEntitiesDisplayString?: string;

  formGroup: FormGroup;

  constructor(
    protected snack: FennecSnackbarService,
    private duplicateEntityService: DuplicateEntityService,
    public dialogRef: MatDialogRef<DuplicateEntityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super();

    this.entityId = data?.entityId;
    this.entityType = data?.entityType;

    if (this.entityId == null || this.entityType == null) {
      super.showErrorSnack("This dialog is improperly configured. Please contact system support.");
    }

    this.formGroup = new FormGroup<any>({
      duplicateParentEntityId: new FormControl("")
    });
  }

  ngOnInit(): void {
    this.getAllDuplicateInfo();
  }

  getAllDuplicateInfo() {
    this.performXFRequest({
      requestDescription: "Get duplicate info",
      requestFn: this.duplicateEntityService.getAllDuplicateInfo,
      fnParams: [this.entityType, this.entityId],
      onSuccess: (data) => {
        if (data == null || data.length == 0) {
          this.duplicateInfo = {};
          this.calculateDuplicateRelationships({});
          return;
        }
        this.calculateDuplicateRelationships(data);
      },
      onError: err => {
        this.showErrorSnack(err);
      }
    });
  }

  calculateDuplicateRelationships(data: any) {
    if (data == null) data = [];
    this.duplicateInfo = { duplicateEntityIds: [] };
    const duplicateChildRegistries = data.filter((reg: any) => reg.parentEntityId != null && reg.parentEntityId == this.entityId);
    const duplicateParentRegistries = data.filter((reg: any) => reg.duplicateEntityIds.includes(+this.entityId));
    this.entityHasParent = duplicateParentRegistries.length > 0;
    this.entityHasChildren = duplicateChildRegistries.length > 0;

    if (this.entityHasParent) {
      this.duplicateInfo["parentEntityId"] = duplicateParentRegistries[0].parentEntityId;
      this.formGroup?.controls?.["duplicateParentEntityId"]?.setValue(this.duplicateInfo?.parentEntityId ?? "");
    }

    for (const childRegistry of duplicateChildRegistries) {
      this.duplicateInfo["duplicateEntityIds"].push(childRegistry["duplicateEntityIds"]);
    }

    if (this.entityHasChildren) {
      this.childEntitiesDisplayString = this.duplicateInfo?.duplicateEntityIds?.join(", ")
    } else {
      this.childEntitiesDisplayString = "";
    }
  }

  submit() {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.invalid) {
      return;
    }
    const parentEntityId = this.formGroup.value["duplicateParentEntityId"];

    this.performXFRequest({
      requestDescription: "Update Duplicate Info",
      requestFn: this.duplicateEntityService.postRegisterDuplicate,
      fnParams: [this.entityType, parentEntityId, this.entityId],
      onSuccess: _ => {
        this.showSuccessSnack("Success");
        this.getAllDuplicateInfo();
      },
      onError: err => {
        this.showErrorSnack(err);
      }
    });

  }

  onCancel() {
    this.dialogRef.close();
  }
}
