import { Component, OnInit } from '@angular/core';
import { BaseComponent, FennecSnackbarService, Logger } from "xf-common";


@Component({
  selector: 'app-ape-component',
  templateUrl: './ape.component.html',
  styleUrls: ['./ape.component.scss']
})
export class ApeComponent extends BaseComponent implements OnInit {


  protected log: Logger = new Logger("APEComponent");

  constructor(
    public snack: FennecSnackbarService
  ) {
    super();
  }

  ngOnInit(): void {
  }

}
