import { XFRequestHandlers } from "../model/safe-request";
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { BaseCrudComponent } from "../view/crud/base.crud.component";
import { BaseCrudService } from "../view/crud/base.crud.service";
import { Deserializable } from "../model/deserializable";
import { Observable } from "rxjs";
import { PagedResponse } from "../model/paged-response";
import { DataResponse } from "../model/data-response";

export namespace Crud {
  export type ListFunction<T> = (first: number, max: number) => Observable<PagedResponse<T[]>>;
  export type GetByIdFunction<T> = (id: number) => Observable<DataResponse<T>>;
  export type CreateFunction<T> = (el: T) => Observable<DataResponse<T>>;
  export type UpdateFunction<T> = (el: T) => Observable<DataResponse<T>>;
  export type DeactivateFunction<T> = (ids: number[]) => Observable<DataResponse<boolean>>;
  export type DeleteFunction<T> = (ids: number[]) => Observable<DataResponse<boolean>>;

  export interface CrudServiceDef<T> {
    _list: Crud.ListFunction<T>,
    _getById: Crud.GetByIdFunction<T>,
    _create: Crud.CreateFunction<T>,
    _update: Crud.UpdateFunction<T>,
    _deactivate: Crud.DeactivateFunction<T>,
    _delete: Crud.DeleteFunction<T>
  }

  export enum CrudOperations {
    LIST = "LIST",
    GET_BY_ID = "GET_BY_ID",
    CREATE = "CREATE",
    UPDATE = "UPDATE",
    DEACTIVATE = "DEACTIVATE",
    DELETE = "DELETE"
  }

  export type GenericCrudHandlers = {
    [key in CrudOperations]?: XFRequestHandlers;
  }

  export function getGenericCrudHandlers<T extends Deserializable<T>, S extends BaseCrudService<T>, C extends BaseCrudComponent<T, S>>(component: C, dataSource: MatTableDataSource<T>): GenericCrudHandlers {
    return {
      LIST: genericCrudListHandler<T, S, C>(component, dataSource),
      GET_BY_ID: genericCrudGetByIdHandler<T, S, C>(component),
      CREATE: genericCrudCreateHandler<T, S, C>(component),
      UPDATE: genericCrudUpdateHandler<T, S, C>(component),
      DEACTIVATE: genericCrudDeactivateHandler<T, S, C>(component),
      DELETE: genericCrudDeleteHandler<T, S, C>(component)
    };
  }

  export function genericCrudListHandler<T extends Deserializable<T>, S extends BaseCrudService<T>, C extends BaseCrudComponent<T, S>>(component: C, dataSource: MatTableDataSource<T>): XFRequestHandlers {
    return {
      onResponse: (response: PagedResponse<T[]>) => {
        dataSource.data = response?.data ?? [];
        component.paginator.pageIndex = response?.pageNo;
        component.paginator.length = response?.totalRowCount;
      },
      onError: error => component.showErrorSnack(error),
      onComplete: () => component.isLoading = false
    };
  }

  export function genericCrudGetByIdHandler<T extends Deserializable<T>, S extends BaseCrudService<T>, C extends BaseCrudComponent<T, S>>(component: C): XFRequestHandlers {
    return {
      onError: error => component.showErrorSnack(error),
      onComplete: () => component.isLoading = false
    };
  }

  export function genericCrudCreateHandler<T extends Deserializable<T>, S extends BaseCrudService<T>, C extends BaseCrudComponent<T, S>>(component: C): XFRequestHandlers {
    return {
      onSuccess: data => component.list(),
      onError: error => component.showErrorSnack(error),
      onComplete: () => component.isLoading = false
    };
  }

  export function genericCrudUpdateHandler<T extends Deserializable<T>, S extends BaseCrudService<T>, C extends BaseCrudComponent<T, S>>(component: C): XFRequestHandlers {
    return {
      onSuccess: data => component.list(),
      onError: error => component.showErrorSnack(error),
      onComplete: () => component.isLoading = false
    };
  }

  export function genericCrudDeactivateHandler<T extends Deserializable<T>, S extends BaseCrudService<T>, C extends BaseCrudComponent<T, S>>(component: C): XFRequestHandlers {
    return {
      onSuccess: data => component.list(),
      onError: error => component.showErrorSnack(error),
      onComplete: () => component.isLoading = false
    };
  }

  export function genericCrudDeleteHandler<T extends Deserializable<T>, S extends BaseCrudService<T>, C extends BaseCrudComponent<T, S>>(component: C): XFRequestHandlers {
    return {
      onSuccess: data => component.list(),
      onError: error => component.showErrorSnack(error),
      onComplete: () => component.isLoading = false
    };
  }
}


