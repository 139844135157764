import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Logger } from "../../util/logger";
import { BaseComponent } from "../../view/base.component";
import { FennecSnackbarService } from "../../dialog/fennec-snackbar/fennec-snackbar.service";

@Component({
  templateUrl: './crud-delete-status-change.component.html',
  styleUrls: ['./crud-delete-status-change.component.scss']
})
export class CRUDDeleteStatusChangeDialogComponent extends BaseComponent implements OnInit {
  log: Logger = new Logger("CRUDDeleteStatusChangeDialogComponent");
  mode: string = "NONE";
  source: any;
  buttonValue?: string;
  label: string;

  // if set to true, dialog will not display the word "this" before the label variable
  shortenLabel:boolean = false;

  constructor(
    protected snack: FennecSnackbarService,
    public dialogRef: MatDialogRef<CRUDDeleteStatusChangeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super()
    this.mode = data.mode;
    this.source = data.source;
    this.label = data.label;

    if(this.data.shortenLabel != undefined) {
      this.shortenLabel = this.data.shortenLabel;
    }

    if (this.mode === 'status') {
      this.buttonValue = this.source?.active ? "Deactivate" : "Activate";
    }
   }

  ngOnInit() {
  }

  onCancel() {
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.mode === 'delete') {
      this.dialogRef.close(this.source);
    } else if (this.mode === 'status') {
      this.source.active = !this.source.active;
      this.dialogRef.close(this.source);
    }
  }
}
