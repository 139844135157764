import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService, DataResponse, Logger } from 'xf-common';

@Injectable({
  providedIn: 'root'
})
export class MiCaseReportElementsService extends BaseService {

  baseEndpoint = this.apiEndpoint("mi-case-report-elements");
  
  constructor(
    protected override httpClient: HttpClient
  ) {
    super(new Logger("MICaseReportElementService"));
  }

  getMICaseReportElementsByMICaseId = (miCaseId:number): Observable<DataResponse<any>> => {
    return this.httpClient.get<any>(`${this.baseEndpoint}/${miCaseId}`);
  }

  updateMICaseReportElements = (packet:any): Observable<DataResponse<any>> => {
    return this.httpClient.post<any>(`${this.baseEndpoint}/update`, packet);
  }
}
