import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { JslService } from "../../jsl.service";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { BaseResponse, FennecSnackbarService, Logger } from "xf-common";


const log = new Logger("JSLExecuteJobDialogComponent");

@Component({
  selector: "app-jsl-execute-job-dialog-component",
  templateUrl: "./jsl-execute-job-dialog.component.html",
  styleUrls: ["./jsl-execute-job-dialog.component.scss"]
})
export class JSLExecuteJobDialogComponent implements OnInit {
  executeJobDialogFormGroup: FormGroup = new FormGroup({});
  jobDefinitionParams: any [] = [];

  jslJobDefinitionId: number;

  constructor(
    private matDialog: MatDialogRef<JSLExecuteJobDialogComponent>,
    protected snack: FennecSnackbarService,
    protected jslService: JslService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.jslJobDefinitionId = data.jslJobDefinitionId;
  }

  ngOnInit() {
    this.getAllJobDefinitionParamsForJobDefinition(this.jslJobDefinitionId);
  }

  close() {
    const response = {
      confirm: false
    };
    this.matDialog.close(response);
  }

  submit() {
    const response = {
      confirm: true,
      paramValues: new Array()
    };

    // Dynamically pull out the values from the dialog box using the "fc_" + seqNo form control name(s).
    let idx = 0;
    for (idx = 0; idx < this.jobDefinitionParams.length; idx++) {
      const fc = this.executeJobDialogFormGroup.controls["fc_" + this.jobDefinitionParams[idx].seqNo];
      //response["fc_" + this.jobDefinitionParams.items[idx].seqNo] = fc.value;

      response.paramValues.push({
        seqNo: this.jobDefinitionParams[idx].seqNo,
        paramValue: fc.value
      });
    }

    this.matDialog.close(response);
  }

  getAllJobDefinitionParamsForJobDefinition(jobDefinitionId: number) {
    this.jslService.getJSLJobDefinitionParamsForJobDefinition(jobDefinitionId).subscribe(
      (response: BaseResponse) => {
        if (response.hasErrors) {
          this.snack.showErrorSnack(response.consolidatedErrorMessage);
        } else {
          this.jobDefinitionParams = response.data;
          // Build FormGroup dynamically. Each control within the form group has the following name:
          // "fc_" + seqNo from the parameter data
          let idx = 0;
          for (idx = 0; idx < this.jobDefinitionParams.length; idx++) {
            const fc = new FormControl();
            this.executeJobDialogFormGroup.addControl("fc_" + this.jobDefinitionParams[idx].seqNo, fc);
          }
        }
      });
  }
}
