<div class="wrapper">
  <h2 matDialogTitle>
    States for APE Rule Filter '{{apeRuleFilter.name}}'
  
    <button
      mat-icon-button
      style="float:right; color:black;"
      (click)="closeDialog()"
      >
      <mat-icon>close</mat-icon>
    </button>
  </h2>

  <mat-card *ngIf="displayMode === 'ADMIN' && apeRuleFilterStates.length === 0">
    <h4>No States Found</h4>
    <button
      mat-button
      color="accent"
      (click)="changeDisplayMode('SEARCH_STATE')"
    >
    <mat-icon>add_circle_outline</mat-icon>
      Add State
    </button>
  </mat-card>

  <mat-table *ngIf="displayMode === 'ADMIN' && apeRuleFilterStates.length > 0" [dataSource]="apeRuleFilterStates">
    <ng-container matColumnDef="stateCode">
      <mat-header-cell *matHeaderCellDef>State Code</mat-header-cell>
      <mat-cell *matCellDef="let filterState">
        {{filterState.state.stateCode}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="stateName">
      <mat-header-cell *matHeaderCellDef>State Name</mat-header-cell>
      <mat-cell *matCellDef="let filterState">
        {{filterState.state.stateName}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell class="actions" *matHeaderCellDef>
        <button
          mat-button
          color="accent"
          (click)="changeDisplayMode('SEARCH_STATE')"
        >
        <mat-icon>add_circle_outline</mat-icon>
          Add State
        </button>

        <button
          class="remove-all-button"
          mat-stroked-button
          (click)="removeAllPrompt()"
        >
        <mat-icon>delete_sweep</mat-icon>
          Remove All
        </button>
      </mat-header-cell>
      <mat-cell class="actions" *matCellDef="let filterState">
        <button
          mat-icon-button
          matTooltip="Remove"
          (click)="openDeleteDialog(filterState)"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: displayedColumns; let i=index"
      [tabindex]="i + 1"
      [attr.data-index]="i"
      [ngClass]="{ hovered: row.hovered }"
      (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"
    ></mat-row>
  </mat-table>
  
  <!-- STATE SEARCH -->
  <!-- TO DO -- Refactor into seperate component -->
  <div *ngIf="displayMode === 'SEARCH_STATE'">
    <div>
      <button
        mat-flat-button
        (click)="changeDisplayMode('ADMIN')">
        <mat-icon>arrow_back</mat-icon>
        Back
      </button>

      <h2>Add State
        <button
          mat-stroked-button
          class="add-all-button"
          (click)="addAllPrompt()"
        >
          <mat-icon>queue</mat-icon>
          Add All States
        </button>
      </h2>
    </div>

    <form *ngIf="searchFormGroup" [formGroup]="searchFormGroup" class="alt-theme">

      <mat-form-field appearance="outline">
        <mat-label>Search By State Code</mat-label>
        <input matInput formControlName="stateCode">
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Search By State Name</mat-label>
        <input matInput formControlName="stateName">
      </mat-form-field>

      <div class="button-group">
        <button
          mat-stroked-button
          color="accent"
          (click)="searchForState()"
        >
          Search
        </button>

        <button
          mat-stroked-button
          (click)="changeDisplayMode('ADMIN')"
        >
          Cancel
        </button>
      </div>
    </form>

    <mat-table *ngIf="searchResults.length > 0" [dataSource]="searchResults">

      <ng-container matColumnDef="stateCode">
        <mat-header-cell *matHeaderCellDef>State Code</mat-header-cell>
        <mat-cell *matCellDef="let state">
          {{state.stateCode}}
        </mat-cell>
      </ng-container>
  
      <ng-container matColumnDef="stateName">
        <mat-header-cell *matHeaderCellDef>State Name</mat-header-cell>
        <mat-cell *matCellDef="let state">
          {{state.stateName}}
        </mat-cell>
      </ng-container>
  
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef>
        </mat-header-cell>
        <mat-cell *matCellDef="let state">
          <button
            *ngIf="!stateIds.includes(state.id)"
            mat-flat-button
            (click)="addState(state)"
          >
            <mat-icon color="accent">add_circle</mat-icon>
            Add State
          </button>

          <div *ngIf="stateIds.includes(state.id)">
            <span class="message">State Currently Applied</span>
            <button
              mat-icon-button
              (click)="openDeleteDialogStateSearch(state)"
            >
              <mat-icon>delete</mat-icon>
              Remove
            </button>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedColumns; let i=index"
        [tabindex]="i + 1"
        [attr.data-index]="i"
        [ngClass]="{ hovered: row.hovered }"
        (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"
      ></mat-row>
    </mat-table>
  </div>
</div>
