import { Deserializable, deserializeAssign } from "../deserializable";


export class RevenueCodePacket implements Deserializable<RevenueCodePacket> {
  id: number = -1;
  code: String="";
  description: String="";
  selected?: boolean;
  active?: boolean;

  deserialize(input: any): RevenueCodePacket {
    return deserializeAssign(this, input);
  }

  toJSON(): any {
    const obj: { [key: string]: any } = {};
    Object.assign(obj, this);
    delete obj["selected"];
    return obj;
  }
}
