import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AdjustmentExplanationCodePacket, BaseComponent, FennecSnackbarService } from 'xf-common';
import { Logger } from 'xf-common';
import { AdjustmentExplanationCodeService } from '../adjustment-explanation-code.service';
import {AdjustmentReasonCodeService} from "@app/admin/adjustment-reason-code/adjustment-reason-code.service";

@Component({
  selector: 'app-adjustment-explanation-code-dialog',
  templateUrl: './adjustment-explanation-code-dialog.component.html',
  styleUrls: ['../../../../lib/styles/system-admin-styles.component.scss']
})
export class AdjustmentExplanationCodeDialogComponent extends BaseComponent implements OnInit {
  protected log = new Logger("AdjustmentExplanationCodeDialogComponent");
  mode?: "create" | "edit" | "view";
  adjustmentExplanationCode?: AdjustmentExplanationCodePacket;
  formGroup: FormGroup;

  adjustmentExplanationTypes: string[] = [];
  adjustmentReasonCodes: any[] = [];

  constructor(
    protected dialog: MatDialog,
    protected adjustmentExplanationCodeService: AdjustmentExplanationCodeService,
    protected adjustmentReasonCodeService: AdjustmentReasonCodeService,
    protected dialogRef: MatDialogRef<AdjustmentExplanationCodeDialogComponent>,
    override snack: FennecSnackbarService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    super();
    this.mode = this.data?.mode
    this.adjustmentExplanationCode = this.data?.adjustmentExplanationCode
    this.formGroup = new FormGroup({
      code: new FormControl(this.adjustmentExplanationCode?.code ?? "", [Validators.required, Validators.maxLength(7)]),
      description: new FormControl(this.adjustmentExplanationCode?.description ?? "", [Validators.required, Validators.maxLength(1000)]),
      shortDescription: new FormControl(this.adjustmentExplanationCode?.shortDescription ?? "", [Validators.required, Validators.maxLength(100)]),
      visibleOnDashboard: new FormControl(this.adjustmentExplanationCode?.visibleOnDashboard ?? false, [Validators.required]),
      internal: new FormControl(this.adjustmentExplanationCode?.internal ?? false, Validators.required),
      adjustmentExplanationType: new FormControl(this.adjustmentExplanationCode?.adjustmentExplanationType ?? "", Validators.required),
      adjustmentReasonCodeId: new FormControl(this.adjustmentExplanationCode?.adjustmentReasonCodeId ?? "")
    });
  }

  ngOnInit(): void {
    this.getAdjustmentExplanationTypes();
    this.getAdjustmentReasonCodes();
  }

  submit() {
    const adjustmentExplanationCode: AdjustmentExplanationCodePacket = this.adjustmentExplanationCode ?? new AdjustmentExplanationCodePacket()
    const formValue = this.formGroup.value
    if (!formValue.code) {
      super.showErrorSnack("Code is required")
      return
    }
    if (!formValue.description) {
      super.showErrorSnack("Description required")
      return
    }
    adjustmentExplanationCode.code = formValue["code"];
    adjustmentExplanationCode.description = formValue["description"];
    adjustmentExplanationCode.shortDescription = formValue["shortDescription"];
    adjustmentExplanationCode.visibleOnDashboard = formValue["visibleOnDashboard"];
    adjustmentExplanationCode.internal = formValue["internal"];
    adjustmentExplanationCode.adjustmentExplanationType = formValue["adjustmentExplanationType"];
    adjustmentExplanationCode.adjustmentReasonCodeId = formValue["adjustmentReasonCodeId"];
    this.dialogRef.close(adjustmentExplanationCode)
  }
  cancel() {
    this.dialogRef.close()
  }

  getAdjustmentExplanationTypes = () => {
    this.performXFRequest({
      requestDescription: "GET adjustment explanation type",
      requestFn: this.adjustmentExplanationCodeService.getAdjustmentExplanationTypes,
      fnParams: [],
      onSuccess: data => {
        // super.showSuccessSnack("Successful!");
        // this.executeListQuery();
        this.adjustmentExplanationTypes = data;

        if (this.formGroup.controls["adjustmentExplanationType"].value == "") {
          this.formGroup.controls["adjustmentExplanationType"].setValue(this.adjustmentExplanationTypes[0]);
        }
      },
      onError: errString => {
        super.showErrorSnack(errString);
      }
    })
  }

  getAdjustmentReasonCodes = () => {
    this.performXFRequest({
      requestDescription: "Get adjustment reason codes",
      requestFn: this.adjustmentReasonCodeService._list,
      fnParams: [0, 100],
      onSuccess: data => this.adjustmentReasonCodes = data,
      onError: err => super.showErrorSnack(err)
    });
  }
}
