import { Deserializable, deserializeAssign } from "../deserializable";
import { CommentPacket } from "./CommentPacket";
import { CommentRelationalMapPacket } from "./CommentRelationalMapPacket";

export class CommentRelationalMapMultiCreatePacket implements Deserializable<CommentRelationalMapPacket> {

  relatedIds?: number[];
  commentRelationalMapPacket?: CommentRelationalMapPacket;

  deserialize(input: any): CommentRelationalMapMultiCreatePacket {
    return deserializeAssign(this, input);
  }

  toJSON(): any {
    const obj: { [key: string]: any } = {};
    Object.assign(obj, this);
    delete obj["selected"];
    return obj;
  }
}