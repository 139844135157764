<ng-container pageContent class="container-override">

  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h4>Search Filters</h4>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form [formGroup]="formGroup" class="alt-theme" (submit)="onSearch()">
        <div class="input-wrapper">
          <mat-form-field appearance="outline" class="search-field">
            <mat-label>Name</mat-label>
            <input matInput placeholder="Search by Name" formControlName="name">
          </mat-form-field>
    
          <mat-form-field appearance="outline" class="search-field">
            <mat-label>Description</mat-label>
            <input matInput placeholder="Search by Description" formControlName="description">
          </mat-form-field>


          <mat-form-field appearance="outline" class="search-field">
            <mat-label>Filter Domain</mat-label>
            <mat-select formControlName="apeRuleFilterDomain">
              <mat-option [value]="'all'">All</mat-option>
              <mat-option *ngFor="let domain of domains" [value]="domain">{{domain}}</mat-option>
            </mat-select>
          </mat-form-field>
  
          <mat-form-field *ngIf="this.mode == 'ADMIN'" appearance="outline" class="search-field">
            <mat-label>Status</mat-label>
            <mat-select formControlName="active">
              <mat-option [value]="'all'">All</mat-option>
              <mat-option [value]="true">Active</mat-option>
              <mat-option [value]="false">Not Active</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" class="search-field">
            <mat-label>Dev Code</mat-label>
            <input matInput placeholder="Search by Dev Code" formControlName="devCode">
          </mat-form-field>
          
          <div class="button-div">
            <button
              mat-stroked-button
              color="accent"
              type="submit"
              class="search-button"
            >
              <mat-icon>search</mat-icon> Search
            </button>
      
            <button
              mat-stroked-button
              color="primary"
              (click)="clearSearch()"
            >
              Clear
            </button>
          </div>
        </div>
  
      </form>
    </mat-expansion-panel>

  </mat-accordion>


  <mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef>Id</mat-header-cell>
      <mat-cell *matCellDef="let filter">{{filter?.id}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
      <mat-cell *matCellDef="let filter">{{filter?.name}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="devCode">
      <mat-header-cell *matHeaderCellDef>Dev Code</mat-header-cell>
      <mat-cell *matCellDef="let filter">{{filter?.apeRuleFilterDevCode}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef>Description</mat-header-cell>
      <mat-cell *matCellDef="let filter">{{filter?.description}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="domain">
      <mat-header-cell *matHeaderCellDef>Domain</mat-header-cell>
      <mat-cell *matCellDef="let filter">{{filter?.apeRuleFilterDomain}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="active">
      <mat-header-cell *matHeaderCellDef>Active</mat-header-cell>
      <mat-cell *matCellDef="let filter">
        <mat-icon [ngStyle]="getIconStyle(filter)">
          {{filter?.active ? "check_circle" : "error_outline"}}
        </mat-icon>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef>
        <button 
          class="add" 
          mat-flat-button
          (click)="openFormDialog(null)"
          >
          <mat-icon>add_circle</mat-icon>
          New
        </button>
      </mat-header-cell>

      <mat-cell *matCellDef="let filter">
        <mat-menu #actionMenu="matMenu">
          <button
            *ngIf="filter?.apeRuleFilterDomain === 'STATE'"
            mat-menu-item
            (click)="openStateDialog(filter)"
          >
            <mat-icon>settings</mat-icon>
            View States
          </button>

          <button
            *ngIf="filter?.apeRuleFilterDomain === 'PRIMARY_CLIENT'"
            mat-menu-item
            (click)="openPrimaryClientDialog(filter)"
          >
            <mat-icon>settings</mat-icon>
            View Clients
          </button>

          <button
            *ngIf="filter?.apeRuleFilterDomain === 'HEALTH_CARE_PLAN'"
            mat-menu-item
            (click)="openHealthCarePlanDialog(filter)"
          >
            <mat-icon>settings</mat-icon>
            View Plans
          </button>

          <button
            mat-menu-item
            (click)="openFormDialog(filter)"
          >
            <mat-icon>edit</mat-icon>
            Edit
          </button>

          <button
              mat-menu-item 
              color="primary"
              (click)="openDeactivateDialog(filter)">
              <mat-icon>{{getDeleteIconString(filter)}}</mat-icon>
               {{filter?.active ? "Deactivate" : "Restore"}}
            </button>
        </mat-menu>

        <button mat-icon-button [matMenuTriggerFor]="actionMenu" [matMenuTriggerData]="">
          <mat-icon>more_vert</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="select">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let filter">
        <button
          mat-stroked-button
          color="accent"
          (click)="selectAPERuleFilter(filter)"
        >
          Select
        </button>
      </mat-cell>
    </ng-container>
    
    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: displayedColumns; let i=index"
      [tabindex]="i + 1"
      [attr.data-index]="i"
      [ngClass]="{ hovered: row.hovered }"
      (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"
    ></mat-row>
  </mat-table>
  <mat-paginator class="alt-theme" [length]="totalRowCount" [pageSize]="defaultPageSize" [pageSizeOptions]="defaultPageSizeOptions"></mat-paginator>
  
</ng-container>