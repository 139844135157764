import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ApeRuleFilterPrimaryClientService } from '../ape-rule-filter-primary-client.service';
import { ApeRuleFilterAddPrimaryClientDialogComponent } from '../ape-rule-filter-add-primary-client-dialog/ape-rule-filter-add-primary-client-dialog.component';
import {
  APERuleFilter,
  APERuleFilterPrimaryClientPacket,
  BaseCrudComponent, CompanyService, FennecSnackbarService, Logger,
  SingleChoiceDialogComponent
} from "xf-common";

@Component({
  selector: 'app-ape-rule-filter-primary-client-dialog',
  templateUrl: './ape-rule-filter-primary-client-dialog.component.html',
  styleUrls: ['./ape-rule-filter-primary-client-dialog.component.scss']
})
export class ApeRuleFilterPrimaryClientDialogComponent extends BaseCrudComponent<APERuleFilterPrimaryClientPacket, ApeRuleFilterPrimaryClientService> implements OnInit, AfterViewInit {
  override onEdit = (id: number | undefined) => {};
  override onDelete = (ids: any[]) => {};

  protected log = new Logger("ApeRuleFilterPrimaryClientDialogComponent");

  apeRuleFilter: APERuleFilter;
  companyList: APERuleFilterPrimaryClientPacket[] = [];

  mode: "VIEW" | "SEARCH" = "VIEW";
  displayedColumns: string[] = ["companyName", "actions"];

  @ViewChild('addComponent')
  addComponent?: ApeRuleFilterAddPrimaryClientDialogComponent;

  constructor(
    protected dialog: MatDialog,
    protected companyService: CompanyService,
    protected apeRuleFilterPrimaryClientService: ApeRuleFilterPrimaryClientService,
    protected dialogRef: MatDialogRef<ApeRuleFilterPrimaryClientDialogComponent>,
    override snack: FennecSnackbarService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    super(apeRuleFilterPrimaryClientService);
    this.apeRuleFilter = data.apeRuleFilter;

  }
  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
    this.getCompanyList();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  getCompanyList = () => {
    this.isLoading = true;

    const id = this.apeRuleFilter?.id;
    if (!id) {
      this.snack.showErrorSnack("APE Rule Filter ID Not Provided");
      return;
    }

    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;

    this.performXFRequest({
      requestDescription: "GET Company List",
      requestFn: this.apeRuleFilterPrimaryClientService.getAPERuleFilterPrimaryClientListForAPERuleFilterId,
      fnParams: [id, first, pageSize],
      onSuccess: data => {
        this.companyList = data;
      },
      onError: errString => {
        super.showErrorSnack(errString);
      },
      onResponse: response => {
        this.totalRowCount = response['totalRowCount'];
        this.isLoading = false;
      }
    });
  }

  setModeToSearch = () => {
    this.mode = "SEARCH";
  }

  setModeToView = () => {
    this.mode = "VIEW";
    this.getCompanyList();
  }

  deletePrompt = (packet: APERuleFilterPrimaryClientPacket): void  => {

    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      question : "Remove Primary Client",
      infoLine1 : `Remove ${packet.primaryClient?.name ?? "Primary Client"} from ${packet.apeRuleFilter?.name ?? "Ape Rule Filter"}?`
    };
    const dialogRef = this.dialog.open(SingleChoiceDialogComponent, matDialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {
        if (!packet.id) {
          super.showErrorSnack("Cannot delete APE Rule Filter Primary Client.  No ID Provided");
          return;
        }

        this.performXFRequest({
          requestDescription: "DELETE Ape Rule Filter Primary Client",
          requestFn: this.apeRuleFilterPrimaryClientService.deleteAPERuleFilterPrimaryClient,
          fnParams: [packet.id],
          onSuccess: data => {
            super.showSuccessSnack("Delete Successful");
            this.getCompanyList();
          },
          onError: errString => {
            super.showErrorSnack(errString);
          }
        });
      }
    });
  }
}
