import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BaseComponent, FennecSnackbarService, Logger, MICaseFinanceUpdatePacket, MICaseService, StatusTrackService } from 'xf-common';
import { ActivatedRoute } from "@angular/router";


@Component({
  selector: 'app-mi-case-finance',
  templateUrl: './mi-case-finance.component.html',
  styleUrls: ['./mi-case-finance.component.scss']
})
export class MiCaseFinanceComponent extends BaseComponent implements OnInit, AfterViewInit  {
  log: Logger = new Logger("MICaseFinanceComponent");

  @Input()
  miCaseId: string = "-1";

  formGroup: FormGroup;
  miCaseFinanceInfo: any = {};

  constructor(
    protected snack: FennecSnackbarService,
    private miCaseService: MICaseService,
    private statusTrackService: StatusTrackService,
    private route: ActivatedRoute
  ) {
    super();
    this.formGroup = this.createFormGroup();
    this.miCaseId = this.route.parent?.snapshot.paramMap.get("miCaseId") ?? "-1";
  }

  ngAfterViewInit(): void {

  }

  ngOnInit(): void {
    this.getMiCaseFinanceInfo();
  }

  createFormGroup = () => {
    return new FormGroup({
      claimPaid: new FormControl()
    });
  }

  getMiCaseFinanceInfo() {
    if (this.miCaseId === null || this.miCaseId === undefined || this.miCaseId === "-1") {
      return;
    }
    this.performXFRequest({
      requestDescription: "GET Case Finance Info",
      requestFn: this.miCaseService.getMICaseFinanceInfo,
      fnParams: [parseInt(this.miCaseId)],
      onResponse: response => {
        this.miCaseFinanceInfo = null;
        if (response.data !== null && response.data !== undefined) {
          this.miCaseFinanceInfo = response.data;
          this.formGroup.controls['claimPaid'].setValue(this.miCaseFinanceInfo.claimPaid);
        }
      },
      onError: errString => {
        super.showErrorSnack(errString);
      }
    });
  }

  save() {
    const updatedCasePacket = new MICaseFinanceUpdatePacket();
    updatedCasePacket.id = parseInt(this.miCaseId);
    updatedCasePacket.claimPaid = this.formGroup.controls['claimPaid'].value;
    this.performXFRequest({
      requestDescription: "Update Case Finance Info",
      requestFn: this.miCaseService.updateMICaseFinance,
      fnParams: [updatedCasePacket],
      onSuccess: (_data) => {
        this.snack.showSuccessSnack("Case updated successfully");
        this.getMiCaseFinanceInfo();
        this.miCaseService.emitRefreshCaseInfo();
      },
      onError: (err) => {
        this.snack.showErrorSnack(err);
      }
    });
  }

  setDbrdSavingsReady() {
    if (this.miCaseFinanceInfo === null || this.miCaseFinanceInfo === undefined) {
      return;
    }

    this.statusTrackService?.updateStatusTrackStatus(
      StatusTrackService.DOMAIN_MI_CASE,
      parseInt(this.miCaseId),
      StatusTrackService.ELEMENT_MI_CASE_DBRD_SAVINGS,
      StatusTrackService.STATUS_READY).subscribe(response => {
        if (response.hasErrors) {
          this.snack.showErrorSnack(response.consolidatedErrorMessage);
        } else {
          this.getMiCaseFinanceInfo();
        }
    });
  }

  setDbrdSavingsNotReady() {
    if (this.miCaseFinanceInfo === null || this.miCaseFinanceInfo === undefined) {
      return;
    }

    this.statusTrackService?.updateStatusTrackStatus(
      StatusTrackService.DOMAIN_MI_CASE,
      parseInt(this.miCaseId),
      StatusTrackService.ELEMENT_MI_CASE_DBRD_SAVINGS,
      StatusTrackService.STATUS_NONE).subscribe(response => {
        if (response.hasErrors) {
          this.snack.showErrorSnack(response.consolidatedErrorMessage);
        } else {
          this.getMiCaseFinanceInfo();
        }
    });

  }

}
