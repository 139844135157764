<mat-dialog-content>
  <div class="dialog-header">
    <h1 mat-dialog-title>Edit Occurrence Code Information</h1>
    <!-- <button 
      mat-button
      mat-stroked-button 
      class="ub04-icon-btn"
      tabindex="-1"
      (click)="onCancel()"
      matTooltip="Close Dialog">
      <mat-icon>close</mat-icon>
    </button> -->
  </div>

  <div style="clear: both;"></div>
  <div 
    [formGroup]="formGroup" 
    class="content-wrapper alt-theme">
    <ng-container formArrayName="occurrenceCodes">
      <div style="display:flex; justify-content:space-between; width: 100%;">
        <h3>Single Date</h3>

        <button mat-flat-button (click)="onAddOccurrenceCodeLine()">
          <mat-icon>add_circle</mat-icon>Add Occurrence Code
        </button>
      </div>
        <ng-container 
          *ngFor="let rcRow of occurrenceCodes.controls; let i = index">
            <div 
              class="lesson-form-row" 
              [formGroup]="getOccurrenceCodeFormGroup(i)" 
              [ngStyle]="getRowDisplayStyle(i)">
              <mat-icon
                class="ub04-field-status-icon"
                style={{getIconStyle(i)}}
              >
                {{ getDeleteRowOrBlankValueByIdx(i) ? "remove_circle_outline" : rcRow.valid ? "check_circle" : "error" }}
              </mat-icon>
              <mat-form-field 
                appearance="outline" 
                [style.width.px]=160
                >
                <mat-label>Occurrence Code</mat-label>
                <input 
                  matInput 
                  formControlName="occurrenceCode" 
                  placeholder="Occurrence Code">
              </mat-form-field>
              <mat-form-field 
                appearance="fill" 
                [style.width.px]=500>
                <mat-label>Description</mat-label>
                <input 
                  matInput 
                  formControlName="description" 
                  placeholder="Description">
              </mat-form-field>

              <mat-form-field 
                appearance="outline" 
                [style.width.px]="240"
                >
                <mat-label>Date</mat-label>
                <input 
                  matInput 
                  formControlName="fromDate" 
                  placeholder="(MM/DD/YYYY)">
              </mat-form-field>

              <!-- <mat-form-field 
                *ngIf="i > 3"
                appearance="outline" 
                [style.width.px]=120
                >
                <mat-label>Thru Date</mat-label>
                <input 
                  matInput 
                  formControlName="thruDate" 
                  placeholder="Thru Date">
              </mat-form-field> -->

              <button 
                *ngIf="!getDeleteRowByIdx(i)"
                mat-flat-button 
                matTooltip="Delete"
                class="ub04-icon-btn"
                (click)="onDelete(i)">
                <mat-icon>delete</mat-icon>
              </button>         
              
              <button 
                *ngIf="getDeleteRowByIdx(i)"
                mat-flat-button 
                matTooltip="Cancel Delete"
                class="ub04-icon-btn"
                (click)="onDelete(i)">
                <mat-icon>restore_from_trash</mat-icon>
              </button>   
            </div>
        </ng-container>
      </ng-container>
    <ng-container formArrayName="occurrenceCodesWithDateRange">

      <div style="display:flex; justify-content:space-between; width: 100%;">
        <h3>Date Range</h3>

        <button mat-flat-button (click)="onAddOccurrenceCodeLineWithDateRange()">
          <mat-icon>add_circle</mat-icon>Add Date Range Code
        </button>
      </div>
        <ng-container 
          *ngFor="let rcRow of occurrenceCodesWithDateRange.controls; let i = index">
            <div 
              class="lesson-form-row" 
              [formGroup]="getOccurrenceCodeWithDateRangeFormGroup(i)" 
              [ngStyle]="getRowDisplayStyleDateRange(i)">
              <mat-icon
                class="ub04-field-status-icon"
                style={{getIconStyleDateRange(i)}}
              >
                {{ getDateRangeDeleteRowByIdx(i) ? "remove_circle_outline" : rcRow.valid ? "check_circle" : "error" }}
              </mat-icon>
              <mat-form-field 
                appearance="outline" 
                [style.width.px]=160
                >
                <mat-label>Occurrence Code</mat-label>
                <input 
                  matInput 
                  formControlName="occurrenceCode" 
                  placeholder="Occurrence Code">
              </mat-form-field>
              <mat-form-field 
                appearance="fill" 
                [style.width.px]=500>
                <mat-label>Description</mat-label>
                <input 
                  matInput 
                  formControlName="description" 
                  placeholder="Description">
              </mat-form-field>

              <mat-form-field 
                appearance="outline" 
                [style.width.px]="120"
                >
                <mat-label>From Date</mat-label>
                <input 
                  matInput 
                  formControlName="fromDate" 
                  placeholder="(MM/DD/YYYY)">
              </mat-form-field>

              <mat-form-field 
                appearance="outline" 
                [style.width.px]=120
                >
                <mat-label>Thru Date</mat-label>
                <input 
                  matInput 
                  formControlName="thruDate" 
                  placeholder="(MM/DD/YYYY)">
              </mat-form-field>

              <button 
                *ngIf="!getDateRangeDeleteRowByIdx(i)"
                mat-flat-button 
                matTooltip="Delete"
                class="ub04-icon-btn"
                (click)="onDeleteDateRange(i)">
                <mat-icon>delete</mat-icon>
              </button>         
              
              <button 
                *ngIf="getDateRangeDeleteRowByIdx(i)"
                mat-flat-button 
                matTooltip="Cancel Delete"
                class="ub04-icon-btn"
                (click)="onDeleteDateRange(i)">
                <mat-icon>restore_from_trash</mat-icon>
              </button>   
            </div>
        </ng-container>
    </ng-container>    
  </div>
</mat-dialog-content>

<mat-dialog-actions class="fennec-controls-nn">
  <mat-checkbox 
    class="mark-complete-checkbox" 
    [checked]="statusTrackComplete" 
    (change)="toggleStatusTrackComplete()">
    Mark Complete
  </mat-checkbox>
  
    <button 
      mat-flat-button 
      (click)="onSubmit()" 
      [disabled]="!formGroup.valid || (occurrenceCodes.length == 0 && occurrenceCodesWithDateRange.length == 0)"
      color="accent">
        <mat-icon>save</mat-icon> Save
    </button>
    <button 
      mat-button 
      mat-stroked-button 
      (click)="onCancel()">
        <mat-icon>cancel</mat-icon> Cancel
    </button>


    <!-- <div class="ub04-dialog-add-btn">
      <button 
        mat-button 
        mat-stroked-button 
        matTooltip="Add"
        class="ub04-icon-btn"
        (click)="onAddConditionCodeLine()" 
        [disabled]="conditionCodes.length >= 11">
        <mat-icon>add_circle</mat-icon>
      </button>
    </div> -->
</mat-dialog-actions>
