<div matDialogTitle>
    {{ this.mode == 'create' ? 'Create' : 'Edit'}} Adjustment Explanation Code
</div>

  <div matDialogContent class="alt-theme">
    <form [formGroup]="formGroup" style="display:flex; flex-direction:column;">
      <mat-form-field appearance="outline" class="type-input">
        <mat-label>Adjustment Type</mat-label>
        <mat-select formControlName="adjustmentExplanationType">
          <mat-option *ngFor="let type of adjustmentExplanationTypes" [value]="type">{{ type }}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Code</mat-label>
        <input matInput class="id" id="code" formControlName="code" required/>
        <mat-hint>{{formGroup.controls['code'].value.length}} of 7</mat-hint>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Associated Adj Reason Code</mat-label>
        <mat-select class="adjustmentReasonCodeId" id="adjustmentReasonCodeId" formControlName="adjustmentReasonCodeId">
          <mat-option value="">

          </mat-option>
          @for (reasonCode of adjustmentReasonCodes; track reasonCode.code) {
            <mat-option [value]="reasonCode?.['id']">{{ reasonCode?.['code']}}</mat-option>
          }
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" [style.width.px]=450>
        <mat-label>Description</mat-label>
        <textarea matInput class="description" cdkTextareaAutosize rows="5" id="description" formControlName="description" required maxlength="1000"></textarea>
        <mat-hint>{{formGroup.controls['description'].value.length}} of 1000</mat-hint>
      </mat-form-field>
      <mat-form-field appearance="outline" [style.width.px]=450>
        <mat-label>Short Description</mat-label>
        <textarea matInput cdkTextareaAutosize rows="5" class="shortDescription" id="shortDescription" formControlName="shortDescription"
                  required maxlength="100"></textarea>
        <mat-hint>{{formGroup.controls['shortDescription'].value.length}} of 100</mat-hint>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Visible on Dashboard</mat-label>
        <!--      Option 1: Suffix checkbox (right-aligned) -->
        <input matInput disabled>
        <mat-checkbox formControlName="visibleOnDashboard" matSuffix></mat-checkbox>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Internal</mat-label>
        <!--      Option 1: Suffix checkbox (right-aligned) -->
        <input matInput disabled>
        <mat-checkbox formControlName="internal" matSuffix></mat-checkbox>
      </mat-form-field>
    </form>
  </div>

  <div matDialogActions class="fennec-dialog-action-button-container">
    <button mat-flat-button color="primary" (click)="cancel()">
      Cancel
    </button>
    <button mat-flat-button color="accent" [disabled]="this.formGroup.invalid" (click)="submit()">
      Save
    </button>
  </div>
