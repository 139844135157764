<mat-dialog-content>
  <h3>{{data.mode}} JSL Job </h3>
  {{data.name}}
  <!--  -->
  <form [formGroup]="executeJobDialogFormGroup">
  <!--  -->
  <table>
    <tr *ngFor="let pp of this.jobDefinitionParams; index as ppIdx" >
      <td>{{pp.seqNo}}</td>
      <td>({{pp.paramType}})</td>
      <td>
          <mat-form-field appearance="outline" color="accent" *ngIf="pp.paramType === 'DATE';">
              <mat-label>{{pp.paramPrompt}}</mat-label>
              <input
              matInput
              [matDatepicker]="startDatePicker"
              placeholder="MM/DD/YYYY"
              formControlName="fc_{{pp.seqNo}}"
              name="fc_{{pp.seqNo}}"
              max="9999-12-31"
              required
              class="ng-trim-ignore"
              id="fc_{{pp.seqNo}}"
              />
              <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #startDatePicker></mat-datepicker>
              </mat-form-field>

          <mat-form-field *ngIf="pp.paramType === 'STRING';" appearance="outline" color="accent">
              <mat-label>{{pp.paramPrompt}}</mat-label>
              <input matInput placeholder="Enter Parameter Value" required formControlName="fc_{{pp.seqNo}}"/>
          </mat-form-field>

          <mat-form-field *ngIf="pp.paramType === 'INTEGER';" appearance="outline" color="accent">
              <mat-label>{{pp.paramPrompt}}</mat-label>
              <input matInput placeholder="Enter Parameter Value" required formControlName="fc_{{pp.seqNo}}"/>
          </mat-form-field>

          <mat-form-field appearance="outline" color="accent" *ngIf="pp.paramType === 'BOOLEAN';">
            <mat-label>{{pp.paramPrompt}}</mat-label>
            <mat-select matNativeControl formControlName="fc_{{pp.seqNo}}" required>
            <mat-option value="true">Yes</mat-option>
            <mat-option value="false">No</mat-option>
            </mat-select>
          </mat-form-field>
      </td>
    </tr>
  </table>
  </form>
</mat-dialog-content>

<mat-dialog-actions class="fennec-controls">
  <button type="submit" mat-flat-button color="accent" (click)="submit()">
    <mat-icon>workspaces_outline</mat-icon> Execute
  </button>
  <button type="button" mat-stroked-button (click)="close()">
    <mat-icon>cancel</mat-icon> Close
  </button>
</mat-dialog-actions>
