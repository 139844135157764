import {Injectable} from '@angular/core';
import {
  AdjustmentExplanationCodePacket,
  BaseCrudService,
  Crud,
  DataResponse,
  HolidayQueryPacket, PagedResponse,
  UserGroupQueryPacket,
  UserGroupPacket
} from "xf-common";
import {HttpClient} from "@angular/common/http";
import {Observable, of} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UserGroupService extends BaseCrudService<UserGroupQueryPacket> {

  baseEndpoint = super.apiEndpoint("user-group");

  constructor(public override httpClient: HttpClient) {
    super(httpClient, "UserGroupService", "UserGroup");
  }

  changeStatus = (group: UserGroupPacket): Observable<DataResponse<UserGroupQueryPacket>> => {
    const endpoint = `${this.baseEndpoint}/status`;
    return this.httpClient.put<DataResponse<UserGroupQueryPacket>>(endpoint, group);
  };

  getUserGroupsByParams = (packet: any, first: number, max: number) => {
    const endpoint = `${this.baseEndpoint}/list?first=${first}&max=${max}`;
    return this.httpClient.post<PagedResponse<UserGroupQueryPacket>>(endpoint, packet);
  }

  _list: Crud.ListFunction<UserGroupQueryPacket> = (first: number, max: number) => {
    const endpoint = `${this.baseEndpoint}/list`;
    return this.httpClient.get<PagedResponse<UserGroupQueryPacket[]>>(endpoint, {params: {first, max}});
  };
  _getById: Crud.GetByIdFunction<UserGroupQueryPacket> = (id: number) => {
    const endpoint = `${this.baseEndpoint}/${id}`;
    return this.httpClient.get<DataResponse<UserGroupQueryPacket>>(endpoint);
  };
  _create: Crud.CreateFunction<UserGroupQueryPacket> = (el: UserGroupPacket) => {
    const endpoint = `${this.baseEndpoint}/create`;
    return this.httpClient.post<DataResponse<UserGroupQueryPacket>>(endpoint, el);
  };
  _update: Crud.UpdateFunction<UserGroupPacket> = (el: UserGroupPacket) => {
    const endpoint = `${this.baseEndpoint}/update`;
    return this.httpClient.put<DataResponse<UserGroupQueryPacket>>(endpoint, el);
  };
  _deactivate: Crud.DeleteFunction<UserGroupQueryPacket> = (ids: number[]) => {
    const endpoint = `${this.baseEndpoint}/deactivate/${ids[0]}`;
    return this.httpClient.delete<DataResponse<boolean>>(endpoint);
  };
  _delete: Crud.DeleteFunction<UserGroupQueryPacket> = (ids: number[]) => {
    return of(new DataResponse(false));
  };


  protected _crudFunctions: Crud.CrudServiceDef<UserGroupQueryPacket> = {
    _list: this._list,
    _getById: this._getById,
    _create: this._create,
    _update: this._update,
    _deactivate: this._deactivate,
    _delete: this._delete
  }
}
