import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import {
  BaseCrudComponent,
  CRUDDeleteStatusChangeDialogComponent,
  DRGCodePacket,
  FennecSnackbarService,
  Logger,
  UserProfilePacket
} from 'xf-common';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { KeycloakService } from "keycloak-angular";
import { FormControl, FormGroup } from '@angular/forms';
import { DRGCodeService } from '../drg-code.service';
import { DRGCodeDialogComponent } from '../drg-code-dialog/drg-code-dialog.component';


@Component({
  selector: 'app-drg-code-list',
  templateUrl: './drg-code-list.component.html',
  styleUrls: ['../../../../lib/styles/system-admin-styles.component.scss']
})
export class DRGCodeListComponent extends BaseCrudComponent<DRGCodePacket, DRGCodeService> implements AfterViewInit, OnInit {

  log: Logger = new Logger("DRGCodeListComponent");
  displayedColumns = ["code", "description", "active", "actions"];

  dialogRef?: MatDialogRef<any>;

  @ViewChild("page", { read: ElementRef })
  element?: ElementRef;

  formGroup: FormGroup;
  filtersOpen: boolean = false;

  isAdmin = false;
  isCRUDAdmin = false;

  constructor(
    protected override snack: FennecSnackbarService,
    protected drgCodeService: DRGCodeService,
    protected dialog: MatDialog,
    private renderer: Renderer2,
    protected keycloakService: KeycloakService
  ) {
    super(drgCodeService);

    this.isAdmin = keycloakService.isUserInRole("ADMIN");
    this.isCRUDAdmin = keycloakService.isUserInRole("CRUD_ADMIN");
    this.canCreate = this.isAdmin || this.isCRUDAdmin || keycloakService.isUserInRole("DRG_CODE_CREATE");
    this.canUpdate = this.isAdmin || this.isCRUDAdmin || keycloakService.isUserInRole("DRG_CODE_UPDATE");
    this.canDelete = this.isAdmin || this.isCRUDAdmin || keycloakService.isUserInRole("DRG_CODE_DELETE");
    this.canDeactivate = this.isAdmin || this.isCRUDAdmin || keycloakService.isUserInRole("DRG_CODE_DELETE");
    this.canView = this.isAdmin || this.isCRUDAdmin || keycloakService.isUserInRole("DRG_CODE_VIEW");

    this.formGroup = this.createFormGroup();
  }

  ngOnInit() {
    this.executeListQuery();
  }

  ngAfterViewInit(): void {
    this.selection.changed.subscribe(change => this.selectedRow = change.added[0] ?? change.removed[0] ?? undefined);
    if (this.element?.nativeElement) {
      this.renderer.listen(this.element.nativeElement, "keyup", (event) => this.keyEvent(event));
    }
    if (this.paginator) {
      this.paginator.page.subscribe(() => {
        this.executeListQuery();
      })
    }
  }

  // Old, keeping just in case
  // executeListQuery() {
  //   const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
  //   const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;
  //   this.paginator.pageIndex = first;
  //   this.paginator.pageSize = pageSize;
  //   this.list();
  // }

  onCreate(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      mode: "Create"
    };
    dialogConfig.autoFocus = "first-tabbable";
    dialogConfig.width = "35%";
    this.dialogRef = this.dialog.open(DRGCodeDialogComponent, dialogConfig);
    this.dialogRef.afterClosed().subscribe((value?: DRGCodePacket) => {
      if (value) {
        this.create(value).then(() => {this.executeListQuery()});
      }
    });
  }

  onDelete = (ids: number[]) => {
    if (ids.length == 1) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        mode: "delete",
        source: ids,
        label: "DRG Code"
      };
      this.dialogRef = this.dialog.open(CRUDDeleteStatusChangeDialogComponent, dialogConfig);
      this.dialogRef.afterClosed().subscribe({
        next: (value?: number[]) => {
          if (value) {
            this.performXFRequest({
              requestDescription: "Delete discharge code status",
              requestFn: this.drgCodeService._delete,
              fnParams: [value],
              onSuccess: data => {
                super.showSuccessSnack("Successful!");
                this.executeListQuery();
              },
              onError: errString => {
                super.showErrorSnack(errString);
              }
            })
          }
        }
      });
    }
  }

  openDRGCodeStatusChangeDialog(drgCodePacket: DRGCodePacket) {
    if (drgCodePacket) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        mode: "status",
        source: drgCodePacket,
        label: "DRG Code"
      };
      this.dialogRef = this.dialog.open(CRUDDeleteStatusChangeDialogComponent, dialogConfig);
      this.dialogRef.afterClosed().subscribe({
        next: (value?: DRGCodePacket) => {
          if (value) {
            this.performXFRequest({
              requestDescription: "UPDATE drg code status",
              requestFn: this.drgCodeService.changeStatus,
              fnParams: [value],
              onSuccess: data => {
                super.showSuccessSnack("Successful!");
                this.executeListQuery();
              },
              onError: errString => {
                super.showErrorSnack(errString);
              }
            })
          }
        }
      });
    }
  }

  onEdit = (id?: number) => {
    if (!id) {
      return;
    }
    this.getById(id)
      .then(response => {
        if (response && response.data) {
          this.openEditDialog(response.data)
        }
      })
      .catch(error => super.showErrorSnack(error));
  }

  onStatusChange = (id?: number) => {
    if (!id) {
      return;
    }
    this.getById(id)
      .then(response => {
        if (response && response.data) {
          this.openDRGCodeStatusChangeDialog(response.data)
        }
      })
      .catch(error => super.showErrorSnack(error));
  }

  keyEvent(event: KeyboardEvent) {
    if (this.filtersOpen) {
      return;
    }

    const selected = this.selection?.selected;

    switch (event.key) {
      case "c":
        this.onCreate();
        break;
      case "e":
        if (this.selectedRow) {
          this.onEdit(this.selectedRow.id);
        }
        break;
    }
  }

  onSelect(row: UserProfilePacket | undefined) {
    if (row == null) {
      return;
    }
    row.selected = !row.selected;

    this.selection?.toggle(row);
  }

  openEditDialog(drgCodePacket: DRGCodePacket) {
    if (drgCodePacket) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        mode: "Edit",
        dischargeStatus: drgCodePacket
      };
      dialogConfig.autoFocus = "first-tabbable";
      dialogConfig.width = "35%";
      this.dialogRef = this.dialog.open(DRGCodeDialogComponent, dialogConfig);
      this.dialogRef.afterClosed().subscribe({
        next: (value?: UserProfilePacket) => {
          if (value) {
            this.update(value).then(() => {this.executeListQuery()});;
          }
        }
      });
    }
  }

  createFormGroup = () => {
    return new FormGroup({
      code: new FormControl(),
      description: new FormControl(),
      active: new FormControl(true)
    })
  }

  clearSearch = () => {
    this.formGroup = this.createFormGroup();
  }

  executeListQuery = () => {
    const controls = this.formGroup?.controls;

    const searchParams = {
      code: controls["code"].value?.trim() ?? null,
      description: controls["description"].value?.trim() ?? null,
      active: controls["active"].value,
    }

    if(searchParams.active === "all") {
      searchParams.active = null;
    }

    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;
    this.performXFRequest({
      requestDescription: "GET Drg Code By Search Params",
      requestFn: this.drgCodeService.getDRGCodesByParams,
      fnParams: [searchParams, first, pageSize],
      onResponse: response => {
        this.dataSource = response.data;
        this.totalRowCount = response['totalRowCount'];
      },
      onError: errString => {
        super.showErrorSnack(errString)
      }
    })
  }

  onSearch = () => {
    this.paginator?.firstPage();
    this.executeListQuery();
  }
}
