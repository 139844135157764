import { Component, ViewChild } from "@angular/core";
import { BaseComponent } from "../base.component";
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { MatLegacyPaginator as MatPaginator } from "@angular/material/legacy-paginator";
import { BaseCrudService } from "./base.crud.service";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";

import { MatSort } from "@angular/material/sort";
import { SelectionModel } from "@angular/cdk/collections";
import { Subject } from "rxjs";
import { Deserializable } from "../../model/deserializable";
import { Crud } from "../../util/common-handlers";
import { XFRequestHandlers } from "../../model/safe-request";
import { DataResponse } from "../../model/data-response";
import { PagedResponse } from "../../model/paged-response";


@Component({
  template: ''
})
export abstract class BaseCrudComponent<T extends Deserializable<T>, S extends BaseCrudService<T>> extends BaseComponent {
  abstract displayedColumns: string[];

  public readonly defaultPage = 0;
  public readonly defaultPageSize = 25;
  public readonly defaultPageSizeOptions = [10, 25, 50];
  public totalRowCount = 0;

  dataSource: MatTableDataSource<T> = new MatTableDataSource<T>();
  selection = new SelectionModel<T>(true, []);
  selectedRow?: T;
  filtersChanged$ = new Subject<void>();

  canView: boolean = false;
  canCreate: boolean = false;
  canUpdate: boolean = false;
  canDeactivate: boolean = false;
  canDelete: boolean = false;

  @ViewChild(MatSort)
  sort?: MatSort;

  @ViewChild(MatPaginator)
  _paginator?: MatPaginator;

  get paginator(): MatPaginator {
    return this._paginator ?? {} as MatPaginator;
  }

  genericCrudHandlers: Crud.GenericCrudHandlers = Crud.getGenericCrudHandlers(this, this.dataSource);

  protected constructor(protected entityService: BaseCrudService<T>) {
    super();
  }

  list = (customHandlers?: XFRequestHandlers): Promise<PagedResponse<T[]>> => {
    const self = this;
    return new Promise<PagedResponse<T[]>>((next, error) => {
      self.performXFRequest(self.entityService.listRequest
        .def({
          fnParams: [
            self.paginator?.pageIndex ?? self.defaultPage,
            self.paginator?.pageSize ?? self.defaultPageSize
          ],
          cancelRequest$: self.filtersChanged$.asObservable()
        })
        .handle(self.genericCrudHandlers[Crud.CrudOperations.LIST])
        .handle({
          onResponse: val => next(val),
          onError: val => error(val)
        })
        .handle(customHandlers)
      );
    });
  }

  getById = (id: number): Promise<DataResponse<T>> => {
    const self = this;
    return new Promise<DataResponse<T>>((next, error) => {
      self.performXFRequest(self.entityService.getByIdRequest
        .def({ fnParams: [id] })
        .handle(self.genericCrudHandlers[Crud.CrudOperations.GET_BY_ID])
        .handle({
          onResponse: val => next(val),
          onError: val => error(val)
        })
      );
    });
  }

  create = (el: T): Promise<DataResponse<T>> => {
    const self = this;
    return new Promise<DataResponse<T>>((next, error) => {
      self.performXFRequest(self.entityService.createRequest
        .def({ fnParams: [el] })
        .handle(self.genericCrudHandlers[Crud.CrudOperations.CREATE])
        .handle({
          onResponse: val => {
            next(val);
            super.showSuccessSnack("Successfuly Created!");
          },
          onError: val => {
            error(val);
            super.showErrorSnack(val);
          }
        })
      );
    });
  }

  update = (el: T, customHandlers?: XFRequestHandlers): Promise<DataResponse<T>> => {
    const self = this;
    return new Promise<DataResponse<T>>((next, error) => {
      self.performXFRequest(self.entityService.updateRequest
        .def({ fnParams: [el] })
        .handle(self.genericCrudHandlers[Crud.CrudOperations.UPDATE])
        .handle({
          onResponse: val => {
            next(val);
            super.showSuccessSnack("Successfully Updated!");
          },
          onError: val => {
            error(val);
            super.showErrorSnack(val);
          }
        })
        .handle(customHandlers)
      );
    });
  }

  deactivate = (ids: number[]): Promise<DataResponse<boolean>> => {
    const self = this;
    return new Promise<DataResponse<boolean>>((next, error) => {
      self.performXFRequest(self.entityService.deactivateRequest
        .def({ fnParams: [ids] })
        .handle(self.genericCrudHandlers[Crud.CrudOperations.DEACTIVATE])
        .handle({
          onResponse: val => next(val),
          onError: val => error(val)
        })
      );
    });
  }

  delete = (ids: number[]): Promise<DataResponse<boolean>> => {
    const self = this;
    return new Promise<DataResponse<boolean>>((next, error) => {
      self.performXFRequest(self.entityService.deleteRequest
        .def({ fnParams: [ids] })
        .handle(self.genericCrudHandlers[Crud.CrudOperations.DELETE])
        .handle({
          onResponse: val => {
            next(val)
            this.showSuccessSnack("Successfully Deleted");
          },
          onError: val => {
            error(val);
            this.snack.showErrorSnack(val);
          }

        })
      );
    });
  }

  abstract onEdit: (id: number | undefined) => void;
  abstract onDelete: (ids: any[]) => void;
}
