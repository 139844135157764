import { AfterViewInit, Component, OnInit } from '@angular/core';
import { HolidayService } from "@app/admin/holiday-admin/holiday.service";
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { KeycloakService } from "keycloak-angular";
import { HolidayEditDialogComponent } from "@app/admin/holiday-admin/holiday-edit-dialog/holiday-edit-dialog.component";
import { BaseCrudComponent, FennecSnackbarService, HolidayQueryPacket, Logger } from "xf-common";
import {config} from "rxjs";


@Component({
  selector: 'app-holiday-list',
  templateUrl: './holiday-list.component.html',
  styleUrls: ['../../../../lib/styles/system-admin-styles.component.scss']
})
export class HolidayListComponent extends BaseCrudComponent<HolidayQueryPacket, HolidayService> implements OnInit, AfterViewInit {

  protected log = new Logger("HolidayListComponent");
  displayedColumns = ["name", "date", "actions"];
  editDialogRef?: MatDialogRef<HolidayEditDialogComponent>;

  constructor(
    override snack: FennecSnackbarService,
    protected holidayService: HolidayService,
    protected dialog: MatDialog,
    protected keycloakService: KeycloakService
  ) {
    super(holidayService);
  }

  ngOnInit(): void {
    this.list();
  }

  ngAfterViewInit() {
    if (this.paginator) {
      this.paginator.page.subscribe(() => {
        this.list();
      })
    }
  }

  onCreate = () => {
    const config = new MatDialogConfig();

    this.editDialogRef = this.dialog.open(HolidayEditDialogComponent, config);
    this.editDialogRef.afterClosed().subscribe((val) => {
      if (val) {
        this.create(val);
      }
    });
  };

  onEdit = (holiday: any): void => {
    const config = new MatDialogConfig();
    config.data = {
      mode: "Edit",
      holiday: holiday
    };

    this.editDialogRef = this.dialog.open(HolidayEditDialogComponent, config);
    this.editDialogRef.afterClosed().subscribe((val) => {
      if (val) {
        this.update(val);
      }
    });
  };

  onDelete = (ids: any[]): void => {
  }

}
