import { Component, Inject, OnInit } from '@angular/core';
import { HealthCarePlanService } from '../health-care-plan.service';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {
  BaseComponent,
  CompanyQueryPacket001,
  CompanyService,
  FennecSnackbarService,
  HealthCarePlanPacket,
  Logger
} from 'xf-common';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-update-assigned-company-dialog',
  templateUrl: './health-care-plan-update-assigned-company-dialog.component.html',
  styleUrls: ['./health-care-plan-update-assigned-company-dialog.component.scss']
})
export class HealthCarePlanUpdateAssignedCompanyDialogComponent extends BaseComponent implements OnInit {

  log: Logger = new Logger("HealthCarePlanUpdateAssignedCompanyDialogComponent");

  userPrimaryClientList: CompanyQueryPacket001[] = [];
  formGroup:FormGroup;
  healthCarePlanId:number = -1;
  healthCarePlanName:string|null = null;

  constructor(
    private healthCarePlanService: HealthCarePlanService,
    protected dialogRef: MatDialogRef<HealthCarePlanUpdateAssignedCompanyDialogComponent>,
    protected snack: FennecSnackbarService,
    protected companyService: CompanyService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    super();
    if (data.healthCarePlanId) {
      this.healthCarePlanId = data.healthCarePlanId;
    }

    if (data.healthCarePlanName) {
      this.healthCarePlanName = data.healthCarePlanName;
    }

    this.formGroup = new FormGroup({
      company: new FormControl(data.companyId ?? "")
    });
  }

  ngOnInit(): void {
    this.getUserPrimaryClientList();
  }

  getUserPrimaryClientList() {
    this.companyService.getActiveUserProfilePrimaryClientCompanies(0, 9999).subscribe(
      response => {
        if (response.hasErrors) {
          this.snack.showErrorSnack(response.consolidatedErrorMessage != null ? response.consolidatedErrorMessage : "");
        } else {
          this.userPrimaryClientList = response.data;
          if (this.userPrimaryClientList.length > 0) {
            this.formGroup.controls["company"].setValue(this.userPrimaryClientList[0].companyId);
          }
        }
      }
    );
  }

  cancel() {
    let returnObj = {
      confirm: false
    };
    this.dialogRef.close(returnObj);
  }

  submit() {
    const dto = new HealthCarePlanPacket();
    dto.id = this.healthCarePlanId;
    dto.companyId = this.formGroup.controls['company'].value


    this.performXFRequest({
      requestDescription: `Update Health Care Plan Assigned Company`,
      requestFn: this.healthCarePlanService.updateHealthCarePlanAssignedCompany,
      fnParams:[dto],
      onResponse: response => {
        let returnObj = {
          confirm: true
        };
        this.dialogRef.close(returnObj);
      },
      onError: errString => {
        super.showErrorSnack(errString)
      }
    })
  }

}
