import { Component, Inject, OnInit } from '@angular/core';
import { BaseComponent, FennecSnackbarService, Logger } from 'xf-common';
import { HealthCarePlanService } from '../health-care-plan.service';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UserProfileService } from '@app/user/user-profile.service';


@Component({
  selector: 'app-mi-case-plan-edit-dialog',
  templateUrl: './mi-case-plan-edit-dialog.component.html',
  styleUrls: ['./mi-case-plan-edit-dialog.component.scss']
})
export class MiCasePlanEditDialogComponent extends BaseComponent implements OnInit {
  protected override log: Logger = new Logger("MiCasePlanEditDialogComponent");

  companyId: number = -1;
  planList: any[] = [];
  userProfileId: number = -1;
  selectedPlanId: number = -1;
  initialPlanId: number = -1;

  changed:Boolean = false;
  displayColumns = ["select", "name", "state"];

  constructor(
    protected override snack: FennecSnackbarService,
    public healthCarePlanService: HealthCarePlanService,
    public dialogRef: MatDialogRef<MiCasePlanEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private userProfileService: UserProfileService
  ) {
    super();

    if (data.primaryClientId) {
      this.companyId = data.primaryClientId;
    }

    if (data.currentPlanId) {
      this.initialPlanId = data.currentPlanId;
    }
  }

  ngOnInit(): void {
    this.getUserProfile();
  }

  getUserProfile() {
    this.userProfileService.getUserProfile().subscribe((response: any) => {
      if (!response.hasErrors) {
        this.userProfileId = response?.data?.id;
        this.getPlans();
      }else {
        this.showErrorSnack("Error accessing User Profile information");
      }
    });
  }

  getPlans = () => {
    const dto = {
      userProfileId: this.userProfileId,
      companyId: this.companyId
    }

    this.performXFRequest({
      requestDescription: "Get Plans for Primary Client",
      requestFn: this.healthCarePlanService.getHealthCarePlansForUserTenantCompanyIdAndUserId,
      fnParams: [dto],
      onSuccess: data => {
        this.planList = data;
      },
      onError: errString => {
        super.showErrorSnack(errString);
      }
    });
  }

  cancel() {
    this.dialogRef.close({
      confirm: false
    });
  }

  submit() {
    this.dialogRef.close({
      confirm:true,
      healthCarePlanId: this.selectedPlanId,
    })
  }

  selectPlan(planId:number) {
    this.selectedPlanId = planId;
    if(planId == this.initialPlanId) {
      this.changed = false;
    }else {
      this.changed = true;
    }
  }
}
