<div mat-dialog-title>
  Override Earliest {{mode}} Report Publish Date for MICase ID:{{miCaseId}}
</div>

<mat-dialog-content>
	<form [formGroup]="formGroup" class="alt-theme">
		<mat-form-field [style.width.px]=175>
			<input matInput formControlName="date">
		</mat-form-field>
	</form>
</mat-dialog-content>

<mat-dialog-actions class="fennec-controls" style="float:right;">
	<button 
			[disabled]="formGroup.invalid"
      mat-flat-button 
      (click)="submit()" 
      color="accent">
        <mat-icon>save</mat-icon> Save
    </button>
    <button 
      mat-button 
      mat-stroked-button 
      (click)="close()">
        <mat-icon>cancel</mat-icon> Cancel
    </button>
</mat-dialog-actions>