
<div matDialogTitle>
    <div>
    Status Track Log
    </div>
    <div class="status-track-log-dialog-sub-title">
    Status Track Domain: <b>{{configData?.statusTrackDomain}}</b>
    Status Track Element: <b>{{configData?.statusTrackElement}}</b>
    Status Track Related Id: <b>{{configData?.statusTrackRelatedId}}</b>
    Status Track Id: <b>{{configData?.statusTrackId}}</b>
    </div>
  </div>
  
  <mat-dialog-content>

    <mat-table [dataSource]="dataSource">

      <ng-container matColumnDef="statusTrackStatus">
        <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
        <mat-cell *matCellDef="let c"> {{c.statusTrackStatus}} </mat-cell>
      </ng-container>       

        <ng-container matColumnDef="createdBy">
            <mat-header-cell *matHeaderCellDef> Created By </mat-header-cell>
            <mat-cell *matCellDef="let c"> {{c.createdBy}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="createdDate">
          <mat-header-cell *matHeaderCellDef> Created Date </mat-header-cell>
          <mat-cell *matCellDef="let c">
            {{c.createdDate | date: 'MM/dd/yyyy'}} {{c.createdDate | date: 'shortTime'}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="lastModifiedDate">
          <mat-header-cell *matHeaderCellDef> Last Updated </mat-header-cell>
          <mat-cell *matCellDef="let c">
            {{c.lastModifiedDate | date: 'MM/dd/yyyy'}} <br> {{c.lastModifiedDate | date: 'shortTime'}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="lastModifiedBy">
            <mat-header-cell *matHeaderCellDef> Last Modified By </mat-header-cell>
            <mat-cell *matCellDef="let c"> {{c.lastModifiedBy}} </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayColumns;"></mat-row>
    </mat-table>
    <mat-paginator #adPaginator [length]="totalRowCount" [pageSize]="defaultPageSize" [pageSizeOptions]="pageSizeOptions"></mat-paginator>

  </mat-dialog-content>
  
  <mat-dialog-actions>
    <button
      mat-stroked-button
      (click)="close()"
    >
      <mat-icon>close</mat-icon>
      Close
    </button>
  </mat-dialog-actions>