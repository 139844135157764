<div class="wrapper">
  <div class="header">
    <h2>{{getHeaderText()}}</h2>
    <button
      mat-icon-button
      (click)="close()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <form [formGroup]="formGroup">
    <div class="form-wrapper alt-theme">
      <mat-form-field appearance="outline">
        <mat-label>Name</mat-label>
        <input matInput placeholder="Name" formControlName="name">
      </mat-form-field>
      
      <mat-form-field appearance="outline" class="spacer">
        <mat-label>Event Definition Code</mat-label>
        <input matInput placeholder="Event Definition Code" formControlName="eventDefinitionCode">
        <mat-hint [ngStyle]="getValidationIconStyleString()">
          <mat-icon *ngIf="codeValidationText != ''">{{ getValidationIconString() }}</mat-icon>
          {{codeValidationText}}
        </mat-hint>
      </mat-form-field>

      <div>
        <mat-form-field appearance="outline" class="code-input">
          <mat-label>Adjustment Reason Code</mat-label>
          <input matInput formControlName="adjustmentReasonCode">
        </mat-form-field>

        <mat-form-field appearance="fill" class="code-description">
          <mat-label>Adjustment Reason Code Description</mat-label>
          <input matInput formControlName="adjustmentReasonCodeDescription">
        </mat-form-field>
      </div>

      <div>
        <mat-form-field appearance="outline" class="code-input">
          <mat-label>Adjustment Explanation Code</mat-label>
          <input matInput formControlName="adjustmentExplanationCode">
        </mat-form-field>

        <mat-form-field appearance="fill" class="code-description">
          <mat-label>Adjustment Explanation Code Description</mat-label>
          <input matInput formControlName="adjustmentExplanationCodeDescription">
        </mat-form-field>
      </div>
  
      <mat-form-field appearance="outline">
        <mat-label>Description</mat-label>
        <textarea matInput cdkTextareaAutosize rows="4" placeholder="Description" formControlName="description"></textarea>
      </mat-form-field>
    </div>
    
  </form>

  <div [ngClass]="['alt-theme', 'btn-group']">
    <button
      mat-stroked-button
      color="accent"
      [disabled]="!formGroup.valid"
      (click)="onSubmit()"
    >
      <mat-icon>save</mat-icon> Save
    </button>

    <button
      mat-stroked-button
      color="primary"
      (click)="close()"
    >
      <mat-icon>cancel</mat-icon> Cancel
    </button>
  </div>
</div>