<lib-page>
  <ng-container pageTitle>
    <span>Health Care Plans</span>
    <button *ngIf="mode === 'USER_PROFILE'" class="delete-all-button" mat-flat-button (click)="deleteAllHCPlanRelationalMap()">
      <mat-icon>delete</mat-icon>
      Delete All
    </button>
    <button class="add" mat-flat-button (click)="openDialog()">
      <mat-icon>add_circle</mat-icon>
      Add Plan
    </button>
  </ng-container>

  <ng-container pageContent>
    
    <mat-table [dataSource]="dataSource">

      <ng-container matColumnDef="companyName">
        <mat-header-cell *matHeaderCellDef> Company </mat-header-cell>
        <mat-cell *matCellDef="let plan"> {{plan.companyName}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
        <mat-cell *matCellDef="let plan"> {{plan.name}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="state">
        <mat-header-cell *matHeaderCellDef> State </mat-header-cell>
        <mat-cell *matCellDef="let plan"> {{plan.stateCode}} -- {{plan.stateName}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="active">
        <mat-header-cell *matHeaderCellDef> Active </mat-header-cell>
        <mat-cell *matCellDef="let plan">
          <mat-icon>{{plan.relationalMapActive ? "check_small" : "close"}}</mat-icon>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> activate actions </mat-header-cell>
        <mat-cell *matCellDef="let plan">
            <button mat-flat-button color="primary" (click)="openDeactivateDialog(plan)">{{plan.relationalMapActive ? "Deactivate" : "Activate"}}</button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayColumns;"></mat-row>
    </mat-table>
    <mat-paginator [length]="totalRowCount" [pageSize]="defaultPageSize" [pageSizeOptions]="pageSizeOptions"></mat-paginator>

    <ng-container *ngIf="dataSource.data.length == 0">
      <div class="no-data-message">
        <div style="text-align:center;">
          <h3 style="color:red;">No Health Care Plans found for {{getFormattedMode()}} ID:{{relatedId}}</h3>
          <button
              mat-button
              color="accent"
              (click)="openDialog()"
            >
              <mat-icon>add_circle</mat-icon>
              Add Plan
            </button>  
        </div>
      </div>
    </ng-container>
  </ng-container>
</lib-page>
