import {Deserializable, deserializeAssign} from "./deserializable";

export class DataResponse<T> extends Deserializable<DataResponse<T>> {
  constructor(public data?: T) {
    super();
  }

  errors?: string[];
  consolidatedErrorMessage?: string;

  get hasErrors(): boolean {
    return (this.errors?.length ?? 0) > 0;
  }

  override deserialize(input: any): DataResponse<T> {
    return deserializeAssign(this, input);
  }
}
