<lib-page>
  <ng-container pageTitle>
      Revenue Code Summary for Itemized Revision: {{itemizedRevisionId}}
  </ng-container>
  <ng-container pageContent>
    <div class="revenue-code-summary-table-wrapper">
    <table class="revenue-code-summary-table">
      <tr class="revenue-code-summary-table-header-row">
        <th>Rev Code</th>
        <th colspan="2">[LI grid filters]</th>
        <th>Description</th>
        <th>UB04 Units</th>
        <th>UB04 Amount</th>
        <th>LI Count</th>
        <th>LI Units</th>
        <th>LI Total</th>
        <th>(M) LI Count</th>
        <th>(M) LI Units</th>
        <th>(M) LI Total</th>
        <th>UB04 - LI Total</th>
        <th>UB04 - (M) LI Total</th>
      </tr>
      <tr *ngFor="let row of revenueCodeSummaryData.revenueCodeSummaryLines">
        <td>{{row.revenueCode}}</td>
        <td><button *ngIf="row.revenueCode != '99999' && row.iliRevenueCodeLineItemCount > 0" style="width: 100%" (click)="onRevenueCodeSelected(row.revenueCode)">RC</button></td>
        <td><button *ngIf="row.revenueCode != '99999' && row.iliMappedRevenueCodeLineItemCount > 0" style="width: 100%" (click)="onMappedRevenueCodeSelected(row.revenueCode)">mRC</button></td>
        <td class="revenue-code-summary-table-description-cell">{{row.revenueCodeDescription}}</td>
        <td class="revenue-code-summary-table-dec-cell"
        [ngStyle]="{'background-color': row.ub04Units != row.iliMappedRevenueCodeUnits ? 'yellow': 'white', 'font-weight': row.ub04Units != row.iliMappedRevenueCodeUnits ? 'bold': 'normal' }">
          {{row.ub04Units | number: '1.2-2'}}
        </td>
        <td class="revenue-code-summary-table-currency-cell"
        [ngStyle]="{'background-color': (row.iliRevenueCodeTotal != row.iliMappedRevenueCodeTotal) || (row.ub04Total != row.iliMappedRevenueCodeTotal) ? 'yellow': 'white', 'font-weight': (row.iliRevenueCodeTotal != row.iliMappedRevenueCodeTotal) || (row.ub04Total != row.iliMappedRevenueCodeTotal) ? 'bold': 'normal' }">
          {{row.ub04Total | currency}}
        </td>
        <td class="revenue-code-summary-table-int-cell"
          [ngStyle]="{'background-color': row.iliRevenueCodeLineItemCount != row.iliMappedRevenueCodeLineItemCount ? 'yellow': 'white', 'font-weight': row.iliRevenueCodeLineItemCount != row.iliMappedRevenueCodeLineItemCount ? 'bold': 'normal' }">
          {{row.iliRevenueCodeLineItemCount}}
        </td>
        <td class="revenue-code-summary-table-dec-cell"
          [ngStyle]="{'background-color': row.iliRevenueCodeUnits != row.iliMappedRevenueCodeUnits ? 'yellow': 'white', 'font-weight': row.iliRevenueCodeUnits != row.iliMappedRevenueCodeUnits ? 'bold': 'normal' }">
          {{row.iliRevenueCodeUnits | number: '1.2-2'}}
        </td>
        <td class="revenue-code-summary-table-currency-cell"
          [ngStyle]="{'background-color': row.iliRevenueCodeTotal != row.iliMappedRevenueCodeTotal ? 'yellow': 'white', 'font-weight': row.iliRevenueCodeTotal != row.iliMappedRevenueCodeTotal ? 'bold': 'normal' }">
          {{row.iliRevenueCodeTotal | currency}}
        </td>
        <td class="revenue-code-summary-table-int-cell"
          [ngStyle]="{'background-color': row.iliRevenueCodeLineItemCount != row.iliMappedRevenueCodeLineItemCount ? 'yellow': 'white', 'font-weight': row.iliRevenueCodeLineItemCount != row.iliMappedRevenueCodeLineItemCount ? 'bold': 'normal' }">
          {{row.iliMappedRevenueCodeLineItemCount}}
        </td>
        <td class="revenue-code-summary-table-dec-cell"
          [ngStyle]="{'background-color': (row.iliRevenueCodeUnits != row.iliMappedRevenueCodeUnits) || (row.ub04Units != row.iliMappedRevenueCodeUnits) ? 'yellow': 'white', 'font-weight': (row.iliRevenueCodeUnits != row.iliMappedRevenueCodeUnits) || (row.ub04Units != row.iliMappedRevenueCodeUnits) ? 'bold': 'normal' }">
          {{row.iliMappedRevenueCodeUnits | number: '1.2-2'}}
        </td>
        <td class="revenue-code-summary-table-currency-cell"
          [ngStyle]="{'background-color': (row.iliRevenueCodeTotal != row.iliMappedRevenueCodeTotal) || (row.ub04Total != row.iliMappedRevenueCodeTotal) ? 'yellow': 'white', 'font-weight': (row.iliRevenueCodeTotal != row.iliMappedRevenueCodeTotal) || (row.ub04Total != row.iliMappedRevenueCodeTotal) ? 'bold': 'normal' }">
          {{row.iliMappedRevenueCodeTotal | currency}}
        </td>
        <td class="revenue-code-summary-table-currency-cell"
          [ngStyle]="{'background-color': (row.iliRevenueCodeTotal != row.iliMappedRevenueCodeTotal) || (row.ub04Total != row.iliMappedRevenueCodeTotal) ? 'yellow': 'white', 'font-weight': (row.iliRevenueCodeTotal != row.iliMappedRevenueCodeTotal) || (row.ub04Total != row.iliMappedRevenueCodeTotal) ? 'bold': 'normal' }">
          {{row.ub04TotalRCTotalDiff | currency}}
        </td>
        <td class="revenue-code-summary-table-currency-cell"
          [ngStyle]="{'background-color': (row.iliRevenueCodeTotal != row.iliMappedRevenueCodeTotal) || (row.ub04Total != row.iliMappedRevenueCodeTotal) ? 'yellow': 'white', 'font-weight': (row.iliRevenueCodeTotal != row.iliMappedRevenueCodeTotal) || (row.ub04Total != row.iliMappedRevenueCodeTotal) ? 'bold': 'normal' }">
          {{row.ub04TotalMRCTotalDiff | currency}}
        </td>        
      </tr>
    </table>
    </div>
  </ng-container>
</lib-page>
