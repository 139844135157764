
<div>
  <div>
    <form [formGroup]="lobSelectFormGroup" class="lob-select-form">
      <mat-form-field>
        <mat-label>Line of Business</mat-label>
        <mat-select formControlName="lob" panelClass="alt-theme">
          <mat-option *ngFor="let lob of lineOfBusinessList" [value]="lob.value">
            {{ lob.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button mat-raised-button mat-icon-button color="accent" style="margin-left: 10px;"
      (click)="onRefreshInfo()">
      <mat-icon>refresh</mat-icon>
      </button>            
    </form>
  </div>
  <form [formGroup]="hcPlanLOBFormGroup">
    <mat-form-field>
      <mat-label>Plan Code</mat-label>
      <input matInput id="planCode" formControlName="planCode"/>
    </mat-form-field>
    <mat-form-field class="hcplan-lob-state-code">
      <mat-label>State Code</mat-label>
      <input matInput id="stateCode" formControlName="stateCode"/>
    </mat-form-field>    
    <button mat-raised-button color="accent" style="margin-left: 10px;"
    (click)="onSave()">
      Save
    </button>            
  </form>
  <div>

  </div>
  
  <div *ngIf="selectedLOB !== null && selectedLOB !== undefined" class="hc-plan-lob-wrapper-div">
    
    <mat-grid-list cols="4" rowHeight="fit" gutterSize="15px">
      <mat-grid-tile>
      <!-- Provider Report Logo Card -->
      <mat-card class="mat-elevation-z8 hcplan-lob-provider-rpt-logo-card"
          [class.hcplan-lob-attachment-present] = "!!fileNameMap.get('PROVIDER_RPT_LOGO')">
        <mat-card-header>
          <mat-card-title>Provider Report Logo</mat-card-title>
        </mat-card-header>
        <mat-card-content style="width: 100%">
          <div *ngIf="hcPlanLOB?.logoAttachmentId !== null && hcPlanLOB?.logoAttachmentId !== undefined" class="hcplan-log-provider-rpt-logo-img-div">
            <img [src]="providerReportLogoAttachment">
          </div>
          <div *ngIf="hcPlanLOB?.logoAttachmentId === null || hcPlanLOB?.logoAttachmentId === undefined" 
            class="hcplan-log-provider-rpt-logo-img-div-not-found">
            No Logo Found
          </div>        

      <!-- Upload File Attachment Div-->
      <div style="width: 100%; text-align:center">
        <h1>Upload Logo</h1>
      </div>
      <div class="hcplan-lob-provder-rpt-logo-upload-inside">
        <div class="input-group alt-theme">
        </div>

        <h5>{{ fileMap.get('PROVIDER_RPT_LOGO') ? "Current file: " + fileMap.get('PROVIDER_RPT_LOGO').name : "No File Chosen" }}</h5>
        <input
          #fileUploadInput
          type="file"
          name="fileUpload"
          data-attachment-type="PROVIDER_RPT_LOGO"
          (change)="addFile(fileUploadInput)"
          [hidden]="true">

          <div class="button-group">
            <button
              [disabled]="uploading"
              class="btn"
              mat-raised-button
              color="primary"
              #upload
              (click)="fileUploadInput.click()">
                <mat-icon>upload</mat-icon> Choose file
            </button>

            <button
              class="btn hcplan-log-provider-rpt-logo-add-btn"
              mat-raised-button
              color="accent"
              (click)="onSaveAttachment('PROVIDER_RPT_LOGO')"
              [disabled]="disableSave('PROVIDER_RPT_LOGO')"
              >
              <mat-icon>save</mat-icon>
              {{uploading ? "Uploading..." : "Save"}}
            </button>

            <button *ngIf="hcPlanLOB?.logoAttachmentId !== null && hcPlanLOB?.logoAttachmentId !== undefined"
              class="btn hcplan-log-provider-rpt-logo-add-btn"
              mat-raised-button
              color="accent"
              (click)="deleteAttachmentConfirmation('PROVIDER_RPT_LOGO')">
              <mat-icon>delete</mat-icon>
            </button>          

          </div>

        </div>

        <div *ngIf="uploading" class="hcplan-lob-logo-loading">
          Loading...please wait
        </div>

        </mat-card-content>

        <mat-card-actions>
        </mat-card-actions>
      </mat-card>
      </mat-grid-tile>

      <mat-grid-tile *ngFor="let ibTemplate of ibRequestTemplateUIConfig">
      <!-- IB Request Letter Template Attachment Card -->
      <mat-card class="mat-elevation-z8 hcplan-lob-provider-rpt-logo-card"
        [class.hcplan-lob-attachment-present] = "!!fileNameMap.get(ibTemplate.attachmentType)  && !!fileStatusMap.get(ibTemplate.attachmentType)">
        <mat-card-header>
          <mat-card-title>{{ibTemplate.cardTitle}}</mat-card-title>
        </mat-card-header>
        <mat-card-content style="width: 100%">       

      <!-- Upload File Attachment Div-->
      <div style="width: 100%; text-align:center">
        <h1>IB Request Template (HTML)</h1>
      </div>
      <div class="hcplan-lob-provder-rpt-logo-upload-inside">
        <div class="input-group alt-theme">
        </div>

        <h5>{{ fileNameMap.get(ibTemplate.attachmentType) ? "Current file: " + fileNameMap.get(ibTemplate.attachmentType) : "No File Chosen" }}</h5>
        <input
          #fileUploadInput
          type="file"
          name="fileUpload"
          [attr.data-attachment-type]="ibTemplate.attachmentType"
          (change)="addFile(fileUploadInput)"
          [hidden]="true">

          <div class="button-group">
            <button *ngIf="fileStatusMap.get(ibTemplate.attachmentType) === null || fileStatusMap.get(ibTemplate.attachmentType) === undefined"
              [disabled]="uploading"
              class="btn"
              mat-raised-button
              color="primary"
              #upload
              (click)="fileUploadInput.click()">
                <mat-icon>upload</mat-icon> Choose file
            </button>

            <button *ngIf="fileStatusMap.get(ibTemplate.attachmentType) === null || fileStatusMap.get(ibTemplate.attachmentType) === undefined"
              class="btn hcplan-log-provider-rpt-logo-add-btn"
              mat-raised-button
              color="accent"
              (click)="onSaveAttachment(ibTemplate.attachmentType)"
              [disabled]="disableSave(ibTemplate.attachmentType)"
              >
              <mat-icon>save</mat-icon>
              {{uploading ? "Uploading..." : "Save"}}
            </button>

            <button *ngIf="!!fileNameMap.get(ibTemplate.attachmentType) && !!fileStatusMap.get(ibTemplate.attachmentType)"
              class="btn hcplan-log-provider-rpt-logo-add-btn"
              mat-raised-button
              matTooltip="Generate test letter"
              color="accent"
              (click)="genIBRequestTest(ibTemplate.attachmentType)">
              <mat-icon>view_kanban</mat-icon>
            </button> 

            <button *ngIf="!!fileNameMap.get(ibTemplate.attachmentType) && !!fileStatusMap.get(ibTemplate.attachmentType)"
              class="btn hcplan-log-provider-rpt-logo-add-btn"
              mat-raised-button
              matTooltip="Download template"
              color="accent"
              (click)="downloadAttachment(ibTemplate.attachmentType)">
              <mat-icon>download</mat-icon>
            </button> 

            <button *ngIf="!!fileNameMap.get(ibTemplate.attachmentType) && !!fileStatusMap.get(ibTemplate.attachmentType)"
              class="btn hcplan-log-provider-rpt-logo-add-btn"
              mat-raised-button
              matTooltip="Delete template"
              color="accent"
              (click)="deleteAttachmentConfirmation(ibTemplate.attachmentType)">
              <mat-icon>delete</mat-icon>
            </button>          
          </div>
          <!-- Test IB Request Letter Generation Results-->
          <div class="ib-req-gen-test-button-group">
            <button *ngIf="!!fileNameMap.get(ibTemplate.genAttachmentType) && !!fileStatusMap.get(ibTemplate.genAttachmentType)"
              class="btn hcplan-log-provider-rpt-logo-add-btn"
              mat-raised-button
              matTooltip="Delete template"
              color="accent"
              (click)="downloadAttachment(ibTemplate.genAttachmentType)">
              <mat-icon>download</mat-icon> IB Req Letter Gen Test: {{attachmentInfoMap.get(ibTemplate.genAttachmentType).createdDateDisplayFormat1}}
            </button> 
          </div>

        </div>

        <div *ngIf="uploading" class="hcplan-lob-logo-loading">
          Loading...please wait
        </div>

        </mat-card-content>

        <mat-card-actions>
        </mat-card-actions>
      </mat-card>
      
      </mat-grid-tile>
    </mat-grid-list>

  </div>


</div>
