import { Deserializable, deserializeAssign } from "../deserializable";

export class AdjustmentExplanationCodePacket implements Deserializable<AdjustmentExplanationCodePacket> {
  id?: number
  code?: string
  description?: string;
  shortDescription?: string;
  selected?: boolean
  active?: boolean
  internal?: boolean
  visibleOnDashboard?: boolean;
  adjustmentExplanationType?: string;
  adjustmentReasonCodeId: any;

  deserialize(input: any): AdjustmentExplanationCodePacket {
    return deserializeAssign(this, input)
  }

  toJSON(): any {
    const obj: { [key: string]: any } = {}
    Object.assign(obj, this)
    delete obj["selected"]
    return obj
  }
}
