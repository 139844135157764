<mat-accordion>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      Filters
    </mat-expansion-panel-header>

    <form [formGroup]="formGroup">
      <div class="input-group">
        <mat-form-field class="input form-field-spacer" appearance="outline">
          <mat-label>
            Name
          </mat-label>
          <input matInput placeholder="Name" formControlName="name">
        </mat-form-field>

        <mat-form-field class="input form-field-spacer" appearance="outline">
          <mat-label>Phone Number</mat-label>
          <input matInput placeholder="phoneNumber" formControlName="phoneNumber">
        </mat-form-field>

        <mat-form-field class="input form-field-spacer" appearance="outline">
          <mat-label>Fax Number</mat-label>
          <input matInput placeholder="Fax Number" formControlName="faxNumber">
        </mat-form-field>

        <mat-form-field class="input form-field-spacer" appearance="outline">
          <mat-label>Email Address</mat-label>
          <input matInput placeholder="Email Address" formControlName="emailAddress">
        </mat-form-field>
      </div>
    </form>

    <button
      class="search-btn"
      mat-stroked-button
      (click)="getData()"
    >
      <mat-icon>search</mat-icon> Search
    </button>
  </mat-expansion-panel>
</mat-accordion>

<mat-table [dataSource]="contactList">

  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
    <mat-cell *matCellDef="let contactMap"> {{contactMap.contact.name}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="phoneNumber">
    <mat-header-cell *matHeaderCellDef> Phone Number </mat-header-cell>
    <mat-cell *matCellDef="let contactMap"> {{contactMap.contact.phoneNumber}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="emailAddress">
    <mat-header-cell *matHeaderCellDef> Email Address </mat-header-cell>
    <mat-cell *matCellDef="let contactMap"> {{contactMap.contact.emailAddress}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="faxNumber">
    <mat-header-cell *matHeaderCellDef> Fax Number </mat-header-cell>
    <mat-cell *matCellDef="let contactMap"> {{contactMap.contact.faxNumber}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="contactType">
    <mat-header-cell *matHeaderCellDef> Contact Type </mat-header-cell>
    <mat-cell *matCellDef="let contactMap"> {{contactMap.contact.contactTypeLabel}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="actions">
    <mat-header-cell *matHeaderCellDef>
      <button
        mat-stroked-button
        color="accent"
        (click)="openDialog(null)"
      >
        <mat-icon>add_circle</mat-icon>
        Add
      </button>

    </mat-header-cell>

    <mat-cell *matCellDef="let crm">
      <mat-menu #actionMenu="matMenu">
        <button
          mat-menu-item
          (click)="openDialog(crm)"
        >
          <mat-icon>edit</mat-icon>
          Edit Contact
        </button>

        <button
            mat-menu-item 
            (click)="delete(crm)"
            color="primary">
            <mat-icon>delete</mat-icon>
             Delete
          </button>
      </mat-menu>

      <button mat-icon-button [matMenuTriggerFor]="actionMenu" [matMenuTriggerData]="">
        <mat-icon>more_vert</mat-icon>
      </button>

    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayColumns;"></mat-row>
</mat-table>

<mat-paginator 
  #paginator
  [length]="totalRowCount" 
  [pageSize]="defaultPageSize"
  [pageSizeOptions]="pageSizeOptions"
  (page)="getData()"></mat-paginator>