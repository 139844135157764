import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';



import { ProviderEINService } from '../provider-ein.service';
import { BaseComponent } from "../../../view/base.component";
import { Logger } from "../../../util/logger";
import { FennecSnackbarService } from "../../../dialog/fennec-snackbar/fennec-snackbar.service";

@Component({
  selector: 'app-provider-ein-dialog',
  templateUrl: './provider-ein-dialog.component.html',
  styleUrls: ['./provider-ein-dialog.component.scss']
})
export class ProviderEinDialogComponent extends BaseComponent implements OnInit {

  protected log = new Logger("ProviderEinDialogComponent")
  mode?: "create" | "edit" | "view"

  existingId:number = -1;
  providerId: number = -1;
  formGroup: FormGroup;


  constructor(
    public dialogRef: MatDialogRef<ProviderEinDialogComponent>,
    public matDialog: MatDialog,
    public snack: FennecSnackbarService,
    public providerEINService: ProviderEINService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    super();
    this.mode = data.mode;
    this.existingId = data?.existingId ?? -1;
    this.providerId = parseInt(data.providerId);
    this.formGroup = new FormGroup({
      federalTaxNumber: new FormControl(this.data?.federalTaxNumber ?? "", Validators.required)
    })
  }

  ngOnInit(): void {
  }

  cancel() {
    this.dialogRef.close()
  }

  submit = () => {
    if(this.providerId == -1) {
      super.showErrorSnack("Provider ID Not provided");
      return;
    }

    const packet = {
      id: this.existingId,
      providerId: this.providerId,
      federalTaxNumber: this.formGroup.controls['federalTaxNumber'].value
    }

    if(this.mode == "create") {
      this.providerEINService.createProviderEIN(packet).subscribe(response => {
        if (response.hasErrors) {
          this.snack.showErrorSnack(response.consolidatedErrorMessage);
        } else {
          this.snack.showSuccessSnack(`EIN ${packet.federalTaxNumber} added to Provider ID:${packet.providerId}`);
          this.cancel();
        }
      })
    }

    if(this.mode == "edit") {
      this.providerEINService.updateProviderEIN(packet).subscribe(response => {
        if (response.hasErrors) {
          this.snack.showErrorSnack(response.consolidatedErrorMessage);
        } else {
          this.snack.showSuccessSnack(`EIN ${packet.federalTaxNumber} added to Provider ID:${packet.providerId}`);
          this.cancel();
        }
      })
    }

  }

}
