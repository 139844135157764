<div class="wrapper">
  <div matDialogTitle>
    Create Health Care Plan Relationship
  </div>

  <div matDialogContent>

    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <h4>Search Health Care Plans</h4>
        </mat-expansion-panel-header>

        <form [formGroup]="formGroup" class="alt-theme" (submit)="onSearch()">

          <div class="input-wrapper">
            <mat-form-field appearance="outline" class="search-input">
              <mat-label>Plan Name</mat-label>
              <input matInput placeholder="Search by Name" formControlName="name">
            </mat-form-field>

            <mat-form-field appearance="outline" class="state-search-input">
              <mat-label>State Code</mat-label>
              <input matInput placeholder="Search by State Code" formControlName="stateCode">
            </mat-form-field>
          </div>

          <mat-form-field *ngIf="mode == 'USER_PROFILE'" appearance="outline" class="company-search-input">
            <mat-label>Company Name</mat-label>
            <input matInput placeholder="Search by Company Name" formControlName="companyName">
          </mat-form-field>

          <div class="search-button-div">
            <button
              mat-stroked-button
              color="accent"
              type="submit"
              class="search-button"
            >
              <mat-icon>search</mat-icon>
              Search
            </button>

            <button
              mat-stroked-button
              color="primary"
              (click)="clearSearch()"
            >
              Clear
            </button>
          </div>
        </form>

      </mat-expansion-panel>
    </mat-accordion>

    <mat-table [dataSource]="healthCarePlanList">

      <ng-container matColumnDef="select">
        <mat-header-cell *matHeaderCellDef> Select</mat-header-cell>
        <mat-cell *matCellDef="let healthCarePlan">
          <span>{{ selection.isSelected(healthCarePlan) ? "Selected" : "Select" }}</span>
          <!--        <button mat-stroked-button (click)="selection.select(healthCarePlan)" [disabled]="healthCarePlan.id == selectedHealthCarePlanId"></button>-->
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef> Id</mat-header-cell>
        <mat-cell *matCellDef="let healthCarePlan">
          {{ healthCarePlan.id }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef> Plan Name</mat-header-cell>
        <mat-cell *matCellDef="let healthCarePlan">
          {{ healthCarePlan.name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="state">
        <mat-header-cell *matHeaderCellDef> State</mat-header-cell>
        <mat-cell *matCellDef="let healthCarePlan">
          {{ healthCarePlan.stateCode }} -- {{ healthCarePlan.stateName }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="companyName">
        <mat-header-cell *matHeaderCellDef> Company Name</mat-header-cell>
        <mat-cell *matCellDef="let healthCarePlan">
          {{ healthCarePlan.companyName }}
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"
               [ngClass]="{ hovered: row.hovered, highlighted: selection.isSelected(row) }"
               (click)="onSelect(row)"
      >
      </mat-row>
    </mat-table>
  </div>

  <div matDialogActions class="fennec-controls">
    <mat-paginator style="float:left;" [length]="totalRowCount" [pageSize]="defaultPageSize"
                   [pageSizeOptions]="pageSizeOptions"></mat-paginator>
    <button class="save-button" mat-flat-button color="accent" [disabled]="selection.isEmpty()" (click)="onSubmit()">
      Add Selected Plans
    </button>
    <button mat-flat-button color="primary" (click)="cancel()">
      Cancel
    </button>
  </div>

</div>
