import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseCrudService, Crud, DataResponse, PagedResponse, TaxonomyCodePacket } from "xf-common";


@Injectable()
export class TaxonomyCodeService extends BaseCrudService<TaxonomyCodePacket> {

  baseEndpoint = super.apiEndpoint("taxonomy-code");

  constructor(public override httpClient: HttpClient) {
    super(httpClient, "TaxonomyCodeService", "TaxonomyCode");
  }

  getTaxonomyCode: () => Observable<DataResponse<TaxonomyCodePacket>> = () => {
    const endpoint = `${this.baseEndpoint}`;
    return this.httpClient.get<DataResponse<TaxonomyCodePacket>>(endpoint);
  }

  _list: Crud.ListFunction<TaxonomyCodePacket> = (first, max) => {
    const endpoint = `${this.baseEndpoint}/list`;
    return this.httpClient.get<PagedResponse<TaxonomyCodePacket[]>>(endpoint, { params: { first, max } });
  };

  _getById: Crud.GetByIdFunction<TaxonomyCodePacket> = (id) => {
    const endpoint = `${this.baseEndpoint}/${id}`;
    return this.httpClient.get<PagedResponse<TaxonomyCodePacket>>(endpoint);
  }

  _create: Crud.CreateFunction<TaxonomyCodePacket> = (user) => {
    const endpoint = `${this.baseEndpoint}/create`;
    return this.httpClient.post<PagedResponse<TaxonomyCodePacket>>(endpoint, user);
  }

  _update: Crud.UpdateFunction<TaxonomyCodePacket> = (user): Observable<DataResponse<TaxonomyCodePacket>> => {
    const endpoint = `${this.baseEndpoint}/update`;
    return this.httpClient.put<PagedResponse<TaxonomyCodePacket>>(endpoint, user);
  }

  _deactivate: Crud.DeactivateFunction<TaxonomyCodePacket> = (ids) => {
    const endpoint = `${this.baseEndpoint}/deactivate`;
    return this.httpClient.put<DataResponse<boolean>>(endpoint, ids[0]);
  }

  _delete = (ids: number[]):  Observable<DataResponse<boolean>> => {
    const endpoint = `${this.baseEndpoint}/delete`;
    return this.httpClient.put<DataResponse<boolean>>(endpoint, ids);
  }

  changeStatus = (diagnosisCodePacket: TaxonomyCodePacket): Observable<DataResponse<TaxonomyCodePacket>> => {
    const endpoint = `${this.baseEndpoint}/status`;
    return this.httpClient.put<DataResponse<TaxonomyCodePacket>>(endpoint, diagnosisCodePacket);
  }

  protected _crudFunctions: Crud.CrudServiceDef<TaxonomyCodePacket> = {
    _list: this._list,
    _getById: this._getById,
    _create: this._create,
    _update: this._update,
    _deactivate: this._deactivate,
    _delete: this._delete
  };

  getTaxonomyCodesByParams = (packet:any, first:number, max:number) => {
    const endpoint = `${this.baseEndpoint}/list?first=${first}&max=${max}`;
    return this.httpClient.post<PagedResponse<TaxonomyCodePacket>>(endpoint, packet);
  }
}
