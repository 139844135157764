<ng-container pageContent class="container-override">
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Filters
        </mat-panel-title>
      </mat-expansion-panel-header>

      <form [formGroup]="formGroup" class="alt-theme" (submit)="executeListQuery()">
        <div class="input-wrapper">
          <mat-form-field appearance="outline">
            <mat-label>Name</mat-label>
            <input matInput placeholder="Search by Name" formControlName="name">
          </mat-form-field>
    
          <mat-form-field appearance="outline">
            <mat-label>Description</mat-label>
            <input matInput placeholder="Search by Description" formControlName="description">
          </mat-form-field>
          
          <mat-form-field appearance="outline">
            <mat-label>Event Definition Code</mat-label>
            <input matInput placeholder="Search by Event Definition Code" formControlName="eventDefinitionCode">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Status</mat-label>
            <mat-select formControlName="active">
              <mat-option [value]="'all'">All</mat-option>
              <mat-option [value]="true">Active</mat-option>
              <mat-option [value]="false">Not Active</mat-option>
            </mat-select>
          </mat-form-field>

          <div class="button-div">
            <button
              mat-stroked-button
              color="accent"
              type="submit"
              class="search-button"
            >
              <mat-icon>search</mat-icon> Search
            </button>
      
            <button
              mat-stroked-button
              color="primary"
              (click)="clearSearch()"
            >
              Clear
            </button>
          </div>
        </div>
      </form>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef>ID</mat-header-cell>
      <mat-cell *matCellDef="let eventDefinition">{{eventDefinition?.id}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
      <mat-cell *matCellDef="let eventDefinition">
        {{eventDefinition?.name}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef> Description </mat-header-cell>
      <mat-cell *matCellDef="let eventDefinition">
        {{eventDefinition?.description}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="eventDefinitionCode">
      <mat-header-cell *matHeaderCellDef> Event DefinitionCode </mat-header-cell>
      <mat-cell *matCellDef="let eventDefinition">
        {{eventDefinition?.eventDefinitionCode}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef> 
        <button 
          class="add" 
          mat-flat-button
          (click)="openDialog(null)"
        >
        <mat-icon>add_circle</mat-icon>
         New
      </button></mat-header-cell>
      <mat-cell *matCellDef="let eventDefinition">
        <mat-menu #actionMenu="matMenu">
          <button
            mat-menu-item
            (click)="openDialog(eventDefinition)"
          >
            <mat-icon>edit</mat-icon>
            Edit
          </button>
          <button
            mat-menu-item
            (click)="openDeactivateDialog(eventDefinition)">
            <mat-icon>{{getDeleteIconString(eventDefinition)}}</mat-icon>
            {{eventDefinition?.active ? "Deactivate" : "Restore"}}
          </button>
        </mat-menu>

        <button 
          mat-icon-button [matMenuTriggerFor]="actionMenu" [matMenuTriggerData]="">
          <mat-icon>more_vert</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="active">
      <mat-header-cell *matHeaderCellDef>Active</mat-header-cell>
      <mat-cell *matCellDef="let eventDefinition">
        <mat-icon [ngStyle]="getActiveIconStyle(eventDefinition)">
          {{eventDefinition?.active ? "check_circle" : "error_outline"}}
        </mat-icon>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedColumns; let i=index"
        [tabindex]="i + 1"
        [attr.data-index]="i"
        [ngClass]="{ hovered: row.hovered }"
        (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"
      ></mat-row>
  </mat-table>
  <mat-paginator class="alt-theme" [length]="totalRowCount" [pageSize]="defaultPageSize" [pageSizeOptions]="defaultPageSizeOptions"></mat-paginator>
</ng-container>
