import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ApeRuleFilterStateService } from '../../ape-rule-filter-state.service';
import {
  APERuleFilter,
  APERuleFilterState,
  BaseComponent,
  FennecSnackbarService,
  Logger,
  SingleChoiceDialogComponent,
  StatePacket,
  StateService
} from "xf-common";

@Component({
  selector: 'app-ape-rule-filter-state-dialog',
  templateUrl: './ape-rule-filter-state-dialog.component.html',
  styleUrls: ['./ape-rule-filter-state-dialog.component.scss', '../../../ape-rule-admin-styles.scss']
})
export class ApeRuleFilterStateDialogComponent extends BaseComponent implements OnInit, AfterViewInit {

  protected log = new Logger("ApeRuleFilterStateDialogComponent");

  /*
    Working Display Modes:
    ADMIN - Displays a simple list of all APERuleFilterState entities associated with the selected APERuleFilter.
  */
  displayMode: string = "ADMIN";
  displayModes: string[] = ["ADMIN", "SEARCH_STATE"]

  apeRuleFilter: APERuleFilter = new APERuleFilter();
  stateIds: number[] = [];
  apeRuleFilterStates: APERuleFilterState[] = [];
  displayedColumns: string[] = ["stateCode", "stateName", "actions"];

  searchFormGroup?: FormGroup;
  searchResults: StatePacket[] = [];

  constructor(
    protected apeRuleFilterStateService: ApeRuleFilterStateService,
    protected stateService: StateService,
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<ApeRuleFilterStateDialogComponent>,
    override snack: FennecSnackbarService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    super();

    this.apeRuleFilter = data.apeRuleFilter != null && data.apeRuleFilter !== undefined ? data.apeRuleFilter : new APERuleFilter();
    if(this.displayMode === "ADMIN") {
      this.getApeRuleFilterStateList();
    }
  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {

  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  getApeRuleFilterStateList(): void {

    if (this.apeRuleFilter?.id === undefined) {
      this.showErrorSnack("APE Rule Filter not provided.");
      return;
    }

    this.performXFRequest({
      requestDescription: "GET Ape Rule Filter States",
      requestFn: this.apeRuleFilterStateService.getApeRuleFilterStatesForApeRuleFilterId,
      fnParams: [this.apeRuleFilter.id],
      onResponse: response => {
        this.apeRuleFilterStates = response.data;


        const newIdList: number[] = [];
        response.data.forEach((filterState: APERuleFilterState) => {
          if(filterState.state) {
            newIdList.push(filterState.state.id);
          }
        })
        this.stateIds = newIdList;
      },
      onError: errString => {
        super.showErrorSnack(errString);
      }
    })

  }

  changeDisplayMode(mode:string) {
    if(this.displayModes.includes(mode)) {
      if(mode === "ADMIN") {
        this.getApeRuleFilterStateList();
      }

      if(mode === "SEARCH_STATE") {
        this.searchResults = [];
        this.createFormGroup();
      }

      this.displayMode = mode;
    }
  }

  createFormGroup = () => {
    this.searchFormGroup = new FormGroup({
      stateCode: new FormControl("", Validators.maxLength(2)),
      stateName: new FormControl()
    });
  }

  searchForState = () => {
    if(!this.searchFormGroup) {
      super.showErrorSnack("Unable to search");
      return;
    }

    const controls = this.searchFormGroup.controls;

    const params = {
      stateCode: controls['stateCode'].value,
      stateName: controls['stateName'].value
    }

    this.performXFRequest({
      requestDescription: "Search for States",
      requestFn: this.stateService.searchByParams,
      fnParams: [params, 0, 99],
      onResponse: response => {
        this.searchResults = response.data;
      },
      onError: errString => {
        super.showErrorSnack(errString);
      }
    })
  }

  clearSearch = () => {
    this.searchResults = [];
    this.createFormGroup();
  }

  openDeleteDialog = (apeRuleFilterState: APERuleFilterState) => {
    const stateName = apeRuleFilterState.state?.stateName

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      question: `Are you sure you want to remove ${stateName} from APE Rule Filter '${this.apeRuleFilter.name}'`
    }

    const dialogRef = this.dialog.open(SingleChoiceDialogComponent, dialogConfig)
    dialogRef.afterClosed().subscribe((result) => {
      if(result?.confirm) {
        if(apeRuleFilterState.id === undefined) {
          super.showErrorSnack("Error opening Delete dialog");
          return;
        }

        this.performXFRequest({
          requestDescription: "Delete APE Rule Filter State",
          requestFn: this.apeRuleFilterStateService.deleteApeRuleFilterState,
          fnParams: [apeRuleFilterState.id],
          onResponse: response => {
            super.showSuccessSnack(`${stateName} removed from ${this.apeRuleFilter.name}`)
            this.getApeRuleFilterStateList();
          },
          onError: errString => {
            super.showErrorSnack(errString);
          }
        })
      }
    })
  }

  addState = (state: StatePacket) => {

    const packet = new APERuleFilterState();
    packet.id = -1;
    packet.state = state;
    packet.apeRuleFilter = this.apeRuleFilter;


    this.performXFRequest({
      requestDescription: "Delete APE Rule Filter State",
      requestFn: this.apeRuleFilterStateService.createAPERule,
      fnParams: [packet],
      onResponse: response => {
        super.showSuccessSnack(`${state.stateName} added to ${this.apeRuleFilter.name}`)
        this.getApeRuleFilterStateList();
      },
      onError: errString => {
        super.showErrorSnack(errString);
      }
    })
  }

  openDeleteDialogStateSearch = (state: StatePacket) => {
    const filterState = this.findAPERuleFilterStateByStateId(state.id);
    if(filterState == null) {
      super.showErrorSnack("Error opening Delete dialog");
      return;
    }

    this.openDeleteDialog(filterState);
  }

  findAPERuleFilterStateByStateId = (stateId: number) => {
    let result = null;
    this.apeRuleFilterStates.forEach((apeRuleFilterState: APERuleFilterState) => {
      if(apeRuleFilterState.state?.id === stateId) {
        result = apeRuleFilterState;
        return;
      }
    })
    return result;
  }

  addAllPrompt = () => {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      question: `Add all US States to APE Rule Filter '${this.apeRuleFilter.name}'?`
    }

    const dialogRef = this.dialog.open(SingleChoiceDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if(result?.confirm) {
        if(this.apeRuleFilter.id === undefined) {
          super.showErrorSnack("Error opening Delete dialog");
          return;
        }

        this.performXFRequest({
          requestDescription: "add ALL States to APE Rule Filter Id",
          requestFn: this.apeRuleFilterStateService.addAllStatesForAPERuleFilterId,
          fnParams: [this.apeRuleFilter.id],
          onResponse: response => {
            super.showSuccessSnack(`All states added to ${this.apeRuleFilter.name}`)
            this.getApeRuleFilterStateList();
            this.changeDisplayMode("ADMIN");
          },
          onError: errString => {
            super.showErrorSnack(errString);
          }
        })
      }
    })
  }

  removeAllPrompt = () => {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      question: `Are you sure you want to remove all ${this.apeRuleFilterStates.length} states from APE Rule Filter '${this.apeRuleFilter.name}'?`
    }

    const dialogRef = this.dialog.open(SingleChoiceDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result) => {
      if(result?.confirm) {
        if(this.apeRuleFilter.id === undefined) {
          super.showErrorSnack("Error opening Delete dialog");
          return;
        }

        this.performXFRequest({
          requestDescription: "Delete ALL APE Rule Filter State for Filter Id",
          requestFn: this.apeRuleFilterStateService.deleteAllStatesForAPERuleFilterId,
          fnParams: [this.apeRuleFilter.id],
          onResponse: response => {
            super.showSuccessSnack(`All states removed from ${this.apeRuleFilter.name}`)
            this.getApeRuleFilterStateList();
          },
          onError: errString => {
            super.showErrorSnack(errString);
          }
        })
      }
    })
  }
}
