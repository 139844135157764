import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { BaseComponent, FennecSnackbarService, Logger, RevenueCodePacket } from "xf-common";


@Component({
  selector: 'app-revenue-code-dialog',
  templateUrl: './revenue-code-dialog.component.html',
  styleUrls: ['./revenue-code-dialog.component.scss']
})
export class RevenueCodeDialogComponent extends BaseComponent implements OnInit {

  mode?: "Create" | "Edit" | "View";
  revenueCode?: RevenueCodePacket;
  formGroup: FormGroup;

  protected log = new Logger("RevenueCodeDialogComponent");

  constructor(
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<RevenueCodeDialogComponent>,
    override snack: FennecSnackbarService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    super();
    this.mode = this.data?.mode;
    this.revenueCode = this.data?.revenueCode;
    this.formGroup = new FormGroup({
      code: new FormControl(this.revenueCode?.code ?? "", Validators.required),
      description: new FormControl(this.revenueCode?.description ?? "", [Validators.required, Validators.maxLength(500)]),
    });
  }

  ngOnInit(): void {
  }

  submit() {
    const revenueCode: RevenueCodePacket = this.revenueCode ?? new RevenueCodePacket();
    const formValue = this.formGroup.value;
    if (!formValue.code) {
      super.showErrorSnack("Code is required");
      return;
    }
    if (!formValue.description) {
      super.showErrorSnack("Description required");
      return;
    }
    revenueCode.code = formValue["code"];
    revenueCode.description = formValue["description"];
    this.dialogRef.close(revenueCode);
  }

  cancel() {
    this.dialogRef.close();
  }

}
