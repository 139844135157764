import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  BaseCrudComponent,
  CRUDDeleteStatusChangeDialogComponent,
  FennecSnackbarService,
  HealthCarePlanPacket,
  Logger
} from 'xf-common';
import { HealthCarePlanService } from '../health-care-plan.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { HealthCarePlanDialogComponent } from '../health-care-plan-dialog/health-care-plan-dialog.component';
import { KeycloakService } from 'keycloak-angular';
import {
  HealthCarePlanUpdateAssignedCompanyDialogComponent
} from '../health-care-plan-update-assigned-company-dialog/health-care-plan-update-assigned-company-dialog.component';


@Component({
  selector: 'app-health-plan-list',
  templateUrl: './health-care-plan-list.component.html',
  styleUrls: ['./health-care-plan-list.component.scss', '../../../lib/styles/system-admin-styles.component.scss']
})
export class HealthCarePlanListComponent extends BaseCrudComponent<HealthCarePlanPacket, HealthCarePlanService> implements OnInit, AfterViewInit {

  protected log: Logger = new Logger("HealthCarePlanListComponent");

  displayedColumns = ["name", "state", "company", "active", "actions"];
  dialogRef?: MatDialogRef<any>
  @ViewChild("page", { read: ElementRef})
  element?: ElementRef
  pageSizeOptions = [10, 20, 25, 50]

  formGroup: FormGroup;
  filtersOpen: boolean = false;

  constructor(
    override snack: FennecSnackbarService,
    protected healthCarePlanService: HealthCarePlanService,
    protected dialog: MatDialog,
    protected keycloakService: KeycloakService
  ) {
    super(healthCarePlanService);
    this.formGroup = this.createFormGroup();
    this.canCreate = keycloakService.isUserInRole("ADMIN")
    this.canUpdate = keycloakService.isUserInRole("ADMIN")
    this.canDelete = keycloakService.isUserInRole("ADMIN")
    this.canDeactivate = keycloakService.isUserInRole("ADMIN")
    this.canView = keycloakService.isUserInRole("ADMIN")
   }

   ngAfterViewInit() {
    // if (this.element?.nativeElement) {
    //  this.renderer.listen(this.element.nativeElement, "keyup", (event) => this.keyEvent(event))
    // }
    if (this.paginator) {
       this.paginator.page.subscribe(() => {
       // this.getList()
       this.executeListQuery();
     })
    }
}

  ngOnInit(): void {
  // this.getList();
  this.executeListQuery();
  }

  onCreate = () => {

  }

  onEditDialog = (packet:HealthCarePlanPacket) => {

  }

  onDeleteDialog = (packet:HealthCarePlanPacket) => {
    if (packet) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        mode: "status",
        source: packet,
        label: "Health Care Plan"
      };
      this.dialogRef = this.dialog.open(CRUDDeleteStatusChangeDialogComponent, dialogConfig);
      this.dialogRef.afterClosed().subscribe({
        next: (value?: HealthCarePlanPacket) => {
          if (value) {
            this.performXFRequest({
              requestDescription: "UPDATE Health Care Plan status",
              requestFn: this.healthCarePlanService.toggleActiveStatus,
              fnParams: [packet.id],
              onSuccess: data => {
                super.showSuccessSnack("Request Successful!");
                this.executeListQuery();
              },
              onError: errString => {
                super.showErrorSnack(errString);
              }
            })
          }
        }
      });
    }
  }

  onEdit = () => {

  }

  onDelete = () => {

  }

  createFormGroup = () => {
    return new FormGroup({
      name: new FormControl(),
      stateCode: new FormControl(),
      active: new FormControl(true)
    })
  }

  clearSearch = () => {
    this.formGroup = this.createFormGroup();
  }

  executeListQuery = () => {
    const controls = this.formGroup?.controls;

    const searchParams = {
      name: controls["name"].value?.trim() ?? null,
      stateCode: controls["stateCode"].value?.trim() ?? null,
      active: controls["active"].value,
    }

    if(searchParams.active === "all") {
      searchParams.active = null;
    }

    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;
    this.performXFRequest({
      requestDescription: "GET Health Care Plans By Search Params",
      requestFn: this.healthCarePlanService.getHealthCarePlansByQueryParams,
      fnParams: [searchParams, first, pageSize],
      onResponse: response => {
        this.dataSource = response.data;
        this.totalRowCount = response['totalRowCount'];
      },
      onError: errString => {
        super.showErrorSnack(errString)
      }
    })
  }

  onSearch = () => {
    this.paginator?.firstPage();
    this.executeListQuery();
  }

  openDialog = (healthCarePlanPacket:HealthCarePlanPacket|null) => {
    const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        mode: healthCarePlanPacket == null ? "Create" : "Edit",
        healthCarePlanPacket: healthCarePlanPacket
      }
      this.dialogRef = this.dialog.open(HealthCarePlanDialogComponent, dialogConfig);
      this.dialogRef?.afterClosed().subscribe({
        next: (returnObj) => {
          if (returnObj.confirm) {
            this.executeListQuery();
          }
        }
      });
  }

  openCompanyDialog = (healthCarePlanPacket:HealthCarePlanPacket|null) => {
    const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        healthCarePlanId: healthCarePlanPacket?.id,
        healthCarePlanName: healthCarePlanPacket?.name,
        companyId: healthCarePlanPacket?.companyId
      }
      this.dialogRef = this.dialog.open(HealthCarePlanUpdateAssignedCompanyDialogComponent, dialogConfig);
      this.dialogRef?.afterClosed().subscribe({
        next: (returnObj) => {
          if (returnObj.confirm) {
            this.executeListQuery();
          }
        }
      });
  }
}
