import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Subject } from "rxjs";
import {
  AddressComponent,
  BaseComponent,
  BaseResponse,
  FennecSnackbarService,
  Logger,
  ProviderPacket,
  ProviderService
} from "xf-common";


@Component({
  selector: 'app-provider-edit',
  templateUrl: './provider-edit.component.html',
  styleUrls: ['./provider-edit.component.scss']
})
export class ProviderEditComponent extends BaseComponent implements AfterViewInit {
  log: Logger = new Logger("ProviderEditComponent");

  @ViewChild('addressComponent')
  addressComponent?: AddressComponent;

  providerId = -1;

  provider: ProviderPacket | undefined;

  providerSaveComplete: Subject<any> = new Subject();

  @Input()
  providerTypes: any;

  formGroup: FormGroup;
  constructor(
    protected snack: FennecSnackbarService,
    protected activeRoute: ActivatedRoute,
    private providerService: ProviderService,
  ) {
    super();
    this.providerId = this.activeRoute.snapshot.params["id"];
    this.formGroup = new FormGroup({});
    this.getProvider();
  }

  createForm() {
    this.formGroup = new FormGroup({
      name: new FormControl(this.provider?.name),
      firstName: new FormControl(this.provider?.firstName),
      lastName: new FormControl(this.provider?.lastName),
      npiNumber: new FormControl(this.provider?.npiNumber),
      providerType: new FormControl(this.provider?.providerType, Validators.required),
      taxonomyCode: new FormControl(this.provider?.taxonomyCode),
      taxonomyCodeDescription: new FormControl({value: this.provider?.taxonomyCodeDescription, disabled: true}),
      addressValidator: new FormControl()
    });

    this.formGroup.valueChanges.subscribe(() => {
      this.validateAddress();
    })

    this.formGroup.controls['taxonomyCode'].valueChanges.subscribe((val) => {
      let dcFormControl: FormControl = this.formGroup.controls['taxonomyCode'] as FormControl;

      if(dcFormControl.value.length == 0) {
        dcFormControl.markAsPristine();
        this.formGroup.controls['taxonomyCodeDescription'].setValue("");
        dcFormControl.setErrors(null);
      }else {
        this.performXFRequest({
          requestDescription: "GET taxonomy code by code",
          requestFn: this.providerService.getTaxonomyCodeByCode,
          fnParams: [dcFormControl.value],
          onSuccess: data => {
            if (data !== null) {
              dcFormControl.markAsPristine();
              this.formGroup.controls['taxonomyCodeDescription'].setValue(data.description)
              dcFormControl.setErrors(null);
            } else {
              this.formGroup.controls['taxonomyCodeDescription'].setValue("Invalid");
              dcFormControl.markAsPristine();
              dcFormControl.setErrors({'incorrect': true});
            }
          },
          onError: errString => {
            dcFormControl.markAsPristine();
            dcFormControl.setErrors({'incorrect': true});
          }
        })
      }
    })
  }

  ngAfterViewInit(): void {
    this.findAddressComponent();
  }

  findAddressComponent() {
    if (this.addressComponent !== null && this.addressComponent !== undefined) {
      this.addressComponent?.addressSaveComplete?.subscribe((id: number) => {
        this.saveProviderToServer(id);
      });
      this.addressComponent?.formGroup?.valueChanges?.subscribe(() => {
        this.validateAddress();
      })
    }
  }

  validateAddress(){
    let addressFormControl: FormGroup | undefined = this?.addressComponent?.formGroup;

    if(addressFormControl){
      if(!addressFormControl.valid){
        this.formGroup.controls['addressValidator'].setErrors({'incorrect':true});
      }else{
        this.formGroup.controls['addressValidator'].setErrors(null);
      }
    }
  }

  onSubmit() {
    this.findAddressComponent();
    this.addressComponent?.saveAddressToServer();
  }

  saveProviderToServer(addressId?: number) {
    this.formGroup.markAllAsTouched();
    let updateDto: ProviderPacket = new ProviderPacket();
    updateDto.name = this.formGroup.controls['name'].value;
    updateDto.firstName = this.formGroup.controls['firstName'].value;
    updateDto.lastName = this.formGroup.controls['lastName'].value;
    updateDto.providerType = this.formGroup.controls['providerType'].value;
    updateDto.npiNumber = this.formGroup.controls['npiNumber'].value;
    updateDto.taxonomyCode = this.formGroup.controls['taxonomyCode'].value;
    updateDto.taxonomyCodeDescription = this.formGroup.controls['taxonomyCodeDescription'].value;
    updateDto.id = this.providerId;
    updateDto.addressId = addressId ?? this.provider?.addressId;

    this.providerService.updateProvider(updateDto).subscribe( (response: BaseResponse) => {
      if(response.hasErrors) {
        super.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.providerSaveComplete.next(response.data);
        this.getProvider();
        super.showSuccessSnack("Successfully Updated!");
      }
    });
  }

  cancel() {
    this.getProvider();
  }

  getProvider() {
    this.isLoading = true;
    this.performXFRequest({
      requestDescription: "GET provider by ID",
      requestFn: this.providerService.getProvider,
      fnParams: [this.providerId],
      onSuccess: data => {
        this.provider = data;
        this.createForm();
        this.isLoading = false;
      },
      onError: errString => {
        super.showErrorSnack(errString);
        this.isLoading = false;
      }
    });
  }
}
