<mat-dialog-content>
  <div class="dialog-header">
    <h1 mat-dialog-title>Edit Condition Code Information</h1>
    <!-- <button 
      mat-button
      mat-stroked-button 
      class="ub04-icon-btn"
      tabindex="-1"
      (click)="onCancel()"
      matTooltip="Close Dialog">
      <mat-icon>close</mat-icon>
    </button> -->
  </div>

  <div style="clear: both;"></div>
  <div 
    [formGroup]="formGroup" 
    class="content-wrapper alt-theme">
    <ng-container formArrayName="conditionCodes">
        <ng-container 
          *ngFor="let rcRow of conditionCodes.controls; let i = index">
            <div 
              class="lesson-form-row" 
              [formGroup]="getConditionCodeFormGroup(i)" 
              [ngStyle]="getRowDisplayStyle(i)">
              <mat-icon
                class="ub04-field-status-icon"
                style={{getIconStyle(i)}}
              >
                {{ getDeleteRowByIdx(i) ? "remove_circle_outline" : rcRow.valid ? "check_circle" : "error" }}
              </mat-icon>
              <mat-form-field 
                appearance="outline" 
                [style.width.px]=160
                >
                <mat-label>Condition Code</mat-label>
                <input 
                  matInput 
                  formControlName="conditionCode" 
                  placeholder="Condition Code">
              </mat-form-field>
              <mat-form-field 
                appearance="fill" 
                [style.width.px]=500>
                <mat-label>Description</mat-label>
                <input 
                  matInput 
                  formControlName="description" 
                  placeholder="Description">
              </mat-form-field>

              <button 
                *ngIf="!getDeleteRowByIdx(i)"
                mat-flat-button 
                matTooltip="Delete"
                class="ub04-icon-btn"
                (click)="onDelete(i)">
                <mat-icon>delete</mat-icon>
              </button>         
              
              <button 
                *ngIf="getDeleteRowByIdx(i)"
                mat-flat-button 
                matTooltip="Cancel Delete"
                class="ub04-icon-btn"
                (click)="onDelete(i)">
                <mat-icon>restore_from_trash</mat-icon>
              </button>   
            </div>
        </ng-container>
    </ng-container>    
  </div>
</mat-dialog-content>

<mat-dialog-actions class="fennec-controls">
  <div class="ub04-dialog-add-btn">
    <button 
      mat-button 
      mat-stroked-button 
      matTooltip="Add"
      class="ub04-icon-btn"
      (click)="onAddConditionCodeLine()">
      <mat-icon>add_circle</mat-icon>
      Add Condition Code
    </button>
  </div>
  
  <div>
    <mat-checkbox 
        class="mark-complete-checkbox" 
        [checked]="statusTrackComplete" 
        (change)="toggleStatusTrackComplete()">
        Mark Complete
      </mat-checkbox>
  
      
      <button 
        mat-flat-button 
        (click)="onSubmit()" 
        [disabled]="!formGroup.valid" 
        color="accent">
          <mat-icon>save</mat-icon> Save
      </button>
      <button 
        mat-button 
        mat-stroked-button 
        (click)="onCancel()">
          <mat-icon>cancel</mat-icon> Cancel
      </button>
  </div>
</mat-dialog-actions>
