import { AfterViewInit, Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ApeRuleCollectionService } from '@app/ape/ape-rule-collection.service';
import { APERule, BaseCrudComponent, FennecSnackbarService, Logger, SingleChoiceDialogComponent } from 'xf-common';
import { KeycloakService } from 'keycloak-angular';
import { ApeRuleDialogComponent } from '../ape-rule-dialog/ape-rule-dialog.component';
import { ApeRuleEventDefinitionListDialogComponent } from '../ape-rule-event-definition/ape-rule-event-definition-list-dialog/ape-rule-event-definition-list-dialog.component';
import { ApeRuleApeRuleFilterListComponent } from '../ape-rule-filter/ape-rule-ape-rule-filter/ape-rule-ape-rule-filter-list/ape-rule-ape-rule-filter-list.component';
import { ApeRuleService } from './ape-rule.service';

@Component({
  selector: 'app-ape-rule-list',
  templateUrl: './ape-rule-list.component.html',
  styleUrls: ['./ape-rule-list.component.scss', '../../../../lib/styles/system-admin-styles.component.scss']
})
export class ApeRuleListComponent extends BaseCrudComponent<APERule, ApeRuleService> implements OnInit, AfterViewInit {
  onEdit = (id: number | undefined) => {};
  onDelete = (ids: any[]) => {};


  /*
    Current modes--
    ADMIN - Operates as a searchable list with full CRUD capabilities for individual APE Rules.
    COLLECTION_RULES - Displays existing APE Collection Rules for provided apeRuleCollectionID
    COLLECTION_ADD - Searchable list of all existing APE Rules.
    COLLECTION_REORDER - Lists collection rules with select
  */
  @Input()
  mode:string = "ADMIN";

  @Input()
  apeRuleCollectionID:string = '-1';

  displayedColumns: string[] = [];

  adminColumns: string[] = ["id", "name", "serviceIdentificationCode", "apeRuleType", "description", "active", "admin_actions"]
  collectionColumns: string[] = ["simple_reorder", "seqNo", "id", "name", "description", "apeRuleType", "collection_actions"]
  collectionAddColumns: string[] = ["id", "name", "apeRuleType", "description", "select"]
  collectionReorderColumns: string[] = ["seqNo", "id", "name", "description", "apeRuleType", "select"]

  dialogRef?: MatDialogRef<any>;

  @ViewChild("page", { read: ElementRef })
  element?: ElementRef;

  protected log: Logger = new Logger("APERuleList");

  formGroup: FormGroup;
  apeRuleCollection:any = null;

  nextSeqNo:number = 0;

  selectedApeRuleCollectionRule:any = null;
  reorderMode:string = "";

  constructor(
    protected apeRuleService: ApeRuleService,
    protected apeRuleCollectionService: ApeRuleCollectionService,
    public override snack: FennecSnackbarService,
    protected dialog: MatDialog,
    private renderer: Renderer2,
    protected keycloakService: KeycloakService
  ) {
    super(apeRuleService);
    this.formGroup = this.createFormGroup();
   }

   ngOnInit(): void {
    this.executeListQuery();
  }

  ngAfterViewInit(): void {
    this.selection.changed.subscribe(change => this.selectedRow = change.added[0] ?? change.removed[0] ?? undefined);
      if (this.element?.nativeElement) {
        this.renderer.listen(this.element.nativeElement, "keyup", (event) => this.keyEvent(event));
      }
      if (this.paginator) {
        this.paginator.page.subscribe(() => {
          this.executeListQuery();
        })
      }
  }

  createFormGroup = () => {
    return new FormGroup({
      name: new FormControl(),
      description: new FormControl(),
      serviceIdentificationCode: new FormControl(),
      active: new FormControl("all")
    })
  }

  executeListQuery() {
    if(this.mode === "ADMIN" || this.mode === "COLLECTION_ADD") {
      const controls = this.formGroup?.controls;
      if(controls == undefined) {
        return;
      }

      const searchParams = {
        name: controls["name"].value?.trim() ?? null,
        description: controls["description"].value?.trim() ?? null,
        serviceIdentificationCode: controls["serviceIdentificationCode"].value?.trim() ?? null,
        active: controls["active"].value,
      }

      if(searchParams.active === "all") {
        searchParams.active = null;
      }

      const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
      const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;
      // this.paginator.pageIndex = first;
      // this.paginator.pageSize = pageSize;
      this.performXFRequest({
        requestDescription: "GET APE Rules By Search Params",
        requestFn: this.apeRuleService.getAPERules,
        fnParams: [searchParams, first, pageSize],
        onResponse: response => {
          this.dataSource = response.data;
          this.totalRowCount = response['totalRowCount'];
        },
        onError: errString => {
          super.showErrorSnack(errString)
        }
      })

      if(this.mode === "ADMIN") {
        this.displayedColumns = this.adminColumns;
      }
      if(this.mode === "COLLECTION_ADD") {
        this.displayedColumns = this.collectionAddColumns;
      }
    }

    if(this.mode === "COLLECTION_RULES" || this.mode === "COLLECTION_REORDER"){
      this.getAPERuleCollectionRuleList();

      if(this.mode === "COLLECTION_RULES") {
        this.displayedColumns = this.collectionColumns;
      }

      if(this.mode === "COLLECTION_REORDER") {
        this.displayedColumns = this.collectionReorderColumns;
      }
    }
  }

  getAPERuleCollectionRuleList() {
    // const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    //   const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;
    //   this.paginator.pageIndex = first;
    //   this.paginator.pageSize = pageSize;
    if(this.apeRuleCollectionID === "-1") {
      return;
    }

    const id = parseInt(this.apeRuleCollectionID);

    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;
    this.paginator.pageIndex = first;
    this.paginator.pageSize = pageSize;
    this.performXFRequest({
      requestDescription: "GET APE Rules By APE Rule Collection ID",
      requestFn: this.apeRuleCollectionService.getAPERuleCollectionRulesByAPERuleCollectionId,
      fnParams: [id, first, pageSize],
      onResponse: response => {
        this.dataSource = response.data.map((apeRuleCollectionRule:any) => {

          // Stores extra data inside of APE Rule for APE Rule Collection Rule
          const apeRule = {
            ...apeRuleCollectionRule.apeRule
          }
          apeRule.seqNo = apeRuleCollectionRule.seqNo;
          apeRule.apeRuleCollectionRuleId = apeRuleCollectionRule.id;
          return apeRule;
        });
        this.nextSeqNo = response.data.length;
        this.totalRowCount = response['totalRowCount'];
      },
      onError: errString => {
        super.showErrorSnack(errString)
      }
    })
  }

  keyEvent(event: KeyboardEvent) {
    const selected = this.selection?.selected;

    switch (event.key) {
      case "c":
        // this.onCreate();
        break;
      case "e":
        if (this.selectedRow) {
          // this.onEdit(this.selectedRow.id);
        }
        break;
      // case "d":
      //   this.onDelete(selected);
      //   break;
    }
  }

  clearSearch = () => {
    this.formGroup = this.createFormGroup();
  }

  getIconStyle = (rule:any) => {
    if(rule?.active) {
      return {"color":"green"}
    }

    return {"color":"red"}
  }

  toggleActive = (rule:any) => {
    let status = ""
    if(rule.active) {
      status = "Deactivate";
    }else {
      status = "Activate";
    }

    let id = rule.id;
    if(this.mode === "COLLECTION_RULES") {
      id = rule.apeRuleCollectionRuleId;
    }

    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      question : `${status} APE Rule '${rule.name}'?`
    };

    const dialogRef = this.dialog.open(SingleChoiceDialogComponent, matDialogConfig);
    if(this.mode === "COLLECTION_RULES") {
      dialogRef.afterClosed().subscribe(result => {
        if (result.confirm) {
          this.performXFRequest({
            requestDescription: "Toggle Active",
            requestFn: this.apeRuleCollectionService.deleteCollectionRule,
            fnParams: [id],
            onResponse: response => {
              let message = "APE Rule "
              if(rule.active) {
                message += "deactivated";
              }else {
                message += "activated";
              }

              this.snack.showInfoSnack(message);
              this.executeListQuery();
            },
            onError: errString => {
              super.showErrorSnack(errString)
            }
          })
        }
      });
    }

    if(this.mode === "ADMIN") {
      dialogRef.afterClosed().subscribe(result => {
        if (result.confirm) {
          this.performXFRequest({
            requestDescription: "Toggle Active",
            requestFn: this.apeRuleService._deactivate,
            fnParams: [id],
            onResponse: response => {
              let message = "APE Rule "
              if(rule.active) {
                message += "deactivated";
              }else {
                message += "activated";
              }

              this.snack.showInfoSnack(message);
              this.executeListQuery();
            },
            onError: errString => {
              super.showErrorSnack(errString)
            }
          })
        }
      });
    }
  }

  getIconString = (rule:any): string => {
    if(rule.active) {
      return "delete";
    }
    return "restore_from_trash";
  }

  getDeleteButtonText = (rule:any): string => {
    if(rule.active) {
      return "Deactivate";
    }
    return "Activate";
  }

  openDialog = (rule:any) => {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      rule:rule
    };
    const dialogRef = this.dialog.open(ApeRuleDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(() => {
      this.executeListQuery();
    })
  }

  addMode = () => {
    this.mode = "COLLECTION_ADD";
    this.executeListQuery();
  }

  collectionMode = () => {
    this.mode = "COLLECTION_RULES";
    this.executeListQuery();
  }

  collectionReorderMode = (rule:any, mode:string) => {
    this.reorderMode = mode;
    this.selectedApeRuleCollectionRule = rule;
    this.mode = "COLLECTION_REORDER";
    this.executeListQuery();
  }

  addAPERuleToAPERuleCollection = (apeRuleId:number) => {
    const collectionId = parseInt(this.apeRuleCollectionID);
    const dto = {
      id: -1,
      seqNo: this.nextSeqNo,
      apeRule: {
        id: apeRuleId
      },
      collection: {
        id: collectionId
      }
    }

    this.performXFRequest({
      requestDescription: "POST APE Rule Collection Rule",
      requestFn: this.apeRuleCollectionService.createUpdateAPERuleCollection,
      fnParams: [dto],
      onResponse: response => {
        // this.dataSource = response.data;
        // this.totalRowCount = response['totalRowCount'];
        this.collectionMode();
      },
      onError: errString => {
        super.showErrorSnack(errString)
      }
    })
  }

  moveRuleOnePlace(rule:any, down:boolean=false) {
    let seqNo = rule.seqNo;
    if(down){
      seqNo++;
    }else{
      seqNo--;
    }

    const dto = {
      movingEntityId: rule.apeRuleCollectionRuleId,
      targetSeqNo: seqNo,
      mode: "SWAP"
    }

    this.performXFRequest({
      requestDescription: "POST Reorder APE Rule Collection Rule",
      requestFn: this.apeRuleCollectionService.reorder,
      fnParams: [dto],
      onResponse: response => {
        // this.dataSource = response.data;
        // this.totalRowCount = response['totalRowCount'];
        super.showSuccessSnack(`${rule.name} moved to Sequence #${seqNo}`);
        this.executeListQuery();
      },
      onError: errString => {
        super.showErrorSnack(errString)
      }
    })
  }

  reorder(targetRule:any) {
    const dto = {
      movingEntityId: this.selectedApeRuleCollectionRule.apeRuleCollectionRuleId,
      targetSeqNo: targetRule.seqNo,
      mode: this.reorderMode
    }

    this.performXFRequest({
      requestDescription: "POST Reorder APE Rule Collection Rule",
      requestFn: this.apeRuleCollectionService.reorder,
      fnParams: [dto],
      onResponse: response => {
        // this.dataSource = response.data;
        // this.totalRowCount = response['totalRowCount'];
        super.showSuccessSnack(`${this.selectedApeRuleCollectionRule.name} moved to Sequence #${dto.targetSeqNo}`);

        this.collectionMode();
      },
      onError: errString => {
        super.showErrorSnack(errString)
      }
    })
  }

  selectButton(rule:any) {
    if(this.mode === "COLLECTION_ADD") {
      this.addAPERuleToAPERuleCollection(rule.id)
    }

    if(this.mode === "COLLECTION_REORDER") {
      this.reorder(rule);
    }
  }

  getHeaderString():string {
    if(this.mode === "COLLECTION_ADD") {
      return "Select new rule to Add";
    }

    if(this.mode === "COLLECTION_REORDER") {
      if(this.reorderMode === "AUTO") {
        return `Select new Sequence No for ${this.selectedApeRuleCollectionRule.name}`;
      }

      if(this.reorderMode === "SWAP") {
        return `Swap location for ${this.selectedApeRuleCollectionRule.name}`;
      }
    }

    return "";
  }

  openApeRuleDefinitionDialog(rule:any) {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      rule:rule
    };

    const dialogRef = this.dialog.open(ApeRuleEventDefinitionListDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(() => {
      this.executeListQuery();
    })
  }

  openApeRuleFilterDialog(rule:any) {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      apeRule:rule
    };

    const dialogRef = this.dialog.open(ApeRuleApeRuleFilterListComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(() => {
      this.executeListQuery();
    })
  }

  onSearch = () => {
    this.paginator?.firstPage();
    this.executeListQuery();
  }
}
