<div matDialogTitle>
  {{ this.mode }} APE Rule Collection
</div>

<div matDialogContent>
  <form [formGroup]="formGroup" class="container alt-theme">
    <mat-form-field appearance="outline" color="accent">
      <mat-label>Name</mat-label>
      <input matInput class="name" id="name" formControlName="name" required/>
    </mat-form-field>

    <mat-form-field appearance="outline" color="accent">
      <mat-label>Unique Identification Code</mat-label>
      <input matInput class="uniqueIdentificationCode" id="uniqueIdentificationCode" formControlName="uniqueIdentificationCode" required />
    </mat-form-field>

    <mat-form-field appearance="outline" color="accent">
      <mat-label>Description</mat-label>
      <textarea matInput cdkTextareaAutosize rows="4" class="description" id="description" formControlName="description" required></textarea>
    </mat-form-field>

  </form>
</div>

<div matDialogActions class="fennec-controls">
  <button mat-flat-button color="primary" (click)="cancel()">
    Cancel
  </button>
  <button [disabled]="formGroup.invalid" mat-flat-button color="accent" (click)="submit()">
    Submit
  </button>
</div>