import { AfterViewInit, Component, ElementRef, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UB04StatusTrackBaseDialogComponent } from '../ub04-status-track-base-dialog/ub04-status-track-base-dialog.component';
import { Ub04Service } from '../ub04.service';
import { UB04ConditionCodePacket } from "../../model/net/ub04-condition-code-packet";
import { CodeValidationService } from "../../code/code-validation.service";
import { StatusTrackService } from "../../status-track/status-track.service";
import { FennecSnackbarService } from "../../dialog/fennec-snackbar/fennec-snackbar.service";
import { BaseResponse } from '../../model/base-response';
import { UB04ConditionCodeBulkPacket } from '../../model/net/ub04-condition-code-bulk-packet';
import { Logger } from '../../util/logger';

@Component({
  selector: 'app-ub04-condition-code-dialog',
  templateUrl: './ub04-condition-code-dialog.component.html',
  styleUrls: [
    './ub04-condition-code-dialog.component.scss',
    '../ub04-dialog-base-styles.scss'
  ]
})
export class Ub04ConditionCodeDialogComponent extends UB04StatusTrackBaseDialogComponent implements OnInit, AfterViewInit {

  override log = new Logger("Ub04ConditionCodeDialogComponent");
  formGroup!: FormGroup;
  ub04ConditionCodes: any [] = [];

  @ViewChild("page", { read: ElementRef })
  element?: ElementRef;

  constructor(
    private codeValidationService: CodeValidationService,
    public dialogRef: MatDialogRef<Ub04ConditionCodeDialogComponent>,
    private statusTrackService: StatusTrackService,
    private renderer: Renderer2,
    override snack: FennecSnackbarService,
    private ub04Service: Ub04Service,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    super(snack, data.ub04Id, StatusTrackService.ELEMENT_UB04_EDIT_CONDITION_CODE, statusTrackService);
    this.constructFormGroup();
  }

  ngAfterViewInit(): void {
    if (this.element?.nativeElement) {
      this.renderer.listen(this.element.nativeElement, "keyup", (event) => this.keyEvent(event));
    }
  }

  ngOnInit(): void {
    this.fetchData();
  }

  getRowDisplayStyle(idx: number): any {
    let rcFormGroup: FormGroup = this.getConditionCodeFormGroup(idx);
    if (rcFormGroup.controls["deleteRow"].value) {
      return {
        "background-color": "rgba(255, 0, 0, 0.32)"
      };
    } else {
      return {};
    }
  }

  getIconStyle(idx: number){
    let rcFormGroup: FormGroup = this.getConditionCodeFormGroup(idx);
    let color = 'green';

    if(!rcFormGroup.valid){
      color = 'orange'
    }

    if(rcFormGroup.controls["deleteRow"].value){
      color = 'red'
    }

    return {
      color
    }
  }

  getDeleteRowByIdx(idx: number): any {
    let rcFormGroup: FormGroup = this.getConditionCodeFormGroup(idx);
    if (rcFormGroup.controls["deleteRow"].value) {
      return true;
    } else {
      return false;
    }
  }

  constructFormGroup() {
    this.formGroup = new FormGroup({
      conditionCodes: new FormArray([])
    });
  }

  get conditionCodes(): FormArray {
    return this.formGroup.controls["conditionCodes"] as FormArray;
  }

  getConditionCodeFormGroup(idx: number): FormGroup {
    return this.conditionCodes.at(idx) as FormGroup;
  }

  populateConditionCodesOnFormGroup() {
    if (this.ub04ConditionCodes.length <= 0) {
      this.onAddConditionCodeLine();
      return;
    }

    let conditionCodes = this.conditionCodes;
    conditionCodes.clear();
    let idx = 0;
    for (idx = 0; idx < this.ub04ConditionCodes.length; idx++) {
      let cc = this.ub04ConditionCodes[idx];
      let ccFormGroup: FormGroup = new FormGroup({
        id: new FormControl(cc.id),
        conditionCode: new FormControl(cc.conditionCode, [Validators.required, Validators.maxLength(4)]),
        description: new FormControl(cc.conditionCodeDescription),
        tempKey: new FormControl(this.codeValidationService.createUUID()),
        deleteRow: new FormControl(false),
      });
      ccFormGroup.controls['description'].disable();
      // Chatty condition code validation during data entry
      ccFormGroup.controls['conditionCode'].valueChanges.subscribe((val) => {
        this.validateConditionCode(ccFormGroup, val);
      });
      conditionCodes.push(ccFormGroup);
    }
  }

  onAddConditionCodeLine(){
    let newFormGroup: FormGroup = new FormGroup({
      id: new FormControl(-1),
      conditionCode: new FormControl("", [Validators.required, Validators.maxLength(4)]),
      description: new FormControl(""),
      tempKey: new FormControl(this.codeValidationService.createUUID()),
      deleteRow: new FormControl(false),
    }, {
      // updateOn: 'blur'
    });
    newFormGroup.controls['description'].disable();
    newFormGroup.controls['conditionCode'].valueChanges.subscribe((val) => {
      this.validateConditionCode(newFormGroup, val);
    });
    this.conditionCodes.push(newFormGroup);
  }

  validateConditionCode(row: FormGroup, conditionCodeRow: any) {
    let ccFormControl: FormControl = row.controls['conditionCode'] as FormControl;
    let descriptionFormControl: FormControl = row.controls['description'] as FormControl;

    if (!ccFormControl.pristine) {
      if (ccFormControl.value.length < 2 ||  ccFormControl.value.length > 4) {
        ccFormControl.markAsPristine();
        descriptionFormControl.setValue("Invalid", {onlySelf: true, emitEvent: false});
        ccFormControl.setErrors({'incorrect': true});
      } else {
        this.codeValidationService.getConditionCode(ccFormControl.value).subscribe((response: BaseResponse) => {
          if (response.hasErrors) {
            ccFormControl.markAsPristine();
            ccFormControl.setErrors({'incorrect': true});
          } else {
            if (response.data !== null) {
              ccFormControl.markAsPristine();
              descriptionFormControl.setValue(response.data.description, {onlySelf: true, emitEvent: false});
              ccFormControl.setErrors(null);
            } else {
              descriptionFormControl.setValue("Invalid", {onlySelf: true, emitEvent: false});
              ccFormControl.markAsPristine();
              ccFormControl.setErrors({'incorrect': true});

            }
          }
        });

      }
    }
  }

  findControlUsingTempKey(tempKey: string): FormGroup {
    let ctrl: FormGroup = new FormGroup({});
    let idx = 0;
    for (idx = 0; idx < this.conditionCodes.length; idx++) {
      let rc: FormGroup = this.getConditionCodeFormGroup(idx);
      if (rc.controls['tempKey'].value === tempKey) {
        ctrl = rc;
        break;
      }
    }
    return ctrl;
  }

  fetchData() {
    this.ub04Service.getUb04ConditionCodes(this.data.ub04Id).subscribe(response => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.ub04ConditionCodes = response.data;
        this.populateConditionCodesOnFormGroup();
      }
    });
  }

  onDelete(idx: number) {
    let rfg: FormGroup = this.getConditionCodeFormGroup(idx);
    if (rfg.controls["id"].value < 0) {
      this.conditionCodes.removeAt(idx);
    } else {
      rfg.controls["deleteRow"].setValue(!rfg.controls["deleteRow"].value);
    }
  }

  onCancel() {
    const returnObj = {
      confirm: false
    }
    this.dialogRef.close(returnObj);
  }

  onSubmit() {
    this.formGroup.markAllAsTouched();
    let updDto: UB04ConditionCodeBulkPacket = new UB04ConditionCodeBulkPacket();
    updDto.ub04Id = parseInt(this.data.ub04Id, 10);
    let ccRows: FormArray = this.conditionCodes;
    ccRows.controls.forEach((ccRow: any) => {
      if(ccRow.controls["conditionCode"].value != null){
        let ccDto: UB04ConditionCodePacket = new UB04ConditionCodePacket();
        ccDto.id = ccRow.controls["id"].value;
        ccDto.ub04Id = parseInt(this.data.ub04Id, 10);
        ccDto.conditionCode = ccRow.controls["conditionCode"].value;
        ccDto.deleteRow = ccRow.controls["deleteRow"].value;
        updDto.ub04ConditionCodes.push(ccDto);
      }
    });

    this.ub04Service.putUb04ConditionCodesBulkUpdate(updDto).subscribe(response => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        const returnObj = {
          confirm: true,
        }
        this.dialogRef.close(returnObj);
        this.snack.showSuccessSnack(`Successfully Updated!`);
      }
    });
  }

  keyEvent(event: KeyboardEvent) {
    switch (event.key) {
      case "Escape":
        this.onCancel();
        break;
      case "n":
        this.onAddConditionCodeLine();
        break;
    }
  }
}
