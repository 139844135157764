import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  BaseResponse,
  ClientReportPacket,
  EnvironmentService,
  ItemizedRevisionExportPacket,
  ProviderReportPacket
} from "xf-common";


@Injectable({
  providedIn: 'root'
})
export class ItemizedRevisionService {

  constructor(
    private httpClient: HttpClient,
    private environment: EnvironmentService
  ) { }

  getAllItemizedRevisionsForItemized(id: number): Observable<BaseResponse> {
    return this.httpClient.get<any>(this.environment.baseApiUrl + "itemized-revision/list/itemized/" + id.toString());
  }

  getItemizedRevisionListForItemizedPaginated(id: number, first:number, max:number): Observable<BaseResponse> {
    return this.httpClient.get<any>(this.environment.baseApiUrl + "itemized-revision/list/itemized/p/" + id.toString() + "?first=" + first + "&max=" + max);
  }

  getItemizedRevisionRevenueCodeSummary = (id: number): Observable<any> => {
    return this.httpClient.get<any>(this.environment.baseApiUrl + "itemized-revision/revenue-code-summary/" + id.toString());
  }

  sendItemizedRevisionToAPE(id: number): Observable<BaseResponse> {
    return this.httpClient.post<any>(this.environment.baseApiUrl + "ape/itemized-revision/claim-analysis/" + id.toString(), {});
  }

  getItemizedRevisionInfo(id: number): Observable<BaseResponse> {
    return this.httpClient.get<any>(this.environment.baseApiUrl + "itemized-revision/info/" + id.toString());
  }

  cloneItemizedRevision(id: number, name: string, type:string): Observable<BaseResponse> {
    return this.httpClient.get<any>(this.environment.baseApiUrl + "itemized-revision/clone/" + id.toString()
      + "?name=" + encodeURIComponent(name)
      + "&revisionType=" + encodeURIComponent(type))
  }

  generateItemizedProviderReport(payload: ProviderReportPacket): Observable<BaseResponse> {
    return this.httpClient.post<any>(this.environment.baseApiUrl + "report/provider-report", payload);
  }

  generateItemizedClientReport(payload: ClientReportPacket): Observable<BaseResponse> {
    return this.httpClient.post<any>(this.environment.baseApiUrl + "report/client-report", payload);
  }

  setItemizedRevisionToReportMaster(itemizedId:number, itemizedRevisionId:number): Observable<BaseResponse> {
    return this.httpClient.get<any>(this.environment.baseApiUrl + "itemized-revision/report-master/" + itemizedId + "/" + itemizedRevisionId);
  }

  getItemizedRevisionReviewTypeList = () => {
    return this.httpClient.get<any>(this.environment.baseApiUrl + "itemized-revision/revision-review-type/list");
  }

  updateItemizedRevision = (packet:any) => {
    return this.httpClient.post<any>(this.environment.baseApiUrl + "itemized-revision", packet);
  }

  generateItemizedRevisionXLSX = (packet:ItemizedRevisionExportPacket) => {
    return this.httpClient.post<any>(this.environment.baseApiUrl + "itemized-revision/export-xlsx", packet);
  }

  generateAppealReport = (payload:any) => {
    return this.httpClient.post<any>(this.environment.baseApiUrl + "report/appeal-report", payload);
  }

  deactivateItemizedRevision = (id:number) => {
    return this.httpClient.post<any>(`${this.environment.baseApiUrl}itemized-revision/deactivate`, id);
  }
}
