<h1 mat-dialog-title>Edit Condition Code</h1>

<div matDialogContent class="alt-theme">
    <form [formGroup]="formGroup" class="content">
        <mat-form-field appearance="outline">
            <mat-label>
                Code
            </mat-label>
            <input matInput formControlName="code">
            <mat-hint>{{formGroup.controls['code'].value.length}} of 4</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="outline" [style.width.px]=450>
            <mat-label>
                Description
            </mat-label>
            <textarea matInput rows="5" cdkTextareaAutosize formControlName="description" maxLength="1000"></textarea>
            <mat-hint>{{formGroup.controls['description'].value.length}} of 1000</mat-hint>
        </mat-form-field>
    </form>
</div>
   
  <div matDialogActions class="fennec-dialog-action-button-container">
    <button (click)="onClose()" mat-flat-button color="primary">
      <mat-icon>cancel</mat-icon> Cancel
    </button>    
    <button [disabled]="formGroup.invalid" (click)="onSave()" mat-flat-button color="accent">
        <mat-icon>save</mat-icon> Save
    </button>
  </div>

