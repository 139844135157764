import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { BaseComponent, DateUtil, FennecSnackbarService, Logger } from 'xf-common';
import { MiCaseReportElementsService } from './mi-case-report-elements.service';
import { ActivatedRoute } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { MiCaseReportElementPublishDateEditDialogComponent } from './mi-case-report-element-publish-date-edit-dialog/mi-case-report-element-publish-date-edit-dialog.component';

@Component({
  selector: 'app-mi-case-report-elements',
  templateUrl: './mi-case-report-elements.component.html',
  styleUrls: ['./mi-case-report-elements.component.scss']
})
export class MiCaseReportElementsComponent extends BaseComponent implements OnInit, AfterViewInit {

  protected override log: Logger = new Logger("MICaseReportElementsComponent");
  
  @Input()
  miCaseId: string = "-1";

  earliestProviderReportPublishDate:string = "None";
  earliestClientReportPublishDate:string = "None";
  earliestAppealReportPublishDate:string = "None";
  appealReceivedDate:string = "None";


  userCanEdit: boolean = false;
  reportElements:any;

  constructor(
    public matDialog: MatDialog,
    protected snack: FennecSnackbarService,
    private miCaseReportElementsService: MiCaseReportElementsService,
    private keycloakService: KeycloakService,
    private route: ActivatedRoute
  ) {
    super();
    this.miCaseId = this.route.parent?.snapshot.paramMap.get("miCaseId") ?? "-1";
    const roles = keycloakService.getUserRoles();
    this.userCanEdit = roles.includes("ADMIN") || roles.includes("RE_PUBLISH_DATE_OVERRIDE"); 
   }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.getMICaseReportElements();
  }

  getMICaseReportElements = () => {
    this.performXFRequest({
      requestDescription: "GET Case Report Elements Info",
      requestFn: this.miCaseReportElementsService.getMICaseReportElementsByMICaseId,
      fnParams: [parseInt(this.miCaseId)],
      onSuccess: data => {
        if (data != null) {
          if (data.earliestClientReportPublishDateString != null) {
            this.earliestClientReportPublishDate = DateUtil.getDisplayDateYYYY(data.earliestClientReportPublishDateString);
          }

          if (data.earliestProviderReportPublishDateString != null) {
            this.earliestProviderReportPublishDate = DateUtil.getDisplayDateYYYY(data.earliestProviderReportPublishDateString);
          }

          if (data.earliestAppealReportPublishDateString != null) {
            this.earliestAppealReportPublishDate = DateUtil.getDisplayDateYYYY(data.earliestAppealReportPublishDateString);
          }

          if (data.appealReceivedDateString != null) {
            this.appealReceivedDate = DateUtil.getDisplayDateYYYY(data.appealReceivedDateString);
          }
          this.reportElements = data;
        }
      },
      onError: errString => {
        super.showErrorSnack(errString);
      }
    });
  }

  openEditDialog = (mode:"Provider" | "Client" | "Appeal" | "appealReceivedDateString", currentDate:String) => {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      mode,
      miCaseId: this.miCaseId,
      date: currentDate
    }
    const dialogRef = this.matDialog.open(MiCaseReportElementPublishDateEditDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe((dialogResponse) => {
      if (dialogResponse.confirm) {
        this.getMICaseReportElements();
      }
    });
  }

}
