import { AfterViewInit, Component, EventEmitter, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { BaseComponent, FennecSnackbarService, Logger, SelectedUser } from 'xf-common';
import { UserProfileService } from '@app/user/user-profile.service';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';


@Component({
  selector: 'app-user-search-dialog',
  templateUrl: './user-search-dialog.component.html',
  styleUrls: ['./user-search-dialog.component.scss']
})
export class UserSearchDialogComponent extends BaseComponent implements AfterViewInit, OnInit {

  log: Logger = new Logger("UserSearchDialogComponent");
  displayedColumns = ["name", "id", "actions"];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  selectedUser: any = null;

  onSubmit$ = new EventEmitter<SelectedUser | null>();
  mode: "SELECT_USER" | "TASK_ACTION" = "TASK_ACTION";

  formGroup: FormGroup;

  @ViewChild(MatPaginator)
  // matDialogRef?: MatDialogRef<any>
  paginator?: MatPaginator;
  totalRowCount?: number;
  defaultPageSize = 8;
  pageSizeOptions = [8];

  constructor(
    protected snack: FennecSnackbarService,
    private userProfileService: UserProfileService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UserSearchDialogComponent>
  ) {
    super();
    this.formGroup = this.createFormGroup();
   }

  ngAfterViewInit(): void {
    if (this.paginator) {
      this.paginator.page.subscribe(() => {
        this.getActiveUserList();
      });
    }
  }

  ngOnInit() {
    this.getActiveUserList();
    if (this.data.mode) {
      this.mode = this.data.mode;
    }
    if (this.data.selectedUser != null) {
      this.selectedUser = this.data.selectedUser;
    }
  }

  onSubmit() {
    if (this.selectedUser === null || this.selectedUser === undefined) {
      return;
    }
    const returnObj = {
      confirm: true,
      user: this.selectedUser
    }
    this.dialogRef.close(returnObj);
  }

  onClose() {
    const returnObj = {
      confirm: false
    }
    this.dialogRef.close(returnObj);
  }

  getActiveUserList() {
    this.isLoading = true;
    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;
    this.performXFRequest({
      requestDescription: "GET Active Users",
      requestFn: this.userProfileService.getActiveUserList,
      fnParams: [first, pageSize],
      onSuccess: (data) => {
        this.dataSource.data = data;
      },
      onResponse: (response) => {
        this.totalRowCount = response['totalRowCount'];
      },
      onComplete: () => {
        this.isLoading = false;
      },
      onError: (error) => {
        super.showErrorSnack(error);
        this.isLoading = false;
      }
    });
  }

  onUserProfileSelect(user: any) {
    this.selectedUser = user;
  }

  isSaveDisabled():boolean {
    return this.selectedUser == null;
  }

  createFormGroup = () => {
    return new FormGroup({
      firstName: new FormControl(),
      lastName: new FormControl(),
      userId: new FormControl()
    })
  }

  executeSearchQuery = () => {
    const controls = this.formGroup?.controls;

    const searchParams = {
      firstName: controls["firstName"].value,
      lastName: controls["lastName"].value,
      userId: controls["userId"].value,
      active: true,
    }

    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;
    this.performXFRequest({
      requestDescription: "GET User Profile By Search Params",
      requestFn: this.userProfileService.getUserListByParams,
      fnParams: [searchParams, first, pageSize],
      onResponse: response => {
        this.dataSource = response.data;
        this.totalRowCount = response['totalRowCount'];
      },
      onError: errString => {
        super.showErrorSnack(errString)
      }
    })
  }

  clearSearch = () => {
    this.formGroup = this.createFormGroup();
    this.getActiveUserList();
  }
}
