<div matDialogTitle>
  Provider Create
  <mat-icon><span class="material-symbols-outlined">
    medical_information
  </span></mat-icon>
</div>
<div matDialogContent>
  <form [formGroup]="formGroup" class="container alt-theme">
    <mat-card class="child-container">
      <mat-card-header>
        <mat-card-title>
          Basic Information
        </mat-card-title>
      </mat-card-header>
      <div class="mat-card-divider"></div>
      <mat-card-content class="form-div">
        <mat-form-field appearance="outline">
          <mat-label>Provider Type</mat-label>
          <mat-select matInput formControlName="providerType">
            <mat-option *ngFor="let type of providerTypes" [value]="type.value">{{type.label}}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" *ngIf="formGroup.controls['providerType'].value == 'HOSPITAL' || formGroup.controls['providerType'].value == 'CARE_GIVER'">
          <mat-label>Organization Name</mat-label>
          <input matInput formControlName="name">
        </mat-form-field>

        <mat-form-field appearance="outline" *ngIf="formGroup.controls['providerType'].value == 'PHYSICIAN' || formGroup.controls['providerType'].value == 'CARE_GIVER'">
          <mat-label>First Name</mat-label>
          <input matInput formControlName="firstName">
        </mat-form-field>

        <mat-form-field appearance="outline" *ngIf="formGroup.controls['providerType'].value == 'PHYSICIAN' || formGroup.controls['providerType'].value == 'CARE_GIVER'">
          <mat-label>Last Name</mat-label>
          <input matInput formControlName="lastName">
        </mat-form-field>


        <mat-form-field appearance="outline">
          <mat-label>NPI Number</mat-label>
          <input matInput formControlName="npiNumber">
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Taxonomy Code</mat-label>
          <input matInput formControlName="taxonomyCode">
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Taxonomy Code Description</mat-label>
          <input matInput formControlName="taxonomyCodeDescription">
        </mat-form-field>
      </mat-card-content>
    </mat-card>
  </form>

  <app-address #addressComponent context="PROVIDER"></app-address>
</div>

<div matDialogActions class="fennec-controls">
  <button mat-flat-button color="accent" (click)="onSubmit()" [disabled]="!formGroup.valid">
    Submit
  </button>
  <button mat-stroked-button (click)="cancel()">
    Cancel
  </button>
</div>

