import { Deserializable, deserializeAssign } from "../deserializable";
import { AdjustmentExplanationCodePacket } from "./adjustmentExplanationCodePacket";
import { AdjustmentReasonCodePacket } from "./adjustmentReasonCodePacket";
import { APERule } from "./APERule";


export class APERuleEventDefinitionPacket implements Deserializable<APERuleEventDefinitionPacket> {
  id?: number;
  apeRule?: APERule;
  eventDefinitionCode?: string;
  name?: string;
  description?: string;
  adjustmentReasonCode?: AdjustmentReasonCodePacket;
  adjustmentExplanationCode?: AdjustmentExplanationCodePacket;
  active?:boolean;

  deserialize(input: any): APERule {
    return deserializeAssign(this, input);
  }

  toJSON(): any {
    const obj: { [key: string]: any } = {};
    Object.assign(obj, this);
    delete obj["selected"];
    return obj;
  }
}