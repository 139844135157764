import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { DataResponse, EnvironmentService } from "xf-common";


@Injectable({
  providedIn: 'root'
})
export class DuplicateEntityService {

  private baseUrl = `${this.environment.baseApiUrl}duplicate-entity`;
  private getDuplicateChildEntitiesUrl = `${this.baseUrl}/duplicate-children`;
  private getAllDuplicateInfoUrl = `${this.baseUrl}/all-duplicate-info`;
  private postRegisterDuplicateUrl = `${this.baseUrl}/register`;

  constructor(
    private httpClient: HttpClient,
    private environment: EnvironmentService
  ) {}

  getDuplicateChildEntities = (entityType: any, entityId: any): Observable<DataResponse<any>> => {
    // let params = new HttpParams();
    // params = params.set("entityId", entityId);
    // params = params.set("entityType", entityType);
    return this.httpClient.get<DataResponse<any>>(this.getDuplicateChildEntitiesUrl, { params: { entityId, entityType }});
  }

  getAllDuplicateInfo = (entityType: any, entityId: any): Observable<DataResponse<any>> => {
    // let params = new HttpParams();
    // params = params.set("entityId", entityId);
    // params = params.set("entityType", entityType);
    return this.httpClient.get<DataResponse<any>>(this.getAllDuplicateInfoUrl, { params: { entityId, entityType }});
  }

  postRegisterDuplicate = (entityType: any, parentEntityId: any, duplicateEntityId: any): Observable<DataResponse<any>> => {
    let params = new HttpParams();
    if (parentEntityId != null) {
      params = params.set("parentEntityId", parentEntityId);
    }
    params = params.set("entityType", entityType);
    params = params.set("duplicateEntityId", duplicateEntityId);
    return this.httpClient.post<DataResponse<any>>(this.postRegisterDuplicateUrl, {}, { params });
  }
}
