<mat-tab-group #tabGroup (selectedTabIndex)="selectedTabIndex">
  <mat-tab label="General">
    <ng-template matTabContent>
      <app-admin-user-profile-edit [userProfile]="userProfile"></app-admin-user-profile-edit>
    </ng-template>
  </mat-tab>

  <mat-tab label="Companies">
    <ng-template matTabContent>
      <app-tenant-company-list mode="USER_PROFILE" relatedId="{{userProfile?.id}}"></app-tenant-company-list>
    </ng-template>
  </mat-tab>

  <mat-tab label="Plans">
    <ng-template matTabContent>
      <app-health-care-plan-relational-list mode="USER_PROFILE" relatedId="{{userProfile?.id}}"></app-health-care-plan-relational-list>
      <!-- <app-user-plan-list [userId]="userProfile?.id ?? -1"></app-user-plan-list> -->
    </ng-template>
  </mat-tab>
</mat-tab-group>