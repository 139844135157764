import { Deserializable, deserializeAssign } from "../deserializable";


export class HealthCarePlanPacket implements Deserializable<HealthCarePlanPacket> {
  id: number = -1;
  name: string = "";
  stateCode: string = "";
  stateName: string = "";
  companyId?: number;
  active?: boolean;

  deserialize(input: any): HealthCarePlanPacket {
    return deserializeAssign(this, input);
  }

  toJSON(): any {
    const obj: { [key: string]: any } = {};
    Object.assign(obj, this);
    delete obj["selected"];
    return obj;
  }
}
