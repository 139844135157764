import { Deserializable, deserializeAssign } from "../deserializable";

export class CommentPacket implements Deserializable<CommentPacket> {

  id?: number;
  commentType?: string;
  commentText?: string;
  active?: boolean;

  deserialize(input: any): CommentPacket {
    return deserializeAssign(this, input);
  }

  toJSON(): any {
    const obj: { [key: string]: any } = {};
    Object.assign(obj, this);
    delete obj["selected"];
    return obj;
  }
}