<ng-template #innerContent>
  <div class="page-content-div">
    <div class="filter-wrapper">
      <mat-accordion class="filters">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h4>Filters</h4>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <form [formGroup]="formGroup" class="filter-options alt-theme" (submit)="getTaskList()">
            <mat-form-field class="search-field" appearance="outline" color="accent">
              <mat-label>
                Status
              </mat-label>
              <mat-select formControlName="xfwfListStatus">
                <mat-option value="All">All</mat-option>
                <mat-option value="OPERATIVE">Operative</mat-option>
                <mat-option *ngFor="let task of taskStatuses" [value]="task.value">{{task.label}}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="search-field" appearance="outline" color="accent">
              <mat-label>
                Type
              </mat-label>
              <mat-select formControlName="xfwfListType">
                <mat-option value="All">All</mat-option>
                <mat-option *ngFor="let type of taskTypes" [value]="type.value">{{type.label}}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="!miCaseId" class="search-field" appearance="outline" color="accent">
              <mat-label>
                Stay ID
              </mat-label>
              <input type="number" matInput formControlName="miCaseId" autofocus>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>User Group</mat-label>
              <mat-select formControlName="userGroupSearch">
                <mat-option [value]="-1">None</mat-option>
                @for (option of userGroupOptions; track option.id) {
                  <mat-option [value]="option.id">{{option.name}}</mat-option>
                }
              </mat-select>
            </mat-form-field>

            <!-- <mat-form-field *ngIf="admin" appearance="outline"> -->
            <mat-radio-group *ngIf="admin || allowUnassignedTaskRead || this.mode === 'ALL'"
                             formControlName="userId" style="margin-left: 10px">
              <!-- <mat-label>
                User ID
              </mat-label> -->
              <mat-radio-button
                class="radio-btn"
                value="MINE"
              >My Tasks
              </mat-radio-button>

              <mat-radio-button
                value="ALL"
              >Tasks for All Users
              </mat-radio-button>
            </mat-radio-group>
            <!-- </mat-form-field> -->

            <mat-form-field *ngIf="displayAssignedUserSearch" class="user-id-search-field" appearance="outline" color="accent">
              <mat-label>
                Assigned User ID
              </mat-label>
              <input matInput formControlName="userIdSearch">
            </mat-form-field>

            <button
              *ngIf="displayAssignedUserSearch"
              mat-raised-button
              color="accent"
              type="submit"
              class="submit-button"
            >
              Search
            </button>
          </form>


        </mat-expansion-panel>
      </mat-accordion>
      <button class="add" mat-icon-button mat-raised-button color="accent" 
      (click)="createManualTask()" *ngIf="showCreateManualButton">
        <mat-icon>add_circle</mat-icon>
      </button>
    </div>
    <mat-table [dataSource]="taskList" class="task-list-table mat-elevation-z8">

      <!-- Id Column -->
      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef> Stay ID </mat-header-cell>
        <mat-cell *matCellDef="let t">
          <button mat-button mat-raised-button color="accent" class="mi-case-btn xfwf-task-mat-cell-button-text" (click)="onOpenMiCase(t.miCaseId)">
            <mat-icon inline="true" matListIcon>folder</mat-icon>
            {{t.miCaseId }}
          </button>
        </mat-cell>
      </ng-container>

      <!-- assigned to Column -->
      <ng-container matColumnDef="assigned-to">
        <mat-header-cell *matHeaderCellDef> Assigned To </mat-header-cell>
        <mat-cell *matCellDef="let t">
          <app-truncate-text>{{ (t.firstName || t.lastName) ? t.firstName + ' ' + t.lastName : 'N/A' }}</app-truncate-text>
        </mat-cell>
      </ng-container>

      <!-- miCaseName Column -->
      <ng-container matColumnDef="mi-case-name">
        <mat-header-cell *matHeaderCellDef> Case Name </mat-header-cell>
        <mat-cell *matCellDef="let t"> <app-truncate-text>{{t.miCaseName}}</app-truncate-text> </mat-cell>
      </ng-container>

      <!-- Mi Case Status Track Status Column -->
      <ng-container matColumnDef="status-track-status">
        <mat-header-cell *matHeaderCellDef> Case Status </mat-header-cell>
        <mat-cell *matCellDef="let t"> 
          <span *ngIf="t.caseOnHold" style="color: darkred"><app-truncate-text>{{t.statusTrackStatus}}</app-truncate-text></span>
          <span *ngIf="!t.caseOnHold"><app-truncate-text>{{t.statusTrackStatus}}</app-truncate-text></span> 
           
        </mat-cell>
      </ng-container>

      <!-- Total Billed Amount -->
      <ng-container matColumnDef="total-billed-amount">
        <mat-header-cell *matHeaderCellDef> Bill Amt </mat-header-cell>
        <mat-cell *matCellDef="let t"> <app-truncate-text>{{getDisplayString(t.totalBilledAmount ?? 0)}}</app-truncate-text> </mat-cell>
      </ng-container>

      <!-- Task Status Column -->
      <ng-container matColumnDef="task-status">
        <mat-header-cell *matHeaderCellDef> Task Status </mat-header-cell>
        <mat-cell *matCellDef="let t" [matTooltip]="t.taskStatus === 'COMPLETE' ? 'Completed: ' + (t.completedDate | date: 'MM/dd/yyyy h:mm a' ) : ''"> 
          {{t.taskStatus}} 
        </mat-cell>
      </ng-container>

      <!-- Task Type Column -->
      <ng-container matColumnDef="task-type">
        <mat-header-cell *matHeaderCellDef> Task Type </mat-header-cell>
        <mat-cell *matCellDef="let t"> 
          <table>
          <tr [style.color]="t.taskType == 'CR_ASSIGN_REVENUE_CODES' ? 'darkorange' : 'black'"
            [style.font-weight]="t.taskType == 'CR_ASSIGN_REVENUE_CODES' ? 'bold' : 'normal'"
            [style.width]="'100%'">
            <td>{{t.taskTypeLabel}}</td> 
          </tr>
          <tr *ngIf="t.taskType == 'ON_HOLD'">
            <td style="color: darkred">{{t.holdReasonUserLabel}}</td>
          </tr>
          </table>
        </mat-cell>
      </ng-container>

      <!-- Last Action Taken Column -->
      <ng-container matColumnDef="last-action-taken">
        <mat-header-cell *matHeaderCellDef> Last Action </mat-header-cell>
        <mat-cell *matCellDef="let t"> <app-truncate-text>{{t.lastActionTaken}}</app-truncate-text> </mat-cell>
      </ng-container>

      <!-- Task Start Date Column -->
      <ng-container matColumnDef="start-date">
        <mat-header-cell *matHeaderCellDef> Start Date </mat-header-cell>
        <mat-cell *matCellDef="let t">
          <app-truncate-text [fullText]="t.startDate | date: 'MM/dd/yyyy h:mm a'">{{ t.startDate | date: 'MM/dd/yyyy' }}</app-truncate-text>
        </mat-cell>
      </ng-container>

      <!-- Task Due Date Column -->
      <ng-container matColumnDef="due-date">
        <mat-header-cell *matHeaderCellDef (click)="toggleDueDateOrderBy()" style="cursor: pointer;">
          <mat-icon>{{dueDateOrderBy === "ASC" ? "arrow_drop_up" : "arrow_drop_down"}}</mat-icon>
          Due Date
        </mat-header-cell>
        <mat-cell *matCellDef="let t" 
          [matTooltip]="t.taskStatus === 'COMPLETE' ? 'Completed: ' + (t.completedDate | date: 'MM/dd/yyyy h:mm a') : ''">
          <span *ngIf="t.caseOnHold" style="color: darkred">{{ t.taskDueDateString | date: 'MM/dd/yyyy' }}</span>
          <span *ngIf="!t.caseOnHold">{{ t.taskDueDateString | date: 'MM/dd/yyyy' }}</span>
        </mat-cell>
      </ng-container>

      <!-- Task Created Date Column -->
      <ng-container matColumnDef="created-date">
        <mat-header-cell *matHeaderCellDef> Created Date </mat-header-cell>
        <mat-cell *matCellDef="let t">
          <app-truncate-text [fullText]="t.createdDate | date: 'MM/dd/yyyy h:mm a'">{{t.createdDate | date: 'MM/dd/yyyy'}}</app-truncate-text>
        </mat-cell>
      </ng-container>

      <!-- Task Due Date Column -->
      <ng-container matColumnDef="last-modified-date">
        <mat-header-cell *matHeaderCellDef> Last updated </mat-header-cell>
        <mat-cell *matCellDef="let t">
          <app-truncate-text [fullText]="t.lastModifiedDate | date: 'MM/dd/yyyy h:mm a'">{{ t.lastModifiedDate | date: 'MM/d/yyyy' }}</app-truncate-text>
        </mat-cell>
      </ng-container>

      <!-- Task Actions Column -->
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
        <mat-cell *matCellDef="let t">

          <mat-menu #actionMenu="matMenu">
            <!-- Renders menu button for each action -->
            <button
              *ngFor="let action of t.actions"
              mat-menu-item
              (click)="onAction(t.id, action.actionName, action.actionLabel)"
            >
              {{action.actionLabel}}
            </button>

            <button
              *ngIf="t.taskType == 'ON_HOLD' && t.taskStatus != 'COMPLETE' && view !== 'MICASE'"
              mat-menu-item
              (click)="openHoldInfoForMICase(t)"
            >
              Review Hold
            </button>

            <!-- Unassigned Task Assign -->
            <button
              *ngIf="t.taskStatus == 'UNASSIGNED' && allowUnassignedTaskAssign"
              mat-menu-item
              (click)="onAction(t.id, 'ASSIGN_TASK', 'Assign Task')"
            >
              Assign Task
            </button>

            <!-- Task Re-Assign -->
            <button
              *ngIf="t.taskStatus == 'ASSIGNED' && allowUnassignedTaskAssign"
              mat-menu-item
              (click)="onAction(t.id, 'REASSIGN_TASK', 'Reassign Task')"
            >
              Reassign Task
            </button>

            <!-- Override -->
            <button
              *ngIf="t.taskType == 'ITEMIZED_DATA_ENTRY' && allowOverride"
              mat-menu-item
              (click)="onManualOverride(t.id)"
            >
              Manual Override
            </button>
          </mat-menu>

          <button
            mat-icon-button
            [matMenuTriggerFor]="actionMenu"
            *ngIf="initialized && actionMenu?._directDescendantItems?.length"
          >
            <mat-icon>more</mat-icon>
          </button>

        </mat-cell>
      </ng-container>

      <!-- Override Column -->
      <ng-container matColumnDef="override">
        <mat-header-cell *matHeaderCellDef> Ovrd </mat-header-cell>
        <mat-cell *matCellDef="let t">
          <ng-container *ngIf="t.taskType == 'ITEMIZED_DATA_ENTRY'">
            <button mat-icon-button (click)="onManualOverride(t.id)">
              <mat-icon inline="true">eject</mat-icon>
            </button>
          </ng-container>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="comments">
        <mat-header-cell *matHeaderCellDef> Comments </mat-header-cell>
        <mat-cell *matCellDef="let t">
          <!-- <button mat-icon-button (click)="openCommentDialog(t.id)">
            <mat-icon [ngClass]="t.commentCount > 0 ? 'xfwf-task-has-comments' : ''">comment</mat-icon>
          </button> -->

          <app-comment-list-dialog-button
            buttonType="icon"
            commentMode="XFWF_TASK"
            [relatedId]="t.id"
            [externalCommentCount]="t.commentCount"
          ></app-comment-list-dialog-button>
        </mat-cell>
      </ng-container>

      <!-- Activity Log Column -->
      <ng-container matColumnDef="history">
        <mat-header-cell *matHeaderCellDef> Activity Log </mat-header-cell>
        <mat-cell *matCellDef="let t">
          <button style="width: 20px" matTooltip="List" mat-icon-button (click)="onOpenActivityLog(t.id)">
            <mat-icon>view_kanban</mat-icon>
          </button>
          <button style="width: 20px" matTooltip="Dialog" mat-icon-button (click)="onOpenActivityLogDialog(t.id)">
            <mat-icon>view_kanban</mat-icon>
          </button>          
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"
               [class.row-on-hold]="row.caseOnHold && row.taskStatus !== 'COMPLETE' && row.taskStatus !== 'TERMINATED'"
               [class.row-deadline-approaching]="row.deadlineApproaching && row.taskStatus !== 'COMPLETE' && row.taskStatus !== 'TERMINATED'"
               [class.row-started]="row.taskStatus === 'STARTED'"
               [class.row-terminated]="row.taskStatus === 'TERMINATED'"
               [class.row-assigned]="row.taskStatus === 'ASSIGNED'"
               [class.row-complete]="row.taskStatus === 'COMPLETE'"
      ></mat-row>
    </mat-table>
    <mat-paginator [length]="totalRowCount" [pageSize]="defaultPageSize" [pageSizeOptions]="pageSizeOptions"></mat-paginator>
  </div>
</ng-template>

<ng-container *ngIf="view !== 'MICASE'; else innerContent">
  <lib-page>
    <ng-container pageTitle>
      <span>XF Workflow</span>
    </ng-container>
    <ng-container pageContent>
      <ng-container *ngTemplateOutlet="innerContent"></ng-container>
    </ng-container>
  </lib-page>
</ng-container>
