
<mat-toolbar class="app-toolbar horizontal-nav">
  <button
    *ngIf="deviceIsMobile"
    mat-icon-button
    class="navbar-icon"
    aria-label="Open navigation drawer"
    (click)="sidenav.toggle()"
  >
    <mat-icon>menu</mat-icon>
  </button>
  <div class="title">
    <a href="">Fennec</a>
<!--    <img class="fennec-logo" src="../assets/logo.png">-->
  </div>
  <!-- <div class="fennec-text">
    <img src="../assets/FennecText.png">
  </div> -->
  <!-- <div class="fennec-logo">
    <img src="../assets/logo.png">
  </div> -->
<!--  <div class="user-welcome">-->
<!--    <h2 *ngIf="username">Welcome, {{ username }}</h2>-->
<!--  </div>-->

  <ng-container *ngIf="!deviceIsMobile" [ngTemplateOutlet]="navList"></ng-container>

  <span class="spacer" *ngIf="deviceIsMobile"></span>

  <div class="user-logout">
    <button id="fennec-logout-button" mat-icon-button (click)="logout()" matTooltip="Logout">
      <mat-icon>logout</mat-icon>
    </button>
  </div>
</mat-toolbar>

<mat-sidenav-container #sidenavContainer [hasBackdrop]="false">
  <mat-sidenav mode="side" class="vertical-nav" #sidenav>
    <ng-container *ngIf="deviceIsMobile" [ngTemplateOutlet]="navList"></ng-container>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #navList>
  <mat-nav-list [class.horizontal-nav]="!deviceIsMobile">
    <!-- <a mat-list-item routerLink="/">Home</a> -->
    <a mat-list-item routerLink="/">Tasks</a>
    <a mat-list-item routerLink="/mi-case">Medical Incident Stays</a>
    <a mat-list-item routerLink="/providers">Providers</a>
    <a mat-list-item routerLink="/companies">Companies</a>
    <a mat-list-item routerLink="/health-care-plan">Plans</a>
    <a mat-list-item *ngIf="userIsPatientRead" routerLink="/patients">Patients</a>
    <a *ngIf="userIsAdmin || userIsCrudAdmin" mat-list-item routerLink="/admin">Admin</a>
    <a *ngIf="userIsAdmin" mat-list-item routerLink="/jsl">JSL</a>
    <a mat-list-item routerLink="/edi-run">EDI Run</a>
    <a mat-list-item routerLink="/activity-diary">Activity Diary</a>
    <a *ngIf="userIsAdmin" mat-list-item routerLink="/users">Users</a>
    <a *ngIf="userIsAdmin || userIsApeAdmin" mat-list-item routerLink="/ape">APE</a>
    <a *ngIf="userIsAdmin || userIsSystemAdmin" mat-list-item routerLink="/system-configuration">System Configuration</a>
    <div class="spacer"></div>
    <a mat-list-item routerLink="/profile">Profile</a>
  </mat-nav-list>
</ng-template>
