import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import {
  APERuleFilter,
  APERuleFilterHealthCarePlanPacket,
  BaseComponent,
  FennecSnackbarService,
  HealthCarePlanPacket,
  Logger
} from 'xf-common';
import { ApeRuleFilterHealthCarePlanService } from '../ape-rule-filter-health-care-plan-service.service';
import { HealthCarePlanService } from '@app/health-care-plan/health-care-plan.service';
import { UserProfileService } from '@app/user/user-profile.service';

@Component({
  selector: 'app-add-ape-rule-filter-health-care-plan-dialog',
  templateUrl: './add-ape-rule-filter-health-care-plan-dialog.component.html',
  styleUrls: ['./add-ape-rule-filter-health-care-plan-dialog.component.scss']
})
export class AddApeRuleFilterHealthCarePlanDialogComponent extends BaseComponent implements OnInit {

  protected log = new Logger("AddApeRuleFilterHealthCarePlanDialogComponent");

  @Output()
  closeSearchEvent: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  apeRuleFilterId: number = -1;

  healthCarePlanList: any[] = [];

  selectedHealthCarePlanId: number = -1;

  displayColumns = ["select", "compnayName", "planName"];

  currentUserId: number = -1;

  @ViewChild(MatPaginator)
  paginator?: MatPaginator;
  totalRowCount?: number;
  defaultPageSize = 20;
  pageSizeOptions = [5, 10, 20, 25];

  constructor(
    override snack: FennecSnackbarService,
    protected healthCarePlanService: HealthCarePlanService,
    protected apeRuleFilterHealthCarePlanService: ApeRuleFilterHealthCarePlanService,
    public userProfileService: UserProfileService,
  ) {
    super();
  }
  ngAfterViewInit(): void {
    this.getHealthCarePlanList();
  }

  ngOnInit(): void {
  }

  getHealthCarePlanList = () => {
    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;
    this.performXFRequest({
      requestDescription: "GET Health Care Plan List",
      requestFn: this.healthCarePlanService.getAllAssignedHealthCarePlansForCurrentUser,
      fnParams: [first, pageSize],
      onSuccess: data => {
        this.healthCarePlanList = data;
      },
      onError: errString => {
        super.showErrorSnack(errString);
      },
      onResponse: response => {
        this.totalRowCount = response['totalRowCount'];
      }
    });
  }

  selectHealthCarePlan = (healthCarePlanId: number) => {
    this.selectedHealthCarePlanId = healthCarePlanId;
  }

  submit() {

    const payload = new APERuleFilterHealthCarePlanPacket();
    payload.apeRuleFilter = new APERuleFilter();
    payload.apeRuleFilter.id = this.apeRuleFilterId;
    payload.healthCarePlan = new HealthCarePlanPacket();
    payload.healthCarePlan.id = this.selectedHealthCarePlanId;

    this.performXFRequest({
      requestDescription: "CREATE APE Rule Filter Health Care Plan",
      requestFn: this.apeRuleFilterHealthCarePlanService._create,
      fnParams: [payload],
      onSuccess: data => {
        super.showSuccessSnack("Health Care Plan Successfuly Added to Ape Rule Filter");
        this.closeSearchEvent.emit();
      },
      onError: errString => {
        super.showErrorSnack(errString);
      }
    });
  }

}
