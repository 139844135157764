<mat-table [dataSource]="caseList">

  <ng-container matColumnDef="id">
    <mat-header-cell *matHeaderCellDef>ID</mat-header-cell>
    <mat-cell *matCellDef="let case"> 
      <button 
        mat-raised-button color="accent" class="mi-case-btn"
        (click)="navigateToMICase(case)"
        >
            <mat-icon matListIcon>folder</mat-icon> {{case.id}}
          </button>  
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="type">
    <mat-header-cell *matHeaderCellDef>Case Type</mat-header-cell>
    <mat-cell *matCellDef="let micase"> {{ micase.type }} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="stage">
    <mat-header-cell *matHeaderCellDef>Stage</mat-header-cell>
    <mat-cell *matCellDef="let micase"> {{ micase.currentXFWFStageName }} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef> Case Name </mat-header-cell>
    <mat-cell *matCellDef="let case"> <app-truncate-text>{{case.name}}</app-truncate-text> </mat-cell>
  </ng-container>

  <ng-container matColumnDef="statusTrackStatus">
    <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
    <mat-cell *matCellDef="let case">
      <ng-container *ngIf="case?.statusTrackStatusCode === 'CREATED_UNASSIGNED' || case?.statusTrackStatusCode === 'IN_PROGRESS_UNASSIGNED' || case?.statusTrackStatusCode === 'AWAITING_NEXT_TASK_CREATE'; else noHyperlink">
        <table>
          <tr>
            <td>
              <app-truncate-text>
                {{case.statusTrackStatus}}
              </app-truncate-text>
            </td>
          </tr>
          <tr>
            <td style="font-size: small;">{{case.customerFacingStatus}}</td>
          </tr>
        </table>
        <app-truncate-text>

        </app-truncate-text>
      </ng-container>
      <ng-template #noHyperlink>
        <table>
          <tr>
            <td>
              <span [matTooltip]="case.onHold ? case.holdReasonUserLabel || 'Unspecified' : case.customerFacingStatus">
                {{case.statusTrackStatus}}
              </span>
            </td>
          </tr>
          <tr>
            <td style="font-size: small;">{{case.customerFacingStatus}}</td>
          </tr>
        </table>
      </ng-template>
    </mat-cell>
  </ng-container>

  <!-- Alt External ID Column (labeled client claim id) -->
  <ng-container matColumnDef="altexternalid">
    <mat-header-cell *matHeaderCellDef> Client Claim ID </mat-header-cell>
    <mat-cell *matCellDef="let case"> <app-truncate-text>{{case.altExternalId}}</app-truncate-text> </mat-cell>
  </ng-container>

  <ng-container matColumnDef="patientName">
    <mat-header-cell *matHeaderCellDef> Patient Name </mat-header-cell>
    <mat-cell *matCellDef="let case">
      <ng-template #notAvailable>
        <app-truncate-text class="patient-name-not-available">{{case.patientLastName}}</app-truncate-text>
      </ng-template>
      <app-truncate-text *ngIf="case.patientLastName != 'Not Available'; else notAvailable"></app-truncate-text>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="clientName">
    <mat-header-cell *matHeaderCellDef> Client </mat-header-cell>
    <mat-cell *matCellDef="let case">
      <table>
        <tr>
          <td><app-truncate-text>{{case.clientName}}</app-truncate-text></td>
        </tr>
        <tr>
          <td style="font-size: small;">{{case.healthCarePlanName}}</td>
        </tr>
      </table>
    </mat-cell>
  </ng-container>
  
  <ng-container matColumnDef="lineOfBusiness">
    <mat-header-cell *matHeaderCellDef> Line Of Business </mat-header-cell>
    <mat-cell *matCellDef="let case"> <app-truncate-text>{{case.lineOfBusiness}}</app-truncate-text> </mat-cell>
  </ng-container>

  <ng-container matColumnDef="startDate">
    <mat-header-cell *matHeaderCellDef> 
      Start Date 
      <button
        mat-icon-button
        (click)="frontEndSort('coverPeriodFromDate')"
      >
        <mat-icon>
          {{ 
            sortOptions['coverPeriodFromDate'] == "ASC" ? 'expand_less' :
            sortOptions['coverPeriodFromDate'] == "DESC" ? 'expand_more' :
            'remove'
          }}
        </mat-icon>
      </button>
    </mat-header-cell>
    <mat-cell *matCellDef="let case">
      <app-truncate-text>
        {{ case.coverPeriodFromDate }}
      </app-truncate-text>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="endDate">
    <mat-header-cell *matHeaderCellDef> 
      End Date 
      
      <button
        mat-icon-button
        (click)="frontEndSort('coverPeriodThruDate')"
      >
        <mat-icon>
          {{ 
            sortOptions['coverPeriodThruDate'] == "ASC" ? 'expand_less' :
            sortOptions['coverPeriodThruDate'] == "DESC" ? 'expand_more' :
            'remove'
          }}
        </mat-icon>
      </button>
    </mat-header-cell>
    <mat-cell *matCellDef="let case">
      <app-truncate-text>
        {{ case.coverPeriodThruDate }}
      </app-truncate-text>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="totalBilledCharges">
    <mat-header-cell *matHeaderCellDef> Total Billed Charges 
      <button
        mat-icon-button
        (click)="frontEndSort('ub04RevenueCodeSummaryTotal')"
      >
        <mat-icon>
          {{ 
            sortOptions['ub04RevenueCodeSummaryTotal'] == "ASC" ? 'expand_less' :
            sortOptions['ub04RevenueCodeSummaryTotal'] == "DESC" ? 'expand_more' :
            'remove'
          }}
        </mat-icon>
      </button>

    </mat-header-cell>
    <mat-cell *matCellDef="let case">
      <app-truncate-text>
        ${{ case.ub04RevenueCodeSummaryTotal ?? "0.00" }}
      </app-truncate-text>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="createdBy">
    <mat-header-cell *matHeaderCellDef> Created </mat-header-cell>
    <mat-cell *matCellDef="let case">
      <div>
        <table>
          <tr>
            <td style="font-size: small;"><app-truncate-text>{{case.createdUser}}</app-truncate-text></td>
          </tr>
          <tr>
            <td style="font-size: small;">{{case.createdDate | date:'MM/dd/yyyy'}}</td>
          </tr>
        </table>
      </div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="createdDate">
    <mat-header-cell *matHeaderCellDef> Created Date </mat-header-cell>
    <mat-cell *matCellDef="let case"> <app-truncate-text>{{case.createdDate | date:'MM/dd/yyyy'}}</app-truncate-text> </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayColumns;"></mat-row>
</mat-table>