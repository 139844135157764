<div matDialogTitle>
  <h2>APE Event Diary

    <button
      class="close-btn"
      mat-icon-button
      (click)="closeDialog()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </h2>
</div>

<mat-dialog-content>
  <mat-table [dataSource]="diaryEntries">
    <ng-container matColumnDef="apeRuleName">
      <mat-header-cell *matHeaderCellDef>Rule Name</mat-header-cell>
      <mat-cell *matCellDef="let eventDiary">{{eventDiary?.apeRuleEventDefinition?.apeRule?.name}}</mat-cell>
    </ng-container>
  
    <ng-container matColumnDef="apeRuleCode">
      <mat-header-cell *matHeaderCellDef>Rule Code</mat-header-cell>
      <mat-cell *matCellDef="let eventDiary">{{eventDiary?.apeRuleEventDefinition?.apeRule?.serviceIdentificationCode}}</mat-cell>
    </ng-container>
  
    <ng-container matColumnDef="eventName">
      <mat-header-cell *matHeaderCellDef>Event Name</mat-header-cell>
      <mat-cell *matCellDef="let eventDiary">{{eventDiary?.apeRuleEventDefinition?.name}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="eventCode">
      <mat-header-cell *matHeaderCellDef>Event Code</mat-header-cell>
      <mat-cell *matCellDef="let eventDiary">{{eventDiary?.apeRuleEventDefinition?.eventDefinitionCode}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="adjustmentExplanationCode">
      <mat-header-cell *matHeaderCellDef>Adjustment Explanation Code</mat-header-cell>
      <mat-cell *matCellDef="let eventDiary">{{eventDiary?.apeRuleEventDefinition?.adjustmentExplanationCode?.code}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="adjustmentReasonCode">
      <mat-header-cell *matHeaderCellDef>Adjustment Reason Code</mat-header-cell>
      <mat-cell *matCellDef="let eventDiary">{{eventDiary?.apeRuleEventDefinition?.adjustmentReasonCode?.code}}</mat-cell>
    </ng-container>
  
    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: displayedColumns; let i=index"
      [tabindex]="i + 1"
      [attr.data-index]="i"
      [ngClass]="{ hovered: row.hovered }"
      (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"
    ></mat-row>
  </mat-table>
</mat-dialog-content>
