import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { BaseComponent, FennecSnackbarService, Logger, ProcedureCodePacket } from "xf-common";

@Component({
  selector: 'app-procedure-code-dialog',
  templateUrl: './procedure-code-dialog.component.html',
  styleUrls: ['../../../../lib/styles/system-admin-styles.component.scss']
})
export class ProcedureCodeDialogComponent extends BaseComponent implements OnInit {
  protected log = new Logger("ProcedureCodeDialogComponent")
  mode?: "create" | "edit" | "view"
  procedureCode?: ProcedureCodePacket
  formGroup: FormGroup

  constructor(
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<ProcedureCodeDialogComponent>,
    override snack: FennecSnackbarService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    super();
    this.mode = this.data?.mode
    this.procedureCode = this.data?.procedureCode
    this.formGroup = new FormGroup({
      code: new FormControl(this.procedureCode?.code ?? "", Validators.required),
      description: new FormControl(this.procedureCode?.description ?? "", [Validators.required, Validators.maxLength(300)])
    })
  }

  ngOnInit(): void {
  }

  submit() {
    const procedureCode: ProcedureCodePacket = this.procedureCode ?? new ProcedureCodePacket()
    const formValue = this.formGroup.value
    if (!formValue.code) {
      super.showErrorSnack("Code is required")
      return
    }
    if (!formValue.description) {
      super.showErrorSnack("Description required")
      return
    }
    procedureCode.code = formValue["code"]
    procedureCode.description = formValue["description"]
    this.dialogRef.close(procedureCode)
  }
  cancel() {
    this.dialogRef.close()
  }

}
