import {Deserializable, deserializeAssign} from "../deserializable";

export class UserProfilePacket extends Deserializable<UserProfilePacket> {

  id?: number;
  userId?: string;
  firstName?: string;
  lastName?: string;
  selected?: boolean;
  active?: boolean;
  addressId?: number;
  userMICaseVisibilityLevel?: string;
  userGroupId?: number;

  override deserialize(input: any): UserProfilePacket {
    return deserializeAssign(this, input);
  }

  toJSON(): any {
    const obj: { [key: string]: any } = {};
    Object.assign(obj, this);
    delete obj["selected"];
    return obj;
  }
}
