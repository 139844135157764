import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { KeycloakService } from 'keycloak-angular';
import { ValueCodeDialogComponent } from '../value-code-dialog/value-code-dialog.component';
import { ValueCodeService } from '../value-code.service';
import { FormControl, FormGroup } from '@angular/forms';
import {
  BaseCrudComponent,
  CRUDDeleteStatusChangeDialogComponent,
  FennecSnackbarService,
  Logger,
  ValueCodePacket
} from "xf-common";


@Component({
  selector: 'app-value-code-list',
  templateUrl: './value-code-list.component.html',
  styleUrls: ['../../../../lib/styles/system-admin-styles.component.scss']
})
export class ValueCodeListComponent extends BaseCrudComponent<ValueCodePacket, ValueCodeService> implements AfterViewInit, OnInit {

  displayedColumns: string[] = ["code", "description", "active", "actions"];

  dialogRef?: MatDialogRef<any>;

  @ViewChild("page", { read: ElementRef })
  element?: ElementRef;

  protected log = new Logger("ValueCodeListComponent");

  formGroup: FormGroup;
  filtersOpen: boolean = false;

  constructor(
    protected override snack: FennecSnackbarService,
    protected valueCodeService: ValueCodeService,
    protected dialog: MatDialog,
    private renderer: Renderer2,
    protected keycloakService: KeycloakService
  ) {
    super(valueCodeService);
    this.canCreate = keycloakService.isUserInRole("ADMIN");
    this.canUpdate = keycloakService.isUserInRole("ADMIN");
    this.canDelete = keycloakService.isUserInRole("ADMIN");
    this.canDeactivate = keycloakService.isUserInRole("ADMIN");
    this.canView = keycloakService.isUserInRole("ADMIN");

    this.formGroup = this.createFormGroup();
  }

  ngOnInit() {
    this.executeListQuery();
  }

  ngAfterViewInit(): void {
    this.selection.changed.subscribe(change => this.selectedRow = change.added[0] ?? change.removed[0] ?? undefined);
    if (this.element?.nativeElement) {
      this.renderer.listen(this.element.nativeElement, "keyup", (event) => this.keyEvent(event));
    }
    if (this.paginator) {
      this.paginator.page.subscribe(() => {
        this.executeListQuery();
      })
    }
  }

  // executeListQuery() {
  //   const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
  //   const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;
  //   this.paginator.pageIndex = first;
  //   this.paginator.pageSize = pageSize;
  //   this.list();
  // }

  onCreate = () => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      mode: "create"
    };
    dialogConfig.autoFocus = "first-tabbable";
    this.dialogRef = this.dialog.open(ValueCodeDialogComponent, dialogConfig);
    this.dialogRef.afterClosed().subscribe((value?: ValueCodePacket) => {
      if(value){
        this.create(value).then(() => {this.executeListQuery()});
      }
    })
  }

  keyEvent(event: KeyboardEvent) {
    if (this.filtersOpen) {
      return;
    }

    const selected = this.selection?.selected;

    switch (event.key) {
      case "c":
        this.onCreate();
        break;
      case "e":
        if (this.selectedRow) {
          this.onEdit(this.selectedRow.id);
        }
        break;
      case "d":
        this.onDelete(selected);
        break;
    }
  }

  openEditDialog(valueCode: ValueCodePacket) {
    if (valueCode) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        mode: "edit",
        valueCode: valueCode
      };
      dialogConfig.autoFocus = "first-tabbable";
      this.dialogRef = this.dialog.open(ValueCodeDialogComponent, dialogConfig);
      this.dialogRef.afterClosed().subscribe({
        next: (value?: ValueCodePacket) => {
          if (value) {
            this.update(value).then(() => {this.executeListQuery()});
          }
        }
      });
    }
  }

  onSelect = (row?: ValueCodePacket) => {
    if (row == null) {
      return;
    }
    row.selected = !row.selected;

    this.selection?.toggle(row);
  }

  onEdit = (id?: number) => {
    if(!id){
      return;
    }

    this.getById(id).then(
      response => {
        if(response && response.data) {
          this.openEditDialog(response.data);
        }
      }
    ).catch(error => super.showErrorSnack(error));
  };

  onDelete = (ids: any[]) => {
    if (ids.length == 1) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        mode: "delete",
        source: ids,
        label: "Value Code"
      };
      this.dialogRef = this.dialog.open(CRUDDeleteStatusChangeDialogComponent, dialogConfig);
      this.dialogRef.afterClosed().subscribe({
        next: (value?: number[]) => {
          if (value) {
            this.performXFRequest({
              requestDescription: "Delete value code",
              requestFn: this.valueCodeService._delete,
              fnParams: [value],
              onSuccess: data => {
                super.showSuccessSnack("Successful!");
                this.executeListQuery();
              },
              onError: errString => {
                super.showErrorSnack(errString);
              }
            })
          }
        }
      });
    }
  };

  openValueCodeStatusChangeDialog(valueCode: ValueCodePacket) {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {};
    matDialogConfig.data.source = valueCode;
    matDialogConfig.data.mode = 'status';
    matDialogConfig.data.label = 'Value Code';
    const dialogRef = this.dialog.open(CRUDDeleteStatusChangeDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe({
      next: (value?: ValueCodePacket) => {
        if (value) {
          this.performXFRequest({
            requestDescription: "Change Status of State",
            requestFn: this.valueCodeService.changeStatus,
            fnParams: [value],
            onSuccess: data => {
              super.showSuccessSnack("Successful!");
              this.executeListQuery();
            },
            onError: errString => {
              super.showErrorSnack(errString);
            }
          })
        }
      }
    });
  }

  createFormGroup = () => {
    return new FormGroup({
      code: new FormControl(),
      description: new FormControl(),
      active: new FormControl(true)
    })
  }

  clearSearch = () => {
    this.formGroup = this.createFormGroup();
  }

  executeListQuery = () => {
    const controls = this.formGroup?.controls;

    const searchParams = {
      code: controls["code"].value?.trim() ?? null,
      description: controls["description"].value?.trim() ?? null,
      active: controls["active"].value,
    }

    if(searchParams.active === "all") {
      searchParams.active = null;
    }

    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;
    this.performXFRequest({
      requestDescription: "GET Value Code By Search Params",
      requestFn: this.valueCodeService.getValueCodesByParams,
      fnParams: [searchParams, first, pageSize],
      onResponse: response => {
        this.dataSource = response.data;
        this.totalRowCount = response['totalRowCount'];
      },
      onError: errString => {
        super.showErrorSnack(errString)
      }
    })
  }

  onSearch = () => {
    this.paginator?.firstPage();
    this.executeListQuery();
  }
}
