import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { BaseComponent, FennecSnackbarService, Logger } from 'xf-common';
import { EdiRunService } from '../edi-run.service';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';


@Component({
  selector: 'app-edi-micase-dialog',
  templateUrl: './edi-micase-dialog.component.html',
  styleUrls: ['./edi-micase-dialog.component.scss']
})
export class EdiMicaseDialogComponent extends BaseComponent implements OnInit {

  log: Logger = new Logger("EdiMicaseDialogComponent");

  displayColumns = ['miCaseId', 'ediRunId', 'caseType', 'createdBy', 'createdDate'];

  ediMICaseList = new MatTableDataSource<any>()

  @ViewChild(MatPaginator)
  paginator?: MatPaginator;
  totalRowCount?: number;
  defaultPageSize = 20;
  pageSizeOptions = [5, 10, 20, 25, 50];

  constructor(
    protected snack: FennecSnackbarService,
    private ediRunService: EdiRunService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EdiMicaseDialogComponent>
    ) {
      super();
    }

    ngOnInit() {
      this.getMICasesForEDIRun(this.data);
    }


  getMICasesForEDIRun(id: any) {
    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;
    this.ediRunService.getMICaseForEDIRun(id, first, pageSize).subscribe((response: any) => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.ediMICaseList.data = response.data;
        this.totalRowCount = response.totalRowCount;
      }
    })
  }

  onClose() {
    this.dialogRef.close();
  }

}
