import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent, CompanyService, HealthCarePlanPacket, FennecSnackbarService, Logger } from "xf-common";
import { HealthCarePlanService } from './health-care-plan.service';

@Component({
  selector: 'app-health-care-plan',
  templateUrl: './health-care-plan.component.html',
  styleUrls: ['./health-care-plan.component.scss']
})
export class HealthCarePlanComponent extends BaseComponent implements OnInit {
  log: Logger = new Logger("HealthCarePlanComponent");

  healthCarePlanId: number;
  healthCarePlan?: HealthCarePlanPacket;

  constructor(
    protected snack: FennecSnackbarService,
    protected activeRoute: ActivatedRoute,
    private healthCarePlanService: HealthCarePlanService
  ) {
    super();
    this.healthCarePlanId = this.activeRoute.snapshot.params["id"];
    this.getHealthCarePlan();
   }

  ngOnInit(): void {
  }

  getHealthCarePlan() {
    this.isLoading = true;
    this.performXFRequest({
      requestDescription: "GET Health Care Plan by ID",
      requestFn: this.healthCarePlanService.getInfo,
      fnParams: [this.healthCarePlanId],
      onSuccess: data => {
        this.healthCarePlan = data;
      },
      onError: errString => {
        super.showErrorSnack(errString);
      }
    });
  } 

}
