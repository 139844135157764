<mat-dialog-content class="alt-theme">
  <h1 mat-dialog-title>Adjust Mapped Revenue Code for Line Item ID: {{getDisplayIds()}}</h1>

  <div>
    <div *ngIf="formGroup.controls['mappedRevenueCode'].dirty" class="li-update-warning">
      Mapped Revenue Code will be updated!
    </div>
    <div *ngIf="formGroup.controls['description'].dirty" class="li-update-warning">
      Description will be updated!
    </div>  
    <div *ngIf="formGroup.controls['preExAdjustedAmount'].dirty" class="li-update-warning">
      Pre Existing Allowed Amount will be updated!
    </div>  
    <div *ngIf="formGroup.controls['preExExplanation'].dirty" class="li-update-warning">
      Pre Existing Explanation will be updated!
    </div>   
    <div *ngIf="formGroup.controls['billPageNo'].dirty" class="li-update-warning">
      Bill Page No will be updated!
    </div> 
    <div *ngIf="formGroup.controls['reportExclude'].dirty" class="li-update-warning">
      Report Exclude will be updated!
    </div>               
  </div>
  <form [formGroup]="formGroup">
    <div>
      <mat-form-field appearance="outline" class="li-mapped-revenue-code-input">
        <mat-label>Mapped Revenue Code</mat-label>
        <input matInput formControlName="mappedRevenueCode">
      </mat-form-field>
      <mat-form-field appearance="outline" class="li-mapped-revenue-code-description">
        <mat-label>Mapped Revenue Code Description</mat-label>
        <input matInput formControlName="mappedRevenueCodeDescription">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="outline" class="li-description">
        <mat-label>Line Item Description</mat-label>
        <input matInput formControlName="description">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="outline" class="li-pre-ex-adj-amt">
        <mat-label>Pre Ex Allowed Amt</mat-label>
        <mat-icon matPrefix>attach_money</mat-icon>
        <input type='number' matInput formControlName="preExAdjustedAmount" placeholder="Pre Ex Adj Amount">
      </mat-form-field>
      <mat-form-field appearance="outline" class="li-pre-ex-explanation">
        <mat-label>Pre Existing Explanation</mat-label>
        <input matInput formControlName="preExExplanation">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="outline" class="li-units">
        <mat-label>Bill Page No</mat-label>
        <input type='number' matInput formControlName="billPageNo" placeholder="Bill Page No">
      </mat-form-field>
      <mat-checkbox class="li-report-exclude" [formControlName]="'reportExclude'">Report Exclude</mat-checkbox>
    </div>    
  </form>
</mat-dialog-content>

<mat-dialog-actions class="fennec-controls alt-theme">
  <button 
    mat-flat-button
    (click)="save()"
    [disabled]="!formGroup.valid" 
    color="accent">
      <mat-icon>save</mat-icon> Save
  </button>
  <button mat-button mat-stroked-button (click)="onCancel()">
    <mat-icon>cancel</mat-icon> Cancel
  </button>
</mat-dialog-actions>
