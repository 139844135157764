import { AfterViewInit, Component, Input, OnInit, Renderer2 } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { KeycloakService } from 'keycloak-angular';
import {
  ApeRuleEventDefinitionCreateEditComponent
} from '../ape-rule-event-definition-create-edit/ape-rule-event-definition-create-edit.component';
import { ApeRuleEventDefinitionService } from '../ape-rule-event-definition.service';
import {
  APERuleEventDefinitionPacket,
  BaseCrudComponent,
  FennecSnackbarService,
  Logger,
  SingleChoiceDialogComponent
} from "xf-common";

@Component({
  selector: 'app-ape-rule-event-definition-list',
  templateUrl: './ape-rule-event-definition-list.component.html',
  styleUrls: ['./ape-rule-event-definition-list.component.scss', '../../../../../lib/styles/system-admin-styles.component.scss']
})
export class ApeRuleEventDefinitionListComponent extends BaseCrudComponent<APERuleEventDefinitionPacket, ApeRuleEventDefinitionService> implements OnInit, AfterViewInit {

  @Input()
  apeRuleID?: number = 0;

  displayedColumns: string[] = ["id", "name", "eventDefinitionCode", "description", "active", "actions"];

  onDelete = (ids: any[]) => {};
  onEdit = (id: number | undefined) => {};

  protected log: Logger = new Logger("ApeRuleEventDefinitionListComponent");

  formGroup: FormGroup;
  dialogRef?: MatDialogRef<any>;

  constructor(
    protected apeRuleEventDefinitionService: ApeRuleEventDefinitionService,
    public override snack: FennecSnackbarService,
    protected dialog: MatDialog,
    private renderer: Renderer2,
    protected keycloakService: KeycloakService
  ) {
    super(apeRuleEventDefinitionService);
    this.formGroup = this.createFormGroup();
   }

  ngOnInit(): void {
    this.executeListQuery();
  }

  ngAfterViewInit(): void {
    // this.selection.changed.subscribe(change => this.selectedRow = change.added[0] ?? change.removed[0] ?? undefined);
    // if (this.element?.nativeElement) {
    //   this.renderer.listen(this.element.nativeElement, "keyup", (event) => this.keyEvent(event));
    // }
    if (this.paginator) {
      this.paginator.page.subscribe(() => {
        this.executeListQuery();
      })
    }
  }

  createFormGroup = () => {
    return new FormGroup({
      name: new FormControl(),
      description: new FormControl(),
      eventDefinitionCode: new FormControl(),
      active: new FormControl("all")
    })
  }

  executeListQuery = () => {
    const controls = this.formGroup?.controls;
      if(controls == undefined) {
        return;
      }

    const searchParams = {
      apeRuleId: this.apeRuleID,
      name: controls["name"].value,
      description: controls["description"].value,
      eventDefinitionCode: controls["eventDefinitionCode"].value,
      active: controls["active"].value,
    }

    if(searchParams.active === "all") {
      searchParams.active = null;
    }

    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;
    this.paginator.pageIndex = first;
    this.paginator.pageSize = pageSize;
    this.performXFRequest({
      requestDescription: "GET APE Rule Event Definitions By Search Params",
      requestFn: this.apeRuleEventDefinitionService.getAPERuleEventDefinitions,
      fnParams: [searchParams, first, pageSize],
      onResponse: response => {
        this.dataSource = response.data;
        this.totalRowCount = response['totalRowCount'];
      },
      onError: errString => {
        super.showErrorSnack(errString)
      }
    })
  }

  clearSearch = () => {
    this.formGroup = this.createFormGroup();
    this.executeListQuery();
  }

  openDialog = (packet:APERuleEventDefinitionPacket | null) => {
    let mode = "EDIT";
    if(packet == null) {
      mode = "NEW";
    }

    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      apeRuleId: this.apeRuleID,
      mode:mode,
      eventDefinition:packet
    };
    const dialogRef = this.dialog.open(ApeRuleEventDefinitionCreateEditComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(() => {
      this.executeListQuery();
    })
  }

  openDeactivateDialog(apeRuleEventDefinition: APERuleEventDefinitionPacket) {
    if (apeRuleEventDefinition) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        question:`Are you sure you want to deactivate ${apeRuleEventDefinition.name}?`
      };
      this.dialogRef = this.dialog.open(SingleChoiceDialogComponent, dialogConfig);
      this.dialogRef.afterClosed().subscribe((result) => {
        if (result?.confirm && apeRuleEventDefinition.id) {
          this.apeRuleEventDefinitionService._delete([apeRuleEventDefinition.id]).subscribe(response => {
            if(response.hasErrors) {
              super.showErrorSnack("Error processing delete request");
            }else{
              super.showSuccessSnack("Delete successful!");
              this.executeListQuery();
            }
          })
        }
      });
    }
  }

  getDeleteIconString = (rule:any): string => {
    if(rule.active) {
      return "delete";
    }
    return "restore_from_trash";
  }

  getActiveIconStyle = (rule:any) => {
    if(rule?.active) {
      return {"color":"green"}
    }

    return {"color":"red"}
  }
}
