import { AfterViewInit, Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { FennecSnackbarService, Logger } from 'xf-common';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { registerCustomQueryHandler } from 'puppeteer';
import { MatDialogConfig } from '@angular/material/dialog';
import { StatusTrackDialogConfig, StatusTrackService } from 'projects/xf-common/src/public-api';
import { StatusTrackLogDialogConfig } from 'projects/xf-common/src/lib/model/status-track-log-dialog-config';
import { StatusTrackLogDialogComponent } from '../status-track-log-dialog/status-track-log-dialog.component';

@Component({
  selector: 'app-status-track-dialog',
  templateUrl: './status-track-dialog.component.html',
  styleUrls: ['./status-track-dialog.component.scss']
})
export class StatusTrackDialogComponent implements AfterViewInit, OnInit {

  configData: StatusTrackDialogConfig;

  dataSource = new MatTableDataSource<any>()
  displayColumns = ['statusTrackElement', 'statusTrackStatusLabel', 'createdBy', 'createdDate', 
    'lastModifiedBy', 'lastModifiedDate', 'history'
  ];  

  @ViewChild('adPaginator')
  paginator?: MatPaginator;

  totalRowCount?: number;
  defaultPageSize = 20;
  pageSizeOptions = [5, 10, 20, 25, 50];  

  constructor(
    private statusTrackService: StatusTrackService,
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<StatusTrackDialogComponent>,
    private snack: FennecSnackbarService,
    @Inject(MAT_DIALOG_DATA) private data: StatusTrackDialogConfig
  ) {
    this.configData = data;
  }

  ngOnInit(): void {
    this.getStatusTrack();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.paginator) {
        this.paginator.page.subscribe(() => {
          this.getStatusTrack();
        });
      }
    }, 200);
  }

  getStatusTrack() {
    const user = "ALL";
    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;
    const domain = this.configData.statusTrackDomain;
    const relatedId = this.configData.relatedId;
    this.statusTrackService.getStatusTrackListForDomainRelatedId(domain, relatedId).subscribe((response: any) => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.dataSource.data = response.data;
        this.totalRowCount = response.totalRowCount;
      }
    });
  }

  openStatusTrackLogDialog(statusTrackElement: string, id: any) {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "80vw";

    const stdConfig = new StatusTrackLogDialogConfig();
    stdConfig.statusTrackDomain = this.configData.statusTrackDomain;
    stdConfig.statusTrackElement = statusTrackElement;
    stdConfig.statusTrackRelatedId = this.configData.relatedId;
    stdConfig.statusTrackId = parseInt(id);
    matDialogConfig.data = stdConfig;
    const dialogRef = this.dialog.open(StatusTrackLogDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.confirm) {
      }
    });
  }

  close() {
    const retObj = {
      confirm: false
    }
    this.dialogRef.close(retObj);
  }

}
