<div matDialogTitle>
  {{mode}} Holiday
</div>
<div matDialogContent>
  <form [formGroup]="formGroup" class="alt-theme">
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput formControlName="name">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Date</mat-label>
      <input matInput formControlName="date" maxlength="10" placeholder="MM/DD/YYYY">
    </mat-form-field>
  </form>
</div>
<div matDialogActions  class="fennec-dialog-action-button-container">
  <button mat-flat-button color="primary" (click)="close()">
    Cancel
  </button>
  <button mat-flat-button color="accent" [disabled]="formGroup.invalid" (click)="close(true)">
    Save
  </button>
</div>
