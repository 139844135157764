import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { UserProfileService } from '@app/user/user-profile.service';
import {
  BaseComponent,
  CommentQueryParamsPacket,
  FennecSnackbarService,
  Logger,
  SingleChoiceDialogComponent,
  UserProfilePacket
} from 'xf-common';
import { AddCommentComponent } from '../add-comment/add-comment.component';
import { CommentService } from '../comment.service';
import { ActivatedRoute } from "@angular/router";
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-comment-list',
  templateUrl: './comment-list.component.html',
  styleUrls: ['./comment-list.component.scss']
})
export class CommentListComponent extends BaseComponent implements OnInit, AfterViewInit {

  protected log: Logger = new Logger("CommentListComponent");

  @Input()
  mode: string = "MI_CASE";

  @Input()
  relatedId: string = "-1";

  // Possible contexts: "FLAT" && "DIALOG"
  @Input()
  displayContext: string = "FLAT";

  commentList: any[] = [];

  @ViewChild("add")
  addCommentComponent?: AddCommentComponent;

  addNewActive = false;
  userProfile?: UserProfilePacket;

  public readonly defaultPage = 0;
  public readonly defaultPageSize = 10;
  public readonly defaultPageSizeOptions = [10, 25, 50];
  public totalRowCount = 0;

  @ViewChild(MatPaginator)
  paginator?: MatPaginator;

  dialogPaginator: MatPaginator | null = null;

  // ids For Itemized Line Item Comments
  @Input()
  lineItemIds:[] = [];

  adminDelete: boolean = false;

  constructor(
    override snack: FennecSnackbarService,
    protected commentService: CommentService,
    protected userProfileService: UserProfileService,
    protected keycloakService: KeycloakService,
    protected dialog: MatDialog,
    private route: ActivatedRoute
  ) {
    super();
    if (this.route.parent) {
      this.relatedId = this.route.parent?.snapshot.paramMap.get("miCaseId") ?? "-1";
    }

    const roles = keycloakService.getUserRoles();
    this.adminDelete = roles.includes("ADMIN_COMMENT_DELETE");
   }

  ngOnInit(): void {
    this.getComments();
    this.getUserProfile();
  }

  ngAfterViewInit(): void {
    if(this.addCommentComponent) {
      this.addCommentComponent?.saveCommentComplete?.subscribe(() => {
        this.addNewActive = false;
        this.getComments();
      })

      this.addCommentComponent.cancelComment?.subscribe(() => {
        this.addNewActive = false;
      })
    }

    if(this.dialogPaginator) {
      this.dialogPaginator.page.subscribe(() => {
        this.getComments();
      })
    }

    if (this.paginator) {
      this.paginator.page.subscribe(() => {
        this.getComments();
      })
    }
  }

  getComments = () => {

    let paginator = this.paginator;
    if(this.dialogPaginator) {
      paginator = this.dialogPaginator;
    }

    if(this.relatedId == "-1") {
      super.showErrorSnack("No ID Provided");
      return;
    }

    const id = parseInt(this.relatedId);
    let packet = new CommentQueryParamsPacket();
    packet.relatedId = id;
    packet.commentRelationalType = this.mode;


    if(this.mode === "ITEMIZED_LINE_ITEM") {
      packet.lineItemIds = this.lineItemIds;
    }

    const pageSize = !paginator?.pageSize ? this.defaultPageSize : paginator.pageSize;
    const first = paginator?.pageIndex ? paginator.pageIndex * pageSize : 0;

    this.performXFRequest({
      requestDescription: "Get Comments",
      requestFn: this.commentService.getComments,
      fnParams: [packet, first, pageSize],
      onComplete: () => {
        this.isLoading = false;
      },
      onResponse: response => {
        this.commentList = response.data;
        this.totalRowCount = response['totalRowCount'];
      },
      onError: (error) => {
        super.showErrorSnack(error);
      }
    })
  }

  getUserProfile = () => {
    this.performXFRequest({
      requestDescription: "GET User Profile",
      requestFn: this.userProfileService.getUserProfile,
      fnParams: [],
      onSuccess: (data) => {
        this.userProfile = data;
      },
      onError: (error) => {
        super.showErrorSnack(error);
      }
    })
  }

  showAddNew = () => {
    this.addNewActive = true;
    if(this.addCommentComponent) {
      this.addCommentComponent.clearFormGroup();
    }
  }

  getFormattedUsername = (): String => {
    if(!this.userProfile) {
      return "";
    }

    return `${this.userProfile.firstName ?? ""} ${this.userProfile.lastName ?? ""}`
  }

  openCommentDialog = (comment = null) => {
    let mode = "CREATE";
    if(comment !== null) {
      mode = "EDIT";
    }

    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "65vw";
    matDialogConfig.data = {
      mode,
      comment,
      relationalType: this.mode,
      relatedId: this.relatedId,
      formattedUsername: this.getFormattedUsername()
    };

    const dialogRef = this.dialog.open(AddCommentComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(() => {
      this.getComments();
    })
  }

  deleteComment = (comment:any) => {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {
      question: `Are you sure you want to delete this comment?`,
      infoLine1: `"${comment.commentText}"`,
      buttonMode: "YES/NO"
    };

    const dialogRef = this.dialog.open(SingleChoiceDialogComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if(result.confirm) {
        this.performXFRequest({
          requestDescription: "DELETE Comment",
          requestFn: this.commentService.deleteComment,
          fnParams: [comment.id],
          onSuccess: (data) => {
            super.showSuccessSnack("Comment successfully deleted");
            this.getComments();
          },
          onError: (error) => {
            super.showErrorSnack(error);
          }
        })
      }
      this.getComments();
    })
  }
}
