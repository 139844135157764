<div class="company-relational-type-dialog-wrapper-div">
<div matDialogTitle>
  Create Company Relationship
  <mat-icon>apartment</mat-icon>
  <div class="sub-title-div">
  {{companyRelationalType}} / {{companyType}}
  </div>
</div>
<div matDialogContent>
  <form [formGroup]="formGroup" class="container alt-theme">
    <mat-form-field appearance="outline">
      <mat-label>Search Company Name</mat-label>
      <input matInput formControlName="searchCompanyName">
    </mat-form-field>
    <button mat-icon-button class="search-button" (click)="getData()"><mat-icon>search</mat-icon></button>
  </form>
  <mat-table [dataSource]="companyList">

    <ng-container matColumnDef="select">
      <mat-header-cell *matHeaderCellDef> Select </mat-header-cell>
      <mat-cell *matCellDef="let c">
        {{selection.isSelected(c) ? "Selected" : "Select"}}
<!--        <button mat-stroked-button (click)="selectRow(c)" [disabled]="c.companyId ? selectedCompanyId == c.companyId : selectedCompanyId == c.id">{{c.companyId ? selectedCompanyId == c.companyId ? "Selected" : "Select" : selectedCompanyId == c.id ? "Selected" : "Select"}}</button>-->
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef> Id </mat-header-cell>
      <mat-cell *matCellDef="let c">
        {{c.id}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef> Company Name </mat-header-cell>
      <mat-cell *matCellDef="let c">
          {{c.name}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="address">
      <mat-header-cell *matHeaderCellDef> Address </mat-header-cell>
      <mat-cell *matCellDef="let c"> {{c.address}} </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"
      [ngClass]="{ hovered: row.hovered, highlighted: selection.isSelected(row) }"
      (click)="onSelect(row)"
    >
    </mat-row>
  </mat-table>

</div>

<div matDialogActions class="fennec-controls">
  <mat-paginator [length]="totalRowCount" [pageSize]="defaultPageSize" [pageSizeOptions]="pageSizeOptions"></mat-paginator>
  <button mat-flat-button color="accent" [disabled]="selection.isEmpty()" (click)="onSubmit()">
    Add Company Relationships
  </button>
  <button mat-flat-button color="primary" (click)="cancel()">
    Cancel
  </button>
</div>
</div>
