import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { BaseComponent, FennecSnackbarService, Logger } from 'xf-common';
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { EdiRunService } from '../edi-run.service';
import { AttachmentComponent } from '@app/attachment/attachment.component';

@Component({
  selector: 'app-edi-run-create-provider',
  templateUrl: './edi-run-create.component.html',
  styleUrls: ['./edi-run-create.component.scss']
})
export class EdiRunCreateComponent extends BaseComponent implements OnInit, AfterViewInit {
  log: Logger = new Logger("EdiRunCreateComponent");

  miCaseId = -1;

  @ViewChild('attachmentComponent')
  attachmentComponent?: AttachmentComponent;

  constructor(
    protected dialogRef: MatDialogRef<EdiRunCreateComponent>,
    protected ediRunService: EdiRunService,
    protected snack: FennecSnackbarService
  ) {
    super();
  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.attachmentComponent?.attachmentSaveComplete.subscribe((e:any) => {
      let returnObj = {
        confirm: true
      };
      this.dialogRef.close(returnObj);
    });
  }

  cancel() {
    let returnObj = {
      confirm: false
    };
    this.dialogRef.close(returnObj);
  }

}
