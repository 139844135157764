import { APERuleFilter } from "./APERuleFilter";
import { CompanyPacket } from "./company-packet";

import { Deserializable, deserializeAssign } from "../deserializable";

export class APERuleFilterPrimaryClientPacket implements Deserializable<APERuleFilterPrimaryClientPacket>  {
  id?:number;
  apeRuleFilter?: APERuleFilter;
  primaryClient?: CompanyPacket;

  deserialize(input: any): APERuleFilterPrimaryClientPacket {
    return deserializeAssign(this, input);
  }

  toJSON(): any {
    const obj: { [key: string]: any } = {};
    Object.assign(obj, this);
    delete obj["selected"];
    return obj;
  }
}