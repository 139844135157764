import { Deserializable, deserializeAssign } from "../deserializable";

export class APERuleFilter implements Deserializable<APERuleFilter> {

  id?: number;
  name?: string;
  description?: string;
  apeRuleFilterDomain?: string;
  apeRuleFilterDevCode?: string;
  active?:boolean;

  deserialize(input: any): APERuleFilter {
    return deserializeAssign(this, input);
  }

  toJSON(): any {
    const obj: { [key: string]: any } = {};
    Object.assign(obj, this);
    delete obj["selected"];
    return obj;
  }
}