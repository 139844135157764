import { AfterViewInit, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { UserProfileService } from "../user-profile.service";
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { UserDialogComponent } from "@app/user-dialog/user-dialog.component";
import { KeycloakService } from "keycloak-angular";
import {
  BaseCrudComponent,
  FennecSnackbarService,
  Logger, SimpleObject,
  SingleChoiceDialogComponent, UserGroupQueryPacket,
  UserProfilePacket
} from 'xf-common';
import { FormControl, FormGroup } from '@angular/forms';
import {UserGroupService} from "@app/admin/user-group.service";
import { UserSearchDialogComponent } from '../user-search-dialog/user-search-dialog.component';


@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss', '../../../lib/styles/system-admin-styles.component.scss']
})
export class UserListComponent extends BaseCrudComponent<UserProfilePacket, UserProfileService> implements AfterViewInit {
  log = new Logger("UserListComponent");

  displayedColumns = ["userId", "name", "userGroup", "actions"];

  dialogRef?: MatDialogRef<UserDialogComponent>;
  dialogRefSingleChoice?: MatDialogRef<SingleChoiceDialogComponent>;
  dialogRefSearchUser?: MatDialogRef<UserSearchDialogComponent>;

  @ViewChild("page", { read: ElementRef })
  element?: ElementRef;

  selectedUserProfile: UserProfilePacket | null = null;

  formGroup: FormGroup;

  userGroups: SimpleObject = {};
  userGroupOptions: UserGroupQueryPacket[] = [];

  constructor(
    protected snack: FennecSnackbarService,
    protected userProfileService: UserProfileService,
    protected dialog: MatDialog,
    private renderer: Renderer2,
    protected keycloakService: KeycloakService,
    protected userGroupService: UserGroupService
  ) {
    super(userProfileService);
    const isAdmin = keycloakService.isUserInRole("ADMIN");
    this.canCreate = isAdmin;
    this.canUpdate = isAdmin;
    this.canDelete = isAdmin;
    this.canDeactivate = isAdmin;
    this.canView = isAdmin;

    this.formGroup = this.createFormGroup();
  }

  createFormGroup = () => {
    return new FormGroup({
      firstName: new FormControl(),
      lastName: new FormControl(),
      userId: new FormControl(),
      active: new FormControl(true)
    })
  }

  ngAfterViewInit(): void {
    this.performXFRequest({
      requestDescription: "Get user groups",
      requestFn: this.userGroupService._list,
      fnParams: [0, 100],
      onSuccess: data => {
        this.userGroupOptions = data;
        for (let userGroup of this.userGroupOptions) {
          this.userGroups[userGroup.id] = userGroup;
        }
      },
      onError: err => super.showErrorSnack(err)
    });

    this.selection.changed.subscribe(change => this.selectedRow = change.added[0] ?? change.removed[0] ?? undefined);
    if (this.element?.nativeElement) {
      this.renderer.listen(this.element.nativeElement, "keyup", (event) => this.keyEvent(event));
    }
    if (this.paginator) {
      this.paginator.page.subscribe(() => {
        this.executeListQuery();
      })
    }
    this.executeListQuery();
  }

  onCreate(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      mode: "create"
    };
    dialogConfig.autoFocus = "first-tabbable";
    this.dialogRef = this.dialog.open(UserDialogComponent, dialogConfig);
    this.dialogRef.afterClosed().subscribe((value?: UserProfilePacket) => {
      if (value) {
        this.create(value);
      }
    });
  }

  onEdit = (id?: number) => {
    if (!id) {
      return;
    }
    this.getById(id)
      .then(response => {
        if (response && response.data) {
          this.openEditDialog(response.data)
        }
      })
      .catch(error => super.showErrorSnack(error));
  }

  onDelete = (ids?: any[]) => {
    if (ids?.length === 1) {
      let userProfile: any = null;
      let idx = 0;
      this.dataSource.data.forEach((up) => {
        if (up.id === ids[0]) {
          userProfile = up;
        }
      });
      if (userProfile === null) {
        return;
      }

      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        question:`Are you sure you want to delete/deactivate ${userProfile.firstName} ${userProfile.lastName}?`
      };
      this.dialogRefSingleChoice = this.dialog.open(SingleChoiceDialogComponent, dialogConfig);
      this.dialogRefSingleChoice.afterClosed().subscribe((result) => {
        if (result?.confirm) {
          let idArray: number [] = [];
          idArray.push(ids[0]);
          this.deactivate(idArray);
        }
      });
    }
  }

  onSelect(row: UserProfilePacket | undefined) {
    if (row == null) {
      return;
    }
    row.selected = !row.selected;

    this.selection?.toggle(row);
  }

  keyEvent(event: KeyboardEvent) {
    const selected = this.selection?.selected;

    switch (event.key) {
      case "c":
        this.onCreate();
        break;
      case "e":
        if (this.selectedRow) {
          this.onEdit(this.selectedRow.id);
        }
        break;
      case "d":
        this.onDelete(selected?.map(v => v.id));
        break;
    }
  }

  openEditDialog(user: UserProfilePacket) {
    if (user) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        mode: "edit",
        user: user
      };
      dialogConfig.autoFocus = "first-tabbable";
      this.dialogRef = this.dialog.open(UserDialogComponent, dialogConfig);
      this.dialogRef.afterClosed().subscribe({
        next: (value?: UserProfilePacket) => {
          if (value) {
            this.update(value);
          }
        }
      });
    }
  }

  openUserManagement = (user: UserProfilePacket) => {
    if (user) {
      this.selectedUserProfile = user;
    }
  }

  openCopyClonePermissions = (user: UserProfilePacket) => {
    if (user) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = {
        mode: "edit",
        user: user,
        title: "Copy/Clone Case Visibility Permissions",
        subtitle: "To: " + user.firstName + " " + user.lastName
      };
      dialogConfig.autoFocus = "first-tabbable";
      this.dialogRefSearchUser = this.dialog.open(UserSearchDialogComponent, dialogConfig);
      this.dialogRefSearchUser.afterClosed().subscribe(u => {
        if (u.confirm) {
          this.copyCloneUserCaseVisibilityPermissions(u.user.id, user.id);
        }
      });
    }
  }  

  copyCloneUserCaseVisibilityPermissions(fromId: number, toId: number) {
    this.userProfileService.copyCloneMICaseVisibility(fromId, toId).subscribe((response: any) => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.snack.showSuccessSnack("Case visibility permissions copied/cloned!");
      }
    });
  }

  closeUserManagement = () => {
    this.selectedUserProfile = null;
    this.executeListQuery();
  }

  clearSearch = () => {
    this.formGroup = this.createFormGroup();
  }

  executeListQuery = () => {
    const controls = this.formGroup?.controls;

    const searchParams = {
      firstName: controls["firstName"].value,
      lastName: controls["lastName"].value,
      userId: controls["userId"].value,
      active: controls["active"].value,
    }

    if(searchParams.active === "all") {
      searchParams.active = null;
    }

    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;
    this.performXFRequest({
      requestDescription: "GET User Profile By Search Params",
      requestFn: this.userProfileService.getUserListByParams,
      fnParams: [searchParams, first, pageSize],
      onResponse: response => {
        this.dataSource.data = response.data;
        this.totalRowCount = response['totalRowCount'];
      },
      onError: errString => {
        super.showErrorSnack(errString)
      }
    })
  }

  onSearch = () => {
    this.paginator?.firstPage();
    this.executeListQuery();
  }
}
