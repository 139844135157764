import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { BaseComponent, FennecSnackbarService, Logger } from 'xf-common';
import { EdiRunService } from '../edi-run.service';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivityDiaryListComponent } from '@app/activity-diary/activity-diary-list/activity-diary-list.component';


@Component({
  selector: 'app-edi-activity-diary-dialog',
  templateUrl: './edi-activity-diary-dialog.component.html',
  styleUrls: ['./edi-activity-diary-dialog.component.scss', '../../../styles.scss']
})
export class EdiActivityDiaryDialogComponent extends BaseComponent implements OnInit {

  log: Logger = new Logger("EdiActivityDiaryDialogComponent");

  displayColumns = ['activityDiaryId', 'ediRunId', 'createdBy', 'createdDate', 'activityDiaryDomain'];

  diariesList = new MatTableDataSource<any>()

  @ViewChild(MatPaginator)
  paginator?: MatPaginator;
  totalRowCount?: number;
  defaultPageSize = 20;
  pageSizeOptions = [5, 10, 20, 25, 50];

  constructor(
    protected snack: FennecSnackbarService,
    private ediRunService: EdiRunService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EdiActivityDiaryDialogComponent>,
    public matDialog: MatDialog
    ) {
      super();
    }

    ngOnInit() {
      this.getDiariesForEDIRun(this.data);
    }


  getDiariesForEDIRun(id: any) {
    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;
    this.ediRunService.getActivityDiariesForEDIRun(id, first, pageSize).subscribe((response: any) => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.diariesList.data = response.data;
        this.totalRowCount = response.totalRowCount;
      }
    })
  }

  onClose() {
    this.dialogRef.close();
  }

  openDiaryDialog(diaryId: any) {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.panelClass = "dialog-responsive";
    matDialogConfig.height = "auto";
    matDialogConfig.width = "1000px";
    matDialogConfig.data = diaryId;
    const dialogRef = this.matDialog.open(ActivityDiaryListComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(res => {
      this.data = null;
      dialogRef.close();
    })

  }

}
