import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EMPTY, Observable } from "rxjs";
import { APERule, BaseCrudService, Crud, DataResponse, PagedResponse } from "xf-common";

@Injectable()
export class ApeRuleService extends BaseCrudService<APERule> {
  public baseEndpoint = super.apiEndpoint("ape-rule");

  constructor(
    public override httpClient: HttpClient
  ) {
    super(httpClient, "APERuleService", "APERule");
   }

   _list: Crud.ListFunction<APERule> = (first, max) => {
     const endpoint = `${this.baseEndpoint}/list`;
     return this.httpClient.get<PagedResponse<APERule[]>>(endpoint, { params: { first, max} })
    };

    _getById: Crud.GetByIdFunction<APERule> = () => {
      return EMPTY;
    };

    _create: Crud.CreateFunction<APERule> = (payload) => {
      const endpoint = `${this.baseEndpoint}/create-update`;
      return this.httpClient.post<DataResponse<APERule>>(endpoint, payload);
      // return EMPTY;
    };
    _update: Crud.UpdateFunction<APERule> = (payload) => {
      const endpoint = `${this.baseEndpoint}/create-update`;
      // return this.httpClient.post<DataResponse<APERule>>(endpoint, payload);
      return EMPTY;
    };

    _deactivate: Crud.DeleteFunction<APERule> = (id) => {
      const endpoint = `${this.baseEndpoint}/${id}`;
      return this.httpClient.delete<DataResponse<boolean>>(endpoint);
    };

    _delete: Crud.DeleteFunction<APERule> = (id) => {
      const endpoint = `${this.baseEndpoint}/delete`;
      // return this.httpClient.put<DataResponse<boolean>>(endpoint, id);
      return EMPTY;
    };

    protected _crudFunctions: Crud.CrudServiceDef<APERule> = {
      _list: this._list,
      _getById: this._getById,
      _create: this._create,
      _update: this._update,
      _deactivate: this._deactivate,
      _delete: this._delete
    };

    deactivate = (id: number) => {
      const endpoint = `${this.baseEndpoint}/delete`;
      // return this.httpClient.put<DataResponse<boolean>>(endpoint, id);
      return EMPTY;
    }

    getAPERules = (queryParams: {}, first?: number, max?: number): Observable<PagedResponse<any>> => {
      return this.httpClient.put<PagedResponse<any>>(this.baseEndpoint + "/list?first=" + first + "&max=" + max, queryParams)
    }

    getAPERuleTypes = () => {
      return this.httpClient.get<DataResponse<any>>(this.baseEndpoint + "/types");
    }
}
