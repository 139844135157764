import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseResponse, EnvironmentService, ItemizedLineItemAdjustmentPacket, ItemizedLineItemAppealAdjustmentPacket, PagedResponse } from "xf-common";

@Injectable({
  providedIn: 'root'
})
export class ItemizedLineItemService {

  constructor(
    private httpClient: HttpClient,
    private environment: EnvironmentService
  ) { }

  getItemizedLineItemQuery = (
    itemizedRevisionId: number,
    filterNo: number = 0,
    sortOrder: number = 0,
    description: string,
    serviceDateString: string = "ALL",
    revenueCodeStrings: string [] = [],
    mappedRevenueCodeStrings: string [] = [],
    reasonCodeFilter: string [] = [],
    explanationCodeFilter: string [] = [],
    adjusted: string = "ALL",
    apeAdjusted: string = "ALL",
    comments: string = "NA",
    reportExcludeString: string = "ALL",
    first: number = 0,
    max: number = 100
  ): Observable<PagedResponse<any>> => {
    let url = this.environment.baseApiUrl + "itemized-line-item/query/" + itemizedRevisionId.toString();
    url += "?first=" + first.toString();
    url += "&max=" + max.toString();

    // if (description !== null && description !== undefined) {
    //   description = encodeURIComponent(description);
    // }
    let reportExclude = null;
    if (reportExcludeString.toLowerCase() === "true") {
      reportExclude = true;
    } else if (reportExcludeString.toLowerCase() === "false") {
      reportExclude = false;
    }

    const filterParams = {
      filterNo: filterNo,
      sortOrder: sortOrder,
      description: description,
      serviceDateString: serviceDateString,
      revenueCodeStrings: revenueCodeStrings,
      mappedRevenueCodeStrings: mappedRevenueCodeStrings,
      reasonCodeFilter: reasonCodeFilter,
      explanationCodeFilter: explanationCodeFilter,
      adjusted: adjusted,
      apeAdjusted: apeAdjusted,
      comments: comments,
      reportExclude: reportExclude
    }

    return this.httpClient.put<any>(url, filterParams);
  }

  putItemizedLineItemAdjustment(payload: ItemizedLineItemAdjustmentPacket): Observable<BaseResponse> {
    return this.httpClient.put<any>(this.environment.baseApiUrl + "itemized-line-item/adjustment", payload);
  }

  putItemizedLineItemAppealAdjustment(payload: ItemizedLineItemAppealAdjustmentPacket): Observable<BaseResponse> {
    return this.httpClient.put<any>(this.environment.baseApiUrl + "itemized-line-item/appeal-adjustment", payload);
  }

  putItemizedLineItemPristine(payload: number []): Observable<BaseResponse> {
    return this.httpClient.put<any>(this.environment.baseApiUrl + "itemized-line-item/pristine", payload);
  }

  getApeRuleEventDiaryForItemizedLineItemUniqueRefId(uniqueRefId: number): Observable<BaseResponse> {
    return this.httpClient.get<any>(this.environment.baseApiUrl + "ape-rule-event-diary/list/" + uniqueRefId);
  }

  putItemizedLineItemMappedRevenueCode = (payload:any): Observable<any> => {
    return this.httpClient.put<any>(this.environment.baseApiUrl + "itemized-line-item/mapped-revenue-code", payload);
  }

  getAllAppealDecisionCodes(): Observable<BaseResponse> {
    return this.httpClient.get<any>(this.environment.baseApiUrl + "itemized-line-item/appeal-decision-codes");
  }

}
