<mat-dialog-content>
  <h1 mat-dialog-title>Create New Case</h1>
  <div class="mi-case-create-form">
    <form [formGroup]="formGroup" class="container alt-theme">

      <div class="mi-case-create-form-row1">
        <mat-form-field appearance="outline" style="width: 33%;">
          <mat-label>Type</mat-label>
          <mat-select formControlName="type">
            <mat-option *ngFor="let t of typeList" [value]="t.value">
              {{ t.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="userPrimaryClientList.length > 0" appearance="outline"
                        class="mi-case-create-client-select" style="width: 33%;">
          <mat-label>Client</mat-label>
          <mat-select formControlName="clientCompanyId">
            <mat-option *ngFor="let pc of userPrimaryClientList" [value]="pc.companyId">
              {{ pc.companyName }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="userHealthCarePlanList.length > 0" appearance="outline"
                        class="mi-case-create-client-select" style="width: 33%;">
          <mat-label>Plan</mat-label>
          <mat-select formControlName="healthCarePlanId">
            <mat-option *ngFor="let plan of userHealthCarePlanList" [value]="plan.healthCarePlanId">
              {{ plan.healthCarePlanName }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div *ngIf="userHealthCarePlanList.length == 0" class="no-data-msg">
          <h4>No Health Care Plans Available For Selected Client</h4>
        </div>
      </div>

      <div style="display:flex; flex-direction: column;">
        <mat-form-field class="name-field" appearance="outline">
          <mat-label>Patient Name</mat-label>
          <input matInput formControlName="name">
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Client Claim ID</mat-label>
          <input matInput formControlName="clientClaimId">
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Client Member Number</mat-label>
          <input class="input" matInput formControlName="clientMemberNumber" placeholder="Client Member Number"
                 [value]="formGroup.get('clientMemberNumber')?.value">
        </mat-form-field>


        <mat-form-field appearance="outline">
          <mat-label>External ID</mat-label>
          <input matInput formControlName="altExternalId">
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Insured Group Name</mat-label>
          <input matInput formControlName="insuredGroupName">
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Insured Group Number</mat-label>
          <input matInput formControlName="insuredGroupNo">
        </mat-form-field>
      </div>


      <div style="display:flex; gap: 5px;">
        <!-- <mat-form-field appearance="outline" style="width:50%">
          <mat-label>Line of Business</mat-label>
          <input matInput formControlName="lineOfBusiness">
        </mat-form-field>  -->

        <mat-form-field *ngIf="providerParOptionsList.length > 0" appearance="outline"
                        class="mi-case-create-client-select" style="width:50%">
          <mat-label>Line of Business</mat-label>
          <mat-select formControlName="lineOfBusiness">
            <mat-option *ngFor="let lob of lineOfBusinessList" [value]="lob.value">
              {{ lob.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="providerParOptionsList.length > 0" appearance="outline"
                        class="mi-case-create-client-select" style="width:50%">
          <mat-label>Provider Par</mat-label>
          <mat-select formControlName="providerPar">
            <mat-option *ngFor="let pp of providerParOptionsList" [value]="pp">
              {{ pp }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div style="display:flex; gap: 5px;">
        <mat-form-field class="mi-case-create-form-row2">
          <mat-label>Pre 6DH Review Payable</mat-label>
          <input matInput type="number" formControlName="preReviewPayable" (wheel)="$event.preventDefault()">
          <mat-icon matPrefix>attach_money</mat-icon>
        </mat-form-field>

        <!-- Removed - Jira Task: FEN-1322 
        <mat-form-field class="mi-case-create-form-row2">
          <mat-label>Post 6DH Review Payable</mat-label>
          <input matInput type="number" formControlName="postReviewPayable" (wheel)="$event.preventDefault()">
          <mat-icon matPrefix>attach_money</mat-icon>
        </mat-form-field> -->

        <mat-form-field class="drg-paid-date">
          <mat-label>DRG Paid Date</mat-label>
          <input matInput placeholder="(MM/DD/YYYY)" formControlName="drgPaidDate">
          <mat-error *ngIf="formGroup.controls['drgPaidDate'].errors != null">
            Please enter a date in MM/DD/YYYY format
          </mat-error>
        </mat-form-field>

        <mat-form-field class="mi-case-create-form-row2">
          <mat-label>DRG Payment Amount</mat-label>
          <input matInput type="number" formControlName="drgPaymentAmount" (wheel)="$event.preventDefault()">
          <mat-icon matPrefix>attach_money</mat-icon>
        </mat-form-field>

        <mat-form-field class="mi-case-create-form-row2">
          <mat-label>Network Discount</mat-label>
          <input matInput type="number" formControlName="networkDiscount" (wheel)="$event.preventDefault()">
          <mat-icon matSuffix>percent</mat-icon>
        </mat-form-field>
      </div>

      <mat-form-field *ngIf="customerPriorityList.length > 0" appearance="outline" class="mi-case-create-client-select"
                      style="width:100%">
        <mat-label>Customer Priority</mat-label>
        <mat-select formControlName="customerPriority">
          <mat-option *ngFor="let cp of customerPriorityList" [value]="cp.value">
            {{ cp.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="client-internal-adjustments">
        <mat-label>Client Internal Adjustments</mat-label>
        <input matInput type="number" formControlName="clientInternalAdjustments" (wheel)="$event.preventDefault()">
        <mat-icon matPrefix>attach_money</mat-icon>
      </mat-form-field>

      <mat-form-field >
        <mat-label>Post Payment Review</mat-label>
        <input matInput disabled>
        <mat-checkbox formControlName="postPaymentReview" matSuffix></mat-checkbox>
      </mat-form-field>

      <mat-form-field style="width:100%" appearance="outline">
        <mat-label>Notes</mat-label>
        <textarea matInput formControlName="notes" rows="5" cdkTextareaAutosize></textarea>
      </mat-form-field>
    </form>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="fennec-controls">
  <button mat-flat-button (click)="onSubmit()" [disabled]="!formGroup.valid" color="accent" [attr.data-action]="'submit'">
    <mat-icon>save</mat-icon>
    Create
  </button>

  <button mat-button mat-stroked-button (click)="onCancel()" [attr.data-action]="'cancel'">
    <mat-icon>cancel</mat-icon>
    Cancel
  </button>
</mat-dialog-actions>

