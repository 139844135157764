<h2>General Info</h2>

<form [formGroup]="formGroup" class="form alt-theme">
  <mat-form-field appearance="outline">
    <mat-label>First Name</mat-label>
    <input matInput formControlName="firstName">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Last Name</mat-label>
    <input matInput formControlName="lastName">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>User / Case Visibility Level</mat-label>
    <mat-select formControlName="userMICaseVisibilityLevel">
      <mat-option *ngFor="let uvl of userMICaseVisibilityLevels" [value]="uvl">
        {{ uvl }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>User Group</mat-label>
    <mat-select formControlName="userGroup">
      <mat-option [value]="-1">None</mat-option>
      @for (option of userGroupOptions; track option.id) {
        <mat-option [value]="option.id">{{option.name}}</mat-option>
      }
    </mat-select>
  </mat-form-field>

</form>

<div class="save-button">
  <button
    mat-raised-button
    color="accent"
    (click)="saveUserProfile()"
  >
    <mat-icon>save</mat-icon> Save
  </button>
</div>
