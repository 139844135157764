import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { BaseComponent } from '../../view/base.component';
import { FennecSnackbarService, SingleChoiceDialogComponent } from 'projects/xf-common/src/public-api';
import { Logger } from '../../util/logger';
import { ContactServiceService } from '../contact-service.service';
import { FormControl, FormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ContactDialogComponent } from '../contact-dialog/contact-dialog.component';

@Component({
  selector: 'lib-contact-list',
  templateUrl: './contact-list.component.html',
  styleUrl: './contact-list.component.scss'
})
export class ContactListComponent extends BaseComponent implements AfterViewInit {
  protected override log: Logger = new Logger("ContactListComponent");

  @Input()
  context: "PROVIDER_IB_REQUEST_INFO" = "PROVIDER_IB_REQUEST_INFO";

  formGroup:FormGroup;

  @Input()
  relatedId: number = -1;

  contactList = [];

  totalRowCount = 0;
  defaultPageSize = 15;
  pageSizeOptions = [10, 15, 20];

  @ViewChild("paginator")
  paginator;

  displayColumns = ["name", "phoneNumber", "emailAddress", "faxNumber", "contactType", "actions"];
  
  constructor(
    protected override snack: FennecSnackbarService,
    protected contactService: ContactServiceService,
    protected dialog: MatDialog,
  ) {
    super();

    this.formGroup = new FormGroup({
      name: new FormControl(),
      phoneNumber: new FormControl(),
      faxNumber: new FormControl(),
      emailAddress: new FormControl()
    });
  }

  ngAfterViewInit(): void {
    this.getData();
  }

  getData = () => {
    const controls = this.formGroup.controls;
    const packet = {
      contact: {
        name: controls["name"].value ?? "",
        phoneNumber: controls["phoneNumber"].value ?? "", 
        faxNumber: controls["faxNumber"].value ?? "",
        emailAddress: controls["emailAddress"].value ?? "",
      },
      relatedId: this.relatedId,
      contactRelationalType: this.context
    }

    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;

    this.performXFRequest({
      requestDescription: "Get Contacts by Params",
      requestFn: this.contactService.getContactsByRelationalMapPacket,
      fnParams: [packet, first, pageSize],
      onSuccess: data => {
        this.contactList = data
      },
      onResponse: response => {
        this.totalRowCount = response["totalRowCount"];
      },
      onError: errString => {
        super.showErrorSnack(errString);
      }
    })
  }

  openDialog = (crm) => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      context: this.context,
      relatedId: this.relatedId,
      contactRelationalMap: crm
    }
    const dialogRef = this.dialog.open(ContactDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result.confirm) {
        this.getData();
      }
    })
  }

  delete = (crm) => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      question:`Are you sure you want to delete ${crm?.contact?.name ?? "this contact"}?`
    }
    const dialogRef = this.dialog.open(SingleChoiceDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {
      if (result.confirm) {
        this.performXFRequest({
          requestDescription: "Delete Contact and Relational Map",
          requestFn: this.contactService.deleteContactAndMap,
          fnParams: [crm.id],
          onSuccess: data => {
            this.getData();
          },
          onError: errString => {
            super.showErrorSnack(errString);
          }
        })
      }
    })
  }
}
