import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import {
  BaseComponent, DataResponse,
  FennecSnackbarService,
  HealthCarePlanPacket,
  Logger,
  PagedResponse,
  UserProfilePacket
} from 'xf-common';
import {HealthCarePlanService} from '../health-care-plan.service';
import {FormControl, FormGroup} from '@angular/forms';
import {MatLegacyPaginator as MatPaginator} from '@angular/material/legacy-paginator';
import {SelectionModel} from "@angular/cdk/collections";


@Component({
  selector: 'app-health-care-plan-relational-dialog',
  templateUrl: './health-care-plan-relational-dialog.component.html',
  styleUrls: ['./health-care-plan-relational-dialog.component.scss']
})
export class HealthCarePlanRelationalDialogComponent extends BaseComponent implements OnInit, AfterViewInit {

  protected log = new Logger("HealthCarePlanRelationalDialogComponent");

  // healthCarePlanRelationalType: string = "TENANT";
  formGroup!: FormGroup;

  @ViewChild(MatPaginator)
  paginator?: MatPaginator;
  totalRowCount?: number;
  defaultPageSize = 10;
  pageSizeOptions = [5, 10, 20, 25];

  displayedColumns = ['select', 'name', 'state'];
  userProfileColumns = ['select', 'companyName', 'name', 'state'];
  healthCarePlanList: any [] = [];
  // selectedHealthCarePlanId: number = -1;

  mode: "COMPANY" | "USER_PROFILE" = "COMPANY";
  relatedId = -1;
  userProfileId = -1;
  selection = new SelectionModel<any>(true, []);

  constructor(
    private healthCarePlanService: HealthCarePlanService,
    public dialogRef: MatDialogRef<HealthCarePlanRelationalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    override snack: FennecSnackbarService,
  ) {
    super();
    if (this.data?.mode) {
      this.mode = this.data.mode;
    }

    if (this.data?.relatedId) {
      this.relatedId = this.data.relatedId;
    }

    if (this.mode == "USER_PROFILE") {
      this.displayedColumns = this.userProfileColumns;
    }

    this.formGroup = this.createFormGroup();
  }

  ngAfterViewInit(): void {
    if (this.paginator) {
      this.paginator.page.subscribe(() => {
        this.executeListQuery();
      });
    }
  }

  ngOnInit(): void {
    this.executeListQuery();
  }

  // selectRow(healthCarePlan:any) {
  //   this.selectedHealthCarePlanId = healthCarePlan.id;
  // }
  onSelect(row: any) {
    if (row == null) {
      return;
    }
    row.selected = !row.selected;

    this.selection?.toggle(row);
  }


  executeListQuery = () => {
    const controls = this.formGroup?.controls;

    const searchParams = {
      name: controls["name"].value?.trim() ?? null,
      stateCode: controls["stateCode"].value?.trim() ?? null,
      active: true,
      userProfileId: this.relatedId,
      companyName: controls["companyName"]?.value?.trim() ?? null,
      unassigned: this.mode === "COMPANY"
    }

    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;
    this.performXFRequest({
      requestDescription: "GET Health Care Plans By Search Params",
      requestFn: this.mode == "USER_PROFILE" ? this.healthCarePlanService.getHealthCarePlansForTenantCompaniesAndFilters : this.healthCarePlanService.getHealthCarePlansByQueryParams,
      fnParams: [searchParams, first, pageSize],
      onResponse: (response: PagedResponse<HealthCarePlanPacket[]>) => {
        this.healthCarePlanList = response.data;
        this.totalRowCount = response['totalRowCount'];
      },
      onError: errString => {
        super.showErrorSnack(errString)
      }
    })
  }

  createFormGroup = () => {
    let formGroup;

    if (this.mode == "USER_PROFILE") {
      formGroup = new FormGroup({
        name: new FormControl(),
        stateCode: new FormControl(),
        companyName: new FormControl()
      })
    } else {
      formGroup = new FormGroup({
        name: new FormControl(),
        stateCode: new FormControl()
      })
    }

    return formGroup;
  }

  onSearch = () => {
    this.paginator?.firstPage();
    this.executeListQuery();
  }

  clearSearch = () => {
    this.formGroup = this.createFormGroup();
  }

  cancel = () => {
    this.dialogRef.close({confirm: false});
  }

  onSubmit = () => {
    const packet = {
      relatedId: this.relatedId,
      healthCarePlanIds: this.selection.selected?.map(row => row.id),
      healthCarePlanRelationalType: this.mode
    }

    this.performXFRequest({
      requestDescription: "Create Health Care Plan Relational Map",
      requestFn: this.healthCarePlanService.createHealthCarePlanRelationalMap,
      fnParams: [packet],
      onResponse: (response: DataResponse<any[]>) => {
        const showWarning = response.data?.includes(a => a == null);
        super.showSuccessSnack(
          "Health Care Plan relationships added"
          + (showWarning
            ? ";\n At least one selected health care plan already had an existing relationship."
            : "")
        );
        this.dialogRef.close({confirm: true});
      },
      onError: errString => {
        super.showErrorSnack(errString)
      }
    })
  }
}
