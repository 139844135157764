<ng-container pageContent class="container-override">

  <h2 *ngIf="this.mode === 'COLLECTION_ADD' || this.mode === 'COLLECTION_REORDER'">
    <button
      mat-flat-button
      color="accent"
      (click)="collectionMode()"
    >
    <mat-icon>arrow_back</mat-icon>
    Back
  </button>
    {{getHeaderString()}}
  </h2>

  <mat-accordion *ngIf="mode === 'ADMIN' || mode === 'COLLECTION_ADD'">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h4>Filters</h4>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <form [formGroup]="formGroup" class="alt-theme" (submit)="onSearch()">
        <div class="input-wrapper">
          <mat-form-field appearance="outline">
            <mat-label>Name</mat-label>
            <input matInput placeholder="Search by Name" formControlName="name">
          </mat-form-field>
    
          <mat-form-field appearance="outline">
            <mat-label>Description</mat-label>
            <input matInput placeholder="Search by Description" formControlName="description">
          </mat-form-field>
          
          <mat-form-field appearance="outline">
            <mat-label>Service Identification Code</mat-label>
            <input matInput placeholder="Search by Service Identification Code" formControlName="serviceIdentificationCode">
          </mat-form-field>

          <mat-form-field *ngIf="this.mode === 'ADMIN'" appearance="outline">
            <mat-label>Status</mat-label>
            <mat-select formControlName="active">
              <mat-option [value]="'all'">All</mat-option>
              <mat-option [value]="true">Active</mat-option>
              <mat-option [value]="false">Not Active</mat-option>
            </mat-select>
          </mat-form-field>
          
          <div class="button-div">
            <button
              mat-stroked-button
              color="accent"
              type="submit"
              class="search-button"
            >
              <mat-icon>search</mat-icon> Search
            </button>
      
            <button
              mat-stroked-button
              color="primary"
              (click)="clearSearch()"
            >
              Clear
            </button>
          </div>
        </div>
      </form>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef>{{this.mode === "COLLECTION_RULES" ? "Rule ID" : "ID"}}</mat-header-cell>
      <mat-cell *matCellDef="let rule">{{rule?.id}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="apeRuleType">
      <mat-header-cell *matHeaderCellDef> Type </mat-header-cell>
      <mat-cell *matCellDef="let rule">
        {{rule?.apeRuleType}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
      <mat-cell *matCellDef="let rule">
        {{rule?.name}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="serviceIdentificationCode">
      <mat-header-cell *matHeaderCellDef>Service Identification Code</mat-header-cell>
      <mat-cell *matCellDef="let rule">
        {{rule?.serviceIdentificationCode}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef> Description </mat-header-cell>
      <mat-cell *matCellDef="let rule">
        {{rule?.description}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="active">
      <mat-header-cell *matHeaderCellDef>Active</mat-header-cell>
      <mat-cell *matCellDef="let rule">
        <mat-icon [ngStyle]="getIconStyle(rule)">
          {{rule?.active ? "check_circle" : "error_outline"}}
        </mat-icon>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="admin_actions">
      <mat-header-cell *matHeaderCellDef>
        <button 
          class="add" 
          mat-flat-button
          (click)="openDialog(null)"
          >
          <mat-icon>add_circle</mat-icon>
          New
        </button>
      </mat-header-cell>
      <mat-cell *matCellDef="let rule">
        <mat-menu #actionMenu="matMenu">
          <button
            mat-menu-item
            (click)="openApeRuleDefinitionDialog(rule)"
          >
            <mat-icon>list_alt</mat-icon> Definition List
          </button>
          
          <button
            mat-menu-item
            (click)="openApeRuleFilterDialog(rule)"
          >
            <mat-icon>filter_alt</mat-icon> APE Filter List
          </button>

          <button
            mat-menu-item
            (click)="openDialog(rule)"
          >
            <mat-icon>edit</mat-icon> Edit
          </button>

          <button
            mat-menu-item
            (click)="toggleActive(rule)"
          >
            <mat-icon>{{getIconString(rule)}}</mat-icon> {{getDeleteButtonText(rule)}}
          </button>

        </mat-menu>

        <button mat-icon-button [matMenuTriggerFor]="actionMenu" [matMenuTriggerData]="">
          <mat-icon>more_vert</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="seqNo">
      <mat-header-cell *matHeaderCellDef>Sequence Number</mat-header-cell>
      <mat-cell *matCellDef="let rule">
        {{rule?.seqNo}}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="select">
      <mat-header-cell *matHeaderCellDef>Select</mat-header-cell>
      <mat-cell *matCellDef="let rule">
        <button
          mat-stroked-button
          color="accent"
          (click)="selectButton(rule)"
        >
          Select
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="collection_actions">
      <mat-header-cell *matHeaderCellDef>
        <button
          mat-icon-button
          (click)="addMode()"
        >
          <mat-icon>add_circle_outline</mat-icon>
          Add Rule
        </button>
      </mat-header-cell>
      <mat-cell *matCellDef="let rule">
        <mat-menu #collectionActionMenu="matMenu">
          <button
            mat-menu-item
            (click)="toggleActive(rule)"
          >
            <mat-icon>delete</mat-icon>
            Delete
          </button>
          <button
            mat-menu-item
            [matMenuTriggerFor]="collectionActionSubMenu" 
            [matMenuTriggerData]="">
            <mat-icon>move_down</mat-icon>
            Move
          </button>

        </mat-menu>
        
        <mat-menu #collectionActionSubMenu="matMenu">
          <button
            mat-menu-item
            (click)="collectionReorderMode(rule, 'SWAP')"
          >
            Swap
          </button>

          <button
            mat-menu-item
            (click)="collectionReorderMode(rule, 'AUTO')"
          >
            Move To
          </button>
        </mat-menu>
        <button 
          mat-icon-button 
          [matMenuTriggerFor]="collectionActionMenu" 
          [matMenuTriggerData]="">
          <mat-icon>more_vert</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="simple_reorder">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let rule">
        <button
          mat-icon-button
          (click)="moveRuleOnePlace(rule)"
        >
          <mat-icon>keyboard_arrow_up</mat-icon>
        </button>

        <button
          mat-icon-button
          (click)="moveRuleOnePlace(rule, true)"
        >
          <mat-icon>keyboard_arrow_down</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
      <mat-row
        *matRowDef="let row; columns: displayedColumns; let i=index"
        [tabindex]="i + 1"
        [attr.data-index]="i"
        [ngClass]="{ hovered: row.hovered }"
        (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"
      ></mat-row>
    </mat-table>
    <mat-paginator class="alt-theme" [length]="totalRowCount" [pageSize]="defaultPageSize" [pageSizeOptions]="defaultPageSizeOptions"></mat-paginator>
</ng-container>