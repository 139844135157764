import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ApeRuleEventDefinitionService } from '../ape-rule-event-definition.service';
import {
  AdjustmentExplanationCodePacket,
  AdjustmentReasonCodePacket,
  APERule,
  APERuleEventDefinitionPacket,
  BaseComponent,
  BaseResponse,
  CodeValidationService,
  FennecSnackbarService,
  Logger
} from "xf-common";

@Component({
  selector: 'app-ape-rule-event-definition-create-edit',
  templateUrl: './ape-rule-event-definition-create-edit.component.html',
  styleUrls: ['./ape-rule-event-definition-create-edit.component.scss']
})
export class ApeRuleEventDefinitionCreateEditComponent extends BaseComponent implements OnInit {

  protected log: Logger = new Logger("ApeRuleEventDefinitionListComponent");

  apeRuleId?: number;
  eventDefinition!: APERuleEventDefinitionPacket;
  mode:string = "NEW";
  formGroup: FormGroup;

  codeValidationText: string = "";
  constructor(
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<ApeRuleEventDefinitionCreateEditComponent>,
    override snack: FennecSnackbarService,
    protected apeRuleEventDefinitionService: ApeRuleEventDefinitionService,
    protected codeValidationService: CodeValidationService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    super();
    this.apeRuleId = this.data?.apeRuleId;

    if(this.data?.mode) {
      this.mode = this.data.mode;
    }

    if(this.data?.eventDefinition) {
      this.eventDefinition = this.data.eventDefinition;
    }

    this.formGroup = this.createFormGroup(this.eventDefinition);
   }

  ngOnInit(): void {
  }

  createFormGroup = (eventDefinition:APERuleEventDefinitionPacket | null) => {
    const newFormGroup = new FormGroup({
      name: new FormControl(eventDefinition ? eventDefinition.name : "", Validators.required),
      eventDefinitionCode: new FormControl(eventDefinition ? eventDefinition.eventDefinitionCode : "", Validators.required),
      description: new FormControl(eventDefinition ? eventDefinition.description : "", Validators.required),
      adjustmentReasonCode: new FormControl(eventDefinition ? eventDefinition.adjustmentReasonCode?.code : "",  Validators.required),
      adjustmentReasonCodeDescription: new FormControl(eventDefinition ? eventDefinition.adjustmentReasonCode?.description : ""),
      adjustmentExplanationCode: new FormControl(eventDefinition ? eventDefinition.adjustmentExplanationCode?.code : "",  Validators.required),
      adjustmentExplanationCodeDescription: new FormControl(eventDefinition ? eventDefinition.adjustmentExplanationCode?.description : "")
    });

    newFormGroup.controls['eventDefinitionCode'].valueChanges.subscribe(() => {
      this.validateAPERuleEventDefinitionCode()
    });
    newFormGroup.controls['adjustmentReasonCode'].valueChanges.subscribe(() => {
      this.validateAdjustmentReasonCode()
    });
    newFormGroup.controls['adjustmentExplanationCode'].valueChanges.subscribe(() => {
      this.validateAdjustmentExplanationCode()
    });
    newFormGroup.controls['adjustmentReasonCodeDescription'].disable();
    newFormGroup.controls['adjustmentExplanationCodeDescription'].disable();

    return newFormGroup;
  }

  close() {
    this.dialogRef.close();
  }

  getHeaderText() {
    if(this.mode == "NEW") {
      return "Create New Event Definition"
    }
    if(this.mode == "EDIT") {
      return `Edit Event Definition ID: ${this.eventDefinition.id} : ${this.eventDefinition.name}`;
    }
    return "";
  }

  onSubmit = () => {
    const packet = new APERuleEventDefinitionPacket();

    if(this.mode == "NEW") {
      packet.id = -1;
    }else {
      packet.id = this.eventDefinition.id;
    }

    packet.apeRule = new APERule();
    packet.apeRule.id = this.apeRuleId;

    const controls = this.formGroup.controls;
    packet.name = controls['name'].value;
    packet.description = controls['description'].value;
    packet.eventDefinitionCode = controls['eventDefinitionCode'].value;
    packet.adjustmentExplanationCode = new AdjustmentExplanationCodePacket();
    packet.adjustmentExplanationCode.code = controls['adjustmentExplanationCode'].value
    packet.adjustmentReasonCode = new AdjustmentReasonCodePacket();
    packet.adjustmentReasonCode.code = controls['adjustmentReasonCode'].value

    this.performXFRequest({
      requestDescription: "CREATE/UPDATE Ape Rule Event Definition",
      requestFn: this.apeRuleEventDefinitionService._create,
      fnParams: [packet],
      onSuccess: (data:any) => {
        super.showSuccessSnack("Save Successful");
        this.close();
      },
      onError: (errString:any) => {
        super.showErrorSnack(errString);
      }
    })
  }

  validateAPERuleEventDefinitionCode = () => {
    const control = this.formGroup.controls["eventDefinitionCode"]
    if(!control.pristine){
      if(control.value.length == 0){
        control.markAsPristine();
        control.setErrors({'required': true});
        this.codeValidationText = "Unique Identifier Code required"
        return;
      }

      if(this.eventDefinition && control.value == this.eventDefinition?.eventDefinitionCode) {
        control.markAsPristine();
        control.setErrors(null);
        this.codeValidationText = `Valid`
        return;
      }

      const packet = {
        eventDefinitionCode: control.value,
        apeRuleId: this.apeRuleId
      }
      this.apeRuleEventDefinitionService.validateAPERuleEventDefinitionCode(packet).subscribe((response: BaseResponse) => {
        if(response.hasErrors){
          super.showErrorSnack("Error Validating Event Definition Code " + response.consolidatedErrorMessage);
        }else{
          if(!response.data) {
            control.markAsPristine();
            control.setErrors({'codeTaken': true});
            this.codeValidationText = `Event Definition Code already exits for APE Rule ID:${this.apeRuleId}`
          }else{
            control.markAsPristine();
            control.setErrors(null);
            this.codeValidationText = `Valid`
          }
        }
      })
    }
  }

  validateAdjustmentReasonCode() {
    let arcFormControl: FormControl = this.formGroup.controls['adjustmentReasonCode'] as FormControl;
    let arCodeDescriptionFormControl: FormControl = this.formGroup.controls['adjustmentReasonCodeDescription'] as FormControl;

    if (!arcFormControl.pristine) {
      if (arcFormControl.value.length < 1) {
        arcFormControl.markAsPristine();
        arCodeDescriptionFormControl.setValue("");
        arcFormControl.setErrors(null);
      }else if (arcFormControl.value.length > 4) {
        arcFormControl.markAsPristine();
        arCodeDescriptionFormControl.setValue("Invalid");
        arcFormControl.setErrors({'incorrect': true});
      } else {
        this.codeValidationService.getAdjustmentReasonCode(arcFormControl.value).subscribe((response: BaseResponse) => {
          if (response.hasErrors) {
            arcFormControl.markAsPristine();
            arcFormControl.setErrors({'incorrect': true});
          } else {
            if (response.data !== null) {
              arcFormControl.markAsPristine();
              arCodeDescriptionFormControl.setValue(response.data.description);
              arcFormControl.setErrors(null);
            } else {
              arCodeDescriptionFormControl.setValue("Invalid");
              arcFormControl.markAsPristine();
              arcFormControl.setErrors({'incorrect': true});
            }
          }
        });
      }
    }
  }

  validateAdjustmentExplanationCode() {
    let arcFormControl: FormControl = this.formGroup.controls['adjustmentExplanationCode'] as FormControl;
    let arCodeDescriptionFormControl: FormControl = this.formGroup.controls['adjustmentExplanationCodeDescription'] as FormControl;

    if (!arcFormControl.pristine) {
      if (arcFormControl.value.length < 1) {
        arcFormControl.markAsPristine();
        arCodeDescriptionFormControl.setValue("");
        arcFormControl.setErrors(null);
      }else if (arcFormControl.value.length > 4) {
        arcFormControl.markAsPristine();
        arCodeDescriptionFormControl.setValue("Invalid");
        arcFormControl.setErrors({'incorrect': true});
      } else {
        this.codeValidationService.getAdjustmentExplanationCode(arcFormControl.value).subscribe((response: BaseResponse) => {
          if (response.hasErrors) {
            arcFormControl.markAsPristine();
            arcFormControl.setErrors({'incorrect': true});
          } else {
            if (response.data !== null) {
              arcFormControl.markAsPristine();
              arCodeDescriptionFormControl.setValue(response.data.description);
              arcFormControl.setErrors(null);
            } else {
              arCodeDescriptionFormControl.setValue("Invalid");
              arcFormControl.markAsPristine();
              arcFormControl.setErrors({'incorrect': true});
            }
          }
        });
      }
    }
  }

  getValidationIconString = () => {
    if(this.codeValidationText == `Valid`) {
      return "check_circle_outline"
    }
    return "error_outline"
  }

  getValidationIconStyleString = () => {
    if(this.codeValidationText == `Valid`) {
      return {"color":"green"}
    }
    return {"color":"red"}
  }
}
