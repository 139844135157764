import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { EnvironmentService } from "xf-common";

@Injectable({
  providedIn: 'root'
})
export class MICaseUsersService {

  private miCaseUrl = `${this.environment.baseApiUrl} mi-case/"`;

  constructor(
    private httpClient: HttpClient,
    private environment: EnvironmentService
  ) {}

  getAllCaseUserTypes(): Observable<any> {
    return this.httpClient.get<any>(this.environment.baseApiUrl + `mi-case/user-type-list`);
  }

  getCaseUsers(miCaseId: number): Observable<any> {
    return this.httpClient.get<any>(this.environment.baseApiUrl + `mi-case/user-list/` + miCaseId.toString());
  }

  deleteCaseUser(miCaseUserId: number): Observable<any> {
    return this.httpClient.delete<any>(this.environment.baseApiUrl + `mi-case/user/` + miCaseUserId.toString());
  }

}
