import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';

import { Ub04Service } from '../ub04.service';
import { Logger } from "../../util/logger";
import { BaseComponent } from "../../view/base.component";
import { Ub04SelectComponent } from "../../itemized/ub04-select/ub04-select.component";
import { FennecSnackbarService } from "../../dialog/fennec-snackbar/fennec-snackbar.service";

@Component({
  selector: 'app-ub04-review',
  templateUrl: './ub04-review.component.html',
  styleUrls: ['./ub04-review.component.scss']
})
export class Ub04ReviewComponent extends BaseComponent implements AfterViewInit, OnInit {

  log: Logger = new Logger("Ub04ReviewComponent");

  // This is required. It's used to allow the user to link to a ub04 if one is not already found.
  @Input()
  miCaseId: number = -1;

  // This is required. It's used to allow the user to link to a ub04 if one is not already found.
  @Input()
  itemizedId: number = -1;

  // The 'linked' itemized ub04 id if it's available. As of now it must be passed into the component. We
  // could make this component smart enough to glean it from the supplied itemizedId, but this doesn't
  // work that way yet. This is required.
  @Input()
  ub04Id: string | null = null;

  // The data we will display to the user from the ub04.
  ub04ReviewData?: any = null;

  @ViewChild('ub04SelectComponent')
  ub04SelectComponent!: Ub04SelectComponent;

  constructor(
    protected override snack: FennecSnackbarService,
    private ub04Service: Ub04Service
  ) {
    super();
  }
  ngAfterViewInit(): void {
    this.ub04SelectComponent?.saveSuccessful.subscribe((ub04Id) => {
      if (ub04Id !== null && ub04Id !== undefined) {
        this.ub04Id = ub04Id.toString();
        this.getUb04ReviewInfo();
      }
    });
  }

  ngOnInit(): void {
  }

  getUb04ReviewInfo(): void {
    if (this.ub04Id === null) {
      // This is on a delay because sometimes this is called by a parent component and the ub04SelectComponent
      // is not available yet.
      setTimeout(() => {
        this.ub04SelectComponent?.getUb04List(this.miCaseId, this.itemizedId);
      }, 100);
      return;
    }
    const queryUb04Id: number = parseInt(this.ub04Id);
    this.isLoading = true;
    this.performXFRequest({
        requestDescription: "GET review data for selected UB04",
        requestFn: this.ub04Service.getUb04ReviewById,
        fnParams: [queryUb04Id],
        onSuccess: data => {
          this.ub04ReviewData = data;
          this.isLoading = false;
        },
        onError: errString => {
          this.showErrorSnack(errString);
          this.isLoading = false;
        }
      }
    );
  }

}
