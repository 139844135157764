<div class="xfwf-manual-task-parent-wrapper">
  <div matDialogTitle>
    Create Manual Workflow Task for Case Id: {{miCaseId}}
  </div>

  <div matDialogContent>
    <div *ngIf="showCurrentOpenTask()">
      Case Current Open Task: <span class="open-task-highlight">{{this.taskList[0].taskType}}</span> with status <span class="open-task-highlight">{{this.taskList[0].taskStatus}}</span> will be auto completed when manual task is created!
    </div>
    <div *ngIf="showCurrentClosedTask()">
      Latest Closed/Terminated Task: <span class="open-task-highlight">{{this.taskList[0].taskType}}</span> with status <span class="open-task-highlight">{{this.taskList[0].taskStatus}}</span>. Closed/Terminated Date: <span class="open-task-highlight">{{this.taskList[0].createdDate | date: 'MM/dd/yyyy h:mm a'}}</span>
    </div>

    <div class="xfwf-manual-task-selected-info-wrapper">
      Selected Task Type: <span class="xfwf-manual-task-selected-info-element">{{selectedTaskType == null ? 'Not Selected' : selectedTaskType.value}}</span>
    </div>
    <div class="task-options">
      <form [formGroup]="formGroup" class="alt-theme">
        <mat-form-field appearance="outline">
          <mat-label>Stage Group</mat-label>
          <mat-select formControlName="stageGroup">
            <mat-option *ngFor="let sg of stageGroups" [value]="sg">
              {{sg}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Due Date</mat-label>
          <input matInput placeholder="(MM/DD/YYYY)" formControlName="dueDate">
          <mat-error *ngIf="dueDateControl?.hasError('dateTooEarly')">
            The review date must be in the present or future
          </mat-error>
          <mat-error *ngIf="dueDateControl?.hasError('incomplete')">
            Please enter a date in MM/dd/yyyy format
          </mat-error>
        </mat-form-field>
      </form>
      <div class="assignee">
        <div class="assignee-content">
          <span class="assignee-text">Assignee: </span>
          <span class="assignee-name">
            {{
              (selectedUser?.firstName || selectedUser?.lastName)
                ? selectedUser?.firstName + ' ' + selectedUser?.lastName
                : 'N/A'
            }}
          </span>
          <button mat-stroked-button type="button" class="assignee-edit-button" (click)="onUserSelect()">
            <mat-icon>person_search</mat-icon>
            Select User
          </button>
        </div>
      </div>
    </div>
    <div class="xfwf-task-type-list-table-wrapper">
      <mat-table [dataSource]="xfwfTaskTypeDataSource">
        <ng-container matColumnDef="task-type">
          <mat-header-cell *matHeaderCellDef> Task Type </mat-header-cell>
          <mat-cell *matCellDef="let type"> {{type?.label}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="task-stage">
          <mat-header-cell *matHeaderCellDef> Stage </mat-header-cell>
          <mat-cell *matCellDef="let type"> {{type?.properties?.workflowStage}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let type">
              <button
                matTooltip="Select"
                mat-icon-button color="accent"
                (click)="onTaskTypeSelect(type)">
                Select
              </button>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedColumns; let i=index"
          [tabindex]="i + 1"
          [attr.data-index]="i"
          [ngClass]="getRowIndicatorClass(row)"
        ></mat-row>
      </mat-table>
    </div>

  </div>

  <div matDialogActions class="fennec-dialog-action-button-container">
    <button style="margin-right: 50px" mat-flat-button (click)="close()">Cancel</button>
    <button mat-button mat-flat-button color="accent" (click)="submit()"
      [disabled]="selectedTaskType == null">
      Create Task
    </button>
  </div>

</div>
