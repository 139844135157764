import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ApeRuleFilterService } from '../ape-rule-filter.service';
import { APERuleFilter, BaseComponent, FennecSnackbarService, Logger } from "xf-common";


@Component({
  selector: 'app-ape-rule-filter-form-dialog',
  templateUrl: './ape-rule-filter-form-dialog.component.html',
  styleUrls: ['./ape-rule-filter-form-dialog.component.scss', '../../ape-rule-admin-styles.scss']
})
export class ApeRuleFilterFormDialogComponent extends BaseComponent implements OnInit {

  protected log = new Logger("ApeRuleFilterFormDialogComponent");
  formGroup: FormGroup;

  domains: string[] = [""];
  apeRuleFilter: APERuleFilter | null = null;

  constructor(
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<ApeRuleFilterFormDialogComponent>,
    override snack: FennecSnackbarService,
    protected apeRuleFilterService: ApeRuleFilterService,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    super();
    if(this.data?.apeRuleFilter) {
      this.apeRuleFilter = this.data.apeRuleFilter
    }

    this.formGroup = this.createFormGroup();
  }

  ngOnInit(): void {
    this.performXFRequest({
      requestDescription: "GET Ape Rule Filter Domains",
      requestFn: this.apeRuleFilterService.getAPEFilterDomainList,
      fnParams: [],
      onResponse: response => {
        this.domains = response.data;
      },
      onError: errString => {
        super.showErrorSnack(errString)
      }
    })
  }

  createFormGroup = () => {
    return new FormGroup({
      name: new FormControl(this.apeRuleFilter != null ? this.apeRuleFilter.name : "", Validators.required),
      description: new FormControl(this.apeRuleFilter != null ? this.apeRuleFilter.description : "", Validators.required),
      apeRuleFilterDomain: new FormControl(this.apeRuleFilter != null ? this.apeRuleFilter.apeRuleFilterDomain : this.domains[0], Validators.required),
      apeRuleFilterDevCode: new FormControl(this.apeRuleFilter != null ? this.apeRuleFilter.apeRuleFilterDevCode : "", Validators.maxLength(255)),
    })
  }

  close = () => {
    this.dialogRef.close();
  }

  getHeaderText = () => {
    let result = "";

    if(this.apeRuleFilter == null) {
      result = "Add New "
    }else{
      result = "Edit Existing "
    }

    result += "APE Rule Filter";
    return result;
  }

  submit = () => {
    const controls = this.formGroup.controls;
    const dto = new APERuleFilter();
    dto.id = this.apeRuleFilter == null ? -1 : this.apeRuleFilter.id;
    dto.name = controls["name"].value;
    dto.description = controls["description"].value;
    dto.apeRuleFilterDomain = controls["apeRuleFilterDomain"].value;
    dto.apeRuleFilterDevCode = controls["apeRuleFilterDevCode"].value;

    this.performXFRequest({
      requestDescription: "CREATE/UPDATE APE Rule Filter",
      requestFn: this.apeRuleFilterService._create,
      fnParams: [dto],
      onResponse: response => {
        super.showSuccessSnack("Save Complete");
        this.dialogRef.close();
      },
      onError: errString => {
        super.showErrorSnack(errString)
      }
    })
  }
}
