<lib-page>
  <ng-container pageTitle>
    <button type="button" mat-stroked-button color="accent" class="button-with-icon" routerLink="/providers">
      <mat-icon>navigate_before</mat-icon>
      Providers
    </button>
  </ng-container>
  <ng-container pageContent>
    <mat-tab-group color="accent">
      <mat-tab label="Basic Information">
        <app-provider-edit [providerTypes]="providerTypes"></app-provider-edit>
      </mat-tab>

      <mat-tab label="EIN">
        <app-provider-ein-list [providerId]="providerId"></app-provider-ein-list>
      </mat-tab>
      
      <mat-tab label="Addresses">
        <lib-provider-address></lib-provider-address>
      </mat-tab>

      <mat-tab label="Contacts">
        <lib-contact-list [relatedId]="providerId"></lib-contact-list>
      </mat-tab>

    </mat-tab-group>
  </ng-container>
</lib-page>
