import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EnvironmentService, SystemConfigurationPacket } from "xf-common";


@Injectable({
  providedIn: 'root'
})
export class SystemConfigurationService {

  constructor(
    private httpClient: HttpClient,
    private environment: EnvironmentService
  ) {}

  getSystemConfiguration = () => {
    return this.httpClient.get<any>(this.environment.baseApiUrl + "systemconfiguration");
  }

  updateSystemConfiguration = (payload:SystemConfigurationPacket) => {
    return this.httpClient.put<any>(this.environment.baseApiUrl + "systemconfiguration", payload);
  }
}
