import { Deserializable, deserializeAssign } from "../deserializable";
import { APERuleFilter } from "./APERuleFilter";
import { StatePacket } from "./statePacket";

export class APERuleFilterState implements Deserializable<APERuleFilterState> {

  id?: number;
  apeRuleFilter?: APERuleFilter;
  state?: StatePacket;
  active?:boolean;

  deserialize(input: any): APERuleFilterState {
    return deserializeAssign(this, input);
  }

  toJSON(): any {
    const obj: { [key: string]: any } = {};
    Object.assign(obj, this);
    delete obj["selected"];
    return obj;
  }
}