<lib-page #page [noDataFound]="dataSource.data.length === 0" [isLoading]="isLoading">
    <ng-container pageTitle>
      States
    </ng-container>

    <ng-container pageContent class="container-override">

      <mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
          <mat-cell *matCellDef="let code"> {{code?.stateName}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="code">
          <mat-header-cell *matHeaderCellDef> Code </mat-header-cell>
          <mat-cell *matCellDef="let code"> {{code?.stateCode}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="active">
          <mat-header-cell *matHeaderCellDef> Active </mat-header-cell>
          <mat-cell *matCellDef="let code">
            <mat-icon class="active" *ngIf="code?.active">
              check_small
            </mat-icon>
            <mat-icon class="non-active" *ngIf="!code?.active">
              close
            </mat-icon>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef>
            <button class="add" mat-flat-button (click)="onCreate()">
              <mat-icon>add_circle</mat-icon>
              New
            </button>
          </mat-header-cell>
          <mat-cell *matCellDef="let code">
            <mat-menu #appMenu="matMenu">
              <button
                *ngIf="canUpdate"
                matTooltip="Edit (e)"
                mat-menu-item color="accent"
                (click)="onEdit(code.id)">
                <mat-icon>edit</mat-icon>
                <span>Edit</span>
              </button>
              <button
                *ngIf="canDelete"
                matTooltip="Delete (d)"
                mat-menu-item color="primary"
                (click)="onDelete([code.id])">
                <mat-icon>delete</mat-icon>
                <span>Delete</span>
              </button>
              <button
                *ngIf="!code?.active && canDeactivate"
                matTooltip="Activate"
                mat-menu-item
                (click)="openRelationshipChangeDialog(code)">
                <mat-icon>check</mat-icon>
                <span>Activate</span>
              </button>
              <button
                *ngIf="code?.active && canDeactivate"
                matTooltip="Deactivate"
                mat-menu-item
                (click)="openRelationshipChangeDialog(code)">
                <mat-icon>close</mat-icon>
                <span>Deactivate</span>
              </button>
            </mat-menu>

            <button mat-icon-button [matMenuTriggerFor]="appMenu" [matMenuTriggerData]="{name: 'Sally'}">
              <mat-icon>more_vert</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row
          *matRowDef="let row; columns: displayedColumns; let i=index"
          [tabindex]="i + 1"
          [attr.data-index]="i"
          [ngClass]="{ hovered: row.hovered }"
          (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"
        ></mat-row>
      </mat-table>
      <mat-paginator [length]="totalRowCount" [pageSize]="defaultPageSize" [pageSizeOptions]="defaultPageSizeOptions"></mat-paginator>

    </ng-container>

  </lib-page>
