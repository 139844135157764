import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { Logger } from "xf-common";
import { BaseComponent } from "xf-common";
import { FennecSnackbarService } from 'xf-common';
import { StatusTrackService } from 'xf-common';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-status-track-manual-assign',
  templateUrl: './status-track-manual-assign.component.html',
  styleUrls: ['./status-track-manual-assign.component.scss']
})
export class StatusTrackManualAssignComponent extends BaseComponent implements OnInit, AfterViewInit {
  log = new Logger("StatusTrackManualAssignComponent");

  miCaseId: number = -1;
  infoLine1?: string | null = null;
  initialStatusTrackStatus: string;
  elementLabel: string = "Unknown";
  statusTrackDomain: string = "MI_CASE";
  statusTrackElement: string = "MI_CASE_CF_STATUS";
  manualStatusTrackStatusList: any [] = [];
  formGroup: FormGroup;

  constructor(
    protected snack: FennecSnackbarService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<StatusTrackManualAssignComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private statusTrackService: StatusTrackService
  ) {
    super();
    this.miCaseId = data?.miCase?.id;
    this.infoLine1 = data?.infoLine1;
    this.statusTrackDomain = data?.statusTrackDomain;
    this.statusTrackElement = data?.statusTrackElement;
    this.initialStatusTrackStatus = data?.initialStatusTrackStatus;
    this.elementLabel = data?.elementLabel;

    this.formGroup = new FormGroup({
      statusTrackStatus: new FormControl(this.initialStatusTrackStatus)
    });

  }

  ngAfterViewInit(): void {
    this.getManualStatusTrackStatusValues();
  }

  ngOnInit(): void {
  }

  /**
   * Get's the list of available status track status values that the user can manually assign.
   */
  getManualStatusTrackStatusValues() {
    this.statusTrackService.getManualStatusTrackStatusListForElement(this.statusTrackElement).subscribe(response => {
      if (response.hasErrors) {
        super.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.manualStatusTrackStatusList = response.data;
      }
    });
  }  

  updateStatusTrack() {
    let selectedStatusTrackStatus = this.formGroup.controls['statusTrackStatus'].value;
    if (this.initialStatusTrackStatus === selectedStatusTrackStatus) {
      this.closeDialogComplete();
    } else {
      this.statusTrackService.updateStatusTrackStatus(this.statusTrackDomain, this.miCaseId, this.statusTrackElement, 
        selectedStatusTrackStatus).subscribe(response => {
          if (response.hasErrors) {
            super.showErrorSnack(response.consolidatedErrorMessage);
          } else {
            this.closeDialogComplete();
          }
        });
    }
  }

  cancel() {
    let returnObj = {
      confirm: false
    };
    this.dialogRef.close(returnObj);
  }

  submit() {
    this.updateStatusTrack();

  }
  
  closeDialogComplete() {
    let returnObj = {
      confirm: true
    };
    this.dialogRef.close(returnObj);
  }
  
}
