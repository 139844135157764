import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import {
  BaseComponent,
  DateUtil,
  FennecSnackbarService,
  Logger,
  PagedResponse,
  SelectedUser,
  SingleChoiceDialogComponent
} from 'xf-common';
import { XfwfService } from "@app/xfwf/xfwf.service";
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";
import { Observable } from "rxjs";
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { XFWFActionDialogComponent } from "@app/xfwf/xfwf-action-dialog/xfwf-action-dialog.component";


@Component({
  selector: 'app-xfwf-create-manual',
  templateUrl: './xfwf-create-manual.component.html',
  styleUrls: ['./xfwf-create-manual.component.scss']
})
export class XfwfCreateManualComponent extends BaseComponent implements OnInit {
  log = new Logger("XfwfCreateManualComponent");

  miCaseId?: number;
  taskTypes?: any;
  taskStatuses?: any;
  formGroup: FormGroup;
  confirmDialogRef?: any;
  selectedUser?: SelectedUser | null;

  // List of existing tasks associated with the miCaseId.
  taskList: any [] = [];
  xfwfTaskTypeDataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  displayedColumns = ["task-stage", "task-type", "actions"];
  selectedTaskType: any = null;
  currentTaskType: any = null;

  stageGroups: any [] = [];
  selectedStageGroup = "ALL";
  minDueDate = DateUtil.getDenverJSDateToday();
  dueDateControl?: AbstractControl;

  constructor(
    protected snack: FennecSnackbarService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<XfwfCreateManualComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private xfwfService: XfwfService
  ) {
    super();
    this.miCaseId = data?.miCaseId;
    this.taskTypes = data?.taskTypes;
    this.taskStatuses = data?.taskStatuses;
    this.xfwfTaskTypeDataSource.data = data?.taskTypes;
    this.gleanStageGroupsFromConfigData();

    this.formGroup = new FormGroup({
      stageGroup: new FormControl("ALL"),
      dueDate: new FormControl(null, this.validateDueDate())
    });
    this.dueDateControl = this.formGroup.controls['dueDate'];
    this.formGroup.controls['stageGroup'].valueChanges.subscribe(value => {
      this.filterTaskTypesList();
    });

  }

  ngOnInit(): void {
    this.getTaskList();
  }

  showCurrentOpenTask(): boolean {
    if (this.taskList.length > 0) {
      const latestTask = this.taskList[0];
      if (latestTask.taskStatus !== "COMPLETE" && latestTask.taskStatus !== "TERMINATED") {
        return true;
      }
    }
    return false;
  }

  showCurrentClosedTask(): boolean {
    if (this.taskList.length > 0) {
      const latestTask = this.taskList[0];
      if (latestTask.taskStatus === "COMPLETE" || latestTask.taskStatus === "TERMINATED") {
        return true;
      }
    }
    return false;
  }

  getRowIndicatorClass(row: any) {
    if (this.selectedTaskType !== null && this.selectedTaskType.value === row.value) {
      return 'xfwf-task-type-selected';
    } else if (this.currentTaskType !== null && this.currentTaskType.taskType === row.value) {
      return 'xfwf-manual-task-current-task-row';
    }
    return 'xfwf-task-type-not-selected';
  }

  gleanStageGroupsFromConfigData() {
    this.stageGroups = ['ALL'];
    const stageGroupSet = new Set();
    this.xfwfTaskTypeDataSource.data.forEach((taskConfig) => {
      if (taskConfig.properties.stageGroup !== null && taskConfig.properties.stageGroup !== undefined) {
        stageGroupSet.add(taskConfig.properties.stageGroup);
      }
    });
    for (const sg of stageGroupSet.values()) {
      this.stageGroups.push(sg);
    }
  }

  filterTaskTypesList() {
    const stageGroup = this.formGroup.controls['stageGroup'].value;
    let filteredTaskTypes: any [] = [];
    if (stageGroup === "ALL") {
      this.xfwfTaskTypeDataSource.data = this.taskTypes;
    } else {
      this.taskTypes.forEach((taskConfig: any) => {
        if (taskConfig.properties.stageGroup !== null && taskConfig.properties.stageGroup !== undefined
          && taskConfig.properties.stageGroup === stageGroup) {
          filteredTaskTypes.push(taskConfig);
        }
      });
      this.xfwfTaskTypeDataSource.data = filteredTaskTypes;
    }
  }

  validateDueDate(): ValidatorFn {
    return (ctrl: AbstractControl): ValidationErrors | null => {
      const val = ctrl.value;
      if (val == null || val == "") {
        // not a required field; no error
        return null;
      }

      if (val.length != 10) {
        // value entered does not match format length
        return {incomplete: true};
      }

      const dateEntered = DateUtil.inputDateStringToJSDate(val);
      if (dateEntered == null) {
        // value failed to parse as MM/dd/yyyy
        return {incomplete: true};
      }

      if (dateEntered < this.minDueDate) {
        // value entered is too early
        return {dateTooEarly: true};
      }

      // value entered is valid
      return null;
    }
  }

  submit(force = false) {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.invalid) {
      super.showErrorSnack("Please fill out all required fields");
      return;
    }
    if (this.selectedTaskType == null) {
      super.showErrorSnack("Task Type is required");
      return;
    }
    if (this.miCaseId == null) {
      super.showErrorSnack("MI Case ID must be specified; please contact system support");
      return;
    }
    const dto = {
      caseId: this.miCaseId,
      taskType: this.selectedTaskType.value,
      assignee: this.selectedUser?.userId,
      force: force,
      dueDate: this.formGroup?.value?.dueDate
    };
    this.performXFRequest({
      requestDescription: "Create Manual Task",
      requestFn: this.xfwfService.createManualTask,
      fnParams: [dto],
      onSuccess: data => {
        if (data === "confirm") {
          this.openConfirmationDialog().subscribe((result) => {
            if (result?.confirm) {
              this.submit(true);
            }
          });
        } else {
          super.showSuccessSnack("Task successfully created!");
          data['manualTaskCreated'] = true;
          this.dialogRef.close(data);
        }
      },
      onError: err => {
        super.showErrorSnack(err);
      }
    })
  }

  openConfirmationDialog(message?: string): Observable<any> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      question: message ?? `The user ID provided doesn't match any known user. Would you like to create the task unassigned?`
    };
    this.confirmDialogRef = this.dialog.open(SingleChoiceDialogComponent, dialogConfig);
    return this.confirmDialogRef.afterClosed();
  }

  close() {
    const data = {
      manualTaskCreated: false
    }
    this.dialogRef.close(data);
  }

  // Get's all of the tasks for the given mi case so we can use the information to dial in
  // the user experience a bit.
  getTaskList() {
    const pageSize = 9999;
    const first = 0;

    const params = {
      miCaseId: this.miCaseId,
      status: null,
      type: null,
      userId: null
    }

    this.performXFRequest({
      requestDescription: "GET task list",
      requestFn: this.xfwfService.getTaskListByParams,
      fnParams: [params, first, pageSize],
      onSuccess: data => {

      },
      onResponse: (resp: PagedResponse<any>) => {
        this.taskList = resp.data;
        if (!!resp.data && !!resp.data.tasks) {
          this.taskList = resp.data.tasks;
          if (this.taskList.length > 0) {
            this.currentTaskType = this.taskList[0];
          }
        } else {
          this.taskList = [];
        }
      },
      onError: errString => {
        super.showErrorSnack(errString);
      }
    });
  }

  onTaskTypeSelect(taskType: any) {
    this.selectedTaskType = taskType;
  }

  onUserSelect() {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";

    matDialogConfig.data = {
      title: "Assign Task",
      actionName: "ASSIGN_TASK",
      actionLabel: "Select User",
      mode: "SELECT_USER",
      selectedUser: this.selectedUser
    };

    const dialogRef = this.dialog.open(XFWFActionDialogComponent, matDialogConfig);
    dialogRef.afterOpened().subscribe(() => {
      const component = dialogRef.componentInstance;
      component?.onSubmit$.subscribe((user) => {
        this.selectedUser = user;
        dialogRef.close();
      });
    });
  }
}
