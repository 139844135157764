<div matDialogTitle>
  {{ this.mode }} HCPCS Code
</div>

<div matDialogContent>
  <form [formGroup]="formGroup" class="alt-theme" style="display:flex; flex-direction:column;">
    <mat-form-field appearance="outline" color="accent">
      <mat-label>Code</mat-label>
      <input matInput class="name" id="code" formControlName="code" required maxlength="6"/>
      <mat-hint>{{formGroup.controls['code'].value.length}} of 6</mat-hint>
    </mat-form-field>
    <mat-form-field appearance="outline" color="accent" [style.width.px]=450>
      <mat-label>Description</mat-label>
      <textarea matInput cdkTextareaAutosize rows="5" class="name" id="description" formControlName="description" required maxlength="500"></textarea>
      <mat-hint>{{formGroup.controls['description'].value.length}} of 500</mat-hint>
    </mat-form-field>
  </form>
</div>

<div matDialogActions class="fennec-dialog-action-button-container">
  <button mat-flat-button color="primary" (click)="cancel()">
    Cancel
  </button>
  <button [disabled]="formGroup.invalid" mat-flat-button color="accent" (click)="submit()">
    Save
  </button>
</div>