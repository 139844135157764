<lib-page>
  <ng-container pageTitle>
    <span>Companies</span>
    <button class="add" mat-flat-button (click)="onCreate()">
      <mat-icon>add_circle</mat-icon>
      New
    </button>
  </ng-container>
  <ng-container pageContent>
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <h4>Filters</h4>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="filter-options">
          <mat-form-field class="search-field" appearance="outline" color="accent">
            <mat-label>
              Company Name
            </mat-label>
            <input matInput [(ngModel)]="queryParams.companyName" autofocus (keydown.enter)="searchCompanies()">
          </mat-form-field>
          <button mat-flat-button color="accent" (click)="searchCompanies()">
            Search
          </button>
          <button mat-stroked-button color="accent" (click)="clearSearch()">
            Clear
          </button>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <mat-table [dataSource]="companyList" class="mat-elevation-z8">

      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef> Id </mat-header-cell>
        <mat-cell *matCellDef="let c">
          {{c.id}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef> Company Name </mat-header-cell>
        <mat-cell *matCellDef="let c">
          <!-- <a [routerLink]="['/company/', c.id]" routerLinkActive="active"> -->
            {{c.name}}
          <!-- </a> -->
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="address">
        <mat-header-cell *matHeaderCellDef> Address </mat-header-cell>
        <mat-cell *matCellDef="let c"> {{c.address}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
        <mat-cell *matCellDef="let c">
          <mat-menu #actionMenu="matMenu">
            <a [routerLink]="['/company/', c.id]" routerLinkActive="active">
              <button
                mat-menu-item
              >
                <mat-icon>edit</mat-icon>
                Edit
              </button>
            </a>

            <button
              mat-menu-item
              (click)="onDeleteCompany(c)"
            >
              <mat-icon>delete</mat-icon>
              Delete
            </button>
          </mat-menu>

          <button mat-icon-button [matMenuTriggerFor]="actionMenu" aria-label="Row Action Items">
            <mat-icon>more_vert</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row 
        *matRowDef="let row; columns: displayedColumns;"
        [ngClass]="{ hovered: row.hovered }"
        (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"
      >
      </mat-row>
    </mat-table>
    <mat-paginator [length]="totalRowCount" [pageSize]="defaultPageSize" [pageSizeOptions]="pageSizeOptions"></mat-paginator>
  </ng-container>
</lib-page>

