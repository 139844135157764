import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatGridListModule } from "@angular/material/grid-list";
import { RouterModule } from "@angular/router";
import { AttachmentComponent } from "./attachment.component";
import { ModifyAttachmentDialogComponent } from './modify-attachment-dialog/modify-attachment-dialog.component';
import { AttachmentService, CommonViewModule, MaterialModule } from "xf-common";


@NgModule({
  imports: [
    CommonViewModule,
    MatCardModule,
    MaterialModule,
    MatGridListModule,
    RouterModule,
    CommonModule
  ],
  declarations: [AttachmentComponent, ModifyAttachmentDialogComponent],
  exports: [AttachmentComponent],
  providers: [AttachmentService]
})
export class AttachmentModule {}
