<div matDialogTitle>
  Activity Log
</div>

<mat-dialog-content>
  <div class="container">
    <mat-card>
      <mat-card-header>
        <mat-card-title>
          Before Data
        </mat-card-title>
      </mat-card-header>
    
      <mat-card-content>
        <div *ngFor="let data of beforeData" [ngStyle]="getStyle(data)">
          <span>{{data.key}}: </span>
          <p>{{data.value}}</p>
        </div>
      </mat-card-content>
    </mat-card>
    
    <mat-card>
      <mat-card-header>
        <mat-card-title>
          After Data
        </mat-card-title>
      </mat-card-header>
    
      <mat-card-content>
        <div *ngFor="let data of afterData" [ngStyle]="getStyle(data)">
          <span>{{data.key}}: </span>
          <p>{{data.value}}</p>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button
    mat-stroked-button
    (click)="close()"
  >
    <mat-icon>close</mat-icon>
    Close
  </button>
</mat-dialog-actions>