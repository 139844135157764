import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { BaseComponent, FennecSnackbarService, Logger, ProviderService } from "xf-common";


@Component({
  selector: 'app-provider',
  templateUrl: './provider.component.html',
  styleUrls: ['./provider.component.scss']
})
export class ProviderComponent extends BaseComponent implements OnInit {
  log: Logger = new Logger("ProviderComponent");
  providerId = -1;

  provider: any;
  providerTypes?: any;

  constructor(
    protected activeRoute: ActivatedRoute,
    protected snack: FennecSnackbarService,
    protected providerService: ProviderService
  ) {
    super();
    this.providerId = this.activeRoute.snapshot.params["id"];
  }

  ngOnInit(): void {
    this.getProviderTypes();
    this.getProvider();
  }

  getProvider() {
    this.performXFRequest({
      requestDescription: "GET provider by ID",
      requestFn: this.providerService.getProvider,
      fnParams: [this.providerId],
      onSuccess: data => {
        this.provider = data;
      },
      onError: errString => {
        super.showErrorSnack(errString);
      }
    });
  }

  getProviderTypes() {
    this.performXFRequest({
      requestDescription: "GET provider types",
      requestFn: this.providerService.getProviderTypes,
      fnParams: [],
      onSuccess: data => {
        this.providerTypes = data;
      },
      onError: errString => {
        super.showErrorSnack(errString);
      }
    })
  }

}
