
<h2 class="header">Itemized Information</h2>
<p class="sub-header">Editing alternate ID: {{itemized?.externalAltId}}</p>
<form [formGroup]="form" class="alt-edit-form">
  <mat-dialog-content>
    <div class="form-container">
      <mat-form-field class="form-field">
        <input class="input" matInput formControlName="externalAltId" placeholder="Enter Alternate ID" [value]="form.get('externalAltId')?.value">
        <mat-error *ngIf="form.get('externalAltId')?.hasError('required') && form.get('externalAltId')?.touched">
          Alternate ID is required
        </mat-error>
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="actions">
    <button mat-raised-button color="primary" (click)="onSave()" [disabled]="form.invalid">Save</button>
    <button mat-raised-button color="warn" (click)="onCancel()">Cancel</button>
  </mat-dialog-actions>
</form>
