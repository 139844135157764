import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';
import { APERuleFilter, BaseCrudService, Crud, DataResponse, PagedResponse } from "xf-common";


@Injectable()
export class ApeRuleFilterService extends BaseCrudService<APERuleFilter> {

  public baseEndpoint = super.apiEndpoint("ape-rule-filter");

  constructor(
    public override httpClient: HttpClient
  ) {
    super(httpClient, "ApeRuleFilterService", "APERuleFilter");
  }

  _list: Crud.ListFunction<APERuleFilter> = (first, max) => {
    const endpoint = `${this.baseEndpoint}/list`;
    return this.httpClient.put<PagedResponse<APERuleFilter[]>>(endpoint, { params: { first, max} })
  };

  _getById: Crud.GetByIdFunction<APERuleFilter> = () => {
    return EMPTY;
  };

  _create: Crud.CreateFunction<APERuleFilter> = (payload) => {
    const endpoint = `${this.baseEndpoint}/create-update`;
    return this.httpClient.post<DataResponse<APERuleFilter>>(endpoint, payload);
  };
  _update: Crud.UpdateFunction<APERuleFilter> = (payload) => {
    const endpoint = `${this.baseEndpoint}/create-update`;
    return this.httpClient.post<DataResponse<APERuleFilter>>(endpoint, payload);
  };

  _deactivate: Crud.DeleteFunction<APERuleFilter> = (id) => {
    const endpoint = `${this.baseEndpoint}/delete`;
    return this.httpClient.put<DataResponse<boolean>>(endpoint, id[0]);
  };
  _delete: Crud.DeleteFunction<APERuleFilter> = (id) => {
    const endpoint = `${this.baseEndpoint}/${id}`;
    return this.httpClient.delete<DataResponse<boolean>>(endpoint);
    // return EMPTY;
  };

  protected _crudFunctions: Crud.CrudServiceDef<APERuleFilter> = {
    _list: this._list,
    _getById: this._getById,
    _create: this._create,
    _update: this._update,
    _deactivate: this._deactivate,
    _delete: this._delete
  };

  getAPERuleFilters = (queryParams: {}, first?: number, max?: number): Observable<PagedResponse<any>> => {
    return this.httpClient.put<PagedResponse<any>>(this.baseEndpoint + "/list?first=" + first + "&max=" + max, queryParams)
  }

  getAPEFilterDomainList = () => {
    return this.httpClient.get<PagedResponse<any>>(this.baseEndpoint + "/domain-list");
  }
}
