import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AttachmentPacket, AttachmentService, BaseComponent, FennecSnackbarService, Logger } from "xf-common";


@Component({
  selector: 'app-modify-attachment-dialog',
  templateUrl: './modify-attachment-dialog.component.html',
  styleUrls: ['./modify-attachment-dialog.component.scss']
})
export class ModifyAttachmentDialogComponent extends BaseComponent implements OnInit {

  protected log = new Logger("ModifyAttachmentDialogComponent");

  attachment?: any;
  attachmentTypes: string[] = [];
  formGroup: FormGroup;

  constructor(
    protected snack: FennecSnackbarService,
    protected attachmentService: AttachmentService,
    protected dialog: MatDialog,
    protected dialogRef: MatDialogRef<ModifyAttachmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    super();
    if(data.attachment) {
      this.attachment = data.attachment;
      this.attachmentTypes = data.attachmentTypes;
    }
    this.formGroup = this.createFormGroup();
   }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }

  createFormGroup = () => {
    return new FormGroup({
      name: new FormControl(this.attachment?.name ?? "", [Validators.required]),
      attachmentType: new FormControl(this.attachment?.attachmentType ?? "", [Validators.required])
    })
  }

  saveAttachmentToServer() {
    // this.uploading = true;

    const dto = new AttachmentPacket();
    dto.id = this.attachment?.id;
    dto.name = this.formGroup.controls["name"].value;
    dto.miCaseId = this.attachment?.miCaseId;
    dto.externalObjectId = this.attachment?.externalObjectId;
    dto.attachmentType = this.formGroup.controls["attachmentType"].value;
    // dto.filePacket = this.attachment?.filePacket;

    this.isLoading = true;

    this.performXFRequest({
      requestDescription: "Update Attachment",
      requestFn: this.attachmentService.updateAttachment,
      fnParams: [dto],
      onSuccess: (data: boolean) => {
        super.showSuccessSnack("Successfully updated Attachment!");
        this.close();
      },
      onComplete: () => {
        // this.isLoading = false;
        // this.name = "";
        // this.attachmentType = AttachmentType.Itemized.toString();
        // this.file = undefined;
        // this.getAttachmentList();
        // this.uploading = false;
      },
      onError: (error) => {
        super.showErrorSnack(error);
        // this.isLoading = false;
        // this.name = "";
        // this.attachmentType = AttachmentType.Itemized.toString();
        // this.file = undefined;
        // this.getAttachmentList();
        // this.uploading = false;
      }
    });
  }
}
