import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ConditionCodeCreateFormComponent } from './condition-code-create-form/condition-code-create-form.component';
import { ConditionCodeDeleteFormComponent } from './condition-code-delete-form/condition-code-delete-form.component';
import { ConditionCodeEditFormComponent } from './condition-code-edit-form/condition-code-edit-form.component';
import { ConditionCodeService } from './condition-code.service';
import { ConditionCode, FennecSnackbarService } from 'xf-common';
import { FormControl, FormGroup } from '@angular/forms';


@Component({
  selector: 'app-condition-code',
  templateUrl: './condition-code.component.html',
  styleUrls: ['../../../lib/styles/system-admin-styles.component.scss']
})
export class ConditionCodeComponent  implements OnInit, AfterViewInit {
  dataSource = new MatTableDataSource<any>();
  displayedColumns = ['code', 'description', 'active', 'actions'];
  @ViewChild(MatPaginator)
  paginator?: MatPaginator;
  totalRowCount?: number;
  defaultPageSize = 20;
  pageSizeOptions = [10, 20, 25, 50];

  formGroup: FormGroup;
  filtersOpen: boolean = false;

  constructor(
    private snack: FennecSnackbarService,
    public matDialog: MatDialog,
    private codeService: ConditionCodeService
  ) {

    this.formGroup = this.createFormGroup();
   }

  ngAfterViewInit() {
    if (this.paginator) {
      this.paginator.page.subscribe(() => {
        this.getCodes();
      })
   }
  }

  ngOnInit(): void {
    this.getCodes();
  }

  getCodes() {
    this.executeListQuery();
    // const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    // const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;
    // this.codeService.getCodes(first, pageSize).subscribe((response: any) => {
    //   if (response.hasErrors) {
    //     this.snack.showErrorSnack(response.consolidatedErrorMessage);
    //   } else {
    //     this.dataSource.data = response.data;
    //     this.totalRowCount = response.totalRowCount;
    //   }
    // })
  }

  createClick() {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    const dialogRef = this.matDialog.open(ConditionCodeCreateFormComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.getCodes();
    })
  }

  editClick(code: ConditionCode) {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {};
    matDialogConfig.data.code = code;
    const dialogRef = this.matDialog.open(ConditionCodeEditFormComponent, matDialogConfig)
    dialogRef.afterClosed().subscribe(result => {
      this.getCodes();
    })
  }

  delete(code: ConditionCode) {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {};
    matDialogConfig.data.code = code;
    matDialogConfig.data.mode = 'delete';
    const dialogRef = this.matDialog.open(ConditionCodeDeleteFormComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.getCodes();
    });
  }

  changeStatus(code: ConditionCode) {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.disableClose = true;
    matDialogConfig.height = "auto";
    matDialogConfig.width = "auto";
    matDialogConfig.data = {};
    matDialogConfig.data.code = code;
    matDialogConfig.data.mode = 'status';
    const dialogRef = this.matDialog.open(ConditionCodeDeleteFormComponent, matDialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      this.getCodes();
    });
  }

  createFormGroup = () => {
    return new FormGroup({
      code: new FormControl(),
      description: new FormControl(),
      active: new FormControl(true)
    })
  }

  clearSearch = () => {
    this.formGroup = this.createFormGroup();
  }

  executeListQuery = () => {
    const controls = this.formGroup?.controls;

    const searchParams = {
      code: controls["code"].value?.trim() ?? null,
      description: controls["description"].value?.trim() ?? null,
      active: controls["active"].value,
    }

    if(searchParams.active === "all") {
      searchParams.active = null;
    }

    const pageSize = !this.paginator?.pageSize ? this.defaultPageSize : this.paginator.pageSize;
    const first = this.paginator?.pageIndex ? this.paginator.pageIndex * pageSize : 0;
    // this.performXFRequest({
    //   requestDescription: "GET Revenue Code By Search Params",
    //   requestFn: this.revenueCodeService.getRevenueCodesByParams,
    //   fnParams: [searchParams, first, pageSize],
    //   onResponse: response => {
    //     console.log(response);
    //     this.dataSource = response.data;
    //     this.totalRowCount = response['totalRowCount'];
    //   },
    //   onError: errString => {
    //     super.showErrorSnack(errString)
    //   }
    // })

    this.codeService.getConditionCodesByParams(searchParams, first, pageSize).subscribe((response: any) => {
      if (response.hasErrors) {
        this.snack.showErrorSnack(response.consolidatedErrorMessage);
      } else {
        this.dataSource.data = response.data;
        this.totalRowCount = response.totalRowCount;
      }
    })
  }

  onSearch = () => {
    this.paginator?.firstPage();
    this.executeListQuery();
  }
}
