import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { BaseCrudService, Crud, DataResponse, DischargeStatusPacket, PagedResponse } from "xf-common";


@Injectable()
export class DischargeStatusCodeService extends BaseCrudService<DischargeStatusPacket> {
  baseEndpoint = super.apiEndpoint("discharge-status");

  constructor(public override httpClient: HttpClient) {
    super(httpClient, "DischargeStatusCodeService", "DischargeStatus");
  }

  getDischargeStatus: () => Observable<DataResponse<DischargeStatusPacket>> = () => {
    const endpoint = `${this.baseEndpoint}`;
    return this.httpClient.get<DataResponse<DischargeStatusPacket>>(endpoint);
  }

  _list: Crud.ListFunction<DischargeStatusPacket> = (first, max) => {
    const endpoint = `${this.baseEndpoint}/list`;
    return this.httpClient.get<PagedResponse<DischargeStatusPacket[]>>(endpoint, { params: { first, max } });
  };

  _getById: Crud.GetByIdFunction<DischargeStatusPacket> = (id) => {
    const endpoint = `${this.baseEndpoint}/${id}`;
    return this.httpClient.get<PagedResponse<DischargeStatusPacket>>(endpoint);
  }

  _create: Crud.CreateFunction<DischargeStatusPacket> = (user) => {
    const endpoint = `${this.baseEndpoint}/create`;
    return this.httpClient.post<PagedResponse<DischargeStatusPacket>>(endpoint, user);
  }

  _update: Crud.UpdateFunction<DischargeStatusPacket> = (user): Observable<DataResponse<DischargeStatusPacket>> => {
    const endpoint = `${this.baseEndpoint}/update`;
    return this.httpClient.put<PagedResponse<DischargeStatusPacket>>(endpoint, user);
  }

  _deactivate: Crud.DeactivateFunction<DischargeStatusPacket> = (ids) => {
    const endpoint = `${this.baseEndpoint}/deactivate`;
    return this.httpClient.put<DataResponse<boolean>>(endpoint, ids[0]);
  }

  _delete = (ids: number[]):  Observable<DataResponse<boolean>> => {
    const endpoint = `${this.baseEndpoint}/delete/` + ids[0];
    return this.httpClient.delete<DataResponse<boolean>>(endpoint, {});
  }

  changeStatus = (dischargeStatus: DischargeStatusPacket): Observable<DataResponse<DischargeStatusPacket>> => {
    const endpoint = `${this.baseEndpoint}/status`;
    return this.httpClient.put<DataResponse<DischargeStatusPacket>>(endpoint, dischargeStatus);
  }

  protected _crudFunctions: Crud.CrudServiceDef<DischargeStatusPacket> = {
    _list: this._list,
    _getById: this._getById,
    _create: this._create,
    _update: this._update,
    _deactivate: this._deactivate,
    _delete: this._delete
  };

  getDischargeStatusByParams = (packet:any, first:number, max:number) => {
    const endpoint = `${this.baseEndpoint}/list?first=${first}&max=${max}`;
    return this.httpClient.post<PagedResponse<DischargeStatusPacket>>(endpoint, packet);
  }
}
