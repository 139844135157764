<div>
  <div matDialogTitle style="width: 100%; text-align: center;">
    {{elementLabel}}
  </div>
  <div *ngIf="infoLine1 !== null && infoLine1 !== undefined" 
     style="width: 100%; text-align: center; font-size: small;">
    {{infoLine1}}
  </div>

  <div matDialogContent>
    <form class="alt-theme" style="margin: 2%" [formGroup]="formGroup">
      <mat-form-field class="alt-theme" appearance="outline" style="width: 400px" color="secondary">
        <mat-label>{{elementLabel}} Value</mat-label>
        <mat-select panelClass="alt-theme" formControlName="statusTrackStatus">
          <mat-option *ngFor="let sts of manualStatusTrackStatusList" [value]="sts.statusTrackStatus">
            {{sts.label}}</mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </div>

  <div matDialogActions class="fennec-dialog-action-button-container">
    <button mat-flat-button color="primary" (click)="cancel()">
      <mat-icon>cancel</mat-icon>
      Cancel
    </button>
    <button mat-flat-button color="accent" (click)="submit()">
      <mat-icon>save</mat-icon>
      Save
    </button>
  </div>

</div>
