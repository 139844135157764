<h1 mat-dialog-title>Edit User Profile</h1>

<div>
    
  <mat-form-field>
    <mat-label>
      First Name
    </mat-label>
    <input matInput [(ngModel)]="userProfile.firstName">
  </mat-form-field>

  <mat-form-field>
    <mat-label>
      Last Name
    </mat-label>
    <input matInput [(ngModel)]="userProfile.lastName">
  </mat-form-field>

</div>

<div mat-dialog-actions align="end">
    
  <button mat-button color="accent" (click)="onSave()">
    <mat-icon>save</mat-icon> Save
  </button>
  
  <button mat-button color="secondary" [mat-dialog-close]="userProfile">
    <mat-icon>cancel</mat-icon> Cancel
  </button>

</div>