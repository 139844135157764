<div matDialogTitle>
  Event Definitions for APE Rule ID: {{ this.apeRule?.id }} - {{ this.apeRule?.name }}

  <button
    mat-icon-button
    class="close-btn"
    (click)="closeDialog()"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>

<div matDialogContent class="dialog-wrapper-container">
  <app-ape-rule-event-definition-list 
    *ngIf="mode == 'LIST'" 
    [apeRuleID]="this.apeRule?.id">
  </app-ape-rule-event-definition-list>
</div>