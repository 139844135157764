<mat-dialog-content>
<h3>{{data.mode}} JSL Job Definition</h3>

<form [formGroup]="jobDefinitionDialogFormGroup">
    <mat-form-field appearance="outline" color="accent" class="job-code-input">
        <input matInput placeholder="Enter Job Code" formControlName="jslJobCode"/>
    </mat-form-field>
    <mat-form-field appearance="outline" color="accent" class="name-input">
        <input matInput placeholder="Enter Name" formControlName="name"/>
    </mat-form-field>
</form>
</mat-dialog-content>
<!--  -->
<mat-dialog-actions class="fennec-controls">
    <button type="submit" mat-flat-button color="accent" (click)="submit()" style="margin-right: 5px;">
        <mat-icon>save</mat-icon> Save
    </button>
    <button type="button" mat-stroked-button (click)="close()">
        <mat-icon>cancel</mat-icon> Cancel
    </button>
</mat-dialog-actions>
<!--  -->